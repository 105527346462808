import { gql } from '@apollo/client'
import { FULL_TICKET_FRAGMENT } from './tickets/fragments'
import { FULL_TASK_FRAGMENT } from './tasks/fragments'
import { CHANGE_LOG_FRAGMENT, USER_FRAGMENT } from './fragments'

export const ON_NEW_CHANGE_SUBSCRIPTION = gql`
    subscription ON_NEW_CHANGE_SUBSCRIPTION($contentTypes: [String!]) {
        onNewChange(contentTypes: $contentTypes) {
            changeLog {
                ...ChangeLogFields
                objectId
                contentModel
            }
            contentObject {
                ... on TicketType {
                    ...AllTicketFields
                }
                ... on TaskType {
                    ...AllTaskFields
                }
            }
        }
    }
    ${CHANGE_LOG_FRAGMENT}
    ${FULL_TICKET_FRAGMENT}
    ${FULL_TASK_FRAGMENT}
`

export const ON_INSTANCE_DELETED_SUBSCRIPTION = gql`
    subscription ON_INSTANCE_DELETED_SUBSCRIPTION($contentTypes: [String]) {
        onInstanceDeleted(contentTypes: $contentTypes) {
            changeLog {
                ...ChangeLogFields
                objectId
                contentModel
            }
        }
    }
    ${CHANGE_LOG_FRAGMENT}
`

export const ON_USER_STATUS_UPDATE_SUBSCRIPTION = gql`
    subscription ON_USER_STATUS_UPDATE_SUBSCRIPTION {
        onUserStatusUpdate {
            user {
                ...UserFields
                profile {
                    id
                    onlineStatus
                }
            }
        }
    }
    ${USER_FRAGMENT}
`
