import { useSubscription, useReactiveVar } from '@apollo/client'
import { twilioDeviceVar } from '../../cache'
import { GET_USER_CALLS } from '../../contacts/queries'
import { GET_CALLS } from '../queries'
import { ON_CALL_UPDATE_SUBSCRIPTION } from '../subscriptions'

export const OnCallStatusSubscriber = ({ callSids, onData }) => {
    const { conn } = useReactiveVar(twilioDeviceVar)
    const callInProgressSid = conn ? conn._direction === 'OUTGOING' ? conn.parameters?.CallSid : conn.customParameters?.get('call_sid') : ''
    
    useSubscription(ON_CALL_UPDATE_SUBSCRIPTION, {
        variables: { callSids: callSids ? [...new Set(callSids.filter(Boolean))] : undefined },
        onSubscriptionData: ({ subscriptionData, client }) => {
            if (!subscriptionData.data) return
            
            const { call, created } = subscriptionData.data.onCallUpdate || {}
            
            if (!call) return

            if (callInProgressSid && callInProgressSid === call.callSid) {
                twilioDeviceVar({ ...twilioDeviceVar(), call })
            }
            onData && onData(call)

            if (created) {
                // insert new call to calls lists
                // client.cache.updateQuery({
                //     query: GET_CALLS,
                //     variables: ''
                // }, (data) => {
                //     if (!data?.calls) return undefined

                //     if (data.calls.some(c => call.id === client.cache.data.getFieldValue(c, 'id'))) {
                //         return data
                //     } else {
                //         return { calls: [call, ...data.calls] }
                //     }
                // })

                // insert new call to user calls list
                const userIds = []
                if (call.caller) userIds.push(call.caller.id)
                if (call.receiver) userIds.push(call.receiver.id)

                userIds.forEach((userId) => {
                    client.cache.updateQuery({
                        query: GET_USER_CALLS,
                        variables: { userId }
                    }, (data) => {
                        if (!data?.userCalls) return undefined
        
                        if (data.userCalls.some(c => call.id === client.cache.data.getFieldValue(c, 'id'))) {
                            return data
                        } else {
                            return { userCalls: [call, ...data.userCalls] }
                        }
                    })
                })
            }
        }
    })
    
    return null
}

export default OnCallStatusSubscriber
