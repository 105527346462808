import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { TabNavContext } from '../../components/TabNav'
import { lbl, tabNavLinks } from '../../constants'
import { Icon } from '../../components'

const TaskDetailNav = ({ taskId }) => {
    const { activeSubtab } = useContext(TabNavContext)
    const urlRoot = `${tabNavLinks.tasks.dynamicPath}${taskId}`

    return (
        <nav className='tab-nav'>
            <ul className='tab-nav__list'>
                <li className={`tab ${activeSubtab === 'updates' ? ' active' : ''}`}>
                    <Link to={`${urlRoot}/updates`}>
                        <Icon type='time' variant='xs' />
                        <span className='tab__label t-sm'>{lbl.updates}</span>
                    </Link>
                </li>
                <li className={`tab ${activeSubtab === 'attachments' ? ' active' : ''}`}>
                    <Link to={`${urlRoot}/attachments`}>
                        <Icon type='attachment' variant='xs' />
                        <span className='tab__label t-sm'>{lbl.attachments}</span>
                    </Link>
                </li>
                <li className={`tab ${activeSubtab === 'notes' ? ' active' : ''}`}>
                    <Link to={`${urlRoot}/notes`}>
                        <Icon type='clipboard' variant='xs' />
                        <span className='tab__label t-sm'>{lbl.notes}</span>
                    </Link>
                </li>
            </ul>
        </nav>
    )
}

export default TaskDetailNav
