import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { UPDATE_PROPERTY } from '../mutations'
import { GET_PARTNERS, GET_OPERATIONAL_MODELS, GET_PROPERTY_TYPES, GET_LANDLORDS, GET_PROPERTY_TENANTS } from '../queries'
import { useEffect } from 'react'
import FormWithCustomFields from '../../forms/FormWithCustomFields'

const serviceCostDivisionModelOptions = [
    { value: 'SQM', label: 'Per square meter' },
    { value: 'UNIT', label: 'Equal per unit' }
]

const AddressInfoForm = ({
    address={},
    draft,
    submitMutation=UPDATE_PROPERTY,
    onCompleted,
    setDraft,
    extraFields=[]
}) => {
    const {data: propertyTypeData, error: propertyTypeError} = useQuery(GET_PROPERTY_TYPES)
    const {data: operationalModelData, error: operationalModelError} = useQuery(GET_OPERATIONAL_MODELS)
    const {data: partnerData, error: partnerError} = useQuery(GET_PARTNERS)
    const {data: ownerData, error: ownerError} = useQuery(GET_LANDLORDS)
    const [getPropertyTenants, {data: tenantsData, error: tenantsError}] = useLazyQuery(GET_PROPERTY_TENANTS)
    const owner = address.owner || address.building?.owner
    const initialState = draft || {
        ...address,
        buildingId: address.building?.id,
        ownerId: owner?.id,
        tenantRepresentativeId: address.tenantRepresentative?.id,
        propertyTypeId: address.propertyType?.id,
        operationalModelId: address.operationalModel?.id,
        brand: address.centralHeating?.brand,
        model: address.centralHeating?.model,
        location: address.centralHeating?.location,
        dateInstalled: address.centralHeating?.dateInstalled,
        lastCheckUpDate: address.centralHeating?.lastCheckUpDate,
        nextCheckUpDueDate: address.centralHeating?.nextCheckUpDueDate,
        repairContactId: address.centralHeating?.repairContact?.id,
        notes: address.centralHeating?.notes,
    }
    const [updateProperty, { loading, called, data, error }] = useMutation(submitMutation, { onCompleted })
    const success = !error && !loading && called && !!data
    const centralHeatingRepair = address.centralHeating?.repairContact

    let ownerOptions = ownerData?.landlords ? ownerData.landlords.map(({ id, user }) => ({ value: id, label: user.fullName })) : [
        { value: owner?.id, label: owner?.user.fullName },
        { value: 'owner-x', label: ownerError?.message || 'Loading ...' }
    ]
    let partnerContactOptions = partnerData?.partners ? partnerData.partners.map(({ id, user, company }) => ({ value: id, label: `${company ? company.name + ' / ' : ''}${user.fullName}` })) : [
        { value: centralHeatingRepair?.id, label: centralHeatingRepair ? `${centralHeatingRepair.company ? centralHeatingRepair.company.name + ' / ' : ''}${centralHeatingRepair.user.fullName}` : '' },
        { value: 'partner-x', label: partnerError?.message || 'Loading ...' }
    ]
    let propertyTypeOptions = propertyTypeData?.propertyTypes ? propertyTypeData.propertyTypes.map(({ id, name }) => ({ value: id, label: name })) : [
        { value: address.propertyType?.id, label: address.propertyType?.name },
        { value: 'property-type-x', label: propertyTypeError?.message || 'Loading ...' }
    ]
    let operationalModelOptions = operationalModelData?.operationalModels ? operationalModelData.operationalModels.map(({ id, name }) => ({ value: id, label: name })) : [
        { value: address.operationalModel?.id, label: address.operationalModel?.name },
        { value: 'operational-model-x', label: operationalModelError?.message || 'Loading ...' }
    ]
    let tenantRepresentativeOptions = tenantsData?.property?.tenants ? tenantsData.property.tenants.map(({ id, user }) => ({ value: id, label: user.fullName })) : [
        { value: address.tenantRepresentative?.id, label: address.tenantRepresentative?.user.fullName },
        { value: 'tenant-rep-x', label: tenantsError?.message || 'Loading ...' }
    ]

    const streetFields = [
        { name: 'streetName', required: true, fieldsetStyle: { gridColumnStart: 1, gridColumnEnd: 4 }},
        { name: 'houseNumber', type: 'number', fieldsetStyle: { gridColumnStart: 4, gridColumnEnd: 6 }},
        { name: 'houseNumberAddition', lblKey: 'addition', fieldsetStyle: { gridColumnStart: 6, gridColumnEnd: 7 } }
    ]

    const addressFields = [
        { name: 'postcode' },
        { name: 'propertyTypeId', lblKey: 'propertyType', As: 'select', options: propertyTypeOptions },
        { name: 'operationalModelId', lblKey: 'operationalModel', As: 'select', options: operationalModelOptions },
        { name: 'sizeSqm', type: 'number', step: '0.1' },
        !address.id && { name: 'keycode', disabled: true },
        { name: 'minOccupancy', type: 'number' },
        { name: 'maxOccupancy', type: 'number' },
        { name: 'ownerId', lblKey: 'owner', As: 'select', options: ownerOptions },
        tenantRepresentativeOptions.length ? { name: 'tenantRepresentativeId', lblKey: 'tenantRepresentative', As: 'select', options: tenantRepresentativeOptions } : undefined,
        !!address.id && { name: 'keycode', disabled: true },
        { name: 'hasFireSafetySystem', type: 'checkbox' },
        { name: 'serviceCostDivisionModel', As: 'select', options: serviceCostDivisionModelOptions, BeforeNode: () => <div className='field-group__title t-sm mt-2 mb-2'>Costs</div> },
        { name: 'monthlyOwnerServiceCost', type: 'decimal' },
        { name: 'monthlyServiceCost', type: 'decimal' },
        { name: 'otherCosts', type: 'decimal' }
    ].filter(Boolean)

    const centralHeatingFields = [
        { name: 'brand', BeforeNode: () => <div className='field-group__title t-sm mt-2 mb-2'>Central heating / Boiler</div> },
        { name: 'model' },
        { name: 'location', type: 'text', wide: true },
        { name: 'dateInstalled', type: 'date' },
        { name: 'repairContactId', lblKey:'repairContact', As: 'select', options: partnerContactOptions },
        { name: 'lastCheckUpDate', type: 'date' },
        { name: 'nextCheckUpDueDate', type: 'date' },
        { name: 'notes', type: 'textarea', As: 'textarea', wide: true },
    ]

    useEffect(() => {
        if (address.id) getPropertyTenants({ variables: { propertyId: address.id }})
    }, [address.id])

    return (
        <FormWithCustomFields
            contentType='address'
            objectId={address.id}
            introFields={streetFields}
            fields={[...extraFields, ...addressFields, ...centralHeatingFields]}
            initialState={initialState}
            onSubmit={(data, cancelChanges, saveCustomFields) => {
                updateProperty({
                    variables: { id: address.id, ...data },
                    onCompleted: (c) => {
                        if (c && saveCustomFields) {
                            saveCustomFields(address.id || c.createProperty?.property?.id)
                        }
                        onCompleted && onCompleted(c)
                    }
                })
            }}
            onCancel={onCompleted}
            isSubmitting={loading}
            isSuccess={success}
            setDraft={setDraft}
            formClassName='form--column--2'
        />
    )
};

export default AddressInfoForm
