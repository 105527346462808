import { useState, useContext } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { currentUserVar, taskListCtrlsVar } from '../../cache'
import { TabNavContext } from '../../components/TabNav'
import { getProp } from '../../utils'
import { DUPLICATE_TASK, ARCHIVE_TASK, ACTIVATE_TASK, DELETE_TASK } from '../mutations'
import { GET_TASKS } from '../queries'
import { lbl, tabNavLinks } from '../../constants'
import { Icon } from '../../components'


const TaskActions = (task) => {
    const history = useHistory()
    const { pinnedTabs, unpinTab, closeTab } = useContext(TabNavContext)
    const [deletePopUpOpened, toggleDeletePopUp] = useState(false)
    const { dynamicPath } = tabNavLinks.tasks
    const isEditable = !task.archived && !task.deleted
    
    const [duplicateTask] = useMutation(DUPLICATE_TASK, {
        variables: { id: task.id },
        onCompleted: ({ duplicateTask }) => { 
            duplicateTask && history.push(`${dynamicPath}${duplicateTask.task.id}`)
        },
        update(cache, { data: { duplicateTask } }) {
            const tasksField = duplicateTask.task.archived ? 'archivedTasks' : 'tasks'

            cache.modify({
                fields: {
                    [tasksField](existingTasks = []) {
                        return [duplicateTask.task, ...existingTasks];
                    }
                }
            })
            duplicateTask.task.ticket && cache.modify({
                id: cache.identify({ __typename: 'TicketType', id: duplicateTask.task.ticket.id }),
                fields: {
                    tasks(existingTasks = []) {
                        return [...existingTasks, duplicateTask.task]
                    }
                }
            })
        }
    })

    const [deleteTask] = useMutation(DELETE_TASK, {
        onCompleted: ({ deleteTask: { deletedId } }) => {
            const relativeUrl = dynamicPath + deletedId
            pinnedTabs.get(relativeUrl) ?
                unpinTab({ relativeUrl, preventOpen: true }) :
                closeTab({ id: deletedId, relativeUrl })
        },
        update(cache, { data: { deleteTask: { deletedId, relatedTicketId } } }) {
            task.ticket && cache.modify({
                id: cache.identify({ __typename: 'TicketType', id: relatedTicketId }),
                fields: {
                    tasks(existingTasks = [], { readField }) {
                        return existingTasks.filter(t => deletedId !== readField('id', t))
                    }
                }
            })
            const tasksField = task.archived ? 'archivedTasks' : 'tasks'
            cache.modify({
                fields: {
                    [tasksField](existingTasks = [], { readField }) {
                        return existingTasks.filter(t => deletedId !== readField('id', t))
                    }
                }
            })
            cache.modify({
                id: cache.identify({ __typename: 'TaskType', id: deletedId }),
                fields(fieldValue, details) {
                    return details.DELETE //TODO: Prevent GET_TASK query after deleting
                }
            })
        }
    })

    const [archive] = useMutation(ARCHIVE_TASK, {
        variables: { id: task.id },
        update(cache, { data: { archiveTask: { task: archivedTask } } }) {
            cache.modify({
                id: cache.identify({ __typename: 'TaskType', id: archivedTask.id }),
                fields: { archived() { return archivedTask.archived } }
            })
            archivedTask.ticket && !archivedTask.ticket.archived && cache.modify({
                id: cache.identify({ __typename: 'TicketType', id: archivedTask.ticket.id }),
                fields: {
                    tasks(existingTasks = [], { readField }) {
                        return existingTasks.filter(t => archivedTask.id !== readField('id', t))
                    }
                }
            })
            // task listers will be updated via ON_NEW_CHANGE subscription
        }
    })

    const [activate] = useMutation(ACTIVATE_TASK, {
        variables: { id: task.id },
        update(cache, { data: { activateTask } }) {
            activateTask.task.ticket && cache.modify({
                id: cache.identify({ __typename: 'TicketType', id: activateTask.task.ticket.id }),
                fields: {
                    tasks(existingTasks = []) {
                        return [activateTask.task, ...existingTasks]
                    }
                }
            })
            // task listers will be updated via ON_NEW_CHANGE subscription
        }
    })
    
    return (
        <section className='task__actions'>
            <ul className='action-list'>
                {task.ticket &&
                    <li className='action__li'>
                        <Link
                            className='btn btn--naked btn--inline-icon'
                            to={tabNavLinks.tickets.dynamicPath + task.ticket.id}
                        >
                            <>
                                <Icon type='arrow-right' variant='sm' />
                                {lbl.goToRelTicket}
                            </>
                        </Link>
                    </li>
                }
                <li className='action__li task-action--duplicate'>
                    <button
                        className='btn btn--naked btn--inline-icon'
                        onClick={duplicateTask}
                        disabled={!isEditable}
                    >
                        <Icon type='duplicate' variant='sm' />
                        {lbl.duplicate}
                    </button>
                </li>

                {task.archived ?
                    <li className='action__li task-action--activate'>
                        <button
                            className='btn btn--naked btn--inline-icon'
                            onClick={activate}
                            disabled={task.deleted}
                        >
                            <Icon type='activate' variant='sm' />
                            {lbl.restore}
                        </button>
                    </li>
                :
                    <li className='action__li task-action--archive'>
                        <button
                            className='btn btn--naked btn--inline-icon'
                            onClick={archive}
                            disabled={task.deleted}
                        >
                            <Icon type='archive' variant='sm' />
                            {lbl.archive}
                        </button>
                    </li>
                }

                {!task.deleted && (task.createdBy?.id === getProp('id', currentUserVar()) || getProp('isSuperuser', currentUserVar())) &&
                    <li className='action__li task-action--delete pos-relative'>
                        <button
                            className='btn btn--naked btn--inline-icon'
                            onClick={() => toggleDeletePopUp(true)}
                        >
                            <Icon type='bin' variant='sm' />
                            {lbl.delete}
                        </button>
                        {deletePopUpOpened &&
                            <div className='pop-up pop-up--top'>
                                <div className='pop-up__container'>
                                    <header className='pop-up__header'>
                                        Confirm delete
                                    </header>
                                    <div className='pop-up__message t-s'>
                                        <p>Are you sure you want to delete <strong>{task.name}</strong>?</p>
                                        <p>Task will be permanently deleted and will not available in tasks archive.</p>
                                    </div>
                                    <div className='pop-up__btns'>
                                        <button className='btn btn--secondary mr-2' onClick={() => toggleDeletePopUp(false)}>
                                            {lbl.cancel}
                                        </button>
                                        <button
                                            className='btn btn--primary'
                                            onClick={() => {
                                                deleteTask({ variables: { id: task.id } })
                                                toggleDeletePopUp(false)
                                            }}
                                        >
                                            {lbl.delete}
                                        </button>
                                    </div>
                                    <button
                                        className='btn btn--naked close mt-2 mr-2'
                                        onClick={() => toggleDeletePopUp(false)}
                                    >
                                        <Icon type='close' variant='xs' />
                                    </button>
                                </div>
                            </div>
                        }
                    </li>
                }
            </ul>
        </section>
    )
}

export default TaskActions
