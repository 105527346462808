import { useQuery } from '@apollo/client'
import { GET_TICKET_THREADS } from '../../messenger/queries'
import ThreadList from '../../messenger/components/ThreadList'

const TicketThreads = ({ ticketId }) => {
    const { data, error, loading } = useQuery(GET_TICKET_THREADS, { 
        variables: { ticketId }
    })

    if (loading) return <div className='loader' />
    
    if (error) return (
        <div className='t-grey w-100 mx-auto my-auto'>
            <center>{error.message}</center>
        </div>
    )
    
    if (data && data.ticket.threads.length === 0) return (
        <div className='t-grey w-100 mx-auto my-auto'>
            <center>No conversations on this ticket</center>
        </div>
    )
    
    if (data) return <ThreadList threads={data.ticket.threads} />
    
    return null
};

export default TicketThreads
