import { useQuery } from '@apollo/client'
import { useLocation, useHistory } from 'react-router-dom'
import { GET_SERVICE_METER } from '../queries'
import { ADD_METER } from '../mutations'
import { lbl } from '../../constants'
import { Accordion, Label } from '../../components'
import MeterInfoForm from './MeterInfoForm'
import MeterReadings from './MeterReadings'

const ServiceMeter = ({ service }) => {
    const { loading, data, refetch } = useQuery(GET_SERVICE_METER, { variables: { serviceId: service.id } })
    const history = useHistory()
    const { pathname, hash } = useLocation()

    return (
        <>
            {loading && <div className='loader pos-center-y'/>}
            {!loading && data && !data.serviceMeter ?
                <Accordion
                    Tag='div'
                    headerClass='accordion__header--bordered t-s t-heading p-1 t-darkgrey'
                    label={`Add ${service.type.name} meter`}
                    toggleOnHeaderClick
                >
                    <div className='ml-6 mr-1 mb-2'>
                        <MeterInfoForm
                            draft={{ serviceId: service.id }}
                            submitMutation={ADD_METER}
                            mutationVariables={{ serviceId: service.id }}
                            onCompleted={({ addMeter }) => {
                                if (addMeter.meter) {
                                    refetch()
                                    const url = `${pathname}#meter_${addMeter.meter.id}`
                                    history.push(url)
                                }
                            }}
                        />
                    </div>
                </Accordion>
            : data?.serviceMeter &&
                <>
                    <Accordion
                        Tag='div'
                        isOpen={hash === `#meter_${data.serviceMeter.id}`}
                        className={data.serviceMeter.archived ? 'inactive' : ''}
                        headerClass='accordion__header--bordered t-s t-heading p-1 t-darkgrey'
                        toggleOnHeaderClick
                        label={
                            <div className='d-flex align-center'>
                                <span>Meter</span>
                                {data.serviceMeter.archived &&
                                    <Label
                                        classModifier='grey'
                                        className='ml-1 label--uppercase label--archived'
                                        iconProps={{ type: 'archive', variant: '3xs' }}
                                        label={lbl.archived}
                                    />
                                }
                            </div>
                        }
                    >
                        <div className='ml-4 mr-1'>
                            <MeterInfoForm meter={data.serviceMeter} />
                        </div>
                    </Accordion>
                    <Accordion
                        Tag='div'
                        headerClass='accordion__header--bordered t-s t-heading p-1 t-darkgrey'
                        label='Meter readings'
                        toggleOnHeaderClick
                        isOpen={data.serviceMeter?.meterReadings.map(({ id }) => `#meter_reading_${id}`).includes(hash)}
                    >
                        <div className='ml-4 mr-1'>
                            <MeterReadings meter={data.serviceMeter} />
                        </div>
                    </Accordion>
                </>
            }
        </>
    )
}

export default ServiceMeter
