import { useEffect, useContext } from 'react'
import { useQuery, useLazyQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import { PROPERTY_FRAGMENT } from '../fragments'
import { GET_PROPERTY } from '../queries'
import { GET_PINNED_NOTES_FOR_CONTENT_OBJECTS } from '../../notes/queries'
import { TabNavContext } from '../../components/TabNav'
import { Icon, Label } from '../../components'
import { lbl, tabNavLinks } from '../../constants'
import PropertySidePanel from './PropertySidePanel'
import PropertyDetailNav from './PropertyDetailNav'
import PropertyDetailRouter from './PropertyDetailRouter'
import PinnedNotePreview from '../../notes/components/PinnedNotePreview'

const PropertyDetail = () => {
    const { id, activeSubtab, pinnedTabs, openTab } = useContext(TabNavContext)
    const [getPinnedNotes, { data: pinnedNotesData }] = useLazyQuery(GET_PINNED_NOTES_FOR_CONTENT_OBJECTS)
    const { loading, error, data, called } = useQuery(GET_PROPERTY, {
        variables: { id },
        onCompleted: ({ property }) => {
            const owner = property.owner || property.building?.owner
            
            getPinnedNotes({
                variables: { contentObjects: [
                    { contentType: 'address', objectIds: [id] },
                    { contentType: 'user', objectIds: owner?.user?.id ? [owner.user.id] : [] },
                ] }
            })
        }
    })

    const relativeUrl = tabNavLinks.properties.dynamicPath + id
    const isPinned = pinnedTabs.get(relativeUrl)
    const name = data?.property?.streetWithHouseNumber
    const isLister = ['tickets', 'tasks', 'attachments', 'calls'].includes(activeSubtab)

    useEffect(() => {
        called && name && !isPinned && openTab({ id, name, relativeUrl, icon: 'property' })
    }, [called, name, id, isPinned])

    if (loading) return <div className='loader pos-center-y' />
    if (error || !data?.property) return <p>Error :(</p>
    
    const { property } = data

    return (
        <div className='property-detail split-panel'>
            <section className='split-panel--main-dual intersection-root'>
                <div className='split-panel--main-top'>
                    {property.archived &&
                        <div>
                            <Label
                                classModifier='grey'
                                className='label--uppercase label--archived'
                                iconProps={{ type: 'archive', variant: '3xs' }}
                                label={lbl.archived}
                            />
                        </div>
                    }
                    <div className='d-inline-flex mb-1'>
                        <h1 className='t-heading t-2xl mr-2'>
                            {property.streetWithHouseNumber}
                        </h1>
                    </div>
                    <div className='d-flex'>
                        <Link to={tabNavLinks.properties.building.dynamicPath + property.building.id} className='t-underline-hover d-flex'>
                            <Icon type='building' variant='2xs' wrapperClassAdditions='mr-05' />
                            {property.building.name}
                        </Link>
                        {`, ${property.postcode}, ${property.city.name}`}
                    </div>
                    <div>
                        {pinnedNotesData?.notesForContentObjects?.map(note => (
                            <PinnedNotePreview
                                key={`${note.pinned ? 'pinned-' : ''}note-${note.id}`}
                                note={note}
                                path={`${tabNavLinks.properties.dynamicPath}${property.id}`}
                            />
                        ))}
                    </div>
                    <br/>
                </div>
                <div className='split-panel--main-nav'>
                    <PropertyDetailNav
                        urlRoot={tabNavLinks.properties.dynamicPath + property.id}
                        contentType='address'
                    />
                </div>
                <div className={`split-panel--main-bottom${isLister ? ' lister' : ''}`}>
                    <PropertyDetailRouter
                        urlRoot={tabNavLinks.properties.dynamicPath + property.id}
                        objectId={property.id}
                        contentType='address'
                        building={property.building}
                        addresses={[property]}
                        units={property.rooms}
                        title={property.streetWithHouseNumber}
                        owner={property.owner || property.building?.owner}
                        archived={property.archived}
                    />
                </div>
            </section>

            <PropertySidePanel
                type='address'
                id={id}
                image={property.picture}
                contacts={[
                    { label: lbl.tenantRepresentativeShort, type: '1_guests', user: property.tenantRepresentative?.user },
                    { label: lbl.owner, type: 'landlords', user: property.owner?.user || property.building.owner?.user },
                    { label: lbl.caretaker, type: 'partners', user: property.building.caretaker?.user, organisation: property.building.caretaker?.company?.name },
                    { label: lbl.hoaManager, type: 'partners', user: property.building.homeownerAssociation?.vveManager?.user, organisation: property.building.homeownerAssociation?.name },
                    { label: lbl.boilerRepair, type: 'partners', user: property.centralHeating?.repairContact?.user, organisation: property.centralHeating?.repairContact?.company?.name }
                ].filter(({ user }) => !!user)}
                propertyId={property.id}
                fragment={PROPERTY_FRAGMENT}
                isArchived={property.archived}
            />
        </div>
    )
}

export default PropertyDetail
