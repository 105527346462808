import { Redirect, useLocation, Link } from 'react-router-dom'
import PropertyOwnerContracts from './PropertyOwnerContracts'
import PropertyContracts from './PropertyContracts'
import UnitContracts from './UnitContracts'
import { Icon } from '../../components'

const Contracts = ({
    urlRoot,
    contentType,
    objectId,
    building,
    units=[],
    addresses=[],
    title,
    owner,
    archived
}) => {
    const { hash, pathname } = useLocation()
    const showOwnerContracts = contentType === 'building' || hash.includes('#owner_contract')

    return (
        <>
            <div className='d-inline-flex justify-start mb-3'>
                {contentType !== 'building' &&
                    <Link
                        disabled={!showOwnerContracts}
                        className={`btn btn--naked btn--icon mr-1 br-05${!showOwnerContracts ? ' active': ''}`}
                        to={pathname}
                    >
                        <Icon type='tenant' variant='sm'/>
                    </Link>
                }
                <Link
                    disabled={showOwnerContracts || !owner}
                    className={`btn btn--naked btn--icon mr-1 br-05${showOwnerContracts ? ' active': ''}`}
                    to={`${pathname}#owner_contracts`}
                >
                    <Icon type='landlord' variant='sm'/>
                </Link>
                
            </div>
            {showOwnerContracts ?
                owner ?
                    <PropertyOwnerContracts
                        contentType={contentType}
                        objectId={objectId}
                        building={building}
                        units={units}
                        addresses={addresses}
                        title={title}
                        owner={owner}
                        archived={archived}
                    />
                :
                    <div className='t-center t-s mt-4'>   
                        This property has no identified owner.<br/><br/>
                        Please fill in owner field in <Link to={contentType === 'room' ? `/properties/property/${addresses[0].id}/info` : `${urlRoot}/info`}>Info</Link> section.
                    </div>
            : contentType === 'address' ?
                <PropertyContracts
                    id={objectId}
                    property={addresses[0]}
                    units={units}
                    archived={archived}
                />
            : contentType === 'room' ?
                <UnitContracts
                    id={objectId}
                    unit={units[0]}
                    archived={archived}
                />
            :
                <Redirect to={{ pathname: `${urlRoot}/info` }}/>
            }
        </>
    )
}

export default Contracts
