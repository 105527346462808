import { useRef, useEffect } from 'react'
import Select from 'react-dropdown-select'
import { lbl } from '../constants'
import { Icon } from '../components'

const compareValuesFunc = (a, b) => {
    if (a.length !== b.length) return false
    const _a = a.map(({ value }) => value).sort().join()
    const _b = b.map(({ value }) => value).sort().join()
    return _a === _b;
}

const Field = ({
    name,
    value,
    programaticValue,
    handleChange,
    As='input',
    type='text',
    wide=false,
    isEdited,
    isSubmitting,
    options,
    label,
    lblKey,
    fieldsetStyle,
    href,
    filename,
    ...inputProps
}) => {
    const fieldEl = useRef(null)
    const getValues = () => {
        if (inputProps.values) return inputProps.values;
        if (value) {
            if (Array.isArray(value)) return value.map(v => ({ value: v, label: options.find(o => o.value === v)?.label }))
            return [{ value, label: options.find(o => o.value === value)?.label }]
        }
        return []
    }

    useEffect(() => {
        if (programaticValue !== value && programaticValue !== undefined) {
            handleChange({ target: { name, type, value: programaticValue }}, true)
        }
    }, [programaticValue])

    return (
        <fieldset className={wide ? 'wide' : ''} style={fieldsetStyle}>
            {type === 'file' && !isEdited && !isSubmitting ?
                <label htmlFor={inputProps.id || name} className={type === 'file' ? 'upload-file cursor--pointer' : ''}>
                    <div className='upload-file__label'>{label || lbl[lblKey || name]}</div>
                    <div className={`upload-file__input${inputProps.disabled ? ' disabled' : ''}`}>
                        {filename && <div className={`t-s t-ellipsis${isEdited ? ' t-blue' : ''}`}>{filename}</div>}
                    </div>
                </label>
            :
                <label className='t-ellipsis w-100'>{label || lbl[lblKey || name]}</label>
            }
            {As === 'search-select' ?
                <Select
                    options={options}
                    searchable
                    onChange={(x) => {
                        if (inputProps.multiple) {
                            handleChange({ target: { name, value: x.map(({ value }) => value) } })
                        } else {
                            handleChange({ target: { name, value: x[0] ? x[0].value : '' } })
                        }
                    }}
                    className={`fieldset__search-select${isEdited ? ' edited' : ''}`}
                    multi={inputProps.multiple}
                    values={getValues()}
                    dropdownGap={0}
                    clearable={false}
                    compareValuesFunc={compareValuesFunc}
                    wrapperClassName='w-100'
                    {...inputProps}
                />
            :
                <As
                    ref={fieldEl}
                    type={type === 'decimal' ? 'number' : type}
                    id={name}
                    name={name}
                    value={type === 'file' ? undefined : value}
                    onChange={handleChange}
                    className={`t-ellipsis ${isEdited ? ' t-blue' : ''}`}
                    checked={value}
                    step={type === 'decimal' ? '0.01' : undefined}
                    min='0'
                    style={type === 'file' && !isEdited && !isSubmitting ? { display: 'none' } : undefined}
                    {...inputProps}
                >
                    {options ? 
                        <>
                            {!value && options[0]?.value &&
                                <option key='option-0' disabled={inputProps.required} value={value}></option>
                            }
                            {options.map(option => (
                                <option key={option.value || 'x'} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </>
                    : null }
                </As>
            }
            {href && !isEdited &&
                <a
                    href={href}
                    className='btn btn--naked pos-right pos-top'
                    target='_blank'
                    rel='noreferrer'
                    style={{ zIndex: 10, position: 'absolute', padding: '2px' }}
                >
                    <Icon
                        variant='xs'
                        type={type === 'file' ? 'eye' : 'arrow-right'}
                        style={type === 'file' ? undefined : { transform: 'rotate(-45deg)' }}
                    />
                </a>
            }
        </fieldset>
    )
}

export default Field
