import { gql } from '@apollo/client'

export const CALL_FRAGMENT = gql`
    fragment CallFields on CallType {
        id
        callSid
        dialCallSid
        direction
        status
        missed
        subject
        duration
        dateTime
        caller {
            id
            fullName
            profile {
                id
                phoneNumber
                secondaryPhoneNumber
                picture
            }
        }
        receiver {
            id
            fullName
            profile {
                id
                phoneNumber
                secondaryPhoneNumber
                picture
            }
        }
        fromNumber
        toNumber
        recordingUrl
        outsideOfficeHours
        isEmergency
        missed
        department {
            id
            name
            icon
        }
        resolvedBy {
            id
        }
    }
`

export const FULL_CALL_FRAGMENT = gql`
    fragment AllCallFields on CallType {
        ...CallFields
        summary
        errorMessage
        resolvedBy {
            id
            fullName
            picture
        }
        transferredFromCall {
            id
            callSid
            fromNumber
            caller {
                id
                fullName
            }
            toNumber
            receiver {
                id
                fullName
            }
        }
        transferredToCall {
            id
            callSid
            fromNumber
            caller {
                id
                fullName
            }
            toNumber
            receiver {
                id
                fullName
            }
        }
    }
    ${CALL_FRAGMENT}
`
