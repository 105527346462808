import { useState, useEffect, useRef } from 'react'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { GET_UNREAD_NOTIFICATIONS, GET_READ_NOTIFICATIONS } from '../queries'
import { MARK_ALL_NOTIFICATIONS_READ } from '../mutations'
import { notificationCountersVar } from '../../cache'
import { OnScrollLoader } from '../../components'
import { Notification } from './NotificationItem'


const Notifications = () => {
    const containerEl = useRef(null)
    const [shouldFetchMore, setFetchMore] = useState(false)
    const [shouldFetchMoreRead, setFetchMoreRead] = useState(false)
    const { data, error, loading, fetchMore } = useQuery(GET_UNREAD_NOTIFICATIONS, { variables: { limit: 20 } })
    const [markAllAsRead] = useMutation(MARK_ALL_NOTIFICATIONS_READ)
    const [loadReadNotifications, { data:readNotificationData, error:readNotificationError, loading: loadingReadNotifications, fetchMore: fetchMoreReadNotifications }] = useLazyQuery(GET_READ_NOTIFICATIONS, { variables: { limit: 20 } })
    const hasUnreadNotifications = data && data.unreadNotifications && data.unreadNotifications.length > 0
    const markAsRead = () => {
        markAllAsRead({
            update(cache) {
                notificationCountersVar({ ...notificationCountersVar(), unreadNotificationsCount: 0 })
                let prevNotifications = []

                cache.modify({
                    fields: {
                        unreadNotifications(existingNotifications) {
                            prevNotifications = existingNotifications
                            return []
                        },
                        readNotifications(existingNotifications=[], { toReference }) {
                            return [...prevNotifications, ...existingNotifications]
                        }
                    }
                })
            }
        })
    }

    const onScroll = (loadMore, queryFieldName, offset) => {                
        loadMore({
            variables: { limit: 20, offset }
        }).then(fetchMoreResult => {
            if (fetchMoreResult.data && fetchMoreResult.data[queryFieldName]) {
                if (fetchMoreResult.data[queryFieldName].length === 0) {
                    setFetchMore(false)
                }
            }
        })
    }

    useEffect(() => {
        if (data?.unreadNotifications) {
            if (data.unreadNotifications.length) {
                const hasMore = !!data.unreadNotifications[data.unreadNotifications.length - 1].hasMore
                if (hasMore !== shouldFetchMore) setFetchMore(hasMore)
            }
        }
    }, [data?.unreadNotifications])

    useEffect(() => {
        if (readNotificationData?.readNotifications) {
            if (readNotificationData.readNotifications.length) {
                const hasMore = !!readNotificationData.readNotifications[readNotificationData.readNotifications.length - 1].hasMore
                if (hasMore !== shouldFetchMoreRead) setFetchMoreRead(hasMore)
            }
        }
    }, [readNotificationData?.readNotifications])

    return (
        <div className='tab__panel tab__panel--notifications'>
            <div className='tab__panel--main bg-lightgrey' ref={containerEl}>
                <div>
                    <h2 className='t-heading t-lg--responsive mb-2 mt-2'>
                        Unread Notifications:
                        {hasUnreadNotifications &&
                            <button
                                className='btn btn--tertiary float-right'
                                onClick={markAsRead}
                            >
                                Mark all as read
                            </button>
                        }
                    </h2>
                    {hasUnreadNotifications ?
                        data.unreadNotifications.map(
                            notification => <Notification key={notification.id} notification={notification} />
                        )
                    : loading ?
                        <><br/><div className='loader pos-center-y'/></>
                    : error ?
                        <div className='t-red t-xs'>{error}</div>
                    :
                        <span>No unread notifications</span>
                    }
                    {shouldFetchMore && !loading &&
                        <OnScrollLoader
                            onIntersect={() => onScroll(fetchMore, 'unreadNotifications', data?.unreadNotifications?.length)}
                            root={containerEl.current}
                        />
                    }
                </div>
                <br/>
                <br/>
                <div>
                    {(readNotificationData && readNotificationData.readNotifications) ? 
                        <div>
                            <h2 className='t-heading t-lg--responsive mb-2'>
                                Read Notifications:
                            </h2>
                            <div>
                            {
                                readNotificationData.readNotifications.map(
                                    notification => <Notification key={notification.id} notification={notification} isRead={true} />
                                )
                            }
                            </div>
                        </div>
                    : loadingReadNotifications ?
                        <><br/><div className='loader pos-center-y'/></>
                    : readNotificationError ?
                        <div className='t-red t-xs'>{readNotificationError}</div>
                    :
                        <button
                            className='btn btn--secondary mt-2'
                            onClick={loadReadNotifications}
                        >
                            Show read notifications
                        </button>
                    }
                    {shouldFetchMoreRead && !loadingReadNotifications &&
                        <OnScrollLoader
                            onIntersect={() => onScroll(fetchMoreReadNotifications, 'readNotifications', readNotificationData?.readNotifications?.length)}
                            root={containerEl.current}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default Notifications
