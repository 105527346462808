import { useState, useRef, useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { ADD_SERVICE_PAYMENT } from '../mutations'
import { Accordion, Icon, Label } from '../../components'
import DateString from '../../components/DateString'
import ServiceInfoForm from './ServiceInfoForm'
import ServicePaymentForm from './ServicePaymentForm'
import { lbl } from '../../constants'
import { newDraftsVar } from '../../cache'
import { dateInPast, toTitleCase } from '../../utils'
import ServiceMeter from './ServiceMeter'


const ServiceInfo = ({
    service={},
    draft,
    setDraft,
    submitMutation,
    close=()=>{},
}) => {
    const { hash } = useLocation()
    const servicePaymentEl = useRef()
    const serviceEl = useRef()
    const [newServicePaymentOpen, setNewServicePaymentOpen] = useState(false)
    let { servicePayments } = useReactiveVar(newDraftsVar)
    let servicePaymentDraft = service.id ? { amount: service.price, ...servicePayments[service.id] } || {} : {}

    useLayoutEffect(() => {
        if (hash && hash.includes('#service_payment') && servicePaymentEl?.current) {
            setTimeout(() => servicePaymentEl.current.scrollIntoView({
                behavior: 'smooth', block: 'start'
            }), 100)
        }
    }, [hash, servicePaymentEl?.current])

    useLayoutEffect(() => {
        if (hash && hash.includes('#service') && !hash.includes('#service_payment') && serviceEl?.current) {
            setTimeout(() => serviceEl.current.scrollIntoView({
                behavior: 'smooth', block: 'start'
            }), 100)
        }
    }, [hash, serviceEl?.current])

    return (
        <div className='bg-light mb-2 br-1'>
            <Accordion
                Tag='div'
                className={`bg-light p-1 br-05 ${service.archived ? ' inactive' : ''}`}
                headerClass='t-md t-heading mr-3'
                isOpen
                label={
                    <div className='d-flex align-center'>
                        {service.type.emoji ?
                            <div className='mr-1'>{service.type.emoji}</div>
                        :
                            <Icon type={service.type.name.toLowerCase()} variant='sm'wrapperClassAdditions='mr-05'/>
                        }
                        <span>{toTitleCase(service.type.name)}</span>
                        {service.archived &&
                            <Label
                                classModifier='grey'
                                className='ml-1 label--uppercase label--archived'
                                iconProps={{ type: 'archive', variant: '3xs' }}
                                label={lbl.archived}
                            />
                        }
                    </div>
                }
            >
                <div
                    className='mt-1'
                    id={`service_${service.id}`}
                    ref={hash === `#service_${service.id}` ? serviceEl : undefined}
                >
                    <Accordion
                        key='serviceInfo'
                        Tag='div'
                        isOpen={hash === `#service_${service.id}`}
                        headerClass='accordion__header--bordered t-s t-heading p-1 t-darkgrey'
                        label='Service Info'
                        toggleOnHeaderClick
                    >
                        <div className='ml-6 mr-1 mb-2'>
                            <ServiceInfoForm
                                service={service}
                                draft={draft}
                                submitMutation={submitMutation}
                                close={close}
                                setDraft={setDraft}
                            />
                        </div>
                    </Accordion>
                    <Accordion
                        key='servicePayments'
                        isOpen={service?.payments?.map(({ id }) => `#service_payment_${id}`).includes(hash)}
                        Tag='div'
                        headerClass='accordion__header--bordered t-s t-heading p-1 t-darkgrey'
                        label='Payments'
                        toggleOnHeaderClick
                    >
                        <div className='ml-5 mr-1 mb-2'>
                            {service?.payments?.map((servicePayment) => (
                                <Accordion
                                    key={'servicePayment' + servicePayment.id + servicePayment.archived}
                                    isOpen={hash === `#service_payment_${servicePayment.id}`}
                                    Tag='div'
                                    className={`bg-lightgrey--open br-05 mb-05${!servicePayment.archived ? '' : ' inactive'}`}
                                    headerClass='t-s t-heading p-1'
                                    label={
                                        <div>
                                            <div className='d-flex justify-space-between'>
                                                <div className='d-inline-flex align-center'>
                                                    <DateString dateStr={servicePayment.periodStartDate} fadeInPast={false} />
                                                    <span className='ml-1 mr-1'>-</span>
                                                    <DateString dateStr={servicePayment.periodEndDate} fadeInPast={false} />
                                                    {servicePayment.archived &&
                                                        <Label
                                                            classModifier='grey'
                                                            className='ml-1 label--uppercase label--archived'
                                                            iconProps={{ type: 'archive', variant: '3xs' }}
                                                            label={lbl.archived}
                                                        />
                                                    }
                                                </div>
                                                <div className='d-inline-flex align-center'>
                                                    {servicePayment.amount &&
                                                        <strong className='mr-1'>€ {servicePayment.amount}</strong>
                                                    }
                                                    <Icon
                                                        variant='xs'
                                                        type={servicePayment.paidBy || servicePayment.paymentDate ? 'checkmark-double' : 'clock'}
                                                        fill={servicePayment.paidBy || servicePayment.paymentDate ? 'green' : dateInPast(servicePayment.dueDate) ? 'red' : 'grey'}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                >
                                    <div 
                                        className='ml-3 mr-3 mt-3 pb-1'
                                        id={`service_payment_${servicePayment.id}`}
                                        ref={hash === `#service_payment_${servicePayment.id}` ? servicePaymentEl : undefined}
                                    >
                                        <ServicePaymentForm servicePayment={servicePayment} />
                                    </div>
                                </Accordion>
                            ))}
                            {newServicePaymentOpen ?
                                <div className='pb-2'>
                                    <Accordion
                                        isOpen
                                        Tag='div'
                                        className='bg-lightblue pb-1 br-05 mb-1'
                                        headerClass='t-heading t-s p-1'
                                        label={<div className='ml-05'>{`${lbl.new} ${service.type.name} ${lbl.payment}`}</div>}
                                        onOpenCb={() => { setNewServicePaymentOpen(false) }}
                                    >
                                        <div className='ml-3 mr-3 mt-2'>
                                            <ServicePaymentForm
                                                draft={servicePaymentDraft}
                                                submitMutation={ADD_SERVICE_PAYMENT}
                                                mutationVariables={{ serviceId: service.id }}
                                                close={() => { setNewServicePaymentOpen(false) }}
                                                setDraft={paymentDraft => {
                                                    const drafts = { ...newDraftsVar()}
                                                    drafts.servicePayments[service.id] = paymentDraft
                                                    newDraftsVar(drafts)
                                                }}
                                            />
                                        </div>
                                    </Accordion>
                                </div>
                            : (service.id && !service.archived) ?
                                <div className='mt-1 pb-2'>
                                    <button
                                        className='btn btn--naked btn--new-item w-100'
                                        onClick={() => { setNewServicePaymentOpen(true) }}
                                    >
                                        <span className="plus">+</span>
                                        {lbl.addNewPayment}
                                    </button>
                                </div>
                            : null }
                        </div>
                    </Accordion>
                    {service?.type.isUtility &&
                        <ServiceMeter service={service}/>
                    }
                </div>
            </Accordion>
        </div>
    )
}

export default ServiceInfo
