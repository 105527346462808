import { gql } from '@apollo/client'
import { BASIC_NOTE_FRAGMENT } from '../notes/fragments'

export const GET_THREADS_BY_SEARCH_TERM = gql`
    query GET_THREADS_BY_SEARCH_TERM($searchTerm: String!, $offset: Int, $limit: Int) {
        threadsBySearchTerm(searchTerm: $searchTerm, offset: $offset, limit: $limit) {
            id
            name
            messagesBySearchTerm(searchTerm: $searchTerm) {
                id
                title
                body
                sentAt
                sender {
                    id
                    fullName
                }
            }
            hasMore
        }
    }
`

export const GET_TICKETS_BY_SEARCH_TERM = gql`
    query GET_TICKETS_BY_SEARCH_TERM($searchTerm: String!, $offset: Int, $limit: Int) {
        ticketsBySearchTerm(searchTerm: $searchTerm, offset: $offset, limit: $limit) {
            id
            name
            description
            createdDate
            owner {
                id
                fullName
            }
            notesBySearchTerm(searchTerm: $searchTerm) {
                ...BasicNoteFields
            }
            hasMore
        }
    }
    ${BASIC_NOTE_FRAGMENT}
`

export const GET_TASKS_BY_SEARCH_TERM = gql`
    query GET_TASKS_BY_SEARCH_TERM($searchTerm: String!, $offset: Int, $limit: Int) {
        tasksBySearchTerm(searchTerm: $searchTerm, offset: $offset, limit: $limit) {
            id
            name
            description
            createdDate
            assignee {
                id
                fullName
            }
            notesBySearchTerm(searchTerm: $searchTerm) {
                ...BasicNoteFields
            }
            hasMore
        }
    }
    ${BASIC_NOTE_FRAGMENT}
`

export const GET_USERS_BY_SEARCH_TERM = gql`
    query GET_USERS_BY_SEARCH_TERM($searchTerm: String!, $offset: Int, $limit: Int) {
        usersBySearchTerm(searchTerm: $searchTerm, offset: $offset, limit: $limit) {
            id
            fullName
            picture
            email
            notesBySearchTerm(searchTerm: $searchTerm) {
                ...BasicNoteFields
            }
            hasMore
        }
    }
    ${BASIC_NOTE_FRAGMENT}
`

export const GET_PROPERTIES_BY_SEARCH_TERM = gql`
    query GET_PROPERTIES_BY_SEARCH_TERM($searchTerm: String!, $offset: Int, $limit: Int) {
        propertiesBySearchTerm(searchTerm: $searchTerm, offset: $offset, limit: $limit) {
            id
            fullAddress
            notesBySearchTerm(searchTerm: $searchTerm) {
                ...BasicNoteFields
            }
            hasMore
        }
    }
    ${BASIC_NOTE_FRAGMENT}
`

export const GET_TEMPLATES_BY_SEARCH_TERM = gql`
    query GET_TEMPLATES_BY_SEARCH_TERM($searchTerm: String!, $offset: Int, $limit: Int) {
        templatesBySearchTerm(searchTerm: $searchTerm, offset: $offset, limit: $limit) {
            id
            name
            body
            createdBy {
                id
                fullName
            }
            createdAt
            hasMore
        }
    }
`

