import { gql } from '@apollo/client'
import { FULL_CALL_FRAGMENT, CALL_FRAGMENT } from './fragments'

export const SET_RECEIVER_ID = gql`
    mutation SetReceiverId($callSid: String!, $receiverId: ID!, $phoneNumber: String) {
        setReceiverId(callSid: $callSid, receiverId: $receiverId, phoneNumber: $phoneNumber) {
            call {
                ...AllCallFields
            }
        }
    }
    ${FULL_CALL_FRAGMENT}
`

export const SET_CALLER_ID = gql`
    mutation SetCallerId($callSid: String!, $callerId: ID!, $phoneNumber: String) {
        setCallerId(callSid: $callSid, callerId: $callerId, phoneNumber: $phoneNumber) {
            call {
                ...AllCallFields
            }
        }
    }
    ${FULL_CALL_FRAGMENT}
`

export const SET_CALL_INFO = gql`
    mutation SetCallInfo($callSid: String!, $subject: String, $summary: String, $departmentId: ID) {
        setCallInfo(callSid: $callSid, subject: $subject, summary: $summary, departmentId: $departmentId) {
            call {
                ...AllCallFields
            }
        }
    }
    ${FULL_CALL_FRAGMENT}
`

export const RESOLVE_CALL = gql`
    mutation ResolveCall($callSid: String!) {
        resolveCall(callSid: $callSid) {
            call {
                ...AllCallFields
            }
        }
    }
    ${FULL_CALL_FRAGMENT}
`

export const TRANSFER_CALL_TO_DEPARTMENT = gql`
    mutation TransferCallToDepartment($callSid: String!, $departmentId: ID!) {
        transferCallToDepartment(callSid: $callSid, departmentId: $departmentId) {
            call {
                ...CallFields
            }
        }
    }
    ${CALL_FRAGMENT}
`

export const TRANSFER_CALL_TO_EMPLOYEE = gql`
    mutation TransferCallToEmployee($callSid: String!, $employeeUserId: ID!, $employeeUsername: String!) {
        transferCallToEmployee(callSid: $callSid, employeeUserId: $employeeUserId, employeeUsername: $employeeUsername) {
            call {
                ...CallFields
            }
        }
    }
    ${CALL_FRAGMENT}
`
