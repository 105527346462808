import { useLocation, useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { ARCHIVE_CONTRACT, UPDATE_CONTRACT } from '../mutations'
import { GET_CONTRACT_TYPES } from '../queries'
import FormWithCustomFields from '../../forms/FormWithCustomFields'
import { lbl } from '../../constants'


const ContractInfoForm = ({
    contract={},
    extraFields=[],
    draft,
    submitMutation=UPDATE_CONTRACT,
    archiveMutation=ARCHIVE_CONTRACT,
    mutationVariables={},
    onCompleted,
    close=()=>{},
    setDraft,
    contentType='tenantcontract',
    prefilledCosts={}
}) => {
    const history = useHistory()
    const { pathname } = useLocation()
    const { data: contractTypeData, error: contractTypeError } = useQuery(GET_CONTRACT_TYPES)

    const initialState = draft || {
        ...contract,
        typeId: contract.type?.id,
        ownerId: contract.owner?.id,
        buildingIds: contract.buildings?.map(({id}) => id),
        addressIds: contract.properties?.map(({id}) => id),
        roomIds: contract.units?.map(({id}) => id)
    }

    let [isIndefinite, setIsIndefinite] = useState(initialState.forIndefinitePeriod)
    let [changedPrefilledFields, setChanged] = useState([])
    const [updateContract, { loading, done, called, data, error }] = useMutation(submitMutation)
    const [archiveContract, { loading: archiving }] = useMutation(archiveMutation)

    const success = !error && !loading && called && !!data
    
    let contractTypeOptions = contractTypeData?.contractTypes ? contractTypeData.contractTypes.map(({ id, name }) => ({ value: id, label: name })) : [
        { value: contract.type?.id, label: contract.type?.name },
        { value: 'contractType-x', label: contractTypeError?.message || 'Loading ...' }
    ]

    const getCostFieldProps = (f) => {
        const costFieldProps = {
            type: 'decimal',
            onChangeCb: (v, isProgramaticChange) => {
                if (!isProgramaticChange && !changedPrefilledFields.includes(f)) {
                    setChanged([...changedPrefilledFields, f])
                }
            }
        }
        if (!changedPrefilledFields.includes(f)) {
            costFieldProps.programaticValue = prefilledCosts[f]
        } else {
            costFieldProps.programaticValue = undefined
        }
        return costFieldProps
    }

    const contractEndDateStr = contract.endDate ? new Date(contract.endDate).toLocaleDateString() : ''

    const alertOnEndDateChange = (v) => {
        if (!contract.id) return
        if (!contract.endDate) {
            alert(`Changing the end date will automatically set "Rent responsibility end date" and "Check-out date" on all previouslly indefinite tenancy agreements`)
        } else if (v) {
            alert(`Changing the end date will automatically update "Rent responsibility end date" and "Check-out date" on all tenancy agreements if they were previously set to ${contractEndDateStr}`)
        } else {
            alert(`Removing contract end date will automatically remove "Rent responsibility end date" and "Check-out date" from all tenancy agreements where both were previously set to ${contractEndDateStr}. Those tenancy agreements will be marked as indefinite.`)
        }
    }

    const onIndefiniteChange = (v) => {
        setIsIndefinite(v)
        if (contract.id && v) alert(`Setting contract to indefinite will automatically remove "Contract end date" as well as "Rent responsibility end date" and "Check-out date" on all tenancy agreements where both were previously set to ${contractEndDateStr}. Those tenancy agreements will also be marked as indefinite.`)
    }


    const fields = [
        contentType !== 'ownercontract' && { name: 'typeId', lblKey: 'contractType', As: 'select', options: contractTypeOptions },
        { name: 'forIndefinitePeriod', type: 'checkbox', onChangeCb: onIndefiniteChange, wide: contentType !== 'ownercontract' && extraFields.length % 2 === 1 },
        { name: 'startDate', type: 'date', required: true },
        { name: 'endDate', type: 'date', required: !isIndefinite, disabled: isIndefinite, ...isIndefinite ? { value: '' } : {}, onChangeCb: alertOnEndDateChange },
        { name: 'monthlyRent', BeforeNode: () => <><div className='t-sm mt-2 mb-2'>Costs</div><br/></>, ...getCostFieldProps('monthlyRent') },
        { name: 'monthlyServiceCost', ...getCostFieldProps('monthlyServiceCost') },
        { name: 'serviceCostDescription', As: 'textarea', wide: true },
        { name: 'otherCosts', ...getCostFieldProps('otherCosts') },
        { name: 'deposit', ...getCostFieldProps('deposit') },
    ].filter(Boolean)

    return (
        <FormWithCustomFields
            key={contract.id + initialState.buildingIds + initialState.addressIds + initialState.roomIds}
            contentType={contentType}
            objectId={contract.id}
            fields={[...extraFields, ...fields]}
            initialState={initialState}
            onSubmit={(data, cancelChanges, saveCustomFields) => {
                const _data = {...data}
                if (data.forIndefinitePeriod) _data['endDate'] = ''
                if (data.endDate) _data['forIndefinitePeriod'] = false
                updateContract({
                    variables: { id: contract.id, ..._data, ...mutationVariables },
                    onError: cancelChanges,
                    onCompleted: (c) => {
                        if (onCompleted) onCompleted(c)
                        if (c && saveCustomFields) {
                            saveCustomFields(c[contentType === 'ownercontract' ? 'createOwnerContract' : 'createContract'].contract.id)
                        }
                        if (setDraft) setDraft({})
                        if (!!c?.createContract) {
                            const url = `${pathname}#contract_${c.createContract.contract.id}`
                            history.push(url)
                        } else if (!!c?.createOwnerContract) {
                            const url = `${pathname}#owner_contract_${c.createOwnerContract.contract.id}`
                            history.push(url)
                        }
                        close()
                    },
                })
            }}
            onCancel={close}
            isSubmitting={archiving || (loading && !done)}
            disabled={contract.archived}
            isSuccess={success}
            error={error?.message}
            setDraft={setDraft}
            formClassName='form--column--2'
            actions={(contract.id && !contract.archived) ? [{
                label: lbl.archive,
                onClick: () => {
                    if ( window.confirm('Are you sure you want to archive this contract?')) {
                        archiveContract({ variables: { id: contract.id }})
                    }
                }
            }] : []}
        />
    )
}

export default ContractInfoForm
