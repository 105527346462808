import { getCsrfCookie } from './index'

export const postAsFormData = async ({
    url='/upload/',
    data,
    onSuccess,
    onError,
    onDone,
    options={}
}) => {
    const formData = new FormData();
    const fullUrl = window._env_.REACT_APP_REST_ENDPOINT + url

    Object.keys(data).forEach(key => formData.append(key, data[key]))

    const token = getCsrfCookie()
    formData.append('csrfmiddlewaretoken', token)

    const _options = {
        method: 'POST',
        body: formData,
        credentials: 'include',
        ...options
    };
    
    try {
        const response = await fetch(fullUrl, _options)
        try {
            const data = await response.json()

            if (response.status >= 300) {
                throw new Error(data?.file || response.statusText)
            } else if (data && onSuccess) {
                onSuccess(data)
            }
        } catch (error) {
            console.debug(error)
            onError && onError(error)
        }
    } catch (error) {
        onError && onError(error)
    } finally {
        onDone && onDone()
    }
};
