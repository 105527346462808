import { useState } from 'react'
import { Icon } from '../../components'
import NotesFeed from '../../notes/components/NotesFeed'
import { GET_NOTES_FOR_INSTANCES, GET_PINNED_NOTES_FOR_CONTENT_OBJECTS } from '../../notes/queries'

const PropertyNotes = ({
    contentType,
    objectId,
    building,
    addresses,
    units,
    owner
}) => {
    let initialState = { [contentType]: [objectId] }
    if (owner?.user) initialState = { ...initialState, user: [owner.user.id] }
    let [contentObjects, setContentObjects] = useState(initialState)

    return (
        <div className='pos-relative'>
            <div className='property-level-filters d-flex justify-end pos-absolute'>
                {[['building', [building]], ['address', addresses], ['room', units], ['user', [owner?.user].filter(Boolean)]].map(([type, objects], i) => {
                    const isOn = !!contentObjects[type]
                    const iconType = type === 'user' ? 'landlord' : type

                    return (
                        <button
                            key={type}
                            disabled={type === contentType || !objects}
                            className={`btn btn--naked btn--icon ml-1 br-05${isOn ? ' active': ''}`}
                            title={`${isOn ? 'exclude' : 'include'} ${iconType} notes`}
                            onClick={() => setContentObjects(prev => {
                                if (isOn) {
                                    const newState = {...prev}
                                    delete newState[type]
                                    return newState
                                } else {
                                    return ({ ...prev, [type]: [...prev[type] ? prev[type] : [], ...objects.map(({ id }) => id )] })
                                }
                            })}
                        >
                            <Icon type={iconType} variant='sm'/>
                        </button>
                    )
                })}
            </div>
            <NotesFeed
                contentType={contentType}
                objectId={objectId}
                query={GET_NOTES_FOR_INSTANCES}
                queryName='notesForContentObjects'
                queryVariables={{ contentObjects: Object.keys(contentObjects).sort().map(type => ({ contentType: type, objectIds: contentObjects[type].sort() })) }}
                pinnedNotesQuery={GET_PINNED_NOTES_FOR_CONTENT_OBJECTS}
                pinnedNotesVariables={{ contentObjects: [
                    { contentType, objectIds: [objectId] },
                    { contentType: 'user', objectIds: owner?.user?.id ? [owner.user.id] : [] },
                ] }}
                showAnnotations
            />
        </div>
    )
}

export default PropertyNotes
