import { Link } from 'react-router-dom'
import { ProfileImage, Label } from '../../components'
import { lbl, tabNavLinks } from '../../constants'

const TaskCreator = ({ id, fullName, picture, createdDateLabel, createdDateOffset }) => (
    <section className='task__creator'>
        <div className='d-flex mt-2 mb-1'>
            <div className='d-inline-flex mr-auto'>
                <ProfileImage url={picture} size='md' />
                <div className='ml-2'>
                    <div className='t-grey t-xs'>{lbl.createdBy}</div>
                    <Link
                        to={tabNavLinks.contacts.dynamicPath + id}
                        title={fullName}
                        className='t-underline-hover t-sm mb-05'
                    >
                        {fullName}
                    </Link>
                </div>
            </div>
            <Label
                className='time-label my-auto'
                iconProps={{ type: 'time' }}
                label={createdDateLabel}
                classModifier={createdDateOffset < -2 ? 'red' : createdDateOffset < -1 ? 'yellow' : 'grey'}
            />
        </div>
    </section>
)

export default TaskCreator
