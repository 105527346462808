import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_MENTIONS } from '../queries'
import Message from './Message'
import ThreadLabel from './ThreadLabel'
import Counter from '../../components/Counter'
import { tabNavLinks } from '../../constants'
import { formatDate } from '../../utils/stringUtils'


const MentionsOverview = () => {
    const { data, loading, error } = useQuery(GET_MENTIONS)

    if (loading) return <div className='loader pos-center-y' />
    if (error) console.error(error)
    if (data && data.mentions.length === 0) return (
        <div className='t-grey w-100 mx-auto my-auto'>
            <center>You have no mentions</center>
        </div>
    )
    if (data) return (
        <div className='split-panel--main p-0'>
            <ul className='mentions__list'>
                {data.mentions.map(msg => {
                    const sentAtDate = new Date(msg.sentAt)
                    const sentDayLabel = formatDate(msg.sentAt)?.label
                    const sentTimeLabel = sentAtDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    
                    return (
                        <li className='mentions__listitem' key={`mention-${msg.id}`}>
                            <Link to={`${tabNavLinks.messenger.dynamicPath}${msg.thread.id}#${msg.id}`}>
                                <div className='d-flex t-grey mb-2'>
                                    <div className='d-flex t-sm'>
                                        {!msg.isRead && !!msg.thread.unreadCount && <Counter isInline />}
                                        <strong>{msg.sender?.fullName}</strong>
                                        <span className='ml-05 mr-05'>mentioned you in</span>
                                        {msg.thread.isDirect ? 'a direct message' : <ThreadLabel icon={msg.thread.icon || 'hash'} name={msg.thread.name} />}
                                    </div>
                                    <div className='ml-auto t-xs'>
                                        {`${sentDayLabel}, ${sentTimeLabel}`}
                                    </div>
                                </div>
                                <Message {...msg} as='div' threadId={msg.thread.id} hideTime />
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
    return null
}

export default MentionsOverview
