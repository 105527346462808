import { gql } from '@apollo/client'
import { TENANCY_AGREEMENT_FRAGMENT } from '../properties/fragments'

export const CONTACT_FRAGMENT = gql`
    fragment AllUserFields on UserType {
        id
        onlineStatus
        type
        isStaff
        firstName
        lastName
        fullName
        email
        profile {
            id
            picture
            onlineStatus
            phoneNumber
            secondaryPhoneNumber
            gender
            salutation
            employmentStatus
            country
            nationality
            language
            dob
            secondaryEmail
            address
        }
        tenant {
            id
            about
            hobbies
            bsnNumber
            countryOfOrigin
            countryOfResidence
            degreeTitle
            graduationDate
            relationshipStatus
            secondaryEmail
            emergencyContactName
            emergencyContactNumber
            emergencyContactAddress
            emergencyContactRelationship
            emergencyContactEmail
            emergencyContactLanguage
            facebook
            instagram
            tiktok
            linkedin
            tenancyStatus
            activeTenancyAgreement {
                ...TenancyAgreementFields
                unit {
                    id
                    fullUnitName
                    longName
                }
                contract {
                    id
                    type {
                        id
                        name
                    }
                    startDate
                    endDate
                    forIndefinitePeriod
                    monthlyRent
                    monthlyServiceCost
                    otherCosts
                    deposit
                    property {
                        id
                        longName
                        serviceCostDivisionModel
                        sizeSqm
                        monthlyServiceCost
                        otherCosts
                        rooms {
                            id
                            fullUnitName
                        }
                    }
                    unit {
                        id
                        longName
                        fullUnitName
                        monthlyBasicRent
                        monthlyServiceCost
                        deposit
                        sizeSqm
                        address {
                            id
                            longName
                        }
                    }
                    contractTenancyAgreements {
                        id
                        active
                        archived
                    }
                }
            }
            activeLead {
                id
                archived
                preferredCheckInDate
                budget
                property {
                    id
                    longName
                    rooms {
                        id
                        fullUnitName
                    }
                }
                unit {
                    id
                    longName
                    fullUnitName
                }
            }
        }
        partner {
            id
            company {
                id
                name
            }
        }
        landlord {
            id
            mandateAmount
        }
        employee {
            id
        }
    }
    ${TENANCY_AGREEMENT_FRAGMENT}
`

export const CONTACT_LIST_FRAGMENT = gql`
    fragment ContactListerFields on UserType {
        id
        fullName
        type
        onlineStatus
        tenancyStatus
        rentResponsabilityEndDate
        unit
        address
        city
        profile {
            id
            picture
            onlineStatus
        }
    }
`
