import React, { useEffect, useState, useCallback, Suspense } from 'react'
import { createPortal } from 'react-dom'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { DELETE_ATTACHMENT } from '../mutations'
import { postAsFormData } from '../../utils'
import { listerColumns, listerCtrlOptions, attachmentProperties } from '../settings'
import { attachmentListCtrlsVar, ocpVar } from '../../cache'
import { lbl } from '../../constants'
import { Icon } from '../../components'
import Lister from '../../components/Lister'
import AttachmentItem from './AttachmentItem'

const FilesCarousel = React.lazy(() => import('./FilesCarousel'))


const AttachmentsLister = ({
    query,
    queryVariables={},
    queryFieldName='attachments',
    contentTypeString='users | profile',
    contentObjectId,
    onUploaded,
    columns=listerColumns,
    ctrlOptions=listerCtrlOptions,
    disableUpload,
    ...props
}) => {
    const listCtrlVar = props.listCtrlVar || attachmentListCtrlsVar
    const vars = useReactiveVar(listCtrlVar)
    let ocp = useReactiveVar(ocpVar)
    const filters = vars.filters.map(f => `${f.field}___${f.id}`).sort().join(',')
    let variables = { ...queryVariables, ...vars, filters }
    const [isUploading, setIsUploading] = useState(false)
    const [uploadError, setUploadError] = useState('')
    const [initialSlideIdx, setInitialSlideIdx] = useState(-1)
    const [highlightItemIdx, highlightItem] = useState(-1)

    const { data, error, loading, refetch, fetchMore } = useQuery(query, { variables })
    const items = data ? data[queryFieldName] : undefined

    const setHighlightItem = useCallback(highlightItem, [])

    const openOcp = (index) => {
        highlightItem(index)
        setInitialSlideIdx(index)
        if (!ocp.isOpen) {
            ocpVar({ ...ocp, defaultWidth: 600, isOpen: true, type: 'attachments' })
        }
        document.getElementsByClassName('carousel-root')[0]?.focus()
    }

    const submitForm = (e) => {
        setIsUploading(true)
    
        postAsFormData({
            url: '/upload/',
            data: {
                file: e.target.files[0],
                content_type_str: contentTypeString,
                object_id: contentObjectId
            },
            onSuccess: () => {
                refetch()
                if (uploadError) setUploadError('')
                if (onUploaded) onUploaded()
            },
            onError: (error) => {
                setUploadError(error.message || 'File upload failed')
            },
            onDone: () => {
                setIsUploading(false)
            }
        })
    }

    const [deleteAttachment, { client }] = useMutation(DELETE_ATTACHMENT, {
        onCompleted: ({ deleteAttachment: { deletedId } }) => {
            client.cache.data.delete(`AttachmentType:${deletedId}`)
            refetch()
        }
    })

    const renderAttachmentsList = () => {
        if (error) return <div className='pos-center-y w-100 t-center'>{error.message}</div>

        return (
            <Lister
                {...props}
                options={ctrlOptions}
                optionsDict={attachmentProperties}
                listerCtrlVar={listCtrlVar}
                columnKeys={columns}
                items={items}
                showLoader={loading}
                fetchMore={fetchMore}
                queryFieldName={queryFieldName}
                queryVariables={queryVariables}
                query={query}
                showFirstColumnLabel={columns[0] !== 'contentType'}
                type='attachments'
            >
                {(itemProps, index) => (
                    <AttachmentItem
                        key={(itemProps.originalFilename || itemProps.fileName) + itemProps.index + itemProps.id}
                        propKeys={columns}
                        deleteAttachment={deleteAttachment}
                        listCtrlVar={listCtrlVar}
                        openOcp={() => openOcp(index)}
                        isInCurrentSlide={index === highlightItemIdx}
                        {...itemProps}
                    />
                )}
            </Lister>
        )
    }

    useEffect(() => {
        return () => {
            if (ocp.isOpen) ocpVar({ ...ocp, isOpen: false })
        }
    }, [])

    return (
        <React.Fragment>
            {uploadError && <div className='w-100 t-red pl-r mb-4 mt-1 w-80'>{uploadError}</div>}
            {!disableUpload &&
                <form className='upload-file-wrapper'>
                    <label className='upload-file-label' htmlFor='file-input'>
                        <button
                            disabled={isUploading}
                            className='btn btn--primary btn--inline-icon btn--rounded t-none upload-file-button'
                        >
                            {isUploading ?
                                <div className='loader loader--inline' />
                            : 
                                <><Icon type='plus' variant='sm' />{lbl.upload}</>
                            }
                        </button>
                    </label>
                    <input
                        id='file-input'
                        style={{ display: 'none' }}
                        type='file'
                        disabled={isUploading}
                        onChange={submitForm}
                    />
                </form>
            }
            {renderAttachmentsList()}
            {!!items && initialSlideIdx > -1 && createPortal(
                <Suspense fallback={<div className='loader pos-center-y'/>}>
                    <FilesCarousel
                        files={items}
                        initialSlideIdx={initialSlideIdx}
                        onChange={setHighlightItem}
                    />
                </Suspense>,
                ocp.containerEl
            )}
        </React.Fragment>
    )
}

export default AttachmentsLister
