import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { GET_USER_PUBLIC_THREADS } from '../../messenger/queries'
import ThreadList from '../../messenger/components/ThreadList'
import { OnScrollLoader } from '../../components';

const UserThreads = ({ userId }) => {
    const containerEl = document.querySelector('.intersection-root')
    const [shouldFetchMore, setFetchMore] = useState(false)
    const { data, error, loading, fetchMore } = useQuery(GET_USER_PUBLIC_THREADS, { 
        variables: { id: userId, limit: 30 }
    })

    const onScroll = () => {   
        fetchMore({
            variables: { id: userId, limit: 30, offset: data?.userPublicThreads?.length }
        }).then(fetchMoreResult => {
            if (fetchMoreResult.data?.userPublicThreads) {
                if (fetchMoreResult.data.userPublicThreads.length === 0) {
                    setFetchMore(false)
                }
            }
        })
    }

    useEffect(() => {
        if (data?.userPublicThreads) {
            if (data.userPublicThreads.length) {
                const hasMore = !!data.userPublicThreads[data.userPublicThreads.length - 1].hasMore
                if (hasMore !== shouldFetchMore) setFetchMore(hasMore)
            }
        }
    }, [data?.userPublicThreads])

    return (
        <div className='tab__panel--main'>
            {(data && !!data.userPublicThreads.length) ?
                <div>
                    <ThreadList threads={data.userPublicThreads} accordionClosed />
                    {shouldFetchMore && !loading && containerEl &&
                        <OnScrollLoader
                            onIntersect={onScroll}
                            root={containerEl}
                        />
                    }
                </div>
            : loading ?
                <div className='loader pos-center-y' />
            : error ?
                <div className='t-red t-xs'>{error}</div>
            : (data && data.userPublicThreads.length === 0) ?
                <div className='t-grey w-100 mx-auto my-auto'>
                    <center>User has no messages</center>
                </div>
            : null}
        </div>
    )
}

export default UserThreads
