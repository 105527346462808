import { Link } from 'react-router-dom'
import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { currentUserVar } from '../../cache'
import { GET_STATUS_OPTIONS, GET_CATEGORIES, GET_PIPELINE, GET_PIPELINES } from '../queries'
import {
    UPDATE_TICKET,
    UNASSIGN_TICKET,
    REMOVE_CATEGORY,
    REMOVE_FROM_PIPELINE,
    REMOVE_TEMPLATE_FOR_GROUP,
    REMOVE_REVIEWER_GROUP,
    CREATE_FROM_TEMPLATE_FOR_GROUP
} from '../mutations'
import { GET_ALL_USERS, GET_USER_GROUPS } from '../../queries'
import { lbl, priorityOptions, tabNavLinks } from '../../constants'
import SelectForm, { LazySelectForm, SearchSelect } from '../../forms/Select'
import { Icon, ProfileImage } from '../../components'
import TicketLabels from './TicketLabels'


const TicketProperties = ({ ticket, onChange, isNew }) => {
    const [updateTicket, { loading }] = useMutation(UPDATE_TICKET)
    const [unassign, { loading: unassigning }] = useMutation(UNASSIGN_TICKET)
    const [removeCategory, { loading: removingCategory }] = useMutation(REMOVE_CATEGORY)
    const [removeTemplateForGroup, { loading: removingTemplateForGroup }] = useMutation(REMOVE_TEMPLATE_FOR_GROUP)
    const [removeReviewerGroup, { loading: removingReviewerGroup }] = useMutation(REMOVE_REVIEWER_GROUP)
    const [removeFromPipeline, { loading: removingFromPipeline }] = useMutation(REMOVE_FROM_PIPELINE)
    const [createFromTemplateForGroup, { loading: creatingFromTemplateForGroup, data, error }] = useMutation(CREATE_FROM_TEMPLATE_FOR_GROUP)
    const [isAssigneeOpen, openAssignee] = useState(false)
    const [isOwnerOpen, openOwner] = useState(false)
    const [isPropagateOpen, openPropagate] = useState(false)
    const isUpdating = loading || unassigning || removingCategory || removingTemplateForGroup || removingReviewerGroup || removingFromPipeline
    const isEditable = !ticket.archived && !ticket.deleted && !isUpdating
    const { isSuperuser } = currentUserVar()
    const onSelect = (vars, args, extraVars={}) => {
        if (isNew) {
            onChange({ ...vars, ...extraVars })
        } else {
            updateTicket({ variables: { id: ticket.id, ...vars }, ...args })
        }
    }

    return (
        <section id='ticket-properties'>
            <dl className='dl'>
                {!isNew && 
                    <div className='dl__li t-s'>
                        <dt>{lbl.status}</dt>
                        <dd>
                            {ticket.pipeline ?
                                <LazySelectForm
                                    key={ticket.pipelineStage?.id}
                                    disabled={!isEditable}
                                    onSelect={isEditable ? ([{ id }]) => { id !== ticket.pipelineStage?.id && onSelect({ pipelineStageId: id }, {
                                        refetchQueries: [{ 
                                            query: GET_PIPELINE,
                                            variables: { id: ticket.pipeline.id }
                                        }]
                                    })} : () => {}}
                                    optionsQuery={{
                                        query: GET_STATUS_OPTIONS,
                                        name: 'pipelineStages',
                                        args: { variables: { id: ticket.pipeline.id } }
                                    }}
                                    labelField='name'
                                    valueField='id'
                                    keepSelectedInList={false}
                                    selectedOptions={[ticket.pipelineStage]}
                                    getLabelNode={({ item: { name, isFirst, isLast } }) => (<>
                                        <Icon type={`status-${isFirst ? '1' : isLast ? '3' : '2'}`} variant='sm' />
                                        {name}
                                    </>)}
                                />
                            :
                                <SelectForm
                                    disabled={!isEditable}
                                    className='select-form select-form--static select-form--disabled'
                                    onSelect={() => {}}
                                    options={[]}
                                    noDataRenderer={() => <div className='react-dropdown-select-no-data'>
                                        {ticket.archived ? lbl.activateTicketPrompt : lbl.chooseBoardPrompt}
                                    </div>}
                                    getLabelNode={({ item }) => (<>
                                        {item.value && <Icon type={item.value} variant='sm' />}
                                        {item.label}
                                    </>)}
                                    selectedOptions={[
                                        ticket.deleted ?
                                            { label: lbl.deleted, value: 'bin' }
                                        : ticket.archived ? 
                                            { label: lbl.archived, value: 'archive' }
                                        :
                                            { label: lbl.noStatus, value: 'status-0' }
                                    ]}
                                />
                            }
                        </dd>
                    </div>
                }

                <div className='dl__li t-s'>
                    <dt>{lbl.priority}</dt>
                    <dd>
                        <SelectForm
                            disabled={!isEditable}
                            onSelect={isEditable ? ([{ value }]) => { value !== ticket.priority && onSelect({ priority: value }) } : () => {}}
                            options={Object.keys(priorityOptions).map(value => ({ value, label: priorityOptions[value].label }))}
                            selectedOptions={ticket.priority ? [{ label: priorityOptions[ticket.priority].label, value: ticket.priority }] : []}
                            keepSelectedInList={false}
                            getLabelNode={({ item: { label, value } }) => (<>
                                <Icon type={priorityOptions[value].icon} variant='sm' />
                                {label}
                            </>)}
                        />
                    </dd>
                </div>

                <div className='dl__li t-s'>
                    <dt>{lbl.owner}</dt>
                    <dd>
                        <button
                            className='btn btn--naked dd__values label-wrapper'
                            onClick={() => openOwner(true)}
                            disabled={!isEditable}
                        >
                            {ticket.owner ?
                                <>
                                    <ProfileImage url={ticket.owner.picture} size='xs'/>
                                    {ticket.owner.fullName}
                                </>
                            : isEditable ? lbl.selectOwner : '/'}
                        </button>
                        {ticket.owner && !isNew &&
                            <Link
                                className='btn btn--naked btn--inline-icon add mt-2'
                                to={tabNavLinks.contacts.dynamicPath + ticket.owner.id}
                                title='Go to contact page'
                            >
                                <Icon type='arrow-right' variant='xs' style={{ transform: 'rotate(-45deg)' }}/>
                            </Link>
                        }
                        {isOwnerOpen &&
                            <SearchSelect
                                searchable
                                isHidden
                                searchBy='fullName'
                                onSelect={isEditable ? ([option]) => {
                                    if (ticket.owner?.id !== option.id) {
                                        onSelect({ ownerId: option.id }, null, { owner: option })
                                    }
                                } : () => {}}
                                optionsQuery={{
                                    query: GET_ALL_USERS,
                                    name: 'users'
                                }}
                                showLoader
                                labelField='fullName'
                                valueField='id'
                                keepSelectedInList={false}
                                onDropdownClose={() => openOwner(false)}
                                selectedOptions={[ticket.owner || { id: 'NONE', fullName: lbl.selectOwner }]}
                                getLabelNode={({ item: { fullName, picture } }) => (
                                    <>
                                        <ProfileImage url={picture} size='xs' />
                                        {fullName}
                                    </>
                                )}
                            />
                        }
                    </dd>
                </div>

                <div className='dl__li t-s'>
                    <dt>{lbl.assignee}</dt>
                    <dd>
                        <button
                            className='btn btn--naked dd__values label-wrapper'
                            onClick={() => openAssignee(true)}
                            disabled={!isEditable}
                        >
                            {ticket.assignee ?
                                <>
                                    <ProfileImage url={ticket.assignee.picture} size='xs'/>
                                    {ticket.assignee.fullName}
                                </>
                            : isEditable ? lbl.selectAssignee : '/'}
                        </button>
                        {ticket.assignee && !isNew &&
                            <Link
                                className='btn btn--naked btn--inline-icon add mt-2'
                                to={tabNavLinks.contacts.dynamicPath + ticket.assignee.id}
                                title='Go to contact page'
                            >
                                <Icon type='arrow-right' variant='xs' style={{ transform: 'rotate(-45deg)' }}/>
                            </Link>
                        }
                        {isAssigneeOpen &&
                            <SearchSelect
                                searchable
                                isHidden
                                searchBy='fullName'
                                onSelect={isEditable ? ([option]) => {
                                    if (!isNew && option.id === 'NONE') {
                                        unassign({ variables: { id: ticket.id }})
                                    } else if (ticket.assignee?.id !== option.id) {
                                        onSelect({ assigneeId: option.id }, null, { assignee: option })
                                    }
                                } : () => {}}
                                optionsQuery={{
                                    query: GET_ALL_USERS,
                                    name: 'users'
                                }}
                                showLoader
                                labelField='fullName'
                                valueField='id'
                                keepSelectedInList={false}
                                onDropdownClose={() => openAssignee(false)}
                                selectedOptions={[ticket.assignee || { id: 'NONE', fullName: lbl.selectAssignee }]}
                                unselectOption={(ticket.assignee || ticket.assigneeId) && { id: 'NONE', fullName: lbl.noAssignee }}
                                getLabelNode={({ item: { id, fullName, picture }, isSelectedContent }) => (<>
                                    {id === 'NONE' && !isSelectedContent ?
                                        <Icon type='close' variant='xs' /> :
                                        <ProfileImage url={picture} size='xs' />
                                    }
                                    {fullName}
                                </>)}
                            />
                        }
                    </dd>
                </div>

                <TicketLabels
                    ticketId={ticket.id}
                    labels={ticket.labels || []}
                    isEditable={isEditable}
                    onChange={onChange}
                />

                <div className='dl__li t-s'>
                    <dt>{lbl.category}</dt>
                    <dd>
                        <LazySelectForm
                            disabled={!isEditable}
                            onSelect={isEditable ? ([option]) => {
                                if (!isNew && option.id === 'NONE') {
                                    removeCategory({ variables: { id: ticket.id }})
                                } else if (ticket.category?.id !== option.id) {
                                    onSelect({ categoryId: option.id }, null, { category: option })
                                }
                            } : () => {}}
                            optionsQuery={{
                                query: GET_CATEGORIES,
                                name: 'categories'
                            }}
                            labelField='name'
                            valueField='id'
                            keepSelectedInList={false}
                            selectedOptions={[ticket.category || { id: 'NONE', name: lbl.selectCategory }]}
                            unselectOption={(ticket.category || ticket.categoryId) && { id: 'NONE', name: lbl.noCategory }}
                            getLabelNode={({ item: { id, name, userGroup }, isSelectedContent }) => (
                                <div className={`d-flex align-center${id !== 'NONE' && !isSelectedContent ? ' w-100 justify-space-between flex-reverse' : ''}`}>
                                    {id === 'NONE' && !isSelectedContent ?
                                        <Icon type='close' variant='xs' />
                                    : userGroup &&
                                        <span
                                            className={`label chip--${userGroup.name}`}
                                            style={isSelectedContent ? { borderRadius: '50%', padding: '4px', marginRight: '8px' } : { marginLeft: '8px' }}
                                        >
                                            {isSelectedContent ?
                                                <Icon
                                                    type={userGroup.name}
                                                    variant='2xs'
                                                    wrapperClassAdditions='mr-0'
                                                    classAdditions='mr-0'
                                                />
                                            : userGroup.name}
                                        </span>
                                    }
                                    {id === 'NONE' && isSelectedContent ? lbl.selectCategory : name}
                                </div>
                            )}
                        />
                    </dd>
                </div>

                {isSuperuser &&
                    <>
                        <div className='dl__li t-s pos-relative'>
                            <dt>{lbl.templateForGroup}</dt>
                            <dd>
                                <LazySelectForm
                                    disabled={!isEditable}
                                    onSelect={isEditable ? ([option]) => {
                                        if (!isNew && option.id === 'NONE') {
                                            removeTemplateForGroup({ variables: {id: ticket.id}})
                                        } else if (ticket.templateForGroup?.id !== option.id) {
                                            onSelect({ templateForGroupId: option.id }, null, { templateForGroup: option })
                                        }
                                    } : () => {}}
                                    optionsQuery={{
                                        query: GET_USER_GROUPS,
                                        name: 'userGroups'
                                    }}
                                    labelField='name'
                                    valueField='id'
                                    keepSelectedInList={false}
                                    selectedOptions={[ticket.templateForGroup || { id: 'NONE', name: lbl.addTemplateForGroup }]}
                                    unselectOption={(ticket.templateForGroup || ticket.templateForGroupId) && { id: 'NONE', name: lbl.noTemplateForGroup }}
                                    getLabelNode={({ item: { id, name }, isSelectedContent }) => (<>
                                        {id === 'NONE' && !isSelectedContent && <Icon type='close' variant='xs' />}
                                        {id === 'NONE' && isSelectedContent ? isEditable ? lbl.addTemplateForGroup : '/' : name}
                                    </>)}
                                />
                                {!isNew && ticket.templateForGroup && isEditable &&
                                    <button
                                        className='btn btn--naked btn--inline-icon add'
                                        onClick={() => openPropagate(true)}
                                        title={`Propagate this ticket to all existing users in group: ${ticket.templateForGroup.name}`}
                                    >
                                        <Icon type='expand' variant='sm' style={creatingFromTemplateForGroup ? {animation: 'pulse 1s infinite linear'} : undefined}/>
                                    </button>
                                }
                            </dd>
                            {isPropagateOpen &&
                                <div className='pop-up pop-up--top'>
                                    <div className='pop-up__container'>
                                        <header className='pop-up__header'>
                                            Ticket propagation
                                        </header>
                                        <div className='pop-up__message t-s h-200'>
                                            {data ? 
                                                <div>
                                                    {Boolean(data.createFromTemplateForGroup?.tickets?.length) ?
                                                        <div>
                                                            <p><strong>Created tickets:</strong></p>
                                                            {data.createFromTemplateForGroup?.tickets?.map(({ name }) => <p key={name}>{name}</p>)}
                                                        </div>
                                                    :
                                                        <p><strong>No affected users found in {ticket.templateForGroup.name} group.</strong></p>
                                                    }
                                                </div>
                                            :
                                                <>
                                                    <p>Are you sure you want create a ticket for all existing users in group <strong>{ticket.templateForGroup.name}</strong>?</p>
                                                    <p>New ticket will not be created if user was already assigned to a ticket created from this template.</p>
                                                </>
                                            }
                                            {error && <p>{error}</p>}
                                            {creatingFromTemplateForGroup && <div className='loader'/>}
                                        </div>
                                        <div className='pop-up__btns'>
                                            {!data?.createFromTemplateForGroup &&
                                                <button
                                                    className='btn btn--secondary mr-2'
                                                    onClick={() => openPropagate(false)}
                                                    disabled={creatingFromTemplateForGroup}
                                                >
                                                    {lbl.cancel}
                                                </button>
                                            }
                                            <button
                                                className='btn btn--primary'
                                                disabled={creatingFromTemplateForGroup}
                                                onClick={() => {
                                                    if (data?.createFromTemplateForGroup) {
                                                        openPropagate(false)
                                                    } else {
                                                        createFromTemplateForGroup({
                                                            variables: { templateTicketId: ticket.id }
                                                        })
                                                    }
                                                }}
                                            >
                                                {creatingFromTemplateForGroup ? 'Creating...' : data?.createFromTemplateForGroup ? 'Close' : 'Create'}
                                            </button>
                                        </div>
                                        <button
                                            className='btn btn--naked close mt-2 mr-2'
                                            onClick={() => openPropagate(false)}
                                        >
                                            <Icon type='close' variant='xs' />
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className='dl__li t-s'>
                            <dt>{lbl.reviewerGroup}</dt>
                            <dd>
                                <LazySelectForm
                                    disabled={!isEditable}
                                    onSelect={([option]) => {
                                        if (isEditable) {
                                            if (!isNew && option.id === 'NONE') {
                                                removeReviewerGroup({ variables: {id: ticket.id}})
                                            } else {
                                                onSelect({ reviewerGroupId: option.id }, null, { reviewerGroup: option })
                                            }
                                        }
                                    }}
                                    optionsQuery={{
                                        query: GET_USER_GROUPS,
                                        name: 'userGroups'
                                    }}
                                    labelField='name'
                                    valueField='id'
                                    keepSelectedInList={false}
                                    selectedOptions={[ticket.reviewerGroup || { id: 'NONE', name: lbl.addReviewerGroup }]}
                                    unselectOption={(ticket.reviewerGroup || ticket.reviewerGroupId) && { id: 'NONE', name: lbl.noReviewerGroup }}
                                    getLabelNode={({ item: { id, name }, isSelectedContent }) => (<>
                                        {id === 'NONE' && !isSelectedContent && <Icon type='close' variant='xs' />}
                                        {id === 'NONE' && isSelectedContent ? isEditable ? lbl.addReviewerGroup : '/' : name}
                                    </>)}
                                />
                            </dd>
                        </div>
                    </>
                }
                
                <div className='dl__li t-s'>
                    <dt>{lbl.board}</dt>
                    <dd>
                        <LazySelectForm
                            disabled={!isEditable}
                            onSelect={isEditable ?
                                ([pipelineItem]) => { 
                                    if (!isNew && pipelineItem?.id === 'NONE') {
                                        removeFromPipeline({ variables: { id: ticket.id }})
                                    } else if (pipelineItem?.id !== ticket.pipeline?.id) {
                                        onSelect({
                                            pipelineStageId: pipelineItem.pipelineStages && pipelineItem.pipelineStages[0]?.id,
                                            pipelineId: pipelineItem.id
                                        }, {
                                            refetchQueries: [{
                                                query: GET_PIPELINE,
                                                variables: { id: pipelineItem.id }
                                            }]
                                        }, {
                                            pipelineStage: pipelineItem.pipelineStages && pipelineItem.pipelineStages[0],
                                            pipeline: pipelineItem
                                        })
                                    }
                                }
                            : () => {}}
                            optionsQuery={{
                                query: GET_PIPELINES,
                                name: 'pipelines',
                                args: { variables: { excludeEmpty: true } }
                            }}
                            labelField='name'
                            valueField='id'
                            keepSelectedInList={false}
                            selectedOptions={[ticket.pipeline || { id: 'NONE', name: lbl.addToBoard }]}
                            unselectOption={(ticket.pipeline || ticket.pipelineId) && { id: 'NONE', name: lbl.noBoard, pipelineStages: [] }}
                            getLabelNode={({ item: { id, name }, isSelectedContent }) => (<>
                                {id === 'NONE' && !isSelectedContent && <Icon type='close' variant='xs' />}
                                {id === 'NONE' && isSelectedContent ? isEditable ? lbl.addToBoard : '/' : name}
                            </>)}
                        />
                        {ticket.pipeline && !isNew &&
                            <Link
                                className='btn btn--naked btn--inline-icon add mt-2'
                                to={`${tabNavLinks.tickets.staticTabs[0].baseUrl}/${ticket.pipeline.id}`}
                                title={`Go to ${ticket.pipeline.name} board`}
                            >
                                <Icon type='arrow-right' variant='xs' style={{ transform: 'rotate(-45deg)' }}/>
                            </Link>
                        }
                    </dd>
                </div>
            </dl>
        </section>
    )
}

export default TicketProperties
