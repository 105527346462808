import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useReactiveVar, useMutation } from '@apollo/client'
import { newDraftsVar, openedTabsVar, currentUserVar } from '../../cache'
import { CREATE_TICKET } from '../mutations'
import { GET_PIPELINE } from '../queries'
import { tabNavLinks, lbl } from '../../constants'
import { TextField } from '../../forms'
import TextEditor from '../../components/TextEditor'
import TicketProperties from './TicketProperties'

const NewTicket = () => {
    const history = useHistory()
    const ticketDraft = useReactiveVar(newDraftsVar).tickets
    const openedTabs = useReactiveVar(openedTabsVar)
    const currentUser = useReactiveVar(currentUserVar)
    const { relativeUrl } = tabNavLinks.tickets.new

    const [createTicket, { error, loading }] = useMutation(CREATE_TICKET, {
        onCompleted: ({ createTicket: { ticket } }) => {
            openedTabs.delete(relativeUrl)
            openedTabsVar(new Map([...openedTabs]))
            newDraftsVar({ ...newDraftsVar(), tickets: {
                priority: '2_MEDIUM',
                owner: currentUser,
            } })
            history.push(`${tabNavLinks.tickets.dynamicPath}${ticket.id}`)
        },
        update(cache, { data: { createTicket: { ticket } } }) {
            cache.modify({
                fields: {
                    tickets(existingTickets = []) {
                        return [ticket, ...existingTickets];
                    }
                }
            })
            if (ticket.pipelineStage) {
                cache.modify({
                    id: cache.identify({ __typename: 'PipelineStageType', id: ticket.pipelineStage.id }),
                    fields: {
                        tickets(existingTickets = []) {
                            return [ticket, ...existingTickets]
                        }
                    }
                })
            }
        }
    })

    const errMsg = error && error.message.replace(/',|'|]|\[/g, '')

    const onSubmit = () => {
        const {
            assignee,
            owner,
            labels,
            category,
            pipelineStage,
            pipeline,
            reviewerGroup,
            templateForGroup,
            ...rest
        } = ticketDraft

        createTicket({
            variables: {
                ...rest,
                labelIds: labels?.length ? labels.map(l => l.id) : undefined
            },
            refetchQueries: pipeline ? [{
                query: GET_PIPELINE,
                variables: { id: pipeline.id }
            }] : []
        })
    }

    useEffect(() => {
        openedTabsVar(new Map([...openedTabsVar(), [relativeUrl, {
            name: lbl.newTicket,
            id: 'new',
            relativeUrl,
            icon: 'ticket'
        }]]))
    }, [relativeUrl])

    useEffect(() => {
        newDraftsVar({
            ...newDraftsVar(),
            tickets: { owner: currentUser, ownerId: currentUser.id, priority: '2_MEDIUM', ...ticketDraft }
        })
    }, [])

    return (
        <div className='split-panel'>
            <section className='split-panel--main ticket'>
                <button
                    onClick={onSubmit}
                    className='btn btn--primary btn--rounded t-none pos-fixed'
                    style={{ position: 'absolute', right: '3vw', top: '10px' }}
                    disabled={loading || !ticketDraft.name || !ticketDraft.description}
                >
                    {loading ? <div className='loader loader--inline'/> : 'Save ticket'}
                </button>
                <TextField
                    onSubmit={value => {
                        const name = value.replace(/&nbsp;/g, ' ').trim()
                        if (name !== ticketDraft.name) {
                            newDraftsVar({
                                ...newDraftsVar(),
                                tickets: { ...ticketDraft, name }
                            })
                        }
                    }}
                    inputProps={{
                        type: 'input',
                        name: 'name',
                        value: ticketDraft.name || '',
                        role: 'textbox',
                        contentEditable: true,
                        placeholder: lbl.ticketTitle,
                        autoFocus: true
                    }}
                    innerHtml={ticketDraft.name}
                    size='2xl'
                    as='div'
                />
                {errMsg && <div className='error t-red'>{errMsg}</div>}
                <br/>
                <div className='ticket__editor'>
                    <TextEditor
                        model={ticketDraft.description || ''}
                        onModelChange={(description) => {
                            newDraftsVar({
                                ...newDraftsVar(),
                                tickets: { ...ticketDraft, description }
                            })
                        }}
                        config={{
                            placeholderText: lbl.addDetails,
                            toolbarButtons: ['bold', 'italic', 'underline', 'backgroundColor', '|', 'formatOL', 'formatUL', 'outdent', 'indent', 'align', '|', 'insertTemplate', 'insertLink', 'emoticons', '|', 'selectAll', 'clearFormatting', 'undo', 'redo'],
                        }}
                        immediateReactModelUpdate
                    />
                </div>
            </section>
            <aside className='split-panel--side split-panel--right'>
                <div className='split-panel--side-inner'>
                    <br/>
                    <TicketProperties
                        ticket={ticketDraft}
                        onChange={obj => {
                            const updates = {}
                            Object.keys(obj).forEach(key => {
                                if (!obj[key] || obj[key] === 'NONE' || obj[key].id === 'NONE') {
                                    updates[key] = undefined
                                } else {
                                    updates[key] = obj[key]
                                }
                            })
                            
                            newDraftsVar({
                                ...newDraftsVar(),
                                tickets: { ...ticketDraft, ...updates }
                            })
                        }}
                        isNew
                    />
                </div>
            </aside>
        </div>
    )
}

export default NewTicket
