import { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { staticTabs } from '../../constants'
import { TabNavContext } from '../../components/TabNav'
import ContactDetail from './ContactDetail'
import ContactList from './ContactList'
import NewContact from './NewContact'


const ContatLoader = () => {
    const { id, activeTab } = useContext(TabNavContext)

    switch (activeTab) {
        case 'list':
            return <ContactList/>
        case 'contact':
            return <ContactDetail key={activeTab + id} />
        case 'new':
            return <NewContact/>
        default:
            return <Redirect to={staticTabs.contacts[0].relativeUrl} />
    }
}

export default ContatLoader
