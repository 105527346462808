import Accordion from '../../components/Accordion'
import { lbl } from '../../constants'
import { useQuery, useReactiveVar } from '@apollo/client'
import { GET_PROPERTY_CONTRACTS } from '../queries'
import ContractInfoForm from './ContractInfoForm'
import { useState } from 'react'
import { CREATE_CONTRACT } from '../mutations'
import { newDraftsVar } from '../../cache'
import ContractInfo from './ContractInfo'
import { Icon } from '../../components'

const PropertyContracts = ({ id, property, units, archived }) => {
    const [includeUnitContracts, setUnitContracts] = useState(false)
    const { loading, data, client } = useQuery(GET_PROPERTY_CONTRACTS, { variables: { id, includeUnitContracts, includeArchived: archived } })
    const [newContractOpen, setNewContractOpen] = useState(false)
    let { propertyContracts } = useReactiveVar(newDraftsVar)
    let draft = propertyContracts[id] || {}
    let draftExtra = { propertyId: id }
    const { monthlyServiceCost, otherCosts } = property
    if (monthlyServiceCost) draftExtra['monthlyServiceCost'] = monthlyServiceCost
    if (otherCosts) draftExtra['otherCosts'] = otherCosts

    return (
        <>
            {loading && <div className='loader pos-center-y'/>}
            <div className='d-inline-flex justify-end mb-1 float-right'>
                <button
                    disabled={true}
                    className='btn btn--naked btn--icon mr-1 br-05 active'
                    onClick={() => {}}
                >
                    <Icon type='address' variant='sm'/>
                </button>
                <button
                    className={`btn btn--naked btn--icon br-05${includeUnitContracts ? ' active' : ''}`}
                    onClick={() => setUnitContracts((prev) => !prev)}
                >
                    <Icon type='room' variant='sm'/>
                </button>
            </div>
            {!archived &&
                <>
                    {newContractOpen ?
                        <Accordion
                            isOpen
                            Tag='div'
                            className='bg-lightblue p-1 br-05 mb-1'
                            headerClass='accordion__header--bordered t-md t-heading mr-3'
                            label={lbl.newContract}
                            onOpenCb={() => { setNewContractOpen(false) }}
                        >
                            <div className='ml-4 mr-4 mb-2'>
                                <ContractInfoForm
                                    draft={{ ...draft, ...draftExtra }}
                                    extraFields={[{ name: 'propertyId', lblKey: 'property', As: 'select', options: [
                                        { value: id, label: property?.streetWithHouseNumber}
                                    ], required: true, disabled: true }]}
                                    submitMutation={CREATE_CONTRACT}
                                    mutationVariables={{ includeUnitContracts }}
                                    onCompleted={() => {
                                        client.cache.evict({
                                            id: `PropertyType:${id}`,
                                            fieldName: 'propertyContracts',
                                            variables: { includeUnitContracts: !includeUnitContracts }
                                        })
                                        client.cache.gc()
                                    }}
                                    close={() => { setNewContractOpen(false) }}
                                    setDraft={(contractDraft) => {
                                        const drafts = { ...newDraftsVar()}
                                        drafts.propertyContracts[id] = contractDraft
                                        newDraftsVar(drafts)
                                    }}
                                />
                            </div>
                        </Accordion>
                    :
                        <button
                            className='btn btn--naked btn--new-item w-100 mb-2'
                            onClick={() => { setNewContractOpen(true) }}
                        >
                            <span className="plus">+</span>
                            {lbl.addNewPropertyContract}
                        </button>
                    }
                </>
            }
            {data?.property?.propertyContracts?.map((contract) => (
                <ContractInfo
                    key={'contract' + contract.id}
                    contract={contract}
                    units={units}
                    property={property}
                    propertyId={id}
                    unitId={contract.unit?.id}
                    labelAddition={contract.unit?.fullUnitName}
                />
            ))}
        </>
    )
}

export default PropertyContracts
