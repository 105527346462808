import { Link } from 'react-router-dom'
import Icon from './Icon'
import { snakeToCamelCase, toTitleCase, getProp, extractTextFromHtml } from '../utils'
import { useQuery } from '@apollo/client'
import { GET_PIPELINE_STAGE } from '../tickets/queries'
import { changeLogKinds } from '../constants'
import { taskProperties } from '../tasks/settings'
import { ticketProperties } from '../tickets/settings'

const formatValue = (text) => {
    if (!text) return ''
    let value = text
    if (value.includes('_')) value = toTitleCase(value.substring(2).replace('_' , ' '))
    // extract text from html value
    if (value[0] === '<' && value[value.length-1] === '>') value = extractTextFromHtml(value)
    // limit value to 200 chars
    if (value.includes('&nbsp;')) value = value.replace(/&nbsp;/g, ' ')
    if (value.length > 200) value = value.substring(0, 200) + '...'

    return <>{value}</>
}

const PipelineStageIcon = ({ id }) => {
    const { data } = useQuery(GET_PIPELINE_STAGE, { variables: { id } })
    const type = data?.pipelineStage ? `status-${data.pipelineStage.isFirst ? '1' : data.pipelineStage.isLast ? '3' : '2'}` : 'status'

    return <Icon type={type} variant='sm' />
}

const propSettings = {
    ticket: ticketProperties,
    task: taskProperties
}

const ChangeLog = (props) => {
    const { items, isLoading, isUserActivity } = props

    if (isLoading) return <div className='loader' />

    return (
        <ul className={`timeline timeline--${isUserActivity ? '650' : '350'}`}>
            {items.map(({ kind, dateTime, changes, user, attachment, contentModel, contentName, objectId }, i) => {
                const fields = Object.keys(changes)
                const type = props.type || contentModel
                const properties = props.properties || propSettings[type] || {}
                const { icon, field, getOptionProps } = (kind !== changeLogKinds.DELETE && fields.length && properties && properties[snakeToCamelCase(fields[0])]) || {}
                const { id: firstId, value: firstValue, color } = (kind !== changeLogKinds.DELETE && fields.length && changes[fields[0]]) || {}
                const contentObjectInTitle = isUserActivity && ['ticket', 'task'].includes(contentModel)
                const getIcon = () => {
                    if (kind === changeLogKinds.CREATE) return type === 'profile' ? 'user' : type
                    if (kind === changeLogKinds.DELETE) return 'bin'
                    if (kind === changeLogKinds.UPLOAD) return 'folder'
                    if (['priority', 'status'].includes(field)) return getProp('icon', getOptionProps(firstValue)) || icon
                    return icon || 'pencil'
                }
                const getTitle = () => {
                    if (contentObjectInTitle) {
                        if (objectId && kind !== changeLogKinds.DELETE) return <Link to={`/${type}s/${type}/${objectId}`}>{contentName}</Link>
                        return contentName
                    }
                    return <Link to={`/contacts/contact/${user.id}`}>{user.fullName}</Link>
                }
                const getDescription = () => {
                    let prefix = contentObjectInTitle ? user.fullName + ' ' : ''
                    if (kind === changeLogKinds.DELETE) return <>{prefix}deleted this {type}</>
                    if (kind === changeLogKinds.UPLOAD) {
                        if (attachment) {
                            return <>{prefix}attached file <a href={attachment.link} target='_blank' rel='noreferrer'>{attachment.originalFilename}</a> </>
                        } else if (changes.description) {
                            return <>{changes.description}</>
                        } else {
                            return <>{prefix}uploaded a file</>
                        }
                    }
                    if (kind === changeLogKinds.CREATE) {
                        return (
                            <div key={kind+i}>
                                <span>{prefix}created the <strong>{type}</strong> with </span>
                                {fields.map((key, i) => {
                                    const value = formatValue(changes[key]?.value)
                                    const { label } = properties[snakeToCamelCase(key)] || { label: toTitleCase(key.replace(/_/g, ' ')) }
                                    return (<span key={key+i}>{i !== 0 && ', '}<strong>{label.toLowerCase()}</strong> <i>{value}</i></span>)
                                })}
                            </div>
                        )
                    }
                    return fields.map((key, i) => {
                        const { prevValue, value } = changes[key]
                        const formattedPrevValue = formatValue(prevValue)
                        const formattedValue = formatValue(value)
                        const label = properties && properties[snakeToCamelCase(key)]?.label || toTitleCase(key.replace(/_/g, ' '))
                        if (!label || !(value || prevValue)) return null
                        const displayLabel = label.toLowerCase()
                        const x = i === 0 ? prefix : ''

                        return (
                            <div key={key+i}>
                                {kind === changeLogKinds.CHANGE ?
                                    (!prevValue || prevValue === 'None') ?
                                        <>{x}set <strong>{displayLabel}</strong> to <i>{formattedValue}</i></>
                                    : (!value || value === 'None') ?
                                        <>{x}removed <i>{formattedPrevValue}</i> <strong>{displayLabel}</strong></>
                                    :   <>{x}changed <strong>{displayLabel}</strong> from <i>{formattedPrevValue}</i> to <i>{formattedValue}</i></>
                                : kind === 'add' ?
                                    <>{x}added <strong>{displayLabel}</strong> <i>{formattedValue}</i></>
                                : kind === 'remove' ?
                                    <>{x}removed <strong>{displayLabel}</strong> <i>{formattedValue}</i></>
                                : null}
                            </div>
                        )
                    })
                }

                return (
                    <li key={kind + i} className='timeline__item'>
                        <div className='timeline-item--left'>
                            <div className='timeline__icon'>
                                {field === 'pipelineStage' && firstId ?
                                    <PipelineStageIcon id={firstId} />
                                :
                                    <Icon
                                        type={getIcon()}
                                        variant='sm'
                                        fill={color || '#78909C'}
                                    />
                                }
                            </div>
                            <div className='timeline__line'/>
                        </div>
                        <div className='timeline-item--right'>
                            <div className='timeline-item__header'>
                                <div className='timeline-item__title'>{getTitle()}</div>
                                <div className='timeline__time'>{dateTime}</div>
                            </div>
                            <div className='timeline-item__content'>
                                {getDescription()}
                            </div>
                        </div>
                    </li>
                )
            })}
        </ul>
    )
}

export default ChangeLog
