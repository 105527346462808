import { useQuery } from '@apollo/client'
import { GET_CHANGE_LOG_FOR_INSTANCE, GET_CHANGE_LOG_TOTAL_FOR_INSTANCE } from '../queries'
import ChangeLog from './ChangeLog'
import OnScrollLoader from './OnScrollLoader'


const TimelineWidgets = ({ contentType, objectId, properties }) => {
    const { loading: isChangeLogLoading, data: changeLogData, fetchMore: fetchMoreLogs } = useQuery(
        GET_CHANGE_LOG_FOR_INSTANCE, {
            variables: { contentType, objectId, offset: 0, limit: 20 },
            notifyOnNetworkStatusChange: true,
        }
    )
    const { data: totalData } = useQuery(
        GET_CHANGE_LOG_TOTAL_FOR_INSTANCE, {
            variables: { contentType, objectId },
        }
    )

    if (!changeLogData && isChangeLogLoading) return <div className='loader' />
    if (!changeLogData) return null

    return (
        <>
            <ChangeLog
                type={contentType}
                properties={properties}
                items={changeLogData.changeLogForContentObject}
            />
            {(!totalData || totalData.changeLogTotalForContentObject > changeLogData.changeLogForContentObject.length) &&
                <OnScrollLoader
                    onIntersect={() => {
                        fetchMoreLogs({
                            variables: {
                                offset: changeLogData.changeLogForContentObject.length
                            }
                        })
                    }}
                />
            }
        </>
    )
}

export default TimelineWidgets
