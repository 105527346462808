import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { TabNavContext } from '../../components/TabNav'
import { lbl } from '../../constants'
import Icon from '../../components/Icon'

const PropertyDetailNav = ({ urlRoot, contentType }) => {
    const { activeSubtab } = useContext(TabNavContext)

    return (
        <nav className='tab-nav'>
            <ul className='tab-nav__list'>
                <li className={`tab ${!activeSubtab || activeSubtab === 'info' ? ' active' : ''}`}>
                    <Link to={`${urlRoot}/info`}>
                        <Icon type='account-details' variant='xs' />
                        <span className='tab__label t-sm'>{lbl.info}</span>
                    </Link>
                </li>
                <li className={`tab ${activeSubtab === 'services' ? ' active' : ''}`}>
                    <Link to={`${urlRoot}/services`}>
                        <Icon type='settings' variant='xs' />
                        <span className='tab__label t-sm'>{lbl.services}</span>
                    </Link>
                </li>
                {/* <li className={`tab ${activeSubtab === 'activity' ? ' active' : ''}`}>
                    <Link to={`${urlRoot}/activity`}>
                        <Icon type='time' variant='xs' />
                        <span className='tab__label t-sm'>{lbl.activity}</span>
                    </Link>
                </li> */}
                <li className={`tab ${activeSubtab === 'contracts' ? ' active' : ''}`}>
                    <Link to={`${urlRoot}/contracts`}>
                        <Icon type='document' variant='xs' />
                        <span className='tab__label t-sm'>{lbl.contracts}</span>
                    </Link>
                </li>
                {(contentType === 'address' || contentType === 'room') &&
                    <li className={`tab ${activeSubtab === 'leads' ? ' active' : ''}`}>
                        <Link to={`${urlRoot}/leads`}>
                            <Icon type='document-outline' variant='xs' />
                            <span className='tab__label t-sm'>{lbl.leads}</span>
                        </Link>
                    </li>
                }
                <li className={`tab ${activeSubtab === 'attachments' ? ' active' : ''}`}>
                    <Link to={`${urlRoot}/attachments`}>
                        <Icon type='folder' variant='xs' />
                        <span className='tab__label t-sm'>{lbl.files}</span>
                    </Link>
                </li>
                <li className={`tab ${activeSubtab === 'tickets' ? ' active' : ''}`}>
                    <Link to={`${urlRoot}/tickets`}>
                        <Icon type='ticket' variant='xs' />
                        <span className='tab__label t-sm'>{lbl.tickets}</span>
                    </Link>
                </li>
                <li className={`tab ${activeSubtab === 'tasks' ? ' active' : ''}`}>
                    <Link to={`${urlRoot}/tasks`}>
                        <Icon type='task' variant='xs' />
                        <span className='tab__label t-sm'>{lbl.tasks}</span>
                    </Link>
                </li>
                <li className={`tab ${activeSubtab === 'threads' ? ' active' : ''}`}>
                    <Link to={`${urlRoot}/threads`}>
                        <Icon type='message' variant='xs' />
                        <span className='tab__label t-sm'>{lbl.messages}</span>
                    </Link>
                </li>
                <li className={`tab ${activeSubtab === 'calls' ? ' active' : ''}`}>
                    <Link to={`${urlRoot}/calls`}>
                        <Icon type='call' variant='xs' />
                        <span className='tab__label t-sm'>{lbl.calls}</span>
                    </Link>
                </li>
                <li className={`tab ${activeSubtab === 'notes' ? ' active' : ''}`}>
                    <Link to={`${urlRoot}/notes`}>
                        <Icon type='clipboard' variant='xs' />
                        <span className='tab__label t-sm'>{lbl.notes}</span>
                    </Link>
                </li>
            </ul>
        </nav>
    )
}

export default PropertyDetailNav
