import { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { tabNavLinks } from '../../constants'
import { TabNavContext } from '../../components/TabNav'
import { GET_CONTRACT } from '../queries'


const ContractRedirect = () => {
    const { id } = useContext(TabNavContext)
    const { data, loading, error } = useQuery(GET_CONTRACT, { variables: { id } })
    const { ownercontract, tenantcontract } = data?.contract || {}

    if (loading) return <div className='loader pos-center-y' />
    
    if (error) return (
        <div className='t-red w-100 pos-center-y'>
            <center>{error.message}</center>
        </div>
    )

    if (ownercontract) {
        return <Redirect to={`${tabNavLinks.contacts.dynamicPath}${ownercontract.owner.id}/contracts#contract_${ownercontract.id}`} />
    }

    if (tenantcontract?.property) {
        return <Redirect to={`${tabNavLinks.properties.property.dynamicPath}${tenantcontract.property.id}/contracts#contract_${tenantcontract.id}`} />
    }

    if (tenantcontract?.unit) {
        return <Redirect to={`${tabNavLinks.properties.unit.dynamicPath}${tenantcontract.unit.id}/contracts#contract_${tenantcontract.id}`} />
    }

    return null
}

export default ContractRedirect
