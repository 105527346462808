import { Link } from 'react-router-dom'
import { notificationVerbs, CONTENT_MODEL_MAP } from '../../constants'
import { useMutation } from '@apollo/client'
import { MARK_NOTIFICATION_READ } from '../mutations'
import { notificationCountersVar } from '../../cache'
import { formatDateTimeLabel } from '../../utils'
import { Label } from '../../components'

export const ContentTypeLink = ({
    contentType,
    objectName,
    objectId,
    targetType,
    targetName,
    targetId,
    description,
    className,
    markAsRead,
    children
}) => {
    let text = undefined
    let url = undefined

    if (objectName.indexOf('object (') === -1) {
        text = contentType + ' <strong>' + objectName + '</strong>'
    } else {
        text = '<strong>' + contentType + '</strong>'
    }

    if (contentType === 'comment') {
        const [rootType, rootId, rootName] = description.split('|')
        text += ' on <strong>' + targetName + '</strong>' 
        if (rootName) text += ' on ' + rootType + ' <strong>' + rootName + '</strong>'
        url = `${CONTENT_MODEL_MAP[rootType]?.path}${rootId}${CONTENT_MODEL_MAP[targetType]?.subpath}${targetId}`
    } else if (CONTENT_MODEL_MAP[contentType]?.path) {
        url = `${CONTENT_MODEL_MAP[contentType].path}${objectId}`
        if (targetType) text += ' on ' + targetType + ' <strong>' + targetName + '</strong>'
    } else if (targetType && CONTENT_MODEL_MAP[targetType]?.path) {
        url = `${CONTENT_MODEL_MAP[targetType].path}${targetId}`
        if (CONTENT_MODEL_MAP[contentType]?.subpath) {
            url += `${CONTENT_MODEL_MAP[contentType].subpath}${objectId}`
        }
        text += ' on ' + targetType + ' <strong>' + targetName + '</strong>'
    }

    const Tag = url ? Link : 'div'

    return (
        <Tag
            to={url}
            className={className}
            onClick={url && markAsRead}
        >
            {children(text)}
        </Tag>
    )
}

export const Notification = ({ notification, isRead }) => {
    const { dayLabel, timeLabel } = formatDateTimeLabel(notification.timestamp)
    const [markNotificationRead] = useMutation(MARK_NOTIFICATION_READ)
    const onMarkReadNotification = () => {
        if (isRead) return

        markNotificationRead({
            variables: {ids: [notification.id]}, 
            update(cache) {
                const { unreadNotificationsCount } = notificationCountersVar()
                const newUnreadNotificationsCount = Math.max(0,  unreadNotificationsCount - 1)
                notificationCountersVar({ ...notificationCountersVar(), unreadNotificationsCount: newUnreadNotificationsCount })
                const normalizedId = cache.identify({__typename: 'NotificationType', id: notification.id})
                cache.modify({
                    fields: {
                        unreadNotifications(existingNotifications) {
                            return existingNotifications.filter(
                                notificationRef => notificationRef.__ref !== normalizedId
                            )
                        },
                        readNotifications(existingNotifications=[], { toReference }) {
                            return [toReference(notification), ...existingNotifications]
                        }
                    }
                })
            }
        })
    }

    return (
        <ContentTypeLink
            key={notification.id}
            className='card card--notification mb-1 d-flex t-none'
            contentType={notification.actionObjectContentType.model}
            objectName={notification.actionObjectObjectName}
            objectId={notification.actionObjectObjectId}
            targetType={notification.targetContentType?.model}
            targetName={notification.targetObjectName}
            targetId={notification.targetObjectId}
            description={notification.description}
            markAsRead={onMarkReadNotification}
        >
            {(text) => (
                <>
                    <div className='mb-1'>
                        <Label
                            label={`${dayLabel}, ${timeLabel}`}
                            className='time-label'
                        />
                        {!isRead &&
                            <button
                                className='btn btn--tertiary float-right'
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    onMarkReadNotification()
                                }}
                            >
                                Mark as read
                            </button>
                        }
                    </div>
                    <div className='ml-05'>
                        {notification.actorObjectName}
                        {' '}
                        {notification.verb}
                        {' '}
                        <span dangerouslySetInnerHTML={{ __html: text }}/>
                        {notification.verb === notificationVerbs.ASSIGNED ? ' to you.' : '.'}
                    </div>
                </>
            )}
        </ContentTypeLink>
    )
}

export default Notification