import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useIntersectionObserver } from '../hooks/useIntersectionObserver'


const OnScrollLoader = ({ onIntersect, rootMargin='800px', root }) => {
    const targetRef = useRef(null)

    useIntersectionObserver({
        onIntersect,
        targetRef,
        root,
        rootMargin, // trigger fetch x px before loader element
        threshold: 0
    })

    return (
        <div ref={ targetRef } className='pb-4'>
            <div className='loader'/>
        </div>
    )
}

export default OnScrollLoader

OnScrollLoader.propTypes = {
    onIntersect: PropTypes.func.isRequired,
}
