import { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { staticTabs } from '../../constants'
import { TabNavContext } from '../../components/TabNav'
import Call from './CallDetail'
import CallLister from './CallLister'

const CallLoader = () => {
    const { activeTab, id } = useContext(TabNavContext)

    switch (activeTab) {
        case 'list':
            return <CallLister/>
        case 'call':
            return <Call key={activeTab + id}/>
        default:
            return <Redirect to={staticTabs.calls[0].relativeUrl} />
    }
}

export default CallLoader
