import { Switch, Route, Redirect } from 'react-router-dom'
import NotesFeed from '../../notes/components/NotesFeed'
import TimelineWidgets from '../../components/TimelineWidgets'
import TaskAttachments from './TaskAttachments'
import { tabNavLinks, contentTypes } from '../../constants'
import { taskProperties } from '../settings'
import RedirectToLastSubtab from '../../components/RedirectToLastSubtab'

const TaskDetailRouter = ({ taskId, isEditable }) => {
    const urlRoot = `${tabNavLinks.tasks.dynamicPath}${taskId}`

    return (
        <Switch>
            <Route path={`${urlRoot}/updates`}>
                <TimelineWidgets
                    contentType={contentTypes.TASK}
                    objectId={taskId}
                    properties={taskProperties}
                />
            </Route>
            <Route path={`${urlRoot}/notes`}>
                <NotesFeed
                    contentType={contentTypes.TASK}
                    objectId={taskId}
                    disabled={!isEditable}
                />
            </Route>
            <Route path={`${urlRoot}/attachments`}>
                <TaskAttachments taskId={taskId} />
            </Route>
            <Route path={urlRoot}>
                <RedirectToLastSubtab urlRoot={urlRoot} defaultSubtab='updates'/>
            </Route>
        </Switch>
    )
}

export default TaskDetailRouter
