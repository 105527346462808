import { useState, useContext } from 'react'
import { useHistory, useLocation, Link } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { currentUserVar } from '../../cache'
import { TabNavContext } from '../../components/TabNav'
import { getProp } from '../../utils'
import { GET_TICKETS } from '../queries'
import { DUPLICATE_TICKET, MERGE_TICKETS, ARCHIVE_TICKET, ACTIVATE_TICKET, DELETE_TICKET } from '../mutations'
import { SearchSelect } from '../../forms/Select'
import { lbl, tabNavLinks } from '../../constants'
import { Icon } from '../../components'


const TicketActions = ({ ticket }) => {
    const history = useHistory()
    const { pathname } = useLocation()
    const { pinnedTabs, unpinTab, closeTab, renameTab } = useContext(TabNavContext)
    const [mergeDropOpen, toggleMergeDrop] = useState(false)
    const [deletePopUpOpened, toggleDeletePopUp] = useState(false)
    const [mergePopUp, setMergePopUp] = useState({ open: false })
    const [archivePopUpOpened, toggleArchivePopUp] = useState(false)
    const closeMergePopUp = () => setMergePopUp(prevState => ({ ...prevState, open: false }))
    const { dynamicPath } = tabNavLinks.tickets
    const isInMessenger = pathname.includes('messenger')
    const isEditable = !ticket.archived && !ticket.deleted

    const [duplicateTicket, { loading: duplicating }] = useMutation(DUPLICATE_TICKET, {
        variables: { id: ticket.id },
        onCompleted: ({ duplicateTicket }) => {
            duplicateTicket && history.push(`${dynamicPath}${duplicateTicket.ticket.id}`)
        },
        update(cache, { data: { duplicateTicket } }) {
            cache.modify({
                fields: {
                    tickets(existingTickets = []) {
                        return [duplicateTicket.ticket, ...existingTickets];
                    }
                }
            })
            ticket.pipelineStage && cache.modify({
                id: cache.identify({ __typename: 'PipelineStageType', id: ticket.pipelineStage.id }),
                fields: {
                    tickets(existingTickets = []) {
                        return [...existingTickets, duplicateTicket.ticket]
                    }
                }
            })
        }
    })

    const [mergeTickets] = useMutation(MERGE_TICKETS, {
        onCompleted: ({ mergeTickets: { ticket: mainTicket, mergedTicketIds } }) => {
            mergedTicketIds.forEach(id => {
                const relativeUrl = dynamicPath + id
                
                pinnedTabs.get(relativeUrl) ?
                    unpinTab({ relativeUrl, preventOpen: true }) :
                    closeTab({ id, relativeUrl: dynamicPath+id })
            })
            renameTab({ name: mainTicket.name, relativeUrl: dynamicPath+mainTicket.id })
        },
        update(cache, { data: { mergeTickets: { ticket: mainTicket, mergedTicketIds, mergedTicketStatusIds } } }) {
            cache.modify({
                id: cache.identify({ __typename: 'TicketType', id: mainTicket.id }),
                fields: {
                    name() { return mainTicket.name },
                    description() { return mainTicket.description },
                    labels() { return mainTicket.labels }
                }
            })
            cache.modify({
                fields: {
                    tickets(existingTickets = [], { readField }) {
                        return existingTickets.filter(t => !mergedTicketIds.includes(readField('id', t)))
                    }
                }
            })
            mergedTicketStatusIds.forEach((pipelineStageId) => {
                pipelineStageId && cache.modify({
                    id: cache.identify({ __typename: 'PipelineStageType', id: pipelineStageId }),
                    fields: {
                        tickets(existingTickets = [], { readField }) {
                            return existingTickets.filter(t => !mergedTicketIds.includes(readField('id', t)))
                        }
                    }
                })
            })
        }
    })

    const [deleteTicket] = useMutation(DELETE_TICKET, {
        onCompleted: ({ deleteTicket: { deletedId, deletedTaskIds=[] } }) => {
            const relativeUrl = dynamicPath + deletedId
            pinnedTabs.get(relativeUrl) ?
                unpinTab({ relativeUrl, preventOpen: true }) :
                closeTab({ id: deletedId, relativeUrl })

            if (!!deletedTaskIds.length) {
                // close deleted task tabs
                deletedTaskIds.forEach(taskId => {
                    const taskRelativeUrl = tabNavLinks.tasks.dynamicPath + taskId
                    pinnedTabs.get(taskRelativeUrl) ?
                        unpinTab({ relativeUrl: taskRelativeUrl, preventOpen: true }) :
                        closeTab({ id: taskId, relativeUrl: taskRelativeUrl })
                })
            }
        },
        update(cache, { data: { deleteTicket: { deletedId, pipelineStageId, deletedTaskIds=[] } } }) {
            pipelineStageId && cache.modify({
                id: cache.identify({ __typename: 'PipelineStageType', id: pipelineStageId }),
                fields: {
                    tickets(existingTickets = [], { readField }) {
                        return existingTickets.filter(t => deletedId !== readField('id', t))
                    }
                }
            })
            const ticketsField = ticket.archived ? 'archivedTickets' : 'tickets'
            cache.modify({
                fields: {
                    [ticketsField](existingTickets = [], { readField }) {
                        return existingTickets.filter(t => deletedId !== readField('id', t))
                    }
                }
            })
            if (!!deletedTaskIds.length) {
                cache.modify({
                    fields: {
                        tasks(existingTasks = [], { readField }) {
                            return existingTasks.filter(t => !deletedTaskIds.includes(readField('id', t)))
                        },
                        archivedTasks(existingTasks = [], { readField }) {
                            return existingTasks.filter(t => !deletedTaskIds.includes(readField('id', t)))
                        }
                    }
                })
            }
        }
    })

    const [archive] = useMutation(ARCHIVE_TICKET, {
        variables: { id: ticket.id },
        update(cache, { data: { archiveTicket: { ticket: archivedTicket } } }) {
            // ticket listers will be updated via ON_NEW_CHANGE subscription
            ticket.pipelineStage && cache.modify({
                id: cache.identify({ __typename: 'PipelineStageType', id: ticket.pipelineStage.id }),
                fields: {
                    tickets(existingTickets = [], { readField }) {
                        return existingTickets.filter(t => archivedTicket.id !== readField('id', t))
                    }
                }
            })
        }
    })

    const [activate] = useMutation(ACTIVATE_TICKET, {
        variables: { id: ticket.id },
        update(cache, { data: { activateTicket } }) {
            // ticket listers will be updated via ON_NEW_CHANGE subscription
            activateTicket.ticket.pipelineStage && cache.modify({
                id: cache.identify({ __typename: 'PipelineStageType', id: ticket.pipelineStage.id }),
                fields: {
                    tickets(existingTickets = [], { toReference }) {
                        return [toReference(activateTicket.ticket), ...existingTickets]
                    }
                }
            })
        }
    })
    
    return (
        <section className='ticket__actions'>
            <ul className='action-list'>
                {Boolean(ticket.threads?.length) &&
                    <li className='action__li action__li--thread'>
                        <Link
                            className='btn btn--naked btn--inline-icon'
                            to={isInMessenger ?
                                `${dynamicPath}${ticket.id}` :
                                `${tabNavLinks.messenger.dynamicPath}${ticket.threads[0].id}`
                            }
                        >
                            <Icon type={isInMessenger ? 'ticket' : 'message'} variant='sm' />
                            {isInMessenger ? lbl.goToRelTicket : lbl.goToRelThread}
                        </Link>
                    </li>
                }
                <li className='action__li action__li--duplicate'>
                    <button
                        className='btn btn--naked btn--inline-icon'
                        onClick={duplicateTicket}
                        disabled={!isEditable || duplicating}
                    >
                        {duplicating ?
                            <span className='btn__loader t-blue-grey' style={{ marginRight: '17px'}}>&#8635;</span>
                        :
                            <Icon type='duplicate' variant='sm' />
                        }
                        {lbl.duplicate}
                    </button>
                </li>

                <li className='action__li action__li--merge pos-relative'>
                    <button
                        className='btn btn--naked btn--inline-icon'
                        onClick={() => toggleMergeDrop(isOpen => !isOpen)}
                        disabled={!isEditable}
                    >
                        <Icon type='merge' variant='sm' />
                        {lbl.merge}
                    </button>
                    {mergeDropOpen &&
                        <SearchSelect
                            multi
                            isHidden
                            searchBy='name'
                            keepSelectedInList={false}
                            onSelect={([{ id, name }]) => {
                                setMergePopUp({ open: true, id, name })
                                toggleMergeDrop(false)
                            }}
                            excludeValues={[ticket.id]}
                            onDropdownClose={() => toggleMergeDrop(false)}
                            optionsQuery={{ query: GET_TICKETS, name: 'tickets' }}
                            showLoader
                            labelField='name'
                            valueField='id'
                            placeholder={lbl.selectForMerge}
                            getLabelNode={({ item: { pipelineStage, name, archived } }) => (<>
                                <Icon
                                    type={archived ? 'archived' : pipelineStage ?  `status-${pipelineStage.isFirst ? '1' : pipelineStage.isLast ? '3' : '2'}` : 'status-0'}
                                    variant='sm'
                                />
                                <span>{name}</span>
                            </>)}
                        />
                    }
                    {mergePopUp.open &&
                        <div className='pop-up pop-up--top'>
                            <div className='pop-up__container'>
                                <header className='pop-up__header'>
                                    Confirm merge
                                </header>
                                <p className='pop-up__message t-s'>
                                    Are you sure you want to merge <strong>{ticket.name}</strong> ticket with <strong>{mergePopUp.name}</strong>?
                                </p>
                                <div className='pop-up__btns'>
                                    <button className='btn btn--secondary mr-2' onClick={closeMergePopUp}>
                                        {lbl.cancel}
                                    </button>
                                    <button
                                        className='btn btn--primary'
                                        onClick={() => {
                                            mergeTickets({ variables: { id: ticket.id, ids: [mergePopUp.id] } })
                                            closeMergePopUp()
                                        }}
                                    >
                                        {lbl.merge}
                                    </button>
                                </div>
                                <button
                                    className='btn btn--naked close mt-2 mr-2'
                                    onClick={closeMergePopUp}
                                >
                                    <Icon type='close' variant='xs' />
                                </button>
                            </div>
                        </div>
                    }
                </li>

                {ticket.archived ?
                    <li className='action__li action__li--activate'>
                        <button
                            className='btn btn--naked btn--inline-icon'
                            onClick={activate}
                            disabled={ticket.deleted}
                        >
                            <Icon type='activate' variant='sm' />
                            {lbl.restore}
                        </button>
                    </li>
                :
                    <li className='action__li action__li--archive pos-relative'>
                        <button
                            className='btn btn--naked btn--inline-icon'
                            onClick={() => toggleArchivePopUp(true)}
                            disabled={ticket.deleted}
                        >
                            <Icon type='archive' variant='sm' />
                            {lbl.archive}
                        </button>
                        {archivePopUpOpened &&
                            <div className='pop-up pop-up--top'>
                                <div className='pop-up__container'>
                                    <header className='pop-up__header'>
                                        Confirm archive
                                    </header>
                                    <div className='pop-up__message t-s'>
                                        <p>Are you sure you want to archive <strong>{ticket.name}</strong> ticket and all related threads{!ticket.tasks.length ? '' : ' and tasks'}?</p>
                                        {ticket.pipelineStage && 
                                            <p>Ticket will lose status and be removed from <strong>{ticket.pipeline.name}</strong> board.</p>
                                        }
                                    </div>
                                    <div className='pop-up__btns'>
                                        <button className='btn btn--secondary mr-2' onClick={() => toggleArchivePopUp(false)}>
                                            {lbl.cancel}
                                        </button>
                                        <button
                                            className='btn btn--primary'
                                            onClick={() => {
                                                archive()
                                                toggleArchivePopUp(false)
                                            }}
                                        >
                                            {lbl.archive}
                                        </button>
                                    </div>
                                    <button
                                        className='btn btn--naked close mt-2 mr-2'
                                        onClick={() => toggleArchivePopUp(false)}
                                    >
                                        <Icon type='close' variant='xs' />
                                    </button>
                                </div>
                            </div>
                        }
                    </li>
                }

                {!ticket.deleted && (getProp('isSuperuser', currentUserVar()) || ticket.createdBy?.id === getProp('id', currentUserVar())) &&
                    <li className='action__li action__li--delete pos-relative'>
                        <button className='btn btn--naked btn--inline-icon' onClick={() => toggleDeletePopUp(true)}>
                            <Icon type='bin' variant='sm' />
                            {lbl.delete}
                        </button>
                        {deletePopUpOpened &&
                            <div className='pop-up pop-up--top'>
                                <div className='pop-up__container'>
                                    <header className='pop-up__header'>
                                        Confirm delete
                                    </header>
                                    <div className='pop-up__message t-s'>
                                        <p>Are you sure you want to delete <strong>{ticket.name}</strong>?</p>
                                        <p>{`Ticket and all related threads ${!ticket.tasks.length ? '' : 'and tasks '}will be permanently deleted and will not available in archive.`}</p>
                                    </div>
                                    <div className='pop-up__btns'>
                                        <button className='btn btn--secondary mr-2' onClick={() => toggleDeletePopUp(false)}>
                                            {lbl.cancel}
                                        </button>
                                        <button
                                            className='btn btn--primary'
                                            onClick={() => {
                                                deleteTicket({ variables: { id: ticket.id } })
                                                toggleDeletePopUp(false)
                                            }}
                                        >
                                            {lbl.delete}
                                        </button>
                                    </div>
                                    <button
                                        className='btn btn--naked close mt-2 mr-2'
                                        onClick={() => toggleDeletePopUp(false)}
                                    >
                                        <Icon type='close' variant='xs' />
                                    </button>
                                </div>
                            </div>
                        }
                    </li>
                }
            </ul>
        </section>
    )
}

export default TicketActions
