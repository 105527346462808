const getSvg = (type) => {
    switch (type) {
        case 'attachment':
            return <path d='M24 14V25.5C24 27.71 22.21 29.5 20 29.5C17.79 29.5 16 27.71 16 25.5V13C16 11.62 17.12 10.5 18.5 10.5C19.88 10.5 21 11.62 21 13V23.5C21 24.05 20.55 24.5 20 24.5C19.45 24.5 19 24.05 19 23.5V14H17.5V23.5C17.5 24.88 18.62 26 20 26C21.38 26 22.5 24.88 22.5 23.5V13C22.5 10.79 20.71 9 18.5 9C16.29 9 14.5 10.79 14.5 13V25.5C14.5 28.54 16.96 31 20 31C23.04 31 25.5 28.54 25.5 25.5V14H24Z'/>
        case 'pin':
            return <path d='M24 20V12H25V10H15V12H16V20L14 22V24H19.2V30H20.8V24H26V22L24 20Z' />
        case 'unpin':
            return <>
                <polygon points='28,26.2 26.6,27.7 22.9,24 20.8,24 20.8,30 19.2,30 19.2,24 14,24 14,22 16,20 16,17.1 11.7,12.8 13.2,11.3'/>
                <polygon points='15,10.7 15,10 25,10 25,12 24,12 24,19.9'/>
            </>
        case 'inbox':
            return <path d='M27 23H23C23 23.7956 22.6839 24.5587 22.1213 25.1213C21.5587 25.6839 20.7956 26 20 26C19.2044 26 18.4413 25.6839 17.8787 25.1213C17.3161 24.5587 17 23.7956 17 23H13V13H27V23ZM27 11H13C11.89 11 11 11.9 11 13V27C11 27.5304 11.2107 28.0391 11.5858 28.4142C11.9609 28.7893 12.4696 29 13 29H27C27.5304 29 28.0391 28.7893 28.4142 28.4142C28.7893 28.0391 29 27.5304 29 27V13C29 12.4696 28.7893 11.9609 28.4142 11.5858C28.0391 11.2107 27.5304 11 27 11Z'/>
        case 'bell':
            return <path d='M22 28C22 28.5304 21.7893 29.0391 21.4142 29.4142C21.0391 29.7893 20.5304 30 20 30C19.4696 30 18.9609 29.7893 18.5858 29.4142C18.2107 29.0391 18 28.5304 18 28H22ZM20 10C20.2652 10 20.5196 10.1054 20.7071 10.2929C20.8946 10.4804 21 10.7348 21 11V12.08C23.84 12.56 26 15.03 26 18V24L29 27H11L14 24V18C14 15.03 16.16 12.56 19 12.08V11C19 10.7348 19.1054 10.4804 19.2929 10.2929C19.4804 10.1054 19.7348 10 20 10Z'/>
        case 'bell-off':
            return <path d='M22 28C22 28.5304 21.7893 29.0391 21.4142 29.4142C21.0391 29.7893 20.5304 30 20 30C19.4696 30 18.9609 29.7893 18.5858 29.4142C18.2107 29.0391 18 28.5304 18 28H22ZM27.74 29.57L25.17 27H11L14 24V18C14 17.35 14.1 16.72 14.3 16.13L11.47 13.3L12.89 11.89L15.29 14.29L29.15 28.15L27.74 29.57ZM19 12.08V11C19 10.7348 19.1054 10.4804 19.2929 10.2929C19.4804 10.1054 19.7348 10 20 10C20.2652 10 20.5196 10.1054 20.7071 10.2929C20.8946 10.4804 21 10.7348 21 11V12.08C23.84 12.56 26 15.03 26 18V22.17L16.77 12.94C17.44 12.5 18.19 12.22 19 12.08Z'/>
        case 'send':
            return <path d='M9 29L30 20L9 11V18L24 20L9 22V29Z' />
        case 'contact-multi':
            return <path d='M24 21C23.71 21 23.38 21 23.03 21.05C24.19 21.89 25 23 25 24.5V27H31V24.5C31 22.17 26.33 21 24 21ZM16 21C13.67 21 9 22.17 9 24.5V27H23V24.5C23 22.17 18.33 21 16 21ZM16 19C16.7956 19 17.5587 18.6839 18.1213 18.1213C18.6839 17.5587 19 16.7956 19 16C19 15.2044 18.6839 14.4413 18.1213 13.8787C17.5587 13.3161 16.7956 13 16 13C15.2044 13 14.4413 13.3161 13.8787 13.8787C13.3161 14.4413 13 15.2044 13 16C13 16.7956 13.3161 17.5587 13.8787 18.1213C14.4413 18.6839 15.2044 19 16 19ZM24 19C24.7956 19 25.5587 18.6839 26.1213 18.1213C26.6839 17.5587 27 16.7956 27 16C27 15.2044 26.6839 14.4413 26.1213 13.8787C25.5587 13.3161 24.7956 13 24 13C23.2044 13 22.4413 13.3161 21.8787 13.8787C21.3161 14.4413 21 15.2044 21 16C21 16.7956 21.3161 17.5587 21.8787 18.1213C22.4413 18.6839 23.2044 19 24 19Z'/>
        case 'contact-multi-add':
            return <path d='M21 21C19 21 15 22 15 24V26H27V24C27 22 23 21 21 21ZM27.62 21.16C28.45 21.88 29 22.82 29 24V26H32V24C32 22.46 29.63 21.5 27.62 21.16ZM21 19C21.7956 19 22.5587 18.6839 23.1213 18.1213C23.6839 17.5587 24 16.7956 24 16C24 15.2044 23.6839 14.4413 23.1213 13.8787C22.5587 13.3161 21.7956 13 21 13C20.2044 13 19.4413 13.3161 18.8787 13.8787C18.3161 14.4413 18 15.2044 18 16C18 16.7956 18.3161 17.5587 18.8787 18.1213C19.4413 18.6839 20.2044 19 21 19ZM26 19C26.7956 19 27.5587 18.6839 28.1213 18.1213C28.6839 17.5587 29 16.7956 29 16C29 15.2044 28.6839 14.4413 28.1213 13.8787C27.5587 13.3161 26.7956 13 26 13C25.68 13 25.37 13.05 25.08 13.14C25.65 13.95 26 14.94 26 16C26 17.06 25.65 18.04 25.08 18.85C25.37 18.95 25.68 19 26 19ZM16 18H13V15H11V18H8V20H11V23H13V20H16V18Z'/>
        case 'contact-multi-remove':
            return <path d='M21 21C19 21 15 22 15 24V26H27V24C27 22 23 21 21 21ZM27.62 21.16C28.45 21.88 29 22.82 29 24V26H32V24C32 22.46 29.63 21.5 27.62 21.16ZM21 19C21.7956 19 22.5587 18.6839 23.1213 18.1213C23.6839 17.5587 24 16.7956 24 16C24 15.2044 23.6839 14.4413 23.1213 13.8787C22.5587 13.3161 21.7956 13 21 13C20.2044 13 19.4413 13.3161 18.8787 13.8787C18.3161 14.4413 18 15.2044 18 16C18 16.7956 18.3161 17.5587 18.8787 18.1213C19.4413 18.6839 20.2044 19 21 19ZM26 19C26.7956 19 27.5587 18.6839 28.1213 18.1213C28.6839 17.5587 29 16.7956 29 16C29 15.2044 28.6839 14.4413 28.1213 13.8787C27.5587 13.3161 26.7956 13 26 13C25.68 13 25.37 13.05 25.08 13.14C25.65 13.95 26 14.94 26 16C26 17.06 25.65 18.04 25.08 18.85C25.37 18.95 25.68 19 26 19ZM16 18H8V20H16V18Z'/>
        case 'checkmark':
        case 'approvalRequired':
            return <path d='M28.5 14.41L16.5 26.41L11 20.91L12.41 19.5L16.5 23.58L27.09 13L28.5 14.41Z'/>
        case 'checkmark-double':
            return <path d='M8 20.83L13.59 26.42L15 25L9.42 19.42L8 20.83ZM29.83 13L19.25 23.59L15.09 19.42L13.66 20.83L19.25 26.42L31.25 14.42L29.83 13ZM25.59 14.42L24.18 13L17.83 19.35L19.25 20.76L25.59 14.42Z'/>
        case 'calendar':
            return <path d='M27 28H13V17H27V28ZM24 10V12H16V10H14V12H13C11.89 12 11 12.89 11 14V28C11 28.5304 11.2107 29.0391 11.5858 29.4142C11.9609 29.7893 12.4696 30 13 30H27C27.5304 30 28.0391 29.7893 28.4142 29.4142C28.7893 29.0391 29 28.5304 29 28V14C29 12.89 28.1 12 27 12H26V10'/>
        case 'contact':
            return <path d='M14 25C14 23 18 21.9 20 21.9C22 21.9 26 23 26 25V26H14V25ZM23 17C23 17.7956 22.6839 18.5587 22.1213 19.1213C21.5587 19.6839 20.7956 20 20 20C19.2044 20 18.4413 19.6839 17.8787 19.1213C17.3161 18.5587 17 17.7956 17 17C17 16.2044 17.3161 15.4413 17.8787 14.8787C18.4413 14.3161 19.2044 14 20 14C20.7956 14 21.5587 14.3161 22.1213 14.8787C22.6839 15.4413 23 16.2044 23 17ZM11 13V27C11 27.5304 11.2107 28.0391 11.5858 28.4142C11.9609 28.7893 12.4696 29 13 29H27C27.5304 29 28.0391 28.7893 28.4142 28.4142C28.7893 28.0391 29 27.5304 29 27V13C29 12.4696 28.7893 11.9609 28.4142 11.5858C28.0391 11.2107 27.5304 11 27 11H13C11.89 11 11 11.9 11 13Z'/>    
        case 'creaditCard':
        case 'bankaccount':
            return <path d="M28 16H12V14H28V16ZM28 26H12V20H28V26ZM28 12H12C10.89 12 10 12.89 10 14V26C10 26.5304 10.2107 27.0391 10.5858 27.4142C10.9609 27.7893 11.4696 28 12 28H28C28.5304 28 29.0391 27.7893 29.4142 27.4142C29.7893 27.0391 30 26.5304 30 26V14C30 12.89 29.1 12 28 12Z"/>
        case 'message':
        case 'thread':
        case 'threads':
            return <path fillRule='evenodd' clipRule='evenodd' d='M28 10H12C10.9 10 10 10.9 10 12V30L14 26H28C29.1 26 30 25.1 30 24V12C30 10.9 29.1 10 28 10Z'/>
        case 'email':
            return <path d='M28 16L20 21L12 16V14L20 19L28 14V16ZM28 12H12C10.89 12 10 12.89 10 14V26C10 26.5304 10.2107 27.0391 10.5858 27.4142C10.9609 27.7893 11.4696 28 12 28H28C28.5304 28 29.0391 27.7893 29.4142 27.4142C29.7893 27.0391 30 26.5304 30 26V14C30 12.89 29.1 12 28 12Z'/>
        case 'eye':
            return <path d='M20 16.5C19.2044 16.5 18.4413 16.8161 17.8787 17.3787C17.3161 17.9413 17 18.7044 17 19.5C17 20.2956 17.3161 21.0587 17.8787 21.6213C18.4413 22.1839 19.2044 22.5 20 22.5C20.7956 22.5 21.5587 22.1839 22.1213 21.6213C22.6839 21.0587 23 20.2956 23 19.5C23 18.7044 22.6839 17.9413 22.1213 17.3787C21.5587 16.8161 20.7956 16.5 20 16.5ZM20 24.5C18.6739 24.5 17.4021 23.9732 16.4645 23.0355C15.5268 22.0979 15 20.8261 15 19.5C15 18.1739 15.5268 16.9021 16.4645 15.9645C17.4021 15.0268 18.6739 14.5 20 14.5C21.3261 14.5 22.5979 15.0268 23.5355 15.9645C24.4732 16.9021 25 18.1739 25 19.5C25 20.8261 24.4732 22.0979 23.5355 23.0355C22.5979 23.9732 21.3261 24.5 20 24.5ZM20 12C15 12 10.73 15.11 9 19.5C10.73 23.89 15 27 20 27C25 27 29.27 23.89 31 19.5C29.27 15.11 25 12 20 12Z'/>
        case 'eye-off':
            return <path d='M19.83 17L23 20.16C23 20.11 23 20.05 23 20C23 19.2044 22.6839 18.4413 22.1213 17.8787C21.5587 17.3161 20.7956 17 20 17C19.94 17 19.89 17 19.83 17ZM15.53 17.8L17.08 19.35C17.03 19.56 17 19.77 17 20C17 20.7956 17.3161 21.5587 17.8787 22.1213C18.4413 22.6839 19.2044 23 20 23C20.22 23 20.44 22.97 20.65 22.92L22.2 24.47C21.53 24.8 20.79 25 20 25C18.6739 25 17.4021 24.4732 16.4645 23.5355C15.5268 22.5979 15 21.3261 15 20C15 19.21 15.2 18.47 15.53 17.8ZM10 12.27L12.28 14.55L12.73 15C11.08 16.3 9.78 18 9 20C10.73 24.39 15 27.5 20 27.5C21.55 27.5 23.03 27.2 24.38 26.66L24.81 27.08L27.73 30L29 28.73L11.27 11L10 12.27ZM20 15C21.3261 15 22.5979 15.5268 23.5355 16.4645C24.4732 17.4021 25 18.6739 25 20C25 20.64 24.87 21.26 24.64 21.82L27.57 24.75C29.07 23.5 30.27 21.86 31 20C29.27 15.61 25 12.5 20 12.5C18.6 12.5 17.26 12.75 16 13.2L18.17 15.35C18.74 15.13 19.35 15 20 15Z'/>
        case 'message-notification':
            return <path d='M21 18H19V14H21V18ZM21 22H19V20H21V22ZM28 10H12C11.4696 10 10.9609 10.2107 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12V30L14 26H28C28.5304 26 29.0391 25.7893 29.4142 25.4142C29.7893 25.0391 30 24.5304 30 24V12C30 10.89 29.1 10 28 10Z'/>
        case 'registered-mail':
            return <path fillRule='evenodd' clipRule='evenodd' d='M25 13H15C12.2386 13 10 15.2386 10 18V25.5C10 26.1904 10.5596 26.75 11.25 26.75H28.75C29.4404 26.75 30 26.1904 30 25.5V18C30 15.2386 27.7614 13 25 13ZM20 18C20 16.5066 19.3453 15.1662 18.3073 14.25H25C27.0711 14.25 28.75 15.9289 28.75 18V25.5H20V18ZM21.25 19.875H24.7411L25.8081 20.9419C25.9253 21.0592 26.0842 21.125 26.25 21.125H27.5C27.8452 21.125 28.125 20.8452 28.125 20.5V19.25C28.125 18.9048 27.8452 18.625 27.5 18.625H21.25V19.875ZM15.7311 18.1957C16.0456 18.33 16.2502 18.4175 16.2502 18C16.2502 17.3096 15.6906 16.75 15.0002 16.75C14.3099 16.75 13.7502 17.3096 13.7502 18C13.7502 18.4175 13.9549 18.33 14.2693 18.1957C14.4749 18.1079 14.7273 18 15.0002 18C15.2731 18 15.5256 18.1079 15.7311 18.1957Z'/>
        case 'sms':
            return <path d='M26 16H14V14H26V16ZM26 19H14V17H26V19ZM26 22H14V20H26V22ZM30 12C30 11.4696 29.7893 10.9609 29.4142 10.5858C29.0391 10.2107 28.5304 10 28 10H12C11.4696 10 10.9609 10.2107 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12V24C10 24.5304 10.2107 25.0391 10.5858 25.4142C10.9609 25.7893 11.4696 26 12 26H26L30 30V12Z'/>    
        case 'call':
            return <path d='M14.62 18.79C16.06 21.62 18.38 23.94 21.21 25.38L23.41 23.18C23.69 22.9 24.08 22.82 24.43 22.93C25.55 23.3 26.75 23.5 28 23.5C28.2652 23.5 28.5196 23.6054 28.7071 23.7929C28.8946 23.9804 29 24.2348 29 24.5V28C29 28.2652 28.8946 28.5196 28.7071 28.7071C28.5196 28.8946 28.2652 29 28 29C23.4913 29 19.1673 27.2089 15.9792 24.0208C12.7911 20.8327 11 16.5087 11 12C11 11.7348 11.1054 11.4804 11.2929 11.2929C11.4804 11.1054 11.7348 11 12 11H15.5C15.7652 11 16.0196 11.1054 16.2071 11.2929C16.3946 11.4804 16.5 11.7348 16.5 12C16.5 13.25 16.7 14.45 17.07 15.57C17.18 15.92 17.1 16.31 16.82 16.59L14.62 18.79Z' />
        case 'call-outbound':
            return <path d='M12 11C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12C11 16.5087 12.7911 20.8327 15.9792 24.0208C19.1673 27.2089 23.4913 29 28 29C28.2652 29 28.5196 28.8946 28.7071 28.7071C28.8946 28.5196 29 28.2652 29 28V24.5C29 24.2348 28.8946 23.9804 28.7071 23.7929C28.5196 23.6054 28.2652 23.5 28 23.5C26.75 23.5 25.55 23.3 24.43 22.93C24.08 22.82 23.69 22.9 23.41 23.17L21.21 25.37C18.38 23.93 16.06 21.62 14.62 18.78L16.82 16.57C17.1 16.31 17.18 15.92 17.07 15.57C16.7 14.45 16.5 13.25 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H12ZM23 11V12.5H26.5L21 18L22 19L27.5 13.5V17H29V11H23Z' />
        case 'call-log':
            return <path d='M28 23.5C28.2652 23.5 28.5196 23.6054 28.7071 23.7929C28.8946 23.9804 29 24.2348 29 24.5V28C29 28.2652 28.8946 28.5196 28.7071 28.7071C28.5196 28.8946 28.2652 29 28 29C23.4913 29 19.1673 27.2089 15.9792 24.0208C12.7911 20.8327 11 16.5087 11 12C11 11.7348 11.1054 11.4804 11.2929 11.2929C11.4804 11.1054 11.7348 11 12 11H15.5C15.7652 11 16.0196 11.1054 16.2071 11.2929C16.3946 11.4804 16.5 11.7348 16.5 12C16.5 13.24 16.7 14.45 17.07 15.57C17.18 15.92 17.1 16.31 16.82 16.58L14.62 18.79C16.06 21.62 18.38 23.94 21.21 25.38L23.41 23.18C23.69 22.9 24.08 22.82 24.43 22.93C25.55 23.3 26.75 23.5 28 23.5ZM20 11H22V13H20V11ZM23 11H29V13H23V11ZM20 14H22V16H20V14ZM23 14H29V16H23V14ZM20 17H22V19H20V17ZM23 17H29V19H23' />
        case 'call-in-talk':
            return <path d='M23 20H25C25 18.6739 24.4732 17.4021 23.5355 16.4645C22.5979 15.5268 21.3261 15 20 15V17C20.7956 17 21.5587 17.3161 22.1213 17.8787C22.6839 18.4413 23 19.2044 23 20ZM27 20H29C29 15 24.97 11 20 11V13C23.86 13 27 16.13 27 20ZM28 23.5C26.75 23.5 25.55 23.3 24.43 22.93C24.08 22.82 23.69 22.9 23.41 23.18L21.21 25.38C18.38 23.94 16.06 21.62 14.62 18.79L16.82 16.59C17.1 16.31 17.18 15.92 17.07 15.57C16.7 14.45 16.5 13.25 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H12C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12C11 16.5087 12.7911 20.8327 15.9792 24.0208C19.1673 27.2089 23.4913 29 28 29C28.2652 29 28.5196 28.8946 28.7071 28.7071C28.8946 28.5196 29 28.2652 29 28V24.5C29 24.2348 28.8946 23.9804 28.7071 23.7929C28.5196 23.6054 28.2652 23.5 28 23.5Z' />
        case 'call-inbound':
            return <path d='M12 11C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12C11 16.5087 12.7911 20.8327 15.9792 24.0208C19.1673 27.2089 23.4913 29 28 29C28.2652 29 28.5196 28.8946 28.7071 28.7071C28.8946 28.5196 29 28.2652 29 28V24.5C29 24.2348 28.8946 23.9804 28.7071 23.7929C28.5196 23.6054 28.2652 23.5 28 23.5C26.75 23.5 25.55 23.3 24.43 22.93C24.08 22.82 23.69 22.9 23.41 23.17L21.21 25.37C18.38 23.93 16.06 21.62 14.62 18.78L16.82 16.57C17.1 16.31 17.18 15.92 17.07 15.57C16.7 14.45 16.5 13.25 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H12ZM27 19V17.5H23.5L29 12L28 11L22.5 16.5V13H21V19H27Z' />
        case 'call-hangup':
            return <path d='M20 18C18.4 18 16.85 18.25 15.4 18.72V21.82C15.4 22.22 15.17 22.56 14.84 22.72C13.86 23.21 12.97 23.84 12.17 24.57C12 24.75 11.75 24.86 11.5 24.86C11.2 24.86 10.95 24.74 10.77 24.56L8.29 22.08C8.11 21.9 8 21.65 8 21.38C8 21.1 8.11 20.85 8.29 20.67C11.34 17.77 15.46 16 20 16C24.54 16 28.66 17.77 31.71 20.67C31.89 20.85 32 21.1 32 21.38C32 21.65 31.89 21.9 31.71 22.08L29.23 24.56C29.05 24.74 28.8 24.86 28.5 24.86C28.25 24.86 28 24.75 27.82 24.57C27.03 23.84 26.14 23.21 25.16 22.72C24.83 22.56 24.6 22.22 24.6 21.82V18.72C23.15 18.25 21.6 18 20 18Z' />
        case 'call-missed':
            return <path d='M31.71 24.67C28.66 21.77 24.54 20 20 20C15.46 20 11.34 21.77 8.29 24.67C8.11 24.85 8 25.1 8 25.38C8 25.65 8.11 25.9 8.29 26.08L10.77 28.56C10.95 28.74 11.2 28.86 11.5 28.86C11.75 28.86 12 28.75 12.18 28.57C12.97 27.83 13.86 27.21 14.84 26.72C15.17 26.56 15.4 26.22 15.4 25.82V22.72C16.85 22.25 18.39 22 20 22C21.6 22 23.15 22.25 24.6 22.72V25.82C24.6 26.22 24.83 26.56 25.16 26.72C26.14 27.21 27.03 27.83 27.82 28.57C28 28.75 28.25 28.86 28.5 28.86C28.8 28.86 29.05 28.74 29.23 28.56L31.71 26.08C31.89 25.9 32 25.65 32 25.38C32 25.1 31.89 24.85 31.71 24.67ZM14.5 13.5L20 19L27 12L26 11L20 17L15.5 12.5H19V11H13V17H14.5V13.5Z' />
        case 'call-locked':
            return <path d='M27.2 13H23.8V12.5C23.8 11.56 24.56 10.8 25.5 10.8C26.44 10.8 27.2 11.56 27.2 12.5V13ZM28 13V12.5C28 11.837 27.7366 11.2011 27.2678 10.7322C26.7989 10.2634 26.163 10 25.5 10C24.837 10 24.2011 10.2634 23.7322 10.7322C23.2634 11.2011 23 11.837 23 12.5V13C22.7348 13 22.4804 13.1054 22.2929 13.2929C22.1054 13.4804 22 13.7348 22 14V18C22 18.2652 22.1054 18.5196 22.2929 18.7071C22.4804 18.8946 22.7348 19 23 19H28C28.2652 19 28.5196 18.8946 28.7071 18.7071C28.8946 18.5196 29 18.2652 29 18V14C29 13.7348 28.8946 13.4804 28.7071 13.2929C28.5196 13.1054 28.2652 13 28 13ZM28 24.5C26.75 24.5 25.55 24.3 24.43 23.93C24.08 23.82 23.69 23.9 23.41 24.18L21.21 26.38C18.38 24.94 16.06 22.62 14.62 19.79L16.82 17.59C17.1 17.31 17.18 16.92 17.07 16.57C16.7 15.45 16.5 14.25 16.5 13C16.5 12.7348 16.3946 12.4804 16.2071 12.2929C16.0196 12.1054 15.7652 12 15.5 12H12C11.7348 12 11.4804 12.1054 11.2929 12.2929C11.1054 12.4804 11 12.7348 11 13C11 17.5087 12.7911 21.8327 15.9792 25.0208C19.1673 28.2089 23.4913 30 28 30C28.2652 30 28.5196 29.8946 28.7071 29.7071C28.8946 29.5196 29 29.2652 29 29V25.5C29 25.2348 28.8946 24.9804 28.7071 24.7929C28.5196 24.6054 28.2652 24.5 28 24.5Z' />
        case 'call-minus':
            return <path d='M12 11C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12C11 16.5087 12.7911 20.8327 15.9792 24.0208C19.1673 27.2089 23.4913 29 28 29C28.2652 29 28.5196 28.8946 28.7071 28.7071C28.8946 28.5196 29 28.2652 29 28V24.5C29 24.2348 28.8946 23.9804 28.7071 23.7929C28.5196 23.6054 28.2652 23.5 28 23.5C26.76 23.5 25.55 23.3 24.43 22.93C24.08 22.82 23.69 22.9 23.41 23.18L21.21 25.38C18.38 23.94 16.07 21.62 14.62 18.79L16.82 16.58C17.1 16.31 17.18 15.92 17.07 15.57C16.7 14.45 16.5 13.24 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H12ZM21 14V16H29V14' />
        case 'call-forward':
            return <path d='M27 24.5C25.75 24.5 24.55 24.3 23.43 23.93C23.08 23.82 22.69 23.9 22.41 24.18L20.21 26.38C17.38 24.94 15.06 22.62 13.62 19.79L15.82 17.59C16.1 17.31 16.18 16.92 16.07 16.57C15.7 15.45 15.5 14.25 15.5 13C15.5 12.7348 15.3946 12.4804 15.2071 12.2929C15.0196 12.1054 14.7652 12 14.5 12H11C10.7348 12 10.4804 12.1054 10.2929 12.2929C10.1054 12.4804 10 12.7348 10 13C10 17.5087 11.7911 21.8327 14.9792 25.0208C18.1673 28.2089 22.4913 30 27 30C27.2652 30 27.5196 29.8946 27.7071 29.7071C27.8946 29.5196 28 29.2652 28 29V25.5C28 25.2348 27.8946 24.9804 27.7071 24.7929C27.5196 24.6054 27.2652 24.5 27 24.5ZM25 20L30 15L25 10V13H21V17H25V20Z' />
        case 'microphone':
            return <path d='M20 10C20.7956 10 21.5587 10.3161 22.1213 10.8787C22.6839 11.4413 23 12.2044 23 13V19C23 19.7956 22.6839 20.5587 22.1213 21.1213C21.5587 21.6839 20.7956 22 20 22C19.2044 22 18.4413 21.6839 17.8787 21.1213C17.3161 20.5587 17 19.7956 17 19V13C17 12.2044 17.3161 11.4413 17.8787 10.8787C18.4413 10.3161 19.2044 10 20 10ZM27 19C27 22.53 24.39 25.44 21 25.93V29H19V25.93C15.61 25.44 13 22.53 13 19H15C15 20.3261 15.5268 21.5979 16.4645 22.5355C17.4021 23.4732 18.6739 24 20 24C21.3261 24 22.5979 23.4732 23.5355 22.5355C24.4732 21.5979 25 20.3261 25 19H27Z' />
        case 'microphone-off':
            return <path d='M27 19C27 20.19 26.66 21.3 26.1 22.28L24.87 21.05C25.14 20.43 25.3 19.74 25.3 19H27ZM23 19.16L17 13.18V13C17 12.2044 17.3161 11.4413 17.8787 10.8787C18.4413 10.3161 19.2044 10 20 10C20.7956 10 21.5587 10.3161 22.1213 10.8787C22.6839 11.4413 23 12.2044 23 13V19V19.16ZM12.27 11L29 27.73L27.73 29L23.54 24.81C22.77 25.27 21.91 25.58 21 25.72V29H19V25.72C15.72 25.23 13 22.41 13 19H14.7C14.7 22 17.24 24.1 20 24.1C20.81 24.1 21.6 23.91 22.31 23.58L20.65 21.92L20 22C19.2044 22 18.4413 21.6839 17.8787 21.1213C17.3161 20.5587 17 19.7956 17 19V18.28L11 12.27L12.27 11Z' />
        case 'close':
            return <path d='M27 14.41L25.59 13L20 18.59L14.41 13L13 14.41L18.59 20L13 25.59L14.41 27L20 21.41L25.59 27L27 25.59L21.41 20L27 14.41Z' />
        case 'board':
        case 'pipeline':
            return <path d='M21 11V17H29V11H21ZM21 29H29V19H21V29ZM11 29H19V23H11V29ZM11 21H19V11H11V21Z'/>
        case 'list':
            return <path d='M11 12H29V16H11V12ZM11 18H29V22H11V18ZM11 24H29V28H11V24Z'/>
        case 'search':
            return <path d='M17.5 11C19.2239 11 20.8772 11.6848 22.0962 12.9038C23.3152 14.1228 24 15.7761 24 17.5C24 19.11 23.41 20.59 22.44 21.73L22.71 22H23.5L28.5 27L27 28.5L22 23.5V22.71L21.73 22.44C20.59 23.41 19.11 24 17.5 24C15.7761 24 14.1228 23.3152 12.9038 22.0962C11.6848 20.8772 11 19.2239 11 17.5C11 15.7761 11.6848 14.1228 12.9038 12.9038C14.1228 11.6848 15.7761 11 17.5 11ZM17.5 13C15 13 13 15 13 17.5C13 20 15 22 17.5 22C20 22 22 20 22 17.5C22 15 20 13 17.5 13Z' />
        case 'pencil':
            return <path d='M28.71 15.0425C29.1 14.6525 29.1 14.0025 28.71 13.6325L26.37 11.2925C26 10.9025 25.35 10.9025 24.96 11.2925L23.12 13.1225L26.87 16.8725L28.71 15.0425ZM11 25.2525V29.0025H14.75L25.81 17.9325L22.06 14.1825L11 25.2525Z'/>
        case 'status-0':
            return <>
                <path d='M29.1391 19.0569C29.6445 19.0706 30.0134 19.4667 29.9997 19.9995C29.9997 20.5049 29.5899 20.9147 29.1118 20.9147C28.6064 20.9147 28.1829 20.4502 28.2102 19.9312C28.2239 19.4394 28.6474 19.0433 29.1391 19.0569Z' fill='#78909C'/>
                <path d='M26.4208 27.3486C25.929 27.3349 25.5329 26.8978 25.5465 26.4061C25.5602 25.9006 25.9837 25.5182 26.4754 25.5318C26.9672 25.5455 27.377 25.9826 27.3633 26.4607C27.336 26.9525 26.8852 27.3623 26.4208 27.3486Z' fill='#78909C'/>
                <path d='M16.4763 10.6969C16.9817 10.6832 17.3915 11.0794 17.4052 11.5711C17.4188 12.0629 17.0227 12.4863 16.5173 12.5C16.0118 12.5137 15.6157 12.1312 15.602 11.6258C15.5884 11.1067 15.9572 10.7105 16.4763 10.6969Z' fill='#78909C'/>
                <path d='M29.303 16.4751C29.303 16.9805 28.9068 17.3903 28.4151 17.4039C27.9233 17.4176 27.5135 17.0078 27.4999 16.516C27.4862 16.0106 27.8687 15.6145 28.3878 15.6008C28.8932 15.5735 29.2893 15.956 29.303 16.4751Z' fill='#78909C'/>
                <path d='M16.5445 27.5125C17.05 27.5261 17.4324 27.9222 17.4188 28.4277C17.4051 28.9467 17.0226 29.3156 16.5036 29.3019C15.9845 29.2882 15.6157 28.9058 15.6157 28.3867C15.6293 27.8949 16.0391 27.5125 16.5445 27.5125Z' fill='#78909C'/>
                <path d='M27.3223 13.5109C27.336 14.0026 26.9399 14.4261 26.4344 14.4397C25.929 14.4534 25.5056 14.0709 25.4919 13.5792C25.4783 13.1011 25.888 12.664 26.3798 12.6503C26.8716 12.6093 27.3087 13.0191 27.3223 13.5109Z' fill='#78909C'/>
                <path d='M11.5724 15.6283C12.0641 15.6283 12.4876 16.0381 12.4876 16.5299C12.4876 17.0216 12.0915 17.4314 11.586 17.4314C11.0806 17.4314 10.6845 17.049 10.6845 16.5435C10.6845 16.0245 11.067 15.6283 11.5724 15.6283Z' fill='#78909C'/>
                <path d='M14.4819 26.4878C14.4682 26.9795 14.0311 27.3893 13.553 27.362C13.0749 27.3484 12.6515 26.8976 12.6788 26.4195C12.6924 25.9277 13.1159 25.5452 13.6213 25.5589C14.0994 25.5726 14.4956 25.9824 14.4819 26.4878Z' fill='#78909C'/>
                <path d='M12.4876 23.5102C12.4876 24.0156 12.0915 24.3981 11.5861 24.3981C11.0807 24.3981 10.6982 24.0156 10.6982 23.4965C10.6982 22.9911 11.0943 22.595 11.5997 22.595C12.0915 22.595 12.5013 23.0048 12.4876 23.5102Z' fill='#78909C'/>
                <path d='M29.3167 23.4554C29.3303 23.9745 28.9615 24.3706 28.4424 24.3843C27.937 24.398 27.5272 24.0155 27.5135 23.5101C27.4999 23.0047 27.8824 22.6085 28.3878 22.5949C28.9069 22.5675 29.303 22.95 29.3167 23.4554Z' fill='#78909C'/>
                <path d='M19.9733 28.1953C20.4514 28.1817 20.9021 28.5778 20.9158 29.0696C20.9431 29.5613 20.5333 29.9848 20.0416 29.9985C19.5361 30.0121 19.1127 29.6296 19.099 29.1379C19.0854 28.6461 19.4952 28.2227 19.9733 28.1953Z' fill='#78909C'/>
                <path d='M23.4701 29.3019C22.9647 29.2883 22.5823 28.8921 22.5959 28.3731C22.6096 27.8676 23.0194 27.4852 23.5248 27.4988C24.0302 27.5125 24.4127 27.9086 24.399 28.4277C24.3717 28.9468 23.9756 29.3156 23.4701 29.3019Z' fill='#78909C'/>
                <path d='M10.0015 19.9584C10.0288 19.453 10.4659 19.0842 10.9577 19.1252C11.4494 19.1661 11.8319 19.6033 11.7909 20.095C11.7499 20.5731 11.2855 20.9829 10.8211 20.9419C10.3293 20.8873 9.97416 20.4638 10.0015 19.9584Z' fill='#78909C'/>
                <path d='M23.4702 10.6835C23.9756 10.6835 24.3717 11.0796 24.3717 11.585C24.3717 12.0631 23.9483 12.4866 23.4565 12.4866C22.9511 12.4866 22.5686 12.0905 22.5686 11.5714C22.5686 11.066 22.9511 10.6698 23.4702 10.6835Z' fill='#78909C'/>
                <path d='M13.5394 12.6641C14.0311 12.6641 14.4409 13.0603 14.4409 13.5657C14.4409 14.0711 14.0585 14.4809 13.553 14.4809C13.0613 14.4809 12.6378 14.0711 12.6378 13.5794C12.6242 13.1013 13.0476 12.6778 13.5394 12.6641Z' fill='#78909C'/>
                <path d='M20.8611 10.8746C20.8747 11.38 20.4923 11.7898 19.9868 11.8035C19.4951 11.8171 19.0716 11.4073 19.058 10.9156C19.0443 10.4238 19.4404 10.014 19.9459 10.0004C20.4649 9.98671 20.8611 10.3555 20.8611 10.8746Z' fill='#78909C'/>
            </>
        case 'status':
            return <>
                <path d='M20 26C18.4633 26 17.0615 25.4223 16 24.4722C14.7725 23.3736 14 21.777 14 20C14 18.223 14.7725 16.6264 16 15.5278C17.0615 14.5777 18.4633 14 20 14V26Z'/>
                <path fillRule='evenodd' clipRule='evenodd' d='M10 20C10 14.4772 14.4772 10 20 10C25.5228 10 30 14.4772 30 20C30 25.5228 25.5228 30 20 30C14.4772 30 10 25.5228 10 20ZM20 28C15.5817 28 12 24.4183 12 20C12 15.5817 15.5817 12 20 12C24.4183 12 28 15.5817 28 20C28 24.4183 24.4183 28 20 28Z'/>
            </>
        case 'status-1':
            return <path fillRule='evenodd' clipRule='evenodd' d='M20 10C14.5 10 10 14.5 10 20C10 25.5 14.5 30 20 30C25.5 30 30 25.5 30 20C30 14.5 25.5 10 20 10ZM20 28C15.6 28 12 24.4 12 20C12 15.6 15.6 12 20 12C24.4 12 28 15.6 28 20C28 24.4 24.4 28 20 28Z'/>
        case 'status-2':
            return <>
                <path d='M20 26C18.4633 26 17.0615 25.4223 16 24.4722C14.7725 23.3736 14 21.777 14 20C14 18.223 14.7725 16.6264 16 15.5278C17.0615 14.5777 18.4633 14 20 14V26Z' fill='#F3AA18'/>
                <path fillRule='evenodd' clipRule='evenodd' d='M10 20C10 14.4772 14.4772 10 20 10C25.5228 10 30 14.4772 30 20C30 25.5228 25.5228 30 20 30C14.4772 30 10 25.5228 10 20ZM20 28C15.5817 28 12 24.4183 12 20C12 15.5817 15.5817 12 20 12C24.4183 12 28 15.5817 28 20C28 24.4183 24.4183 28 20 28Z' fill='#F3AA18'/>
            </>
        case 'status-3':
            return <path fillRule='evenodd' clipRule='evenodd' d='M20 10C14.5 10 10 14.5 10 20C10 25.5 14.5 30 20 30C25.5 30 30 25.5 30 20C30 14.5 25.5 10 20 10ZM18 25L13 20L14.4 18.6L18 22.2L25.6 14.6L27 16L18 25Z' fill='#2FA84F'/>
        case 'priority':
            return <path d='M21 21H19V15H21V21ZM21 25H19V23H21V25ZM20 10C18.6868 10 17.3864 10.2587 16.1732 10.7612C14.9599 11.2638 13.8575 12.0003 12.9289 12.9289C11.0536 14.8043 10 17.3478 10 20C10 22.6522 11.0536 25.1957 12.9289 27.0711C13.8575 27.9997 14.9599 28.7362 16.1732 29.2388C17.3864 29.7413 18.6868 30 20 30C22.6522 30 25.1957 28.9464 27.0711 27.0711C28.9464 25.1957 30 22.6522 30 20C30 18.6868 29.7413 17.3864 29.2388 16.1732C28.7362 14.9599 27.9997 13.8575 27.0711 12.9289C26.1425 12.0003 25.0401 11.2638 23.8268 10.7612C22.6136 10.2587 21.3132 10 20 10Z'/>
        case 'priority-3':
            return <path d='M21 21H19V15H21V21ZM21 25H19V23H21V25ZM20 10C18.6868 10 17.3864 10.2587 16.1732 10.7612C14.9599 11.2638 13.8575 12.0003 12.9289 12.9289C11.0536 14.8043 10 17.3478 10 20C10 22.6522 11.0536 25.1957 12.9289 27.0711C13.8575 27.9997 14.9599 28.7362 16.1732 29.2388C17.3864 29.7413 18.6868 30 20 30C22.6522 30 25.1957 28.9464 27.0711 27.0711C28.9464 25.1957 30 22.6522 30 20C30 18.6868 29.7413 17.3864 29.2388 16.1732C28.7362 14.9599 27.9997 13.8575 27.0711 12.9289C26.1425 12.0003 25.0401 11.2638 23.8268 10.7612C22.6136 10.2587 21.3132 10 20 10Z' fill='#EA3D2F'/>
        case 'priority-2':
            return <path d='M21 21H19V15H21V21ZM21 25H19V23H21V25ZM20 10C18.6868 10 17.3864 10.2587 16.1732 10.7612C14.9599 11.2638 13.8575 12.0003 12.9289 12.9289C11.0536 14.8043 10 17.3478 10 20C10 22.6522 11.0536 25.1957 12.9289 27.0711C13.8575 27.9997 14.9599 28.7362 16.1732 29.2388C17.3864 29.7413 18.6868 30 20 30C22.6522 30 25.1957 28.9464 27.0711 27.0711C28.9464 25.1957 30 22.6522 30 20C30 18.6868 29.7413 17.3864 29.2388 16.1732C28.7362 14.9599 27.9997 13.8575 27.0711 12.9289C26.1425 12.0003 25.0401 11.2638 23.8268 10.7612C22.6136 10.2587 21.3132 10 20 10Z' fill='#F3AA18'/>
        case 'priority-1':
            return <path d='M21 21H19V15H21V21ZM21 25H19V23H21V25ZM20 10C18.6868 10 17.3864 10.2587 16.1732 10.7612C14.9599 11.2638 13.8575 12.0003 12.9289 12.9289C11.0536 14.8043 10 17.3478 10 20C10 22.6522 11.0536 25.1957 12.9289 27.0711C13.8575 27.9997 14.9599 28.7362 16.1732 29.2388C17.3864 29.7413 18.6868 30 20 30C22.6522 30 25.1957 28.9464 27.0711 27.0711C28.9464 25.1957 30 22.6522 30 20C30 18.6868 29.7413 17.3864 29.2388 16.1732C28.7362 14.9599 27.9997 13.8575 27.0711 12.9289C26.1425 12.0003 25.0401 11.2638 23.8268 10.7612C22.6136 10.2587 21.3132 10 20 10Z' fill='#A6ADAF'/>
        case 'label':
        case 'labels':
            return <path d='M13.5 15C13.1022 15 12.7206 14.842 12.4393 14.5607C12.158 14.2794 12 13.8978 12 13.5C12 13.1022 12.158 12.7206 12.4393 12.4393C12.7206 12.158 13.1022 12 13.5 12C13.8978 12 14.2794 12.158 14.5607 12.4393C14.842 12.7206 15 13.1022 15 13.5C15 13.8978 14.842 14.2794 14.5607 14.5607C14.2794 14.842 13.8978 15 13.5 15ZM29.41 19.58L20.41 10.58C20.05 10.22 19.55 10 19 10H12C10.89 10 10 10.89 10 12V19C10 19.55 10.22 20.05 10.59 20.41L19.58 29.41C19.95 29.77 20.45 30 21 30C21.55 30 22.05 29.77 22.41 29.41L29.41 22.41C29.78 22.05 30 21.55 30 21C30 20.44 29.77 19.94 29.41 19.58Z' />
        case 'label-plus':
            return <path d='M29.41 19.58L20.41 10.58C20.04 10.21 19.53 10 19 10H12C11.4696 10 10.9609 10.2107 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12V19C10 19.53 10.21 20.04 10.59 20.41L11 20.81C11.9 20.27 12.94 20 14 20C15.5913 20 17.1174 20.6321 18.2426 21.7574C19.3679 22.8826 20 24.4087 20 26C20 27.06 19.72 28.09 19.18 29L19.58 29.4C19.95 29.78 20.47 30 21 30C21.53 30 22.04 29.79 22.41 29.41L29.41 22.41C29.79 22.04 30 21.53 30 21C30 20.47 29.79 19.96 29.41 19.58ZM13.5 15C13.1022 15 12.7206 14.842 12.4393 14.5607C12.158 14.2794 12 13.8978 12 13.5C12 13.1022 12.158 12.7206 12.4393 12.4393C12.7206 12.158 13.1022 12 13.5 12C13.8978 12 14.2794 12.158 14.5607 12.4393C14.842 12.7206 15 13.1022 15 13.5C15 13.8978 14.842 14.2794 14.5607 14.5607C14.2794 14.842 13.8978 15 13.5 15ZM18 27H15V30H13V27H10V25H13V22H15V25H18V27Z' />
        case 'label-outline':
            return <path d='M13.5 15C13.8978 15 14.2794 14.842 14.5607 14.5607C14.842 14.2794 15 13.8978 15 13.5C15 13.1022 14.842 12.7206 14.5607 12.4393C14.2794 12.158 13.8978 12 13.5 12C13.1022 12 12.7206 12.158 12.4393 12.4393C12.158 12.7206 12 13.1022 12 13.5C12 13.8978 12.158 14.2794 12.4393 14.5607C12.7206 14.842 13.1022 15 13.5 15ZM29.41 19.58C29.77 19.94 30 20.44 30 21C30 21.55 29.78 22.05 29.41 22.41L22.41 29.41C22.05 29.77 21.55 30 21 30C20.45 30 19.95 29.77 19.58 29.41L10.59 20.41C10.22 20.05 10 19.55 10 19V12C10 10.89 10.89 10 12 10H19C19.55 10 20.05 10.22 20.41 10.58L29.41 19.58ZM21 28L28 21L19.5 12.5L12.5 19.5L21 28Z'/>
        case 'profile':
            return <path d='M20 34.4C15 34.4 10.58 31.84 8 28C8.06 24 16 21.8 20 21.8C24 21.8 31.94 24 32 28C29.42 31.84 25 34.4 20 34.4ZM20 6C21.5913 6 23.1174 6.63214 24.2426 7.75736C25.3679 8.88258 26 10.4087 26 12C26 13.5913 25.3679 15.1174 24.2426 16.2426C23.1174 17.3679 21.5913 18 20 18C18.4087 18 16.8826 17.3679 15.7574 16.2426C14.6321 15.1174 14 13.5913 14 12C14 10.4087 14.6321 8.88258 15.7574 7.75736C16.8826 6.63214 18.4087 6 20 6V6ZM20 0C17.3736 0 14.7728 0.517315 12.3463 1.52241C9.91982 2.5275 7.71504 4.00069 5.85786 5.85786C2.10714 9.60859 0 14.6957 0 20C0 25.3043 2.10714 30.3914 5.85786 34.1421C7.71504 35.9993 9.91982 37.4725 12.3463 38.4776C14.7728 39.4827 17.3736 40 20 40C25.3043 40 30.3914 37.8929 34.1421 34.1421C37.8929 30.3914 40 25.3043 40 20C40 8.94 31 0 20 0Z' />
            // return <path d='M20 27.2C17.5 27.2 15.29 25.92 14 24C14.03 22 18 20.9 20 20.9C22 20.9 25.97 22 26 24C24.71 25.92 22.5 27.2 20 27.2ZM20 13C20.7956 13 21.5587 13.3161 22.1213 13.8787C22.6839 14.4413 23 15.2044 23 16C23 16.7956 22.6839 17.5587 22.1213 18.1213C21.5587 18.6839 20.7956 19 20 19C19.2044 19 18.4413 18.6839 17.8787 18.1213C17.3161 17.5587 17 16.7956 17 16C17 15.2044 17.3161 14.4413 17.8787 13.8787C18.4413 13.3161 19.2044 13 20 13ZM20 10C18.6868 10 17.3864 10.2587 16.1732 10.7612C14.9599 11.2638 13.8575 12.0003 12.9289 12.9289C11.0536 14.8043 10 17.3478 10 20C10 22.6522 11.0536 25.1957 12.9289 27.0711C13.8575 27.9997 14.9599 28.7362 16.1732 29.2388C17.3864 29.7413 18.6868 30 20 30C22.6522 30 25.1957 28.9464 27.0711 27.0711C28.9464 25.1957 30 22.6522 30 20C30 14.47 25.5 10 20 10Z'/>
        case 'merge':
            return <path fillRule='evenodd' clipRule='evenodd' d='M18.9998 16.045H15.4998L19.9998 11.545L24.4998 16.045H20.9998V22.455L14.9998 28.455L13.5898 27.045L18.9998 21.635V16.045ZM26.4098 27.045L24.9998 28.455L21.5898 25.045L22.9998 23.635L26.4098 27.045Z'/>
        case 'duplicate':
            return <path d='M27 19H23V23H21V19H17V17H21V13H23V17H27V19ZM28 10H16C15.4696 10 14.9609 10.2107 14.5858 10.5858C14.2107 10.9609 14 11.4696 14 12V24C14 24.5304 14.2107 25.0391 14.5858 25.4142C14.9609 25.7893 15.4696 26 16 26H28C28.5304 26 29.0391 25.7893 29.4142 25.4142C29.7893 25.0391 30 24.5304 30 24V12C30 11.4696 29.7893 10.9609 29.4142 10.5858C29.0391 10.2107 28.5304 10 28 10ZM12 14H10V28C10 28.5304 10.2107 29.0391 10.5858 29.4142C10.9609 29.7893 11.4696 30 12 30H26V28H12V14Z'/>
        case 'folder':
            return <path d='M18 12H12C10.89 12 10 12.89 10 14V26C10 26.5304 10.2107 27.0391 10.5858 27.4142C10.9609 27.7893 11.4696 28 12 28H28C28.5304 28 29.0391 27.7893 29.4142 27.4142C29.7893 27.0391 30 26.5304 30 26V16C30 14.89 29.1 14 28 14H20L18 12Z' />
        case 'archive':
            return <path d='M11 11H29V15H11V11ZM12 16H28V29H12V16ZM17.5 19C17.3674 19 17.2402 19.0527 17.1464 19.1464C17.0527 19.2402 17 19.3674 17 19.5V21H23V19.5C23 19.3674 22.9473 19.2402 22.8536 19.1464C22.7598 19.0527 22.6326 19 22.5 19H17.5Z' />
        case 'activate':
            return <path d='M17 24V18H13L20 11L27 18H23V24H17ZM13 28V26H27V28H13Z'/>
        case 'check-status-1':
        case 'checkbox-empty':
            return <path fillRule='evenodd' clipRule='evenodd' d='M27 13V27H13V13H27ZM27 11H13C11.9 11 11 11.9 11 13V27C11 28.1 11.9 29 13 29H27C28.1 29 29 28.1 29 27V13C29 11.9 28.1 11 27 11Z'/>
        case 'check-status-3':
        case 'checkbox':
            return <path fillRule='evenodd' clipRule='evenodd' d='M27 11H13C11.9 11 11 11.9 11 13V27C11 28.1 11.9 29 13 29H27C28.1 29 29 28.1 29 27V13C29 11.9 28.1 11 27 11ZM18 25L13 20L14.4 18.6L18 22.2L25.6 14.6L27 16L18 25Z' fill='#2FA84F'/>
        case 'task':
        case 'tasks':
            return <path fillRule='evenodd' clipRule='evenodd' d='M27 11H13C11.9 11 11 11.9 11 13V27C11 28.1 11.9 29 13 29H27C28.1 29 29 28.1 29 27V13C29 11.9 28.1 11 27 11ZM18 25L13 20L14.4 18.6L18 22.2L25.6 14.6L27 16L18 25Z' />
        case 'check-status-2':
        case 'checkbox-progress':
            return <>
                <path fillRule='evenodd' clipRule='evenodd' d='M27 13V27H13V13H27ZM27 11H13C11.9 11 11 11.9 11 13V27C11 28.1 11.9 29 13 29H27C28.1 29 29 28.1 29 27V13C29 11.9 28.1 11 27 11Z' fill='#F3AA18'/>
                <path d='M21 15H16C15.4477 15 15 15.4477 15 16V24C15 24.5523 15.4477 25 16 25H21V15Z' fill='#F3AA18'/>
            </>
        case 'check-status':
            return <>
                <path fillRule='evenodd' clipRule='evenodd' d='M27 13V27H13V13H27ZM27 11H13C11.9 11 11 11.9 11 13V27C11 28.1 11.9 29 13 29H27C28.1 29 29 28.1 29 27V13C29 11.9 28.1 11 27 11Z'/>
                <path d='M21 15H16C15.4477 15 15 15.4477 15 16V24C15 24.5523 15.4477 25 16 25H21V15Z'/>
            </>
        case 'arrow-right':
            return <path d='M12 18.92V20.92H24L18.5 26.42L19.92 27.84L27.84 19.92L19.92 12L18.5 13.42L24 18.92H12Z'/>
        case 'reply':
            return <path d='M18 16V12L11 19L18 26V21.9C23 21.9 26.5 23.5 29 27C28 22 25 17 18 16Z'/>
        case 'time':
            return <path d='M20 10C14.5 10 10 14.5 10 20C10 25.5 14.5 30 20 30C25.5 30 30 25.5 30 20C30 14.5 25.5 10 20 10ZM24.2 24.2L19 21V15H20.5V20.2L25 22.9L24.2 24.2Z'/>
        case 'clock':
            return <path d='M20 28C22.1217 28 24.1566 27.1571 25.6569 25.6569C27.1571 24.1566 28 22.1217 28 20C28 17.8783 27.1571 15.8434 25.6569 14.3431C24.1566 12.8429 22.1217 12 20 12C17.8783 12 15.8434 12.8429 14.3431 14.3431C12.8429 15.8434 12 17.8783 12 20C12 22.1217 12.8429 24.1566 14.3431 25.6569C15.8434 27.1571 17.8783 28 20 28ZM20 10C21.3132 10 22.6136 10.2587 23.8268 10.7612C25.0401 11.2638 26.1425 12.0003 27.0711 12.9289C27.9997 13.8575 28.7362 14.9599 29.2388 16.1732C29.7413 17.3864 30 18.6868 30 20C30 22.6522 28.9464 25.1957 27.0711 27.0711C25.1957 28.9464 22.6522 30 20 30C14.47 30 10 25.5 10 20C10 17.3478 11.0536 14.8043 12.9289 12.9289C14.8043 11.0536 17.3478 10 20 10ZM20.5 15V20.25L25 22.92L24.25 24.15L19 21V15H20.5Z' />
        case 'clock-alert':
            return <path d='M20 10C14.47 10 10 14.5 10 20C10 22.6522 11.0536 25.1957 12.9289 27.0711C13.8575 27.9997 14.9599 28.7362 16.1732 29.2388C17.3864 29.7413 18.6868 30 20 30C22.25 30 24.33 29.24 26 28V25.28C24.53 26.94 22.39 28 20 28C17.8783 28 15.8434 27.1571 14.3431 25.6569C12.8429 24.1566 12 22.1217 12 20C12 17.8783 12.8429 15.8434 14.3431 14.3431C15.8434 12.8429 17.8783 12 20 12C23.36 12 26.23 14.07 27.41 17H29.54C28.27 12.94 24.5 10 20 10ZM19 15V21L24.25 24.15L25 22.92L20.5 20.25V15H19ZM28 19V26H30V19H28ZM28 28V30H30V28H28Z' />
        case 'clock-sand':
            return <path d='M14 10V16L18 20L14 24V30H26V24L22 20L26 16V10H14ZM24 24.5V28H16V24.5L20 20.5L24 24.5ZM20 19.5L16 15.5V12H24V15.5L20 19.5Z' />
        case 'dots-horizontal':
            return <path d='M14 18C12.9 18 12 18.9 12 20C12 21.1 12.9 22 14 22C15.1 22 16 21.1 16 20C16 18.9 15.1 18 14 18ZM26 18C24.9 18 24 18.9 24 20C24 21.1 24.9 22 26 22C27.1 22 28 21.1 28 20C28 18.9 27.1 18 26 18ZM20 18C18.9 18 18 18.9 18 20C18 21.1 18.9 22 20 22C21.1 22 22 21.1 22 20C22 18.9 21.1 18 20 18Z' />
        case 'dots-vertical':
            return <path d='M20 24C20.5304 24 21.0391 24.2107 21.4142 24.5858C21.7893 24.9609 22 25.4696 22 26C22 26.5304 21.7893 27.0391 21.4142 27.4142C21.0391 27.7893 20.5304 28 20 28C19.4696 28 18.9609 27.7893 18.5858 27.4142C18.2107 27.0391 18 26.5304 18 26C18 25.4696 18.2107 24.9609 18.5858 24.5858C18.9609 24.2107 19.4696 24 20 24ZM20 18C20.5304 18 21.0391 18.2107 21.4142 18.5858C21.7893 18.9609 22 19.4696 22 20C22 20.5304 21.7893 21.0391 21.4142 21.4142C21.0391 21.7893 20.5304 22 20 22C19.4696 22 18.9609 21.7893 18.5858 21.4142C18.2107 21.0391 18 20.5304 18 20C18 19.4696 18.2107 18.9609 18.5858 18.5858C18.9609 18.2107 19.4696 18 20 18ZM20 12C20.5304 12 21.0391 12.2107 21.4142 12.5858C21.7893 12.9609 22 13.4696 22 14C22 14.5304 21.7893 15.0391 21.4142 15.4142C21.0391 15.7893 20.5304 16 20 16C19.4696 16 18.9609 15.7893 18.5858 15.4142C18.2107 15.0391 18 14.5304 18 14C18 13.4696 18.2107 12.9609 18.5858 12.5858C18.9609 12.2107 19.4696 12 20 12Z'/>
        case 'filter':
            return <path d='M11 10H29V12H28.92L22 18.92V30.91L18 26.91V18.91L11.09 12H11V10Z'/>
        case 'category':
            return <path d='M11 11H26V26H11V11ZM27 27H29V29H27V27ZM27 24H29V26H27V24ZM27 21H29V23H27V21ZM27 18H29V20H27V18ZM27 15H29V17H27V15ZM24 27H26V29H24V27ZM21 27H23V29H21V27ZM18 27H20V29H18V27ZM15 27H17V29H15V27Z'/>
        case 'reporter':
            return <path d='M26 23H14V22.1C14 20.1 18 19 20 19C22 19 26 20.1 26 22.1V23ZM20 12.3C21.5 12.3 22.7 13.5 22.7 15C22.7 16.5 21.5 17.7 20 17.7C18.5 17.7 17.3 16.5 17.3 15C17.3 13.5 18.5 12.3 20 12.3ZM27 9H13C11.89 9 11 9.89 11 11V25C11 25.5304 11.2107 26.0391 11.5858 26.4142C11.9609 26.7893 12.4696 27 13 27H17L20 30L23 27H27C27.5304 27 28.0391 26.7893 28.4142 26.4142C28.7893 26.0391 29 25.5304 29 25V11C29 9.89 28.1 9 27 9Z'/>
        case 'caret-down':
            return <path d='M0 0.800049L7 7.80005L14 0.800049L0 0.800049Z'/>
        case 'plus':
            return <path d='M27 21H21V27H19V21H13V19H19V13H21V19H27V21Z'/>
        case 'bin':
            return <path d='M27 12H23.5L22.5 11H17.5L16.5 12H13V14H27V12ZM14 27C14 27.5304 14.2107 28.0391 14.5858 28.4142C14.9609 28.7893 15.4696 29 16 29H24C24.5304 29 25.0391 28.7893 25.4142 28.4142C25.7893 28.0391 26 27.5304 26 27V15H14V27Z'/>
        case 'group':
            return <path d='M10 11V14H29V11H10ZM28 16H11C10.7348 16 10.4804 16.1054 10.2929 16.2929C10.1054 16.4804 10 16.7348 10 17V23C10 23.2652 10.1054 23.5196 10.2929 23.7071C10.4804 23.8946 10.7348 24 11 24H28C28.2652 24 28.5196 23.8946 28.7071 23.7071C28.8946 23.5196 29 23.2652 29 23V17C29 16.7348 28.8946 16.4804 28.7071 16.2929C28.5196 16.1054 28.2652 16 28 16ZM10 29H29V26H10V29Z'/>
        case 'sort-text':
            return <path d='M17.34 13.25L20.59 10L23.84 13.25H17.34ZM23.84 27.25L20.59 30.5L17.34 27.25H23.84ZM16.98 22.55H14.09L13.37 25.25H11L14.09 15.25H17.09L20.22 25.25H17.76L16.98 22.55ZM14.42 20.93H16.65L16.02 18.81L15.76 17.84L15.51 16.88H15.48L15.26 17.85L15.02 18.83L14.42 20.93ZM21.14 25.25V23.99L25.89 17.22V17.16H21.59V15.25H28.82V16.59L24.18 23.25V23.33H28.89V25.25H21.14Z'/>
        case 'sort':
            return <path d='M18.5 20.5V18.5H26.5V20.5H18.5ZM18.5 26.5V24.5H22.5V26.5H18.5ZM18.5 14.5V12.5H30.5V14.5H18.5ZM14.5 24.5H17L13.5 28L10 24.5H12.5V14.5H10L13.5 11L17 14.5H14.5V24.5Z'/>
        case 'sort--1':
            return <path d='M18.5 19.5V21.5H26.5V19.5H18.5ZM18.5 13.5V15.5H22.5V13.5H18.5ZM18.5 25.5V27.5H30.5V25.5H18.5ZM14.5 15.5H17L13.5 12L10 15.5H12.5V28.5H14.5V15.5Z'/>
        case 'sort-1':
            return <path d='M18.5 21V19H26.5V21H18.5ZM18.5 27V25H22.5V27H18.5ZM18.5 15V13H30.5V15H18.5ZM14.5 25H17L13.5 28.5L10 25H12.5V12H14.5V25Z'/>
        case 'caret':
            return <path d='M15.41 16L20 20.59L24.59 16L26 17.42L20 23.42L14 17.42L15.41 16Z'/>
        case 'caret-double':
            return <path d='M13 15.41L14.41 14L20.41 20L14.41 26L13 24.59L17.58 20L13 15.41ZM19 15.41L20.41 14L26.41 20L20.41 26L19 24.59L23.58 20L19 15.41Z'/>
        case 'check-circle':
            return <path fillRule='evenodd' clipRule='evenodd' d='M20 10C14.5 10 10 14.5 10 20C10 25.5 14.5 30 20 30C25.5 30 30 25.5 30 20C30 14.5 25.5 10 20 10ZM20 28C15.6 28 12 24.4 12 20C12 15.6 15.6 12 20 12C24.4 12 28 15.6 28 20C28 24.4 24.4 28 20 28Z' fill='#367BF5'/>
        case 'checked':
            return <path fillRule='evenodd' clipRule='evenodd' d='M20 10C14.5 10 10 14.5 10 20C10 25.5 14.5 30 20 30C25.5 30 30 25.5 30 20C30 14.5 25.5 10 20 10ZM18 25L13 20L14.4 18.6L18 22.2L25.6 14.6L27 16L18 25Z' fill='#367BF5'/>
        case 'assignee':
            return <path d='M10 12V13.5C10 17.65 12.21 21.28 15.5 23.3V28H30.5V26C30.5 23.34 25.17 22 22.5 22C22.5 22 22.33 22 22.25 22C17.5 22 13.5 18 13.5 13.5V12H10ZM22.5 12C21.4391 12 20.4217 12.4214 19.6716 13.1716C18.9214 13.9217 18.5 14.9391 18.5 16C18.5 17.0609 18.9214 18.0783 19.6716 18.8284C20.4217 19.5786 21.4391 20 22.5 20C23.5609 20 24.5783 19.5786 25.3284 18.8284C26.0786 18.0783 26.5 17.0609 26.5 16C26.5 14.9391 26.0786 13.9217 25.3284 13.1716C24.5783 12.4214 23.5609 12 22.5 12Z'/>
        case 'ticket':
        case 'tickets':
            return <path d='M23 26C19.68 26 17 23.31 17 20C17 16.68 19.68 14 23 14C24.5913 14 26.1174 14.6321 27.2426 15.7574C28.3679 16.8826 29 18.4087 29 20C29 21.5913 28.3679 23.1174 27.2426 24.2426C26.1174 25.3679 24.5913 26 23 26ZM12 21C11.7348 21 11.4804 20.8946 11.2929 20.7071C11.1054 20.5196 11 20.2652 11 20C11 19.7348 11.1054 19.4804 11.2929 19.2929C11.4804 19.1054 11.7348 19 12 19C12.2652 19 12.5196 19.1054 12.7071 19.2929C12.8946 19.4804 13 19.7348 13 20C13 20.2652 12.8946 20.5196 12.7071 20.7071C12.5196 20.8946 12.2652 21 12 21ZM30 11H15.63C14.97 11 14.38 11.32 14 11.81L8 20L14 28.18C14.38 28.68 14.97 29 15.63 29H30C30.5304 29 31.0391 28.7893 31.4142 28.4142C31.7893 28.0391 32 27.5304 32 27V13C32 11.89 31.1 11 30 11ZM21 19C21.2652 19 21.5196 18.8946 21.7071 18.7071C21.8946 18.5196 22 18.2652 22 18C22 17.7348 21.8946 17.4804 21.7071 17.2929C21.5196 17.1054 21.2652 17 21 17C20.7348 17 20.4804 17.1054 20.2929 17.2929C20.1054 17.4804 20 17.7348 20 18C20 18.2652 20.1054 18.5196 20.2929 18.7071C20.4804 18.8946 20.7348 19 21 19ZM23 24C24.86 24 26.35 22.72 26.8 21H19.2C19.65 22.72 21.14 24 23 24ZM25 19C25.2652 19 25.5196 18.8946 25.7071 18.7071C25.8946 18.5196 26 18.2652 26 18C26 17.7348 25.8946 17.4804 25.7071 17.2929C25.5196 17.1054 25.2652 17 25 17C24.7348 17 24.4804 17.1054 24.2929 17.2929C24.1054 17.4804 24 17.7348 24 18C24 18.2652 24.1054 18.5196 24.2929 18.7071C24.4804 18.8946 24.7348 19 25 19Z'/>
        case 'country':
            return <path d='M25.9 25.39C25.64 24.59 24.89 24 24 24H23V21C23 20.7348 22.8946 20.4804 22.7071 20.2929C22.5196 20.1054 22.2652 20 22 20H16V18H18C18.2652 18 18.5196 17.8946 18.7071 17.7071C18.8946 17.5196 19 17.2652 19 17V15H21C21.5304 15 22.0391 14.7893 22.4142 14.4142C22.7893 14.0391 23 13.5304 23 13V12.59C25.93 13.77 28 16.64 28 20C28 22.08 27.2 23.97 25.9 25.39ZM19 27.93C15.05 27.44 12 24.08 12 20C12 19.38 12.08 18.78 12.21 18.21L17 23V24C17 24.5304 17.2107 25.0391 17.5858 25.4142C17.9609 25.7893 18.4696 26 19 26V27.93ZM20 10C18.6868 10 17.3864 10.2587 16.1732 10.7612C14.9599 11.2638 13.8575 12.0003 12.9289 12.9289C11.0536 14.8043 10 17.3478 10 20C10 22.6522 11.0536 25.1957 12.9289 27.0711C13.8575 27.9997 14.9599 28.7362 16.1732 29.2388C17.3864 29.7413 18.6868 30 20 30C22.6522 30 25.1957 28.9464 27.0711 27.0711C28.9464 25.1957 30 22.6522 30 20C30 18.6868 29.7413 17.3864 29.2388 16.1732C28.7362 14.9599 27.9997 13.8575 27.0711 12.9289C26.1425 12.0003 25.0401 11.2638 23.8268 10.7612C22.6136 10.2587 21.3132 10 20 10Z'/>
        case 'city':
        case 'location':
            return <path d='M20 19.5C19.337 19.5 18.7011 19.2366 18.2322 18.7678C17.7634 18.2989 17.5 17.663 17.5 17C17.5 16.337 17.7634 15.7011 18.2322 15.2322C18.7011 14.7634 19.337 14.5 20 14.5C20.663 14.5 21.2989 14.7634 21.7678 15.2322C22.2366 15.7011 22.5 16.337 22.5 17C22.5 17.3283 22.4353 17.6534 22.3097 17.9567C22.1841 18.26 21.9999 18.5356 21.7678 18.7678C21.5356 18.9999 21.26 19.1841 20.9567 19.3097C20.6534 19.4353 20.3283 19.5 20 19.5ZM20 10C18.1435 10 16.363 10.7375 15.0503 12.0503C13.7375 13.363 13 15.1435 13 17C13 22.25 20 30 20 30C20 30 27 22.25 27 17C27 15.1435 26.2625 13.363 24.9497 12.0503C23.637 10.7375 21.8565 10 20 10Z'/>
        case 'building':
        case 'buildings':
            return <path d='M27 24H25V22H27V24ZM27 28H25V26H27V28ZM21 16H19V14H21V16ZM21 20H19V18H21V20ZM21 24H19V22H21V24ZM21 28H19V26H21V28ZM15 20H13V18H15V20ZM15 24H13V22H15V24ZM15 28H13V26H15V28ZM23 20V14L20 11L17 14V16H11V30H29V20H23Z'/>
        case 'address2':
            return <>
                <path d='M18.5266 11.2832V14.6014H21.0354V11.2832C21.0354 10.9429 20.9033 10.6165 20.668 10.3758C20.1781 9.87472 19.3839 9.87472 18.894 10.3758C18.6587 10.6165 18.5266 10.9429 18.5266 11.2832Z'/>
                <path d='M11 15.8846C11 15.1759 11.5616 14.6014 12.2544 14.6014H25.4656C25.8379 14.6014 26.191 14.7705 26.4293 15.0631L28.8546 18.0403C29.0485 18.2782 29.0485 18.6238 28.8546 18.8618L26.4293 21.839C26.191 22.1315 25.8379 22.3007 25.4656 22.3007H12.2544C11.5616 22.3007 11 21.7262 11 21.0175V15.8846Z'/>
                <path d='M18.5266 22.3007H21.0354V30H18.5266V22.3007Z'/>
            </>
        case 'property':
        case 'address':
        case 'addresses':
            return <path d='M18 29V23H22V29H27V21H30L20 12L10 21H13V29H18Z'/>
        case 'room':
        case 'unit':
        case 'rooms':
            return <path fillRule='evenodd' clipRule='evenodd' d='M25.3571 11C26.0278 11 26.5714 11.5373 26.5714 12.2V27.8H28.3929C28.7282 27.8 29 28.0686 29 28.4C29 28.7314 28.7282 29 28.3929 29H12.6071C12.2718 29 12 28.7314 12 28.4C12 28.0686 12.2718 27.8 12.6071 27.8H14.4286V12.2C14.4286 11.5373 14.9722 11 15.6429 11H25.3571ZM22.9286 21.8C22.2579 21.8 21.7143 21.2627 21.7143 20.6C21.7143 19.9373 22.2579 19.4 22.9286 19.4C23.5992 19.4 24.1429 19.9373 24.1429 20.6C24.1429 21.2627 23.5992 21.8 22.9286 21.8Z'/>
        case 'highlight':
            return <path fillRule='evenodd' clipRule='evenodd' d='M21.0012 10H19.0012V13H21.0012V10ZM14.0012 22L17.0012 25V30H23.0012V25L26.0012 22V17H14.0012V22ZM16.0012 19H24.0012V21.17L21.0012 24.17V28H19.0012V24.17L16.0012 21.17V19ZM11.5034 13.8742L12.917 12.4594L15.0392 14.5798L13.6256 15.9946L11.5034 13.8742ZM27.0838 12.4626L24.961 14.5824L26.3742 15.9976L28.497 13.8778L27.0838 12.4626Z'/>    
        case 'important':
            return <path d='M31 20.46L28.56 17.68L28.9 14L25.29 13.18L23.4 10L20 11.46L16.6 10L14.71 13.18L11.1 13.99L11.44 17.67L9 20.46L11.44 23.24L11.1 26.93L14.71 27.75L16.6 30.93L20 29.46L23.4 30.92L25.29 27.74L28.9 26.92L28.56 23.24L31 20.46ZM21 25.46H19V23.46H21V25.46ZM21 21.46H19V15.46H21V21.46Z'/>
        case 'link':
            return <path d='M11.9 20C11.9 18.29 13.29 16.9 15 16.9H19V15H15C12.24 15 10 17.24 10 20C10 22.76 12.24 25 15 25H19V23.1H15C13.29 23.1 11.9 21.71 11.9 20ZM16 21H24V19H16V21ZM25 15H21V16.9H25C26.71 16.9 28.1 18.29 28.1 20C28.1 21.71 26.71 23.1 25 23.1H21V25H25C27.76 25 30 22.76 30 20C30 17.24 27.76 15 25 15Z'/>
        case 'expand':
            return <path d='M17.5 21.09L18.91 22.5L14.41 27H18V29H11V22H13V25.59L17.5 21.09ZM18.91 17.5L17.5 18.91L13 14.41V18H11V11H18V13H14.41L18.91 17.5ZM22.5 21.09L27 25.59V22H29V29H22V27H25.59L21.09 22.5L22.5 21.09ZM21.09 17.5L25.59 13H22V11H29V18H27V14.41L22.5 18.91L21.09 17.5Z'/>
        case 'note':
        case 'notes':
        case 'template':
        case 'templates':
        case 'clipboard':
            return <path d='M25 18H15V16H25V18ZM25 22H15V20H25V22ZM22 26H15V24H22V26ZM20 12C20.2652 12 20.5196 12.1054 20.7071 12.2929C20.8946 12.4804 21 12.7348 21 13C21 13.2652 20.8946 13.5196 20.7071 13.7071C20.5196 13.8946 20.2652 14 20 14C19.7348 14 19.4804 13.8946 19.2929 13.7071C19.1054 13.5196 19 13.2652 19 13C19 12.7348 19.1054 12.4804 19.2929 12.2929C19.4804 12.1054 19.7348 12 20 12ZM27 12H22.82C22.4 10.84 21.3 10 20 10C18.7 10 17.6 10.84 17.18 12H13C12.4696 12 11.9609 12.2107 11.5858 12.5858C11.2107 12.9609 11 13.4696 11 14V28C11 28.5304 11.2107 29.0391 11.5858 29.4142C11.9609 29.7893 12.4696 30 13 30H27C27.5304 30 28.0391 29.7893 28.4142 29.4142C28.7893 29.0391 29 28.5304 29 28V14C29 13.4696 28.7893 12.9609 28.4142 12.5858C28.0391 12.2107 27.5304 12 27 12Z' />
        case 'account-details':
            return <path d='M10 11H30C31.05 11 32 11.95 32 13V27C32 28.05 31.05 29 30 29H10C8.95 29 8 28.05 8 27V13C8 11.95 8.95 11 10 11ZM22 14V15H30V14H22ZM22 16V17H29.5H30V16H22ZM22 18V19H29V18H22ZM16 21.91C14 21.91 10 23 10 25V26H22V25C22 23 18 21.91 16 21.91ZM16 14C15.2044 14 14.4413 14.3161 13.8787 14.8787C13.3161 15.4413 13 16.2044 13 17C13 17.7956 13.3161 18.5587 13.8787 19.1213C14.4413 19.6839 15.2044 20 16 20C16.7956 20 17.5587 19.6839 18.1213 19.1213C18.6839 18.5587 19 17.7956 19 17C19 16.2044 18.6839 15.4413 18.1213 14.8787C17.5587 14.3161 16.7956 14 16 14Z' />
        case 'download':
            return <path d='M13 29H27V27H13V29ZM27 18H23V12H17V18H13L20 25L27 18Z' />
        case 'plus':
            return <path d="M27 21H21V27H19V21H13V19H19V13H21V19H27V21Z"/>
        case 'repeat':
            return <path d='M25 25H15V22L11 26L15 30V27H27V21H25V25ZM15 15H25V18L29 14L25 10V13H13V19H15V15Z' />
        case 'ruler':
            return <path d='M9 25.97L10.77 24.21L12.19 25.61L13.25 24.56L11.83 23.15L13.25 21.73L15.72 24.21L16.78 23.15L14.31 20.67L15.72 19.26L17.14 20.67L18.2 19.61L16.78 18.2L18.2 16.78L20.67 19.26L21.73 18.2L19.26 15.72L20.67 14.31L22.08 15.72L23.15 14.66L21.73 13.25L23.15 11.83L25.61 14.31L26.68 13.25L24.21 10.77L25.97 9L30.22 13.25L13.25 30.22L9 25.97Z'/>
        case 'account-box':
            return <path d='M27 27H13V13H27V27ZM27 11H13C12.4696 11 11.9609 11.2107 11.5858 11.5858C11.2107 11.9609 11 12.4696 11 13V27C11 27.5304 11.2107 28.0391 11.5858 28.4142C11.9609 28.7893 12.4696 29 13 29H27C27.5304 29 28.0391 28.7893 28.4142 28.4142C28.7893 28.0391 29 27.5304 29 27V13C29 11.89 28.1 11 27 11ZM24.5 24.25C24.5 22.75 21.5 22 20 22C18.5 22 15.5 22.75 15.5 24.25V25H24.5V24.25ZM20 20.25C20.5967 20.25 21.169 20.0129 21.591 19.591C22.0129 19.169 22.25 18.5967 22.25 18C22.25 17.4033 22.0129 16.831 21.591 16.409C21.169 15.9871 20.5967 15.75 20 15.75C19.4033 15.75 18.831 15.9871 18.409 16.409C17.9871 16.831 17.75 17.4033 17.75 18C17.75 18.5967 17.9871 19.169 18.409 19.591C18.831 20.0129 19.4033 20.25 20 20.25Z' />
        case 'question-circle':
            return <path d='M23.07 19.25L22.17 20.17C21.45 20.89 21 21.5 21 23H19V22.5C19 21.39 19.45 20.39 20.17 19.67L21.41 18.41C21.78 18.05 22 17.55 22 17C22 15.89 21.1 15 20 15C19.4696 15 18.9609 15.2107 18.5858 15.5858C18.2107 15.9609 18 16.4696 18 17H16C16 15.9391 16.4214 14.9217 17.1716 14.1716C17.9217 13.4214 18.9391 13 20 13C21.0609 13 22.0783 13.4214 22.8284 14.1716C23.5786 14.9217 24 15.9391 24 17C24 17.88 23.64 18.67 23.07 19.25ZM21 27H19V25H21V27ZM20 10C18.6868 10 17.3864 10.2587 16.1732 10.7612C14.9599 11.2638 13.8575 12.0003 12.9289 12.9289C11.0536 14.8043 10 17.3478 10 20C10 22.6522 11.0536 25.1957 12.9289 27.0711C13.8575 27.9997 14.9599 28.7362 16.1732 29.2388C17.3864 29.7413 18.6868 30 20 30C22.6522 30 25.1957 28.9464 27.0711 27.0711C28.9464 25.1957 30 22.6522 30 20C30 14.47 25.5 10 20 10Z' />
        case 'document':
        case 'contract':
        case 'tenancyAgreements':
            return <path d='M21 17H26.5L21 11.5V17ZM14 10H22L28 16V28C28 28.5304 27.7893 29.0391 27.4142 29.4142C27.0391 29.7893 26.5304 30 26 30H14C12.89 30 12 29.1 12 28V12C12 10.89 12.89 10 14 10ZM23 26V24H14V26H23ZM26 22V20H14V22H26Z'/>
        case 'document-outline':
        case 'lead':
        case 'leads':
            return <path d='M21 17H26.5L21 11.5V17ZM14 10H22L28 16V28C28 28.5304 27.7893 29.0391 27.4142 29.4142C27.0391 29.7893 26.5304 30 26 30H14C12.89 30 12 29.1 12 28V12C12 10.89 12.89 10 14 10ZM19 12H14V28H19H26V19H19V12Z'/>
        case 'electricity':
            return <path d='M24 15V11H22V15H18V11H16V15C15 15 14 16 14 17V22.5L17.5 26V29H22.5V26L26 22.5V17C26 16 25 15 24 15Z'/>
        case 'water':
            return <path d='M27 22.5C27 22.5 29 24.67 29 26C29 26.5304 28.7893 27.0391 28.4142 27.4142C28.0391 27.7893 27.5304 28 27 28C26.4696 28 25.9609 27.7893 25.5858 27.4142C25.2107 27.0391 25 26.5304 25 26C25 24.67 27 22.5 27 22.5ZM13 26V17C12.4696 17 11.9609 16.7893 11.5858 16.4142C11.2107 16.0391 11 15.5304 11 15C11 14.4696 11.2107 13.9609 11.5858 13.5858C11.9609 13.2107 12.4696 13 13 13V12C13 11.4696 13.2107 10.9609 13.5858 10.5858C13.9609 10.2107 14.4696 10 15 10H17C17.5304 10 18.0391 10.2107 18.4142 10.5858C18.7893 10.9609 19 11.4696 19 12V13H27C27.5304 13 28.0391 13.2107 28.4142 13.5858C28.7893 13.9609 29 14.4696 29 15V17V19C29.2652 19 29.5196 19.1054 29.7071 19.2929C29.8946 19.4804 30 19.7348 30 20C30 20.2652 29.8946 20.5196 29.7071 20.7071C29.5196 20.8946 29.2652 21 29 21H25C24.7348 21 24.4804 20.8946 24.2929 20.7071C24.1054 20.5196 24 20.2652 24 20C24 19.7348 24.1054 19.4804 24.2929 19.2929C24.4804 19.1054 24.7348 19 25 19V17H19V26H20C20.5304 26 21.0391 26.2107 21.4142 26.5858C21.7893 26.9609 22 27.4696 22 28V30H10V28C10 27.4696 10.2107 26.9609 10.5858 26.5858C10.9609 26.2107 11.4696 26 12 26H13Z'/>
        case 'gass':
            return <path d='M27.5445 20.854C25.968 16.774 20.3547 16.554 21.7103 10.624C21.8107 10.184 21.3388 9.84405 20.9572 10.074C17.3121 12.214 14.6912 16.504 16.8903 22.124C17.0711 22.584 16.5288 23.014 16.1372 22.714C14.3196 21.344 14.1288 19.374 14.2895 17.964C14.3498 17.444 13.6669 17.194 13.3757 17.624C12.6929 18.664 12 20.344 12 22.874C12.3816 28.4741 17.1313 30.1941 18.8384 30.4141C21.2785 30.7241 23.9195 30.2741 25.8174 28.5441C27.906 26.6141 28.6692 23.5341 27.5445 20.854ZM18.2258 25.884C19.6718 25.534 20.4149 24.494 20.6158 23.574C20.9471 22.144 19.6518 20.744 20.5254 18.484C20.8568 20.354 23.809 21.524 23.809 23.564C23.8894 26.0941 21.1379 28.2641 18.2258 25.884Z'/>
        case 'server':
            return <path d='M12 9H28C28.2652 9 28.5196 9.10536 28.7071 9.29289C28.8946 9.48043 29 9.73478 29 10V14C29 14.2652 28.8946 14.5196 28.7071 14.7071C28.5196 14.8946 28.2652 15 28 15H12C11.7348 15 11.4804 14.8946 11.2929 14.7071C11.1054 14.5196 11 14.2652 11 14V10C11 9.73478 11.1054 9.48043 11.2929 9.29289C11.4804 9.10536 11.7348 9 12 9V9ZM12 17H28C28.2652 17 28.5196 17.1054 28.7071 17.2929C28.8946 17.4804 29 17.7348 29 18V22C29 22.2652 28.8946 22.5196 28.7071 22.7071C28.5196 22.8946 28.2652 23 28 23H12C11.7348 23 11.4804 22.8946 11.2929 22.7071C11.1054 22.5196 11 22.2652 11 22V18C11 17.7348 11.1054 17.4804 11.2929 17.2929C11.4804 17.1054 11.7348 17 12 17V17ZM12 25H28C28.2652 25 28.5196 25.1054 28.7071 25.2929C28.8946 25.4804 29 25.7348 29 26V30C29 30.2652 28.8946 30.5196 28.7071 30.7071C28.5196 30.8946 28.2652 31 28 31H12C11.7348 31 11.4804 30.8946 11.2929 30.7071C11.1054 30.5196 11 30.2652 11 30V26C11 25.7348 11.1054 25.4804 11.2929 25.2929C11.4804 25.1054 11.7348 25 12 25V25ZM17 13H18V11H17V13ZM17 21H18V19H17V21ZM17 29H18V27H17V29ZM13 11V13H15V11H13ZM13 19V21H15V19H13ZM13 27V29H15V27H13Z'/>
        case 'landlord':
        case 'landlords':
        case 'ownerContracts':
            return (
                <g transform='translate(4,35) scale(0.0165,-0.013)'>
                    <path d='M675 1881 c-50 -14 -103 -20 -188 -20 -143 -1 -169 -11 -200 -79 -19 -41 -22 -65 -22 -182 0 -146 10 -190 50 -234 24 -26 26 -26 162 -26 l138 -1 140 -171 c77 -94 154 -179 170 -189 33 -21 76 -24 106 -9 17 10 19 7 19 -29 0 -34 -3 -40 -27 -46 -156 -37 -193 -248 -60 -343 23 -16 33 -18 39 -9 4 6 8 -83 8 -200 0 -236 2 -243 65 -243 24 0 38 6 45 19 6 12 10 106 11 233 0 117 4 205 7 195 7 -16 10 -15 44 8 128 87 87 304 -64 340 -25 6 -28 12 -28 46 0 37 1 39 21 28 29 -15 81 -3 108 25 34 37 41 79 41 268 0 98 2 178 5 178 3 0 33 -22 68 -49 81 -63 98 -71 146 -71 50 0 98 37 106 84 11 56 -15 98 -101 163 l-78 58 130 5 c148 6 176 17 195 79 9 31 8 43 -5 69 -30 58 -49 62 -317 62 -240 0 -243 0 -308 27 -59 24 -80 27 -211 30 -119 2 -157 -1 -215 -16z m314 -365 c42 -22 51 -55 51 -182 l0 -115 -53 63 c-84 99 -127 158 -127 175 0 22 55 73 80 73 11 0 33 -6 49 -14z'/>
                </g>
            )
        case 'tenant':
        case 'guests':
        case 'tenantContracts':
            return (
                <g transform='translate(5,31) scale(0.015,-0.012)'>
                    <path d='M980 1837 l0 -62 -50 -18 c-102 -36 -154 -109 -154 -217 0 -81 27 -138 86 -180 48 -35 58 -37 58 -10 0 32 44 70 80 70 36 0 80 -38 80 -70 0 -26 8 -25 52 5 156 106 111 356 -74 405 l-38 11 0 64 c0 58 -2 65 -20 65 -18 0 -20 -7 -20 -63z'/>
                    <path d='M950 1390 c-19 -19 -20 -33 -20 -283 0 -263 0 -264 23 -285 30 -28 68 -28 95 1 21 23 22 30 22 285 0 249 -1 263 -20 282 -11 11 -33 20 -50 20 -17 0 -39 -9 -50 -20z'/>
                    <path d='M1379 875 c-32 -17 -59 -57 -74 -110 l-12 -40 -179 0 c-123 0 -188 -4 -209 -13 -93 -40 -95 -184 -3 -230 14 -6 111 -18 214 -25 l189 -13 -165 -10 c-202 -11 -225 -9 -272 22 -54 35 -82 94 -75 160 l5 52 -163 61 c-90 34 -178 61 -195 61 -48 0 -95 -24 -108 -56 -12 -27 -16 -29 -71 -29 -45 0 -67 -5 -88 -21 -71 -52 -65 -132 13 -187 35 -24 44 -37 44 -60 0 -38 24 -78 63 -104 18 -12 104 -42 192 -68 107 -32 177 -58 211 -80 88 -58 162 -77 317 -83 204 -7 367 27 485 102 38 24 51 26 141 26 112 0 138 7 175 47 49 52 61 111 61 293 0 158 -1 167 -27 222 -43 92 -58 98 -268 98 -131 -1 -181 -4 -201 -15z'/>
                </g>
            )
        case 'employee':
        case 'employees':
            return <path d='M14 10L18 14L15 25L20 30L25 25L22 14L26 10H14Z' />
        case 'partner':
        case 'partners':
            return <path d='M20 23C15.58 23 12 24.79 12 27V29H28V27C28 24.79 24.42 23 20 23ZM16 17C16 18.0609 16.4214 19.0783 17.1716 19.8284C17.9217 20.5786 18.9391 21 20 21C21.0609 21 22.0783 20.5786 22.8284 19.8284C23.5786 19.0783 24 18.0609 24 17H16ZM19.5 10C19.2 10 19 10.21 19 10.5V13.5H18V11C18 11 15.75 11.86 15.75 14.75C15.75 14.75 15 14.89 15 16H25C24.95 14.89 24.25 14.75 24.25 14.75C24.25 11.86 22 11 22 11V13.5H21V10.5C21 10.21 20.81 10 20.5 10H19.5Z' />
        case 'user':
        case 'profile':
            return <path d="M20 12C21.0609 12 22.0783 12.4214 22.8284 13.1716C23.5786 13.9217 24 14.9391 24 16C24 17.0609 23.5786 18.0783 22.8284 18.8284C22.0783 19.5786 21.0609 20 20 20C18.9391 20 17.9217 19.5786 17.1716 18.8284C16.4214 18.0783 16 17.0609 16 16C16 14.9391 16.4214 13.9217 17.1716 13.1716C17.9217 12.4214 18.9391 12 20 12ZM20 22C24.42 22 28 23.79 28 26V28H12V26C12 23.79 15.58 22 20 22Z"/>
        case 'settings':
            return <path d='M19.7293 23.5C18.8011 23.5 17.9108 23.1313 17.2545 22.4749C16.5981 21.8185 16.2293 20.9283 16.2293 20C16.2293 19.0717 16.5981 18.1815 17.2545 17.5251C17.9108 16.8687 18.8011 16.5 19.7293 16.5C20.6576 16.5 21.5478 16.8687 22.2042 17.5251C22.8606 18.1815 23.2293 19.0717 23.2293 20C23.2293 20.9283 22.8606 21.8185 22.2042 22.4749C21.5478 23.1313 20.6576 23.5 19.7293 23.5ZM27.1593 20.97C27.1993 20.65 27.2293 20.33 27.2293 20C27.2293 19.67 27.1993 19.34 27.1593 19L29.2693 17.37C29.4593 17.22 29.5093 16.95 29.3893 16.73L27.3893 13.27C27.2693 13.05 26.9993 12.96 26.7793 13.05L24.2893 14.05C23.7693 13.66 23.2293 13.32 22.5993 13.07L22.2293 10.42C22.1893 10.18 21.9793 10 21.7293 10H17.7293C17.4793 10 17.2693 10.18 17.2293 10.42L16.8593 13.07C16.2293 13.32 15.6893 13.66 15.1693 14.05L12.6793 13.05C12.4593 12.96 12.1893 13.05 12.0693 13.27L10.0693 16.73C9.93933 16.95 9.99933 17.22 10.1893 17.37L12.2993 19C12.2593 19.34 12.2293 19.67 12.2293 20C12.2293 20.33 12.2593 20.65 12.2993 20.97L10.1893 22.63C9.99933 22.78 9.93933 23.05 10.0693 23.27L12.0693 26.73C12.1893 26.95 12.4593 27.03 12.6793 26.95L15.1693 25.94C15.6893 26.34 16.2293 26.68 16.8593 26.93L17.2293 29.58C17.2693 29.82 17.4793 30 17.7293 30H21.7293C21.9793 30 22.1893 29.82 22.2293 29.58L22.5993 26.93C23.2293 26.67 23.7693 26.34 24.2893 25.94L26.7793 26.95C26.9993 27.03 27.2693 26.95 27.3893 26.73L29.3893 23.27C29.5093 23.05 29.4593 22.78 29.2693 22.63L27.1593 20.97Z' />
        case 'internet':
            return <path d='M24.36 22C24.44 21.34 24.5 20.68 24.5 20C24.5 19.32 24.44 18.66 24.36 18H27.74C27.9 18.64 28 19.31 28 20C28 20.69 27.9 21.36 27.74 22H24.36ZM22.59 27.56C23.19 26.45 23.65 25.25 23.97 24H26.92C25.96 25.65 24.43 26.93 22.59 27.56ZM22.34 22H17.66C17.56 21.34 17.5 20.68 17.5 20C17.5 19.32 17.56 18.65 17.66 18H22.34C22.43 18.65 22.5 19.32 22.5 20C22.5 20.68 22.43 21.34 22.34 22ZM20 27.96C19.17 26.76 18.5 25.43 18.09 24H21.91C21.5 25.43 20.83 26.76 20 27.96ZM16 16H13.08C14.03 14.34 15.57 13.06 17.4 12.44C16.8 13.55 16.35 14.75 16 16ZM13.08 24H16C16.35 25.25 16.8 26.45 17.4 27.56C15.57 26.93 14.03 25.65 13.08 24ZM12.26 22C12.1 21.36 12 20.69 12 20C12 19.31 12.1 18.64 12.26 18H15.64C15.56 18.66 15.5 19.32 15.5 20C15.5 20.68 15.56 21.34 15.64 22H12.26ZM20 12.03C20.83 13.23 21.5 14.57 21.91 16H18.09C18.5 14.57 19.17 13.23 20 12.03ZM26.92 16H23.97C23.65 14.75 23.19 13.55 22.59 12.44C24.43 13.07 25.96 14.34 26.92 16ZM20 10C14.47 10 10 14.5 10 20C10 22.6522 11.0536 25.1957 12.9289 27.0711C13.8575 27.9997 14.9599 28.7362 16.1732 29.2388C17.3864 29.7413 18.6868 30 20 30C22.6522 30 25.1957 28.9464 27.0711 27.0711C28.9464 25.1957 30 22.6522 30 20C30 18.6868 29.7413 17.3864 29.2388 16.1732C28.7362 14.9599 27.9997 13.8575 27.0711 12.9289C26.1425 12.0003 25.0401 11.2638 23.8268 10.7612C22.6136 10.2587 21.3132 10 20 10Z' />
        case 'cleaning':
            return <path d='M28.01 11L29.43 12.42L23.71 18.13C24.78 19.67 24.93 21.52 24.03 22.72L17.71 16.4C18.91 15.5 20.76 15.65 22.3 16.72L28.01 11ZM14.58 25.85C12.57 23.84 11.34 21.44 11 19.2L15.88 17.11L23.32 24.55L21.23 29.43C18.99 29.09 16.59 27.86 14.58 25.85Z' />
        case 'credit-card':
            return <path d='M28 16H12V14H28V16ZM28 26H12V20H28V26ZM28 12H12C10.89 12 10 12.89 10 14V26C10 26.5304 10.2107 27.0391 10.5858 27.4142C10.9609 27.7893 11.4696 28 12 28H28C28.5304 28 29.0391 27.7893 29.4142 27.4142C29.7893 27.0391 30 26.5304 30 26V14C30 12.89 29.1 12 28 12Z' />
        case '#':
        case 'hash':
            return <path style={{ transform: 'scale(0.7) translate(35%, 35%)' }} d="M 33.332031 23.332031 L 26.167969 23.332031 L 27.382812 16.667969 L 33.332031 16.667969 C 34.253906 16.667969 35 15.921875 35 15 C 35 14.078125 34.253906 13.332031 33.332031 13.332031 L 27.984375 13.332031 L 29.132812 6.984375 C 29.226562 6.53125 29.128906 6.066406 28.863281 5.6875 C 28.597656 5.3125 28.1875 5.0625 27.734375 5 C 27.28125 4.890625 26.804688 4.976562 26.417969 5.238281 C 26.03125 5.496094 25.769531 5.90625 25.699219 6.367188 L 24.449219 13.332031 L 17.984375 13.332031 L 19.132812 6.984375 C 19.226562 6.53125 19.128906 6.066406 18.863281 5.6875 C 18.597656 5.3125 18.1875 5.0625 17.734375 5 C 17.28125 4.890625 16.804688 4.976562 16.417969 5.238281 C 16.03125 5.496094 15.769531 5.90625 15.699219 6.367188 L 14.449219 13.332031 L 6.667969 13.332031 C 5.746094 13.332031 5 14.078125 5 15 C 5 15.921875 5.746094 16.667969 6.667969 16.667969 L 13.832031 16.667969 L 12.617188 23.332031 L 6.667969 23.332031 C 5.746094 23.332031 5 24.078125 5 25 C 5 25.921875 5.746094 26.667969 6.667969 26.667969 L 12.015625 26.667969 L 10.867188 33.015625 C 10.773438 33.46875 10.871094 33.933594 11.136719 34.3125 C 11.402344 34.6875 11.8125 34.9375 12.265625 35 C 12.71875 35.109375 13.195312 35.023438 13.582031 34.761719 C 13.96875 34.503906 14.230469 34.09375 14.300781 33.632812 L 15.550781 26.667969 L 22.015625 26.667969 L 20.867188 33.015625 C 20.773438 33.46875 20.871094 33.933594 21.136719 34.3125 C 21.402344 34.6875 21.8125 34.9375 22.265625 35 C 22.71875 35.109375 23.195312 35.023438 23.582031 34.761719 C 23.96875 34.503906 24.230469 34.09375 24.300781 33.632812 L 25.550781 26.667969 L 33.332031 26.667969 C 34.253906 26.667969 35 25.921875 35 25 C 35 24.078125 34.253906 23.332031 33.332031 23.332031 Z M 16.167969 23.332031 L 17.382812 16.667969 L 23.832031 16.667969 L 22.617188 23.332031 Z M 16.167969 23.332031 "/>
        default:
            return ''
    }
}

const Icon = ({ type, variant, size=40, classAdditions, wrapperClassAdditions, ...props }) => (
    <span
        className={`icon-wrapper d-inline-flex icon-wrapper--${type} ${wrapperClassAdditions}`}
    >
        <svg
            className={`icon icon--${type}${variant ? ` icon--${variant}` : ''} ${classAdditions}`}
            width={size}
            height={size}
            viewBox='7 7 26 26'
            xmlns='http://www.w3.org/2000/svg'
            fill='#78909C'
            {...props}
        >
            {getSvg(type)}
        </svg>
    </span>
)

export default Icon
