import React, { useEffect, useRef } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'
import { searchTermVar } from '../../cache'
import SearchResultItem from './SearchResultItem'
import useIntersectionObserver from '../../hooks/useIntersectionObserver'

const SearchResults = ({ 
    query,
    app,
    contentTypeModel,
    path,
    lblPl,
    titleField,
    bodyField,
    authorField,
    dateField,
    onCompleted,
    onCountChange,
    onIntersect,
    containerEl,
    closePopup,
    limit=30,
    hideHeading=false
}) => {
    const targetRef = useRef(null)
    const searchTerm = useReactiveVar(searchTermVar)
    const { loading, error, data, fetchMore } = useQuery(query, {
        variables: {
            searchTerm,
            offset: 0,
            limit
        },
        onCompleted,
        notifyOnNetworkStatusChange: true
    })
    const queryName = data ? Object.keys(data)[0] : ''
    const resultsCount = data ? data[queryName]?.length || 0 : 0

    useIntersectionObserver({
        onIntersect,
        targetRef,
        root: containerEl,
        rootMargin: '-50% 0px -50% 0px',
        threshold: '0.0'
    })

    useEffect(() => {
        onCountChange(resultsCount)
    }, [resultsCount])

    if (!loading && !data && !error) return null

    const hasMore = data ? !!data[queryName][data[queryName].length-1]?.hasMore : false

    return (
        <div className={`search-results__section search-results__section--${app}`} ref={ targetRef }>
            {(!hideHeading && (loading || error || (data && data[queryName].length > 0))) &&
                <div className='search-results__title d-inline-flex t-uppercase'>
                    {lblPl}
                </div>
            }
            {error && <div className='error t-red w-100 t-center p-3'>{error.message}</div>}
            {(data && data[queryName].length > 0) && 
                <ul className='search-results__list'>
                    {data && data[queryName].map((result) => {
                        return (
                            <React.Fragment key={contentTypeModel + result.id}>
                                <SearchResultItem
                                    icon={contentTypeModel === 'thread' ? '#' : contentTypeModel}
                                    title={result[titleField]}
                                    body={bodyField && result[bodyField]}
                                    author={result[authorField]}
                                    date={result[dateField]}
                                    path={path + result.id}
                                    searchTerm={searchTerm}
                                    closePopup={closePopup}
                                    elementId={`${contentTypeModel}-${result.id}`}
                                />
                                {result.messagesBySearchTerm &&
                                    <ul className='search-results__sublist'>
                                        {result.messagesBySearchTerm && result.messagesBySearchTerm.map(message => {
                                            return (
                                                <SearchResultItem
                                                    key={contentTypeModel + result.id + 'message' + message.id}
                                                    icon='message'
                                                    title={message.title}
                                                    body={message.body}
                                                    author={message.sender}
                                                    date={message.sentAt}
                                                    path={path + result.id + '#' + message.id}
                                                    searchTerm={searchTerm}
                                                    closePopup={closePopup}
                                                    elementId={'message-' + message.id}
                                                />
                                            )
                                        })}
                                    </ul>
                                }
                                {result.notesBySearchTerm &&
                                    <ul className='search-results__sublist'>
                                        {result.notesBySearchTerm && result.notesBySearchTerm.map(note => {
                                            return (
                                                <SearchResultItem
                                                    key={contentTypeModel + result.id + 'note' + note.id}
                                                    icon='note'
                                                    body={note.body}
                                                    author={note.createdBy}
                                                    date={note.createdAt}
                                                    path={path + result.id + '/notes#' + note.id}
                                                    searchTerm={searchTerm}
                                                    closePopup={closePopup}
                                                    elementId={'note-' + note.id}
                                                />
                                            )
                                        })}
                                    </ul>
                                }
                            </React.Fragment>
                        )
                    })}
                </ul>
            }
            {loading ?
                <div className='loader' style={{ marginBottom: '40px' }}/>
            : hasMore ?
                <button
                    className='btn btn--naked btn--block mt-1 ml-4 mb-2'
                    onClick={() => {
                        fetchMore({
                            variables: {
                                offset: data[queryName].length,
                                limit
                            }
                        })
                    }}>
                    <span className='t-blue-grey t-underline p-3'>Load more {lblPl}</span>
                </button>
            : null}
        </div>
    )
}

export default SearchResults
