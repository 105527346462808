import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import MomentUtils from '@date-io/moment'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import {
    LogIn,
    RequestPasswordReset,
    ResetPassword,
    ChangePassword,
    Join
} from './forms'
import {
    SiteNav,
    TabNav,
    Header,
    User,
    UserProfile,
} from './components'
import Tickets from './tickets/components/TicketsLoader'
import Tasks from './tasks/components/TasksLoader'
import Properties from './properties/components/PropertyLoader'
import ContractRedirect from './properties/components/ContractRedirect'
import LeadRedirect from './properties/components/LeadRedirect'
import ServiceRedirect from './properties/components/ServiceRedirect'
import ServicePaymentRedirect from './properties/components/ServicePaymentRedirect'
import Messenger from './messenger/components/Messenger'
import Contacts from './contacts/components/ContactLoader'
import ProfileRedirect from './contacts/components/ProfileRedirect'
import Templates from './templates/components/TemplateLoader'
import Calls from './calls/components/CallsLoader'
import Notifications from './notifications/components/NotificationList'
import { generateApolloClient } from './index'
import { isLoggedInVar } from './cache'
import { useReactiveVar } from '@apollo/client'
import ErrorBoundary from './components/ErrorBoundary'
import Ocp from './components/Ocp'


// Wrapper for <Route> that redirects to the login
// screen if user is not yet authenticated
// and provides origin in location.state.from.
const PrivateRoute = ({ children, hideHeader, ...rest }) => (
    <User>
        {({ me }) => (
            <Route
                {...rest}
                render={({ location }) => {
                    if (me) {
                        if (!!me.employee) {
                            return (
                                <div className='site-container'>
                                    <SiteNav user={me} />
                                    <div className='page-container'>
                                        {!hideHeader && <Header />}
                                        {children}
                                    </div>
                                </div>
                            )
                        }
                        alert('You do not have authorized access to this platform')
                    }
                    return (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: location }
                            }}
                        />
                    )
                }}
            />
        )}
    </User>
)

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#E6EFFF',
            main: '#367BF5',
            dark: '#78909C'
        },
        text: {
            primary: '#31383C',
            disabled: '#818181'
        }
    },
    transitions: {
        create: () => 'none'
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },
})

const GraphqlProvider = ({ children }) => {
    const isLoggedIn = useReactiveVar(isLoggedInVar)
    let client;
    if (isLoggedIn) {
        console.debug('User is logged in. Generating ApolloClient with websocket.')
        client = generateApolloClient(true)[0];
    } else {
        client = generateApolloClient(false)[0];
    }


    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

const App = ({ client }) => {
    return (
        <GraphqlProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <MuiThemeProvider theme={theme}>
                    <DndProvider backend={HTML5Backend}>
                        <Ocp/>
                        <Switch>
                            <PrivateRoute path='/' exact>
                                <Redirect to={{ pathname: '/tickets' }}/>
                            </PrivateRoute>
                            <PrivateRoute path='/today'>
                                <TabNav>Today</TabNav>
                            </PrivateRoute>
                            <PrivateRoute path='/tickets/:activeTab?/:id?/:activeSubtab?'>
                                <TabNav><Tickets/></TabNav>
                            </PrivateRoute>
                            <PrivateRoute path='/tasks/:activeTab?/:id?/:activeSubtab?'>
                                <TabNav><Tasks/></TabNav>
                            </PrivateRoute>
                            <PrivateRoute path='/properties/:activeTab?/:id?/:activeSubtab?'>
                                <TabNav><Properties/></TabNav>
                            </PrivateRoute>
                            <PrivateRoute path='/calendar'>
                                <TabNav>Calendar</TabNav>
                            </PrivateRoute>
                            <PrivateRoute path='/messenger/:activeTab?/:id?'>
                                <TabNav><Messenger/></TabNav>
                            </PrivateRoute>
                            <PrivateRoute path='/profile'>
                                <TabNav><UserProfile/></TabNav>
                            </PrivateRoute>
                            <PrivateRoute path='/contacts/:activeTab?/:id?/:activeSubtab?'>
                                <TabNav><Contacts/></TabNav>
                            </PrivateRoute>
                            <PrivateRoute path='/profiles/profile/:id?'>
                                <TabNav><ProfileRedirect/></TabNav>
                            </PrivateRoute>
                            <PrivateRoute path='/contracts/contract/:id?'>
                                <TabNav><ContractRedirect/></TabNav>
                            </PrivateRoute>
                            <PrivateRoute path='/leads/lead/:id?'>
                                <TabNav><LeadRedirect/></TabNav>
                            </PrivateRoute>
                            <PrivateRoute path='/services/service/:id?'>
                                <TabNav><ServiceRedirect/></TabNav>
                            </PrivateRoute>
                            <PrivateRoute path='/services/service_payment/:id?'>
                                <TabNav><ServicePaymentRedirect/></TabNav>
                            </PrivateRoute>
                            <PrivateRoute path='/templates/:activeTab?/:id?'>
                                <TabNav><Templates/></TabNav>
                            </PrivateRoute>
                            <PrivateRoute path='/calls/:activeTab?/:id?'>
                                <TabNav><Calls/></TabNav>
                            </PrivateRoute>
                            <PrivateRoute path='/change_password' hideHeader>
                                <ErrorBoundary>
                                    <ChangePassword />
                                </ErrorBoundary>
                            </PrivateRoute>
                            <PrivateRoute path='/notifications'>
                                <ErrorBoundary>
                                    <Notifications/>
                                </ErrorBoundary>
                            </PrivateRoute>
                            <Route path='/login'>
                                <ErrorBoundary>
                                    <LogIn/>
                                </ErrorBoundary>
                            </Route>
                            <Route path='/request_reset'>
                                <ErrorBoundary>
                                    <RequestPasswordReset/>
                                </ErrorBoundary>
                            </Route>
                            <Route path='/reset/:resetToken'>
                                <ErrorBoundary>
                                    <ResetPassword/>
                                </ErrorBoundary>
                            </Route>
                            <Route path='/join/:invitationToken'>
                                <ErrorBoundary>
                                    <Join/>
                                </ErrorBoundary>
                            </Route>
                        </Switch>
                    </DndProvider>
                </MuiThemeProvider>
            </MuiPickersUtilsProvider>
        </GraphqlProvider>
    )
}

export default App
