import { gql } from '@apollo/client'
import { TEMPLATE_FRAGMENT } from './fragments'

export const GET_TEMPLATE = gql`
    query GET_TEMPLATE($id: ID!) {
        template(id: $id) {
            id
            name
            body
            archived
            createdBy {
                id
            }
        }
    }
`

export const GET_TEMPLATES = gql`
    query GET_TEMPLATES(
        $offset: Int, $limit: Int, $searchTerm: String, $groupBy: String, $groupDir: Int, $sortBy: String, $sortDir: Int, $filters: String
    ) {
        templates(offset: $offset, limit: $limit, searchTerm: $searchTerm, groupBy: $groupBy, groupDir: $groupDir, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            ...TemplateFields
            hasMore
        }
    }
    ${TEMPLATE_FRAGMENT}
`
