import { useApolloClient, useQuery } from '@apollo/client'
import { useState } from 'react'
import { propertyAttachmentListCtrlsVar } from '../../cache'
import { GET_ATTACHMENTS_FOR_CONTENT_OBJECTS } from '../../attachments/queries'
import { GET_PROPERTY_ATTACHMENT_TAG_OBJECTS } from '../queries'
import AttachmentsLister from '../../attachments/components/AttachmentLister'
import PropertyTypeFilters from './PropertyTypeFilters'
import { extendedListerColumns, extendedListerCtrlOptions } from '../../attachments/settings'

export const listerColumns = ['originalFilename', 'contentType', 'dateChanged', 'dateCreated', 'uploadedBy']

const PropertyAttachments = ({
    contentType,
    objectId,
    ...props
}) => {
    let [contentObjects, setContentObjects] = useState({ [contentType]: [objectId] })
    const { data, loading, error } = useQuery(GET_PROPERTY_ATTACHMENT_TAG_OBJECTS, {
        variables: {
            buildingIds: contentObjects['building'],
            propertyIds: contentObjects['address'],
            unitIds: contentObjects['room']
        }
    })
    const { cache } = useApolloClient()

    const renderFilters = () => (
        <PropertyTypeFilters
            contentType={contentType}
            state={contentObjects}
            setState={setContentObjects}
            subtab='attachments'
            As='li'
            className='ctrls__listitem ml-2'
            {...props}
        />
    )

    const contentObjectsVar = [
        ...Object.keys(contentObjects).sort().map(type => ({ contentType: type, objectIds: contentObjects[type].sort() })),
        !!data?.propertyTasks?.length && { contentType: 'task', objectIds: data.propertyTasks.map(({ id }) => id)},
        !!data?.propertyThreads?.length && { contentType: 'thread', objectIds: data.propertyThreads.map(({ id }) => id)},
    ].filter(Boolean)

    return (
        <div className='tab__panel--main intersection-root'>
            {error && <div className='pos-center-y w-100 t-center'>{error.message}</div>}
            {loading && <div className='loader pos-center-y'/>}
            {data &&
                <AttachmentsLister
                    columns={extendedListerColumns}
                    ctrlOptions={extendedListerCtrlOptions}
                    listCtrlVar={propertyAttachmentListCtrlsVar}
                    contentTypeString={`property | ${contentType}`}
                    contentObjectId={objectId}
                    query={GET_ATTACHMENTS_FOR_CONTENT_OBJECTS}
                    queryFieldName='attachmentsForContentObjects'
                    queryVariables={{ contentObjects: contentObjectsVar }}
                    renderCtrlChildren={renderFilters}
                    onUploaded={() => {
                        cache.evict({ id: 'ROOT_QUERY', fieldName: 'attachmentsForContentObjects' })
                        cache.gc()
                    }}
                />
            }
        </div>
    )
}

export default PropertyAttachments
