import { useLocation, useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { ARCHIVE_SERVICE_PAYMENT, UPDATE_SERVICE_PAYMENT } from '../mutations'
import Form from '../../forms/Form'
import { lbl } from '../../constants'
import { useState } from 'react'
import { postAsFormData } from '../../utils'


const ServicePaymentForm = ({
    servicePayment={},
    draft,
    submitMutation=UPDATE_SERVICE_PAYMENT,
    mutationVariables={},
    close=()=>{},
    setDraft
}) => {
    const history = useHistory()
    const { pathname } = useLocation()
    const initialState = draft || { ...servicePayment }
    const [uploading, setUploading] = useState(false)
    const [uploadError, setUploadError] = useState('')
    const [updateServicePayment, { loading, done, called, data, error }] = useMutation(submitMutation)
    const [archiveServicePayment, { loading: archiving }] = useMutation(ARCHIVE_SERVICE_PAYMENT)

    const success = !error && !uploadError && !loading && !uploading && (called && !!data)

    const fields = [
        { name: 'periodStartDate', type: 'date', required: true },
        { name: 'periodEndDate', type: 'date', required: true },
        { name: 'dueDate', type: 'date' },
        { name: 'paymentDate', type: 'date' },
        { name: 'paidById', lblKey: 'paidBy', As: 'select', options: [] },
        { name: 'amount', type: 'number' },
        { name: 'file', id: 'file_' + servicePayment.id, lblKey: servicePayment.file ? 'changeFile' : 'uploadFile', type: 'file', value: undefined, wide: true, href: servicePayment.file?.url, filename: servicePayment.file?.originalFilename, onChangeCb: () => uploadError && setUploadError('') },
        { name: 'note', As: 'textarea', wide: true }
    ]

    const cleanAndClose = (args) => {
        if (setDraft) setDraft({})
        if (!!args?.addServicePayment) {
            const url = `${pathname}#service_payment_${args.addServicePayment.servicePayment?.id}`
            history.push(url)
            close()
        }
    }

    const uploadFile = ({ file, onSuccess }) => {
        setUploading(true)
        postAsFormData({
            data: {'file': file },
            onError: (err) => setUploadError(err.message),
            onSuccess: (attachment) => onSuccess(attachment.id),
            onDone: () => setUploading(false)
        })
    }

    return (
        <Form
            key={'service_payment_' + servicePayment.id}
            fields={fields}
            initialState={initialState}
            onSubmit={(data, cancelChanges) => {
                const { file, ..._data } = data
                const saveServicePayment = (fileId) => {
                    updateServicePayment({
                        variables: { id: servicePayment.id, fileId, ..._data, ...mutationVariables },
                        onError: cancelChanges,
                        onCompleted: cleanAndClose
                    })
                }

                if (file) {
                    uploadFile({ file, onSuccess: saveServicePayment })
                } else if (Object.keys(_data).length) {
                    saveServicePayment()
                }
            }}
            onCancel={close}
            isSubmitting={archiving || uploading || (loading && !done)}
            isSuccess={success}
            disabled={servicePayment.archived}
            setDraft={setDraft}
            formClassName='form--column--2'
            error={error?.message || uploadError}
            actions={(servicePayment.id && !servicePayment.archived) ? [{
                label: lbl.archive,
                onClick: () => {
                    if (window.confirm('Are you sure you want to archive this service payment?')) {
                        archiveServicePayment({ variables: { id: servicePayment.id }})
                    }
                }
            }] : []}
        />
    )
}

export default ServicePaymentForm
