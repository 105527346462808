import { gql } from '@apollo/client'
import { CALL_FRAGMENT } from './fragments'

export const ON_MISSED_CALL_SUBSCRIPTION = gql`
    subscription ON_MISSED_CALL_SUBSCRIPTION($departments: [String!]) {
        onMissedCall(departments: $departments) {
            call {
                ...CallFields
            }
        }
    }
    ${CALL_FRAGMENT}
`

export const ON_CALL_UPDATE_SUBSCRIPTION = gql`
    subscription ON_CALL_UPDATE_SUBSCRIPTION($callSids: [String]) {
        onCallUpdate(callSids: $callSids) {
            call {
                ...CallFields
            }
            created
        }
    }
    ${CALL_FRAGMENT}
`
