const SwitchButton = ({
    name,
    checked,
    disabled,
    onSwitch,
    labelLeft,
    labelRight,
    innerLabel,
    variant
}) => {
    return (
        <div className={`btn--switch${checked ? ' switched' : ''}${variant ? ` btn--switch--${variant}` : ''}`}>
            {!innerLabel && labelLeft &&
                <div className='switch__label--outer switch__label--left'>
                    {labelLeft}
                </div>
            }
            <div className={`switch switch--lbl-${innerLabel ? 'inner' : 'outer'}`}>
                <input
                    type='checkbox'
                    className='switch__checkbox'
                    name={name}
                    id={name}
                    checked={checked}
                    disabled={disabled}
                    onChange={e => onSwitch(e.target.checked)}
                />
                <label
                    className='switch__label'
                    htmlFor={name}
                >
                    <span
                        className='switch__inner'
                        data-left={innerLabel ? labelLeft : ''}
                        data-right={innerLabel ? labelRight : ''}
                    />
                    <span className='switch__switch' />
                </label>
            </div>
            {!innerLabel && labelRight &&
                <div className='switch__label--outer switch__label--right'>
                    {labelRight}
                </div>
            }
        </div>
    )
}

export default SwitchButton