import { useContext } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { tabNavLinks } from '../../constants'
import { TabNavContext } from '../../components/TabNav'
import { GET_PROFILE_USER_ID } from '../queries'


const ProfileRedirect = () => {
    const { id } = useContext(TabNavContext)
    const { hash } = useLocation()
    const { data, loading, error } = useQuery(GET_PROFILE_USER_ID, { variables: { profileId: id } })

    if (loading) return <div className='loader pos-center-y' />
    
    if (error) return (
        <div className='t-red w-100 pos-center-y'>
            <center>{error.message}</center>
        </div>
    )

    if (data?.profileUserId) {
        return <Redirect to={`${tabNavLinks.contacts.dynamicPath}${data.profileUserId}${hash}`} />
    }

    return null
}

export default ProfileRedirect
