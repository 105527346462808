import { Link } from 'react-router-dom'
import { Icon } from '../../components'

const PinnedNotePreview = ({ note, path }) => {
    if (!note.pinned) return null
    return (
        <div key={`pinned-note-${note.id}`} className='note-preview__wrapper'>
            <Link
                className='note-preview note-preview--pinned'
                to={`${path}/notes#${note.id}`}
                title={`Go to note #${note.id}`}
            >
                <Icon type='pin' variant='2xs'/>
                <div
                    className='note-preview__text'
                    dangerouslySetInnerHTML={{ __html: note.body }}
                />
            </Link>
        </div>
    )
}

export default PinnedNotePreview