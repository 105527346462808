import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useApolloClient, useReactiveVar } from '@apollo/client'
import { newDraftsVar, openedTabsVar } from '../../cache'
import { CREATE_BUILDING } from '../mutations'
import { GET_BUILDINGS } from '../queries'
import { tabNavLinks, lbl } from '../../constants'
import BuildingInfoForm from './BuildingInfoForm'


const NewBuilding = () => {
    const history = useHistory()
    const client = useApolloClient()
    const buildingDraft = useReactiveVar(newDraftsVar).building
    const openedTabs = useReactiveVar(openedTabsVar)
    const { relativeUrl } = tabNavLinks.properties.building.new
    const onCompleted = (data) => {
        openedTabs.delete(relativeUrl)
        openedTabsVar(new Map([...openedTabs]))
        newDraftsVar({ ...newDraftsVar(), building: {} })
        if (data) {
            data.createBuilding?.building && client.cache.updateQuery({
                query: GET_BUILDINGS,
            }, (prevData) => {
                if (!prevData?.buildings) return undefined
                return { buildings: [data.createBuilding?.building, ...prevData.buildings] }
            })
            history.push(`${tabNavLinks.properties.building.dynamicPath}${data.createBuilding?.building?.id}`)
        } else {
            history.goBack()
        }
    }

    useEffect(() => {
        openedTabsVar(new Map([...openedTabsVar(), [relativeUrl, {
            name: lbl.newBuilding,
            id: 'new',
            relativeUrl,
            icon: 'building'
        }]]))
    }, [relativeUrl])

    return (
        <div className='split-panel--main'>
            <BuildingInfoForm
                draft={buildingDraft}
                submitMutation={CREATE_BUILDING}
                onCompleted={onCompleted}
                setDraft={building => { newDraftsVar({ ...newDraftsVar(), building }) }}
            />
        </div>
    )
}

export default NewBuilding
