import { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { staticTabs } from '../../constants'
import { TabNavContext } from '../../components/TabNav'
import Template from './Template'
import TemplatesList from './TemplatesList'
import NewTemplate from './NewTemplate'

const TemplateLoader = () => {
    const { activeTab, id } = useContext(TabNavContext)

    switch (activeTab) {
        case 'list':
            return <TemplatesList/>
        case 'template':
            return <Template key={activeTab + id} />
        case 'new':
            return <NewTemplate/>
        default:
            return <Redirect to={staticTabs.templates[0].relativeUrl} />
    }
}

export default TemplateLoader
