export const FROALA_LICENCE_KEY = "qc1H2pH2A2B2E6D7C5odgygB-16knbsnB2voB-16D4E3D2E2C3I2F1B10B2C1=="

export const lbl = {
    about: 'About',
    accountNumber: 'Account number',
    accountHolderName: 'Account Holder Name',
    accountHolderAddress: 'Account Holder Address',
    activate: 'Activate',
    activateTicketPrompt: 'To set status activate this ticket and choose pipeline board.',
    active: 'Active',
    activeContractDetails: 'Active Contract Details',
    activeLeadDetails: 'Active Lead Details',
    activity: 'Activity',
    addBuilding: 'Add building',
    addContact: 'Add contact',
    addDetails: 'Add some details...',
    addFilter: '+ Add filter',
    addition: 'Addition',
    additionalInfo: 'Additional information',
    addLabel: '+ Add label',
    addLabelSearch: 'Add label...',
    addMembers: 'Add members to thread',
    addNewContract: 'Add new contract',
    addNewPropertyContract: 'Add new property contract',
    addNewUnitContract: 'Add new unit contract',
    addNewOwnerContract: 'Add new owner contract',
    addNewTenantContract: 'Add new tenant contract',
    addNewLead: 'Add new lead',
    addNewMeter: 'Add new meter',
    addNewMeterReading: 'Add new meter reading',
    addNewPropertyLead: 'Add new lead for property',
    addNewService: 'Add new service',
    addNewPayment: 'Add new payment',
    addNewUnitLead: 'Add new lead for unit',
    addNewUtilityPayment: 'Add new utility payment',
    addPastAction: 'Added',
    addProperty: 'Add property',
    addTag: 'Add tag',
    addNoteTagSearch: 'Select note tag...',
    addTemplate: 'Add template',
    addTenant: 'Add tenant',
    addTenantToContract: 'Add tenant to contract',
    addUnit: 'Add unit',
    address: 'Address',
    addresses: 'Addresses',
    addReviewerGroup: 'Select reviewer group',
    addStatusName: 'Add Status Name',
    addSubthread: 'Add subthread',
    addTask: 'Add task',
    addTicket: 'Add ticket',
    addThread: 'Add thread',
    addToBoard: '+ Add to board',
    addTemplateForGroup: 'Select group',
    amount: 'Amount',
    approval: 'Approval',
    archive: 'Archive',
    archived: 'Archived',
    archiving: 'Archiving...',
    assign: 'Assign',
    assignee: 'Assignee',
    assignedToMe: 'Assigned to me',
    attachmentCategory: 'Attach. Category',
    attachments: 'Attachments',
    bankName: 'Bank Name',
    bankAddress: 'Bank Address',
    basicInfo: 'Basic Information',
    board: 'Board',
    bicCode: 'SWIFT / BIC code',
    boilerRepair: 'Boiler Repair',
    brand: 'Brand',
    bsnNumber: 'BSN number',
    budget: 'Budget',
    building: 'Building',
    buildings: 'Buildings',
    call: 'Call',
    calls: 'Calls',
    cancel: 'Cancel',
    caretaker: 'Caretaker',
    category: 'Category',
    changePassIntro: 'Please fill the form bellow and we will update your password.',
    changePastAction: 'Changed',
    changeFile: 'Change file',
    changePicture: 'Change picture',
    chats: 'Chats',
    checkInDate: 'Check in date',
    checkOutDate: 'Check out date',
    chooseBoardPrompt: 'Please select board for this ticket first',
    city: 'City',
    close: 'Close',
    company: 'Company',
    companyInfo: 'Company Info',
    connectToTicket: 'Connect to ticket...',
    confirm: 'Confirm',
    contract: 'Contract',
    contractDetails: 'Contract Details',
    contractFile: 'Contract document',
    contractLink: 'Contract link',
    contracts: 'Contracts',
    contractEndDate: 'Contract End Date',
    contractStartDate: 'Contract Start Date',
    contractType: 'Contract type',
    country: 'Country',
    countryOfOrigin: 'Country of origin',
    countryOfResidence: 'Country of residence',
    createContract: 'Create contract',
    createLead: 'Create lead',
    createdBy: 'Created by',
    createPastAction: 'Created',
    currentAddress: 'Current living address',
    date: 'Date',
    dateInstalled: 'Date installed',
    degreeTitle: 'Degree title',
    delete: 'Delete',
    deleted: 'Deleted',
    deletePastAction: 'Deleted',
    deliveredTo: 'Delivered to:',
    deposit: 'Deposit',
    depositReimbursed: 'Deposit Reimbursed',
    dob: 'Date of birth',
    documents: 'Documents',
    dueDate: 'Due date',
    duplicate: 'Duplicate',
    email: 'Email',
    emergencyContact: 'Emergency contact',
    emergencyContactAndSocialMedia: 'Emergency Contact and Social Media',
    employmentStatus: 'Employment status',
    endDate: 'End Date',
    facebook: 'Facebook',
    filter: 'Filter',
    filterBy: 'Filter by',
    filterXBy: 'Filter {} by',
    filteredBy: 'Filtered by:',
    files: 'Files',
    financialInfo: 'Financial Information',
    firstName: 'First name',
    flatmate: 'Flatmate',
    forgotPassIntro: 'it seems you forgot your password. Do not worry, happens to the best of us.',
    forgotPassDesc: 'We will email you a link to reset your password. That link is valid for single use and will expire in one hour.',
    forgotPassQuestion: 'Forgot your password?',
    forgotPassSuccess: 'Check your email for a reset link.',
    forIndefinitePeriod: 'Indefinite rental contract',
    gender: 'Gender',
    goHome: 'Go home',
    goToLogin: 'Go to login',
    goToTicket: 'Go to ticket',
    goToRelTicket: 'Go to related ticket',
    goToRelThread: 'Go to related thread',
    graduationDate: 'Graduation date',
    group: 'Group',
    groupBy: 'Group by',
    groupXBy: 'Group {} by',
    groupedBy: 'Grouped by:',
    hasFireSafetySystem: 'Fire Safety System',
    hi: 'Hi',
    hobbies: 'Hobbies',
    homeownerAssociation: 'Homeowner Association',
    hoaManager: 'VvE Manager',
    houseNumber: 'House number',
    houseNumberAddition: 'House number addition',
    indefinite: '∞',
    indefiniteTenancy: 'Indefinite tenancy',
    info: 'Info',
    information: 'Information',
    instagram: 'Instagram',
    instantSend: 'Instant send',
    joinIntro: 'Please choose your password to proceed to next step.',
    keycode: 'Key code',
    label: 'Label',
    labels: 'Labels',
    language: 'Language',
    lastCheckUpDate: 'Last check-up date',
    lastName: 'Last name',
    latestLeadDetails: 'Latest Lead Details',
    leads: 'Leads',
    linkedIn: 'LinkedIn',
    list: 'List',
    location: 'Location',
    login: 'Login',
    mailSubject: 'Email Subject',
    makeCall: 'Make a call',
    mandateAmount: 'Mandate Amount',
    mentions: 'Mentions',
    merge: 'Merge',
    messages: 'Messages',
    meterNumber: 'Meter number',
    meters: 'Meters',
    minOccupancy: 'Minimum occupancy',
    maxOccupancy: 'Maximum occupancy',
    missedCall: 'Missed call',
    model: 'Model',
    modemCode: 'Modem code',
    modemLocation: 'Modem location',
    monthlyBasicRent: 'Monthly basic rent',
    monthlyOwnerServiceCost: 'Monthly service cost for owner',
    monthlyRent: 'Monthly rent',
    monthlyServiceCost: 'Monthly service cost',
    name: 'Name',
    nationality: 'Nationality',
    networkName: 'Network name',
    networkPassword: 'Network password',
    newContentPlaceholder: '...edit your content here',
    new: 'New',
    newBuilding: 'New building',
    newContact: 'New contact',
    newContract: 'New contract',
    newLead: 'New lead',
    newMessage: 'New message',
    newMsgPlaceholder: '...type your message here',
    newMsgPlaceholderNonMember: '...type your message here and join this thread',
    newMeter: 'New meter',
    newMeterReading: 'New meter reading',
    newOwnerContract: 'New owner contract',
    newProperty: 'New property',
    newService: 'New service',
    newServicePayment: 'New service payment',
    newTicket: 'New ticket',
    newTask: 'New task',
    newTemplate: 'New template',
    newTenancyAgreement: 'New Tenancy Agreement',
    newUnit: 'New unit',
    nextCheckUpDueDate: 'Next check-up due date',
    noAssignee: 'No assignee',
    noBoard: 'No board',
    noCategory: 'No category',
    noOwner: 'No owner',
    noReviewerGroup: 'No reviewer group',
    noStatus: 'No status',
    noTicket: 'No ticket',
    noTemplateForGroup: 'No default group',
    note: 'Note',
    notes: 'Notes',
    notificationTitle: 'Notification Title',
    notRequired: 'Not required',
    operationalModel: 'Operational model',
    other: 'Other',
    otherCosts: 'Other costs',
    owner: 'Owner',
    packageType: 'Package type',
    paidBy: 'Paid by',
    payment: 'payment',
    paymentDate: 'Date of payment',
    paymentFrequency: 'Payment frequency',
    password: 'Password',
    periodStartDate: 'Period start date',
    periodEndDate: 'Period end date',
    personalInfo: 'Personal details',
    phoneNumber: 'Phone number',
    picture: 'Picture',
    pinned: 'Pinned',
    postcode: 'Postcode',
    preferredCheckInDate: 'Preferred check-in date',
    preferredLanguage: 'Preferred language',
    price: 'Price',
    priority: 'Priority',
    property: 'Property',
    propertyManagement: 'Property Management',
    propertyType: 'Property type',
    provider: 'Provider',
    readBy: 'Read by:',
    redirectingToProfile: 'You will be redirected to finish setting up your profile...',
    relationship: 'Relationship',
    relationshipStatus: 'Relationship status',
    removeAllFilters: 'Remove all filters',
    removePastAction: 'Removed',
    removeTag: 'Remove tag',
    rentResponsabilityEndDate: 'Rent responsability end date',
    rentResponsabilityStartDate: 'Rent responsability start date',
    repairContact: 'Repair contact',
    requestNewPassReset: 'Request new link',
    requestPassReset: 'request password reset',
    required: 'Required',
    resetPassIntro: 'Please type your new password bellow:',
    resetPassSuccess: 'Your new password is set.',
    restore: 'Restore from archive',
    reviewerGroup: 'Reviewer group',
    roommate: 'Roommate',
    save: 'Save',
    saved: 'Saved',
    saveNote: 'Save note',
    saving: 'Saving...',
    search: 'Search',
    searchBy: 'Search by:',
    secondaryEmail: 'Secondary email',
    secondaryPhoneNumber: 'Secondary phone number',
    seenBy: 'Seen by:',
    selectAssignee: 'Select assignee',
    selectAttachmentCategory: 'Select attachment category',
    selectBoard: 'Select board',
    selectCategory: 'Select category',
    selectContact: 'Select contact',
    selectDepartment: 'Select department',
    selectEmploymentStatus: 'Select employment status',
    selectForMerge: 'Select ticket to merge with ...',
    selectOwner: 'Select owner',
    selectTicket: 'Select ticket ...',
    selectUserGroup: 'Select user group',
    send: 'Send',
    sendInvitation: 'Send email invitation',
    sendInvitationSuccess: 'Email invitation sent to',
    sendNotification: 'Send notification',
    sentBy: 'Sent by:',
    serviceCostDescription: 'Service cost description',
    serviceCostDivisionModel: 'Service cost division model',
    service: 'Service',
    serviceFrequency: 'Service frequency',
    services: 'Services',
    serviceType: 'Service type',
    setAssignee: 'Assign to...',
    setDueDate: 'Set due date...',
    setPassSuccess: 'Your password is set',
    contractSignedDate: 'Contract signed on',
    sizeSqm: 'Size (sqm)',
    socialMedia: 'Social media',
    sort: 'Sort',
    sortBy: 'Sort by',
    sortXBy: 'Sort {} by',
    sortedBy: 'Sorted by:',
    spokenLanguage: 'Spoken language',
    startDate: 'Start Date',
    status: 'Status',
    streetName: 'Street name',
    success: 'Success!',
    tasks: 'Tasks',
    taskTitle: 'Task title',
    templateForGroup: 'Default for',
    templateTitle: 'Template title',
    tenant: 'Tenant',
    tenantRepresentative: 'Tenant Representative',
    tenantRepresentativeShort: 'Tenant Rep.',
    ticket: 'Ticket',
    tickets: 'Tickets',
    ticketTitle: 'Ticket title',
    tiktok: 'Tik-Tok',
    title: 'Title',
    tryAgainOr: 'Try again or',
    type: 'Type',
    unassigned: 'Unassigned',    
    undo: 'Undo',
    ungroupedTitle: 'Others',
    unit: 'Unit',
    unitNumber: 'Unit number',
    units: 'Units',
    updates: 'Updates',
    upload: 'Upload',
    uploadFile: 'Upload file',
    uploadPicture: 'Upload picture',
    user: 'User',
    username: 'Username',
    userGroup: 'User group',
    userType: 'User type',
    userType_4_employees: 'Employee',
    userType_1_guests: 'Guest',
    userType_2_landlords: 'Landlord',
    userType_3_partners: 'Partner',
    value: 'Value',
    yourEmail: 'Your email',
    yourNewPass: 'Your new password',
    yourNewPassRetype: 'Re-type your new password',
    yourOldPass: 'Your current password',
    yourPass: 'Your password',
    yourPassRetype: 'Re-type your password',
    welcomeTo: 'Welcome to',
}

export const siteNavLinks = [
    // { label: 'Today', pathname: '/today', icon: 'time' },
    { label: 'Tickets', pathname: '/tickets', icon: 'ticket'},
    { label: 'Tasks', pathname: '/tasks', icon: 'task' },
    { label: 'Properties', pathname: '/properties', icon: 'property' },
    // { label: 'My calendar', pathname: '/calendar', icon: 'calendar' },
    { label: 'Messenger', pathname: '/messenger', icon: 'message', counter: 'unreadMessageCount' },
    { label: 'Calls', pathname: '/calls', icon: 'call', counter: 'missedCallsCount' },
    { label: 'Contacts', pathname: '/contacts', icon: 'contact' },
    { label: 'Templates', pathname: '/templates', icon: 'clipboard' },
    { label: 'Notifications', pathname: '/notifications', icon: 'bell', counter: 'unreadNotificationsCount' },
]

export const staticTabs = {
    tickets: [
        { label: lbl.board, baseUrl: '/tickets/board', relativeUrl: `/tickets/board/${localStorage.getItem('activeTicketBoard') || ''}`, icon: 'board' },
        { label: lbl.list, relativeUrl: '/tickets/list', icon: 'list' },
        { label: lbl.archive, relativeUrl: '/tickets/archive', icon: 'archive' }
    ],
    tasks: [
        { label: lbl.list, relativeUrl: '/tasks/list', icon: 'list' },
        { label: lbl.archive, relativeUrl: '/tasks/archive', icon: 'archive' }
    ],
    contacts: [
        { label: lbl.list, relativeUrl: '/contacts/list', icon: 'list' }
    ],
    properties: [
        { label: lbl.list, relativeUrl: '/properties/list', icon: 'list' }
    ],
    messenger: [
        { label: 'Messenger', relativeUrl: '/messenger', icon: 'message' }
    ],
    calls: [
        { label: lbl.list, relativeUrl: '/calls/list', icon: 'call-log' }
    ],
    templates: [
        { label: lbl.list, relativeUrl: '/templates/list', icon: 'list' }
    ]
}

export const tabNavLinks = {
    tickets: {
        staticTabs: staticTabs.tickets,
        dynamicPath: '/tickets/ticket/',
        new: { relativeUrl: '/tickets/new', btnLabel: lbl.addTicket }
    },
    tasks: {
        staticTabs: staticTabs.tasks,
        dynamicPath: '/tasks/task/',
        new: { relativeUrl: '/tasks/new', btnLabel: lbl.addTask }
    },
    contacts: {
        staticTabs: staticTabs.contacts,
        dynamicPath: '/contacts/contact/',
        new: { relativeUrl: '/contacts/new', btnLabel: lbl.addContact }
    },
    properties: {
        staticTabs: staticTabs.properties,
        dynamicPath: '/properties/property/',
        property: {
            dynamicPath: '/properties/property/',
            new: { relativeUrl: '/properties/property/new', btnLabel: lbl.addProperty }
        },
        address: {
            dynamicPath: '/properties/property/',
            new: { relativeUrl: '/properties/property/new', btnLabel: lbl.addProperty }
        },
        unit: {
            dynamicPath: '/properties/unit/',
            new: { relativeUrl: '/properties/unit/new', btnLabel: lbl.addUnit },
        },
        room: {
            dynamicPath: '/properties/unit/',
            new: { relativeUrl: '/properties/unit/new', btnLabel: lbl.addUnit },
        },
        building: {
            dynamicPath: '/properties/building/',
            new: { relativeUrl: '/properties/building/new', btnLabel: lbl.addBuilding },
        }
    },
    messenger: {
        dynamicPath: '/messenger/thread/',
        new: '/messenger/new',
        pinned: '/messenger/pinned',
        archive: '/messenger/archive',
        mentions: '/messenger/mentions'
    },
    calls: {
        staticTabs: staticTabs.calls,
        dynamicPath: '/calls/call/'
    },
    templates: {
        staticTabs: staticTabs.templates,
        dynamicPath: '/templates/template/',
        new: { relativeUrl: '/templates/new', btnLabel: lbl.addTemplate }
    }
}

export const labelColors = [
    { color: '#D3EEF3' },
    { color: '#26B5CE' },
    { color: '#0F7488' },
    { color: '#F2C94C' },
    { color: '#5A450D' },
    { color: '#FBE1CA' },
    { color: '#F2994A' },
    { color: '#EB5757' },
    { color: '#5E6AD2' },
    { color: '#BF710F' },
    { color: '#730C8F' },
    { color: '#0CDAC6' },
    { color: '#00776A' },
    { color: '#70092F' },
    { color: '#C0C0C0' },
    { color: '#818181' },
    { color: '#414141' }
]

export const priorityOptions = {
    '1_LOW': { color: 'grey', label: 'Low Priority', shortLabel: 'Low', icon: 'priority-1', order: 3, id: '1' },
    '2_MEDIUM': { color: 'yellow', label: 'Medium Priority', shortLabel: 'Medium', icon: 'priority-2', order: 2, id: '2' },
    '3_HIGH': { color: 'red', label: 'High Priority', shortLabel: 'High', icon: 'priority-3', order: 1, id: '3' },
}

export const statusOptions = {
    '1_TODO': { color: 'grey', label: 'Todo', icon: 'check-status-1', order: 3, id: '1' },
    '2_IN_PROGRESS': { color: 'yellow', label: 'In progress', icon: 'check-status-2', order: 2, id: '2' },
    '3_DONE': { color: 'green', label: 'Done', icon: 'check-status-3', order: 1, id: '3' }
}

export const onlineStatusOptions = {
    'ONLINE': { color: 'green', label: 'Online', icon: 'dot', order: 1, id: '1' },
    'OFFLINE': { color: 'grey', label: 'Offline', icon: 'dot', order: 2, id: '2' },
}

export const callDirectionOptions = {
    'INBOUND': { label: 'Inbound', icon: 'call-inbound', order: 1, id: '1' },
    'OUTBOUND': { label: 'Outbound', icon: 'call-outbound', order: 2, id: '2' },
}

export const callStatusOptions = {
    'no-answer': { color: 'red', label: 'No answer', icon: 'call-missed', order: 1, id: '1' },
    'failed': { color: 'red', label: 'Failed', icon: 'close', order: 2, id: '2' },
    'canceled': { color: 'red', label: 'Canceled', icon: 'close', order: 3, id: '3' },
    'initiated': { color: 'yellow', label: 'Connecting', icon: 'call-outbound', order: 4, id: '4' },
    'ringing': { color: 'yellow', label: 'Ringing', icon: 'bell', order: 5, id: '5' },
    'busy': { color: 'yellow', label: 'Busy', icon: 'call-in-talk', order: 6, id: '6' },
    'in-progress': { color: 'yellow', label: 'In progress', icon: 'call-in-talk', order: 7, id: '7' },
    'transferred': { color: 'yellow', label: 'Transferred', icon: 'call-forward', order: 8, id: '8' },
    'completed': { color: 'green', label: 'Completed', icon: 'call-hangup', order: 9, id: '9' },
}

export const callTimingOptions = {
    'OFFICE_HOURS': { label: 'During office hours', icon: 'clock', order: 1, id: false },
    'OUTSIDE_OFFICE_HOURS': { label: 'Outside office hours', icon: 'clock-alert', order: 2, id: true },
}

export const userTypeOptions = {
    '1_guests': { label: 'Guests', id: '1_guests', value: '1_guests', order: 1 },
    '2_landlords': { label: 'Landlords', id: '2_landlords', value: '2_landlords', order: 2 },
    '3_partners': { label: 'Partners', id: '3_partners', value: '3_partners', order: 3 },
    '4_employees': { label: 'Employees', id: '4_employees', value: '4_employees', order: 4 },
    'unknown': { label: 'Unknown', id: '', value: '', order: 5 },
}

export const tenantStatusOptions = {
    '1_LEAD': { label: 'Lead', id: '1_LEAD', order: 1 },
    '2_PRE_SIGNING': { label: 'Pre-Signing', id: '2_PRE_SIGNING', order: 2 }, 
    '3_PRE_CHECK_IN': { label: 'Pre-Check In', id: '3_PRE_CHECK_IN', order: 3 },
    '4_CHECKED_IN': { label: 'Checked In', id: '4_CHECKED_IN', order: 4 },
    '5_PRE_CHECK_OUT': { label: 'Pre-Check Out', id: '5_PRE_CHECK_OUT', order: 5 }, 
    '6_CHECKED_OUT_AWAITING_DEPOSIT': { label: 'Checked out - awaiting deposit', id: '6_CHECKED_OUT_AWAITING_DEPOSIT', order: 6 },
    '7_CHECKED_OUT_DEPOSIT_RECEIVED': { label: 'Checked out - deposit received', id: '7_CHECKED_OUT_DEPOSIT_RECEIVED', order: 7 }
}

export const DragTypes = {
    TICKET: 'ticket',
    TASK: 'task'
}

// Map to models on the backend.
export const contentTypes = {
    TICKET: 'ticket',
    TASK: 'task',
    USER: 'user',
    NOTE: 'note',
    ATTACHMENT: 'attachment',
}

export const notificationVerbs = {
    ASSIGNED: 'assigned',
    UPLOADED: 'uploaded'
}

export const changeLogKinds = {
    CREATE: 'create',
    DELETE: 'delete',
    CHANGE: 'change',
    UPLOAD: 'upload',
}

// Must be kept same as in API users/models
export const employmentStatus = {
    EMPLOYED: 'EMPLOYED',
    UNEMPLOYED: 'UNEMPLOYED',
    STUDENT: 'STUDENT',

    // Treated as None on backend; i.e. database value is NULL
    // In requests do not send this status to backend,
    // rather remove the employmentStatus field from the request body. 
    UNKNOWN: 'UNKNOWN'
}

export const employmentStatuses = {
    STUDENT: { label: 'Student', id: 'STUDENT', order: 1 },
    EMPLOYED: { label: 'Employed', id: 'EMPLOYED', order: 2 }, 
    UNEMPLOYED: { label: 'Unemployed', id: 'UNEMPLOYED', order: 3 },
    UNKNOWN: { label: 'Unknown', id: null, order: 4 }
}

export const salutationOptions = [
    { value: '', label: '' },
    { value: 'MR', label: 'Mr' },
    { value: 'MRS', label: 'Mrs' },
    { value: 'MS', label: 'Ms' },
    { value: 'MISS', label: 'Miss' },
    { value: 'MX', label: 'Mx' },
    { value: 'DR', label: 'Dr' },
]

export const genderOptions = [
    { value: '', label: '' },
    { value: 'MALE', label: 'Male' },
    { value: 'FEMALE', label: 'Female' },
    { value: 'NON_BINARY', label: 'Non binary'}
]

export const relationshipStatusOptions = [
    { value: '', label: '' },
    { value: 'S', label: 'Single' },
    { value: 'C', label: 'Unmarried couple' },
    { value: 'M', label: 'Married / Partnered' },
    { value: 'D', label: 'Divorced / Dispartnered' },
    { value: 'W', label: 'Widowed' },
]

export const languageOptions = [
    { value: '', label: '' },
    { value: 'NL', label: 'Dutch' },
    { value: 'EN', label: 'English' }
]

export const employmentStatusOptions = Object.keys(employmentStatuses).map(key => ({ ...employmentStatuses[key], value: key }))

export const CONTENT_MODEL_MAP = {
    ticket: { path: '/tickets/ticket/', subpath: '/tickets#', lblPl: 'tickets' },
    task: { path: '/tasks/task/', subpath: '/tasks#', lblPl: 'tasks' },
    thread: { path: '/messenger/thread/', subpath: '/threads#', lblPl: 'threads' },
    building: { path: '/properties/building/', lblPl: 'buildings' },
    address: { path: '/properties/property/', lblPl: 'addresses' },
    room: { path: '/properties/unit/', lblPl: 'units' },
    user: { path: '/contacts/contact/', lblPl: 'users', icon: 'contact'},
    profile: { path: '/profiles/profile/', lblPl: 'users', icon: 'contact'},
    contract: { path: '/contracts/contract/', subpath: '/contracts#', lblPl: 'contracts', disableArchived: true },
    lead: { path: '/leads/lead/', subpath: '/leads#', lblPl: 'leads', disableArchived: true },
    servicepayment: { path: '/services/service_payment/', subpath: '/services#service_payment_', lbl: 'service payment', lblPl: 'service payments', icon:'credit-card', disableArchived: true },
    service: { path: '/services/service/', subpath: 'services#', lblPl: 'services', icon:'settings', disableArchived: true },
    note: { subpath: '/notes#', lblPl: 'notes' },
    attachment: { subpath: '/attachments#', lblPl: 'attachments' },
    template: { path: 'templates/template/', lblPl: 'templates' }
}

export const CONTENT_OBJECTS_MAP = {
    tickets: CONTENT_MODEL_MAP.ticket,
    tasks: CONTENT_MODEL_MAP.task,
    threads: CONTENT_MODEL_MAP.thread,
    buildings: CONTENT_MODEL_MAP.building,
    addresses: CONTENT_MODEL_MAP.address,
    rooms: CONTENT_MODEL_MAP.room,
    users: CONTENT_MODEL_MAP.user,
    profiles: CONTENT_MODEL_MAP.profile,
    contracts: CONTENT_MODEL_MAP.contract,
    leads: CONTENT_MODEL_MAP.lead,
    ownerContracts: CONTENT_MODEL_MAP.contract,
    tenantContracts: CONTENT_MODEL_MAP.contract,
    servicePayments: CONTENT_MODEL_MAP.servicepayment,
    services: CONTENT_MODEL_MAP.service,
    notes: CONTENT_MODEL_MAP.note,
    attachments: CONTENT_MODEL_MAP.attachment,
}
