import Icon from '../../components/Icon'

const ThreadLabel = ({ icon, isDirect, name, contact }) => (
    <div className='d-inline-flex'>
        {icon ?
            <Icon type={icon} variant='2xs' wrapperClassAdditions={
                icon === 'dot' ? `icon-wrapper--${contact?.onlineStatus?.toLowerCase()}` : ''
            }/>
        : !isDirect ?
            <span className='t-heading t-blue-grey t-bold mr-1 ml-05 t-sm'>#</span>
        :
            <Icon type='contact-multi' variant='2xs' />
        }
        {name}
    </div>
)

export default ThreadLabel