import { useState } from 'react'
import { useMutation } from '@apollo/client'
import ContactSelect from '../../components/ContactSelect'
import { GET_ALL_USERS } from '../../queries'
import { ADD_MEMBERS, ADD_ALL_PARENT_THREAD_MEMBERS } from '../mutations'
import { lbl } from '../../constants'


const AddContact = ({ threadId, members, presetOptions, onCompleted }) => {
    const [selected, setSelection] = useState([])
    const [addMembers] = useMutation(ADD_MEMBERS, { onCompleted })
    const [addAllParentThreadMembers] = useMutation(ADD_ALL_PARENT_THREAD_MEMBERS, { onCompleted })

    return (
        <>
            <ContactSelect
                inputLabel={lbl.addMembers}
                optionsQuery={GET_ALL_USERS}
                selected={selected}
                multi
                placeholder='Type the contact name'
                onSelect={setSelection}
                excludeUsers={members}
                presetOptions={presetOptions}
                directContactsOnly
            />
            <button
                className='btn btn--primary d-block ml-auto'
                disabled={!selected?.length}
                onClick={() => {
                    if (selected[0].key === 'all-parent-thread-members') {
                        addAllParentThreadMembers({
                            variables: { threadId }
                        })
                    } else {
                        addMembers({
                            variables: {
                                threadId,
                                userIds: selected.map(({ id }) => id)
                            }
                        })
                    }
                }}
            >
                {lbl.save}
            </button>
        </>
    )
}

export default AddContact
