import { useQuery, useReactiveVar } from '@apollo/client'
import { GET_ARCHIVED_TASKS } from '../queries'
import TaskList from './TaskList'
import { taskArchiveCtrlsVar, initailTasksQueryVars } from '../../cache'

const TasksArchive = () => {
    const vars = useReactiveVar(taskArchiveCtrlsVar)
    const filters = vars.filters.map(f => `${f.field}___${f.id}`).sort().join(',')
    const { data, loading, error, fetchMore } = useQuery(GET_ARCHIVED_TASKS, {
        variables: { ...vars, filters },
        notifyOnNetworkStatusChange: true
    })

    if (error) return <div className='pos-center-y w-100 t-center'>{error.message}</div>
    
    return (
        <TaskList
            tasks={data?.archivedTasks}
            controlsDisabled={loading}
            fetchMore={fetchMore}
            showLoader={loading && !data?.archivedTasks}
            query={GET_ARCHIVED_TASKS}
            queryFieldName='archivedTasks'
            listCtrlVar={taskArchiveCtrlsVar}
        />
    )
}

export default TasksArchive
