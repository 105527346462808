import { useState, useRef, useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import {
    GET_THREADS_BY_SEARCH_TERM,
    GET_TICKETS_BY_SEARCH_TERM,
    GET_TASKS_BY_SEARCH_TERM,
    GET_USERS_BY_SEARCH_TERM,
    GET_PROPERTIES_BY_SEARCH_TERM,
    GET_TEMPLATES_BY_SEARCH_TERM
} from '../queries'
import { activeAppVar, searchTermVar } from '../../cache'
import { CONTENT_MODEL_MAP } from '../../constants'
import { Popover, Icon } from '../../components'
import SearchResults from './SearchResults'

const SEARCH_PROPS_BY_APP = {
    'tickets': {
        query: GET_TICKETS_BY_SEARCH_TERM,
        contentTypeModel: 'ticket',
        titleField: 'name',
        bodyField: 'description',
        authorField: 'owner',
        dateField: 'createdDate',
        ...CONTENT_MODEL_MAP['ticket']
    },
    'tasks': {
        query: GET_TASKS_BY_SEARCH_TERM,
        contentTypeModel: 'task',
        titleField: 'name',
        bodyField: 'description',
        authorField: 'assignee',
        dateField: 'createdDate',
        ...CONTENT_MODEL_MAP['task']
    },
    'messenger': {
        query: GET_THREADS_BY_SEARCH_TERM,
        contentTypeModel: 'thread',
        titleField: 'name',
        authorField: '',
        dateField: '',
        ...CONTENT_MODEL_MAP['thread']
    },
    'contacts': {
        query: GET_USERS_BY_SEARCH_TERM,
        contentTypeModel: 'user',
        titleField: 'fullName',
        bodyField: 'email',
        ...CONTENT_MODEL_MAP['user']
    },
    'properties': {
        query: GET_PROPERTIES_BY_SEARCH_TERM,
        contentTypeModel: 'address',
        titleField: 'fullAddress',
        ...CONTENT_MODEL_MAP['address']
    },
    'templates': {
        query: GET_TEMPLATES_BY_SEARCH_TERM,
        contentTypeModel: 'template',
        titleField: 'name',
        bodyField: 'body',
        authorField: 'createdBy',
        dateField: 'createdAt',
        ...CONTENT_MODEL_MAP['template']
    },
}

const SiteSearch = ({ onSubmit, onChange, onClear, initialSearchTerm='' }) => {
    const [searchTerm, setSearchTerm] = useState(initialSearchTerm)
    const [isFocused, setFocus] = useState(false)
    const [isPopupOpen, setPopupOpen] = useState(false)
    const [extendSearch, setExtendSearch] = useState(false)
    const initialSectionResults = {}
    Object.keys(SEARCH_PROPS_BY_APP).forEach(app => {
        initialSectionResults[app] = 0
    })
    const [sectionResults, setSectionResults] = useState(initialSectionResults)
    const inputEl = useRef()
    const formEl = useRef()
    const containerRef = useRef()
    const searchTermVariable = useReactiveVar(searchTermVar)
    let activeApp = useReactiveVar(activeAppVar)
    if (!SEARCH_PROPS_BY_APP[activeApp]) activeApp = 'tickets'
    const [sectionInView, setSectionInView] = useState(activeApp)
    const formPosition = formEl.current && formEl.current.getBoundingClientRect()
    
    const handleOnChange = (e) => {
        setSearchTerm(e.target.value)
        onChange && onChange(e.target.value.toLowerCase())
    }

    const handleOnSubmit = () => {
        setExtendSearch(false)
        setSectionResults(initialSectionResults)
        searchTermVar(searchTerm)
        onSubmit && onSubmit(searchTerm)
        setPopupOpen(true)
    }

    const handleClear = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setSearchTerm('')
        onClear && onClear()
    }

    const closePopup = () => {
        setPopupOpen(false)
        if (searchTermVariable && !searchTerm) {
            searchTermVar('')
        }
    }

    const onClose = (e) => {
        e.preventDefault()
        e.stopPropagation()
        closePopup()
    }

    const scrollToSection = (section) => {
        const block = section === activeApp ? 'start' : sectionResults[section] < 4 ? 'center' : 'start'
        const sectionEl = document.querySelector(`.search-results__section--${section}`)
        if (sectionEl) sectionEl.scrollIntoView({ block})
    }

    const navAnchors = [activeApp, ...Object.keys(SEARCH_PROPS_BY_APP).filter(app => app !== activeApp)].filter(app => sectionResults[app] > 0)

    useEffect(() => {
        if (!searchTermVariable && isPopupOpen) {
            setPopupOpen(false)
        }
    }, [searchTermVariable])

    return (
        <>
            <form
                className={`search-form ${isPopupOpen ? 'search-form--in-popup' : ''}`}
                method='post'
                ref={formEl}
                onSubmit={e => {
                    e.preventDefault()
                    handleOnSubmit()
                }}
                onClick={e => {
                    inputEl.current.focus()
                    setFocus(true)
                }}
            >
                <fieldset>
                    <button
                        type='submit'
                        className='btn btn--naked btn--inline-icon p-1'
                        disabled={!searchTerm || searchTerm.length < 3 || (isPopupOpen && searchTerm === searchTermVariable)}
                    >
                        <Icon type='search' variant='xs' />
                    </button>
                    <input
                        ref={inputEl}
                        type='text'
                        name='search'
                        value={searchTerm}
                        onChange={handleOnChange}
                        onBlur={() => setFocus(false)}
                        placeholder='Search ...'
                        autoComplete='off'
                    />
                    {searchTerm ?
                        <button
                            className='btn close btn--inline-icon btn--naked'
                            title='Clear search'
                            onClick={handleClear}
                        >
                            {isPopupOpen ?
                                <span className='t-grey'>clear</span> :
                                <Icon type='close' variant='3xs' />
                            }
                        </button>
                    : isPopupOpen ?
                        <button
                            className='btn close btn--inline-icon btn--naked'
                            title='Close popup'
                            onClick={onClose}
                        >
                            <span className='t-grey'>close</span>
                        </button>
                    : null}
                </fieldset>
            </form>
            <Popover
                isOpen={isPopupOpen}
                className='popover--search'
                position={{ top: formPosition?.top || '10px', left: formPosition?.left || '150px' }}
                onClose={onClose}
            >
                <>
                    {navAnchors.length > 1 &&
                        <div className='pop-up__search-navbar'>
                            {navAnchors.map(app => {
                                const appProps = SEARCH_PROPS_BY_APP[app]
                                return (
                                    <button
                                        key={app}
                                        className={`btn${sectionInView === app ? ' active' : ''}`}
                                        onClick={() => {
                                            setSectionInView(app)
                                            scrollToSection(app)
                                        }}
                                    >
                                        {appProps.lblPl || app}
                                    </button>
                                )
                            })}
                        </div>
                    }
                    <div className='pop-up__content' ref={containerRef}>
                        <SearchResults
                            {...SEARCH_PROPS_BY_APP[activeApp]}
                            onIntersect={() => setSectionInView(activeApp)}
                            onCompleted={() => setExtendSearch(true)}
                            onCountChange={(resultsCount) => {
                                setSectionResults(prev => ({
                                    ...prev,
                                    [activeApp]: resultsCount
                                }))
                            }}
                            closePopup={closePopup}
                            limit={40}
                            app={activeApp}
                            containerEl={containerRef}
                        />
                        {extendSearch && Object.keys(SEARCH_PROPS_BY_APP).map(app => {
                            if (app !== activeApp) {
                                return (
                                    <SearchResults
                                        key={app}
                                        {...SEARCH_PROPS_BY_APP[app]}
                                        app={app}
                                        closePopup={closePopup}
                                        onCountChange={(resultsCount) => {
                                            setSectionResults(prev => ({
                                                ...prev,
                                                [app]: resultsCount
                                            }))
                                        }}
                                        onIntersect={() => {
                                            setSectionInView(app)
                                        }}
                                        containerEl={containerRef}
                                    />
                                )
                            }
                        })}
                        <br/>
                    </div>
                </>
            </Popover>
        </>
    )
}

export default SiteSearch
