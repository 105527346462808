import * as Sentry from '@sentry/react'
import { useLocation } from 'react-router-dom'

const fallback = ({ error, componentStack, resetError }) => {
    return (
        <div className='pos-absolute pos-center t-center'>
            You have encountered an error:
            <div className='error t-red mt-2'>{error.toString()}</div>
        </div>
    )
}

const ErrorBoundary = ({ children }) => {
    const { pathname } = useLocation()

    return (
        <Sentry.ErrorBoundary
            fallback={fallback}
            showDialog
            beforeCapture={(scope) => {
                scope.setTag('pathname', pathname);
            }}
        >
            {children}
        </Sentry.ErrorBoundary>
    )
}

export default ErrorBoundary
