import { useQuery, useReactiveVar } from '@apollo/client'
import { ticketListCtrlsVar } from '../../cache'
import { GET_TICKETS } from '../queries'
import TicketList from './TicketList'


const TicketLister = () => {
    const vars = useReactiveVar(ticketListCtrlsVar)
    const filters = vars.filters.map(f => `${f.field}___${f.id}`).sort().join(',')
    let variables = { ...vars, filters }
    const { data, loading, error, fetchMore } = useQuery(GET_TICKETS, {
        variables,
        notifyOnNetworkStatusChange: true
    })

    if (error) return <div className='pos-center-y w-100 t-center'>{error.message}</div>

    return (
        <TicketList
            tickets={data?.tickets}
            controlsDisabled={loading}
            fetchMore={fetchMore}
            showLoader={loading && !data?.tickets}
            query={GET_TICKETS}
            queryFieldName='tickets'
        />
    )
}

export default TicketLister
