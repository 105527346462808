import { useReactiveVar } from '@apollo/client'
import Icon from './Icon'
import { twilioDeviceVar } from '../cache'


const VoiceCall = ({ contact, classAdditions, iconVariant='sm' }) => {
    const { device } = useReactiveVar(twilioDeviceVar)
    const { phoneNumber } = contact.profile || {}

    const toggleCall = () => {
        if (!device.isBusy) {
            device.emit('call', { number: phoneNumber, contact })
        } else {
            device.disconnectAll()
        }
    }

    return (
        <>
            <button
                className={`btn btn--naked${device?.isBusy ? ' active' : ''} ${classAdditions}`}
                onClick={toggleCall}
                title={phoneNumber ? `call ${phoneNumber}` : 'no phone number available'}
                style={{ cursor: phoneNumber ? 'pointer' : 'not-allowed' }}
                disabled={!device || !phoneNumber}
            >
                <Icon type={device?.isBusy ? 'close' : 'call'} variant={iconVariant} />
            </button>
        </>
    )
}

export default VoiceCall