import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery, useReactiveVar } from '@apollo/client'
import { newDraftsVar, openedTabsVar } from '../../cache'
import { CREATE_UNIT } from '../mutations'
import { GET_PROPERTIES } from '../queries'
import { tabNavLinks, lbl } from '../../constants'
import UnitInfoForm from './UnitInfoForm'


const NewUnit = () => {
    const { data: propertyData, loading, error, client } = useQuery(GET_PROPERTIES)
    const history = useHistory()
    const unitDraft = useReactiveVar(newDraftsVar).unit
    const openedTabs = useReactiveVar(openedTabsVar)
    const { relativeUrl } = tabNavLinks.properties.unit.new
    const onCompleted = (data) => {
        openedTabs.delete(relativeUrl)
        openedTabsVar(new Map([...openedTabs]))
        newDraftsVar({ ...newDraftsVar(), unit: {} })
        if (data) {
            data.createUnit?.unit && client.cache.modify({
                id: client.cache.identify({ __typename: 'PropertyType', id: data.createUnit.unit.address.id }),
                fields: {
                    rooms(prev=[], { toReference }) {
                        return [...prev, toReference(data.createUnit.unit)]
                    }
                }
            })
            history.push(`${tabNavLinks.properties.unit.dynamicPath}${data.createUnit?.unit?.id}`)
        } else {
            history.goBack()
        }
    }
    let propertyOptions = propertyData?.properties?.length ? propertyData.properties.map(({ id, streetWithHouseNumber }) => (
        { value: id, label: streetWithHouseNumber }
    )).sort((a, b) => (a.label > b.label) ? 1 : -1) : [{ value: '', label: loading ? 'Loading...' : error ? error.message : 'No properties available' }]

    useEffect(() => {
        openedTabsVar(new Map([...openedTabsVar(), [relativeUrl, {
            name: lbl.newUnit,
            id: 'new',
            relativeUrl,
            icon: 'unit'
        }]]))
    }, [relativeUrl])

    return (
        <div className='split-panel--main'>
            <UnitInfoForm
                draft={unitDraft}
                submitMutation={CREATE_UNIT}
                onCompleted={onCompleted}
                setDraft={unit => { newDraftsVar({ ...newDraftsVar(), unit }) }}
                extraFields={[{ name: 'addressId', required: true, lblKey: 'address', As: 'select', options: propertyOptions, wide: true }]}
            />
        </div>
    )
}

export default NewUnit
