import React from 'react'
import { render } from 'react-dom'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history';
import { ApolloClient, split, createHttpLink, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { getMainDefinition } from '@apollo/client/utilities'
import { WebSocketLink } from '@apollo/client/link/ws'
import App from './App'
import { getCsrfCookie, handleGraphQlError } from './utils'
import cache from './cache'
import './styles/index.scss'
import { setContext } from '@apollo/client/link/context'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

const history = createBrowserHistory()

const authHttpLink = setContext((_, { headers }) => {
  // Middleware that inserts authorization headers for HttpLink
  return {
    headers: {
        ...headers,
        'X-CSRFToken': getCsrfCookie()
    }
  }
})

// These are operations that will go to graphqlauth endpoint
// which are public endpoints.
const authLinkOperations = [
    'REQUEST_PASSWORD_RESET_MUTATION',
    'RESET_PASSWORD_MUTATION',
    'CHANGE_PASSWORD_MUTATION',
    'LOGIN',
    'JOIN_MUTATION',
    'GET_USER_BY_RESET_TOKEN',
    'GET_USER_BY_INVITATION_TOKEN'
]

// prevent parsing non json responses
const customFetch = (uri, options) => {
    return fetch(uri, options).then(response => {
        if (response.headers.get('content-type') !== 'application/json') {
            return Promise.reject(response.statusText)
        }
        return response
    })
}

/**
 * https://github.com/apollographql/apollo-link/issues/197#issuecomment-616139145
 * Function to create our apollo client. After login we want to add subscriptions
 * with websocket, so we'll need to recreate the entire client depending on the
 * user being logged in or logged out.
 */
export const generateApolloClient = ( loggedIn ) => {
    const httpLink = createHttpLink({
        uri: window._env_.REACT_APP_HTTP_ENDPOINT,
        fetch: customFetch,
        credentials: 'include' // use httpOnly cookies
    })

    const authLink = createHttpLink({
        uri: window._env_.REACT_APP_HTTP_AUTH_ENDPOINT,
        fetch: customFetch,
        credentials: 'include'
    })

    const errorLink = onError((options) => {
        handleGraphQlError(options)
    })

    let link = authHttpLink.concat(httpLink)

    link = split(
        // separate authLink from normal Link as authLink uses public endpoints
        ({operationName})  =>  {
            return authLinkOperations.includes(operationName)
        },
        authLink,
        link
    )


    // Only apply our subscription client when the user is logged in
    let subscriptionClient
    if (loggedIn) {
        console.debug('User is logged in. Generating ApolloClient with websocket.')
        subscriptionClient = new SubscriptionClient(
            window._env_.REACT_APP_WS_ENDPOINT,
            {
                lazy: true,
                reconnect: true,
            }
        )
        const wsLink = new WebSocketLink(subscriptionClient)
        link = split(
            ({ query }) => {
                const { kind, operation } = getMainDefinition(query)
                return kind === 'OperationDefinition' && operation === 'subscription'
            },
            wsLink,
            link
        )
    } else {
        console.debug('Preparing link without websockets.')
    }

    link = from([errorLink, link])

    const apolloClient = new ApolloClient({
        link,
        cache,
        connectToDevTools: window._env_.REACT_APP_ENV === 'dev'
    })

    return [apolloClient, subscriptionClient]
}


// TODO: use in future in cases when session expires but
// websocket is still using the same session.
// Currently unused.
export function restartWebsockets (wsClient) {
  // https://github.com/apollographql/apollo-client/issues/3967
  // Copy current operations
  const operations = Object.assign({}, wsClient.operations)

  // Close connection
  wsClient.close(true)

  // Open a new one
  wsClient.connect()

  // Push all current operations to the new connection
  Object.keys(operations).forEach(id => {
    wsClient.sendMessage(
      id,
      'start',
      operations[id].options
    )
  })
}

if (window._env_.REACT_APP_ENV !== 'dev') {
    Sentry.init({
        dsn: 'https://b0198c8fea024e32be5f2647be5f0578@o1093035.ingest.sentry.io/6112121',
        environment: window._env_.REACT_APP_ENV,
        // requires custom history: Router component combined with createBrowserHistory (or equivalent)
        integrations: [new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
        })],
        ignoreErrors: ['ResizeObserver loop limit exceeded']
    })
}

render(
    <Router history={history}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </Router>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// import reportWebVitals from './reportWebVitals'
// reportWebVitals()
