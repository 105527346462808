import { useState } from 'react'
import { lbl } from '../../constants'
import { useQuery, useReactiveVar } from '@apollo/client'
import { GET_UNIT_CONTRACTS } from '../queries'
import { CREATE_CONTRACT } from '../mutations'
import { newDraftsVar } from '../../cache'
import Accordion from '../../components/Accordion'
import ContractInfo from './ContractInfo'
import ContractInfoForm from './ContractInfoForm'


const UnitContracts = ({ id, unit, archived }) => {
    const { loading, data, client } = useQuery(GET_UNIT_CONTRACTS, { variables: { id, includeArchived: archived } })
    const [newContractOpen, setNewContractOpen] = useState(false)
    let { unitContracts } = useReactiveVar(newDraftsVar)
    let draft = unitContracts[id] || {}
    let draftExtra = { unitId: id }
    const { address, monthlyServiceCost, monthlyBasicRent, deposit } = unit
    const divider = address.serviceCostDivisionModel === 'SQM' && address.sizeSqm && unit.sizeSqm ? address.sizeSqm / unit.sizeSqm : address.rooms.length
    if (monthlyBasicRent) draftExtra['monthlyRent'] = monthlyBasicRent
    if (deposit) draftExtra['deposit'] = deposit
    if (monthlyServiceCost) {
        draftExtra['monthlyServiceCost'] = monthlyServiceCost
    } else if (address.monthlyServiceCost) {
        draftExtra['monthlyServiceCost'] = Math.round(address.monthlyServiceCost / divider)
    }
    if (address.otherCosts) {
        draftExtra['otherCosts'] = Math.round(address.otherCosts / divider)
    }

    return (
        <>
            {loading && <div className='loader pos-center-y'/>}
            {!archived &&
                <>
                    {newContractOpen ?
                        <Accordion
                            isOpen
                            Tag='div'
                            className='bg-lightblue p-1 br-05 mb-1'
                            headerClass='accordion__header--bordered t-md t-heading mr-3'
                            label={lbl.newContract}
                            onOpenCb={() => { setNewContractOpen(false) }}
                        >
                            <div className='ml-4 mr-4 mb-4'>
                                <ContractInfoForm
                                    draft={{ ...draft, ...draftExtra }}
                                    extraFields={[{ name: 'unitId', lblKey: 'unit', As: 'select', options: [
                                        { value: id, label: unit.fullUnitName}
                                    ], required: true, disabled: true }]}
                                    submitMutation={CREATE_CONTRACT}
                                    onCompleted={() => {
                                        client.cache.evict({
                                            id: `PropertyType:${unit.address.id}`,
                                            fieldName: 'propertyContracts',
                                            variables: { includeUnitContracts: true }
                                        })
                                        client.cache.gc()
                                    }}
                                    close={() => { setNewContractOpen(false) }}
                                    setDraft={(contractDraft) => {
                                        const drafts = { ...newDraftsVar()}
                                        drafts.unitContracts[id] = contractDraft
                                        newDraftsVar(drafts)
                                    }}
                                />
                            </div>
                        </Accordion>
                    :
                        <button
                            className='btn btn--naked btn--new-item w-100 mb-2'
                            onClick={() => { setNewContractOpen(true) }}
                        >
                            <span className="plus">+</span>
                            {lbl.addNewUnitContract}
                        </button>
                    }
                </>
            }
            {data?.unit?.unitContracts?.map((contract, i) => (
                <ContractInfo
                    key={'contract' + contract.id}
                    contract={contract}
                    units={[unit]}
                    unitId={id}
                    property={unit.address}
                    propertyId={unit.address.id}
                />
            ))}
        </>
    )
}

export default UnitContracts
