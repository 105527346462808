import {
    taskListCtrlsVar,
    taskArchiveCtrlsVar,
    ticketListCtrlsVar,
    ticketArchiveCtrlsVar,
    contactListCtrlsVar,
} from '../cache'
import { GET_CONTACTS } from '../contacts/queries'
import { userProperties } from '../contacts/settings'
import { GET_ARCHIVED_TASKS, GET_TASKS } from '../tasks/queries'
import { taskProperties } from '../tasks/settings'
import { GET_ARCHIVED_TICKETS, GET_TICKETS } from '../tickets/queries'
import { ticketProperties } from '../tickets/settings'
import {
    resetControlledList,
    getFilteredList,
    getSortedList,
    objectIncludesString,
    getGroupedAndSortedList
} from './objectUtils'


export const resetCachedLists = ({
    cache,
    fieldName,
    query,
    getFieldProps
}) => {
    const allItems = cache.readQuery({ query })
    allItems && cache.modify({
        fields: {
            [fieldName](currentList=[], { storeFieldName, toReference }) {
                if (storeFieldName === `${fieldName}({})`) {
                    return currentList
                }

                // update all controlled lists in cache
                var regExp = /\(([^)]+)\)/
                var matches = regExp.exec(storeFieldName)
                
                if (matches && matches.length) {
                    const vars = JSON.parse(matches[1])
                    const updatedList = resetControlledList({
                        items: [...allItems[fieldName]],
                        getFieldProps,
                        ...vars
                    })
                    return updatedList.map(item => toReference(item))
                }

                return currentList
            }
        }
    })
}

export const addNewRefToCachedLists = ({
    cache,
    fieldName,
    newItem,
    getFieldProps
}) => {
    cache.modify({
        fields: {
            [fieldName](currentList=[], { readField, storeFieldName, toReference }) {
                if (currentList.some(ref => readField('id', ref) === newItem.id)) {
                    return currentList
                }

                if (storeFieldName !== `${fieldName}({})`) {
                    var regExp = /\(([^)]+)\)/
                    var matches = regExp.exec(storeFieldName)
                    
                    if (matches && matches.length) {
                        const vars = JSON.parse(matches[1])
                        const updatedList = resetControlledList({
                            items: [newItem, ...currentList],
                            getFieldProps,
                            ...vars
                        })
                        return updatedList.map(task => toReference(task))
                    }
                } else {
                    return [newItem, ...currentList]
                }
            }
        }
    })
}

export const updateListByActionType = ({
    actionType,
    listCtrlVar,
    items,
    getFieldProps,
    cache,
    query,
    queryFieldName,
    queryVariables,
    fetchCb,
    setUpdatedList
}) => {
    const initialVariables = queryVariables || {}
    const variables = { ...initialVariables, ...listCtrlVar(), searchTerm: '' }
    const { filters } = variables
    const _filters = ['tickets', 'tasks'].includes(queryFieldName) ? filters.map(f => `${f.field}___${f.id}`).sort().join(',') : filters
    let updatedList

    switch(actionType) {
        case 'search':
            return // resolve search on render
        case 'group':
        case 'sort':
            if (items) {
                updatedList = getSortedList({
                    items,
                    getFieldProps,
                    ...variables
                })
            }
            break
        case 'filter-add':
            const lastFilter = filters[filters.length - 1]
            const { field, type, groupPath } = getFieldProps(lastFilter.field)
            
            updatedList = getFilteredList(items, {
                ...lastFilter,
                type,
                path: groupPath || field
            })
            break
        default:
            const allItems = cache.readQuery({ query, variables: initialVariables })

            if (allItems) {
                if (!filters || !filters.length) {
                    updatedList = getSortedList({
                        items: [...allItems[queryFieldName]],
                        getFieldProps,
                        ...variables
                    })
                } else {
                    // check if matching query already in cache
                    const cached = cache.readQuery({ query, variables: {
                        ...variables, filters: _filters
                    } })

                    if (cached && actionType !== 'reset') {
                        updatedList = [...cached[queryFieldName]]
                    } else {
                        updatedList = resetControlledList({
                            items: [...allItems[queryFieldName]],
                            getFieldProps,
                            ...variables
                        })
                    }
                }
            }
    }

    // write query with new ctrl variables to cache returning updated list
    if (updatedList) {
        cache.writeQuery({
            query,
            variables: { ...variables, filters: _filters },
            data: setUpdatedList ? setUpdatedList(updatedList) : { [queryFieldName]: updatedList }
        })
    }

    if (fetchCb && !updatedList) fetchCb({ variables })
}

export const cacheNewListerQuery = ({
    actionType,
    ctrls,
    listCtrlVar,
    listerCtrlOptions,
    items: _items,
    shouldFetchMore,
    getFieldProps,
    cache,
    query,
    queryFieldName,
    querySubfieldName,
    queryVariables={}
}) => {
    if (!ctrls) return false

    let items = _items
    let prevQuery = undefined
    const prevCtrls = listCtrlVar()

    if (!items) {
        const prevFilters = prevCtrls?.filters?.map(f => `${f.field}___${f.id}`).sort().join(',') || ''
        const prevVariables = { ...queryVariables, ...prevCtrls, filters: prevFilters }
        prevQuery = cache.readQuery({ query, variables: prevVariables })
        if (prevQuery) {
            items = querySubfieldName ? prevQuery[queryFieldName][querySubfieldName] : prevQuery[queryFieldName]
        }
    }

    if (!items) return false

    const { filters, searchTerm } = ctrls
    const _filters = filters?.map(f => `${f.field}___${f.id}`).sort().join(',') || ''
    const variables = { ...queryVariables, ...ctrls, filters: _filters }
    let updatedList


    switch(actionType) {
        case 'search':
            const cachedResult = cache.readQuery({ query, variables: { ...variables, searchTerm } })
            if (cachedResult) return false
            const prevSearchTerm = searchTerm.indexOf(prevCtrls.searchTerm) === 0 ? prevCtrls.searchTerm : ''
            const prevItems = cache.readQuery({ query, variables: { ...variables, searchTerm: prevSearchTerm } })
            if (prevItems) {
                const prevList = querySubfieldName ? prevItems[queryFieldName][querySubfieldName] : prevItems[queryFieldName]
                updatedList = prevList.filter((item) => objectIncludesString({
                    obj: item,
                    str: searchTerm,
                    searchFields: listerCtrlOptions.search
                }))
            }
            break
        case 'filter-add':
            const lastFilter = filters[filters.length - 1]
            const { field, type, objectPath } = getFieldProps(lastFilter.field)
            if (items) {
                updatedList = getFilteredList(items, {
                    ...lastFilter,
                    type,
                    path: objectPath || field
                })
            }
            break
        case 'group':
        case 'sort':
            if (!shouldFetchMore && items) {
                updatedList = getGroupedAndSortedList({
                    items,
                    ...variables
                })
            }
            break
        default:
            return false
    }

    // write query with new ctrl variables to cache returning updated list
    if (updatedList?.length) {
        let data
        if (querySubfieldName) {
            data = {
                [queryFieldName]: {
                    [querySubfieldName]: updatedList,
                    hasMore: shouldFetchMore,
                    cursor: updatedList[updatedList.length-1].id
                }
            }
        } else {
            data = { [queryFieldName]: updatedList }
        }
        cache.writeQuery({
            query,
            variables,
            data
        })

        return true
    }

    return false
}

export const updateLister = ({
    cache,
    queryFieldName,
    ...props
}) => {
    let query, queryVariables, getFieldProps, listCtrlVar

    switch (queryFieldName) {
        case 'tickets':
            query = GET_TICKETS
            getFieldProps = (field) => ticketProperties[field]
            listCtrlVar = ticketListCtrlsVar
            break
        case 'tasks':
            query = GET_TASKS
            getFieldProps = (field) => taskProperties[field]
            listCtrlVar = taskListCtrlsVar
            break
        case 'archivedTickets':
            query = GET_ARCHIVED_TICKETS
            getFieldProps = (field) => ticketProperties[field]
            listCtrlVar = ticketArchiveCtrlsVar
            break
        case 'archivedTasks':
            query = GET_ARCHIVED_TASKS
            getFieldProps = (field) => taskProperties[field]
            listCtrlVar = taskArchiveCtrlsVar
            break
        case 'users':
            query = GET_CONTACTS
            getFieldProps = (field) => userProperties[field]
            listCtrlVar = contactListCtrlsVar
            break
        default:
            break
    }

    query && updateListByActionType({
        cache,
        queryFieldName,
        queryVariables,
        query,
        getFieldProps,
        listCtrlVar,
        actionType: 'reset',
        ...props
    })
}
