import { useState, useEffect } from 'react'
import Icon from './Icon'

const Accordion = ({ Tag='li', isOpen, label, onOpen, onOpenCb, children, className, headerClass, id, toggleOnHeaderClick=false }) => {
    const [open, setOpen] = useState(isOpen)
    const toggle = () => {
        onOpenCb && onOpenCb(!open)
        onOpen ? onOpen() : setOpen(prev => !prev)
    }
    
    useEffect(() => {
        setOpen(isOpen)
    }, [isOpen])

    return (
        <Tag className={`accordion ${open ? 'open' : 'closed'} ${className ? className : ''}`} id={id}>
            <header
                className={`accordion__header ${headerClass ? headerClass : ''}`}
                onClick={toggleOnHeaderClick ? toggle : undefined}
                style={toggleOnHeaderClick ? { cursor: 'pointer' } : undefined}
            >
                <button
                    className='btn btn--naked accordion__toggle'
                    onClick={toggleOnHeaderClick ? () => {} : toggle}
                >
                    <Icon type='caret' variant='xs' classAdditions={open ? '' : 'caret--right'}/>
                </button>
                <div className='accordion__header__label'>
                    {label}
                </div>
            </header>
            {open &&
                <ul className='accordion__body'>
                    {children}
                </ul>
            }
        </Tag>
    )
}

export default Accordion