import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useReactiveVar } from '@apollo/client'
import { useHistory, useLocation } from 'react-router-dom'
import { setUser } from '@sentry/react'
import { CURRENT_USER_QUERY } from '../queries'
import Subscriber from './Subscriber'
import Phone from '../calls/components/Phone'
import {
    currentUserVar,
    newDraftsVar,
    taskListCtrlsVar,
    isLoggedInVar
} from '../cache'
import { lbl } from '../constants'


const User = ({ children }) => {
    let history = useHistory()
    let location = useLocation()
    const [isCompleted, setCompleted] = useState(false)
    const isLoggedIn = useReactiveVar(isLoggedInVar)

    const { data, loading, done } = useQuery(CURRENT_USER_QUERY, {
        onCompleted: (data) => {
            if (data.me) {
                isLoggedInVar(true)
            } else if (isLoggedIn) {
                isLoggedInVar(false)
            }
        },
        onError: (e) => {
            isLoggedInVar(false)
            history.replace({
                pathname: '/login',
                state: {from: location?.pathname} 
            })
        }
    })

    useEffect(() => {
        if (isLoggedIn && data?.me) {
            const preselectedFilter = {
                id: data.me.id,
                picture: data.me.picture,
                field: 'assignee',
                label: lbl.assignedToMe,
                icon: 'assignee'
            }
            taskListCtrlsVar({
                ...taskListCtrlsVar(),
                filters: [preselectedFilter]
            })
            currentUserVar({ ...data.me })
            
            const drafts = newDraftsVar()
            newDraftsVar({
                ...drafts,
                tickets: { ...drafts.tickets, owner: data.me, ownerId: data.me.id },
            })

            setCompleted(true)
            setUser({ id: data.me.id, username: data.me.fullName })
        } else if (done && !isLoggedIn) {
            history.replace({
                pathname: '/login',
                state: {from: location?.pathname} 
            })
        }
    }, [isLoggedIn, done])

    if (loading || !data || (data.me && !isCompleted)) return <div className='loader pos-center-y' />

    return (
        <>
            {isLoggedIn && !!data?.me && isCompleted &&
                <>
                    <Subscriber userId={data.me.id} />
                    <Phone username={data.me.username} />
                </>
            }
            {children(data || {})}
        </>
    )
}

User.propTypes = {
    children: PropTypes.func.isRequired
}

export default User
