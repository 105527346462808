import { gql } from '@apollo/client'
import { BASE_TICKET_FRAGMENT, PIPELINE_STAGE_FRAGMENT } from './fragments'
import { USER_FRAGMENT } from '../fragments'


export const CREATE_TICKET = gql`
    mutation CREATE_TICKET($description: String!, $name: String!, $assigneeId: ID, $ownerId: ID, $pipelineStageId: ID, $priority: String, $categoryId: ID, $templateForGroupId: ID, $reviewerGroupId: ID, $labelIds: [ID]) {
        createTicket(description: $description, name: $name, assigneeId: $assigneeId, ownerId: $ownerId, pipelineStageId: $pipelineStageId, priority: $priority, categoryId: $categoryId, templateForGroupId: $templateForGroupId, reviewerGroupId: $reviewerGroupId, labelIds: $labelIds) {
            ticket {
                ...BaseTicketFields
                pipelineStage {
                    ...PipelineStageFields
                    pipeline {
                        id
                        name
                    }
                }
                pipeline {
                    id
                    name
                }
            }
        }
    }
    ${BASE_TICKET_FRAGMENT}
    ${PIPELINE_STAGE_FRAGMENT}
`

export const UPDATE_TICKET = gql`
    mutation UpdateTicket($id: ID!, $description: String, $name: String, $assigneeId: ID, $ownerId: ID, $pipelineStageId: ID, $priority: String, $categoryId: ID, $templateForGroupId: ID, $reviewerGroupId: ID) {
        updateTicket(id: $id, description: $description, name: $name, assigneeId: $assigneeId, ownerId: $ownerId, pipelineStageId: $pipelineStageId, priority: $priority, categoryId: $categoryId, templateForGroupId: $templateForGroupId, reviewerGroupId: $reviewerGroupId) {
            ticket {
                ...BaseTicketFields
                description
                pipelineStage {
                    ...PipelineStageFields
                    pipeline {
                        id
                        name
                    }
                }
                pipeline {
                    id
                    name
                }
                templateForGroup {
                    id
                    name
                }
                reviewerGroup {
                    id
                    name
                }
                threads {
                    id
                    unreadCount
                    isUnread
                    archived
                    members {
                        ...UserFields
                    }
                    threadmembershipSet {
                        id
                        user {
                            id
                            fullName
                        }
                        joinedAt
                    }
                    parent {
                        id
                    }
                }
            }
        }
    }
    ${USER_FRAGMENT}
    ${BASE_TICKET_FRAGMENT}
    ${PIPELINE_STAGE_FRAGMENT}
`

export const UPDATE_TICKETS = gql`
  mutation UpdateTickets($ids: [ID!], $description: String, $name: String, $assigneeId: ID, $pipelineStageId: ID, $pipelineId: ID, $priority: String, $categoryId: ID) {
    updateTickets(ids: $ids, description: $description, name: $name, assigneeId: $assigneeId, pipelineStageId: $pipelineStageId, pipelineId: $pipelineId, priority: $priority, categoryId: $categoryId) {
        tickets {
            ...BaseTicketFields
            description
            pipelineStage {
                id
                name
                order
                tickets {
                    id
                }
                pipeline {
                    id
                    name
                }
            }
            pipeline {
                id
                name
            }
        }
    }
  }
  ${BASE_TICKET_FRAGMENT}
`

export const UNASSIGN_TICKET = gql`
    mutation UnassignTicket($id: ID!) {
        unassignTicket(id: $id) {
            ticket {
                id
                assignee {
                    id
                    fullName
                }
            }
        }
    }
`

export const REMOVE_CATEGORY = gql`
    mutation REMOVE_CATEGORY($id: ID!) {
        removeCategory(id: $id) {
            ticket {
                id
                category {
                    id
                    name
                }
            }
        }
    }
`


export const REMOVE_FROM_PIPELINE = gql`
    mutation REMOVE_FROM_PIPELINE($id: ID!) {
        removeFromPipeline(id: $id) {
            ticket {
                id
                pipelineStage {
                    id
                    name
                }
                pipeline {
                    id
                    name
                }
            }
        }
    }
`

export const REMOVE_TEMPLATE_FOR_GROUP = gql`
    mutation removeTemplateForGroup($id: ID!) {
        removeTemplateForGroup(id: $id) {
            ticket {
                id
                templateForGroup {
                    id
                }
            }
        }
    }
`

export const CREATE_FROM_TEMPLATE_FOR_GROUP = gql`
    mutation createFromTemplateForGroup($templateTicketId: ID!) {
        createFromTemplateForGroup(templateTicketId: $templateTicketId) {
            tickets {
                ...BaseTicketFields
                description
                pipelineStage {
                    id
                    name
                }
                pipeline {
                    id
                    name
                }
            }
        }
    }
    ${BASE_TICKET_FRAGMENT}
`

export const REMOVE_REVIEWER_GROUP = gql`
    mutation removeReviewerGroup($id: ID!) {
        removeReviewerGroup(id: $id) {
            ticket {
                id
                reviewerGroup {
                    id
                }
            }
        }
    }
`

export const ADD_PIN = gql`
  mutation ADD_PIN($objectId: ID!, $objectType: String!) {
    pinObject(objectId: $objectId, objectType: $objectType) {
        pin {
            contentObject
        }
    }
  }
`

export const REMOVE_PIN = gql`
  mutation REMOVE_PIN($objectId: ID!, $objectType: String!) {
    removePin(objectId: $objectId, objectType: $objectType) {
        id
    }
  }
`

export const REMOVE_LABEL = gql`
    mutation REMOVE_LABEL($id: ID!, $ticketId: ID!) {
        removeLabel(id: $id, ticketId: $ticketId) {
            ticket {
                id
                labels {
                    id
                    name
                    color
                }
            }
        }
    }
`

export const ADD_LABEL = gql`
    mutation ADD_LABEL($id: ID!, $ticketId: ID!) {
        addLabel(id: $id, ticketId: $ticketId) {
            ticket {
                id
                labels {
                    id
                    name
                    color
                }
            }
        }
    }
`

export const LABEL_TICKETS = gql`
    mutation LABEL_TICKETS($labelId: ID!, $ticketIds: [ID!]) {
        labelTickets(labelId: $labelId, ticketIds: $ticketIds) {
            tickets {
                id
                labels {
                    id
                    name
                    color
                }
            }
        }
    }
`

export const CREATE_LABEL = gql`
    mutation CREATE_LABEL($name: String!, $color: String!, $ticketId: ID) {
        createLabel(name: $name, color: $color, ticketId: $ticketId) {
            label {
                id
                name
                color
                tickets {
                    id
                    labels {
                        id
                        name
                        color
                    }
                }
            }
        }
    }
`

export const DUPLICATE_TICKET = gql`
    mutation DUPLICATE_TICKET($id: ID!) {
        duplicateTicket(id: $id) {
            ticket {
                id
            }
        }
    }
`

export const MERGE_TICKETS = gql`
    mutation MERGE_TICKETS($id: ID!, $ids: [ID!]) {
        mergeTickets(mainTicketId: $id, ids: $ids) {
            ticket {
                id
                name
                description
                labels {
                    id
                    name
                    color
                }
            }
            mergedTicketIds,
            mergedTicketStatusIds
        }
    }
`

export const ARCHIVE_TICKET = gql`
    mutation ARCHIVE_TICKET($id: ID!) {
        archiveTicket(id: $id) {
            ticket {
                id
                archived
                pipelineStage {
                    id
                    name
                }
                pipeline {
                    id
                    name
                }
                priority
                tasks {
                    id
                    archived
                }
                threads {
                    id
                    archived
                }
            }
        }
    }
`

export const ARCHIVE_TICKETS = gql`
    mutation ARCHIVE_TICKETS($ids: [ID!]) {
        archiveTickets(ids: $ids) {
            archivedTicketIds
            archivedTicketStatusIds
            tickets {
                id
                archived
                tasks {
                    id
                    archived
                }
                threads {
                    id
                    archived
                }
            }
        }
    }
`

export const DELETE_TICKET = gql`
    mutation DELETE_TICKET($id: ID!) {
        deleteTicket(id: $id) {
            deletedId
            pipelineStageId
        }
    }
`

export const ACTIVATE_TICKET = gql`
    mutation ACTIVATE_TICKET($id: ID!) {
        activateTicket(id: $id) {
            ticket {
                ...BaseTicketFields
                description
                archived
                pipelineStage {
                    id
                    name
                }
                pipeline {
                    id
                    name
                }
                tasks {
                    id
                    archived
                }
                threads {
                    id
                    archived
                }
            }
        }
    }
    ${BASE_TICKET_FRAGMENT}
`

export const ACTIVATE_TICKETS = gql`
    mutation ACTIVATE_TICKETS($ids: [ID!]) {
        activateTickets(ids: $ids) {
            tickets {
                ...BaseTicketFields
                description
                archived
                pipelineStage {
                    id
                    name
                }
                pipeline {
                    id
                    name
                }
                tasks {
                    id
                    archived
                }
                threads {
                    id
                    archived
                }
            }
        }
    }
    ${BASE_TICKET_FRAGMENT}
`

export const CREATE_PIPELINE = gql`
    mutation CREATE_PIPELINE($name: String!) {
        createPipeline(name: $name) {
            pipeline {
                id
                name
                pipelineStages {
                    id
                    name
                    isFirst
                    isLast
                }
            }
        }
    }
`

export const UPDATE_PIPELINE = gql`
    mutation UPDATE_PIPELINE($id: ID!, $name: String!) {
        updatePipeline(id: $id, name: $name) {
            pipeline {
                id
                name
                pipelineStages {
                    id
                    name
                    isFirst
                    isLast
                    order
                }
            }
        }
    }
`

export const CREATE_PIPELINE_STAGE = gql`
    mutation CREATE_PIPELINE_STAGE($name: String!, $prevId: ID!) {
        createPipelineStage(name: $name, prevId: $prevId) {
            pipelineStage {
                id
                name
                isFirst
                isLast
                order
                ticketCount
                pipeline {
                    id
                }
            }
        }
    }
`

export const DELETE_PIPELINE_STAGE = gql`
    mutation DELETE_PIPELINE_STAGE($id: ID!) {
        deletePipelineStage(id: $id) {
            deletedId
        }
    }
`

export const UPDATE_PIPELINE_STAGE = gql`
    mutation UPDATE_PIPELINE_STAGE($id: ID!, $name: String!) {
        updatePipelineStage(id: $id, name: $name) {
            pipelineStage {
                id
                name
            }
        }
    }
`
