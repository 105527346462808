import DateString from "../../components/DateString"
import { lbl } from "../../constants"

const TenancyPeriodLabel = ({ tenancyAgreement }) => (
    <div className='d-inline-flex'>
        {tenancyAgreement.rentResponsabilityStartDate ?
            <DateString dateStr={tenancyAgreement.rentResponsabilityStartDate} />
        : 'N/A'}
        <span className='ml-1 mr-1'>-</span>
        {tenancyAgreement.forIndefinitePeriod ?
            <div>{lbl.indefinite}</div>
        : tenancyAgreement.rentResponsabilityEndDate ?
            <DateString dateStr={tenancyAgreement.rentResponsabilityEndDate} />
        : 'N/A'}
    </div>
)

export default TenancyPeriodLabel
