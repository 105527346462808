import { useQuery, useReactiveVar } from '@apollo/client'
import { callListCtrlsVar } from '../../cache'
import { GET_CALLS } from '../queries'
import CallsList from './CallsList'

const CallLister = () => {
    const vars = useReactiveVar(callListCtrlsVar)
    const filters = vars.filters.map(f => `${f.field}___${f.id}`).sort().join(',')
    let variables = { ...vars, filters }
    const { data, loading, error, fetchMore } = useQuery(GET_CALLS, {
        variables,
        notifyOnNetworkStatusChange: true
    })

    if (error) return <div className='pos-center-y w-100 t-center'>{error.message}</div>

    return (
        <CallsList
            calls={data?.calls}
            controlsDisabled={loading}
            fetchMore={fetchMore}
            showLoader={loading && !data?.calls}
            query={GET_CALLS}
            queryFieldName='calls'
        />
    )
}

export default CallLister
