const DateString = ({ dateStr, fadeInPast=true }) => {
    const date = new Date(dateStr)
    const today = new Date()
    date.setHours(0, 0, 0, 0)
    today.setHours(0, 0, 0, 0)

    const isInPast = today > date

    return (
        <div className={fadeInPast && isInPast ? 't-grey' : ''}>
            {date.toLocaleDateString().replace(/\//g, '.')}
        </div>
    )
}

export default DateString
