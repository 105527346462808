import { useQuery, useReactiveVar } from '@apollo/client'
import { userTaskListCtrlsVar } from '../../cache'
import { GET_USER_TASKS } from '../../tasks/queries'
import TaskList from '../../tasks/components/TaskList'

export const columns = ['status', 'name', 'dueDate', 'priority', 'ticket', 'createdBy', 'archived']

const UserTasks = ({ userId }) => {
    const vars = useReactiveVar(userTaskListCtrlsVar)
    const filters = vars.filters.map(f => `${f.field}___${f.id}`).sort().join(',')
    const { data, error, loading, fetchMore } = useQuery(GET_USER_TASKS, {
        variables: { id: userId, ...vars, filters },
        notifyOnNetworkStatusChange: true
    })
    
    if (error) return <div className='pos-center-y w-100 t-center'>{error.message}</div>

    return (
        <TaskList
            tasks={data?.userTasks}
            controlsDisabled={loading}
            showLoader={loading && !data?.userTasks}
            query={GET_USER_TASKS}
            queryFieldName='userTasks'
            queryVariables={{ id: userId }}
            columnKeys={columns}
            listCtrlVar={userTaskListCtrlsVar}
            fetchMore={fetchMore}
        />
    ) 
}

export default UserTasks
