import { Link } from 'react-router-dom'
import { HighlightedText, Icon } from '../../components'
import { formatDateString } from '../../utils'

const stripHtml = (html) => {
    const tmp = document.createElement("div")
    tmp.innerHTML = html
    return tmp.textContent || tmp.innerText || ""
}

const trimSearchResults = (text, term, padding = 130) => {
    let regex = new RegExp('.{0,' + padding + '}' + term + '.{0,' + padding + '}', 'gi')
    let matches = [...text.matchAll(regex)]
    if (matches.length === 0) return null

    let results = matches.map((match, i) => {
        let start = match.index
        let end = start + match[0].length
        if (start > 0) {
            let lastSpace = text.lastIndexOf(' ', start)
            start = lastSpace != -1 ? lastSpace + 1 : start
        }
        if (end < text.length) {
            let nextSpace = text.indexOf(' ', end)
            end = nextSpace != -1 ? nextSpace : end
        }
        let result = text.substring(start, end)
        if (i == 0 && start > 0) result = '...' + result
        if (end < text.length) result += '...'

        return result
    })
    return results
}

const SearchResultItem = ({
    elementId,
    title,
    body,
    author,
    date,
    icon,
    path,
    searchTerm,
    closePopup
}) => {
    const text = body ? stripHtml(body) : ''
    const bodyText = text && text.toLowerCase().includes(searchTerm.toLowerCase()) ? text : ''
    const formattedBody = bodyText && bodyText.length > 201 ? trimSearchResults(bodyText, searchTerm) : [bodyText]

    return (
        <li className='search-results__item'>
            <Link
                to={path}
                className='t-none d-flex'
                onClick={(e) => {
                    closePopup()
                    if (elementId && window.location.href === window.location.origin + path) {
                        e.preventDefault()
                        const scrollTarget = document.getElementById(elementId)
                        scrollTarget?.scrollIntoView('smooth')
                    }
                }}
            >
                {icon &&
                    <Icon
                        type={icon}
                        variant='2xs'
                        wrapperClassAdditions='search-results__item-icon mr-1 mb-auto'
                    />
                }
                <div className='search-results__item-content'>
                    {title &&
                        <h4 className='search-results__item-title t-normal'>
                            <HighlightedText text={stripHtml(title)} mark={searchTerm} />
                        </h4>
                    }
                    {bodyText && formattedBody &&
                        <p className='search-results__item-body t-darkgrey'>
                            {formattedBody.filter(Boolean).map(text => (
                                <HighlightedText key={text} text={text} mark={searchTerm} />
                            ))}
                        </p>
                    }
                    {(author || date) &&
                        <p className='search-results__item-meta t-sm t-grey mt-05'>
                            {[author?.fullName, formatDateString(date)].filter(Boolean).join(', ')}
                        </p>
                    }
                </div>
                <Icon
                    type='arrow-right'
                    variant='xs'
                    style={{ transform: 'rotate(-45deg)' }}
                    wrapperClassAdditions='ml-auto'
                />
            </Link>
        </li>
    )
}
 
export default SearchResultItem
