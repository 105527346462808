import { useQuery, useReactiveVar } from '@apollo/client'
import { GET_OWNER_CONTRACTS } from '../queries'
import { newDraftsVar } from '../../cache'
import { OwnerContracts } from '../../properties/components/PropertyOwnerContracts'

export const LandlordContracts = ({ user, owner }) => {
    const { loading, data } = useQuery(GET_OWNER_CONTRACTS, { variables: { ownerId: owner.id } })
    const draftKey = `ownerContracts`
    let drafts = useReactiveVar(newDraftsVar)
    let draft = drafts[draftKey][owner.id] || {}
    
    const setDraft = (contractDraft) => {
        const _drafts = { ...drafts }
        _drafts[draftKey][owner.id] = contractDraft
        newDraftsVar(drafts)
    }

    const contracts = data?.landlord?.contracts || []

    return (
        <OwnerContracts
            contracts={contracts}
            loading={loading}
            setDraft={setDraft}
            draft={draft}
            user={user}
            owner={owner}
        />
    )
}

export default LandlordContracts
