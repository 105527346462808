import { useState } from 'react'
import { useMutation, gql } from '@apollo/client'
import { lbl } from '../constants'

const REQUEST_PASSWORD_RESET_MUTATION = gql`
  mutation REQUEST_PASSWORD_RESET_MUTATION($email: String!) {
    requestPasswordReset(email: $email) {
      ok
    }
  }
`;


const RequestPasswordReset = () => {
    let [showError, setShowError] = useState(false)
    let [email, setEmailInput] = useState('')
    const [reset, { loading, error, called }] = useMutation(REQUEST_PASSWORD_RESET_MUTATION, {
        onError: () => { setShowError(true) },
        onCompleted: () => setEmailInput(lbl.forgotPassSuccess)
    })
    const success = !error && !loading && called
    const handleOnChange = (e) => {
        setEmailInput(e.target.value)
        if (showError) setShowError(false)
    }
 
    return (
        <div className='contentbox--lg'>
            <div className='logo--center'>
                <img src={process.env.PUBLIC_URL + '/logo_long.svg'} alt='logo'/>
            </div>
            <h2 className='t-heading t-lg--responsive mb-6'>
                Hi <strong>User</strong>,<br/>{lbl.forgotPassIntro}
            </h2>
            <div className='card'>
                <p className='t-heading t-sm--responsive mb-3'>{lbl.forgotPassDesc}</p>
                <form
                    method='post'
                    data-test='form'
                    onSubmit={async e => {
                        e.preventDefault();
                        await reset({ variables: { email } });
                    }}
                    >
                    <fieldset disabled={loading || success} aria-busy={loading}>
                        <fieldset className={success ? 'success' : error && showError ? 'error' : ''}>
                            <label htmlFor='email'>
                                {success ? lbl.success : lbl.yourEmail}
                            </label>
                            <input
                                type={success ? 'text' : 'email'}
                                name='email'
                                required
                                placeholder=''
                                value={email}
                                onChange={handleOnChange}
                            />
                            {error && showError && <p className='input-message input-message--error'>{error.message}</p>}
                        </fieldset>
                        <button
                            className={`btn d-block mx-auto btn--${success ? 'success' : 'primary'}`}
                            type='submit'
                        >
                            {loading ? 
                                <span className='btn__loader'>&#8635;</span>
                            : success ?
                                <span className='btn__check'>&#10004;</span>
                            : lbl.send}
                        </button>
                    </fieldset>
                </form>
            </div>
        </div>
    )
}

export default RequestPasswordReset;
export { REQUEST_PASSWORD_RESET_MUTATION };
