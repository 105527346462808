export const getCookie = (name) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}
export const setCookie = (name, value, days) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

export const CSRF_TOKEN = 'csrftoken'
export const CSRF_TOKEN_FROM_BODY = 'csrf_token_from_body'

export const getCsrfCookie = () => {
    // We extract cookie from the Cookie storage value csrftoken
    // This cookie is set with every django request.
    // However, when developing locally, and webclient is running on localhost:3000
    // and API is at stage.propertyos.io, we can't access cookies set for stage.propertyos.io,
    // to put them in the required headr.
    // Because of this, the workaround is:
    // * on login, backend API also sends csrfToken property in the body
    // * we store this token into csrf_token_from_body in Login.js
    // * at the moment of request, we look into the cookies and use `csrftoken` 
    // if it is avilable, if not we use csrf_token_from_body as fallback to make
    // development on localhost with stage API communication possible
    return getCookie(CSRF_TOKEN) || getCookie(CSRF_TOKEN_FROM_BODY)
}

export function errorContains(error, inputTypes) {
    const result = {}
    inputTypes.forEach(type => {result[type] =  error && error.message.toLowerCase().includes(type)})
    return result
}



// https://stackoverflow.com/a/33366171
const eraseAllCookies = () => {
    var cookies = document.cookie.split("; ");
    for (var c = 0; c < cookies.length; c++) {
        var d = window.location.hostname.split(".");
        while (d.length > 0) {
            var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
            var p = window.location.pathname.split('/');
            document.cookie = cookieBase + '/';
            while (p.length > 0) {
                document.cookie = cookieBase + p.join('/');
                p.pop();
            };
            d.shift();
        }
    }
};

export const clearLocalStorageAndCookies = () => {
    localStorage.clear();
    eraseAllCookies();
}
