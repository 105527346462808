import { useState, useRef } from 'react'
import Icon from '../components/Icon'

const SearchInput = ({ inputProps, children, onSubmit, onChange, onClear, showIconBtn, initialSearchTerm='' }) => {
    const [searchTerm, setSearchTerm] = useState(initialSearchTerm)
    const [isFocused, setFocus] = useState(false)
    const inputEl = useRef()
    
    const handleOnChange = (e) => {
        setSearchTerm(e.target.value)
        onChange && onChange(e.target.value.toLowerCase())
    }

    return (
        <form
            className='search-form'
            method='post'
            onSubmit={e => {
                e.preventDefault()
                onSubmit && onSubmit(searchTerm)
            }}
            onClick={e => {
                inputEl.current.focus()
                setFocus(true)
            }}>
            {children && children({ isFocused })}
            <fieldset>
                {showIconBtn &&
                    <button type='submit' className='btn btn--naked btn--inline-icon p-1'>
                        <Icon type='search' variant='xs' />
                    </button>
                }
                <input
                    ref={inputEl}
                    type='text'
                    name='search'
                    value={searchTerm}
                    onChange={handleOnChange}
                    onBlur={() => setFocus(false)}
                    {...inputProps}
                />
                {onClear && searchTerm &&
                    <button
                        className='btn close btn--inline-icon btn--naked'
                        title='Clear search'
                        onClick={e => {
                            setSearchTerm('')
                            e.stopPropagation()
                            onClear()
                        }}
                    >
                        <Icon type='close' variant='3xs' />
                    </button>
                }
            </fieldset>
        </form>
    )
}

export default SearchInput
