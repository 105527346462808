import { gql } from '@apollo/client'
import {
    PROPERTY_DETAIL_FRAGMENT,
    PROPERTY_LIST_FRAGMENT,
    UNIT_FRAGMENT,
    BUILDING_FRAGMENT,
    PROPERTY_FRAGMENT,
    BUILDING_DETAIL_FRAGMENT,
    CENTRAL_HEATING_FRAGMENT,
    OWNER_CONTRACT_FRAGMENT,
    TENANT_CONTRACT_FRAGMENT,
    TENANCY_AGREEMENT_FRAGMENT,
    SERVICE_FRAGMENT,
    SERVICE_PAYMENT_FRAGMENT,
} from './fragments'


export const GET_PROPERTY = gql`
    query GET_PROPERTY($id: ID!) {
        property(id: $id) {
            ...AllPropertyFields
        }
    }
    ${PROPERTY_DETAIL_FRAGMENT}
`

export const GET_PROPERTIES = gql`
    query GET_PROPERTIES(
        $offset: Int, $limit: Int, $searchTerm: String, $groupBy: String, $groupDir: Int, $sortBy: String, $sortDir: Int, $filters: String
    ) {
        properties(offset: $offset, limit: $limit, searchTerm: $searchTerm, groupBy: $groupBy, groupDir: $groupDir, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            ...PropertyListerFields
            hasMore
        }
    }
    ${PROPERTY_LIST_FRAGMENT}
`

export const GET_PROPERTY_UNITS = gql`
    query GET_PROPERTY_UNITS($propertyId: ID!) {
        property(id: $propertyId) {
            id
            rooms {
                id
                fullUnitName
                longName
            }
        }
    }
`

export const GET_PROPERTY_TENANTS = gql`
    query GET_PROPERTY_TENANTS($propertyId: ID!) {
        property(id: $propertyId) {
            id
            tenants {
                id
                user {
                    id
                    fullName
                }
            }
        }
    }
`

export const GET_PROPERTY_TENANTS_PER_ROOM = gql`
    query GET_PROPERTY_TENANTS_PER_ROOM($propertyId: ID!) {
        property(id: $propertyId) {
            id
            rooms {
                id
                fullUnitName
                tenancies {
                    id
                    user {
                        id
                        fullName
                        picture
                    }
                }
            }
            roomlessTenants {
                id
                user {
                    id
                    fullName
                    picture
                }
            }
        }
    }
`

export const GET_UNIT = gql`
    query GET_UNIT($id: ID!) {
        unit(id: $id) {
            ...roomFields
            operationalModel {
                id
                name
            }
            sizeSqm
            monthlyBasicRent
            monthlyServiceCost
            deposit
            minOccupancy
            maxOccupancy
            keycode
            address {
                ...propertyFields
                fullAddress
                streetName
                houseNumber
                houseNumberAddition
                sizeSqm
                serviceCostDivisionModel
                monthlyOwnerServiceCost
                monthlyServiceCost
                otherCosts
                hasFireSafetySystem
                tenantRepresentative {
                    id
                    user {
                        id
                        fullName
                        picture
                        email
                        profile {
                            id
                            phoneNumber
                        }
                    }
                }
                owner {
                    id
                    user {
                        id
                        fullName
                        picture
                        email
                        profile {
                            id
                            phoneNumber
                        }
                    }
                }
                propertyType {
                    id
                    name
                }
                operationalModel {
                    id
                    name
                }
                building {
                    ...allBuildingFields
                }
                rooms {
                    id
                }
                centralHeating {
                    ...centralHeatingFields
                }
            }
        }
    }
    ${UNIT_FRAGMENT}
    ${PROPERTY_FRAGMENT}
    ${BUILDING_DETAIL_FRAGMENT}
    ${CENTRAL_HEATING_FRAGMENT}
`

export const GET_UNITS = gql`
    query GET_UNITS {
        units {
            ...roomFields
        }
    }
    ${UNIT_FRAGMENT}
`

export const GET_BUILDING = gql`
    query GET_BUILDING($id: ID!) {
        building(id: $id) {
            ...allBuildingFields
            properties {
                ...propertyFields
                streetName
                houseNumber
                houseNumberAddition
                sizeSqm
                serviceCostDivisionModel
                monthlyOwnerServiceCost
                monthlyServiceCost
                otherCosts
                hasFireSafetySystem
                tenantRepresentative {
                    id
                    user {
                        id
                        fullName
                        picture
                        email
                        profile {
                            id
                            phoneNumber
                        }
                    }
                }
                owner {
                    id
                    user {
                        id
                        fullName
                        picture
                        email
                        profile {
                            id
                            phoneNumber
                        }
                    }
                }
                propertyType {
                    id
                    name
                }
                operationalModel {
                    id
                    name
                }
                centralHeating {
                    ...centralHeatingFields
                }
            }
        }
    }
    ${BUILDING_DETAIL_FRAGMENT}
    ${PROPERTY_FRAGMENT}
    ${CENTRAL_HEATING_FRAGMENT}
`

export const GET_BUILDINGS = gql`
    query GET_BUILDINGS {
        buildings {
            ...buildingFields
        }
    }
    ${BUILDING_FRAGMENT}
`

export const GET_CITIES = gql`
    query GET_CITIES {
        cities {
            id
            name
        }
    }
`

export const GET_PROPERTY_TYPES = gql`
    query GET_PROPERTY_TYPES {
        propertyTypes {
            id
            name
        }
    }
`

export const GET_CONTRACT_TYPES = gql`
    query GET_CONTRACT_TYPES {
        contractTypes {
            id
            name
        }
    }
`

export const GET_OPERATIONAL_MODELS = gql`
    query GET_OPERATIONAL_MODELS {
        operationalModels {
            id
            name
        }
    }
`

export const GET_HOMEOWNER_ASSOCIATIONS = gql`
    query GET_HOMEOWNER_ASSOCIATIONS {
        homeownerAssociations {
            id
            name
        }
    }
`

export const GET_PARTNERS = gql`
    query GET_PARTNERS {
        partners {
            id
            company {
                id
                name
            }
            user {
                id
                fullName
            }
        }
    }
`

export const GET_LANDLORDS = gql`
    query GET_LANDLORDS {
        landlords {
            id
            user {
                id
                fullName
            }
        }
    }
`

export const GET_TENANTS = gql`
    query GET_TENANTS {
        tenants {
            id
            user {
                id
                fullName
            }
        }
    }
`

export const GET_CONTRACTS = gql`
    query GET_CONTRACTS {
        allTenantContracts {
            ...TenantContractFields
            property {
                id
                longName
                serviceCostDivisionModel
                sizeSqm
                monthlyServiceCost
                otherCosts
                rooms {
                    id
                    fullUnitName
                }
            }
            unit {
                id
                longName
                fullUnitName
                monthlyBasicRent
                monthlyServiceCost
                deposit
                sizeSqm
                address {
                    id
                    longName
                    serviceCostDivisionModel
                    sizeSqm
                    monthlyServiceCost
                    otherCosts
                }
            }
            contractTenancyAgreements {
                id
                active
                archived
            }
        }
    }
    ${TENANT_CONTRACT_FRAGMENT}
`

export const GET_TENANT_CONTRACT_BY_ID = gql`
    query GET_TENANT_CONTRACT_BY_ID($id: ID!) {
        contract(id: $id) {
            ...TenantContractFields
            property {
                id
                longName
                serviceCostDivisionModel
                sizeSqm
                monthlyServiceCost
                otherCosts
                rooms {
                    id
                    fullUnitName
                }
            }
            unit {
                id
                longName
                fullUnitName
                monthlyBasicRent
                monthlyServiceCost
                deposit
                sizeSqm
                address {
                    id
                    longName
                    serviceCostDivisionModel
                    sizeSqm
                    monthlyServiceCost
                    otherCosts
                }
            }
            contractTenancyAgreements {
                id
                active
                archived
            }
        }
    }
    ${TENANT_CONTRACT_FRAGMENT}
`

export const GET_TENANT_CONTRACTS = gql`
    query GET_TENANT_CONTRACTS($id: ID!) {
        tenant(id: $id) {
            id
            tenancyAgreements(includeArchived: true) {
                ...TenancyAgreementFields
                unit {
                    id
                    longName
                    fullUnitName
                    monthlyBasicRent
                    monthlyServiceCost
                    deposit
                    sizeSqm
                    address {
                        id
                        longName
                        serviceCostDivisionModel
                        sizeSqm
                        monthlyServiceCost
                        otherCosts
                    }
                }
                contract {
                    ...TenantContractFields
                    property {
                        id
                        longName
                        serviceCostDivisionModel
                        sizeSqm
                        monthlyServiceCost
                        otherCosts
                        rooms {
                            id
                            fullUnitName
                        }
                    }
                    unit {
                        id
                        longName
                        fullUnitName
                        monthlyBasicRent
                        monthlyServiceCost
                        deposit
                        sizeSqm
                        address {
                            id
                            longName
                            serviceCostDivisionModel
                            sizeSqm
                            monthlyServiceCost
                            otherCosts
                        }
                    }
                    contractTenancyAgreements {
                        id
                        active
                        archived
                    }
                }
            }
        }
    }
    ${TENANCY_AGREEMENT_FRAGMENT}
    ${TENANT_CONTRACT_FRAGMENT}
`

export const GET_TENANT_LEADS = gql`
    query GET_TENANT_LEADS($id: ID!) {
        tenant(id: $id) {
            id
            leads(includeArchived: true) {
                id
                archived
                preferredCheckInDate
                budget
                property {
                    id
                    longName
                    rooms {
                        id
                        longName
                        fullUnitName
                    }
                }
                unit {
                    id
                    longName
                    fullUnitName
                }
            }
        }
    }
`


export const GET_PROPERTY_CONTRACTS = gql`
    query GET_PROPERTY_CONTRACTS($id: ID!, $includeUnitContracts: Boolean, $includeArchived: Boolean) {
        property(id: $id) {
            id
            propertyContracts(includeUnitContracts: $includeUnitContracts, includeArchived: $includeArchived) {
                ...TenantContractFields
                unit {
                    id
                    fullUnitName
                }
                contractTenancyAgreements(includeArchived: $includeArchived) {
                    ...TenancyAgreementFields
                    tenant {
                        id
                        user {
                            id
                            fullName
                            picture
                        }
                    }
                }
            }
        }
    }
    ${TENANT_CONTRACT_FRAGMENT}
    ${TENANCY_AGREEMENT_FRAGMENT}
`

export const GET_PROPERTY_OWNER_CONTRACTS = gql`
    query GET_PROPERTY_OWNER_CONTRACTS($buildingId: ID!) {
        building(id: $buildingId) {
            id
            buildingOwnerContracts {
                ...OwnerContractFields
            }
            properties {
                id
                propertyOwnerContracts {
                    ...OwnerContractFields
                }
                rooms {
                    id
                    unitOwnerContracts {
                        ...OwnerContractFields
                    }
                }
            }
        }
    }
    ${OWNER_CONTRACT_FRAGMENT}
`

export const GET_OWNED_PROPERTIES = gql`
    query GET_OWNED_PROPERTIES($ownerId: ID!) {
        landlord(id: $ownerId) {
            id
            ownesBuildings {
                id
                name
            }
            ownesProperties {
                id
                streetWithHouseNumber
                rooms {
                    id
                    longName
                }
                building {
                    id
                }
            }
        }
    }
`

export const GET_PROPERTY_LEADS = gql`
    query GET_PROPERTY_LEADS($id: ID!, $includeUnitLeads: Boolean) {
        property(id: $id) {
            id
            propertyLeads(includeUnitLeads: $includeUnitLeads) {
                id
                budget
                preferredCheckInDate
                archived
                tenant {
                    id
                    user {
                        id
                        fullName
                        picture
                    }
                }
                unit {
                    id
                    fullUnitName
                }
            }
        }
    }
`

export const GET_UNIT_LEADS = gql`
    query GET_UNIT_LEADS($id: ID!) {
        unit(id: $id) {
            id
            unitLeads {
                id
                budget
                preferredCheckInDate
                archived
                tenant {
                    id
                    user {
                        id
                        fullName
                        picture
                    }
                }
                property {
                    id
                    streetWithHouseNumber
                }
            }
        }
    }
`

export const GET_UNIT_CONTRACTS = gql`
    query GET_UNIT_CONTRACTS($id: ID!, $includeArchived: Boolean) {
        unit(id: $id) {
            id
            unitContracts(includeArchived: $includeArchived) {
                ...TenantContractFields
                contractTenancyAgreements(includeArchived: $includeArchived) {
                    ...TenancyAgreementFields
                    tenant {
                        id
                        user {
                            id
                            fullName
                            picture
                        }
                    }
                }
            }
        }
    }
    ${TENANT_CONTRACT_FRAGMENT}
    ${TENANCY_AGREEMENT_FRAGMENT}
`

export const GET_SERVICE_TYPES = gql`
    query GET_SERVICE_TYPES {
        serviceTypes {
            id
            name
            isInternet
            isUtility
        }
    }
`

export const GET_BUILDING_SERVICES = gql`
    query GET_BUILDING_SERVICES($id: ID!) {
        building(id: $id) {
            id
            buildingServices {
                ...ServiceFields
                payments {
                    ...ServicePaymentFields
                }
            }
        }
    }
    ${SERVICE_FRAGMENT}
    ${SERVICE_PAYMENT_FRAGMENT}
`

export const GET_ADDRESS_SERVICES = gql`
    query GET_ADDRESS_SERVICES($id: ID!) {
        address(id: $id) {
            id
            addressServices {
                ...ServiceFields
                payments {
                    ...ServicePaymentFields
                }
            }
        }
    }
    ${SERVICE_FRAGMENT}
    ${SERVICE_PAYMENT_FRAGMENT}
`

export const GET_ROOM_SERVICES = gql`
    query GET_ROOM_SERVICES($id: ID!) {
        room(id: $id) {
            id
            roomServices {
                ...ServiceFields
                payments {
                    ...ServicePaymentFields
                }
            }
        }
    }
    ${SERVICE_FRAGMENT}
    ${SERVICE_PAYMENT_FRAGMENT}
`

export const GET_SERVICE_METER = gql`
    query GET_SERVICE_METER($serviceId: ID!) {
        serviceMeter(serviceId: $serviceId) {
            id
            number
            location
            archived
            meterReadings {
                id
                value
                date
                picture
                archived
            }
        }
    }
`

export const GET_PROPERTY_ATTACHMENT_TAG_OBJECTS = gql`
    query GET_PROPERTY_ATTACHMENT_TAG_OBJECTS($buildingIds: [ID], $propertyIds: [ID], $unitIds: [ID]) {
        propertyTasks(propertyIds: $propertyIds, unitIds: $unitIds) {
            id
        }
        propertyThreads(buildingIds: $buildingIds, propertyIds: $propertyIds, unitIds: $unitIds) {
            id
        }
    }
`

export const GET_CONTRACT = gql`
    query GET_CONTRACT($id: ID!) {
        contract(id: $id) {
            id
            ownercontract {
                id
                owner {
                    id
                }
            }
            tenantcontract {
                id
                property {
                    id
                }
                unit {
                    id
                }
            }
        }
    }
`

export const GET_LEAD = gql`
    query GET_LEAD($id: ID!) {
        lead(id: $id) {
            id
            property {
                id
            }
            unit {
                id
            }
        }
    }
`

export const GET_SERVICE_PAYMENT = gql`
    query GET_SERVICE_PAYMENT($id: ID!) {
        servicePayment(id: $id) {
            id
            service {
                id
                building {
                    id
                }
                address {
                    id
                }
                room {
                    id
                }
            }
        }
    }
`

export const GET_SERVICE = gql`
    query GET_SERVICE($id: ID!) {
        service(id: $id) {
            id
            building {
                id
            }
            address {
                id
            }
            room {
                id
            }
        }
    }
`

export const GET_PROPERTY_RELATED_OBJECTS = gql`
    query GET_PROPERTY_RELATED_OBJECTS($objectId: ID!, $contentModel: String!) {
        propertyRelatedObjects(objectId: $objectId, contentModel: $contentModel) {
            buildings {
                id
                name
            }
            addresses {
                id
                label
            }
            rooms {
                id
                label
            }
            ownerContracts {
                id
                label
            }
            tenantContracts {
                id
                label
            }
            tenancyAgreements {
                id
                label
            }
            leads {
                id
                label
            }
            tickets {
                id
                name
            }
            tasks {
                id
                label
            }
            threads {
                id
                name
            }
        }
    }
`