const deviceStates = {
    CONNECTING: "Connecting",
    READY: "Ready",
    INCOMING: "Incoming",
    CALLING: "Calling",
    ON_CALL: "On call",
    OFFLINE: "Offline",
    CANCELED: "Canceled"
}

export default deviceStates
