import { useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useReactiveVar, useMutation, useQuery } from '@apollo/client'
import { newDraftsVar, openedTabsVar } from '../../cache'
import { GET_USER_TYPE_OPTIONS } from '../../queries'
import { CREATE_USER } from '../mutations'
import { tabNavLinks, lbl, employmentStatusOptions, employmentStatus } from '../../constants'
import Field from '../../forms/Field'
import { TabNavContext } from '../../components/TabNav'

const CreateUser = () => {
    const { closeTab } = useContext(TabNavContext)
    const userDraft = useReactiveVar(newDraftsVar).contacts
    const { relativeUrl } = tabNavLinks.contacts.new
    const [createUser, { loading, error, called, data: newUserData, done }] = useMutation(CREATE_USER, {
        onCompleted: () => {
            newDraftsVar({ ...newDraftsVar(), contacts: { type: '1_guests' } })
        }
    })
    const { data } = useQuery(GET_USER_TYPE_OPTIONS)
    const errMsg = error && error.message.replace(/',|'|]|\[/g, '')
    const success = !error && !loading && called && newUserData?.createUser?.user

    const handleChange = e => {
        const { name, value } = e.target
        
        newDraftsVar({
            ...newDraftsVar(),
            contacts: { ...userDraft, [name]: value }
        })
    }

    useEffect(() => {
        openedTabsVar(new Map([...openedTabsVar(), [relativeUrl, {
            name: lbl.newContact,
            id: 'new',
            relativeUrl,
            icon: 'contact'
        }]]))
    }, [relativeUrl])

    return (
        <div
            className='split-panel--main mx-auto'
            style={{ maxWidth: '800px' }}
        >
            <br/>
            <form
                method='post'
                onSubmit={async e => {
                    e.preventDefault()
                    await createUser({ variables: userDraft })
                }}
            >
                <fieldset disabled={(loading && !done) || success} aria-busy={loading && !done}>
                    <Field
                        As='select'
                        name='type'
                        value={userDraft.type}
                        handleChange={handleChange}
                        options={data?.userTypeOptions || []}
                        lblKey='userType'
                        required
                    />
                    {userDraft.type === '1_guests' &&
                        <Field
                            As='select'
                            name='employmentStatus'
                            value={userDraft.employmentStatus || ''}
                            options={employmentStatusOptions}
                            handleChange={e => {
                                const { name, value } = e.target
                                const newUserDraft = { ...userDraft }
                                
                                if (value === employmentStatus.UNKNOWN) {
                                    delete newUserDraft[name]
                                } else {
                                    newUserDraft[name] = value
                                }
                                newDraftsVar({ ...newDraftsVar(), contacts: newUserDraft })
                            }}
                        />
                    }
                    <Field
                        name='firstName'
                        value={userDraft.firstName || ''}
                        handleChange={handleChange}
                        required
                    />
                    <Field
                        name='lastName'
                        value={userDraft.lastName || ''}
                        handleChange={handleChange}
                        required
                    />
                    <fieldset
                        disabled={success}
                        className={success ? 'success' : error ? 'error' : ''}
                    >
                        <label htmlFor='email'>
                            {success ? 'Success! New contact created' : lbl.email}
                        </label>
                        <input
                            type={success ? 'text' : 'email'}
                            name='email'
                            required
                            value={success ? `${lbl.sendInvitationSuccess} ${newUserData.createUser.user.email}` : userDraft.email || ''}
                            onChange={handleChange}
                        />
                        {error && <p className='input-message input-message--error'>{errMsg}</p>}
                    </fieldset>
                    <br/>
                    {!success &&
                        <button type='submit' className='btn d-block ml-auto btn--primary'>
                            {loading ? <span className='btn__loader'>&#8635;</span> :lbl.sendInvitation}
                        </button>
                    }
                </fieldset>
            </form>
            {(success && newUserData.createUser.user.type === '1_guests') &&
                <div className='d-flex'>
                    <Link
                        to={`${tabNavLinks.contacts.dynamicPath}${newUserData.createUser.user.id}/leads#new`}
                        className='btn btn--primary d-flex align-center justify-center w-100 mr-1'
                        onClick={() => closeTab({ id: 'new', relativeUrl })}
                    >
                        {lbl.createLead}
                    </Link>
                    <Link
                        to={`${tabNavLinks.contacts.dynamicPath}${newUserData.createUser.user.id}/contracts#new`}
                        className='btn btn--primary flex-1 d-flex align-center justify-center w-100 ml-1'
                        onClick={() => closeTab({ id: 'new', relativeUrl })}
                    >
                        {lbl.createContract}
                    </Link>
                </div>
            }
        </div>
    )
}

export default CreateUser
