import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_CUSTOM_FIELD_GROUPS_FOR_INSTANCE, GET_UNGROUPED_CUSTOM_FIELS_FOR_INSTANCE } from '../queries'
import Form from './Form'
import useCustomFormFields from '../hooks/useCustomFields'


const FormWithCustomFields = ({
    contentType,
    objectId,
    ...formProps
}) => {
    const [uploading, setUploading] = useState(false)
    const [uploadErrors, setUploadErrors] = useState({})
    const [uploadSuccess, setUploadSuccess] = useState(false)
    const {
        loading: loadingGrouped,
        error: groupedError,
        data: groupedData
    } = useQuery(GET_CUSTOM_FIELD_GROUPS_FOR_INSTANCE, {
        variables: { contentType, objectId }
    })
    const {
        loading: loadingUngrouped,
        error: ungroupedError,
        data: ungroupedData
    } = useQuery(GET_UNGROUPED_CUSTOM_FIELS_FOR_INSTANCE, {
        variables: { contentType, objectId }
    })
    const { fields, fieldNames, initialState, saveCustomFields } = useCustomFormFields({
        contentType,
        objectId,
        setUploading,
        setUploadSuccess,
        setUploadErrors,
        uploadErrors,
        ungroupedFields: ungroupedData?.ungroupedCustomFieldsForContentObject,
        groupedFields: groupedData?.customFieldGroupsForContentObject,
        groupedFirst: true
    })
    const { save: submitCustomFields, loading: savingCustomFields, called: saveCustomFieldsCalled, data: saveCustomFieldsData, error: saveCustomFieldsError, done: saveCustomFieldsDone } = saveCustomFields
    const uploadErrorMessage = Object.values(uploadErrors).join(', ')
    const error = formProps.error || saveCustomFieldsError?.message || uploadErrorMessage
    const isSubmitting = formProps.isSubmitting || uploading || (savingCustomFields && !saveCustomFieldsDone)
    const isSuccess = (formProps.isSuccess || uploadSuccess || (saveCustomFieldsCalled && !!saveCustomFieldsData)) && !isSubmitting && !error
    const onSubmit = (data, cancelChanges) => {
        const customFieldsFormData = {}
        const formData = {}
        
        Object.keys(data).forEach(fieldName => {
            if (fieldNames.includes(fieldName)) {
                customFieldsFormData[fieldName] = data[fieldName]
            } else {
                formData[fieldName] = data[fieldName]
            }
        })
        
        if (Object.keys(formData).length) {
            let saveCustomFields
            if (!objectId && Object.keys(customFieldsFormData).length) {
                saveCustomFields = (contentObjectId) => submitCustomFields(customFieldsFormData, contentObjectId)
            }
            formProps.onSubmit(formData, cancelChanges, saveCustomFields)
        }
        if (objectId && Object.keys(customFieldsFormData).length) {
            submitCustomFields(customFieldsFormData)
        }
    }

    return (
        <>
            <Form
                key={'customForm' + fields.length}
                {...formProps}
                fields={[...formProps.fields, ...fields]}
                initialState={{ ...formProps.initialState, ...initialState }}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                isSuccess={isSuccess}
                error={error}
                formClassName='form--column--2'
            />
            {(loadingGrouped || loadingUngrouped) && <div className='loader pos-center-y' />}
            {groupedError && <div className='error t-red'>{groupedError.message}</div>}
            {ungroupedError && <div className='error t-red'>{ungroupedError.message}</div>}
        </>
    )
}

export default FormWithCustomFields
