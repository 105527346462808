import { useState, useRef } from 'react'
import { useMutation, useReactiveVar, useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { lbl, tabNavLinks } from '../../constants'
import { openedThreadsVar, notificationCountersVar, currentUserVar, lastActiveThreadVar, showAllThreadsVar } from '../../cache'
import { ContextMenu, SwitchButton, Accordion } from '../../components'
import InboxItem from './InboxItem'
import ThreadsTree from './ThreadsTree'
import { useThreadActions } from '../hooks'
import { CREATE_THREAD, RENAME_THREAD } from '../mutations'
import { GET_PINNED_THREADS } from '../queries'


const Inbox = ({ threads, isLoading, activeThread, activeTab, threadCrumbs }) => {
    let [contextMenu, setContextMenu] = useState(false)
    let showAllThreads = useReactiveVar(showAllThreadsVar)
    let [newThreadParent, setNewThreadParent] = useState()
    let [renameThreadId, setRenameThreadId] = useState()
    let counters = useReactiveVar(notificationCountersVar)
    let openedThreads = useReactiveVar(openedThreadsVar)
    let [pinnedOpen, togglePinned] = useState(openedThreads.includes('pinned'))

    const history = useHistory()
    const threadsList = useRef()
    const crumbs = threadCrumbs.map(({ id }) => id)
    const [getThreadActions] = useThreadActions({ activeThread })

    const { data: pinnedData } = useQuery(GET_PINNED_THREADS)

    const [createThread] = useMutation(CREATE_THREAD, {
        onCompleted: ({ createThread: { thread } }) => {
            setNewThreadParent(null)
            thread && history.push(tabNavLinks.messenger.dynamicPath + thread.id)
        }
    })

    const [renameThread] = useMutation(RENAME_THREAD, {
        onCompleted: () => setRenameThreadId(null)
    })
    
    const _renameThread = ({ name }) => {
        if (name && renameThreadId) {
            renameThread({
                variables: { name, id: renameThreadId }
            })
        }
    }

    const onToggleSubthreads = (id, wasOpen) => {
        const updatedOpenedThreads = wasOpen ? openedThreadsVar().filter(_id => _id !== id) : [...openedThreadsVar(), id]
        openedThreadsVar(updatedOpenedThreads)
        localStorage.setItem('openedThreads', JSON.stringify(updatedOpenedThreads))
    }

    const createSubthread = ({ name, joinParentThreads }) => {
        createThread({
            variables: {
                name,
                parentId: newThreadParent.id,
                members: [currentUserVar().id],
                setMembersFromParent: newThreadParent.isDirect && !newThreadParent.isGroup,
                joinParentThreads: !!joinParentThreads
            },
            update(cache, { data: { createThread } }) {
                if (createThread?.thread) {
                    cache.modify({
                        id: cache.identify({ __typename: 'ThreadType', id: newThreadParent.id }),
                        fields: {
                            subthreads(existing = []) {
                                return [...existing, createThread.thread];
                            }
                        }
                    })
                }
            }
        })
    }

    const onContextMenu = (thread, level) => {
        const actions = thread.isFolder ? [] : getThreadActions({ threads: [thread], threadIds: [thread.id] })

        if (!thread.fixed && !thread.isFolder && !(level === 1 && thread.isDirect && !thread.isGroup)) {
            actions.push({
                icon: 'pencil',
                label: 'Rename',
                onClick: () => setRenameThreadId(thread.id)
            })
        }

        if (level < 6) {
            actions.push({
                icon: 'plus',
                label: lbl.addSubthread,
                onClick: () => setNewThreadParent(thread)
            })
        }

        setContextMenu({
            heading: {
                icon: thread.icon,
                label: thread.name,
                style: { maxWidth: '300px' },
                onClick: () => !thread.isFolder && history.push(tabNavLinks.messenger.dynamicPath + thread.id)
            },
            items: actions
        })
    }

    const setLastActiveThread = (threadId) => {
        lastActiveThreadVar(threadId)
        localStorage.setItem('lastActiveThread', threadId)
    }

    return (
        <aside className='split-panel--side split-panel--left'>
            <ul
                className='split-panel--side-inner inbox'
                ref={threadsList}
                onContextMenu={() => setContextMenu(false)}
            >
                <ul className='pos-sticky pos-top bg-light pt-2 pb-2'>
                    <li className='t-right mr-2'>
                        <SwitchButton
                            name='toggle-threads'
                            checked={showAllThreads}
                            onSwitch={() => showAllThreadsVar(!showAllThreads)}
                            labelLeft='mine'
                            labelRight='all'
                        />
                    </li>
                    <li className={`inbox__listitem${activeTab === 'new' ? ' inbox__listitem--active inbox__listitem--nohover' : ''}`}>
                        <InboxItem
                            level={0}
                            label={lbl.new}
                            relativeUrl={tabNavLinks.messenger.new}
                            unreadCount={counters.unreadMessageCount}
                            onClick={() => { setLastActiveThread('new') }}
                            icon='inbox'
                        />
                    </li>
                    <li className={`inbox__listitem${activeTab === 'mentions' ? ' inbox__listitem--active inbox__listitem--nohover' : ''}`}>
                        <InboxItem
                            level={0}
                            label={lbl.mentions}
                            relativeUrl={tabNavLinks.messenger.mentions}
                            unreadCount={counters.unreadMentionsCount}
                            onClick={() => { setLastActiveThread('mentions') }}
                            icon='at'
                        />
                    </li>
                    <li className={`inbox__listitem${activeTab === 'archive' ? ' inbox__listitem--active inbox__listitem--nohover' : ''}`}>
                        <InboxItem
                            level={0}
                            label={lbl.archive}
                            relativeUrl={tabNavLinks.messenger.archive}
                            onClick={() => { setLastActiveThread('archive') }}
                            icon='archive'
                        />
                    </li>
                    <Accordion
                        className={`inbox__listitem mb-0 ${activeTab === 'pinned' ? ' inbox__listitem--active inbox__listitem--nohover' : ''}`}
                        headerClass='accordion__header--icon level--0'
                        label={<InboxItem
                            level={0}
                            label={lbl.pinned}
                            relativeUrl={tabNavLinks.messenger.pinned}
                            onClick={() => { setLastActiveThread('pinned') }}
                            icon='pin'
                        />}
                        isOpen={pinnedOpen}
                        onOpen={() => {
                            togglePinned(prev => !prev)
                            onToggleSubthreads('pinned', pinnedOpen)
                        }}
                    >
                        <ul>
                            {pinnedData?.pinnedThreads?.map(t => {
                                const icon = t.icon ? t.icon : t.isGroup ? 'contact-multi' : t.isDirect ? 'dot' : 'hash'
                                
                                return (
                                    <li className={'inbox__listitem'} key={`pinned-${t.id}`}>
                                        <div className='level--1'>
                                            <InboxItem
                                                key={`pinned-${t.id}`}
                                                level={1}
                                                label={t.name}
                                                onlineStatus={t.contact?.onlineStatus}
                                                relativeUrl={tabNavLinks.messenger.dynamicPath + t.id}
                                                icon={icon}
                                                onContextMenu={(e) => {
                                                    e.stopPropagation()
                                                    onContextMenu({ ...t, icon }, 1)
                                                }}
                                                unreadCount={t.unreadCount}
                                                markedAsUnread={!!t.membership?.isUnread}
                                                isSubscribed={!!t.membership?.isSubscribed}
                                            />
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </Accordion>
                </ul>
                <li className='inbox__spacer'/>
                {isLoading && <li className='loader' />}
                {threads && 
                    <ul>
                        <ThreadsTree
                            threads={threads}
                            activeThread={activeThread}
                            openedThreads={openedThreads}
                            crumbs={crumbs}
                            onContextMenu={onContextMenu}
                            newThreadParentId={newThreadParent?.id}
                            createSubthread={createSubthread}
                            renameThreadId={renameThreadId}
                            renameThread={_renameThread}
                            clearNewThread={() => setNewThreadParent(null)}
                            onToggleSubthreads={onToggleSubthreads}
                        />
                    </ul>
                }
            </ul>
            <ContextMenu
                key='threads-context-menu'
                element={threadsList.current}
                {...contextMenu}
            />
        </aside>
    )
}

export default Inbox
