import { gql } from '@apollo/client'
import { MESSAGE_FRAGMENT, THREAD_FRAGMENT } from './fragments'
import { USER_FRAGMENT } from '../fragments'


export const SEND_MESSAGE = gql`
    mutation SEND_MESSAGE($title: String, $body: String, $threadId: ID, $contactIds: [ID], $refMsgId: ID, $fwdMsgId: ID, $isNotification: Boolean, $mentionIds: [ID]) {
        sendMessage(title: $title, body: $body, threadId: $threadId, contactIds: $contactIds, refMsgId: $refMsgId, fwdMsgId: $fwdMsgId, isNotification: $isNotification, mentionIds: $mentionIds) {
            message {
                ...MessageFields
                isRead
                thread {
                    id
                    noReply
                    members {
                        ...UserFields
                    }
                    threadmembershipSet {
                        id
                        user {
                            id
                            fullName
                        }
                        joinedAt
                        leftAt
                    }
                }
            }
        }
    }
    ${MESSAGE_FRAGMENT}
    ${USER_FRAGMENT}
`

export const UPDATE_MESSAGE = gql`
    mutation UPDATE_MESSAGE($id: ID!, $body: String, $isImportant: Boolean, $isHighlighted: Boolean, $archived: Boolean, $mentionIds: [ID]) {
        updateMessage(id: $id, body: $body, isImportant: $isImportant, isHighlighted: $isHighlighted, archived: $archived, mentionIds: $mentionIds) {
            message {
                ...MessageFields
            }
        }
    }
    ${MESSAGE_FRAGMENT}
`

export const ARCHIVE_MESSAGE = gql`
    mutation ARCHIVE_MESSAGE($messageId: ID!) {
        archiveMessage(messageId: $messageId) {
            message {
                id
                archived
                thread {
                    id
                }
            }
        }
    }
`

export const CREATE_THREAD = gql`
    mutation CREATE_THREAD($name: String!, $members: [ID!], $parentId: ID, $message: String, $setMembersFromParent: Boolean, $joinParentThreads: Boolean) {
        createThread(name: $name, members: $members, parentId: $parentId, messageBody: $message, setMembersFromParent: $setMembersFromParent, joinParentThreads: $joinParentThreads) {
            thread {
                ...BaseThreadFields,
                members {
                    ...UserFields
                }
                threadmembershipSet {
                    id
                    user {
                        id
                        fullName
                    }
                    joinedAt
                    leftAt
                }
                messages {
                    ...MessageFields
                }
            }
            joinedParentThreads {
                id
                membership {
                    id
                    isPinned
                    isSubscribed
                }
                members {
                    ...UserFields
                }
                threadmembershipSet {
                    id
                    user {
                        id
                        fullName
                    }
                    joinedAt
                    leftAt
                }
            }
        }
    }
    ${THREAD_FRAGMENT}
    ${USER_FRAGMENT}
    ${MESSAGE_FRAGMENT}
`

export const MARK_AS_READ = gql`
    mutation MARK_AS_READ($threadIds: [ID!]) {
        markAsRead(threadIds: $threadIds) {
            threads {
                id
                membership {
                    id
                }
            }
        }
    }
`

export const MARK_AS_UNREAD = gql`
    mutation MARK_AS_UNREAD($threadIds: [ID!]) {
        markAsUnread(threadIds: $threadIds) {
            threads {
                ...BaseThreadFields
                messages(offset: 0, limit: 5, reversed: true) {
                    ...MessageFields
                }
                unreadMessages {
                    ...MessageFields
                }
            }
        }
    }
    ${THREAD_FRAGMENT}
    ${MESSAGE_FRAGMENT}
`

export const JOIN_THREADS = gql`
    mutation JOIN_THREADS($threadIds: [ID!], $subscribe: Boolean) {
        joinThreads(threadIds: $threadIds, subscribe: $subscribe) {
            threads {
                id
                membership {
                    id
                    isPinned
                    isSubscribed
                    isUnread
                }
                members {
                    ...UserFields
                }
                threadmembershipSet {
                    id
                    user {
                        id
                        fullName
                    }
                    joinedAt
                    leftAt
                }
                unreadCount
            }
        }
    }
    ${USER_FRAGMENT}
`

export const LEAVE_THREADS = gql`
    mutation LEAVE_THREADS($threadIds: [ID!]) {
        leaveThreads(threadIds: $threadIds) {
            threads {
                id
                membership {
                    id
                    isPinned
                    isSubscribed
                    isUnread
                }
                members {
                    ...UserFields
                }
                threadmembershipSet {
                    id
                    user {
                        id
                        fullName
                    }
                    joinedAt
                    leftAt
                }
                unreadCount
            }
        }
    }
    ${USER_FRAGMENT}
`

export const PIN_THREADS = gql`
    mutation PIN_THREADS($threadIds: [ID!]) {
        pinThreads(threadIds: $threadIds) {
            threads {
                id
                membership {
                    id
                    isPinned
                }
            }
        }
    }
`

export const UNPIN_THREADS = gql`
    mutation UNPIN_THREADS($threadIds: [ID!]) {
        unpinThreads(threadIds: $threadIds) {
            threads {
                id
                membership {
                    id
                    isPinned
                }
            }
        }
    }
`

export const SUBSCRIBE_TO_THREADS = gql`
    mutation SUBSCRIBE_TO_THREADS($threadIds: [ID!]) {
        subscribeToThreads(threadIds: $threadIds) {
            threads {
                id
                unreadCount
                membership {
                    id
                    isSubscribed
                    isUnread
                }
            }
        }
    }
`

export const UNSUBSCRIBE_FROM_THREADS = gql`
    mutation UNSUBSCRIBE_FROM_THREADS($threadIds: [ID!]) {
        unsubscribeFromThreads(threadIds: $threadIds) {
            threads {
                id
                unreadCount
                membership {
                    id
                    isSubscribed
                    isUnread
                }
            }
        }
    }
`

export const ARCHIVE_THREADS = gql`
    mutation ARCHIVE_THREADS($threadIds: [ID!]) {
        archiveThreads(threadIds: $threadIds) {
            threads {
                id
                unreadCount
                archived
                membership {
                    id
                    isUnread
                }
            }
        }
    }
`

export const ACTIVATE_THREADS = gql`
    mutation ACTIVATE_THREADS($threadIds: [ID!]) {
        activateThreads(threadIds: $threadIds) {
            threads {
                id
                unreadCount
                parent {
                    id
                }
                archived
                membership {
                    id
                    isUnread
                }
            }
        }
    }
`

export const ADD_MEMBERS = gql`
    mutation ADD_MEMBERS($threadId: ID!, $userIds: [ID!]) {
        addMembers(threadId: $threadId, userIds: $userIds) {
            threads {
                id
                members {
                    ...UserFields
                }
                membership {
                    id
                    isSubscribed
                }
                threadmembershipSet {
                    id
                    user {
                        id
                        fullName
                    }
                    joinedAt
                    leftAt
                }
            }
        }
    }
    ${USER_FRAGMENT}
`

export const REMOVE_MEMBERS = gql`
    mutation REMOVE_MEMBERS($threadId: ID!, $userIds: [ID!]) {
        removeMembers(threadId: $threadId, userIds: $userIds) {
            threads {
                id
                members {
                    ...UserFields
                }
                membership {
                    id
                    isSubscribed
                }
                threadmembershipSet {
                    id
                    user {
                        id
                        fullName
                    }
                    joinedAt
                    leftAt
                }
            }
        }
    }
    ${USER_FRAGMENT}
`

export const ADD_ALL_PARENT_THREAD_MEMBERS = gql`
    mutation ADD_ALL_PARENT_THREAD_MEMBERS($threadId: ID!) {
        addAllParentThreadMembers(threadId: $threadId) {
            thread {
                id
                members {
                    ...UserFields
                }
                membership {
                    id
                    isSubscribed
                }
                threadmembershipSet {
                    id
                    user {
                        id
                        fullName
                    }
                    joinedAt
                    leftAt
                }
            }
        }
    }
    ${USER_FRAGMENT}
`

export const RENAME_THREAD = gql`
    mutation RENAME_THREAD($name: String!, $id: ID!) {
        renameThread(name: $name, id: $id) {
            thread {
                id
                name
            }
        }
    }
`

export const SEND_MESSAGE_AS_EMAIL = gql`
    mutation SEND_MESSAGE_AS_EMAIL($messageId: ID!) {
        sendMessageAsEmail(messageId: $messageId) {
            ok
            message {
                id
                sentAsEmail
            }
        }
    }
`
