import { lbl } from '../constants'
import { GET_EMPLOYEES } from '../queries'

export const templateProperties = {
    createdBy: {
        field: 'createdBy',
        type: 'object',
        label: 'Creator',
        sortPath: 'createdBy.fullName',
        groupPath: 'createdBy.fullName',
        objectPath: 'createdBy',
        optionsQuery: { query: GET_EMPLOYEES, name: 'employees' },
        getOptionProps: root => ({
            ...root,
            label: root.fullName
        })
    },
    createdAt: {
        field: 'createdAt',
        type: 'string',
        label: 'Date created',
        sortPath: 'createdAt',
        sortDir: -1
    },
    lastModifiedBy: {
        field: 'lastModifiedBy',
        type: 'object',
        label: 'Updated by',
        sortPath: 'lastModifiedBy.fullName',
        groupPath: 'lastModifiedBy.fullName',
        objectPath: 'lastModifiedBy',
        optionsQuery: { query: GET_EMPLOYEES, name: 'employees' },
        getOptionProps: root => ({
            ...root,
            label: root.fullName
        })
    },
    lastModified: {
        field: 'lastModified',
        type: 'string',
        label: 'Last update',
        sortPath: 'lastModified',
        sortDir: -1
    },
    name: {
        field: 'name',
        label: lbl.name,
        type: 'string',
        sortPath: 'name',
    }
}

export const listerCtrlOptions = {
    filter: ['createdBy', 'lastModifiedBy'].map((key) => templateProperties[key]),
    group: ['createdBy', 'lastModifiedBy'].map((key) => templateProperties[key]),
    sort: ['name', 'createdAt', 'createdBy', 'lastModified', 'lastModifiedBy'].map((key) => templateProperties[key]),
    search: ['name', 'createdBy', 'lastModifiedBy'].map((key) => templateProperties[key])
}

export const listerColumns = ['name', 'lastModified', 'lastModifiedBy', 'createdAt', 'createdBy']
