const TaskAttachmentsPreview = ({ attachments }) => {
    return (
        <section>
            <div className="mb-3">Attachments</div>
            <ul>
                {attachments.map(attachment => {
                    const extIndex = attachment.originalFilename.lastIndexOf('.')

                    return (
                        <li className="mb-1 action__li attachment" key={attachment.originalFilename}>
                            <a
                                className="btn btn--naked btn--inline-icon t-sm t-none attachment__link"
                                target="_blank"
                                rel="noreferrer"
                                href={`${window._env_.REACT_APP_REST_ENDPOINT}/upload_proxy/${attachment.id}`}
                            >
                                📎 
                                <span className="attachment__name ml-05">{attachment.originalFilename.substring(0, extIndex)}</span>
                                <span>{attachment.originalFilename.substring(extIndex)}</span>
                            </a>
                        </li>
                    )
                })}
            </ul>
        </section>
    )
}

export default TaskAttachmentsPreview
