import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { UPDATE_TASK } from '../mutations'
import { CheckboxField } from '../../forms'
import { ProfileImage, Label } from '../../components'
import { tabNavLinks, statusOptions as statusOptionsDict } from '../../constants'


const TaskChecklistItem = ({ id, name, dueDateLabel, dueDateOffset, assignee, status, disabled }) => {
    const [updateTask] = useMutation(UPDATE_TASK)
    const statusOptions = Object.keys(statusOptionsDict)

    return (
        <li className='task-listitem'>
            <CheckboxField
                disabled={disabled}
                className='checkbox--task'
                statusOptions={statusOptions}
                status={status}
                label={<>
                    <ProfileImage url={assignee && assignee.picture} size='xs' />
                    <Link
                        to={`${tabNavLinks.tasks.dynamicPath}${id}`}
                        className='checkbox__text ml-1 mr-auto t-none'
                    >
                        {name}
                    </Link>
                </>}
                onChange={(newStatus) => updateTask({ variables: { id, status: newStatus } })}
            >
                {dueDateLabel && status !== statusOptions[2] && 
                    <Label
                        className='time-label'
                        iconProps={{ type: 'time' }}
                        label={dueDateLabel}
                        classModifier={dueDateOffset < 2 ? 'red' : dueDateOffset < 3 ? 'yellow' : 'grey'}
                    />
                }
            </CheckboxField>
        </li>
    )
}

export default TaskChecklistItem
