import { useHistory } from 'react-router-dom'
import { useState, useRef, useEffect, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { getCsrfCookie } from '../../utils'
import {
    SEND_MESSAGE,
    UPDATE_MESSAGE,
    JOIN_THREADS
} from '../mutations'
import { ADD_ATTACHMENT_TAG } from '../../attachments/mutations'
import TextEditor from '../../components/TextEditor'
import { Icon } from '../../components'
import InputBox from '../../forms/InputBox'
import { TabNavContext } from '../../components/TabNav'
import { lbl, tabNavLinks } from '../../constants'
import { useMarkAsReadMutation } from '../hooks'
import scrollApi from '../../utils/scrollUtils'
import { newMessageDrafts } from '../../cache'

const MessageEditor = ({
    msg,
    threadId,
    contactIds,
    isMember,
    isNotificationThread,
    isEmptyThread,
    isUnread,
    onSend,
    onUpdate,
    fullVariant,
    refMsgId,
    fwdMsgId,
    preventRedirect,
    sendMessageAsEmail,
    scrollContainer
}) => {
    const { scrollToBottom } = scrollApi
    const history = useHistory()
    const drafts = newMessageDrafts()
    const newMessageDraft = drafts.get(threadId)
    const { id: activeId, activeTab } = useContext(TabNavContext)
    const editorRef = useRef()
    const draftRef = useRef(newMessageDraft)
    const [title, setTitle] = useState(newMessageDraft?.title || '')
    const [isNotification, setIsNotification] = useState(!!isNotificationThread || !!newMessageDraft?.isNotification)
    const [sendAsEmail, setSendAsEmail] = useState(!!newMessageDraft?.sendAsEmail)
    // const [sendAsSms, setSendAsSms] = useState(false)
    // const [sendAsRegMail, setSendAsRegMail] = useState(false)
    // const [showCallPopup, setShowCallPopup] = useState(false)
    const [msgBody, setBody] = useState(msg ? msg.body : newMessageDraft?.body || '')
    const [uploadedFile, setUploadedFile] = useState(null)
    const [markAsRead] = useMarkAsReadMutation(!!isUnread)
    const [addAttachmentTag] = useMutation(ADD_ATTACHMENT_TAG)
    const [joinThreads, { loading: joining }] = useMutation(JOIN_THREADS)
    const [sendMessage, { loading }] = useMutation(SEND_MESSAGE, {
        onCompleted: ({ sendMessage: { message } }) => {
            onSend && onSend()
            const _threadId = message.thread.id

            if (sendAsEmail) {
                sendMessageAsEmail(message.id)
                setSendAsEmail(false)
            }

            // if (message && uploadedFile) {
            //     addAttachmentTag({
            //         variables: { attachmentId: uploadedFile.id, objectId: message.id, contentTypeStr: 'messenger | message' },
            //         onCompleted: () => setUploadedFile(null)
            //     })
            // }
            
            markAsRead({ variables: { threadIds: [_threadId] } })

            if (!preventRedirect && (activeTab !== 'thread' || (fullVariant && activeId !== _threadId))) {
                history.push(`${tabNavLinks.messenger.dynamicPath}${_threadId}`)
            }
        }
    })
    const [updateMessage] = useMutation(UPDATE_MESSAGE, { onCompleted: onUpdate })

    const onUploadInserted = (file, response) => {
        response && setUploadedFile(JSON.parse(response))
    }
    
    const onSubmit = async(body) => {
        const mentionIds = body?.match(/data-mention-id="(.*?)"/g)?.map(item => item.match(/^data-mention-id="(.*)"/)[1]) || [];

        if (!isMember && !isNotificationThread && threadId) {
            await joinThreads({ variables: {
                threadIds: [threadId],
                subscribe: true
            } })
        }

        (body || fwdMsgId) && !msg && sendMessage({
            variables: { title, body, threadId, contactIds, refMsgId, fwdMsgId, isNotification, sendAsEmail, mentionIds },
            update(cache, { data: { sendMessage } }) {
                if (sendMessage?.message) {
                    cache.modify({
                        id: cache.identify({ __typename: 'ThreadType', id: sendMessage.message.thread.id }),
                        fields: {
                            messages(existingMsgs = [], { toReference, readField }) {
                                if (existingMsgs.length && sendMessage.message.id === readField('id', existingMsgs[existingMsgs.length-1])) {
                                    return existingMsgs
                                }
                                return [...existingMsgs, toReference(sendMessage.message)];
                            },
                            isEmpty() {
                                return false
                            }
                        }
                    })
                }
            }
        })
        body && msg && updateMessage({ variables: { id: msg.id, body, mentionIds } })
        setBody('')
        setTitle('')
    }

    const uploadURL = window._env_.REACT_APP_REST_ENDPOINT + '/upload/'
    const uploadParams = {
        csrfmiddlewaretoken: getCsrfCookie(),
        // content_type_str: 'messenger | thread',
        // object_id: threadId
    }
    const editorConfig = {
        placeholderText: isMember || fwdMsgId ? lbl.newMsgPlaceholder : lbl.newMsgPlaceholderNonMember,
        imageUploadURL: uploadURL,
        videoUploadURL: uploadURL,
        fileUploadURL: uploadURL,

        // send httpOnly cookies stored in the browser
        requestWithCredentials: true,
        imageUploadParams: uploadParams,
        videoUploadParams: uploadParams,
        fileUploadParams: uploadParams,

        fileMaxSize: 1024 * 1024 * 20,
        imageDefaultWidth: 300,
        imageMaxSize: 1024 * 1024 * 20,
        imageResize: false,
        imageStyles: { 'fr-rounded': 'Rounded' },
        videoAllowedTypes: ['quicktime', 'mp4', 'webm', 'ogg'],
        videoMaxSize: 1024 * 1024 * 200,
        videoResponsive: true,
        videoSplitHTML: true,
        events: {
            'keydown': ({ key, shiftKey, altKey, target }) => {
                if (key === 'Enter' && !shiftKey && !altKey && !isNotification && !sendAsEmail) {
                    if (!!target.innerText.trim() || target.innerHTML.includes('=')) {
                        const body = target?.innerHTML?.replace(/<p><br><\/p>+$/, '').replace(/&nbsp;<\/p>+$/, '</p>')
                        if (!msgBody) setBody(body)
                        body && onSubmit(body)
                        return false
                    }
                }
            },
            'video.inserted': onUploadInserted,
            'image.inserted': onUploadInserted,
            'file.inserted': onUploadInserted,
            'video.error': (error) => {
                if (error.message) {
                    if (error.code === 5) {
                        error.message += ' Max allowed video size is 200MB.'
                    }
                    editorRef.current.popups.areVisible()
                        .find('.fr-video-progress-bar-layer.fr-error .fr-message')
                        .text(error.message);
                }
            },
            'image.error': (error) => {
                if (error.message) {
                    if (error.code === 5) {
                        error.message += ' Max allowed image size is 20MB.'
                    }
                    editorRef.current.popups.areVisible()
                        .find('.fr-image-progress-bar-layer.fr-error .fr-message')
                        .text(error.message);
                }
            },
            'file.error': (error) => {
                if (error.message) {
                    if (error.code === 5) {
                        error.message += ' Max allowed file size is 20MB.'
                    }
                    editorRef.current.popups.areVisible()
                        .find('.fr-file-progress-bar-layer.fr-error .fr-message')
                        .text(error.message);
                }
            },
        }
    }

    const getInputLabel = () => [
        isNotification && lbl.notificationTitle,
        sendAsEmail && lbl.mailSubject
    ].filter(Boolean).join(' / ')

    if (!fullVariant) {
        editorConfig['toolbarButtons'] = ['insertLink', 'insertFile', 'insertImage', 'insertTemplate', 'emoticons']
    }

    const focusAtEnd = (scroll=false) => {
        editorRef.current.el.focus()
        setTimeout(() => {
            if (editorRef.current?.selection) {
                editorRef.current.selection.setAtEnd(editorRef.current.el)
                editorRef.current.selection.restore()
                scroll && scrollToBottom(scrollContainer?.current)
            }
        }, 100)
    }

    const handleManualController = ({ getEditor }) => {
        editorRef.current = getEditor()
        !sendAsEmail && !msg && focusAtEnd(true)
    }

    const saveDraft = () => {
        if ((draftRef.current.title || draftRef.current.body) || (newMessageDraft?.title || newMessageDraft?.body)) {
            newMessageDrafts(new Map([...newMessageDrafts(), [threadId, draftRef.current]]))
        }
    }

    useEffect(() => {
        if (!msgBody && msg?.body) {
            setBody(msg.body)
        }
        (msgBody || msg?.body) && editorRef?.current && focusAtEnd()
    }, [msg?.body])

    useEffect(() => {
        sendAsEmail && setTimeout(() => scrollToBottom(scrollContainer?.current), 100)
    }, [sendAsEmail])

    useEffect(() => {
        draftRef.current = {
            body: msgBody,
            title,
            isNotification,
            sendAsEmail
        }
    }, [msgBody, title, isNotification, sendAsEmail])

    useEffect(() => saveDraft, [])

    return (
        <>
            {(isNotification || sendAsEmail) &&
                <InputBox
                    label={getInputLabel()}
                    name='mgs-title'
                    onChange={e => setTitle(e.target.value)}
                    value={title}
                    autoFocus
                    classAdditions='mb-1'
                />
            }
            <div className={`msg-editor editor--${fullVariant ? 'full' : 'inline'}`}>
                <TextEditor
                    key={threadId + isMember + isNotification + sendAsEmail + msg?.body + refMsgId}
                    model={msgBody || ''}
                    onModelChange={setBody}
                    config={editorConfig}
                    immediateReactModelUpdate
                    handleManualController={handleManualController}
                    allowMentions
                />
                {fullVariant ?
                    <footer className='new-mgs__footer'>
                        {!msg &&
                            <div className='new-mgs__footer--left'>
                                {(isEmptyThread || !isNotification) &&
                                    <button
                                        className={`btn btn--naked btn--inline-icon${isNotification ? '' : ' active'}`}
                                        onClick={() => setIsNotification(false)}
                                        title='Send as chat message'
                                        disabled={!isEmptyThread}
                                    >
                                        <Icon type='message' variant='xs' />
                                    </button>
                                }
                                {(isEmptyThread || isNotification) &&
                                    <button
                                        className={`btn btn--naked btn--inline-icon${isNotification ? ' active' : ''}`}
                                        onClick={() => setIsNotification(true)}
                                        title='Send as notification message'
                                        disabled={!isEmptyThread}
                                    >
                                        <Icon type='message-notification' variant='xs' />
                                    </button>
                                }
                                <button
                                    className={`btn btn--naked btn--inline-icon${sendAsEmail ? ' active' : ''}`}
                                    onClick={() => setSendAsEmail(!sendAsEmail)}
                                    title='Send as e-mail'
                                >
                                    <Icon type='email' variant='xs' />
                                </button>
                                {/* <button
                                    className={`btn btn--naked btn--inline-icon${sendAsRegMail ? ' active' : ''}`}
                                    onClick={() => setSendAsRegMail(!sendAsRegMail)}
                                    title='Send as registered e-mail'
                                >
                                    <Icon type='registered-mail' variant='xs' />
                                </button>
                                <button
                                    className={`btn btn--naked btn--inline-icon${sendAsSms ? ' active' : ''}`}
                                    onClick={() => setSendAsSms(!sendAsSms)}
                                    title='Send as sms'
                                >
                                    <Icon type='sms' variant='xs' />
                                </button>
                                <button
                                    className={`btn btn--naked btn--inline-icon${showCallPopup ? ' active' : ''}`}
                                    onClick={() => setShowCallPopup(!showCallPopup)}
                                    title='Call'
                                >
                                    <Icon type='call' variant='xs' />
                                </button> */}
                            </div>
                        }
                        <div className='new-mgs__footer--right'>
                            {msg &&
                                <button
                                    className='btn btn--secondary mr-1'
                                    onClick={() => {
                                        onUpdate()
                                        setBody('')
                                    }}
                                >
                                    {lbl.cancel}
                                </button>
                            }
                            <button
                                className='btn btn--primary'
                                onClick={() => onSubmit(msgBody)}
                                disabled={loading || joining || (!threadId && !contactIds?.length) || (!msgBody && !fwdMsgId) || ((isNotification || sendAsEmail) && !title)}
                            >
                                {loading || joining ? <span className='btn__loader'>&#8635;</span> : msg ? lbl.save : lbl.send}
                            </button>
                        </div>
                    </footer>
                :
                    <button
                        className='btn btn--naked btn--inline-icon send-btn'
                        onClick={() => onSubmit(msgBody)}
                        disabled={loading || joining || !msgBody}
                    >
                        <Icon type='send' variant='xs' style={(loading || joining) ? {animation: 'spin 1s infinite linear'} : undefined} />
                    </button>
                }
            </div>
            {/* {showCallPopup &&
                <Popover isOpen onClose={() => setShowCallPopup(false)}>
                    <CallContact />
                </Popover>
            } */}
        </>
    )
}

export default MessageEditor
