import { useState } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'
import { GET_BUILDING_SERVICES, GET_ADDRESS_SERVICES, GET_ROOM_SERVICES } from '../queries'
import { ADD_SERVICE } from '../mutations'
import { lbl } from '../../constants'
import { newDraftsVar } from '../../cache'
import ServiceInfo from './ServiceInfo'
import Accordion from '../../components/Accordion'
import ServiceInfoForm from './ServiceInfoForm'

const PropertyServices = ({ contentType, objectId }) => {
    const queriesMap = {
        building: GET_BUILDING_SERVICES,
        address: GET_ADDRESS_SERVICES,
        room: GET_ROOM_SERVICES
    }
    const { loading, data } = useQuery(queriesMap[contentType], { variables: { id: objectId } })
    const [newServiceOpen, setNewServiceOpen] = useState(false)
    let servicesDrafts = useReactiveVar(newDraftsVar)[`${contentType}Services`]
    let draft = servicesDrafts[objectId] || {}
    const services = data && data[contentType] ? data[contentType][`${contentType}Services`] : []

    return (
        <>
            {loading && <div className='loader pos-center-y'/>}
            {newServiceOpen ?
                <Accordion
                    isOpen
                    Tag='div'
                    className='bg-lightblue p-1 br-05 mb-1'
                    headerClass='accordion__header--bordered t-md t-heading mr-3'
                    label={lbl.newService}
                    onOpenCb={() => { setNewServiceOpen(false) }}
                >
                    <div className='ml-4 mr-4 mb-2'>
                        <ServiceInfoForm
                            draft={{ ...draft, [`${contentType}Id`]: objectId }}
                            submitMutation={ADD_SERVICE}
                            mutationVariables={{ [`${contentType}Id`]: objectId }}
                            onCompleted={() => {}}
                            close={() => { setNewServiceOpen(false) }}
                            setDraft={(serviceDraft) => {
                                const drafts = { ...newDraftsVar()}
                                drafts[`${contentType}Services`][objectId] = serviceDraft
                                newDraftsVar(drafts)
                            }}
                        />
                    </div>
                </Accordion>
            :
                <button
                    className='btn btn--naked btn--new-item w-100 mb-2'
                    onClick={() => { setNewServiceOpen(true) }}
                >
                    <span className="plus">+</span>
                    {lbl.addNewService}
                </button>
            }
            {services.map((service) => (
                <ServiceInfo
                    key={'service' + service.id}
                    service={service}
                    propertyId={contentType === 'address' ? objectId : undefined}
                />
            ))}
        </>
    )
}

export default PropertyServices
