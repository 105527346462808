import { Link, useLocation } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { activeAppVar } from '../cache'
import { tabNavLinks } from '../constants'
import SiteSearch from '../search/components/SiteSearch'
import Icon from './Icon'

const Header = () => {
    const activeApp = useReactiveVar(activeAppVar)
    const { pathname } = useLocation()
    const activeTab = pathname.split('/')[2]
    const newTab = (activeApp && tabNavLinks[activeApp]?.new) || (activeApp && activeTab && tabNavLinks[activeApp] && tabNavLinks[activeApp][activeTab]?.new)

    return (
        <header className='header ml-r'>
            <SiteSearch/>
            <div>
                {newTab && newTab.relativeUrl && pathname !== newTab.relativeUrl &&
                    <Link
                        to={newTab.relativeUrl}
                        className='btn btn--primary btn--inline-icon btn--rounded t-none'
                    >
                        <Icon type='plus' variant='sm' />
                        {newTab.btnLabel}
                    </Link>
                }
            </div>
        </header>
    )
}

export default Header