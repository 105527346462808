import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '../fragments'


export const PIPELINE_STAGE_FRAGMENT = gql`
    fragment PipelineStageFields on PipelineStageType {
        id
        name
        isFirst
        isLast
        order
    }
`

export const LABEL_FRAGMENT = gql`
    fragment LabelFields on LabelType {
        id
        name
        color
    }
`

export const CREATED_DATE_FRAGMENT = gql`
    fragment CreatedDateFields on TicketType {
        createdDate
        createdDateLabel
        createdDateOffset
    }
`

export const BASE_TICKET_FRAGMENT = gql`
    fragment BaseTicketFields on TicketType {
        id
        name
        priority
        archived
        ...CreatedDateFields
        createdBy {
            ...UserFields
        }
        assignee {
            ...UserFields
        }
        owner {
            ...UserFields
        }
        labels {
            ...LabelFields
        }
        category {
            id
            name
            userGroup {
                id
                name
            }
        }
    }
    ${CREATED_DATE_FRAGMENT}
    ${USER_FRAGMENT}
    ${LABEL_FRAGMENT}
`

export const TICKET_TASK_FRAGMENT = gql`
    fragment TicketTaskFields on TaskType {
        id
        name
        assignee {
            ...UserFields
        }
        status
        priority
        dueDate
        dueDateOffset
        dueDateLabel
        approvalRequired
    }
    ${USER_FRAGMENT}
`

export const FULL_TICKET_FRAGMENT = gql`
    fragment AllTicketFields on TicketType {
        id
        name
        description
        priority
        ...CreatedDateFields
        createdBy {
            ...UserFields
        }
        owner {
            ...UserFields
            email,
            profile {
                id
                phoneNumber
            }
            tenant {
                id
                activeTenancyAgreement {
                    id
                    room {
                        id
                        fullUnitName
                    }
                    address {
                        id
                        streetWithHouseNumber
                        postcode
                        building {
                            id
                            city {
                                id
                                name
                            }
                        }
                    }
                }
                activeLead {
                    id
                    room {
                        id
                        fullUnitName
                    }
                    address {
                        id
                        streetWithHouseNumber
                        postcode
                        building {
                            id
                            city {
                                id
                                name
                            }
                        }
                    }
                }
                address @client
                unit @client
            }
        }
        assignee {
            ...UserFields
        }
        labels {
            ...LabelFields
        }
        category {
            id
            name
            userGroup {
                id
                name
            }
        }
        archived
        deleted @client
        pipelineStage {
            id
            name
            isFirst
            isLast
            order
            pipeline {
                id
                name
            }
        }
        pipeline {
            id
            name
            pipelineStages {
                id
                name
            }
        }
        templateForGroup {
            id
            name
        }
        reviewerGroup {
            id
            name
        }
        threads {
            id
            name
            archived
            unreadCount
            isUnread
            parent {
                id
            }
        }
        unreadMessageCount @client
    }
    ${CREATED_DATE_FRAGMENT}
    ${USER_FRAGMENT}
    ${LABEL_FRAGMENT}
`
