import { useLocation, useHistory } from 'react-router-dom'
import { useMutation, useApolloClient } from '@apollo/client'
import { ARCHIVE_METER_READING, UPDATE_METER_READING } from '../mutations'
import Form from '../../forms/Form'
import { lbl } from '../../constants'
import { useState } from 'react'
import { postAsFormData } from '../../utils'
import { Icon, Popover } from '../../components'


const MeterReadingForm = ({
    meterReading={},
    draft,
    submitMutation=UPDATE_METER_READING,
    mutationVariables={},
    close=()=>{},
    setDraft
}) => {
    const history = useHistory()
    const { pathname } = useLocation()
    const client = useApolloClient()
    const initialState = draft || { ...meterReading }
    const [uploading, setUploading] = useState(false)
    const [uploadError, setUploadError] = useState('')
    const [uploadSuccess, setUploadSuccess] = useState(false)
    const [showBigImage, setShowBigImage] = useState(false)
    if (!initialState.date) initialState['date'] = new Date().toISOString().substring(0, 10)
    const [updateMeterReading, { loading, done, called, data, error }] = useMutation(submitMutation)
    const [archiveMeterReading, { loading: archiving }] = useMutation(ARCHIVE_METER_READING)

    const success = !error && !uploadError && !loading && !uploading && (uploadSuccess || (called && !!data?.updateMeterReading?.meterReading))

    const fields = [
        { name: 'date', type: 'date', required: true },
        { name: 'value', type: 'number', required: true, step: '0.001' },
        {
            name: 'picture',
            id: 'picture_'+ meterReading.id,
            lblKey: meterReading.picture ? 'changePicture' : 'uploadPicture',
            type: 'file',
            accept:'.jpg, .jpeg, .png',
            value: undefined,
            wide: true,
            onChangeCb: () => uploadError && setUploadError(''),
            AfterNode: () => meterReading.picture ? <img src={meterReading.picture} onClick={() => setShowBigImage(true)} style={{ maxHeight: '250px', maxWidth: '100%', borderRadius: '16px', opacity: uploading ? 0.5 : 1 }} alt="meter reading" className='mb-2' /> : null
        }
    ]

    const cleanAndClose = (args) => {
        if (setDraft) setDraft({})
        if (!!args?.addMeterReading) {
            const url = `${pathname}#meter_reading_${args.addMeterReading.meterReading?.id}`
            history.push(url)
            close()
        }
    }

    const uploadImage = (file, meterReadingId, args) => {
        setUploading(true)
        postAsFormData({
            url: '/upload_meter_reading_picture/',
            data: {'file': file, 'meter_reading_id': meterReadingId},
            onError: (err) => setUploadError(err.message),
            onSuccess: (picture) => {
                client.cache.modify({
                    id: client.cache.identify({
                        __typename: 'MeterReadingType',
                        id: meterReadingId
                    }),
                    fields: { picture() { return picture } }
                })
                setUploadSuccess(true)
                cleanAndClose(args)
            },
            onDone: () => setUploading(false)
        })
    }

    return (
        <>
            <Form
                fields={fields}
                initialState={initialState}
                onSubmit={(data, cancelChanges) => {
                    const { picture, ..._data } = data

                    if (Object.keys(_data).length) {
                        updateMeterReading({
                            variables: { id: meterReading.id, ..._data, ...mutationVariables },
                            onError: cancelChanges,
                            onCompleted: (args) => {
                                if (picture) {
                                    uploadImage(picture, meterReading.id || args?.addMeterReading?.meterReading.id, args)
                                } else {
                                    cleanAndClose(args)
                                }
                            }
                        })
                    } else if (picture) {
                        uploadImage(picture, meterReading.id)
                    }
                }}
                onCancel={close}
                isSubmitting={archiving || uploading || (loading && !done)}
                disabled={meterReading.archived}
                isSuccess={success}
                setDraft={setDraft}
                formClassName='form--column--2'
                error={error?.message || uploadError}
                actions={(meterReading.id && !meterReading.archived) ? [{
                    label: lbl.archive,
                    onClick: () => {
                        if (window.confirm('Are you sure you want to archive this meter reading?')) {
                            archiveMeterReading({ variables: { id: meterReading.id }})
                        }
                    }
                }] : []}
            />
            {showBigImage &&
                <Popover
                    isOpen
                    className='popover--no-padding popover--image'
                    onClose={() => setShowBigImage('')}
                >
                    <>
                        <img src={meterReading.picture} alt='lightbox'/>
                        <a
                            href={meterReading.picture}
                            download
                            className='download'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <Icon type='arrow-right' variant='xs' style={{ rotate: '-45deg'}} />
                        </a>
                    </>
                </Popover>
            }
        </>
    )
}

export default MeterReadingForm
