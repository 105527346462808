import { gql } from '@apollo/client'

export const MARK_NOTIFICATION_READ = gql`
    mutation markNotificationRead($ids: [ID]!) {
        markNotificationRead(ids: $ids) {
            updatedIds
        }
    }
`

export const MARK_ALL_NOTIFICATIONS_READ = gql`
    mutation marAllNotificationsRead {
        markAllNotificationsRead {
            ok
        }
    }
`
