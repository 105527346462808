import { useQuery, useReactiveVar } from '@apollo/client'
import { userTicketListCtrlsVar } from '../../cache'
import { GET_USER_TICKETS } from '../../tickets/queries'
import TicketList from '../../tickets/components/TicketList'

const columns = ['pipelineStage', 'name', 'assignee', 'priority', 'createdDate', 'owner']

const UserTickets = ({ userId }) => {
    const vars = useReactiveVar(userTicketListCtrlsVar)
    const filters = vars.filters.map(f => `${f.field}___${f.id}`).sort().join(',')
    const { data, error, loading, fetchMore } = useQuery(GET_USER_TICKETS, {
        variables: { id: userId, ...vars, filters },
        notifyOnNetworkStatusChange: true
    })

    if (error) return <div className='pos-center-y w-100 t-center'>{error.message}</div>

    return (
        <TicketList
            tickets={data?.userTickets}
            controlsDisabled={loading}
            showLoader={loading && !data?.userTickets}
            columnKeys={columns}
            listCtrlVar={userTicketListCtrlsVar}
            query={GET_USER_TICKETS}
            queryFieldName='userTickets'
            queryVariables={{ id: userId }}
            fetchMore={fetchMore}
        />
    )
}

export default UserTickets
