import { useEffect, useContext, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { Link } from 'react-router-dom'
import { GET_PINNED_NOTES } from '../../notes/queries'
import { GET_TICKET } from '../queries'
import { UPDATE_TICKET } from '../mutations'
import { tabNavLinks, lbl, contentTypes } from '../../constants'
import { TabNavContext } from '../../components/TabNav'
import TextEditor from '../../components/TextEditor'
import { TextField } from '../../forms'
import { Label, Icon } from '../../components'
import TicketSidePanel from './TicketSidePanel'
import TicketDetailNav from './TicketDetailNav'
import TicketDetailRouter from './TicketDetailRouter'
import PinnedNotePreview from '../../notes/components/PinnedNotePreview'

const TicketDetail = () => {
    const { id, pinnedTabs, openTab, renameTab, activeSubtab } = useContext(TabNavContext)
    const [showEditor, setShowEditor] = useState(false)
    const [body, setBody] = useState('')
    const { loading, error, data, called } = useQuery(GET_TICKET, {
        variables: { id },
        onCompleted: ({ ticket }) => {
            !body && setBody(ticket.description)
        }
    })
    const [updateTicket] = useMutation(UPDATE_TICKET)
    const { data: pinnedNotesData } = useQuery(GET_PINNED_NOTES, { 
        variables: { contentType: contentTypes.TICKET, objectId: id }
    })
    const relativeUrl = tabNavLinks.tickets.dynamicPath + id
    const isPinned = pinnedTabs.get(relativeUrl)
    const name = data?.ticket?.name
    const isEditable = data?.ticket && !data.ticket.archived && !data.ticket.deleted
    const isLister = activeSubtab === 'attachments'

    // TODO: memoize openTab to add to dependencies list
    useEffect(() => {
        called && name && !isPinned && openTab({ id, name, relativeUrl, icon: 'ticket' })
    }, [called, name, id, isPinned])

    if (loading) return <div className='loader pos-center-y' />
    if (error) return (
        <div className='t-grey w-100 pos-center-y'>
            <center>{error.message}</center>
        </div>
    )

    return (
        <div
            className='split-panel'
            onMouseDown={showEditor ? () => {
                setShowEditor(false)
            } : undefined}
        >
            <section className='split-panel--main-dual intersection-root ticket'>
                <div className='split-panel--main-top'>
                    {data.ticket.deleted ?
                        <Label
                            classModifier='grey'
                            className='label--uppercase label--archived'
                            iconProps={{ type: 'bin', variant: '3xs' }}
                            label={lbl.deleted}
                        />
                    : data.ticket.archived ?
                        <Label
                            classModifier='grey'
                            className='label--uppercase label--archived'
                            iconProps={{ type: 'archive', variant: '3xs' }}
                            label={lbl.archived}
                        />
                    : null}
                    <TextField
                        onSubmit={async value => {
                            await updateTicket({ variables: { id, name: value } })
                            renameTab({ id, name: value, relativeUrl })
                        }}
                        inputProps={{
                            type: 'input',
                            name: 'name',
                            value: data.ticket.name,
                            role: 'textbox',
                            contentEditable: isEditable,
                            placeholder: lbl.ticketTitle
                        }}
                        size='2xl'
                        as='div'
                        innerHtml={data.ticket.name}
                        hidePencil={!isEditable}
                    />
                    <div>
                        {pinnedNotesData?.notesForContentObject?.map(note => (
                            <PinnedNotePreview
                                key={`${note.pinned ? 'pinned-' : ''}note-${note.id}`}
                                note={note}
                                path={`${tabNavLinks.tickets.dynamicPath}${id}`}
                            />
                        ))}
                    </div>
                    <div className='w-100' onMouseDown={e => e.stopPropagation()}>
                        {!showEditor ?
                            <div onClick={isEditable ? e => setShowEditor(true) : undefined}>
                                <br/>
                                <TextField
                                    inputProps={{
                                        type: 'input',
                                        name: 'description',
                                        value: data.ticket.description,
                                        role: 'textbox',
                                        contentEditable: false,
                                        placeholder: lbl.addDetails
                                    }}
                                    size='md'
                                    as='div'
                                    innerContent='innerHTML'
                                    innerHtml={data.ticket.description}
                                    hidePencil={!isEditable}
                                />
                            </div>
                        :
                            <div className='ticket__editor bg-lightblue'>
                                <TextEditor
                                    model={body}
                                    onModelChange={setBody}
                                    config={{
                                        placeholderText: lbl.addDetails,
                                        toolbarButtons: ['bold', 'italic', 'underline', 'backgroundColor', '|', 'formatOL', 'formatUL', 'outdent', 'indent', 'align', '|', 'insertTemplate', 'insertLink', 'emoticons', '|', 'selectAll', 'clearFormatting', 'undo', 'redo', '|', 'saveChanges', 'cancelEditing'],
                                    }}
                                    immediateReactModelUpdate
                                    focusOnMount
                                    saveCommand='saveChanges'
                                    onSave={async () => {
                                        await updateTicket({ variables: { id, description: body } })
                                        setShowEditor(false)
                                    }}
                                    onCancel={() => {
                                        setBody(data.ticket.description)
                                        setShowEditor(false)
                                    }}
                                />
                            </div>
                        }
                        <br/>
                    </div>
                </div>
                
                <div className='split-panel--main-nav'>
                    <TicketDetailNav ticketId={id} ticket={data.ticket} />
                </div>

                <div className={`split-panel--main-bottom${isLister ? ' lister' : ''}`}>
                    <TicketDetailRouter ticket={data.ticket} ticketId={id} isEditable={isEditable} />
                </div>
            </section>
            <TicketSidePanel key={data.ticket.id} ticket={data.ticket} />
        </div>
    )
}

export default TicketDetail
