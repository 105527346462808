import { useState, useRef, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { GET_ARCHIVED_THREADS } from '../queries'
import ThreadList from './ThreadList'
import { OnScrollLoader } from '../../components'


const ArchivedOverview = () => {
    const containerEl = useRef(null)
    const [shouldFetchMore, setFetchMore] = useState(false)
    const { data, loading, error, fetchMore } = useQuery(GET_ARCHIVED_THREADS, {
        variables: { limit: 30 },
    })

    const onScroll = () => {                
        fetchMore({
            variables: { limit: 30, offset: data?.archivedThreads?.length }
        }).then(fetchMoreResult => {
            if (fetchMoreResult.data?.archivedThreads) {
                if (fetchMoreResult.data.archivedThreads.length === 0) {
                    setFetchMore(false)
                }
            }
        })
    }

    useEffect(() => {
        if (data?.archivedThreads) {
            if (data.archivedThreads.length) {
                const hasMore = !!data.archivedThreads[data.archivedThreads.length - 1].hasMore
                if (hasMore !== shouldFetchMore) setFetchMore(hasMore)
            }
        }
    }, [data?.archivedThreads])

    return (
        <div className='split-panel--main p-0' ref={containerEl} style={{ overflowY: 'scroll' }}>
            {(data && !!data.archivedThreads.length) ?
                <div>
                    <ThreadList threads={data.archivedThreads} noReply accordionClosed />
                    {shouldFetchMore && !loading &&
                        <OnScrollLoader
                            onIntersect={onScroll}
                            root={containerEl.current}
                        />
                    }
                </div>
            : loading ?
                <div className='loader pos-center-y' />
            : error ?
                <div className='t-red t-xs'>{error}</div>
            : (data && data.archivedThreads.length === 0) ?
                <div className='t-grey w-100 mx-auto my-auto'>
                    <center>You have no archived threads</center>
                </div>
            : null}
        </div>
    )
}

export default ArchivedOverview
