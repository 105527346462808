import React from 'react'
import { useHistory } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { tabNavLinks, callStatusOptions, callDirectionOptions } from '../../constants'
import { ProfileImage, Label, HighlightedText, Icon } from '../../components'
import { toTitleCase } from '../../utils'
import { currentUserVar } from '../../cache'

const CallListItem = ({
    callSid,
    direction,
    caller,
    fromNumber,
    receiver,
    toNumber,
    status,
    missed,
    subject='',
    recordingUrl,
    dateTime,
    dayLabel,
    timeLabel,
    duration,
    outsideOfficeHours,
    isEmergency,
    department,
    showDepartmentIcon,
    propKeys,
    listCtrlVar
}) => {
    const history = useHistory()
    let { searchTerm } = useReactiveVar(listCtrlVar)
    const currentUser = currentUserVar()
    const highlight = missed && (!department || !currentUser.department || currentUser.department.id === department.id)
    const isDone = status !== 'initiated' && status !== 'ringing' && status !== 'in-progress'

    return (
        <tr
            onClick={() => history.push(`${tabNavLinks.calls.dynamicPath}${callSid}`)}
            className={`lister__row${highlight ? ' bg-lightblue' : ''}`}
        >
            {propKeys.map((key, i) => (
                <React.Fragment key={key + i}>
                    {key === 'direction' ? (
                        <td className='lister__cell'>
                            <div className='d-flex'>
                                {showDepartmentIcon && department.icon}
                                <Icon
                                    type={callDirectionOptions[direction].icon}
                                    variant='xs'
                                    wrapperClassAdditions='ml-auto'
                                    fill={highlight ? '#367BF5' : '#78909C'}
                                />
                            </div>
                        </td>
                    ) : key === 'status' ? (
                        <td className='lister__cell'>
                            {!!callStatusOptions[status] &&
                                <Label
                                    classModifier={callStatusOptions[status].color}
                                    label={<HighlightedText text={callStatusOptions[status].label} mark={searchTerm} />}
                                    iconProps={{ type: callStatusOptions[status].icon }}
                                />
                            }
                        </td>
                    ) : key === 'caller' ? (
                        <td className='lister__cell t-sm lister__cell--200 t-left'>
                            {caller ?
                                <div className='lister-cell__wrapper'>
                                    <ProfileImage
                                        url={caller.profile.picture}
                                        size='xs'
                                    />
                                    <div className='d-inline-block ml-1'>
                                        <HighlightedText text={caller.fullName} mark={searchTerm} />
                                    </div>
                                </div>
                            : fromNumber}
                        </td>
                        ) : key === 'receiver' ? (
                            <td className='lister__cell t-sm lister__cell--200'>
                                {receiver ?
                                    <div className='lister-cell__wrapper'>
                                        <ProfileImage
                                            url={receiver.profile.picture}
                                            size='xs'
                                        />
                                        <div className='d-inline-block ml-1'>
                                            <HighlightedText text={receiver.fullName} mark={searchTerm} />
                                        </div>
                                    </div>
                                : toNumber}
                            </td>
                        ) : key === 'department' ? (
                            <td className='lister__cell t-sm'>
                                {department &&
                                    <Label label={
                                        <div>
                                            {department.icon ? `${department.icon} ` : ''}
                                            <HighlightedText text={toTitleCase(department.name)} mark={searchTerm}/>
                                        </div>
                                    }/>
                                }
                            </td>
                    ) : key === 'dateTime' ? (
                        <td className='lister__cell'>
                            {dateTime &&
                                <Label
                                    className='time-label'
                                    iconProps={{ type: outsideOfficeHours ? 'clock-alert' : 'clock' }}
                                    label={`${dayLabel}, ${timeLabel}`}
                                    classModifier={isEmergency ? 'red' : 'grey'}
                                />
                            }
                        </td>
                    ) : key === 'subject' ? (
                        <td className='lister__cell'>
                            {subject && <HighlightedText text={subject} mark={searchTerm} />}
                        </td>
                    ) : key === 'duration' ? (
                        <td className='lister__cell'>
                            {isDone &&
                                <Label
                                    className='time-label'
                                    iconProps={{ type: 'clock-sand' }}
                                    label={duration > 59 ? `${Math.round(duration/60)} min` : `${duration} sec`}
                                    classModifier={duration ? 'green' : 'red'}
                                />
                            }
                        </td>
                    ) : key === 'recordingUrl' ? (
                        <td className='lister__cell'>
                            {recordingUrl &&
                                <div className='audio__wrapper' onClick={e => e.stopPropagation()}>
                                    <audio controls preload='none'>
                                        <source src={recordingUrl} />
                                    </audio>
                                </div>
                            }
                        </td>
                    ) : null}
                </React.Fragment>
            ))}
        </tr>
    )
}

export default CallListItem
