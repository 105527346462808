import { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useMutation, useLazyQuery } from '@apollo/client'
import { UPDATE_TENANCY_AGREEMENT } from '../mutations'
import { GET_PROPERTY_TENANTS_PER_ROOM, GET_TENANTS } from '../queries'
import Form from '../../forms/Form'

const prefillCostFields = ({ draft, contract={}, unitId, units=[], property: _property, isUnitContract: _isUnitContract, changedPrefilledFields=[] }) => {
    const isUnitContract = _isUnitContract || contract.unit?.id
    const activeTenancyAgreements = contract.contractTenancyAgreements?.filter(ta => ta.active && !ta.archived) || []
    const contractTenantsCount = activeTenancyAgreements.length + 1
    let unit
    let prefilledFields = {}
    
    if (unitId || draft?.unitId || isUnitContract || units.length === 1) {
        const taUnitId = unitId || draft?.unitId || contract.unit?.id || units[0].id
        unit = units.find(u => u.id === taUnitId)
    }

    const property = _property || contract.property || unit?.property
    const propertyServiceCost = property?.monthlyServiceCost || (!isUnitContract && contract.monthlyServiceCost) || undefined
    const propertyMonthlyRent = !isUnitContract ? contract.monthlyRent : undefined
    const propertyDeposit = !isUnitContract ? contract.deposit : undefined
    const propertyOtherCosts = property?.otherCosts || (!isUnitContract && contract.otherCosts) || undefined

    let rent, serviceCosts, deposit, otherCosts

    if (unit) {
        const propertyCostsDivider = property?.serviceCostDivisionModel === 'SQM' && property.sizeSqm && unit.sizeSqm ? property.sizeSqm / unit.sizeSqm : property?.units?.length || units.length
        rent = (isUnitContract && contract.monthlyRent) ? contract.monthlyRent : unit.monthlyBasicRent ? unit.monthlyBasicRent : propertyMonthlyRent ? propertyMonthlyRent / propertyCostsDivider : undefined
        serviceCosts = (isUnitContract && contract.monthlyServiceCost) ? contract.monthlyServiceCost : unit.monthlyServiceCost ? unit.monthlyServiceCost : propertyServiceCost ? propertyServiceCost / propertyCostsDivider : undefined
        deposit = (isUnitContract && contract.deposit) ? contract.deposit : unit.deposit ? unit.deposit : propertyDeposit ? propertyDeposit / propertyCostsDivider : undefined
        otherCosts = (isUnitContract && contract.otherCosts) ? contract.otherCosts : propertyOtherCosts ? propertyOtherCosts / propertyCostsDivider : undefined
    } else {
        rent = propertyMonthlyRent
        serviceCosts = propertyServiceCost
        deposit = propertyDeposit
        otherCosts = propertyOtherCosts
    }

    if (!changedPrefilledFields.includes('monthlyRent')) prefilledFields['monthlyRent'] = rent ? Math.round(rent / contractTenantsCount) : ''
    if (!changedPrefilledFields.includes('monthlyServiceCost')) prefilledFields['monthlyServiceCost'] = serviceCosts ? Math.round(serviceCosts / contractTenantsCount) : ''
    if (!changedPrefilledFields.includes('deposit')) prefilledFields['deposit'] = deposit ? Math.round(deposit / contractTenantsCount) : ''
    if (!changedPrefilledFields.includes('otherCosts')) prefilledFields['otherCosts'] = otherCosts ? Math.round(otherCosts / contractTenantsCount) : ''
    
    return prefilledFields
}

const TenancyAgreementForm = ({
    tenancyAgreement={},
    units=[],
    contract,
    tenant: _tenant,
    property,
    propertyId,
    contractId,
    unitId,
    draft,
    submitMutation=UPDATE_TENANCY_AGREEMENT,
    close,
    setDraft,
    isUnitContract,
    extraFields=[],
    disableFields=[],
    disableAllFields
}) => {
    const history = useHistory()
    const { pathname } = useLocation()
    const [getTenants, {data: tenantsData, error: tenantsError, loading: tenantsLoading, called: tenantsCalled }] = useLazyQuery(GET_TENANTS)
    const tenant = _tenant || tenancyAgreement.tenant || {}
    const [changedPrefilledFields, setChanged] = useState([])
    const initialCosts = prefillCostFields({ draft, contract, unitId, units, property, isUnitContract })
    const [prefilledCosts, setPrefillCosts] = useState(initialCosts)
    const initialState = draft ? draft : {
        tenantId: tenant.id,
        unitId: tenancyAgreement.unit?.id,
        ...tenancyAgreement
    }
    let [isIndefinite, setIsIndefinite] = useState(initialState.forIndefinitePeriod)

    const [updateTenancyAgreement, { loading, done, called, data, error, client }] = useMutation(submitMutation)

    const success = !error && !loading && called && !!data?.updateTenancyAgreement?.tenancyAgreement
    
    let tenantOptions = tenantsData?.tenants ? tenantsData.tenants.map(({ id, user }) => ({ value: id, label: user.fullName })) : [
        { value: 'select tenant...', label: tenantsError?.message || 'Loading ...' }
    ]
    let unitOptions = units.map(({ id, fullUnitName }) => ({ value: id, label: fullUnitName }))
    const disableTenantField = disableAllFields || disableFields.includes('tenantId')
    const resetDraft = (selectedUnitId) => {
        setPrefillCosts(prefillCostFields({ draft, contract, unitId: selectedUnitId, units, property, isUnitContract, changedPrefilledFields }))
    }
    
    const fields = [
        { name: 'tenantId', lblKey: 'tenant', required: true, wide: true, disabled: disableTenantField, As: disableTenantField ? 'select' : 'search-select', options: tenantOptions, values: tenant.id ? [{ value: tenant.id, label: tenant.user?.fullName }] : undefined },
        { name: 'unitId', lblKey: 'unit', As: 'select', value: unitId || undefined, onChangeCb: (v) => resetDraft(v), options: unitOptions, disabled: disableAllFields || (isUnitContract && !!initialState.unitId) },
        { name: 'contractSignedDate', type: 'date', disabled: disableAllFields },
        { name: 'forIndefinitePeriod', lblKey: 'indefiniteTenancy', type: 'checkbox', onChangeCb: (v) => setIsIndefinite(v), BeforeNode: () => <div className='t-sm mt-2 mb-2'>Duration</div>, disabled: disableAllFields },
        { name: 'checkInDate', type: 'date', disabled: disableAllFields },
        { name: 'checkOutDate', type: 'date', disabled: isIndefinite || disableAllFields, ...isIndefinite && !initialState.checkOutDate ? { value: '' } : {} },
        { name: 'rentResponsabilityStartDate', type: 'date', required: true, disabled: disableAllFields },
        { name: 'rentResponsabilityEndDate', type: 'date', required: !isIndefinite, disabled: isIndefinite || disableAllFields, ...isIndefinite && !initialState.rentResponsabilityEndDate ? { value: '' } : {}, AfterNode: () => <><div className='t-sm mt-2 mb-2'>Costs</div><br/></> },
        ...['monthlyRent', 'monthlyServiceCost', 'otherCosts', 'deposit'].map(f => ({ 
            name: f,
            type: 'decimal',
            disabled: disableAllFields,
            onChangeCb: (v, isProgramaticChange) => !isProgramaticChange && setChanged(prev => [...prev, f]),
            ...!tenancyAgreement.id && !changedPrefilledFields.includes(f) && { programaticValue: prefilledCosts[f] }
        })),
        { name: 'depositReimbursed', type: 'checkbox' },
    ]

    useEffect(() => {
        if (!disableTenantField && !tenantsCalled && !tenantsData && !tenantsLoading) {
            getTenants()
        }
    }, [disableTenantField])

    return (
        <Form
            fields={[...extraFields, ...fields]}
            initialState={initialState}
            disabled={tenancyAgreement.archived}
            disableSubmit={disableAllFields}
            As={disableAllFields ? 'div' : 'form'}
            onSubmit={(data, cancelChanges) => {
                let _data = {...data}
                if (data.forIndefinitePeriod) {
                    _data['checkOutDate'] = ''
                    _data['rentResponsabilityEndDate'] = ''
                }
                if (draft) {
                    _data = { ...draft, _data }
                }
                updateTenancyAgreement({
                    variables: { id: tenancyAgreement.id, ..._data },
                    refetchQueries: propertyId ? [{ query: GET_PROPERTY_TENANTS_PER_ROOM, variables: { propertyId } }] : [],
                    onCompleted: ({ createTenancyAgreement }) => {
                        if (!!createTenancyAgreement) {
                            const newTenancyAgreement = createTenancyAgreement.tenancyAgreement
                            if (newTenancyAgreement) {
                                // add new tenancy agreement to cached contract
                                client.cache.modify({
                                    id: client.cache.identify({
                                        __typename: 'TenantContractType',
                                        id: contractId
                                    }),
                                    fields: {
                                        contractTenancyAgreements(existing, { toReference, readField }) {
                                            if (!existing || existing.some(ta => readField('id', ta) === newTenancyAgreement.id)) return existing
                                            return [...existing, toReference(newTenancyAgreement)]
                                        }
                                    }
                                })
                            }

                            const url = `${pathname}#contract_${contractId}_tenancy_agreement_${newTenancyAgreement?.id}`
                            history.push(url)
                            close()
                        }
                        setDraft && setDraft({})
                    },
                    onError: cancelChanges
                })
            }}
            onCancel={close}
            isSubmitting={loading && !done}
            isSuccess={success}
            setDraft={setDraft}
            error={error?.message}
            formClassName='form--column--2'
        />
    )
}

export default TenancyAgreementForm
