import React from 'react'

const InputBox = React.forwardRef(({
    label,
    value,
    name,
    onChange,
    classAdditions='',
    As='input',
    ...inputProps
}, ref) => (
    <fieldset className={`input-box ${classAdditions}`}>
        {label &&
            <label
                className='input-box__label'
                htmlFor={name}
            >
                {label}
            </label>
        }
        <As
            ref={ref}
            className='input-box__input'
            value={value}
            name={name}
            onChange={onChange}
            autoComplete='off'
            type='text'
            {...inputProps}
        />
    </fieldset>
))

export default InputBox
