import { useState } from 'react'
import Icon from './Icon'

const SelectIcon = ({ isSelected, isVisible, icon, onSelect, isDisabled }) => {
    const [hovered, setHovered] = useState(false)
    const iconType = isSelected ? 'checked' : hovered || isVisible ? 'check-circle' : icon    

    return (
        <button
            className='btn btn--round'
            title={isSelected ? 'deselect' : 'select'}
            role='checkbox'
            aria-checked={isSelected}
            onClick={onSelect}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            disabled={isDisabled}
        >
            <Icon
                type={iconType}
                variant='sm'
            />
        </button>
    )
}

export default SelectIcon
