import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { TabNavContext } from '../../components/TabNav'
import { lbl, tabNavLinks } from '../../constants'
import { Counter, Icon } from '../../components'

const TicketDetailNav = ({ ticketId, ticket }) => {
    const { activeSubtab } = useContext(TabNavContext)
    const urlRoot = `${tabNavLinks.tickets.dynamicPath}${ticketId}`

    return (
        <nav className='tab-nav'>
            <ul className='tab-nav__list'>
                <li className={`tab ${activeSubtab === 'updates' ? ' active' : ''}`}>
                    <Link to={`${urlRoot}/updates`}>
                        <Icon type='time' variant='xs' />
                        <span className='tab__label t-sm'>{lbl.updates}</span>
                    </Link>
                </li>
                <li className={`tab ${activeSubtab === 'threads' ? ' active' : ''}`}>
                    <Link to={`${urlRoot}/threads`}>
                        <div className='pos-relative'>
                            <Icon type='message' variant='xs' />
                            {!!ticket?.unreadMessageCount && <Counter count={ticket.unreadMessageCount} />}
                        </div>
                        <span className='tab__label t-sm'>{lbl.messages}</span>
                    </Link>
                </li>
                <li className={`tab ${activeSubtab === 'attachments' ? ' active' : ''}`}>
                    <Link to={`${urlRoot}/attachments`}>
                        <Icon type='attachment' variant='xs' />
                        <span className='tab__label t-sm'>{lbl.attachments}</span>
                    </Link>
                </li>
                <li className={`tab ${activeSubtab === 'notes' ? ' active' : ''}`}>
                    <Link to={`${urlRoot}/notes`}>
                        <Icon type='clipboard' variant='xs' />
                        <span className='tab__label t-sm'>{lbl.notes}</span>
                    </Link>
                </li>
            </ul>
        </nav>
    )
}

export default TicketDetailNav
