import { GET_CALLS } from '../queries'
import Lister from '../../components/Lister'
import { listerCtrlOptions, callProperties, listerColumns } from '../settings'
import { callListCtrlsVar } from '../../cache'
import { formatDateTimeLabel } from '../../utils'
import CallListItem from './CallListItem'

const CallsList = ({
    calls,
    fetchMore,
    hideControls,
    controlsDisabled,
    showLoader,
    queryFieldName='calls',
    queryVariables,
    query=GET_CALLS,
    ...props
}) => {
    const listCtrlVar = props.listCtrlVar || callListCtrlsVar
    const listCtrlOpts = props.listerCtrlOptions || listerCtrlOptions
    const columnKeys = props.columnKeys || listerColumns

    if (!calls && !showLoader) return <div className='loader pos-center-y'/>
    
    return (
        <div className='tab__panel--main intersection-root'>
            <Lister
                options={listCtrlOpts}
                optionsDict={callProperties}
                listerCtrlVar={listCtrlVar}
                columnKeys={columnKeys}
                items={calls}
                hideControls={hideControls}
                controlsDisabled={controlsDisabled}
                renderCtrlChildren={props.renderCtrlChildren}
                showLoader={showLoader}
                fetchMore={fetchMore}
                queryFieldName={queryFieldName}
                queryVariables={queryVariables}
                query={query}
                type='calls'
            >
                {(itemProps) => (
                    <CallListItem
                        propKeys={columnKeys}
                        listCtrlVar={listCtrlVar}
                        showDepartmentIcon={itemProps.department?.icon && columnKeys.indexOf('department') === -1}
                        {...formatDateTimeLabel(itemProps.dateTime)}
                        {...itemProps}
                    />
                )}
            </Lister>
        </div>
    )
}

export default CallsList
