import { useEffect, useState } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'
import { GET_PROPERTY_UNITS, GET_TENANT_LEADS } from '../../properties/queries'
import { GET_PROPERTIES } from '../queries'
import { lbl } from '../../constants'
import { getLeadAddress } from '../utils'
import { Label, DateString } from '../../components'
import LeadAccordion from '../../properties/components/LeadAccordion'
import { newDraftsVar } from '../../cache'
import { CREATE_LEAD } from '../../properties/mutations'


const TenantLead = ({ lead, properties, propertyError, onAccordionOpen }) => {
    const leadFor = lead ? lead.unit ? 'unit' : lead.property ? 'property' : '' : ''
    const { addressLabel, leadUrl } = getLeadAddress(lead)
    const [leadPropertyChanged, setLeadPropertyChanged] = useState(false)
    const units = lead.property?.rooms ? lead.property.rooms : lead.unit ? [lead.unit] : []

    const accordionHeader = (
        <div className='d-flex justify-space-between ml-05'>
            <div className='d-inline-flex w-100'>
                <Link
                    className='d-inline-flex justify-space-between ml-05 t-underline-hover no-wrap'
                    to={leadUrl}
                    title={`Go to lead on ${leadFor} page`}
                >
                    {addressLabel}
                </Link>
                {lead.archived &&
                    <Label
                        classModifier='grey'
                        className='ml-1 label--uppercase label--archived'
                        iconProps={{ type: 'archive', variant: '3xs' }}
                        label={lbl.archived}
                    />
                }
            </div>
            {lead.preferredCheckInDate &&
                <DateString dateStr={lead.preferredCheckInDate} />
            }
        </div>
    )


    return (
        <LeadAccordion
            lead={lead}
            accordionProps={{
                label: accordionHeader,
                onOpenCb: onAccordionOpen
            }}
            units={units}
            extraFields={[
                {
                    name: 'propertyId',
                    lblKey: 'property',
                    As: 'search-select',
                    wide: true,
                    options: properties ? properties.map(({ id, longName }) => ({ value: id, label: longName })) : [
                        { value: lead.property?.id, label: lead.property?.longName },
                        { value: 'property-x', label: propertyError?.message || 'Loading ...' }
                    ],
                    onChangeCb: (v) => setLeadPropertyChanged(v !== lead.property?.id)
                },
            ]}
            disableFields={leadPropertyChanged ? ['unitId'] : undefined}
            onCompleted={() => setLeadPropertyChanged(false)}
            propertyUpdated={leadPropertyChanged}
        />
    )
}


const TenantLeads = ({ tenant }) => {
    const history = useHistory()
    const { pathname, hash } = useLocation()
    const { loading, data, error } = useQuery(GET_TENANT_LEADS, { variables: { id: tenant.id } })
    const [getProperties, { data: propertyData, called: propertiesCalled, error: propertyError, loading: propertiesLoading }] = useLazyQuery(GET_PROPERTIES)
    const [newLeadOpen, setNewLeadOpen] = useState(hash === '#new')
    const [getPropertyUnits, { data: unitsData }] = useLazyQuery(GET_PROPERTY_UNITS)
    let properties = propertyData?.properties
    let units = unitsData?.property?.rooms || []
    let { tenantLeads } = useReactiveVar(newDraftsVar)
    let draft = tenantLeads[tenant.id] || {}

    useEffect(() => {
        if (draft.propertyId) {
            getPropertyUnits({ variables: { propertyId: draft.propertyId }})
        }
    }, [draft.propertyId])

    useEffect(() => {
        if (newLeadOpen && !propertiesCalled && !propertyData && !propertiesLoading) {
            getProperties()
        } else if (!newLeadOpen && hash === '#new') {
            history.replace(pathname)
        }
    }, [newLeadOpen])
    
    return (
        <>
            {newLeadOpen ?
                <LeadAccordion
                    accordionProps={{
                        isOpen: true,
                        className: 'bg-lightblue p-1 br-05 mb-1',
                        label: lbl.newLead,
                        onOpenCb: () => { setNewLeadOpen(false) }
                    }}
                    draft={{ ...draft, tenantId: tenant.id }}
                    units={units}
                    extraFields={[
                        {
                            name: 'propertyId',
                            lblKey: 'property',
                            As: 'search-select',
                            wide: true,
                            required: true,
                            options: properties ? properties.map(({ id, longName }) => ({ value: id, label: longName })) : [
                                { value: '', label: propertyError?.message || 'Loading ...' }
                            ],
                        },
                    ]}
                    disableFields={!draft.propertyId ? ['unitId'] : undefined}
                    propertyUpdated={draft.unitId && units.every(u => u.id !== draft.unitId)}
                    submitMutation={CREATE_LEAD}
                    close={() => { setNewLeadOpen(false) }}
                    setDraft={(leadDraft) => {
                        const drafts = { ...newDraftsVar()}
                        drafts.tenantLeads[tenant.id] = leadDraft
                        newDraftsVar(drafts)
                    }}
                />
            :
                <button
                    className='btn btn--naked btn--new-item w-100 mb-2'
                    onClick={() => setNewLeadOpen(true)}
                >
                    <span className="plus">+</span>
                    {lbl.addNewLead}
                </button>
            }
            {loading && <div className='p-3'><div className='loader w-100'/></div>}
            {error && <div className='error t-red w-100 t-center p-3'>{error.message}</div>}
            {data?.tenant?.leads?.map((lead) => (
                <TenantLead
                    key={`lead-${lead.id}`}
                    lead={lead}
                    onAccordionOpen={!propertiesCalled && !propertyData ? getProperties : () => {}}
                    properties={properties}
                    propertyError={propertyError}
                />
            ))}
        </>
    )
}

export default TenantLeads
