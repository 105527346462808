import { useState, useEffect } from 'react'
import Icon from './Icon'

export const Popup = ({ position, children, onClose, isOpen=false, className }) => {
    let [open, setOpen] = useState(isOpen)
    const close = () => {
        setOpen(false)
        onClose && onClose()
    }

    if (!open || !children) return null

    return (
        <div
            className={`pop-up pop-up--block ${className ? className : ''}`}
            style={{
                position: 'fixed',
                top: position.y,
                left: position.x
            }}
            onClick={e => e.stopPropagation()}
        >
            <div className='pop-up__container'>
                {children}
                <button
                    className='btn btn--naked close'
                    onClick={close}
                >
                    <Icon type='close' variant='xs' />
                </button>
            </div>
        </div>
    )
}

const Popover = ({ position, children, onClose, isOpen=false, className }) => {
    let [open, setOpen] = useState(isOpen)
    const close = (e) => {
        setOpen(false)
        onClose && onClose(e)
    }

    const closeOnEsc = (e) => e.keyCode === 27 && close(e)

    useEffect(() => {
        if (open) {
            document.addEventListener('keydown', closeOnEsc)
            return () => document.removeEventListener('keydown', closeOnEsc)
        }
    }, [open])

    useEffect(() => {
        setOpen(isOpen)
    }, [isOpen])

    if (!open || !children) return null

    return (
        <div
            className={`popover${position ? '' : ' popover--centered'} ${className ? className : ''}`}
            onClick={close}
        >
            <div
                className='pop-up pop-up--block'
                style={position && {
                    position: 'absolute',
                    top: position.y || position.top || 'auto',
                    left: position.x || position.left || 'auto',
                    bottom: position.bottom || 'auto',
                    right: position.right || 'auto'
                }}
                onClick={e => e.stopPropagation()}
            >
                <div className='pop-up__container'>
                    {children}
                    <button
                        title='Close popup'
                        className='btn btn--naked close'
                        onClick={close}
                    >
                        <Icon type='close' variant='xs' />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Popover