import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { postAsFormData } from '../../utils'
import { lbl } from '../../constants'
import { GET_ATTACHMENTS_FOR_CONTENT_OBJECT } from '../queries'
import { DELETE_ATTACHMENT } from '../mutations'
import AttachmentItem from './AttachmentItem'

const FilesList = ({
    contentTypeModel,
    contentTypeApp='property',
    objectId,
    uploadBtnLabel=lbl.upload,
    disableUpload
}) => {
    const [isUploading, setIsUploading] = useState(false)
    const [uploadError, setUploadError] = useState('')
    const { data, loading, refetch, client } = useQuery(GET_ATTACHMENTS_FOR_CONTENT_OBJECT, { variables: { contentTypeModel, objectId } })
    const files = data ? data.attachmentsForContentObject : undefined
    const [deleteAttachment] = useMutation(DELETE_ATTACHMENT, { onCompleted: refetch })

    const submitForm = (e) => {
        setIsUploading(true)
    
        postAsFormData({
            url: '/upload/',
            data: {
                file: e.target.files[0],
                content_type_str: `${contentTypeApp} | ${contentTypeModel}`,
                object_id: objectId
            },
            onSuccess: () => {
                refetch()
                client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'attachmentsForContentObjects' })
                client.cache.gc()
                if (uploadError) setUploadError('')
            },
            onError: (error) => {
                setUploadError(error.message || 'File upload failed')
            },
            onDone: () => {
                setIsUploading(false)
            }
        })
    }

    const renderList = () => {
        if (loading) return <div className='loader loader--inline' />

        if (!files?.length) return null

        return (
            <table className='lister--attachments lister--single-cell w-100 mb-1'>
                <tbody>
                    {files.map((file, i) => (
                        <AttachmentItem
                            key={file.originalFilename + i}
                            propKeys={['originalFilename', 'category']}
                            deleteAttachment={deleteAttachment}
                            {...file}
                        />
                    ))}
                </tbody>
            </table>
        )
    }

    return (
        <React.Fragment>
            {renderList()}
            <form className='mb-1' style={{ marginLeft: '-12px', marginRight: '-12px' }}>
                <label className='upload-file-label btn--new-item cursor--pointer w-100 d-block' htmlFor='file-input'>
                    <div className='t-dark'>
                        <button
                            disabled={disableUpload || isUploading }
                            className='btn btn--naked upload-file-button w-100 t-left'
                        >
                            {isUploading ?
                                <div className='loader loader--inline' />
                            : 
                                <><span className="plus">+</span>{uploadBtnLabel}</>
                            }
                        </button>
                    </div>
                </label>
                <input
                    id='file-input'
                    style={{ display: 'none' }}
                    type='file'
                    disabled={disableUpload || isUploading}
                    onChange={submitForm}
                />
                {uploadError && <div className='w-100 t-red t-sm'>{uploadError}</div>}
            </form>
        </React.Fragment>
    )
}

export default FilesList
