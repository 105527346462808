import { useMutation, useQuery } from '@apollo/client'
import { UPDATE_PARTNER } from '../mutations'
import { GET_COMPANIES } from '../queries'
import Form from '../../forms/Form'


const CompanyInfoForm = ({ user }) => {
    const {data: companyData, error: companyError} = useQuery(GET_COMPANIES)
    const initialState = {
        companyId: user.partner.company?.id,
    }
    const [updatePartner, { loading, called, data, error }] = useMutation(UPDATE_PARTNER)

    const success = !error && !loading && called && !!data?.updatePartner?.partner
    let companyOptions = companyData?.companies ? companyData.companies.map(({ id, name }) => ({ value: id, label: name })) : [
        { value: user.partner.company?.id, label: user.partner.company?.name },
        { value: 'company-x', label: companyError?.message || 'Loading ...' }
    ]
    const fields = [
        { name: 'companyId', lblKey: 'company', As: 'select', options: companyOptions.sort((a, b) => (a.label > b.label) ? 1 : -1), wide: true },
    ]

    return (
        <Form
            fields={fields}
            initialState={initialState}
            onSubmit={(data) => updatePartner({ variables: { partnerId: user.partner.id, ...data } })}
            isSubmitting={loading}
            isSuccess={success}
        />
    )
}

export default CompanyInfoForm
