import { useState } from 'react'
import MessageEditor from './MessageEditor'
import ContactSelect from '../../components/ContactSelect'
import { GET_CONTACT_OPTIONS } from '../queries'


const NewMessage = ({ inputLabel, fwdMsgId, sendMessageAsEmail, onMsgSend }) => {
    const [selected, setSelection] = useState([])
    const selectedThread = selected?.length === 1 && selected[0].__typename === 'ThreadType' ? selected[0].id : undefined
    const contactIds = selectedThread ? undefined : selected.map(({id}) => id)

    return (
        <div className='new-message-form'>
            <ContactSelect
                inputLabel={inputLabel}
                optionsQuery={GET_CONTACT_OPTIONS}
                selected={selected}
                multi={selected && selected.every(o => o.__typename === 'UserType')}
                placeholder='Type the name of the thread, person or ticket'
                onSelect={setSelection}
                onCloseOptions={() => document.querySelector('.new-message-form .fr-element').focus()}
            />
            <MessageEditor
                key={selectedThread + contactIds?.join('')}
                threadId={selectedThread}
                contactIds={contactIds}
                fwdMsgId={fwdMsgId}
                onSend={onMsgSend}
                sendMessageAsEmail={sendMessageAsEmail}
                fullVariant
            />
        </div>
    )
}

export default NewMessage
