import { gql } from '@apollo/client'
import { MESSAGE_FRAGMENT, THREAD_FRAGMENT } from './fragments'

export const ON_NEW_MESSAGE_SUBSCRIPTION = gql`
    subscription ON_NEW_MESSAGE_SUBSCRIPTION($threadId: ID) {
        onNewMessage(threadId: $threadId) {
            newMessage {
                ...MessageFields
                isRead
                thread {
                    id
                    unreadCount
                    membership {
                        id
                        isPinned
                        isSubscribed
                    }
                }
                mentions {
                    id
                }
            }
        }
    }
    ${MESSAGE_FRAGMENT}
`

export const ON_NEW_THREAD_SUBSCRIPTION = gql`
    subscription ON_NEW_THREAD_SUBSCRIPTION {
        onNewThread {
            newThread {
                ...BaseThreadFields
                parent {
                    id
                    ...BaseThreadFields
                    subthreads {
                        ...BaseThreadFields
                    }
                }
                subthreads {
                    ...BaseThreadFields
                }
            }
        }
    }
    ${THREAD_FRAGMENT}
`

export const ON_THREAD_DELETED = gql`
    subscription ON_THREAD_DELETED {
        onThreadDeleted {
            deletedThreadId
        }
    }
`

export const ON_NEW_READ_RECEIPT_SUBSCRIPTION = gql`
    subscription ON_NEW_READ_RECEIPT_SUBSCRIPTION {
        onNewReadReceipt {
            newReadReceipt {
                id
                message {
                    id
                    readreceiptSet {
                        user {
                            id
                            picture
                            fullName
                            type
                        }
                        readAt
                    }
                }
            }
        }
    }
`
