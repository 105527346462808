import { memo } from 'react'
import { normalizeString } from '../utils'

const HighlightedText = ({ text, mark }) => {
    if (!text) return ''
    if (!mark) return text

    const _text = normalizeString(text)
    const _mark = normalizeString(mark)

    const start = _text.toLowerCase().indexOf(_mark)
    if (start !== -1) {
        const end = start + mark.length
        const marked = text.slice(start, end)
        const prev = text.slice(0, start)
        const post = text.slice(end, text.length)
        return <>{prev}<mark>{marked}</mark>{post}</>
    }
    return text
}

export default memo(HighlightedText)
