import { gql } from '@apollo/client'
import { CHANGE_LOG_FRAGMENT, USER_FRAGMENT, NOTE_FRAGMENT } from './fragments'

export const CURRENT_USER_QUERY = gql`
  query CURRENT_USER_QUERY {
    me {
        id
        username
        firstName
        lastName
        fullName
        email
        picture
        isStaff
        isSuperuser
        onlineStatus
        assignedToTickets {
            id
        }
        type
        employee {
            id
        }
        department {
            id
            name
        }
        profile {
            id
            picture
        }
    }
  }
`

export const GET_ALL_USERS = gql`
    query GET_ALL_USERS {
        users(groupBy: "type") {
            ...UserFields
        }
    }
    ${USER_FRAGMENT}
`

export const GET_USER_GROUPS = gql`
    query GET_USER_GROUPS($topLevelOnly: Boolean) {
        userGroups(topLevelOnly: $topLevelOnly) {
            id
            name
            isSystem
            isGuest
        }
    }
`

export const GET_EMPLOYEES = gql`
    query GET_EMPLOYEES {
        employees {
            ...UserFields
        }
    }
    ${USER_FRAGMENT}
`

export const GET_PRIORITY_OPTIONS = gql`
    query PriorityOptions {
        priorityOptions @client
    }
`

export const GET_STATIC_STATUS_OPTIONS = gql`
    query StatusOptions {
        statusOptions @client
    }
`

export const GET_ONLINE_STATUS_OPTIONS = gql`
    query OnlineStatusOptions {
        onlineStatusOptions @client
    }
`

export const GET_EMPLOYMENT_STATUS_OPTIONS = gql`
    query EmploymentStatusOptions {
        employmentStatusOptions @client
    }
`

export const GET_TENANT_STATUS_OPTIONS = gql`
    query TenantStatusOptions {
        tenantStatusOptions @client
    }
`

export const GET_APPROVAL_REQUIRED_OPTIONS = gql`
    query ApprovalRequiredOptions {
        approvalRequiredOptions @client
    }
`

export const GET_USER_TYPE_OPTIONS = gql`
    query GET_USER_TYPE_OPTIONS {
        userTypeOptions @client
    }
`

export const GET_CALL_STATUS_OPTIONS = gql`
    query CallStatusOptions {
        callStatusOptions @client
    }
`

export const GET_CALL_DIRECTION_OPTIONS = gql`
    query CallDirectionOptions {
        callDirectionOptions @client
    }
`

export const GET_CALL_TIMING_OPTIONS = gql`
    query CallTimingOptions {
        callTimingOptions @client
    }
`

export const RESET_QUERY = gql`
    query Reset {
        reset @client
    }
`

export const GET_CHANGE_LOG_FOR_INSTANCE = gql`
    query GET_CHANGE_LOG_FOR_INSTANCE($contentType: String!, $objectId: ID!, $offset: Int, $limit: Int) {
        changeLogForContentObject(contentType: $contentType, objectId: $objectId, offset: $offset, limit: $limit) {
            ...ChangeLogFields
        }
    }
    ${CHANGE_LOG_FRAGMENT}
`

export const GET_CHANGE_LOG_TOTAL_FOR_INSTANCE = gql`
    query GET_CHANGE_LOG_TOTAL_FOR_INSTANCE($contentType: String!, $objectId: ID!) {
        changeLogTotalForContentObject(contentType: $contentType, objectId: $objectId)
    }
`

export const GET_DEPARTMENTS = gql`
    query GET_DEPARTMENTS {
        departments {
            id
            name
            icon
        }
    }
`

export const GET_CUSTOM_FIELD_GROUPS_FOR_INSTANCE = gql`
    query GET_CUSTOM_FIELD_GROUPS_FOR_INSTANCE($contentType: String!, $objectId: ID) {
        customFieldGroupsForContentObject(contentTypeModel: $contentType) {
            name
            id
            fields {
                id
                label
                valueType
                editable
                helpText
                value(objectId: $objectId)
            }
        }
    }
`

export const GET_UNGROUPED_CUSTOM_FIELS_FOR_INSTANCE = gql`
    query GET_UNGROUPED_CUSTOM_FIELS_FOR_INSTANCE($contentType: String!, $objectId: ID) {
        ungroupedCustomFieldsForContentObject(contentTypeModel: $contentType) {
            id
            label
            valueType
            editable
            helpText
            value(objectId: $objectId)
        }
    }
`
