import { useEffect, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import { newDraftsVar } from '../../cache'
import { GET_USER } from '../queries'
import { GET_PINNED_NOTES } from '../../notes/queries'
import { TabNavContext } from '../../components/TabNav'
import { ProfileImage, Icon } from '../../components'
import VoiceCall from '../../components/VoiceCall'
import { tabNavLinks, lbl, tenantStatusOptions } from '../../constants'
import ContactDetailNav from './ContactDetailNav'
import ContactDetailRouter from './ContactDetailRouter'
import LandlordPropertyList from './LandlordPropertyList'
import { getLeadAddress, getTenancyAddress } from '../utils'
import FlatMates from './FlatMates'
import PinnedNotePreview from '../../notes/components/PinnedNotePreview'

const ContactDetail = () => {
    const { id, activeSubtab, pinnedTabs, openTab } = useContext(TabNavContext)
    const { loading, error, data, called } = useQuery(GET_USER, {
        variables: { id },
        fetchPolicy: 'cache-first'
    })
    const { data: pinnedNotesData } = useQuery(GET_PINNED_NOTES, { 
        variables: { contentType: 'user', objectId: id }
    })
    const relativeUrl = tabNavLinks.contacts.dynamicPath + id
    const isPinned = pinnedTabs.get(relativeUrl)
    const name = data?.user?.fullName
    const isLister = ['tickets', 'tasks', 'attachments', 'calls'].includes(activeSubtab)

    useEffect(() => {
        called && name && !isPinned && openTab({ id, name, relativeUrl, icon: 'contact' })
    }, [called, name, id, isPinned])

    if (loading) return <div className='loader pos-center-y' />
    if (error || !data?.user) return <p>Error :(</p>
    
    const { user } = data

    const { addressLabel, addressUrl } = user.tenant?.activeTenancyAgreement ? getTenancyAddress(user.tenant.activeTenancyAgreement) : user.tenant?.activeLead ? getLeadAddress(user.tenant.activeLead) : []

    return (
        <div className='contact-detail split-panel'>
            <section className='split-panel--main-dual intersection-root'>
                <div className='split-panel--main-top'>
                    <div className='d-inline-flex mb-1'>
                        <h1 className='t-heading t-2xl mr-2'>
                            {user.fullName}
                        </h1>
                        <span className={`label chip--${user.type} t-xs mt-1`}>
                            {lbl[`userType_${user.type}`]}
                            {user.tenant?.tenancyStatus && <span className='ml-05'>{`- ${tenantStatusOptions[user.tenant.tenancyStatus].label}`}</span>}
                        </span>
                    </div>
                    {!!(addressUrl && addressLabel) &&
                        <div>
                            <Link
                                className='t-underline-hover'
                                to={addressUrl}
                            >
                                {addressLabel}
                            </Link>
                        </div>
                    }
                    <div>
                        {pinnedNotesData?.notesForContentObject?.map(note => (
                            <PinnedNotePreview
                                key={`${note.pinned ? 'pinned-' : ''}note-${note.id}`}
                                note={note}
                                path={`${tabNavLinks.contacts.dynamicPath}${user.id}`}
                            />
                        ))}
                    </div>
                    <br/>
                </div>
                <div className='split-panel--main-nav'>
                    <ContactDetailNav
                        userId={user.id}
                        userType={user.type}
                    />
                </div>

                <div className={`split-panel--main-bottom${isLister ? ' lister' : ''}`}>
                    <ContactDetailRouter
                        key={user.id}
                        user={user}
                        activeSubtab={activeSubtab}
                    />
                </div>
            </section>

            <aside className='split-panel--side split-panel--right split-panel--side--small'>
                <div className='split-panel--side-inner'>
                    <section>
                        <div className='d-column mt-1'>
                            <ProfileImage
                                url={user.profile.picture}
                                size='2xl'
                                type={user.type}
                            />
                            <div className='mt-1'>{user.fullName}</div>
                            {user.onlineStatus &&
                                <div className='t-sm t-grey mt-05 d-inline-flex'>
                                    <Icon
                                        type='dot'
                                        variant='2xs'
                                        wrapperClassAdditions={`icon-wrapper--${user.onlineStatus.toLowerCase()} mr-05`}
                                    />
                                    {user.onlineStatus.toLowerCase()}
                                </div>
                            }
                            <div className='mt-1'>
                                <VoiceCall
                                    contact={user}
                                    classAdditions='mr-4'
                                />
                                <Link
                                    className='btn btn--naked mr-4'
                                    to={tabNavLinks.tickets.new.relativeUrl}
                                    title='Assign new ticket'
                                    onClick={() => {
                                        newDraftsVar({ ...newDraftsVar(), tickets: {
                                            ...newDraftsVar().tickets,
                                            assignee: user,
                                            assigneeId: user.id,
                                            priority: '2_MEDIUM'
                                        } })
                                    }}
                                >
                                    <Icon type='ticket' variant='sm' />
                                </Link>
                                <Link
                                    className='btn btn--naked'
                                    to={tabNavLinks.tasks.new.relativeUrl}
                                    title='Assign new task'
                                    onClick={() => { 
                                        newDraftsVar({ ...newDraftsVar(), tasks: {
                                            ...newDraftsVar().tasks,
                                            assignee: user,
                                            assigneeId: user.id,
                                            priority: '2_MEDIUM',
                                            status: '1_TODO',
                                            approvalRequired: true
                                        } })
                                    }}
                                >
                                    <Icon type='task' variant='sm' />
                                </Link>
                            </div>
                        </div>
                    </section>
                    <hr/>
                    {user?.tenant?.activeTenancyAgreement &&
                        <>
                            <section>
                                <FlatMates
                                    tenantId={user.tenant.id}
                                    roomId={user.tenant.activeTenancyAgreement.unit?.id || user.tenant.activeTenancyAgreement.contract.unit?.id}
                                    propertyId={user.tenant.activeTenancyAgreement.contract.property?.id || user.tenant.activeTenancyAgreement.contract.unit?.address.id}
                                />
                            </section>
                            <hr/>
                        </>
                    }
                    {user?.landlord &&
                        <>
                            <section>
                                <LandlordPropertyList landlordId={user.landlord.id} />
                            </section>
                            <hr/>
                        </>
                    }
                </div>
            </aside>
        </div>
    )
};

export default ContactDetail;
