import { createContext } from 'react'
import { useLocation, useParams, Link, useHistory } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import {
    activeAppVar,
    staticTabsVar,
    openedTabsVar,
    pinnedTabsVar,
    newDraftsVar
} from '../cache'
import Icon from './Icon'
import ErrorBoundary from './ErrorBoundary'

const handleEvent = (e) => {
    e && e.preventDefault() && e.stopPropagation()
}

export const TabNavContext = createContext()

const TabNav = ({ children }) => {
    const activeApp = useReactiveVar(activeAppVar)
    const staticTabs = useReactiveVar(staticTabsVar)[activeApp]
    const pinnedTabs = useReactiveVar(pinnedTabsVar)
    const openedTabs = useReactiveVar(openedTabsVar)

    const params = useParams()
    const history = useHistory()
    const location = useLocation()

    const openedTabValues = Array.from(openedTabs.values())
    const openedTabKeys = Array.from(openedTabs.keys())
    const pinnedTabValues = Array.from(pinnedTabs.values())
    const pinnedTabKeys = Array.from(pinnedTabs.keys())

    const fallbackPath = staticTabs && staticTabs.length ? staticTabs[staticTabs.length-1].relativeUrl : '/'
    const isActive = (relativeUrl) =>  {
        if (relativeUrl === '/messenger') return location.pathname.includes('/messenger')
        if (params.activeSubtab) return location.pathname.includes(`${relativeUrl}/${params.activeSubtab}`)
        return relativeUrl === location.pathname
    }
    const staticOnly = pinnedTabs.size + openedTabs.size === 0
    
    const openTab = ({ name, id, relativeUrl, icon }) => {
        if (!openedTabs.get(relativeUrl)) {
            openedTabsVar(new Map([...openedTabs, [relativeUrl, { name, id, relativeUrl, icon }]]))
        }
    }

    const closeTab = ({ id, relativeUrl, preventRedirect }) => {
        let index = openedTabKeys.indexOf(relativeUrl)

        if (index === -1) return
        
        openedTabs.delete(relativeUrl)
        openedTabsVar(new Map([...openedTabs]))

        if (id === 'new') {
            const contentType = relativeUrl.split('/')[1]
            newDraftsVar({ ...newDraftsVar(), [contentType]: {} })
        }
        
        if (isActive(relativeUrl) && !preventRedirect) {            
            let redirectPath = fallbackPath
            if (index > 0) {
                redirectPath = openedTabKeys[index-1]
            } else if (pinnedTabs.size) {
                redirectPath = pinnedTabKeys[pinnedTabs.size-1]
            }
            history.push(redirectPath)
        }
    }

    const renameTab = ({ name, relativeUrl }) => {
        const tab = openedTabs.get(relativeUrl)
        if (tab) {
            openedTabsVar(new Map([...openedTabs, [relativeUrl, { ...tab, name }]]))
        }
    }

    const setPinnedTabs = (newMap) => {
        pinnedTabsVar(newMap)
        localStorage.setItem('pinnedTabs', JSON.stringify(Array.from(newMap.entries())))
    }

    const pinTab = ({ id, name, relativeUrl, icon }) => {
        if (!pinnedTabs.get(relativeUrl)) {
            setPinnedTabs(new Map([...pinnedTabs, [relativeUrl, { name, id, relativeUrl, icon }]]))
        }
        closeTab({ id, relativeUrl, preventRedirect: true })
    }

    const unpinTab = ({ relativeUrl, preventOpen=false }) => {
        const pinnedTab = pinnedTabs.get(relativeUrl)
        pinnedTabs.delete(relativeUrl)
        setPinnedTabs(new Map([...pinnedTabs]))
        !preventOpen && openTab(pinnedTab)
    }


    return (
        <>
            <nav className='tab-nav'>
                <ul className={`tab-nav__list tab-nav__list--static${staticTabs && staticTabs.length ? '' : ' empty'} pl-r`}>
                    {staticTabs && staticTabs.map(({ relativeUrl, label, icon }) => (
                        <li
                            key={label}
                            className={`tab tab--static${isActive(relativeUrl) ? ' active' : ''}`}
                        >
                            <Link to={relativeUrl}>
                                <>
                                    <Icon type={icon} variant='xs' />
                                    <span className='tab__label t-sm'>{label}</span>
                                </>
                            </Link>
                        </li>
                    ))}
                </ul>
                <div className={`tab-nav--scroll${staticOnly ? ' empty' : ''}`}>
                    <ul className='tab-nav__list tab-nav__list--scroll'>
                        {pinnedTabValues.map(({ name, relativeUrl, icon }) => (
                            <li
                                key={name}
                                title={name}
                                className={`tab tab--pinned${isActive(relativeUrl) ? ' active' : ''}`}
                            >
                                <Link to={relativeUrl}>
                                    <>
                                        <Icon type={icon} variant='xs' />
                                        <span className='tab__label t-sm'>{name}</span>
                                        <button
                                            className='btn unpin btn--inline-icon btn--naked'
                                            title={`Unpin ${name}`}
                                            onClick={e => {
                                                handleEvent(e)
                                                unpinTab({ relativeUrl })
                                            }}
                                        >
                                            <Icon type='unpin' variant='2xs' />
                                        </button>
                                    </>
                                </Link>
                            </li>
                        ))}
                        {openedTabValues.map(({ name, id, relativeUrl, icon }, i) => (
                            <li
                                key={i + name}
                                title={name}
                                className={`tab tab--dynamic${isActive(relativeUrl) ? ' active' : ''}`}>
                                <Link to={relativeUrl}>
                                    <>   
                                        {id === 'new' ?
                                            <Icon type={icon} variant='xs' fill='#367BF5' />
                                            :
                                            <button
                                                className='btn btn--inline-icon btn--naked pin'
                                                title={`Pin ${name} tab`}
                                                onClick={e => {
                                                    handleEvent(e)
                                                    pinTab({ id, name, relativeUrl, icon })
                                                }}
                                            >
                                                <Icon type='pin' variant='xs' />
                                                <Icon type={icon} variant='xs' classAdditions='tab__icon tab__icon--type' />
                                            </button>
                                        }
                                        <span className='tab__label t-sm'>{name}</span>
                                        <button
                                            className='btn close btn--inline-icon btn--naked'
                                            title='Close tab'
                                            onClick={e => {
                                                handleEvent(e)
                                                closeTab({ id, relativeUrl })
                                            }}
                                        >
                                            <Icon type='close' variant='2xs' />
                                        </button>
                                    </>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </nav>
            <TabNavContext.Provider value={{
                ...params,
                openedTabs,
                pinnedTabs,
                closeTab,
                openTab,
                renameTab,
                pinTab,
                unpinTab
            }}>
                <section className='tab__panel'>
                    <ErrorBoundary>
                        {children}
                    </ErrorBoundary>
                </section>
            </TabNavContext.Provider>
        </>
    )
}

export default TabNav
