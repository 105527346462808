import { useMutation } from '@apollo/client'
import { UPDATE_USER } from '../mutations'
import { lbl } from '../../constants'
import Form from '../../forms/Form'

const fields = [
    { name: 'emergencyContactName', lblKey: 'name' },
    { name: 'emergencyContactRelationship', lblKey: 'relationship' },
    { name: 'emergencyContactEmail', lblKey: 'email' },
    { name: 'emergencyContactNumber', type: 'tel', lblKey: 'phoneNumber' },
    { name: 'emergencyContactAddress', lblKey: 'address' },
    { name: 'emergencyContactLanguage', lblKey: 'spokenLanguage' },
    { name: 'linkedin', lblKey: 'linkedIn', BeforeNode: () => <><div className='t-sm mt-2 mb-2'>{lbl.socialMedia}</div><br/></> },
    { name: 'facebook' },
    { name: 'instagram' },
    { name: 'tiktok' }
]

const ComunicationForm = ({ user }) => {
    const initialState = {
        facebook: user.tenant?.facebook,
        instagram: user.tenant?.instagram,
        tiktok: user.tenant?.tiktok,
        linkedin: user.tenant?.linkedin,
        emergencyContactName: user.tenant?.emergencyContactName,
        emergencyContactRelationship: user.tenant?.emergencyContactRelationship,
        emergencyContactNumber: user.tenant?.emergencyContactNumber,
        emergencyContactEmail: user.tenant?.emergencyContactEmail,
        emergencyContactAddress: user.tenant?.emergencyContactAddress,
        emergencyContactLanguage: user.tenant?.emergencyContactLanguage,
    }

    const [updateUser, { loading, called, data, error }] = useMutation(UPDATE_USER)
    const success = !error && !loading && called && !!data?.updateUserDetails?.user

    return (
        <>
            <div className='t-sm mt-2 mb-2'>
                {lbl.emergencyContact}
            </div>
            <Form
                fields={fields}
                initialState={initialState}
                onSubmit={data => updateUser({ variables: { id: user.id, ...data } })}
                isSubmitting={loading}
                isSuccess={success}
            />
        </>
    )
};

export default ComunicationForm;
