import { ticketProperties } from '../settings'
import { addNewRefToCachedLists } from '../../utils'

export const onNewTicket = ({ subscriptionData, client }) => {
    if (!subscriptionData.data) return
    const { newTicket } = subscriptionData.data.onNewTicket

    // add to board
    newTicket.pipelineStage && client.cache.modify({
        id: client.cache.identify({
            __typename: 'PipelineStageType',
            id: newTicket.pipelineStage.id
        }),
        fields: {
            tickets(existingTickets = [], { toReference }) {
                return [...existingTickets, toReference(newTicket)]
            }
        }
    })

    // update all controlled lists in cache
    addNewRefToCachedLists({
        cache: client.cache,
        fieldName: 'tickets',
        newItem: newTicket,
        getFieldProps: (field) => ticketProperties[field]
    })
}
