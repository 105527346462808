import { useState, useRef, useCallback, useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import { ocpVar } from '../cache'
import Icon from './Icon'


const Ocp = () => {
    const ocpRef = useRef(null)
    const [isResizing, setIsResizing] = useState(false)
    const { isOpen, defaultWidth, type } = useReactiveVar(ocpVar)
    const [sidebarWidth, setSidebarWidth] = useState(isOpen ? defaultWidth : 0)
    const showBackdrop = ['thread-attachments'].includes(type)
    const siteContainer = document.getElementsByClassName('site-container')[0]
    const { pathname } = useLocation()

    const startResizing = useCallback(() => {
        setIsResizing(true)
    }, [])

    const stopResizing = useCallback(() => {
        setIsResizing(false)
    }, [])

    const resize = useCallback(
        (mouseMoveEvent) => {
            if (isResizing) {
                setSidebarWidth(window.innerWidth - mouseMoveEvent.clientX)
            }
        },
        [isResizing]
    )

    const escape = useCallback(
        (keydownEvent) => {
            if (keydownEvent.key === 'Escape') {
                ocpVar({ ...ocpVar(), isOpen: false })
            }
        },
        []
    )

    useEffect(() => {
        ocpVar({ ...ocpVar(), containerEl: ocpRef.current })
    }, [])

    useEffect(() => {
        setSidebarWidth(isOpen ? defaultWidth : 0)

        if (isOpen) {
            if (showBackdrop) {
                document.body.classList.add('scroll-lock')
                siteContainer.style.pointerEvents = 'none'
            }
            document.getElementsByClassName('carousel-root')[0]?.focus()
        } else {
            document.body.classList.remove('scroll-lock')
            if (siteContainer && siteContainer.style.pointerEvents === 'none') {
                siteContainer.style.pointerEvents = 'all'
            }
        }
    }, [isOpen])

    useEffect(() => {
        if (isOpen) {
            window.addEventListener('mousemove', resize)
            window.addEventListener('mouseup', stopResizing)
            window.addEventListener('keydown', escape)
        }

        return () => {
            window.removeEventListener('mousemove', resize)
            window.removeEventListener('mouseup', stopResizing)
            window.removeEventListener('keydown', escape)
        }
    }, [isOpen, resize, stopResizing])

    useEffect(() => {
        if (isOpen) ocpVar({ ...ocpVar(), isOpen: false })
    }, [pathname])

    return (
        <div className={`ocp ${showBackdrop ? 'ocp--backdrop' : 'ocp--no-backdrop'} ${isOpen ? 'open' : 'closed'} ${type}`}>
            {showBackdrop &&
                <div
                    className='ocp__backdrop'
                    onClick={() => ocpVar({ ...ocpVar(), isOpen: false })}
                />
            }
            <div
                className={`ocp__container ${isOpen ? 'open' : 'closed'} ${type}`}
                style={{ width: sidebarWidth }}
                onMouseDown={(e) => e.preventDefault()}
            >
                {isOpen && <div className='ocp__resizer' onMouseDown={startResizing}/>}
                <div className='ocp__content' ref={ocpRef}/>
                <button
                    className='btn btn--naked close ocp__close'
                    onClick={() => ocpVar({ ...ocpVar(), isOpen: false })}
                >
                    <Icon type='close' variant='m' />
                </button>
            </div>
        </div>
    )
}

export default Ocp
