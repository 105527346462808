import { Fragment, useState, useEffect, useRef } from 'react'
import { useApolloClient, useReactiveVar } from '@apollo/client'
import { lbl } from '../constants'
import { cacheNewListerQuery, getProp, objectIncludesString, stripFieldFromPath, updateListByActionType } from '../utils'
import Icon from './Icon'
import ProfileImage from './ProfileImage'
import ListerHeader from './ListerHeader'
import ListerControls from './ListerControls'
import OnScrollLoader from './OnScrollLoader'


const Lister = ({
    items,
    children,
    hideControls,
    showLoader,
    query,
    queryFieldName,
    queryVariables,
    fetchMore,
    fetchMoreOnScroll,
    ...props
}) => {
    const { listerCtrlVar, options, optionsDict } = props
    const [shouldFetchMore, setFetchMore] = useState(false)
    const { cache } = useApolloClient()
    const vars = useReactiveVar(listerCtrlVar) || {}
    const { groupBy, searchTerm, limit } = vars
    const isPaginated = !!limit
    const filters = vars.filters?.map(f => `${f.field}___${f.id}`).sort().join(',') || ''
    const searchFields = props.options.search
    const groupField = stripFieldFromPath(groupBy)
    const groupProps = groupBy ? props.optionsDict[groupField] : {}
    const getGroupKey = (item) => ['string', 'boolean'].includes(groupProps.type) ? getProp(groupProps.groupPath || groupBy, item) : getProp('id', getProp(groupProps.objectPath || groupProps.groupPath || groupBy, item))
    const container = document.querySelector('.intersection-root')
    let fromWrittenCache = useRef(false)
    let groupKey = useRef()
    let groupItems = useRef({})
    let variables = { ...vars, filters }

    const onSelectCtrl = (actionType, ctrls) => {
        if (props.onSelectCtrl) {
            props.onSelectCtrl(actionType, ctrls, shouldFetchMore)
        } else {
            const updateList = isPaginated ? cacheNewListerQuery : updateListByActionType
            const isCached = updateList({
                actionType,
                ctrls,
                items,
                shouldFetchMore,
                listCtrlVar: listerCtrlVar,
                listerCtrlOptions: options,
                getFieldProps: (field) => optionsDict[field],
                queryFieldName,
                queryVariables,
                query,
                cache
            })
            if (isCached) fromWrittenCache.current = true
        }
    }

    const onScrollToBottom = () => {        
        if (!isPaginated) return
        
        fetchMore({
            variables: { ...variables, offset: items.length }
        }).then(fetchMoreResult => {
            if (fetchMoreResult.data && fetchMoreResult.data[queryFieldName]) {
                const newBatchCount = fetchMoreResult.data[queryFieldName].length
                if (newBatchCount === 0) setFetchMore(false)
            }
        })
    }

    useEffect(() => {
        if (isPaginated && items) {
            let hasMore = false
            if (fromWrittenCache.current) {
                hasMore = true
                fromWrittenCache.current = false
            } else if (items.length) {
                hasMore = !!items[items.length - 1].hasMore
            }
            if (hasMore !== shouldFetchMore) setFetchMore(hasMore)
        }
    }, [isPaginated, items])

    useEffect(() => {
        groupItems.current = {}
    }, [groupBy, items])
    
    return (
        <div className={`lister lister--${props.type}`}>
            {!hideControls &&
                <ListerControls {...props} onSelectCtrl={onSelectCtrl} />
            }
            <div className='lister-table__wrapper pl-r'>
                <table className='lister__table'>
                    <ListerHeader {...props} onSelectCtrl={onSelectCtrl} isPaginated={isPaginated} />
                    <tbody>
                        {items && items.map((item, index) => {
                            if (searchTerm && !objectIncludesString({
                                obj: item, str: searchTerm, searchFields
                            })) return null

                            const currentGroupKey = groupBy ? getGroupKey(item)?.toString() || 'other' : ''
                            
                            if (!groupBy || (!!index && groupKey.current === currentGroupKey && groupItems.current[currentGroupKey])) {
                                if (groupBy) groupItems.current[currentGroupKey].push(item)

                                return (
                                    <Fragment key={!!(groupBy && item.id) ? groupBy + item.id : item.id}>
                                        {children(item, index)}
                                    </Fragment>
                                )
                            } else {
                                groupKey.current = currentGroupKey
                                groupItems.current[currentGroupKey] = [item]
                                
                                return (
                                    <Fragment key={groupBy + '_list_item_' + index + item.id}>
                                        {index !== 0 && <tr className='spacer-row'/>}
                                        {(groupBy !== 'archived' || currentGroupKey !== 'other') &&
                                            <ListerGroupHeader
                                                items={groupItems.current[currentGroupKey]}
                                                groupProps={currentGroupKey !== 'other' ?
                                                    groupProps.getOptionProps(getProp(groupProps.objectPath || groupProps.groupPath || groupBy, item)) 
                                                    : { 
                                                        iconProps: { type: 'status-0' },
                                                        label: groupBy === 'assignee' ? lbl.unassigned : lbl.other
                                                    }}
                                                {...props}
                                            />
                                        }
                                        {children(item, index)}
                                    </Fragment>
                                )
                            }
                        })}
                    </tbody>
                </table>
                <table className='spacer-after'></table>
            </div>
            {shouldFetchMore && isPaginated && !showLoader &&
                <OnScrollLoader onIntersect={onScrollToBottom} root={container} />
            }
            {showLoader && <div className='t-center mt-4'><div className='loader loader--inline'/></div>}
        </div>
    )
}

const ListerGroupHeader = ({
    groupProps,
    columnKeys,
    onSelectBatch,
    resetBatchSelection,
    items=[],
}) => {
    const [selected, setSelected] = useState(false)
    const { label, iconProps, color, picture } = groupProps
    const onHeaderClick = () => {
        if (onSelectBatch) {
            onSelectBatch(items, selected)
            setSelected(prev => !prev)
        }
    }

    useEffect(() => {
        resetBatchSelection && setSelected(false)
    }, [resetBatchSelection])

    return (
        <tr className='lister__row lister-group__header'>
            <th className={`lister-group-header__cell t-md t-${color ? color : 'grey'}`}>
                <div className='lister-cell__wrapper'>
                    <button
                        className='btn btn--round'
                        disabled={!onSelectBatch || !items}
                        onClick={onHeaderClick}
                    >
                        {picture && <ProfileImage url={picture} iconProps={iconProps} size='sm' showIconTag />}
                        {!picture && iconProps && <Icon {...iconProps} variant='sm' />}
                    </button>
                    <div className={`d-inline-block${picture || iconProps ? ' ml-2' : ''}`}>
                        {label}
                    </div>
                </div>
            </th>
            {Array.apply(null, Array(columnKeys.length - 1)).map((x, idx) => <th key={idx} />)}
        </tr>
    )
}

export default Lister
