import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { currentUserVar } from '../../cache'
import scrollApi from '../../utils/scrollUtils'
import { lbl, tabNavLinks } from '../../constants'
import { useActivateThreadsMutation } from '../hooks'
import { ARCHIVE_MESSAGE, UPDATE_MESSAGE } from '../mutations'
import MessageEditor from './MessageEditor'
import NewMessage from './NewMessage'
import ChatFeed from './ChatFeed'
import { Icon, ContextMenu, Popover } from '../../components'

const Chat = ({
    archived,
    deleted,
    messages,
    totalCount,
    unreadCount,
    members,
    memberships,
    threadId,
    isLoading,
    loadMore,
    preventScroll,
    noReply,
    isNotification,
    isInLister,
    redirect,
    isMember,
    isEmpty,
    isUnread,
    hasRelatedTicket,
    sendMessageAsEmail
}) => {
    const me = currentUserVar()
    const scrollContainerRef = useRef()
    const loadOldThreshold = useRef()
    const msgList = useRef()
    const [activateThreads] = useActivateThreadsMutation()
    const [updateMessage, { client }] = useMutation(UPDATE_MESSAGE, {
        onCompleted: () => {
            client.cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'highlightedMessages',
                variables: { threadId }
            })
            client.cache.gc()
        }
    })
    const [archiveMessage] = useMutation(ARCHIVE_MESSAGE, {
        update(cache, { data: { archiveMessage: { message } } }) {
            cache.modify({
                id: cache.identify({ __typename: 'ThreadType', id: message.thread.id }),
                fields: {
                    totalMessageCount(prev) {
                        return prev - 1
                    },
                    messages(prev=[], { readField }) {
                        return prev.filter(prevRef => readField('id', message) !== readField('id', prevRef))
                    }
                }
            })
        }
    })

    let [contextMenu, setContextMenu] = useState(false)
    let [editMsg, setEditMsg] = useState()
    let [refMsg, setRefMsg] = useState()
    let [activePopup, setActivePopup] = useState()

    const canReply = !deleted && !noReply && !archived && (!isNotification || me?.isStaff)

    const onContextMenu = (message) => {
        const items = deleted ? [] : [
            canReply && !isLoading && !message.isNotification && {
                icon: 'reply',
                label: 'Reply',
                onClick: () => {
                    editMsg && setEditMsg(undefined)
                    setRefMsg(message)
                }
            },
            {
                icon: 'arrow-right',
                label: 'Forward',
                onClick: () => setActivePopup({ action: 'forward', message })
            },
            {
                icon: 'link',
                label: 'Link',
                onClick: () => {
                    const relativeUrl = `${tabNavLinks.messenger.dynamicPath}${threadId}#${message.id}`
                    const url = window.location.origin + relativeUrl
                    navigator.clipboard.writeText(url)
                    setActivePopup({ action: 'link', url, relativeUrl  })
                }
            },
            {
                icon: 'highlight',
                label: message.isHighlighted ? 'Remove highlight' : 'Highlight',
                onClick: () => updateMessage({
                    variables: { id: message.id, isHighlighted: !message.isHighlighted }
                })
            },
            {
                icon: 'important',
                label: message.isImportant ? 'Remove crucial info' : 'Crucial information',
                onClick: () => updateMessage({
                    variables: { id: message.id, isImportant: !message.isImportant }
                })
            },
            message.sender && me.id === message.sender.id && {
                icon: 'pencil',
                label: 'Edit',
                onClick: () => {
                    refMsg && setRefMsg(undefined)
                    setEditMsg(message)
                }
            },
            (me.isSuperuser || (message.sender && me.id === message.sender.id)) && {
                icon: 'archive',
                label: 'Archive',
                onClick: () => setActivePopup({ action: 'archive', messageId: message.id })
            }
        ].filter(Boolean)

        setContextMenu({ items })
    }

    useEffect(() => {
        setEditMsg(undefined)
        setRefMsg(undefined)
    }, [threadId])

    return (
        <>
            <div
                className='chat__body'
                ref={scrollContainerRef}
                onScroll={!preventScroll ? () => {
                    if (scrollApi.scrolledToThreshold(scrollContainerRef?.current, 0) &&
                    !isLoading && messages && loadMore && totalCount > messages.length) {
                        loadOldThreshold.current = scrollApi.scrollHeight(scrollContainerRef?.current)
                        loadMore({ variables: { offset: messages.length } })
                    }
                } : undefined}
            >
                {(isLoading || (!preventScroll && messages && loadMore && totalCount > messages.length)) &&
                    <>
                        <div className='mb-6'/>
                        {isLoading && <div className='loader' style={{ position: 'absolute', top: '40px', left: 'calc(50% - 2px)', zIndex: 1 }}/>}
                    </>
                    
                }
                <div ref={msgList}>
                    <ChatFeed
                        messages={messages}
                        members={members}
                        memberships={memberships}
                        threadId={threadId}
                        userId={me?.id}
                        preventScroll={preventScroll}
                        scrollContainer={scrollContainerRef}
                        hasMoreMessages={messages && totalCount > messages.length}
                        loadOldThreshold={loadOldThreshold}
                        isLoading={isLoading}
                        handleContextMenu={onContextMenu}
                        loadMore={loadMore}
                        totalCount={totalCount}
                    />
                </div>
                {!isLoading && (!messages || !messages.length) &&
                    <div className='t-grey t-sm'>
                        ...nothing here yet
                    </div>
                }
            </div>
            {(canReply || archived) &&
                <div className='new-mgs__container'>
                    {refMsg &&
                        <div className='d-flex'>
                            <Icon type='reply' variant='2xs' classAdditions='mr-05' fill='#C0C0C0' />
                            <div className='msg-ref t-xs mb-1 mr-1'>
                                <div className='msg-ref__header mb-05'>
                                    {refMsg.sender?.fullName}
                                </div>
                                <div className='msg-ref__body' dangerouslySetInnerHTML={{ __html: refMsg.body }} />
                            </div>
                            <button
                                className='msg-ref__close btn btn--inline-icon btn--round close'
                                onClick={() => setRefMsg(undefined)}
                            >
                                <Icon type='close' variant='3xs' />
                            </button>
                        </div>
                    }
                    {canReply &&
                        <MessageEditor
                            key={'chat-msg-editor-' + threadId + refMsg?.id}
                            threadId={threadId}
                            fullVariant={!isInLister}
                            preventRedirect={!redirect && isInLister}
                            isMember={isMember}
                            isNotificationThread={isNotification}
                            isUnread={unreadCount > 0 || isUnread}
                            isEmptyThread={isEmpty}
                            msg={editMsg}
                            refMsgId={refMsg && refMsg.id}
                            onUpdate={() => setEditMsg(undefined)}
                            onSend={refMsg ? () => setRefMsg(undefined) : undefined}
                            sendMessageAsEmail={sendMessageAsEmail}
                            scrollContainer={scrollContainerRef}
                        />
                    }
                    {archived && !hasRelatedTicket &&
                        <button
                            className='btn btn--primary btn--inline-icon mx-auto'
                            onClick={() => activateThreads({ variables: { threadIds: [threadId] } })}
                        >
                            <Icon type='activate' />
                            activate thread
                        </button>
                    }
                </div>
            }
            <ContextMenu
                key='msg-context-menu'
                element={msgList.current}
                {...contextMenu}
            />
            {activePopup &&
                <Popover
                    onClose={() => setActivePopup(null)}
                    isOpen={!!activePopup}
                >
                    {activePopup.action === 'link' &&
                        <div className='t-center t-none'>
                            <p className='t-grey t-sm mb-2'>Link url has been copied to your clipboard</p>
                            <Link to={activePopup.relativeUrl}>
                                <p className='t-blue'>{activePopup.url}</p>
                            </Link>
                        </div>
                    }
                    {activePopup.action === 'archive' &&
                        <>
                            <div className='pop-up__message t-s'>
                                Are you sure you want to archive this message?<br/>
                                Message will be removed from this thread.
                            </div>
                            <div className='pop-up__btns'>
                                <button
                                    className='btn btn--secondary mr-2'
                                    onClick={() => setActivePopup(null)}
                                >
                                    {lbl.cancel}
                                </button>
                                <button
                                    className='btn btn--primary'
                                    onClick={() => {
                                        archiveMessage({
                                            variables: { messageId: activePopup.messageId },
                                        })
                                        setActivePopup(null)
                                    }}
                                >
                                    {lbl.archive}
                                </button>
                            </div>
                        </>
                    }
                    {activePopup.action === 'forward' &&
                        <>
                            <div className='pop-up__header'>
                                <Icon type='arrow-right' variant='sm' classAdditions='mr-1' />
                                Forward Message
                            </div>
                            <NewMessage
                                inputLabel='Forward Message To'
                                fwdMsgId={activePopup.message.id}
                                onMsgSend={() => setActivePopup(null)}
                            />
                        </>
                    }
                </Popover>
            }
        </>
    )
}

export default Chat
