import { gql } from '@apollo/client'


export const MESSAGE_FRAGMENT = gql`
    fragment MessageFields on MessageType {
        id
        title
        body
        sentAt
        archived
        isHighlighted
        isImportant
        isNotification
        sentAsEmail
        sender {
            id
            picture
            fullName
            type
            onlineStatus
        }
        readreceiptSet {
            user {
                id
                picture
                fullName
                type
            }
            readAt
        }
        refMsg {
            id
            body
            sender {
                id
                fullName
            }
        }
        fwdMsg {
            id
            body
            sender {
                id
                fullName
            }
        }
    }
`

export const THREAD_FRAGMENT = gql`
    fragment BaseThreadFields on ThreadType {
        id
        name
        icon
        archived
        deleted @client
        fixed
        noReply
        isFolder
        isEmpty
        isDirect
        isGroup
        unreadCount
        contentType {
            id
            model
        }
        objectId
        membership {
            id
            isPinned
            isSubscribed
            isUnread
        }
        contact {
            id
            onlineStatus
        }
        parent {
            id
        }
    }
`
