import { useMutation, gql } from '@apollo/client'
import { lbl } from '../../constants'
import { CREATE_PIPELINE_STAGE } from '../mutations'
import { TextField } from '../../forms'
import { Icon } from '../../components'


const TicketBoardColumnNew = ({
    index,
    closeNew,
    prevId
}) => {
    const [createPipelineStage] = useMutation(CREATE_PIPELINE_STAGE, {
        onCompleted: closeNew,
        update(cache, { data: { createPipelineStage: { pipelineStage } } }) {
            cache.modify({
                id: cache.identify({ __typename: 'PipelineType', id: pipelineStage.pipeline.id }),
                fields: {
                    pipelineStages(stages = []) {
                        const newStageRef = cache.writeFragment({
                            data: pipelineStage,
                            fragment: gql`
                                fragment NewStage on PipelineStageType {
                                    id
                                    name
                                    isFirst
                                    isLast
                                    order
                                    ticketCount
                                    pipeline {
                                        id
                                    }
                                }
                            `
                        });
                        const merged = stages ? stages.slice(0) : []
                        merged.splice(index, 0, newStageRef)
                        return merged
                    }
                }
            })
        }
    })

    return (
        <div className={`board-column board-column--${index}`}>
            <header className='board-column__header no-wrap'>
                <div className='board-column-title board-column-title--yellow mr-2'>
                    <TextField
                        classAdditions='textfield-form--board-column-name'
                        onSubmit={value => {
                            value && createPipelineStage({ variables: { name: value, prevId } })
                        }}
                        inputProps={{
                            type: 'input',
                            name: 'name',
                            placeholder: lbl.addStatusName
                        }}
                        size='sm'
                        inline
                    />
                    <Icon
                        type='status-2'
                        wrapperClassAdditions='icon-wrapper--status'
                        variant='sm'
                    />
                </div>
            </header>
        </div>
    )
}

export default TicketBoardColumnNew
