import { useState, useEffect } from 'react';
import { useLocation, Link, useParams } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { activeAppVar, notificationCountersVar, activeSubtabVar } from '../cache'
import { siteNavLinks } from '../constants'
import { Icon, Counter, ProfileImage } from '../components'
import LogOut from '../forms/LogOut'

const SiteNav = ({ user }) => {
    const [isCollapsed, toggleNav] = useState(true)
    const { pathname: currentPathname } = useLocation()
    const activeApp = useReactiveVar(activeAppVar)
    const activePathname = '/' + activeApp
    const counters = useReactiveVar(notificationCountersVar)
    const { activeTab, id, activeSubtab } = useParams()

    useEffect(() => {
        activeAppVar(currentPathname.split('/')[1])
    }, [currentPathname])

    useEffect(() => {
        if (activeSubtab) {
            const activeSubtabs = activeSubtabVar()
            if (activeSubtabs[activeTab]) {
                activeSubtabs[activeTab][id] = activeSubtab
            } else {
                activeSubtabs[activeTab] = { [id]: activeSubtab }
            }
            activeSubtabVar(activeSubtabs)
        }
    }, [activeSubtab, activeTab, id])

    return (
        <aside
            className={`aside-panel${isCollapsed ? ' collapsed' : ''}`}
            onClick={() => toggleNav(prevState => !prevState)}
        >
            <img className='aside-panel__logo' src={process.env.PUBLIC_URL + '/logo_long.svg'} alt='logo'/>
            <nav className='nav'>
                <ul className='nav__list'>
                    {siteNavLinks.map(({ pathname, label, icon, counter }) => {
                        return (
                            <li
                                key={label}
                                className={`nav__listitem${pathname === activePathname ? ' active' : ''}`}>
                                <Link
                                    to={pathname}
                                    title={label}
                                    onClick={e => {
                                        e.stopPropagation()
                                        activeAppVar(pathname.split('/')[1])
                                    }}
                                >
                                    <>
                                        <span className='icon__wrapper pos-relative'>
                                            <Icon type={icon} variant='m' />
                                            {counter && !!counters[counter] &&
                                                <Counter count={counters[counter]} />
                                            }
                                        </span>
                                        <span className='nav-listitem__label'>{label}</span>
                                    </>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </nav>
            <div className='aside-panel__bottom'>
                <Link
                    className='aside-panel__profile mb-1'
                    to='/profile'
                    onClick={e => e.stopPropagation()}
                >
                    <ProfileImage
                        size='lg'
                        url={user.picture}
                        status={user.onlineStatus}
                    />
                </Link>
                <LogOut/>
            </div>
            <button
                className='btn btn--naked aside-panel__toggle'
                onClick={e => {
                    e.stopPropagation()
                    toggleNav(prevState => !prevState)
                }}
            >
                {isCollapsed ? <>&#10095;</> : <>&#10094;</>}
            </button>
        </aside>
    )
}

export default SiteNav
