import { gql } from '@apollo/client'
import { BASE_NOTIFICATION_FRAGMENT } from './fragments'

export const GET_UNREAD_NOTIFICATIONS = gql`
    query GET_UNREAD_NOTIFICATIONS($offset: Int, $limit: Int) {
        unreadNotifications(offset: $offset, limit: $limit) {
            ...NotificationFields
            hasMore
        }
    }
    ${BASE_NOTIFICATION_FRAGMENT}
`

export const GET_READ_NOTIFICATIONS = gql`
    query GET_READ_NOTIFICATIONS($offset: Int, $limit: Int) {
        readNotifications(offset: $offset, limit: $limit) {
            ...NotificationFields
            hasMore
        }
    }
    ${BASE_NOTIFICATION_FRAGMENT}
`

export const GET_UNREAD_NOTIFICATIONS_COUNT = gql`
    query GET_UNREAD_NOTIFICATIONS_COUNT {
        unreadNotificationsCount
    }
`
