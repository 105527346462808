import { gql } from '@apollo/client'
import { BASE_TASK_FRAGMENT, FULL_TASK_FRAGMENT } from './fragments'

export const GET_TASK = gql`
    query GET_TASK($id: ID!) {
        task(id: $id) {
            ...AllTaskFields
            deleted @client
        }
    }
    ${FULL_TASK_FRAGMENT}
`

export const GET_TASKS = gql`
    query GET_TASKS(
        $offset: Int, $limit: Int, $searchTerm: String, $groupBy: String, $groupDir: Int, $sortBy: String, $sortDir: Int, $filters: String
    ) {
        tasks(offset: $offset, limit: $limit, searchTerm: $searchTerm, groupBy: $groupBy, groupDir: $groupDir, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            ...BaseTaskFields
            ticket {
                id
                name
            }
            hasMore
        }
    }
    ${BASE_TASK_FRAGMENT}
`

export const GET_ARCHIVED_TASKS = gql`
    query GET_ARCHIVED_TASKS($offset: Int, $limit: Int, $searchTerm: String, $groupBy: String, $groupDir: Int, $sortBy: String, $sortDir: Int, $filters: String) {
        archivedTasks(offset: $offset, limit: $limit, searchTerm: $searchTerm, groupBy: $groupBy, groupDir: $groupDir, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            ...BaseTaskFields
            ticket {
                id
                name
            }
            hasMore
        }
    }
    ${BASE_TASK_FRAGMENT}
`

export const GET_USER_TASKS = gql`
    query GET_USER_TASKS($id: ID!, $offset: Int, $limit: Int, $searchTerm: String, $groupBy: String, $groupDir: Int, $sortBy: String, $sortDir: Int, $filters: String) {
        userTasks(userId: $id, offset: $offset, limit: $limit, searchTerm: $searchTerm, groupBy: $groupBy, groupDir: $groupDir, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            ...BaseTaskFields
            ticket {
                id
                name
            }
            archived
            hasMore
        }
    }
    ${BASE_TASK_FRAGMENT}
`

export const GET_PROPERTY_TASKS = gql`
  query GET_PROPERTY_TASKS($propertyIds: [ID], $unitIds: [ID], $offset: Int, $limit: Int, $searchTerm: String, $groupBy: String, $groupDir: Int, $sortBy: String, $sortDir: Int, $filters: String) {
        propertyTasks(propertyIds: $propertyIds, unitIds: $unitIds, offset: $offset, limit: $limit, searchTerm: $searchTerm, groupBy: $groupBy, groupDir: $groupDir, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            ...BaseTaskFields
            ticket {
                id
                name
            }
            archived
            hasMore
        }
    }
    ${BASE_TASK_FRAGMENT}
`

export const GET_TASK_ATTACHMENTS = gql`
    query GET_TASK_ATTACHMENTS($taskId: ID!, $offset: Int, $limit: Int, $searchTerm: String, $groupBy: String, $groupDir: Int, $sortBy: String, $sortDir: Int, $filters: String) {
        taskAttachments(taskId: $taskId, offset: $offset, limit: $limit, searchTerm: $searchTerm, groupBy: $groupBy, groupDir: $groupDir, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            id
            file
            originalFilename
            url
            dateChanged
            dateChangedLabel
            uploadedBy {
                id
                fullName
                picture
            }
            dateCreated
            dateCreatedLabel
            hasMore
            category {
                id
                name
                userGroups {
                    id
                    name
                }
            }
        }
    }
`
