import { gql } from '@apollo/client'

export const UPDATE_ATTACHMENT = gql`
    mutation UPDATE_ATTACHMENT($id: ID!, $originalFilename: String, $categoryId: ID) {
        updateAttachment(id: $id, originalFilename: $originalFilename, categoryId: $categoryId) {
            attachment {
                id
                originalFilename
                category {
                    id
                    name
                    userGroups {
                        id
                        name
                    }
                }
            }
        }
    }
`

export const DELETE_ATTACHMENT = gql`
    mutation DELETE_ATTACHMENT($id: ID!) {
        deleteAttachment(id: $id) {
            deletedId
        }
    }
`

export const ADD_ATTACHMENT_TAG = gql`
    mutation ADD_ATTACHMENT_TAG($attachmentId: ID!, $objectId: ID!, $contentTypeStr: String!) {
        addAttachmentTag(attachmentId: $attachmentId, objectId: $objectId, contentTypeStr: $contentTypeStr) {
            attachmentTag {
                id
            }
        }
    }
`

export const CREATE_ATTACHMENT_CATEGORY = gql`
    mutation CREATE_ATTACHMENT_CATEGORY($name: String!, $userGroupIds: [ID]) {
        createAttachmentCategory(name: $name, userGroupIds: $userGroupIds) {
            attachmentCategory {
                id
                name
                userGroups {
                    id
                    name
                }
            }
        }
    }
`

export const REMOVE_ATTACHMENT_CATEGORY = gql`
    mutation REMOVE_ATTACHMENT_CATEGORY($attachmentId: ID!) {
        removeAttachmentCategory(attachmentId: $attachmentId) {
            attachment {
                id
                originalFilename
                category {
                    id
                }
            }
        }
    }
`
