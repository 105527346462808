import { useQuery } from '@apollo/client'
import { useReactiveVar } from '@apollo/client'
import { GET_TEMPLATES } from '../queries'
import { listerCtrlOptions, templateProperties, listerColumns } from '../settings'
import { templateListCtrlsVar } from '../../cache'
import Lister from '../../components/Lister'
import TemplateListItem from './TemplateListItem'

const TemplatesList = () => {
    const vars = useReactiveVar(templateListCtrlsVar)
    const filters = vars.filters.map(f => `${f.field}___${f.id}`).sort().join(',')
    let variables = { ...vars, filters }
    const { data, loading, error, fetchMore } = useQuery(GET_TEMPLATES, {
        variables,
        notifyOnNetworkStatusChange: true
    })

    if (error) return <div className='pos-center-y w-100 t-center'>{error.message}</div>
    
    return (
        <div className='tab__panel--main intersection-root'>
            <Lister
                options={listerCtrlOptions}
                optionsDict={templateProperties}
                listerCtrlVar={templateListCtrlsVar}
                columnKeys={listerColumns}
                items={data?.templates}
                showLoader={loading}
                fetchMore={fetchMore}
                queryFieldName='templates'
                query={GET_TEMPLATES}
                type='templates'
                showFirstColumnLabel
            >
                {(props) => (
                    <TemplateListItem
                        propKeys={listerColumns}
                        {...props}
                    />
                )}
            </Lister>
        </div>
    )
}

export default TemplatesList
