import { useRef, useLayoutEffect, useEffect, Fragment } from 'react'
import { useLocation } from 'react-router-dom'
import scrollApi from '../../utils/scrollUtils'
import { formatDate } from '../../utils/stringUtils'
import { usePrevious } from '../../hooks/usePrevious'
import { currentUserVar } from '../../cache'
import Message from './Message'
import { sortObjectsByFieldPath } from '../../utils'

const groupSortedMemberships = (ms=[]) => {
    let result = []
    
    ms.forEach((m, i) => {
        if (i === 0) {
            result.push({ actionLabel: m.sortField, names: [m.user.fullName] })
        } else if (m.sortField === ms[i-1].sortField) {
            result[result.length-1].names.push(m.user.fullName)
        } else {
            result.push({ actionLabel: m.sortField, names: [m.user.fullName] })
        }
    })

    return result
}

const ChatFeed = ({
    messages,
    threadId,
    userId,
    preventScroll,
    scrollContainer,
    loadOldThreshold,
    isLoading,
    handleContextMenu,
    handleMessageClick,
    hasMoreMessages,
    loadMore,
    memberships,
    areComments,
    areHighlights,
    totalCount
}) => {
    const { hash } = useLocation()
    const prevMsgSender = useRef()
    const prevMsgDay = useRef()
    const anchorMsgEl = useRef()
    const senderKey = areComments ? 'createdBy' : 'sender'
    const dateTimeKey = areComments ? 'createdAt' : 'sentAt'
    const currentUserId = userId || currentUserVar()?.id
    const { scrollToBottom, scrollTo, scrollHeight } = scrollApi
    const msgCount = messages?.length
    let prevMsgCount = usePrevious(msgCount)
    let prevThreadId = usePrevious(threadId)
    let prevContainerRef = usePrevious(scrollContainer?.current)
    let membershipIndex = 0
    const sortedMemberships = memberships ? sortObjectsByFieldPath(memberships.map(m => (
        {...m, sortField: 'joined' , sortValue: m.joinedAt}
    )).concat(memberships.filter(({ leftAt }) => !!leftAt).map(m => (
        {...m, sortField: 'left' , sortValue: m.leftAt}
    ))), 'sortValue') : []
    const loadAll = loadMore ? () => loadMore({
        variables: { offset: messages.length, limit: totalCount - messages.length }
    }) : undefined

    useLayoutEffect(() => {
        setTimeout(() => {
            if (!isLoading && !preventScroll && scrollContainer?.current && msgCount) {
                if (!prevContainerRef || !prevMsgCount || msgCount - prevMsgCount === 1 || threadId !== prevThreadId) {
                    if (hash && anchorMsgEl?.current && (!prevContainerRef || !prevMsgCount || threadId !== prevThreadId)) {
                        anchorMsgEl.current.scrollIntoView()
                    } else {
                        scrollToBottom(scrollContainer.current)
                    }
                } else if (msgCount !== prevMsgCount && loadOldThreshold?.current) {
                    scrollTo(scrollContainer.current, scrollHeight(scrollContainer.current) - loadOldThreshold.current - 10)
                }
            }
        }, 200)
    }, [preventScroll, msgCount, prevMsgCount, scrollContainer, prevContainerRef, isLoading, loadOldThreshold, threadId, prevThreadId])

    useLayoutEffect(() => {
        if (hash) {
            if (anchorMsgEl?.current) {
                setTimeout(() => anchorMsgEl.current.scrollIntoView(), 200)
            } else if (!isLoading && loadAll) {
                loadAll()
            }
        }
    }, [hash, anchorMsgEl?.current, isLoading])

    const renderMembershipUpdates = (ms=[]) => (
        ms.map(({ actionLabel, names }, i) => (
            <div className='t-center mx-auto' key={actionLabel + i}>
                <div className='t-xs t-grey mb-05'>
                    {names.length > 1 ?
                        `${names.slice(0, -1).join(', ')} and ${names.slice(-1)} ${actionLabel}` :
                        `${names[0]} ${actionLabel}`
                    }
                </div>
            </div>
        ))
    )

    return (
        <ul className='chat__feed'>
            {messages && messages.map((message, i) => {
                const isMine = message[senderKey] && message[senderKey].id === currentUserId
                const isNewSeq = message[senderKey] ? message[senderKey].id !== prevMsgSender.current : prevMsgSender.current !== 'unknown'
                const sentDayLabel = formatDate(message[dateTimeKey])?.label
                const isNewDay = i === 0 || sentDayLabel !== prevMsgDay.current
                prevMsgSender.current = message[senderKey] ? message[senderKey].id : 'unknown'
                prevMsgDay.current = sentDayLabel
                let [updatedMemberships, membershipsBeforeDayTag, membershipsAfterLastMsg] = [[], [], []]
                let activeMembers = []

                if (!areComments && sortedMemberships) {
                    let membership = sortedMemberships[membershipIndex]
                    while (membership && membership?.sortValue < message[dateTimeKey]) {
                        if (formatDate(membership.sortValue)?.label === sentDayLabel) {
                            updatedMemberships.push(membership)
                        } else if (i === 0 && !hasMoreMessages) {
                            membershipsBeforeDayTag.push(membership)
                        } else if (i !== 0 && membership.sortValue > messages[i-1][dateTimeKey]) {
                            membershipsBeforeDayTag.push(membership)
                        }

                        membershipIndex += 1
                        membership = sortedMemberships[membershipIndex]
                    }

                    activeMembers = sortedMemberships.slice(0, membershipIndex).filter(m => (
                        m.sortField === 'joined' && (!m.leftAt || m.leftAt > message[dateTimeKey])
                    )).map(m => m.user)

                    if (i === messages.length - 1 && membershipIndex < sortedMemberships.length) {
                        sortedMemberships.slice(membershipIndex).forEach(m => {
                            membershipsAfterLastMsg.push(m)
                        })
                    }
                }

                const _updatedMemberships = groupSortedMemberships(updatedMemberships)
                const _membershipsBeforeDayTag = groupSortedMemberships(membershipsBeforeDayTag)
                const _membershipsAfterLastMsg = groupSortedMemberships(membershipsAfterLastMsg)

                return (
                    <Fragment key={`message-${threadId || message.id}-${i}`}>
                        {!areComments && !areHighlights &&
                            <>
                                {renderMembershipUpdates(_membershipsBeforeDayTag)}
                                {isNewDay &&
                                    <div className='t-center mx-auto'>
                                        <span className='chat__day-label'>
                                            {sentDayLabel}
                                        </span>
                                    </div>
                                }
                                {renderMembershipUpdates(_updatedMemberships)}
                            </>
                        }
                        <Message
                            ref={!areComments && !areHighlights && hash && '#' + message.id === hash ? anchorMsgEl : undefined}
                            isMine={isMine}
                            isNewSeq={isNewSeq}
                            threadId={threadId}
                            handleContextMenu={handleContextMenu ? (e) => handleContextMenu(message, e) : undefined}
                            onClick={handleMessageClick ? (e) => handleMessageClick(message, e) : undefined}
                            members={activeMembers}
                            {...message}
                            sender={message[senderKey]}
                            sentAt={message[dateTimeKey]}
                            sentDayLabel={sentDayLabel}
                            isComment={areComments}
                            isInOcp={areHighlights}
                        />
                        {renderMembershipUpdates(_membershipsAfterLastMsg)}
                    </Fragment>
                )
            })}
        </ul>
    )
}

export default ChatFeed
