import { Link } from 'react-router-dom'
import { newDraftsVar } from '../../cache'
import { tabNavLinks } from '../../constants'
import TaskChecklist from '../../tasks/components/TaskChecklist'
import TicketProperties from './TicketProperties'
import TicketActions from './TicketActions'
import TicketReporter from './TicketReporter'
import { Icon } from '../../components'

const TicketSidePanel = ({ ticket }) => {
    const isEditable = !ticket.archived && !ticket.deleted
    const reporter = ticket.owner || ticket.createdBy

    return (
        <aside className='split-panel--side split-panel--right'>
            <div className='split-panel--side-inner'>
                {reporter &&
                    <TicketReporter
                        {...reporter}
                        createdBy={ticket.createdBy}
                        createdDateLabel={ticket.createdDateLabel}
                        createdDateOffset={ticket.createdDateOffset}
                    />
                }
                <hr/>
                <TicketProperties ticket={ticket} />
                <hr/>
                <section className='ticket__tasks'>
                    <div className='action__li'>
                        <Link
                            className={`btn btn--naked btn--inline-icon${isEditable ? '' : ' disabled'}`}
                            to={tabNavLinks.tasks.new.relativeUrl}
                            onClick={(e) => { 
                                isEditable ? newDraftsVar({ ...newDraftsVar(), tasks: { 
                                    ticket,
                                    ticketId: ticket.id,
                                    priority: '2_MEDIUM',
                                    status: '1_TODO',
                                    approvalRequired: true,
                                    assignee: ticket.assignee,
                                    assigneeId: ticket.assignee?.id
                                } }) : e.preventDefault()
                            }}
                        >
                            <>
                                <Icon type='arrow-right' variant='sm' />
                                {tabNavLinks.tasks.new.btnLabel}
                            </>
                        </Link>
                    </div>
                    {ticket.tasks && !!ticket.tasks.length &&
                        <TaskChecklist tasks={ticket.tasks} disabled={!isEditable} />
                    }
                </section>
                <hr/>
                <TicketActions ticket={ticket} />
            </div>
        </aside>
    )
}

export default TicketSidePanel
