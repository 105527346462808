import { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useQuery, useLazyQuery, useReactiveVar } from '@apollo/client'
import { CREATE_CONTRACT, CREATE_TENANCY_AGREEMENT } from '../../properties/mutations'
import { GET_TENANT_CONTRACTS, GET_CONTRACTS, GET_TENANT_CONTRACT_BY_ID } from '../../properties/queries'
import { GET_PROPERTIES, GET_UNITS } from '../queries'
import { newDraftsVar } from '../../cache'
import { getTenancyAddress } from '../utils'
import { lbl } from '../../constants'

import TenancyAgreementForm from '../../properties/components/TenancyAgreementForm'
import TenancyPeriodLabel from '../../properties/components/TenancyPeriodLabel'
import FilesList from '../../attachments/components/FilesList'
import Accordion from '../../components/Accordion'
import { Label } from '../../components'
import ContractPeriodLabel from '../../properties/components/ContractPeriodLabel'
import ContractInfoForm from '../../properties/components/ContractInfoForm'
import { sortObjectsByFieldPath } from '../../utils'


const NewContractForm = ({ tenantId }) => {
    let { tenantContracts, tenantAgreements } = useReactiveVar(newDraftsVar)
    let contractDraft = tenantContracts[tenantId] || {}
    let agreementDraft = tenantAgreements[tenantId] || {}
    const [prefilledCosts, setPrefillCosts] = useState({})
    const { client, data: propertyData, error: propertyError, loading: propertiesLoading } = useQuery(GET_PROPERTIES)
    const { data: unitsData, error: unitsError, loading: unitsLoading } = useQuery(GET_UNITS)

    let properties = propertyData?.properties
    let propertyOptions = properties ? properties.map((p) => ({
        label: p.longName, value: p.id
    })) : [{ value: '', label: propertyError?.message || propertiesLoading ? 'Loading ...' : '' }]
    let unitOptions = unitsData?.units ? unitsData.units.map((r) => ({
        label: r.longName, value: r.id
    })) : [{ value: '', label: unitsError?.message || unitsLoading ? 'Loading ...' : '' }]

    const prefillCostFields = ({ propertyId, unitId }) => {
        let prefilledFields = {
            monthlyServiceCost: '',
            monthlyRent: '',
            deposit: '',
            otherCosts: ''
        }
        if (unitId) {
            const unit = unitsData?.units.find(({ id }) => id === unitId)
            if (unit) {
                if (unit.monthlyServiceCost) prefilledFields['monthlyServiceCost'] = unit.monthlyServiceCost
                if (unit.monthlyBasicRent) prefilledFields['monthlyRent'] = unit.monthlyBasicRent
                if (unit.deposit) prefilledFields['deposit'] = unit.deposit
            }
        } else if (propertyId) {
            const property = properties?.find(({ id }) => id === propertyId)
            if (property) {
                if (property.monthlyServiceCost) prefilledFields['monthlyServiceCost'] = property.monthlyServiceCost
                if (property.otherCosts) prefilledFields['otherCosts'] = property.otherCosts
            }
        }
        setPrefillCosts(prefilledFields)
    }

    return (
        <div className='p-3 mb-3 field-group__title bg-light br-1'>
            <ContractInfoForm
                draft={contractDraft}
                prefilledCosts={prefilledCosts}
                extraFields={[{
                    name:'propertyId',
                    lblKey: 'property',
                    As: 'search-select',
                    disabled: !!contractDraft.unitId,
                    required: !contractDraft.unitId,
                    wide: true,
                    options: sortObjectsByFieldPath(propertyOptions, 'label'),
                    onChangeCb: (v) => prefillCostFields({ propertyId: v })
                }, {
                    name: 'unitId',
                    lblKey: 'unit',
                    As: 'search-select', 
                    disabled: !!contractDraft.propertyId,
                    required: !contractDraft.propertyId,
                    wide: true,
                    options: sortObjectsByFieldPath(unitOptions, 'label'),
                    onChangeCb: (v) => prefillCostFields({ unitId: v })
                }]}
                submitMutation={CREATE_CONTRACT}
                onCompleted={({ createContract }) => {
                    if (createContract?.contract) {
                        client.cache.updateQuery({
                            query: GET_CONTRACTS,
                        }, (data) => {
                            return { allTenantContracts: [createContract.contract, ...data.allTenantContracts] }
                        })
                        const drafts = { ...newDraftsVar()}
                        drafts.tenantAgreements[tenantId] = {...agreementDraft, contractId: createContract.contract.id }
                        newDraftsVar(drafts)
                    }
                }}
                setDraft={(contractDraft) => {
                    const drafts = { ...newDraftsVar()}
                    drafts.tenantContracts[tenantId] = contractDraft
                    newDraftsVar(drafts)
                }}
            />
        </div>
    )
}


const TenantContracts = ({ tenant, user }) => {
    const history = useHistory()
    const { pathname, hash } = useLocation()
    const [newAgreementOpen, setNewAgreementOpen] = useState(hash === '#new')
    let { tenantAgreements } = useReactiveVar(newDraftsVar)
    let agreementDraft = tenantAgreements[tenant.id] || {}

    const { loading, data, error } = useQuery(GET_TENANT_CONTRACTS, { variables: { id: tenant.id } })
    const [getContracts, { data: contractsData, called: contractsCalled, error: contractsError, loading: contractsLoading }] = useLazyQuery(GET_CONTRACTS)
    
    let contract = agreementDraft['contractId'] && contractsData?.allTenantContracts?.find(({ id }) => id === agreementDraft['contractId'])
    let contractOptions = contractsData?.allTenantContracts ? contractsData.allTenantContracts.map((c) => {
        const { longName } = c.unit || c.property
        const contractName = `${longName}${c.type ? ` / ${c.type.name}` : ''}`
        return ({
            value: c.id,
            contractName,
            label: (
                <div className='d-flex justify-space-between align-center w-100'>
                    <div className='t-ellipsis mr-2'>{contractName}</div>
                    <ContractPeriodLabel {...c} />
                </div>
            ),
            
        })
    }) : [{ value: '', label: contractsError?.message || contractsLoading ? 'Loading ...' : '' }]
    contractOptions.unshift({ value: 'new', label: <div className='d-flex t-blue'>+ Create new contract</div> })
    let units = contract?.unit ? [contract.unit] : contract?.property ? contract.property.rooms : []

    useEffect(() => {
        if (newAgreementOpen && !contractsCalled && !contractsData && !contractsLoading) {
            getContracts()
        } else if (!newAgreementOpen && hash === '#new') {
            history.replace(pathname)
        }
    }, [newAgreementOpen])

    return (
        <>
            {newAgreementOpen ?
                <Accordion
                    isOpen
                    Tag='div'
                    className='bg-lightblue p-1 br-05 mb-1'
                    headerClass='accordion__header--bordered t-md t-heading mr-3'
                    label={lbl.newTenancyAgreement}
                    onOpenCb={() => { setNewAgreementOpen(false) }}
                >
                    <div className='ml-4 mr-4'>
                        <TenancyAgreementForm
                            key={agreementDraft.contractId}
                            draft={{ ...agreementDraft, tenantId: tenant.id, unitId: contract?.unit?.id || undefined }}
                            tenant={{ ...tenant, user }}
                            units={units}
                            isUnitContract={!!contract?.unit}
                            unitId={contract?.unit?.id}
                            property={contract?.property || contract?.unit?.address}
                            propertyId={contract ? contract.property?.id || contract.unit?.address?.id : null }
                            contractId={contract?.id}
                            contract={contract}
                            submitMutation={CREATE_TENANCY_AGREEMENT}
                            close={() => setNewAgreementOpen(false)}
                            setDraft={formDraft => {
                                const drafts = { ...newDraftsVar()}
                                drafts.tenantAgreements[tenant.id] = formDraft
                                newDraftsVar(drafts)
                            }}
                            extraFields={[
                                {
                                    name: 'contractId',
                                    lblKey: 'contract',
                                    wide: true,
                                    As: 'search-select',
                                    options: contractOptions,
                                    sortBy: 'contractName',
                                    searchBy: 'contractName',
                                    keepSelectedInList: false,
                                    required: true,
                                    create: true,
                                    createNewLabel: '+ Create new contract',
                                    onCreateNew: () => {
                                        const drafts = { ...newDraftsVar()}
                                        drafts.tenantAgreements[tenant.id] = {...agreementDraft, contractId: 'new'}
                                        newDraftsVar(drafts)
                                    },
                                    AfterNode: agreementDraft.contractId === 'new' ? <NewContractForm tenantId={tenant.id}/> : undefined
                                },
                                ...contract ? [
                                    contract.property && { name: 'property', disabled: true, value: contract.property.longName, wide: true },
                                    contract.unit && { name: 'unit', disabled: true, value: contract.unit.longName, wide: true },
                                    { name: 'contractType', lblKey: 'contractType', disabled: true, value: contract?.type?.name || '' },
                                    { name: 'contractForIndefinitePeriod', lblKey: 'forIndefinitePeriod', type: 'checkbox', disabled: true, value: !!contract?.forIndefinitePeriod },
                                    { name: 'startDate', lblKey: 'contractStartDate', type: 'date', disabled: true, value: contract?.startDate || '' },
                                    { name: 'endDate', lblKey: 'contractEndDate', type: 'date', disabled: true, value: contract?.endDate || '', AfterNode: () => <div className='field-group__title t-sm mt-2 mb-2'>New tenancy info on existing contract</div> }
                                ] : []
                            ].filter(Boolean)}
                            disableFields={['tenantId', contract?.unit && 'unitId'].filter(Boolean)}
                            disableAllFields={!contract}
                        />
                    </div>
                </Accordion>
            : 
                <button
                    className='btn btn--naked btn--new-item w-100 mb-2'
                    onClick={() => setNewAgreementOpen(true)}
                >
                    <span className="plus">+</span>
                    {lbl.addNewTenantContract}
                </button>
            }
            {loading && <div className='p-3'><div className='loader w-100'/></div>}
            {error && <div className='error t-red w-100 t-center p-3'>{error.message}</div>}
            {data?.tenant?.tenancyAgreements?.map((tenancyAgreement) => {
                const { addressLabel, contractUrl } = getTenancyAddress(tenancyAgreement)
                const isHashed = hash === `#contract_${tenancyAgreement.contract.id}_tenancy_agreement_${tenancyAgreement.id}`
                return (
                    <Accordion
                        key={'tenancyAgreement' + tenancyAgreement.id}
                        Tag='div'
                        isOpen={isHashed}
                        className='bg-light p-1 br-05 mb-1'
                        headerClass='accordion__header--bordered t-md t-heading mr-4'
                        onOpenCb={(isOpen) => {
                            if (isHashed && !isOpen) history.replace(pathname)
                        }}
                        label={
                            <div className='d-flex justify-space-between w-100'>
                                <div className='d-inline-flex' style={{ flexGrow: 1 }}>
                                    {tenancyAgreement.archived &&
                                        <Label
                                            classModifier='grey'
                                            className='mr-1 label--uppercase label--archived'
                                            iconProps={{ type: 'archive', variant: '3xs' }}
                                            label={lbl.archived}
                                        />
                                    }
                                    <Link
                                        className='d-inline-flex justify-space-between t-underline-hover no-wrap'
                                        to={contractUrl}
                                    >
                                        {addressLabel}
                                    </Link>
                                </div>
                                <TenancyPeriodLabel tenancyAgreement={tenancyAgreement}/>
                            </div>
                        }
                    >
                        <div className='ml-4 mr-4'>
                            <TenancyAgreementForm
                                tenancyAgreement={{
                                    ...tenancyAgreement,
                                    tenant: { id: tenant.id, user }
                                }}
                                contract={tenancyAgreement.contract}
                                isUnitContract={!!tenancyAgreement.contract.unit}
                                contractId={tenancyAgreement.contract.id}
                                propertyId={tenancyAgreement.contract.property?.id || tenancyAgreement.contract.unit.address.id}
                                property={tenancyAgreement.contract.property || tenancyAgreement.contract.unit.address}
                                units={tenancyAgreement.contract.unit ?
                                    [tenancyAgreement.contract.unit] :
                                    tenancyAgreement.contract.property.rooms
                                }
                                extraFields={[
                                    tenancyAgreement.contract.property && { name: 'property', disabled: true, value: tenancyAgreement.contract.property.longName, wide: true },
                                    tenancyAgreement.contract.unit && { name: 'unit', disabled: true, value: tenancyAgreement.contract.unit.longName, wide: true },
                                    { name: 'contractType', lblKey: 'contractType', disabled: true, value: tenancyAgreement.contract?.type?.name || '' },
                                    { name: 'contractForIndefinitePeriod', lblKey: 'forIndefinitePeriod', type: 'checkbox', disabled: true, value: !!tenancyAgreement.contract?.forIndefinitePeriod },
                                    { name: 'startDate', lblKey: 'contractStartDate', type: 'date', disabled: true, value: tenancyAgreement.contract?.startDate || '' },
                                    { name: 'endDate', lblKey: 'contractEndDate', type: 'date', disabled: true, value: tenancyAgreement.contract?.endDate || '' },
                                ].filter(Boolean)}
                                disableFields={['tenantId']}
                            />
                            <div className='bb mt-2 mb-3'/>
                            <div className='t-sm mb-1 t-grey'>FILES</div>
                            <FilesList
                                contentTypeModel='contract'
                                objectId={tenancyAgreement.contract.id}
                                uploadBtnLabel='Upload file to contract'
                                disableUpload={tenancyAgreement.archived}
                            />
                        </div>
                    </Accordion>
                )
            })}
        </>
    )
}

export default TenantContracts
