import { forwardRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { ProfileImage, Icon, UserChip, Popover } from '../../components'
import { tabNavLinks } from '../../constants'
import ReadReceipts from './ReadReceipts'

const emptyMsgPatterns = ['<p></p>', '<p>', '</p>', '<br>', '<p><br></p>', '<p><br>', '</p><br>', '<br><p>', '<br></p>']


const Message = forwardRef(({
    id,
    threadId,
    sender,
    readreceiptSet,
    sentAt,
    sentDayLabel,
    sentAsEmail,
    title,
    body,
    refMsg,
    fwdMsg,
    isHighlighted,
    isImportant,
    isNotification,
    isMine,
    isNewSeq,
    handleContextMenu,
    onClick,
    members,
    as='li',
    hideTime=false,
    archived=false,
    isComment,
    isInOcp
}, ref) => {
    const referenceMsg = fwdMsg || refMsg
    const bodyEls = body?.split(/<img(.*?)>|<span class="fr-vid(.*?)<\/span>|<span contenteditable="false" draggable="true" class="fr-vid(.*?)<\/span>|<p>\s?<span class="fr-emo(.*?)<\/span>\s?<\/p>|<p data-f-id="(.*?)<\/p>/g) || []
    const refBodyEls = referenceMsg?.body?.split(/<img(.*?)>/g) || []
    let [showReadReceipts, setShowReadReceipts] = useState(false)
    let [bigImage, setBigImage] = useState('')
    const Tag = as
    const sentAtDate = new Date(sentAt)
    const sentDateTimeLabel = sentAtDate.toLocaleString()
    const sentTimeLabel = sentAtDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    const readBy = readreceiptSet ? readreceiptSet.map(rr => rr.user.id) : []
    const allMembersHaveRead = members?.length && members.every(m => readBy.includes(m.id))
    
    const renderTimeLabel = () => (
        <div className='d-flex align-center justify-end'>
            <div className='msg__timestamp t-grey'>
                {isComment || isInOcp ? `${sentDayLabel}, ${sentTimeLabel}`: sentTimeLabel}
            </div>
            {readreceiptSet && members &&
                <button
                    onClick={() => setShowReadReceipts(true)}
                    className='btn btn--naked d-flex'
                >
                    <Icon
                        type={allMembersHaveRead ? 'checkmark-double' : 'checkmark'}
                        variant='2xs'
                    />
                </button>
            }
        </div>
    )

    return (
        <>
            <Tag
                ref={ref}
                className={`chat__msg msg msg--${isNotification ? 'notification' : isMine ? 'right' : sender?.type}${isNewSeq ? ' mt-1' : ''}${isHighlighted ? ' msg--highlighted' : ''}${isImportant ? ' msg--important' : ''}${archived ? ' msg--archived' : ''}${isComment ? ' msg--comment' : ''}`}
                onContextMenu={handleContextMenu}
                id={`${isInOcp ? 'ocp-' : ''}message-${id}`}
            >
                <div className='msg__main'>
                    {!isMine && !isNotification &&
                        <Link
                            className='msg__sender'
                            to={tabNavLinks.contacts.dynamicPath + sender?.id}
                            title={sender?.fullName}
                            key={sender?.fullName}
                            onClick={sender ? undefined : (e) => e.preventDefault()}
                            style={sender ? undefined : { cursor: 'default' }}
                        >
                            <ProfileImage url={sender?.picture} size='xs' />
                        </Link>
                    }
                    {referenceMsg ?
                        <div className='msg__body' onClick={onClick}>
                            <div className='d-inline-flex t-xs mx--05'>
                                <Icon
                                    type={fwdMsg ? 'arrow-right' : 'reply'}
                                    variant='2xs'
                                    classAdditions='mr-05'
                                />
                                <i>{fwdMsg ? 'forwarded' : 'reply'}</i>
                            </div>
                            <Link
                                className='d-block t-none msg__ref mb-05 mx--05'
                                to={`${tabNavLinks.messenger.dynamicPath}${threadId}#${referenceMsg.id}`}
                                onClick={() => {
                                    const refMessageEl = document.getElementById(`message-${referenceMsg.id}`)
                                    refMessageEl?.scrollIntoView({ behavior: 'smooth' })
                                }}
                            >
                                <div className='msg-ref t-xs'>
                                    <div className='msg-ref__header mb-05'>
                                        <div>{referenceMsg.sender?.fullName}</div>
                                    </div>
                                    <div className='msg-ref__body'>
                                        {refBodyEls.map((el, i) => {
                                            if (el && !emptyMsgPatterns.includes(el.trim())) {
                                                if (el.indexOf(' src=') === 0) {
                                                    return (
                                                        <div
                                                            onClick={(e) => e.target.src && setBigImage(e.target.src.replace('small', ''))}
                                                            key={'ref-msg'+i}
                                                            dangerouslySetInnerHTML={{ __html: `<img${el}>` }}
                                                        />
                                                    )
                                                }
                                                return (
                                                    <div
                                                        key={'ref-msg'+i}
                                                        dangerouslySetInnerHTML={{ __html: el }}
                                                    />
                                                )
                                            }
                                            return null
                                        })}
                                    </div>
                                </div>
                            </Link>
                            {title && <div className='t-bold'>{title}</div>}
                            <div
                                className='t-sm'
                                dangerouslySetInnerHTML={{ __html: body }}
                            />
                        </div>
                    : title ?
                        <div className='msg__body t-sm' onClick={onClick}>
                            <div className='t-bold mb-05'>{title}</div>
                            <div className='t-sm' dangerouslySetInnerHTML={{ __html: body }} />
                            {(isNotification) && sender &&
                                <Link
                                    className='d-block t-none t-xs mt-1'
                                    to={tabNavLinks.contacts.dynamicPath + sender.id}
                                    title={sender.fullName}
                                    key={sender.fullName}
                                >
                                    <UserChip user={sender} />
                                </Link>
                            }
                        </div>
                    :
                        <div className='msg__body-wrapper' onClick={onClick}>
                            {bodyEls.map((el, i) => {
                                if (el && !emptyMsgPatterns.includes(el.trim())) {
                                    if (el.indexOf(' src=') === 0) {
                                        return (
                                            <div
                                                onClick={(e) => e.target.src && setBigImage(e.target.src.replace('small', ''))}
                                                key={'msg'+i}
                                                className='msg__img'
                                                dangerouslySetInnerHTML={{ __html: `<img${el}>` }}
                                            />
                                        )
                                    }
                                    if (el.indexOf('eo ') === 0) {
                                        return (
                                            <div
                                                key={'msg'+i}
                                                className='msg__video'
                                                dangerouslySetInnerHTML={{ __html: `<span class="fr-vid${el}</span>` }}
                                            />
                                        )
                                    }
                                    if (el.indexOf('ticon ') === 0) {
                                        return (
                                            <div
                                                key={'msg'+i}
                                                className='msg__emoticon'
                                                dangerouslySetInnerHTML={{ __html: `<span class="fr-emo${el}</span>` }}
                                            />
                                        )
                                    }
                                    if (el.indexOf('pbf') === 0) {
                                        return null
                                    }
                                    return (
                                        <div
                                            key={'msg'+i}
                                            className='msg__body t-sm'
                                            dangerouslySetInnerHTML={{ __html: el }}
                                        />
                                    )
                                }
                                return null
                            })}
                        </div>
                    }
                    <div className='ml-auto msg__icons'>
                        {ref && <Icon type='link' variant='2xs' />}
                        {isHighlighted && <Icon type='highlight' variant='2xs' />}
                        {isImportant && <Icon type='important' variant='2xs' />}
                        {archived && <Icon type='archive' variant='2xs' />}
                        {sentAsEmail && <Icon type='email' variant='2xs' />}
                    </div>
                </div>
                {!hideTime && renderTimeLabel()}
            </Tag>
            {showReadReceipts &&
                <Popover
                    isOpen
                    className='popover--no-padding'
                    onClose={() => setShowReadReceipts(false)}
                >
                    <ReadReceipts
                        receipts={readreceiptSet}
                        members={members}
                        sender={sender}
                        readByIds={readBy}
                        sentAtLabel={sentDateTimeLabel}
                        allMembersHaveRead={allMembersHaveRead}
                    />
                </Popover>
            }
            {bigImage &&
                <Popover
                    isOpen
                    className='popover--no-padding popover--image'
                    onClose={() => setBigImage('')}
                >
                    <>
                        <img src={bigImage}
                            alt='lightbox'
                            onContextMenu={e => {
                                e.stopPropagation()
                                return false
                            }}
                        />
                        <a
                            href={bigImage}
                            download
                            className='download'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <Icon type='download' variant='xs' />
                        </a>
                    </>
                </Popover>
            }
        </>
    )
})

export default Message
