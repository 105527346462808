import { useState } from 'react'
import { useHistory, useLocation, Link } from 'react-router-dom'
import { useMutation, gql } from '@apollo/client'
import { lbl } from '../constants'
import { errorContains, setCookie, CSRF_TOKEN_FROM_BODY } from '../utils'
import { isLoggedInVar } from '../cache';

const LOGIN = gql`
  mutation LOGIN($username: String!, $password: String!) {
    login(username: $username, password: $password) {
        ok
        csrfToken
    }
  }
`

const LogIn = () => {
    let usernameInput
    let passwordInput
    let [showError, setShowError] = useState(false)
    let history = useHistory()
    let location = useLocation()
    let from = location.state && location.state.from.pathname !== '/login' ? location.state.from : { pathname: '/' }
    
    const [login, { client, loading, error }] = useMutation(LOGIN, {
        onError: () => { setShowError(true) },
        onCompleted: async (data) => { 
            isLoggedInVar(true);
            await client.clearStore()
            if (data.login && data.login.csrfToken) {
                setCookie(CSRF_TOKEN_FROM_BODY, data.login.csrfToken)
            }
            history.push(from)
            history.go()
        }
    })
    const handleOnChange = () => {if (showError) setShowError(false)}
    const isInvalid = errorContains(error, ['username', 'password'])

    return (
        <div className='contentbox--sm'>
            <div className='card'>
                <div className='logo--center'>
                    <img src={process.env.PUBLIC_URL + '/logo_long.svg'} alt='logo'/>
                </div>
                <form
                    method='post'
                    onSubmit={e => {
                        e.preventDefault()
                        login({ 
                            variables: {
                                username: usernameInput.value,
                                password: passwordInput.value
                            }
                        })
                    }}
                >
                    <fieldset disabled={loading} aria-busy={loading}>
                        <fieldset className={isInvalid.username && showError ? 'error' : ''}>
                            <label htmlFor='username'>
                                {lbl.username}
                            </label>
                            <input
                                type='text'
                                name='username'
                                defaultValue=''
                                required
                                ref={node => { usernameInput = node }}
                                onChange={isInvalid.username ? handleOnChange : undefined}
                            />
                            {isInvalid.username && showError &&
                                <p className='input-message input-message--error'>
                                    {error.message}
                                </p>
                            }
                        </fieldset>
                        <fieldset className={isInvalid.password && showError ? 'error' : ''}>
                            <label htmlFor='password'>
                                {lbl.password}
                            </label>
                            <input
                                type='password'
                                name='password'
                                defaultValue=''
                                required
                                ref={node => { passwordInput = node }}
                                onChange={isInvalid.password ? handleOnChange : undefined}
                            />
                            {isInvalid.password && showError ?
                                <p className='input-message input-message--error'>
                                    {`${error.message}. ${lbl.tryAgainOr} `}<Link to='/request_reset'>{lbl.requestPassReset}</Link>.
                                </p> :
                                <Link to='/request_reset' className='input-message t-lightgrey'>{lbl.forgotPassQuestion}</Link>
                            }
                        </fieldset>
                        <div className='spacer--4'/>
                        <button
                            className='btn btn--primary d-block ml-auto'
                            type='submit'
                        >
                            {loading ? <span className='btn__loader'>&#8635;</span> : lbl.login}
                        </button>
                    </fieldset>
                </form>
            </div>
        </div>
    )
}

export default LogIn
