import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { CREATE_TENANCY_AGREEMENT } from '../mutations'
import { Accordion, Label, ProfileImage } from '../../components'
import TenancyAgreementForm from './TenancyAgreementForm'
import { lbl, tabNavLinks } from '../../constants'
import { newDraftsVar } from '../../cache'
import DateString from '../../components/DateString'
import ContractInfoForm from './ContractInfoForm'
import TenancyPeriodLabel from './TenancyPeriodLabel'
import FilesList from '../../attachments/components/FilesList'

const ContractInfo = ({
    contract={},
    units,
    unitId,
    property,
    propertyId,
    extraFields=[],
    draft,
    submitMutation,
    close=()=>{},
    setDraft,
    labelAddition
}) => {
    const [newTenancyAgreementOpen, setNewTenancyAgreementOpen] = useState(false)
    const { hash } = useLocation()
    const contractEl = useRef()
    const tenancyAgreementEl = useRef()
    let { tenancyAgreements } = useReactiveVar(newDraftsVar)
    let tenancyAgreementDraft = contract.id ? tenancyAgreements[contract.id] || {} : {}
    const period = (
        <div className='d-inline-flex'>
            {contract.startDate ? <DateString dateStr={contract.startDate} /> : 'N/A'}
            <span className='ml-1 mr-1'>-</span>
            {contract.forIndefinitePeriod ?
                <div>{lbl.indefinite}</div>
            : contract.endDate ?
                <DateString dateStr={contract.endDate} />
            : 'N/A'}
        </div>
    )

    const resetTenancyAgreementDraft = () => {
        const d = { contractId: contract.id, unitId: contract.unit?.id || unitId }
        if (!d.checkInDate) d.checkInDate = contract.startDate
        if (!d.rentResponsabilityStartDate) d.rentResponsabilityStartDate = d.checkInDate
        if (!d.checkOutDate && !d.rentResponsabilityEndDate && contract.forIndefinitePeriod) d.forIndefinitePeriod = true
        if (!d.forIndefinitePeriod) {
            if (!d.checkOutDate) d.checkOutDate = contract.endDate
            if (!d.rentResponsabilityEndDate) d.rentResponsabilityEndDate = d.checkOutDate
        }
        const drafts = { ...newDraftsVar()}
        drafts.tenancyAgreements[contract.id] = d
        newDraftsVar(drafts)
    }

    useEffect(() => {
        if (!tenancyAgreements[contract.id]) resetTenancyAgreementDraft()
    }, [])

    useLayoutEffect(() => {
        if (hash && contract.id) {
            let el
            if (hash === `#contract_${contract.id}` && contractEl?.current) {
                el = contractEl.current
            } else if (hash.includes(`#contract_${contract.id}_tenancy_agreement`) && tenancyAgreementEl?.current) {
                el = tenancyAgreementEl.current
            }
            if (el) {
                setTimeout(() => el.scrollIntoView({
                    behavior: 'smooth', block: 'start'
                }), 100)
            }
        }
    }, [hash, contractEl.current, tenancyAgreementEl.current, contract.id])

    return (
        <div
            className='bg-light mb-2 br-1'
            id={`contract_${contract.id}`}
            ref={contractEl}
        >
            <Accordion
                isOpen={hash === `#contract_${contract.id}`}
                Tag='div'
                className={`bg-light p-1 br-05 ${(contract.active && !contract.archived) ? '' : ' inactive'}`}
                headerClass='accordion__header--bordered t-md t-heading mr-3'
                label={
                    <div className='d-flex justify-space-between'>
                        <span>
                            {labelAddition && `${labelAddition} / `}
                            {contract.type?.name || 'Contract'}
                            {contract.archived &&
                                <Label
                                    classModifier='grey'
                                    className='ml-1 label--uppercase label--archived'
                                    iconProps={{ type: 'archive', variant: '3xs' }}
                                    label={lbl.archived}
                                />
                            }
                        </span>
                        {period}
                    </div>
                }
            >
                <div className='ml-4 mr-4'>
                    <ContractInfoForm
                        contract={contract}
                        extraFields={extraFields}
                        draft={draft}
                        submitMutation={submitMutation}
                        close={close}
                        setDraft={setDraft}
                    />
                    <div className='bb mt-2 mb-3'/>
                    <div className='t-sm mb-1 t-grey'>FILES</div>
                    <FilesList
                        contentTypeModel='contract'
                        objectId={contract.id}
                        uploadBtnLabel='Upload file to contract'
                        disableUpload={contract.archived}
                    />
                    <div className='bb mt-2 mb-3'/>
                    <div className='t-sm t-grey'>TENANTS</div>
                </div>
            </Accordion>
            <div className='ml-3 mr-3' key={contract?.contractTenancyAgreements?.length}>
                {contract?.contractTenancyAgreements?.map((tenancyAgreement) => (
                    <Accordion
                        key={tenancyAgreement.id + tenancyAgreement.tenant.user.fullName}
                        isOpen={hash === `#contract_${contract.id}_tenancy_agreement_${tenancyAgreement.id}`}
                        Tag='div'
                        className={`bg-lightgrey--open br-05 mb-05${(tenancyAgreement.active && !tenancyAgreement.archived) ? '' : ' inactive'}`}
                        headerClass='t-s t-heading p-1'
                        label={
                            <div className='d-flex justify-space-between ml-05'>
                                <div className='d-inline-flex'>
                                    <Link
                                        className='d-inline-flex t-underline-hover'
                                        to={tabNavLinks.contacts.dynamicPath + tenancyAgreement.tenant.user.id}
                                    >
                                        <ProfileImage size='xs' url={tenancyAgreement.tenant.user.picture} />
                                        <div className='ml-1'>{tenancyAgreement.tenant.user.fullName}</div>
                                    </Link>
                                    {tenancyAgreement.archived &&
                                        <Label
                                            classModifier='grey'
                                            className='ml-1 label--uppercase label--archived'
                                            iconProps={{ type: 'archive', variant: '3xs' }}
                                            label={lbl.archived}
                                        />
                                    }
                                </div>
                                <TenancyPeriodLabel tenancyAgreement={tenancyAgreement}/>
                            </div>
                        }
                    >
                        <div
                            className='ml-3 mr-3 mt-3 pb-1'
                            id={`contract_${contract.id}_tenancy_agreement_${tenancyAgreement.id}`}
                            ref={hash === `#contract_${contract.id}_tenancy_agreement_${tenancyAgreement.id}` ? tenancyAgreementEl : undefined}
                        >
                            <TenancyAgreementForm
                                tenancyAgreement={tenancyAgreement}
                                propertyId={propertyId}
                                property={property}
                                units={contract.unit ? [contract.unit] : units}
                                isUnitContract={!!unitId}
                                contractId={contract.id}
                                contract={contract}
                            />
                        </div>
                    </Accordion>
                ))}
                {newTenancyAgreementOpen ?
                    <div className='pb-2'>
                        <Accordion
                            isOpen
                            Tag='div'
                            className='bg-lightblue pb-1 br-05 mb-1'
                            headerClass='t-heading t-s p-1'
                            label={<div className='ml-05'>{lbl.newTenancyAgreement}</div>}
                            onOpenCb={() => { setNewTenancyAgreementOpen(false) }}
                        >
                            <div className='ml-3 mr-3 mt-2'>
                                <TenancyAgreementForm
                                    draft={tenancyAgreementDraft}
                                    propertyId={propertyId}
                                    contractId={contract.id}
                                    contract={contract}
                                    property={property}
                                    units={contract.unit ? [contract.unit] : units}
                                    submitMutation={CREATE_TENANCY_AGREEMENT}
                                    close={() => {
                                        resetTenancyAgreementDraft()
                                        setNewTenancyAgreementOpen(false)
                                    }}
                                    setDraft={agreementDraft => {
                                        const drafts = { ...newDraftsVar()}
                                        drafts.tenancyAgreements[contract.id] = agreementDraft
                                        newDraftsVar(drafts)
                                    }}
                                    isUnitContract={!!unitId}
                                />
                            </div>
                        </Accordion>
                    </div>
                : (contract.id && !contract.archived) ?
                    <div className='mt-1 pb-2'>
                        <button
                            className='btn btn--naked btn--new-item w-100'
                            onClick={() => { setNewTenancyAgreementOpen(true) }}
                        >
                            <span className="plus">+</span>
                            {lbl.addTenantToContract}
                        </button>
                    </div>
                : null }
            </div>
        </div>
    )
}

export default ContractInfo
