import { Switch, Route, Redirect } from 'react-router-dom'
import Contracts from './Contracts'
import PropertyAttachments from './PropertyAttachments'
import PropertyInfoForms from './PropertyInfoForms'
import PropertyLeads from './PropertyLeads'
import PropertyNotes from './PropertyNotes'
import PropertyServices from './PropertyServices'
import PropertyTickets from './PropertyTickets'
import PropertyTasks from './PropertyTasks'
import UnitLeads from './UnitLeads'
import PropertyThreads from './PropertyMessages'
import PropertyCalls from './PropertyCalls'
import RedirectToLastSubtab from '../../components/RedirectToLastSubtab'

const PropertyDetailRouter = (props) => {
    const { urlRoot, contentType, objectId, ...data } = props

    return (
        <Switch>
            <Route path={`${urlRoot}/info`}>
                <PropertyInfoForms
                    contentType={contentType}
                    objectId={objectId}
                    {...data}
                />
            </Route>
            <Route path={`${urlRoot}/services`}>
                <PropertyServices objectId={objectId} contentType={contentType} />
            </Route>
            <Route path={`${urlRoot}/contracts`}>
                <Contracts {...props} />
            </Route>
            <Route path={`${urlRoot}/leads`}>
                {contentType === 'address' ?
                    <PropertyLeads
                        id={objectId}
                        property={data?.addresses ? data.addresses[0] : undefined}
                        units={data.units}
                    />
                : contentType === 'room' ?
                    <UnitLeads unit={data?.units ? data.units[0] : undefined} />
                :
                    <Redirect to={{ pathname: `${urlRoot}/info` }}/>
                }
            </Route>
            <Route path={`${urlRoot}/activity`}>
                <div>{contentType} activity</div>
            </Route>
            <Route path={`${urlRoot}/tickets`}>
                <PropertyTickets contentType={contentType} {...data} />
            </Route>
            <Route path={`${urlRoot}/tasks`}>
                <PropertyTasks contentType={contentType} {...data} />
            </Route>
            <Route path={`${urlRoot}/threads`}>
                <PropertyThreads
                    contentType={contentType}
                    objectId={objectId}
                    {...data}
                />
            </Route>
            <Route path={`${urlRoot}/calls`}>
                <PropertyCalls contentType={contentType} {...data} />
            </Route>
            <Route path={`${urlRoot}/notes`}>
                <PropertyNotes
                    contentType={contentType}
                    objectId={objectId}
                    {...data}
                />
            </Route>
            <Route path={`${urlRoot}/attachments`}>
                <PropertyAttachments
                    contentType={contentType}
                    objectId={objectId}
                    {...data}
                />
            </Route>
            <Route path={urlRoot}>
                <RedirectToLastSubtab urlRoot={urlRoot}/>
            </Route>
        </Switch>
    )
}

export default PropertyDetailRouter
