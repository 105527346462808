import { gql } from '@apollo/client'

export const NOTE_FRAGMENT = gql`
    fragment NoteFields on NoteType {
        id
        body
        pinned
        archived
        createdAt
        createdBy {
            id
            fullName
            picture
        }
        comments {
            id
            body
            archived
            createdAt
            createdBy {
                id
                fullName
                picture
            }
        }
    }
`

export const BASIC_NOTE_FRAGMENT = gql`
    fragment BasicNoteFields on NoteType {
        id
        body
        createdAt
        createdBy {
            id
            fullName
        }
    }
`
