import { gql } from '@apollo/client'
import { CALL_FRAGMENT, FULL_CALL_FRAGMENT } from './fragments'

export const GET_CALL = gql`
    query GET_CALL($sid: String!) {
        call(sid: $sid) {
            ...AllCallFields
        }
    }
    ${FULL_CALL_FRAGMENT}
`

export const GET_CALLS = gql`
    query GET_CALLS(
        $offset: Int, $limit: Int, $searchTerm: String, $groupBy: String, $groupDir: Int, $sortBy: String, $sortDir: Int, $filters: String
    ) {
        calls(offset: $offset, limit: $limit, searchTerm: $searchTerm, groupBy: $groupBy, groupDir: $groupDir, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            ...CallFields
            hasMore
        }
    }
    ${CALL_FRAGMENT}
`


export const GET_MISSED_CALLS_COUNT = gql`
    query GET_MISSED_CALLS_COUNT($departmentId: ID) {
        missedCallsCount(departmentId: $departmentId)
    }
`

export const GET_PROPERTY_CALLS = gql`
  query GET_PROPERTY_CALLS($propertyIds: [ID], $unitIds: [ID], $offset: Int, $limit: Int, $searchTerm: String, $groupBy: String, $groupDir: Int, $sortBy: String, $sortDir: Int, $filters: String) {
        propertyCalls(propertyIds: $propertyIds, unitIds: $unitIds, offset: $offset, limit: $limit, searchTerm: $searchTerm, groupBy: $groupBy, groupDir: $groupDir, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            ...CallFields
            hasMore
        }
    }
    ${CALL_FRAGMENT}
`
