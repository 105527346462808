import { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { staticTabs } from '../../constants'
import { TabNavContext } from '../../components/TabNav'
import TicketDetail from './TicketDetail'
import TicketLister from './TicketLister'
import TicketsArchive from './TicketsArchive'
import TicketBoard, { EmptyBoard } from './TicketBoard'
import NewTicket from './TicketNew'


const TicketsLoader = () => {
    const { id, activeTab } = useContext(TabNavContext)

    switch (activeTab) {
        case 'board':
            return id ? <TicketBoard id={id} /> : <EmptyBoard/>
        case 'list':
            return <TicketLister/>
        case 'archive':
            return <TicketsArchive/>
        case 'ticket':
            return <TicketDetail key={activeTab + id} />
        case 'new':
            return <NewTicket/>
        default:
            return <Redirect to={staticTabs.tickets[0].relativeUrl} />
    }
}

export default TicketsLoader
