import { useState } from 'react'
import { useParams, Redirect } from 'react-router-dom'
import { useQuery, useMutation, gql } from '@apollo/client'
import { CURRENT_USER_QUERY } from '../queries'
import { lbl } from '../constants'
import { errorContains } from '../utils'


const GET_USER_BY_INVITATION_TOKEN = gql`
    query GET_USER_BY_INVITATION_TOKEN($invitationToken: String!) {
        userByInvitationToken(invitationToken: $invitationToken) {
            firstName
            username
        }
    }
`;

const JOIN_MUTATION = gql`
  mutation JOIN_MUTATION($invitationToken: String!, $password: String!, $confirmPassword: String!, $username: String!) {
    join(invitationToken: $invitationToken, password: $password, confirmPassword: $confirmPassword, username: $username) {
        user {
            id
            email
            username
            firstName
            type
        }
    }
  }
`

const Join = () => {
    let { invitationToken } = useParams()
    let [showError, setShowError] = useState(false)
    let [showGuestMessage, setShowGuestMessage] = useState(false)
    let [shouldRedirect, setRedirect] = useState(false)
    let [state, setState] = useState({ username: '', password: '',  confirmPassword: ''})

    const { data } = useQuery(GET_USER_BY_INVITATION_TOKEN, {
        variables: { invitationToken },
        fetchPolicy: 'no-cache',
        onCompleted: ({ userByInvitationToken }) => { setState(prevState => ({ ...prevState, username: userByInvitationToken.username })) }
    })
    
    const [join, { loading, error, called }] = useMutation(JOIN_MUTATION, {
        onError: () => setShowError(true),
        onCompleted: ({ join: { user } }) => {
            if (user?.type === 'employees') {
                setTimeout(() => { setRedirect(true) }, 2000)
            } else {
                setShowGuestMessage(true)
            }
        }
    })

    const errMsg = error && error.message.replace(/',|'|]|\[/g, '')
    const isInvalid = errorContains(error, ['username', 'password'])
    const success = !error && !loading && called
    
    const handleChange = e => {
        const { name, value } = e.target;
        setState(prevState => {
            showError && setShowError(false)
            return { ...prevState, [name]: value }
        });
    };

    if (shouldRedirect) return (<Redirect to={{ pathname: '/profile' }} />)

    return (
        <div className='contentbox--lg'>
            <div className='logo--center'>
                <img src={process.env.PUBLIC_URL + '/logo_long.svg'} alt='logo'/>
            </div>
            <h2 className='t-heading t-lg--responsive mb-6'>
                {lbl.hi} <strong>{ (data && data.userByInvitationToken && data.userByInvitationToken.firstName) || lbl.user}</strong>,
                <br/>
                {lbl.welcomeTo} University Housing!
                <br/>
                {success ? shouldRedirect ? lbl.redirectingToProfile : '' : lbl.joinIntro}
            </h2>
            <div className='card'>
                <form
                    method='post'
                    data-test='form'
                    onSubmit={async e => {
                        e.preventDefault();
                        await join({
                            variables: { invitationToken, ...state },
                            refetchQueries: [{ query: CURRENT_USER_QUERY }]
                        });
                    }}
                    >
                    <fieldset disabled={loading || success} aria-busy={loading}>
                        <br/>
                        { success ?
                            <fieldset className='success'>
                                <label htmlFor='success'>
                                    {lbl.success}
                                </label>
                                <input
                                    type='text'
                                    name='success'
                                    value={lbl.setPassSuccess}
                                    onChange={handleChange}
                                />
                            </fieldset>
                        : <>
                            <fieldset className={isInvalid.username && showError ? 'error' : ''}>
                                <label htmlFor='username'>
                                    {lbl.username}
                                </label>
                                <input
                                    type='text'
                                    name='username'
                                    required
                                    value={state.username}
                                    onChange={handleChange}
                                />
                                {isInvalid.username && showError &&
                                    <p className='input-message input-message--error'>
                                        {errMsg}
                                    </p>
                                }
                            </fieldset>
                            <fieldset className={isInvalid.password && showError ? 'error' : ''}>
                                <label htmlFor='password'>
                                    {lbl.yourPass}
                                </label>
                                <input
                                    type='password'
                                    name='password'
                                    placeholder=''
                                    required
                                    value={state.password}
                                    onChange={handleChange}
                                />
                            </fieldset>
                            <fieldset
                                className={isInvalid.password && showError ? 'error' : ''}>
                                <label htmlFor='confirmPassword'>
                                    {lbl.yourPassRetype}
                                </label>
                                <input
                                    type='password'
                                    name='confirmPassword'
                                    placeholder=''
                                    required
                                    value={state.confirmPassword}
                                    onChange={handleChange}
                                />
                                {isInvalid.password && showError &&
                                    <p className='input-message input-message--error'>
                                        {errMsg}
                                    </p>
                                }
                            </fieldset>
                        </>}
                        <br/>
                        {showGuestMessage ?
                            <p>Download <strong>University Housing Home App</strong> on your mobile device and sign in with your new credentials.</p>
                        :
                            <button
                                className={`btn d-block mx-auto btn--${success ? 'success' : 'primary'}`}
                                type='submit'
                            >
                                {loading || success ? <span className='btn__loader'>&#8635;</span> : lbl.confirm}
                            </button>
                        }
                    </fieldset>
                </form>
            </div>
        </div>
    )
}

export default Join;
