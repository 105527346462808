import { Link } from 'react-router-dom'
import { Icon, ProfileImage } from '../../components'
import { lbl, tabNavLinks } from '../../constants'

const ReadReceipts = ({
    receipts,
    members,
    sender,
    sentAtLabel,
    allMembersHaveRead
}) => (
    <ul className='read-receipts'>
        {sender &&
            <>
                <li className='p-1 bg-grey t-xs t-uppercase'>
                    <Icon
                        type='arrow-right'
                        variant='2xs'
                        wrapperClassAdditions='mr-1'
                    />
                    {lbl.sentBy}
                </li>
                <li key={`sent-by-${sender.id}`} className='p-1 d-flex'>
                    <Link
                        to={tabNavLinks.contacts.dynamicPath + sender.id}
                        className='t-none d-inline-flex'
                    >
                        <ProfileImage url={sender.picture} size='2xs' />
                        <div className='ml-1 mr-1'>{sender.fullName}</div>
                    </Link>
                    <div className='ml-auto t-xs'>
                        {sentAtLabel}
                    </div>
                </li>
            </>
        }
        <li className='p-1 bg-grey t-xs t-uppercase'>
            <Icon
                type='checkmark-double'
                variant='2xs'
                wrapperClassAdditions='mr-1'
            />
            {lbl.seenBy}
        </li>
        {receipts.map(({ user, readAt }) => {
            if (members.length === 1 || user.id !== sender?.id) return (
                <li key={`read-by-${user.id}`} className='p-1 d-flex'>
                    <Link
                        to={tabNavLinks.contacts.dynamicPath + user.id}
                        className='t-none d-inline-flex'
                    >
                        <ProfileImage url={user.picture} size='2xs' />
                        <div className='ml-1'>{user.fullName}</div>
                    </Link>
                    <div className='ml-auto t-xs'>
                        {new Date(readAt).toLocaleString()}
                    </div>
                </li>
            )
            return null
        })}
        {!allMembersHaveRead && 
            <li className='p-1 d-flex justify-center'>
                <Icon type='dots-horizontal' variant='2xs' />
            </li>
        }
        {!!members.length &&
            <>
                <li className='p-1 bg-grey t-xs t-uppercase'>
                    <Icon
                        type='checkmark'
                        variant='2xs'
                        wrapperClassAdditions='mr-1'
                    />
                    {lbl.deliveredTo}
                </li>
                {members.map(user => {
                    if ((members.length === 1 || user.id !== sender?.id)) return (
                        <li key={`read-by-${user.id}`} className='p-1 d-flex'>
                            <Link
                                to={tabNavLinks.contacts.dynamicPath + user.id}
                                className='t-none d-inline-flex'
                            >
                                <ProfileImage url={user.picture} size='2xs' />
                                <div className='ml-1 mr-1'>{user.fullName}</div>
                            </Link>
                            <div className='ml-auto t-xs'>
                                {sentAtLabel}
                            </div>
                        </li>
                    )
                    return null
                })}
            </>
        }
    </ul>
)

export default ReadReceipts
