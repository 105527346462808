import { useState, useRef, useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { ADD_METER_READING } from '../mutations'
import { Accordion, Label } from '../../components'
import MeterReadingForm from './MeterReadingForm'
import { lbl } from '../../constants'
import { newDraftsVar } from '../../cache'
import DateString from '../../components/DateString'


const MeterReadings = ({ meter={} }) => {
    const { hash } = useLocation()
    const meterReadingEl = useRef()
    const [newMeterReadingOpen, setNewMeterReadingOpen] = useState(false)
    let { meterReadings } = useReactiveVar(newDraftsVar)
    let meterReadingDraft = meter.id ? meterReadings[meter.id] || {} : {}

    useLayoutEffect(() => {
        if (hash && hash.includes('#meter_reading') && meterReadingEl?.current) {
            setTimeout(() => meterReadingEl.current.scrollIntoView({
                behavior: 'smooth', block: 'start'
            }), 100)
        }
    }, [hash, meterReadingEl.current])

    return (
        <>
            {meter?.meterReadings?.map((meterReading) => (
                <Accordion
                    key={'meterReading' + meterReading.id + meterReading.archived}
                    isOpen={hash === `#meter_reading_${meterReading.id}`}
                    Tag='div'
                    className={`bg-lightgrey--open br-05 mb-05${!meterReading.archived ? '' : ' inactive'}`}
                    headerClass='t-s t-heading p-1'
                    label={
                        <div>
                            <div className='d-flex align-center'>
                                <DateString dateStr={meterReading.date} fadeInPast={false} />
                                {meterReading.archived &&
                                    <Label
                                        classModifier='grey'
                                        className='ml-1 label--uppercase label--archived'
                                        iconProps={{ type: 'archive', variant: '3xs' }}
                                        label={lbl.archived}
                                    />
                                }
                                {/* <Icon type={meter.type.name.toLowerCase()} variant='xs' wrapperClassAdditions='mr-05 ml-auto' /> */}
                                <strong className='ml-auto t-blue-grey'>{meterReading.value}</strong>
                            </div>
                        </div>
                    }
                >
                    <div 
                        className='ml-3 mr-3 mt-3 pb-1'
                        id={`meter_reading_${meterReading.id}`}
                        ref={hash === `#meter_reading_${meterReading.id}` ? meterReadingEl : undefined}
                    >
                        <MeterReadingForm
                            meterReading={meterReading}
                            meterId={meter.id}
                        />
                    </div>
                </Accordion>
            ))}
            {newMeterReadingOpen ?
                <div className='pb-2'>
                    <Accordion
                        isOpen
                        Tag='div'
                        className='bg-lightblue pb-1 br-05 mb-1'
                        headerClass='t-heading t-s p-1'
                        label={<div className='ml-05'>{lbl.newMeterReading}</div>}
                        onOpenCb={() => { setNewMeterReadingOpen(false) }}
                    >
                        <div className='ml-3 mr-3 mt-2'>
                            <MeterReadingForm
                                draft={meterReadingDraft}
                                submitMutation={ADD_METER_READING}
                                mutationVariables={{ meterId: meter.id }}
                                close={() => { setNewMeterReadingOpen(false) }}
                                setDraft={agreementDraft => {
                                    const drafts = { ...newDraftsVar()}
                                    drafts.meterReadings[meter.id] = agreementDraft
                                    newDraftsVar(drafts)
                                }}
                            />
                        </div>
                    </Accordion>
                </div>
            : (meter.id && !meter.archived) ?
                <div className='mt-1 pb-2'>
                    <button
                        className='btn btn--naked btn--new-item w-100'
                        onClick={() => { setNewMeterReadingOpen(true) }}
                    >
                        <span className="plus">+</span>
                        {lbl.addNewMeterReading}
                    </button>
                </div>
            : null }
        </>
    )
}

export default MeterReadings
