import useContextMenu from '../hooks/useContextMenu'
import Icon from './Icon'


const ContextMenuItem = ({ icon, label, onClick, className }) => (
    <li
        className={`context-menu__item ${className}`}
        onClick={onClick}
        role='button'
    >
        {icon && <Icon type={icon} variant='xs' />}
        {label}
    </li>
)


const ContextMenu = ({ items, element, heading, children }) => {
    const minHeight = items ? heading ? (items.length + 1) * 36 + 5 : items.length * 36 + 5 : 200
    const minWidth = 200
    let { xPos, yPos, showMenu } = useContextMenu(element)
    
    const { clientWidth, clientHeight } = document?.documentElement || {}
    if (clientWidth && xPos && clientWidth - xPos < minWidth) xPos = clientWidth - minWidth
    if (clientHeight && yPos && clientHeight - yPos < minHeight) yPos = clientHeight - minHeight

    if (showMenu && (children || items?.length)) return (
        <div
            className='context-menu pop-up'
            style={{
                top: `${yPos}px`,
                left: `${xPos}px`,
            }}
        >
            <ul className='pop-up__container'>
                {heading &&
                    <ContextMenuItem
                        {...heading}
                        className='context-menu__header t-grey'
                        key={heading.label}
                    />
                }
                {items?.map(item => <ContextMenuItem {...item} key={item.label} />)}
                {children}
            </ul>
        </div>
    )

    return null
}

export default ContextMenu