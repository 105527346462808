import { taskProperties } from '../settings'
import { addNewRefToCachedLists, resetControlledList } from '../../utils'

export const onNewTask = ({ subscriptionData, client }) => {
    if (!subscriptionData.data) return
    const { newTask } = subscriptionData.data.onNewTask

    addNewRefToCachedLists({
        cache: client.cache,
        fieldName: 'tasks',
        newItem: newTask,
        getFieldProps: (field) => taskProperties[field]
    })
}
