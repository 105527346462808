import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_PROPERTIES_FOR_LANDLORD } from '../queries'
import { tabNavLinks } from '../../constants'
import { Icon } from '../../components'


const LandlordPropertyList = ({ landlordId }) => {
    const { data, loading, error } = useQuery(GET_PROPERTIES_FOR_LANDLORD, { variables: { landlordId } })
    
    if (loading) return <div className='loader' />
    
    if (error) return <div className='w-100 t-center'>{error.message}</div>
    
    if (data) {
        return (
            <>
                {!!data.ownedBuildings?.length &&
                    <>
                        <div className='t-sm mb-2'>OWNES BUILDINGS</div>
                        <ul className='mb-4'>
                            {data.ownedBuildings.map(building => (
                                <li key={'building' + building.id}>
                                    <Link
                                        to={`${tabNavLinks.properties.building.dynamicPath}${building.id}`}
                                        className='d-inline-flex t-underline-hover'
                                    >
                                        <Icon type='building' variant='xs'/>
                                        <div className='ml-05'>{building.name}</div>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </>
                }
                <div className='t-sm mb-2'>OWNES PROPERTIES</div>
                <ul>
                    {data.ownedProperties.map(property => (
                        <li key={'property' + property.id}>
                            <Link
                                to={`${tabNavLinks.properties.dynamicPath}${property.id}`}
                                className='d-inline-flex t-underline-hover'
                            >
                                <Icon type='property' variant='xs'/>
                                <div className='ml-05'>{property.streetWithHouseNumber}</div>
                            </Link>
                        </li>
                    ))}
                </ul>
            </>
        )
    }
}

export default LandlordPropertyList
