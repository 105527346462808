import { lbl } from '../../constants'
import { useState, useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery, useReactiveVar } from '@apollo/client'
import { GET_PROPERTY_OWNER_CONTRACTS, GET_OWNED_PROPERTIES } from '../queries'
import { CREATE_OWNER_CONTRACT, UPDATE_OWNER_CONTRACT, ARCHIVE_OWNER_CONTRACT } from '../mutations'
import { newDraftsVar } from '../../cache'
import ContractInfoForm from './ContractInfoForm'
import Accordion from '../../components/Accordion'
import DateString from '../../components/DateString'
import Icon from '../../components/Icon'

const AUTO_LBL = '**'
const AutoNote = () => <div className='t-xs t-grey t-right pos-relative' style={{ gridColumn: '1 / 3', height:'5px', top: '-15px' }}>{AUTO_LBL} automatically included</div>

const ContractLabel = ({ contract, buildingOptions, addressOptions, unitOptions, ownerName }) => {
    return (
        <div className='d-inline-block t-ellipsis owner-contract__label t-grey t-xs'>
            {ownerName &&
                <span className='mr-1 t-s t-dark'>{ownerName}</span>
            }
            {!!contract.buildings.length &&
                <>
                    <Icon type='building' variant='2xs' />
                    {contract.buildings.map(({ id }) => buildingOptions.find(({ value }) => value === id)?.label).filter(Boolean).join(', ')}
                    <span className='mr-1'/>
                </>
            }
            {!!contract.properties.length &&
                <>
                    <Icon type='address' variant='2xs' />
                    {contract.properties.map(({ id }) => addressOptions.find(({ value }) => value === id)?.label).filter(Boolean).join(', ')}{` `}
                    <span className='mr-1'/>
                </>
            }
            {!!contract.units.length &&
                <>
                    <Icon type='room' variant='2xs' />
                    {contract.units.map(({ id }) => unitOptions.find(({ value }) => value === id)?.label).filter(Boolean).join(', ')}
                </>
            }
        </div>
    )
}

export const OwnerContractInfoForm = ({ extraFields, ...props }) => {
    const [selectedBuildings, setSelectedBuildings] = useState(props.contract?.buildings.map(({id}) => id) || props.draft?.buildingIds || [])
    const [selectedAddresses, setSelectedAddresses] = useState(props.contract?.properties.map(({id}) => id) || props.draft?.addressIds || [])

    const _extraFields = extraFields.map(field => {
        const _field = { ...field }
        if (field.name === 'buildingIds') {
            _field['onChangeCb'] = (v) => {
                if (v.length < selectedBuildings.length) {
                    window.alert('If you did not make any changes in "Addresses" and "Units" fields, after saving these changes all addresses and units from removed building will be automatically removed from this contract as well.')
                }
                setSelectedBuildings(v)
            }
        }
        if (field.name === 'addressIds') {
            _field['onChangeCb'] = (v) => {
                if (v.length < selectedAddresses.length) {
                    window.alert('If you did not make any changes in "Units" field, after saving these changes all units from removed address will be automatically removed from this contract as well.')
                }
                setSelectedAddresses(v)
            }
            let isAnyDisabled = false
            _field.options.forEach(o => {
                const isDisabled = selectedBuildings.includes(o.buildingId)
                if (!isAnyDisabled && isDisabled) isAnyDisabled = true
                o['disabled'] = isDisabled
                if (isDisabled && !o['label'].includes(AUTO_LBL)) o['label'] = `${o['label']}${AUTO_LBL}`
            })
            if (isAnyDisabled)  {
                _field['backspaceDelete'] = false
                _field['AfterNode'] = AutoNote
            }
        }
        if (field.name === 'roomIds') {
            let isAnyDisabled = false
            _field.options.forEach(o => {
                const isDisabled = selectedAddresses.includes(o.addressId) || selectedBuildings.includes(o.buildingId)
                if (!isAnyDisabled && isDisabled) isAnyDisabled = true
                o['disabled'] = isDisabled
                if (isDisabled && !o['label'].includes(AUTO_LBL)) o['label'] = `${o['label']}${AUTO_LBL}`
            })
            if (isAnyDisabled)  {
                _field['backspaceDelete'] = false
                _field['AfterNode'] = AutoNote
            }
        }
        return _field
    })


    return (
        <ContractInfoForm
            extraFields={_extraFields}
            {...props}
        />
    )
}


export const OwnerContracts = ({ contracts, user, owner, draft, setDraft, loading, ownerName }) => {
    const { hash } = useLocation()
    const [newContractOpen, setNewContractOpen] = useState(false)
    let extraFields = [{ name: 'ownerId', lblKey: 'owner', As: 'select', options: [{ value: owner.id, label: user.fullName }], required: true, disabled: true }]
    
    const { loading: loadingProperties, data, error } = useQuery(GET_OWNED_PROPERTIES, {
        variables: { ownerId: owner.id }
    })

    useLayoutEffect(() => {
        if (hash && hash.includes('#owner_contract_')) {
            const el = document.getElementById(hash)
            if (el) {
                setTimeout(() => el.scrollIntoView({
                    behavior: 'smooth', block: 'start'
                }), 100)
            }
        }
    }, [hash])

    if (loading || loadingProperties) return <div className='loader pos-center-y'/>

    if (data?.landlord) {
        const { ownesBuildings, ownesProperties } = data.landlord
        let unitOptions = []
        let buildingAddressOptions = {}
        const addressOptions = ownesProperties.map(p => {
            const propertyUnitOptions = p.rooms.map(r => ({ value: r.id, label: r.longName, addressId: p.id, buildingId: p.building.id }))
            unitOptions = [...unitOptions, ...propertyUnitOptions]
            const addressOption = { value: p.id, label: p.streetWithHouseNumber, propertyUnitOptions, buildingId: p.building.id }
            if (buildingAddressOptions[p.building.id]) {
                buildingAddressOptions[p.building.id].push(addressOption)
            } else {
                buildingAddressOptions[p.building.id] = [addressOption]
            }
            return addressOption
        })
        const buildingOptions = ownesBuildings.map(b => ({ value: b.id, label: b.name, buildingAddressOptions: buildingAddressOptions[b.id] || [] }))
        
        extraFields = [
            { name: 'buildingIds', lblKey: 'buildings', As: 'search-select', multiple: true, options: buildingOptions, wide: true },
            { name: 'addressIds', lblKey: 'addresses', As: 'search-select', multiple: true, options: addressOptions, wide: true },
            { name: 'roomIds', lblKey: 'units', As: 'search-select', multiple: true, options: unitOptions, wide: true },
            ...extraFields
        ]

        return (
            <>
                {owner && newContractOpen ?
                    <Accordion
                        isOpen
                        Tag='div'
                        className='bg-lightblue p-1 br-05 mb-1'
                        headerClass='accordion__header--bordered t-md t-heading mr-3'
                        label={lbl.newOwnerContract}
                        onOpenCb={() => { setNewContractOpen(false) }}
                    >
                        <div className='ml-4 mr-4 mb-2'>
                            <OwnerContractInfoForm
                                draft={{ ownerId: owner.id, ...draft }}
                                extraFields={extraFields}
                                submitMutation={CREATE_OWNER_CONTRACT}
                                close={() => { setNewContractOpen(false) }}
                                setDraft={setDraft}
                                contentType='ownercontract'
                            />
                        </div>
                    </Accordion>
                : owner ?
                    <button
                        className='btn btn--naked btn--new-item w-100 mb-2'
                        onClick={() => { setNewContractOpen(true) }}
                    >
                        <span className="plus">+</span>
                        {lbl.addNewOwnerContract}
                    </button>
                : null }
                {contracts.map((contract) => {                
                    if (!contract.archived) return (
                        <Accordion
                            id={`#owner_contract_${contract.id}`}
                            isOpen={hash.includes(`#owner_contract_${contract.id}`)}
                            key={`owner_contract_${contract.id}`}
                            Tag='div'
                            className={`bg-light p-1 mb-1 br-05 ${(contract.active && !contract.archived) ? '' : ' inactive'}`}
                            headerClass='accordion__header--bordered t-md t-heading mr-3'
                            label={
                                <div className='d-flex justify-space-between align-center'>
                                    {extraFields.length === 1 ?
                                        <span>{user.fullName}</span>
                                    :
                                        <ContractLabel
                                            contract={contract}
                                            buildingOptions={extraFields[0].options}
                                            addressOptions={extraFields[1].options}
                                            unitOptions={extraFields[2].options}
                                            ownerName={ownerName}
                                        />
                                    }
                                    <div className='d-inline-flex'>
                                        {contract.startDate ? <DateString dateStr={contract.startDate} /> : 'N/A'}
                                        <span className='ml-1 mr-1'>-</span>
                                        {contract.forIndefinitePeriod ?
                                            <div>{lbl.indefinite}</div>
                                        : contract.endDate ?
                                            <DateString dateStr={contract.endDate} />
                                        : 'N/A'}
                                    </div>
                                </div>
                            }
                        >
                            <div className='ml-4 mr-4'>
                                <OwnerContractInfoForm
                                    key={'contract' + contract.id}
                                    contract={contract}
                                    extraFields={extraFields}
                                    submitMutation={UPDATE_OWNER_CONTRACT}
                                    archiveMutation={ARCHIVE_OWNER_CONTRACT}
                                    contentType='ownercontract'
                                />
                            </div>
                        </Accordion>
                    )
                    return null
                })}
            </>
        )
    }

    return null
}

const PropertyOwnerContracts = ({
    contentType,
    objectId,
    building,
    owner
}) => {
    const showBuildingContracts = contentType === 'building'
    const [showAddressContracts, setShowAddressContracts] = useState(contentType === 'address')
    const [showUnitContracts, setShowUnitContracts] = useState(contentType === 'room')
    const { loading, data } = useQuery(GET_PROPERTY_OWNER_CONTRACTS, { variables: { buildingId: building.id } })
    const draftKey = `${contentType}OwnerContracts`
    let drafts = useReactiveVar(newDraftsVar)
    let draft = {[`${contentType}Ids`]: [objectId], ...drafts[draftKey][objectId] || {}}
    const buildingContracts = {}
    const unitContracts = {}
    const addressContracts = {}
    if (data?.building) {
        data.building.buildingOwnerContracts.forEach(c => { buildingContracts[c.id] = c })
        data.building.properties.forEach(p => {
            p.rooms.forEach(u => { u.unitOwnerContracts.forEach(c => { unitContracts[c.id] = c }) })
            p.propertyOwnerContracts.forEach(c => { addressContracts[c.id] = c })
        })
    }
    const contracts = {...(showBuildingContracts ? buildingContracts : {}), ...(showAddressContracts ? addressContracts : {}), ...(showUnitContracts ? unitContracts : {})}
    const setDraft = (contractDraft) => {
        const _drafts = { ...drafts }
        _drafts[draftKey][objectId] = contractDraft
        newDraftsVar(drafts)
    }

    return (
        <>
            <div className='d-inline-flex justify-end mb-1 float-right'>
                {contentType === 'building' &&
                    <button
                        disabled={true}
                        className='btn btn--naked btn--icon mr-1 br-05 active'
                        onClick={() => {}}
                    >
                        <Icon type='building' variant='sm'/>
                    </button>
                }
                {contentType !== 'room' &&
                    <>
                        <button
                            disabled={contentType === 'address'}
                            className={`btn btn--naked btn--icon mr-1 br-05${showAddressContracts ? ' active' : ''}`}
                            onClick={() => setShowAddressContracts((prev) => !prev)}
                        >
                            <Icon type='address' variant='sm'/>
                        </button>
                        <button
                            className={`btn btn--naked btn--icon br-05${showUnitContracts ? ' active' : ''}`}
                            onClick={() => setShowUnitContracts((prev) => !prev)}
                        >
                            <Icon type='room' variant='sm'/>
                        </button>
                    </>
                }
            </div>
            <OwnerContracts
                contracts={Object.values(contracts)}
                loading={loading}
                setDraft={setDraft}
                draft={draft}
                user={owner.user}
                owner={owner}
                ownerName={owner.user.fullName}
            />
        </>
    )
}

export default PropertyOwnerContracts
