import React from 'react'
import { useHistory } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { templateListCtrlsVar } from '../../cache'
import { tabNavLinks } from '../../constants'
import { ProfileImage, Label, HighlightedText } from '../../components'

const TemplateListItem = ({
    id,
    name,
    createdBy,
    lastModifiedBy,
    dateCreatedLabel,
    dateUpdatedLabel,
    propKeys
}) => {
    const history = useHistory()
    let { searchTerm } = useReactiveVar(templateListCtrlsVar)

    return (
        <tr
            onClick={() => history.push(`${tabNavLinks.templates.dynamicPath}${id}`)}
            className='lister__row'>
            {propKeys.map((key, i) => (
                <React.Fragment key={key + i}>
                    {key === 'name' ? (
                        <td className='lister__cell'>
                            <HighlightedText text={name} mark={searchTerm} />
                        </td>
                    ) : key === 'createdAt' ? (
                        <td className='lister__cell'>
                            <Label
                                className='time-label'
                                iconProps={{ type: 'time' }}
                                label={dateCreatedLabel}
                                classModifier='grey'
                            />
                        </td>
                    ) : key === 'lastModified' ? (
                        <td className='lister__cell'>
                            {dateUpdatedLabel &&
                                <Label
                                    className='time-label'
                                    iconProps={{ type: 'time' }}
                                    label={dateUpdatedLabel}
                                    classModifier='grey'
                                />
                            }
                        </td>
                    ) : key === 'createdBy' ? (
                        <td className='lister__cell t-sm'>
                            {createdBy &&
                                <div className='lister-cell__wrapper'>
                                    <ProfileImage
                                        url={createdBy.picture}
                                        size='xs'
                                    />
                                    <div className='d-inline-block ml-1'>
                                        <HighlightedText text={createdBy.fullName} mark={searchTerm} />
                                    </div>
                                </div>
                            }
                        </td>
                    ) : key === 'lastModifiedBy' ? (
                        <td className='lister__cell t-sm'>
                            {lastModifiedBy &&
                                <div className='lister-cell__wrapper'>
                                    <ProfileImage
                                        url={lastModifiedBy.picture}
                                        size='xs'
                                    />
                                    <div className='d-inline-block ml-1'>
                                        <HighlightedText text={lastModifiedBy.fullName} mark={searchTerm} />
                                    </div>
                                </div>
                            }
                        </td>
                    ) : null}
                </React.Fragment>
            ))}
        </tr>
    )
}

export default TemplateListItem
