import { gql } from '@apollo/client'
import { BASE_TASK_FRAGMENT } from './fragments'

export const UPDATE_TASK = gql`
    mutation UPDATE_TASK($id: ID!, $description: String, $name: String, $assigneeId: ID, $status: String, $priority: String, $ticketId: ID, $dueDate: String, $approvalRequired: Boolean, $attachmentCategoryId: ID) {
        updateTask(id: $id, description: $description, name: $name, assigneeId: $assigneeId, status: $status, priority: $priority, ticketId: $ticketId, dueDate: $dueDate, approvalRequired: $approvalRequired, attachmentCategoryId: $attachmentCategoryId) {
            task {
                ...BaseTaskFields
                ticket {
                    id
                    name
                }
                attachmentCategory {
                    id
                    name
                    userGroups {
                        id
                        name
                    }
                }
            }
        }
    }
    ${BASE_TASK_FRAGMENT}
`

export const CREATE_TASK = gql`
    mutation CREATE_TASK($description: String, $name: String, $assigneeId: ID, $status: String, $priority: String, $ticketId: ID, $dueDate: String, $approvalRequired: Boolean) {
        createTask(description: $description, name: $name, assigneeId: $assigneeId, status: $status, priority: $priority, ticketId: $ticketId, dueDate: $dueDate, approvalRequired: $approvalRequired) {
            task {
                ...BaseTaskFields
                ticket {
                    id
                    name
                }
                attachmentCategory {
                    id
                    name
                    userGroups {
                        id
                        name
                    }
                }
            }
        }
    }
    ${BASE_TASK_FRAGMENT}
`

export const UNASSIGN_TASK = gql`
    mutation UNASSIGN_TASK($id: ID!) {
        unassignTask(id: $id) {
            task {
                id
                assignee {
                    id
                    fullName
                }
            }
        }
    }
`

export const REMOVE_TICKET_RELATION = gql`
    mutation REMOVE_TICKET_RELATION($id: ID!) {
        removeTicketRelation(id: $id) {
            task {
                id
                ticket {
                    id
                    name
                }
            }
        }
    }
`

export const REMOVE_TASK_ATTACHMENT_CATEGORY = gql`
    mutation REMOVE_TASK_ATTACHMENT_CATEGORY($id: ID!) {
        removeTaskAttachmentCategory(id: $id) {
            task {
                id
                attachmentCategory {
                    id
                }
            }
        }
    }
`

export const DELETE_TASK = gql`
    mutation DELETE_TASK($id: ID!) {
        deleteTask(id: $id) {
            deletedId
            relatedTicketId
        }
    }
`

export const DUPLICATE_TASK = gql`
    mutation DUPLICATE_TASK($id: ID!) {
        duplicateTask(id: $id) {
            task {
                id
                name
                ticket {
                    id
                }
                attachmentCategory {
                    id
                    name
                    userGroups {
                        id
                        name
                    }
                }
                approvalRequired
                createdDateLabel
                createdDateOffset
                createdBy {
                    id
                    fullName
                    picture
                }
            }
        }
    }
`

export const ARCHIVE_TASK = gql`
    mutation ARCHIVE_TASK($id: ID!) {
        archiveTask(id: $id) {
            task {
                id
                archived
                ticket {
                    id
                }
            }
        }
    }
`

export const ACTIVATE_TASK = gql`
    mutation ACTIVATE_TASK($id: ID!) {
        activateTask(id: $id) {
            task {
                id
                description
                name
                assignee {
                    id
                    fullName
                }
                status
                priority
                ticket {
                    id
                    name
                }
                attachmentCategory {
                    id
                    name
                    userGroups {
                        id
                        name
                    }
                }
                archived
                dueDate
                dueDateLabel
                dueDateOffset
                approvalRequired
            }
        }
    }
`

export const UPDATE_TASKS = gql `
    mutation UPDATE_TASKS($ids: [ID!], $description: String, $name: String, $assigneeId: ID, $status: String, $priority: String, $ticketId: ID, $dueDate: String, $approvalRequired: Boolean) {
        updateTasks(ids: $ids, description: $description, name: $name, assigneeId: $assigneeId, status: $status, priority: $priority, ticketId: $ticketId, dueDate: $dueDate, approvalRequired: $approvalRequired) {
            tasks {
                id
                description
                name
                assignee {
                    id
                    fullName
                }
                status
                priority
                ticket {
                    id
                    name
                }
                attachmentCategory {
                    id
                    name
                    userGroups {
                        id
                        name
                    }
                }
                archived
                dueDate
                dueDateLabel
                dueDateOffset
                approvalRequired
            }
        }
    }
`

export const ARCHIVE_TASKS = gql`
    mutation ARCHIVE_TASKS($ids: [ID!]) {
        archiveTasks(ids: $ids) {
            archivedIds
            tasks {
                id
                archived
                ticket {
                    id
                }
            }
        }
    }
`

export const ACTIVATE_TASKS = gql`
    mutation ACTIVATE_TASKS($ids: [ID!]) {
        activateTasks(ids: $ids) {
            tasks {
                id
                description
                name
                assignee {
                    id
                    fullName
                }
                status
                priority
                ticket {
                    id
                    name
                }
                archived
                dueDate
                dueDateLabel
                dueDateOffset
                approvalRequired
                attachmentCategory {
                    id
                    name
                    userGroups {
                        id
                        name
                    }
                }
            }
        }
    }
`
