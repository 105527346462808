import { GET_CATEGORIES, GET_LABELS, GET_PIPELINES, GET_STATUS_OPTIONS, GET_BOARD_FILTER_OPTIONS } from './queries'
import { GET_PRIORITY_OPTIONS, GET_ALL_USERS, GET_USER_GROUPS } from '../queries'
import { lbl, priorityOptions } from '../constants'
import { currentUserVar } from '../cache'


export const ticketProperties = {
    priority: {
        field: 'priority',
        type: 'string',
        label: 'Priority',
        iconProps: { type: 'priority' },
        icon: 'priority',
        sortPath: 'priority',
        sortDir: -1,
        labelField: 'label',
        optionsQuery: { query: GET_PRIORITY_OPTIONS, name: 'priorityOptions' },
        getOptionProps: r => { 
            const root = typeof r === 'string' ? priorityOptions[r] : r
            return ({ ...root, iconProps: { type: root.icon } })
        },
        options: priorityOptions,
    },
    category: {
        field: 'category',
        type: 'object',
        label: 'Category',
        iconProps: { type: 'category' },
        icon: 'category',
        sortPath: 'category.name',
        groupPath: 'category.name',
        objectPath: 'category',
        optionsQuery: { query: GET_CATEGORIES, name: 'categories' },
        getOptionProps: root => ({ 
            ...root, label: root.name, icon: 'category', iconProps: { type: 'category' }
        }),
    },
    assignee: {
        field: 'assignee',
        type: 'object',
        label: 'Assignee',
        actionLabel: 'Assign',
        iconProps: { type: 'assignee' },
        icon: 'assignee',
        sortPath: 'assignee.fullName',
        groupPath: 'assignee.fullName',
        objectPath: 'assignee',
        optionsQuery: { query: GET_ALL_USERS, name: 'users' },
        getOptionProps: root => {
            const { id } = currentUserVar()
            const isMe = root.id === id
            return {
                ...root,
                label: `${root.fullName}${isMe ? ' (me)' : ''}`,
                actionLabel: `Assigned to ${isMe ? 'me' : root.fullName}`,
                classModifier: 'profile',
                icon: 'assignee',
            }
        }
    },
    owner: {
        field: 'owner',
        type: 'object',
        label: 'Owner',
        sortPath: 'owner.fullName',
        groupPath: 'owner.fullName',
        objectPath: 'owner',
        iconProps: { type: 'reporter' },
        icon: 'reporter',
        optionsQuery: { query: GET_ALL_USERS, name: 'users' },
        getOptionProps: root => {
            const isMe = root.id === currentUserVar().id
            return {
                ...root,
                label: `${root.fullName}${isMe ? ' (me)' : ''}`,
                actionLabel: `Owned by ${isMe ? 'me' : root.fullName}`,
                classModifier: 'reporter',
                icon: 'reporter'
            }
        }
    },
    createdBy: {
        field: 'createdBy',
        type: 'object',
        label: 'Reporter',
        sortPath: 'createdBy.fullName',
        groupPath: 'createdBy.fullName',
        objectPath: 'createdBy',
        iconProps: { type: 'reporter' },
        icon: 'reporter',
        optionsQuery: { query: GET_ALL_USERS, name: 'users' },
        getOptionProps: root => {
            const isMe = root.id === currentUserVar().id
            return {
                ...root,
                label: `${root.fullName}${isMe ? ' (me)' : ''}`,
                actionLabel: `Reported by ${isMe ? 'me' : root.fullName}`,
                classModifier: 'reporter',
                icon: 'reporter'
            }
        }
    },
    createdDate: {
        field: 'createdDate',
        type: 'string',
        label: 'Date created',
        iconProps: { type: 'time', variant: '2xs' },
        sortPath: 'createdDate',
        sortDir: -1,
        icon: 'time'
    },
    name: {
        field: 'name',
        type: 'string',
        label: 'Name',
        sortPath: 'name',
        iconProps: { type: 'sort-text' },
    },
    description: {
        field: 'description',
        type: 'string',
        label: 'Description',
        iconProps: { type: 'sort-text' },
    },
    'pipelineStage.pipeline': {
        field: 'pipelineStage.pipeline',
        type: 'object',
        label: 'Board',
        sortPath: 'pipelineStage.pipeline.name',
        groupPath: 'pipelineStage.pipeline.name',
        objectPath: 'pipelineStage.pipeline',
        iconProps: { type: 'board' },
        icon: 'board',
        optionsQuery: { query: GET_PIPELINES, name: 'pipelines' },
        getOptionProps: root => ({
            ...root, label: root.name, icon: 'board', iconProps: { type: 'board' }
        })
    },
    pipelineStage: {
        field: 'pipelineStage',
        type: 'object',
        label: 'Status',
        iconProps: { type: 'status' },
        icon: 'status',
        optionsQuery: { query: GET_STATUS_OPTIONS, name: 'pipelineStages' },
        getOptionProps: root => ({
            ...root, label: `${root.name} • ${root.pipeline.name}`, iconProps: {
                type: `status-${root.isFirst ? '1' : root.isLast ? '3' : '2'}`,
            }, color: root.isFirst ? 'grey' : root.isLast ? 'green' : 'yellow',
        })
    },
    labels: {
        field: 'labels',
        type: 'array',
        label: 'Label',
        multi: true,
        icon: 'label',
        iconProps: { type: 'label' },
        optionsQuery: { query: GET_LABELS, name: 'labels' },
        getOptionProps: root => ({
            ...root, label: root.name, icon: 'label', iconProps: { type: 'label', fill: root.color }
        })
    },
    archived: {
        field: 'archived',
        sortPath: 'archived',
        type: 'string',
        label: 'Archived',
        icon: 'archive',
        iconProps: { type: 'archive' },
        getOptionProps: root => ({
            label: root ? lbl.archived : lbl.active, iconProps: { type: root ? 'archive' : 'ticket' }
        })
    },
    me: {
        field: 'me',
        type: 'object',
        label: 'Assigned to me',
        iconProps: { type: 'assignee' },
        icon: 'assignee',
        getOptionProps: () => {
            const { id, picture, assignedToTickets } = currentUserVar()
            return {
                field: 'assignee', id, picture, label: 'Assigned to me', icon: 'assignee',
                itemIds: assignedToTickets && assignedToTickets.map(t => t.id)
            }
        }
    },
    relevantToMe: {
        field: 'relevantToMe',
        type: 'boolean',
        label: 'Relevant to me',
        iconProps: { type: 'important' },
        icon: 'important',
        getOptionProps: () => ({ field: 'relevantToMe', id: 'true', label: 'Relevant to me', icon: 'important' })
    },
    unreadMessageCount: {
        field: 'unreadMessageCount',
        type: 'number',
        label: 'Unread message count',
        iconProps: { type: 'message' },
        icon: 'message',
        sortDir: -1
    },
    templateForGroup: {
        field: 'template_for_group',
        type: 'object',
        label: 'Default group',
        iconProps: { type: 'category' },
        icon: 'category',
        sortPath: 'templateForGroup.name',
        groupPath: 'templateForGroup.name',
        objectPath: 'templateForGroup',
        optionsQuery: { query: GET_USER_GROUPS, name: 'userGroups' },
        getOptionProps: root => ({ 
            ...root, label: root.name
        }),
    },
    reviewerGroup: {
        field: 'reviewer_group',
        type: 'object',
        label: 'Reviewer group',
        iconProps: { type: 'category' },
        icon: 'category',
        sortPath: 'reviewerGroup.name',
        groupPath: 'reviewerGroup.name',
        objectPath: 'reviewerGroup',
        optionsQuery: { query: GET_USER_GROUPS, name: 'userGroups' },
        getOptionProps: root => ({ 
            ...root, label: root.name
        }),
    },
}

export const listerCtrlOptions = {
    filter: ['relevantToMe', 'priority', 'category', 'pipelineStage.pipeline', 'assignee', 'owner', 'labels'].map(key => ticketProperties[key]),
    group: ['category', 'priority', 'assignee', 'owner', 'pipelineStage.pipeline', 'pipelineStage'].map(key => ticketProperties[key]),
    sort: ['createdDate', 'name', 'assignee', 'priority', 'category', 'owner'].map(key => ticketProperties[key]),
    search: ['name', 'category', 'assignee', 'owner', 'priority', 'pipelineStage', 'labels'].map(key => ticketProperties[key])
}

export const boardCtrlOptions = {
    filter: ['relevantToMe', 'priority', 'category', 'owner', 'assignee', 'labels'].map(key => ticketProperties[key]),
    sort: ['unreadMessageCount', 'createdDate', 'name', 'assignee', 'priority', 'category', 'owner'].map(key => ticketProperties[key]),
    search: ['name', 'category', 'assignee', 'owner', 'priority', 'labels'].map(key => ticketProperties[key])
}

export const batchActions = ['merge', 'archive', 'assignee', 'labels', 'priority', 'pipelineStage.pipeline']

export const listerColumns = ['pipelineStage', 'name', 'labels', 'category', 'createdDate', 'owner', 'priority', 'assignee']
