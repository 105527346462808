import { useMutation } from '@apollo/client'
import { UPDATE_USER } from '../mutations'
import Form from '../../forms/Form'
import FormWithCustomFields from '../../forms/FormWithCustomFields'
import {
    salutationOptions,
    genderOptions,
    employmentStatusOptions,
    relationshipStatusOptions,
    languageOptions,
} from '../../constants'

const titleFields = [
    { name: 'salutation', As: 'select', options: salutationOptions, lblKey: 'title' },
    { name: 'firstName' },
    { name: 'lastName' },
]

const generalFields = [
    { name: 'phoneNumber', type: 'tel' },
    { name: 'secondaryPhoneNumber', type: 'tel' },
    { name: 'email', type: 'email' },
    { name: 'secondaryEmail', type: 'email'},
    { name: 'gender', As: 'select', options: genderOptions },
    { name: 'dob', type: 'date' },
    { name: 'language', lblKey: 'preferredLanguage', As: 'select', options: languageOptions },
    { name: 'nationality' },
    { name: 'address', lblKey: 'currentAddress' }
]

const tenantFields = [
    { name: 'countryOfOrigin' },
    { name: 'countryOfResidence' },
    { name: 'bsnNumber' },
    { name: 'relationshipStatus', As: 'select', options: relationshipStatusOptions },
    { name: 'employmentStatus', As: 'select', options: employmentStatusOptions },
    { name: 'degreeTitle' },
    { name: 'graduationDate', type: 'date' },
    { name: 'about', As: 'textarea' },
    { name: 'hobbies', As: 'textarea' },
]

const ContactInfoForm = ({ user }) => {
    const initialState = {
        firstName: user.firstName,
        lastName: user.lastName,
        gender: user.profile?.gender,
        salutation: user.profile?.salutation,
        dob: user.profile?.dob,
        phoneNumber: user.profile?.phoneNumber,
        secondaryPhoneNumber: user.profile?.secondaryPhoneNumber,
        language: user.profile?.language,
        nationality: user.profile?.nationality,
        address: user.profile?.address,
        email: user.email,
        secondaryEmail: user.profile?.secondaryEmail || user.tenant?.secondaryEmail,
        employmentStatus: user.profile?.employmentStatus,
        relationshipStatus: user.tenant?.relationshipStatus,
        countryOfOrigin: user.tenant?.countryOfOrigin,
        countryOfResidence: user.tenant?.countryOfResidence,
        bsnNumber: user.tenant?.bsnNumber,
        degreeTitle: user.tenant?.degreeTitle,
        graduationDate: user.tenant?.graduationDate,
        about: user.tenant?.about,
        hobbies: user.tenant?.hobbies
    }
    const [updateUser, { loading, called, data, error }] = useMutation(UPDATE_USER)
    const success = !error && !loading && called && !!data?.updateUserDetails?.user
    const fields = user.tenant ? [...generalFields, ...tenantFields] : generalFields
    const userTypeModel = user.tenant ? 'tenant' : user.employee ? 'employee' : user.landlord ? 'landlord' : user.partner ? 'partner' : null
    
    if (userTypeModel && user[userTypeModel]?.id) return (
        <FormWithCustomFields
            contentType={userTypeModel}
            objectId={user[userTypeModel].id}
            fields={fields}
            introFields={titleFields}
            initialState={initialState}
            onSubmit={(data, cancelChanges, saveCustomFields) => {
                updateUser({
                    variables: { id: user.id, ...data },
                    onCompleted: (c) => {
                        if (c && saveCustomFields) {
                            saveCustomFields(user[userTypeModel].id)
                        }
                    }
                })
            }}
            isSubmitting={loading}
            isSuccess={success}
        />
    )

    return (
        <Form
            fields={fields}
            introFields={titleFields}
            initialState={initialState}
            onSubmit={data => updateUser({ variables: { id: user.id, ...data } })}
            isSubmitting={loading}
            isSuccess={success}
        />
    )
};

export default ContactInfoForm;
