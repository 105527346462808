import * as Sentry from '@sentry/react'

const getResponseData = (response) => {
    if (response) return null
  
    let responseData
  
    try {
        responseData = JSON.stringify(response)
    } catch {
        responseData = response
    }
  
    return responseData
}

const getOperationData = (operation) => {
    const query = operation.query
    const definition = query?.definitions?.[0]
    const variables = operation.variables || {}
    
    Object.keys(variables).forEach(variable => {
        if (variable.indexOf('password') !== -1 || variable.indexOf('token') !== -1) {
            variables[variable] = undefined
        }
    })

    const operationData = {
        name: operation.operationName,
        type: definition?.['operation'],
        body: query?.loc?.source?.body || '',
        variables: JSON.stringify(variables)
    }

    return operationData
}
  
const captureSentryException = ({ title, operation, response, extras }) => {
    Sentry.withScope((scope) => {
        if (operation) {
            const operationData = getOperationData(operation)
            operationData && scope.setExtra('operation', operationData)
        }

        if (response) {
            const responseData = getResponseData(response)
            responseData && scope.setExtra('response', responseData)
        }

        if (extras?.length) {
            extras.forEach(({ key, value }) => {
                scope.setExtra(key, value)
            })
        }
    
        Sentry.captureException(new Error(title))
    })
}

export const handleGraphQlError = (options) => {
    const { graphQLErrors, networkError, operation } = options
    let errorMessage = ''

    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {            
            let extras = []
            if (locations) extras.push({ key: 'location', value: JSON.stringify(locations) })
            if (path) extras.push({ key: 'path', value: path })

            if (!(operation.operationName === 'LOGIN' && message.includes('Invalid'))) {
                captureSentryException({
                    title: `[GraphQL error]: ${message}`,
                    extras,
                    ...options
                })

                errorMessage += `\n ${message}`
            }
        })
    }
      
    if (!graphQLErrors && networkError) {
        captureSentryException({
            title: `[Network error]: ${networkError}`,
            ...options
        })
    }

    if (errorMessage) {
        window.alert(`ERROR OCCURRED\n${errorMessage}\n🥺`)
    }
}
