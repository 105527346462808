import { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { staticTabs } from '../../constants'
import { TabNavContext } from '../../components/TabNav'
import BuildingDetail from './BuildingDetail'
import PropertyDetail from './PropertyDetail'
import UnitDetail from './UnitDetail'
import PropertyList from './PropertyList'
import NewProperty from './NewProperty'
import NewUnit from './NewUnit'
import NewBuilding from './NewBuilding'

const PropertyLoader = () => {
    const { activeTab, id } = useContext(TabNavContext)

    switch (activeTab) {
        case 'list':
            return <PropertyList/>
        case 'property':
            if (id === 'new') return <NewProperty/>
            return <PropertyDetail key={activeTab + id} />
        case 'unit':
            if (id === 'new') return <NewUnit/>
            return <UnitDetail key={activeTab + id}/>
        case 'building':
            if (id === 'new') return <NewBuilding/>
            return <BuildingDetail key={activeTab + id}/>
        default:
            return <Redirect to={staticTabs.properties[0].relativeUrl} />
    }
}

export default PropertyLoader
