import { gql } from '@apollo/client'

export const ON_NEW_TASK_SUBSCRIPTION = gql`
    subscription ON_NEW_TASK_SUBSCRIPTION {
        onNewTask {
            newTask {
                id
                name
                description
                createdDate
                createdDateLabel
                createdDateOffset
                dueDate
                dueDateLabel
                dueDateOffset
                createdBy {
                    id
                    fullName
                    picture
                }
                ticket {
                    id
                    name
                    priority
                    pipelineStage {
                        id
                        isFirst
                        isLast
                    }
                    createdDate
                }
                assignee {
                    id
                    fullName
                    picture
                }
                priority
                status
                approvalRequired
            }
        }
    }
`;
