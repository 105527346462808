import { gql } from '@apollo/client'

export const BASE_NOTIFICATION_FRAGMENT = gql`
    fragment NotificationFields on NotificationType {
        id
        level
        unread
        verb
        actorObjectId
        actorObjectName
        actorContentType {
            id 
            model
            __typename
        }
        targetObjectId
        targetObjectName
        targetContentType {
            id 
            model
            __typename
        }
        actionObjectObjectId
        actionObjectObjectName
        actionObjectContentType {
            id 
            model
            __typename
        }
        timestamp
        emailed
        description
        __typename
  }
`
