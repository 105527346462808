import { useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { tabNavLinks } from '../../constants'
import { GET_HIGHLIGHTED_MESSAGES_FOR_THREAD } from '../queries'
import ChatFeed from './ChatFeed'

const HighlightedMessages = ({ threadId }) => {
    const history = useHistory()
    const { data, loading, error } = useQuery(GET_HIGHLIGHTED_MESSAGES_FOR_THREAD, {
        variables: { threadId }
    })

    const handleMessageClick = ({ id, archived }) => {
        if (!archived) {
            const refMessageEl = document.getElementById(`message-${id}`)
            if (refMessageEl) {
                refMessageEl.scrollIntoView({ behavior: 'smooth' })
            } 
            history.push(`${tabNavLinks.messenger.dynamicPath}${threadId}#${id}`)
        }
    }

    return (
        <div className='ocp__chat-container'>
            {loading ?
                <div className='loader pos-center-y' />
            : error ?
                <div className='t-red w-100 pos-center-y'>
                    <center>{error.message}</center>
                </div>
            : (!!data && !data.highlightedMessages.length) ?
                <div className='w-100 pos-center-y'>
                    <center>No crutial information or highlighted messages in this thread.</center>
                </div>
            : (!!data && data.highlightedMessages.length) ?
                <ChatFeed
                    messages={data?.highlightedMessages || []}
                    threadId={threadId}
                    handleMessageClick={handleMessageClick}
                    areHighlights
                />
            : null}
        </div>
    )
}

export default HighlightedMessages
