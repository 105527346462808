import TaskChecklistItem from './TaskChecklistItem'

const TaskChecklist = ({ tasks, disabled }) => {
    const sortedTasks = tasks.slice().sort((a, b) => (a.dueDateOffset > b.dueDateOffset) ? 1 : -1)
    
    return (
        <ul className='task-list'>
            {sortedTasks.map(task => <TaskChecklistItem {...task} key={task.name} disabled={disabled} />)}
        </ul>
    )
}

export default TaskChecklist
