import Icon from './Icon'

const ProfileImage = ({ url, type, size='sm', iconProps={}, tagProps, showIconTag, status, wrapperClassAdditions, ...imgProps }) => (
    <div className={`profile-img__wrapper d-inline-flex pos-relative ${wrapperClassAdditions}`}>
        <div className={`img profile-img profile-img--${size} profile-img--${type || 'noborder'}${url ? '' : ' profile-img--icon'}`}>
            { url ?
                <img src={process.env.PUBLIC_URL + url} alt='user' {...imgProps} />
            : 
                <Icon type='profile' variant={size} viewBox="0 0 40 40" {...iconProps} />
            }
        </div>
        {showIconTag && (tagProps || iconProps.type) && 
            <Icon classAdditions='profile-img__icon-tag' variant='3xs' {...(tagProps ? tagProps : iconProps)} />
        }
        {status &&
            <Icon type='dot' variant='2xs' wrapperClassAdditions={`icon-wrapper--${status.toLowerCase()}`} />
        }
    </div>
)

export default ProfileImage
