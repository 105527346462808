import Froala from 'froala-editor'
import { useState, useRef, useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { GET_PINNED_NOTES, GET_NOTES_FOR_INSTANCE } from '../queries'
import { CREATE_NOTE, PIN_NOTE, ARCHIVE_NOTE, ARCHIVE_COMMENT } from '../mutations'
import { ProfileImage, ContextMenu, Icon } from '../../components'
import TextEditor from '../../components/TextEditor'
import { currentUserVar } from '../../cache'
import Note from './Note'
import NoteTags from './NoteTags'

const NotesFeed = ({
    contentType,
    objectId,
    query = GET_NOTES_FOR_INSTANCE,
    queryName='notesForContentObject',
    queryVariables,
    pinnedNotesQuery=GET_PINNED_NOTES,
    pinnedNotesVariables,
    showAnnotations = false,
    disabled
}) => {
    const { hash } = useLocation()
    const variables = queryVariables || { contentType, objectId }
    const [tagFilters, setTagFilers] = useState([])
    const { data, error, loading } = useQuery(query, {
        variables, pollInterval: 1800000,
        onCompleted: (x) => {
            const filters = []
            x[queryName].forEach(note => { 
                note.tags.forEach(tag => {
                    if (!filters.find(({ id }) => id === tag.id)) filters.push(tag)
                })
            })
            if (filters.length) {
                setTagFilers([...filters, {
                    id: 'none',
                    label: 'No tag',
                    color: '#78909C'
                }])
            }
        }
    })
    const currentUser = currentUserVar()
    let [note, setNote] = useState('')
    let [rerenderKey, setKey] = useState(0)
    let [contextMenu, setContextMenu] = useState(false)
    let [activeCommentInput, setActiveCommentInput] = useState('')
    let [activeTaggedNote, setActiveTaggedNote] = useState()
    let [activeTagFilters, setActiveTagFilters] = useState([])
    let [tags, setTags] = useState([])
    const noteList = useRef()
    const anchorNoteEl = useRef()
    
    const [archiveComment] = useMutation(ARCHIVE_COMMENT, {
        onCompleted: () => { setKey(prev => prev + 1) }
    })
    const [archiveNote] = useMutation(ARCHIVE_NOTE)
    const [pinNote] = useMutation(PIN_NOTE, {
        refetchQueries: [{
            query: pinnedNotesQuery,
            variables: pinnedNotesVariables || { contentType, objectId }
        }],
    })
    const [saveNote, { error: newNoteError, client }] = useMutation(CREATE_NOTE, {
        onCompleted: ({ createNote }) => {
            setNote('')
            setTags([])
            setKey(prev => prev + 1)
            client.writeQuery({
                query,
                data: {
                    [queryName]: [createNote.note, ...data[queryName]],
                },
                variables
            })
        }
    })

    const submitNote = (body=note, selectedTags=tags) => {
        if (body) {
            const mentionIds = body?.match(/data-mention-id="(.*?)"/g)?.map(item => item.match(/^data-mention-id="(.*)"/)[1]) || [];
            saveNote({ 
                variables: {
                    body,
                    notedContentType: contentType,
                    notedObjectId: objectId,
                    mentionIds,
                    tagIds: selectedTags.map(t => t.id)
                }
            })
        }
    }

    // const handleKeyDown = ({ key, shiftKey, altKey, target, preventDefault, tags }) => {
    //     if (!!target?.innerText.trim() && key === 'Enter' && !shiftKey && !altKey) {
    //         preventDefault()
    //         submitNote(target.innerHTML.replace(/<p><br><\/p>+$/, ''), tags)
    //     }
    // }

    const onContextMenu = (item) => {
        const { __typename, id, archived, pinned, body, createdBy } = item
        const items = []

        if (__typename === 'CommentType' && !archived && createdBy?.id === currentUser.id) {
            items.push({
                icon: 'archive',
                label: 'Archive comment',
                onClick: () => {
                    const confirmed = window.confirm(`Please confirm that you want to archive comment "${body}" by pressing OK`)
                    if (confirmed) archiveComment({ variables: { id } })
                }
            })
        }

        if (__typename === 'NoteType') {
            if (!archived) {
                items.push({
                    icon: pinned ? 'unpin' : 'pin',
                    label: pinned ? 'Remove pin' : 'Pin note',
                    onClick: () => pinNote({ variables: { id, pinned: !pinned } })
                }, {
                    icon: 'message',
                    label: 'Add comment',
                    onClick: () => {
                        setActiveCommentInput(id)
                    }
                }, {
                    icon: 'label',
                    label: 'Add tag',
                    onClick: () => {
                        setActiveTaggedNote(id)
                    }
                })
            }
            if (!pinned && createdBy?.id === currentUser.id) {
                items.push({
                    icon: archived ? 'activate': 'archive',
                    label: archived ? 'Restore from archive' : 'Archive note',
                    onClick: () => archiveNote({ variables: { id, archived: !archived } })
                })
            }
        }

        setContextMenu({ items })
    }

    const renderNotes = () => {
        if (loading) return <div className='loader' />
        
        if (error) return <p>Error :( {error.message}</p>
        
        if (data && data[queryName]?.length === 0) return (
            <div className='t-grey w-100 mx-auto my-auto'>
                <center>No related notes</center>
            </div>
        )
        
        if (data && data[queryName]) return (
            <>
                {data[queryName].map((note) => {
                    if (!activeTagFilters?.length || (!note.tags.length && activeTagFilters.includes('none')) || note.tags.find(t => activeTagFilters.includes(t.id))) {
                        return (
                            <div
                                ref={hash && '#' + note.id === hash ? anchorNoteEl : undefined}
                                key={`note-${note.id}`}
                            >
                                <Note
                                    onContextMenu={disabled ? () => {} : onContextMenu}
                                    pinNote={pinNote}
                                    archiveNote={archiveNote}
                                    note={note}
                                    initialCornerIcon={note.archived ? 'archive' : note.pinned ? 'pin' : ''}
                                    hoverCornerIcon={note.archived ? 'activate' : note.pinned ? 'unpin' : ''}
                                    commentInputShown={activeCommentInput === note.id}
                                    tagsOpened={activeTaggedNote === note.id}
                                    onTagsClosed={() => setActiveTaggedNote('')}
                                    hideCommentInput={() => setActiveCommentInput('')}
                                    commentDisabled={disabled}
                                    showAnnotations={showAnnotations}
                                />
                            </div>
                        )
                    }
                    return null
                })}
            </>
        )
        
        return null
    }

    Froala.DefineIcon('saveNote', { NAME: 'SAVE NOTE', template: 'text' });
    Froala.RegisterCommand('saveNote', {
        title: 'Save note',
        icon: 'saveNote',
        focus: true,
        undo: false,
        refreshAfterCallback: true,
        callback: function () {
            const text = this.html.get()
            submitNote(text)
        },
        refresh: function ($btn) {
            const text = this.html.get()
            if (text) {
                $btn.removeClass('fr-disabled')
            } else if (!text) {
                $btn.addClass('fr-disabled')
            }
        }
    })

    useLayoutEffect(() => {
        if (hash && anchorNoteEl?.current) {
            setTimeout(() => anchorNoteEl.current.scrollIntoView({
                behavior: 'smooth', block: 'center'
            }), 100)
        }
    }, [hash, anchorNoteEl.current, data && data[queryName]])

    return (
        <div className='notes-container'>
            <ul className='note-tag-filters d-inline-flex flex-wrap'>
                {tagFilters?.map(tag => {
                    const isActive = activeTagFilters.includes(tag.id)
                    return (
                        <li key={tag.label} className='mr-05'>
                            <button
                                className='btn btn--naked btn--inline-icon p-05 pr-1'
                                style={{ backgroundColor: isActive ? `${tag.color}40` : undefined }}
                                onClick={() => setActiveTagFilters(prev => {
                                    if (!isActive) return [...prev, tag.id]
                                    return prev.filter(x => x !== tag.id)
                                })}
                                title={`${isActive ? 'Exclude' : 'Include'} ${tag.label.toLowerCase()} notes`}
                            >
                                <Icon
                                    type={tag.id === 'none' ? 'label-outline' : 'label'}
                                    variant='2xs'
                                    fill={tag.color}
                                    style={{ transform: 'rotate(45deg)' }}
                                />
                                {tag.label}
                            </button>
                        </li>
                    )
                })}
                {!!activeTagFilters.length &&
                    <li key='clear-tag-filters'>
                        <button
                            className='btn btn--naked btn--inline-icon p-05 pr-1'
                            onClick={() => setActiveTagFilters([])}
                            title='Clear all tag filters'
                        >
                            <Icon
                                type='close'
                                variant='2xs'
                            />
                            Clear
                        </button>
                    </li>
                }
            </ul>
            {!disabled &&
                <div className='note note--new'>
                    <div className='t-xs pos-relative' style={{ marginLeft: '40px', width: '250px' }}>
                        <NoteTags
                            tags={tags}
                            isOpen={activeTaggedNote === 'new'}
                            onClose={() => setActiveTaggedNote('')}
                            onTagSelect={tag => setTags(prev => ([...prev, tag]))}
                            onTagRemove={({ id }) => setTags(prev => (prev.filter(t => t.id !== id)))}
                            hideAddButton
                        />
                    </div>
                    <div className='d-flex pos-relative'>
                        <ProfileImage url={currentUser.picture} />
                        <div className='note__text editor--full'>
                            <TextEditor
                                key={`new-note-${objectId}-${contentType}-${rerenderKey}`}
                                model={note}
                                onModelChange={setNote}
                                config={{
                                    placeholderText: 'Add note.',
                                    toolbarButtons: ['bold', 'italic', 'underline', 'backgroundColor', 'formatOL', 'formatUL', 'clearFormatting', 'emoticons', 'insertLink', 'insertTemplate', 'addTag', '|', 'saveNote'],
                                    events: {}
                                    // events: {'keydown': (e) => handleKeyDown({...e, tags})}
                                }}
                                onAddTag={() => setActiveTaggedNote('new')}
                                saveCommand='saveNote'
                                immediateReactModelUpdate
                                allowMentions
                                hasSaveButton
                            />
                        </div>
                    </div>
                    {newNoteError &&
                        <p class='t-red t-right'>Error :( {newNoteError.message}</p>
                    }
                </div>
            }
            <div ref={noteList}>
                {renderNotes()}
            </div>
            <ContextMenu
                key='note-context-menu'
                element={noteList.current}
                {...contextMenu}
            />
        </div>
    )
}

export default NotesFeed
