import DateString from "../../components/DateString"
import { lbl } from "../../constants"

const ContractPeriodLabel = ({ startDate, endDate, forIndefinitePeriod }) => (
    <div className='d-inline-flex'>
        {startDate ?
            <DateString dateStr={startDate} />
        : 'N/A'}
        <span className='ml-1 mr-1'>-</span>
        {forIndefinitePeriod ?
            <div>{lbl.indefinite}</div>
        : endDate ?
            <DateString dateStr={endDate} />
        : 'N/A'}
    </div>
)

export default ContractPeriodLabel
