import Froala from 'froala-editor'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { ADD_COMMENT } from '../mutations'
import { ProfileImage } from '../../components'
import { formatDateTimeLabel } from '../../utils/stringUtils'
import ChatFeed from '../../messenger/components/ChatFeed'
import Icon from '../../components/Icon'
import TextEditor from '../../components/TextEditor'
import { CONTENT_MODEL_MAP } from '../../constants'
import NoteTags from './NoteTags'

const Note = ({
    note,
    onContextMenu,
    pinNote,
    archiveNote,
    initialCornerIcon,
    hoverCornerIcon,
    commentInputShown,
    hideCommentInput,
    commentDisabled,
    showAnnotations,
    tagsOpened,
    onTagsClosed
}) => {
    const { id, body, createdAt, createdBy, comments, pinned, archived, annotations, tags } = note
    const { dayLabel, timeLabel } = formatDateTimeLabel(createdAt)
    const [cornerIcon, setCornerIcon] = useState(initialCornerIcon)
    const [comment, setComment] = useState('')
    const [addComment, { error }] = useMutation(ADD_COMMENT, {
        onCompleted: () => {
            setComment('')
            hideCommentInput()
        }
    })

    const submitComment = (body) => {
        if (body) {
            const mentionIds = body?.match(/data-mention-id="(.*?)"/g)?.map(item => item.match(/^data-mention-id="(.*)"/)[1]) || [];

            addComment({ variables: {
                body,
                noteId: id,
                mentionIds
            }})
        }
    }

    const handleKeyDown = ({ key, shiftKey, altKey, target, preventDefault }) => {
        if (!!target?.innerText.trim() && key === 'Enter' && !shiftKey && !altKey) {
            preventDefault()
            submitComment(target.innerHTML.replace(/<p><br><\/p>+$/, ''))
        }
    }

    Froala.DefineIcon('saveComment', { NAME: 'SAVE COMMENT', template: 'text' });
    Froala.RegisterCommand('saveComment', {
        title: 'Save comment',
        icon: 'saveComment',
        focus: true,
        undo: false,
        refreshAfterCallback: true,
        callback: function () {
            const text = this.html.get()
            submitComment(text)
        },
        refresh: function ($btn) {
            const text = this.html.get()
            if (text) {
                $btn.removeClass('fr-disabled')
            } else if (!text) {
                $btn.addClass('fr-disabled')
            }
        }
    })

    useEffect(() => {
        setCornerIcon(initialCornerIcon)
    }, [initialCornerIcon])

    return (
        <div id={id} className={`note${pinned ? ' note--pinned' : ''}${archived ? ' note--archived' : ''}`}>
            <div>
                <div className='d-flex justify-space-between t-xs mb-1'>
                    <div className='w-50 pos-relative flex-grow-1 flex-wrap'>
                        <NoteTags
                            isOpen={tagsOpened}
                            onClose={onTagsClosed}
                            noteId={id}
                            tags={tags}
                        />
                    </div>
                    <div className='note__annotations t-grey w-50 flex-grow-1'>
                        {showAnnotations && !!annotations?.length &&
                            <ul className='d-flex justify-end'>
                                {annotations.map(({ label, contentType, objectId }, i) => (
                                    <Link
                                        className={`d-inline-flex t-underline-hover${i === 0 ? '' : ' ml-1'}`}
                                        key={label}
                                        to={CONTENT_MODEL_MAP[contentType.model]?.path + objectId}
                                    >
                                        <Icon type={contentType.model} variant='xs' classAdditions='mr-05' />
                                        <span className='mt-05'>{label}</span>
                                    </Link>
                                ))}
                            </ul>
                        }
                    </div>
                </div>
                <div
                    className='d-flex'
                    onContextMenu={() => onContextMenu(note)}
                >
                    <ProfileImage url={createdBy.picture} />
                    <div className='note__text'>
                        <div
                            className='note__body t-s'
                            dangerouslySetInnerHTML={{ __html: body }}
                        />
                        <div className='d-flex note__footer'>
                            <span className='note__author t-sm'>
                                <strong>{createdBy.fullName}</strong>
                            </span>
                            <span className='note__time t-xs t-grey'>
                                {`${dayLabel}, ${timeLabel}`}
                            </span>
                        </div>
                    </div>
                </div>
                
                {!commentDisabled && (!!comments?.length || commentInputShown) &&
                    <div className='note__comments'>
                        {!!comments?.length &&
                            <ChatFeed
                                messages={comments}
                                handleContextMenu={comment => onContextMenu(comment)}
                                preventScroll
                                areComments
                            />
                        }
                        <div className='comment-editor editor--inline'>
                            <TextEditor
                                key={`new-comment--note-${id}`}
                                model={comment}
                                onModelChange={setComment}
                                config={{
                                    placeholderText: 'Add comment.',
                                    events: {'keydown': handleKeyDown},
                                    toolbarBottom: true,
                                    toolbarButtons: {
                                        moreMisc: {
                                            buttons: ['saveComment'],
                                            align: 'right'
                                        }
                                    }
                                }}
                                focusOnMount={commentInputShown}
                                allowMentions
                                saveCommand='saveComment'
                                immediateReactModelUpdate
                            />
                            {error &&
                                <p className='t-red t-xs'>Error :( {error.message}</p>
                            }
                        </div>
                    </div>
                }
            </div>
            {(pinned || archived) &&
                <button
                    className='btn btn--round btn--inline-icon note__corner-btn'
                    title={archived ? 'Restore from archive' : 'Remove pin'}
                    onMouseEnter={() => setCornerIcon(hoverCornerIcon)}
                    onMouseLeave={() => setCornerIcon(initialCornerIcon)}
                    onClick={() => {
                        archived ?
                            archiveNote({ variables: { id, archived: false } })
                        : 
                            pinNote({ variables: { id, pinned: false } })
                    }}
                >
                    <Icon type={cornerIcon} variant='xs' />
                </button>
            }
        </div>
    )
}

export default Note

