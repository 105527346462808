import { useEffect, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, useMutation, useReactiveVar } from '@apollo/client'
import { TabNavContext } from '../../components/TabNav'
import { ProfileImage, Icon, Label } from '../../components'
import { TextField } from '../../forms'
import { SearchSelect, LazySelectForm } from '../../forms/Select'
import { tabNavLinks, lbl, callStatusOptions } from '../../constants'
import { currentUserVar, twilioDeviceVar } from '../../cache'
import { SET_RECEIVER_ID, SET_CALLER_ID, SET_CALL_INFO, RESOLVE_CALL } from '../mutations'
import { GET_CALL } from '../queries'
import { GET_ALL_USERS, GET_DEPARTMENTS } from '../../queries'
import { formatDateTimeLabel, toTitleCase } from '../../utils'

const Call = () => {
    const currentUser = currentUserVar()
    const { device } = useReactiveVar(twilioDeviceVar)
    const { id, pinnedTabs, openTab, renameTab } = useContext(TabNavContext)
    const [showContacts, setShowContacts] = useState(false)
    const [isLableHovered, setLabelHovered] = useState(false)
    const [refetchAudio, setRefetchAudio] = useState(0)
    const { loading, error, data, called } = useQuery(GET_CALL, { variables: { sid: id } })
    const [setReceiver] = useMutation(SET_RECEIVER_ID)
    const [setCaller] = useMutation(SET_CALLER_ID)
    const [setCallInfo] = useMutation(SET_CALL_INFO)
    const [resolveCall] = useMutation(RESOLVE_CALL)
    const relativeUrl = tabNavLinks.calls.dynamicPath + id
    const isPinned = pinnedTabs.get(relativeUrl)
    const { call } = data || {}
    const isInbound = call && call.direction === 'INBOUND'
    const { dayLabel, timeLabel } = call ? formatDateTimeLabel(call.dateTime) : {}
    let contact, agent, contactNumber, name

    if (call) {
        contact = isInbound ? call.caller : call.receiver
        agent = isInbound ? call.receiver : call.caller
        contactNumber = isInbound ? call.fromNumber : call.toNumber
        name = contact ? contact.fullName : contactNumber
    }

    useEffect(() => {
        called && name && !isPinned && openTab({
            id,
            name,
            relativeUrl,
            icon: `call-${call.direction.toLowerCase()}`
        })
    }, [called, name, id, isPinned])

    if (loading) return <div className='loader pos-center-y' />
    if (error || !data?.call) return <p>Error :(</p>

    const isEditable = currentUser.isSuperuser || currentUser.id === agent?.id
    const onContactChange = (newContact) => {
        renameTab({ id, name: newContact.fullName, relativeUrl })
    }

    const renderContactSelect = () => (
        <SearchSelect
            searchable
            isHidden
            showLoader
            searchBy='fullName'
            onSelect={([user]) => {
                isInbound ?
                    setCaller({
                        variables: {
                            callerId: user.id,
                            callSid: id,
                            phoneNumber: call.fromNumber
                        },
                        onCompleted: ({ setCallerId }) => {
                            onContactChange(setCallerId.call.caller)
                        }
                    })
                :
                    setReceiver({
                        variables: {
                            receiverId: user.id,
                            callSid: id,
                            phoneNumber: call.toNumber
                        }, onCompleted: ({ setReceiverId }) => {
                            onContactChange(setReceiverId.call.receiver)
                        }
                    })
            }}
            optionsQuery={{ query: GET_ALL_USERS, name: 'users'}}
            labelField='fullName'
            valueField='id'
            keepSelectedInList={false}
            onDropdownClose={() => setShowContacts(false)}
            selectedOptions={[{ id: contact?.id || 'NONE', fullName: contact?.name || 'Unknown' }]}
            getLabelNode={({ item: { id, fullName, picture }, isSelectedContent }) => (<>
                {id === 'NONE' && !isSelectedContent ?
                    <Icon type='close' variant='xs' /> :
                    <ProfileImage url={picture} size='xs' />
                }
                {fullName}
            </>)}
        />
    )

    return (
        <div className='call-detail-page split-panel'>
            <section className='split-panel--main'>
                {call.missed &&
                    <button
                        className='btn btn--naked mb-2'
                        onMouseEnter={() => setLabelHovered(true)}
                        onMouseLeave={() => setLabelHovered(false)}
                        onClick={() => {
                            if (window.confirm('This action will remove the call from missed calls list for all users.')) {
                                resolveCall({ variables: { callSid: id } })
                            }
                        }}
                    >
                        <Label
                            classModifier={isLableHovered ? 'blue' : 'red'}
                            className='label--uppercase label--archive'
                            iconProps={{
                                type: isLableHovered ? 'checkmark-double' : 'call-missed',
                                variant: '2xs'
                            }}
                            label={isLableHovered ? 'Mark as resolved' : lbl.missedCall}
                        />
                    </button>
                }
                <div className='t-grey t-xs t-uppercase'>Subject:</div>
                <TextField
                    inputProps={{
                        type: 'input',
                        name: 'subject',
                        role: 'textbox',
                        placeholder: 'Add subject',
                        value: call.subject,
                        contentEditable: isEditable
                    }}
                    onSubmit={(subject) => setCallInfo({ variables: { subject, callSid: id } })}
                    size='xl'
                    as='div'
                    innerHtml={call.subject}
                    hidePencil={!isEditable}
                />
                <br/>
                <div className='t-grey t-xs t-uppercase'>Summary:</div>
                <TextField
                    inputProps={{
                        type: 'input',
                        name: 'summary',
                        placeholder: 'Add summary',
                        role: 'textbox',
                        value: call.summary,
                        contentEditable: isEditable
                    }}
                    onSubmit={(summary) => setCallInfo({ variables: { summary, callSid: id } })}
                    size='md'
                    as='div'
                    innerContent='innerHTML'
                    innerHtml={call.summary}
                    hidePencil={!isEditable}
                />
                {call.recordingUrl &&
                    <>
                        <br/>
                        <div className='t-grey t-xs t-uppercase'>
                            {!call.duration && call.recordingUrl ? 'Voice mail:' : 'Recording:'}
                        </div>
                        <div className='audio__wrapper mt-2'>
                            <audio
                                key={`refetched-${refetchAudio}`}
                                controls
                                onError={() => refetchAudio < 5 && setTimeout(() => setRefetchAudio(prev => prev + 1), 2000)}
                            >
                                <source src={call.recordingUrl} />
                            </audio>
                        </div>
                    </>
                }
                {call.errorMessage &&
                    <>
                        <br/>
                        <div className='t-grey t-xs t-uppercase'>Error:</div>
                        <div className='mt-2'>
                            {call.errorMessage}
                        </div>
                    </>
                }
            </section>
            <aside className='split-panel--side split-panel--right'>
                <div className='split-panel--side-inner'>
                    <div className='d-flex d-column mb-2'>
                        <br/><br/>
                        <ProfileImage url={contact?.profile.picture} size='2xl' />
                        {contact &&
                            <div className='d-inline-flex mt-1 pos-relative'>
                                <Link
                                    to={tabNavLinks.contacts.dynamicPath + contact.id}
                                    title={contact.fullName}
                                    className='t-underline-hover d-flex d-column'
                                >
                                    {contact.fullName}
                                </Link>
                                <button
                                    className='btn btn--naked btn--inline-icon'
                                    style={{ position: 'absolute', left: '100%' }}
                                    onClick={() => setShowContacts(true)}
                                    title='Change contact'
                                >
                                    <Icon type='close' variant='2xs' />
                                </button>
                                {showContacts && renderContactSelect()}
                            </div>
                        }
                        <div className='t-grey d-flex mt-1 t-underline-hover'>
                            <button
                                className='btn btn--naked d-inline-flex'
                                onClick={() => device.emit('call', { number: contactNumber, contact })}
                                title={`call ${contactNumber}`}
                            >
                                <Icon type='call' variant='2xs' classAdditions='mr-05' />
                                {contactNumber}
                            </button>
                        </div>
                        
                        {!contact &&
                            <div className='pos-relative'>
                                <button
                                    className='btn btn--tertiary mt-2'
                                    onClick={() => setShowContacts(true)}
                                >
                                    Identify contact
                                </button>
                                {showContacts && renderContactSelect()}
                            </div>
                        }
                    </div>
                    <hr/>
                    <section id='call-properties'>
                        <dl className='dl'>
                            <li className='dl__li t-s'>
                                <dt>{lbl.status}</dt>
                                <dd>
                                    <Label
                                        className='t-sm'
                                        classModifier={callStatusOptions[call.status].color}
                                        label={callStatusOptions[call.status].label}
                                        iconProps={{ type: callStatusOptions[call.status].icon }}
                                    />
                                </dd>
                            </li>
                            <li className='dl__li t-s'>
                                <dt>Department</dt>
                                <dd>
                                    <LazySelectForm
                                        onSelect={([option]) => option.id !== 'NONE' && option.id !== call.department?.id && setCallInfo({ variables: { departmentId: option.id, callSid: id }})}
                                        optionsQuery={{
                                            query: GET_DEPARTMENTS,
                                            name: 'departments'
                                        }}
                                        labelField='name'
                                        valueField='id'
                                        keepSelectedInList={false}
                                        selectedOptions={[call.department || { id: 'NONE', name: lbl.selectDepartment }]}
                                        getLabelNode={({ item }) => item.id === 'NONE' ? item.name : <span>{item.icon ? `${item.icon}  ` : ''}{toTitleCase(item.name)}</span>}
                                        placeholder={lbl.selectDepartment}
                                    />
                                </dd>
                            </li>
                            <li className='dl__li t-s'>
                                <dt>Agent</dt>
                                <dd>
                                    {agent ?
                                        <Link
                                            to={tabNavLinks.contacts.dynamicPath + agent.id}
                                            title={agent.fullName}
                                            className='d-inline-flex t-none t-sm label-wrapper'
                                        >
                                            <ProfileImage
                                                url={agent.profile.picture}
                                                size='xs'
                                            />
                                            <div className='d-inline-block ml-1'>
                                                {agent.fullName}
                                            </div>
                                        </Link>
                                    :
                                        '/'
                                    }
                                </dd>
                            </li>
                            <li className='dl__li t-s'>
                                <dt>Started</dt>
                                <dd>
                                    <Label
                                        className='time-label'
                                        iconProps={{ type: call.outsideOfficeHours ? 'clock-alert' : 'clock' }}
                                        label={`${dayLabel}, ${timeLabel}${call.isEmergency ? ' - emergency call' : ''}`}
                                        classModifier={call.isEmergency ? 'red' : ''}
                                    />
                                </dd>
                            </li>
                            <li className='dl__li t-s'>
                                <dt>Duration</dt>
                                <dd>
                                    <Label
                                        className='time-label'
                                        iconProps={{ type: 'clock-sand' }}
                                        label={call.duration > 59 ? `${Math.round(call.duration/60)} min` : `${call.duration} sec`}
                                    />
                                </dd>
                            </li>
                            <li className='dl__li t-s'>
                                <dt>Direction</dt>
                                <dd>
                                    <Label
                                        className='t-sm'
                                        iconProps={{ type: `call-${call.direction.toLowerCase()}` }}
                                        label={toTitleCase(call.direction)}
                                    />
                                </dd>
                            </li>
                            {call.transferredFromCall &&
                                <li className='dl__li t-s'>
                                    <dt>Transfered from</dt>
                                    <dd>
                                        <Link
                                            to={tabNavLinks.calls.dynamicPath + call.transferredFromCall.callSid}
                                            className='d-inline-flex t-none t-sm'
                                        >
                                            <Label
                                                className='label-wrapper'
                                                iconProps={{ type: 'call' }}
                                                label={<div>
                                                        {call.transferredFromCall.caller?.fullName || call.transferredFromCall.fromNumber} /<br/>
                                                        {call.transferredFromCall.receiver?.fullName || call.transferredFromCall.toNumber}
                                                    </div>}
                                            />
                                        </Link>
                                    </dd>
                                </li>
                            }
                            {call.transferredToCall &&
                                <li className='dl__li t-s'>
                                    <dt>Transfered to</dt>
                                    <dd>
                                        <Link
                                            to={tabNavLinks.calls.dynamicPath + call.transferredToCall.callSid}
                                            className='d-inline-flex t-none t-sm'
                                        >
                                            <Label
                                                className='label-wrapper'
                                                iconProps={{ type: 'call-forward' }}
                                                label={<div>
                                                    {call.transferredToCall.caller?.fullName || call.transferredToCall.fromNumber} /<br/>
                                                    {call.transferredToCall.receiver?.fullName || call.transferredToCall.toNumber}
                                                </div>}
                                            />
                                        </Link>
                                    </dd>
                                </li>
                            }
                            {call.resolvedBy &&
                                <li className='dl__li t-s'>
                                    <dt>Resolved</dt>
                                    <dd>
                                        <Link
                                            to={tabNavLinks.contacts.dynamicPath + call.resolvedBy.id}
                                            title={call.resolvedBy.fullName}
                                            className='d-inline-flex t-underline-hover'
                                        >
                                            <ProfileImage
                                                url={call.resolvedBy.picture}
                                                size='xs'
                                            />
                                            <div className='d-inline-block ml-1'>
                                                {call.resolvedBy.fullName}
                                            </div>
                                        </Link>
                                    </dd>
                                </li>
                            }
                        </dl>
                    </section>
                </div>
            </aside>
        </div>
    )
}

export default Call
