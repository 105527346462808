import { Link } from 'react-router-dom'
import Accordion from '../../components/Accordion'
import BuildingInfoForm from './BuildingInfoForm'
import AddressInfoForm from './AddressInfoForm'
import UnitInfoForm from './UnitInfoForm'
import { Icon } from '../../components'
import { tabNavLinks } from '../../constants'
import { newDraftsVar } from '../../cache'
import React from 'react'

const PropertyInfoForms = ({
    contentType,
    objectId,
    building,
    addresses=[],
    units=[]
}) => {
    return (
        <>
            <Accordion
                isOpen={contentType === 'building'}
                Tag='div'
                className='bg-light p-1 br-05 mb-1'
                headerClass='accordion__header--bordered t-md t-heading mr-4'
                label={
                    <Link
                        className='d-flex justify-space-between t-underline-hover'
                        to={tabNavLinks.properties.building.dynamicPath + building.id}
                    >
                        {building.name}
                        <Icon type='building' variant='xs' classAdditions='mr-05'/>
                    </Link>
                }
            >
                <div className='ml-4 mr-4 form--building'>
                    <BuildingInfoForm building={building} />
                </div>
            </Accordion>
            
            {addresses.map(address => (
                <React.Fragment key={address.streetWithHouseNumber}>
                    <Accordion
                        isOpen={contentType === 'address' && objectId === address.id}
                        Tag='div'
                        className='bg-light p-1 br-05 mb-1'
                        headerClass='accordion__header--bordered t-md t-heading mr-4'
                        label={
                            <Link
                                className='d-flex justify-space-between t-underline-hover'
                                to={tabNavLinks.properties.dynamicPath + address.id}
                            >
                                {address.streetWithHouseNumber}
                                <Icon type='property' variant='xs' classAdditions='mr-05'/>
                            </Link>
                        }
                    >
                        <div className='ml-4 mr-4 form--address'>
                            <AddressInfoForm address={address} />
                        </div>
                    </Accordion>
                </React.Fragment>
            ))}

            {units.map(unit => (
                <Accordion
                    key={unit.name + unit.id}
                    isOpen={contentType === 'room' && objectId === unit.id}
                    Tag='div'
                    className='bg-light p-1 br-05 mb-1'
                    headerClass='accordion__header--bordered t-md t-heading mr-4'
                    label={
                        <Link
                            className='d-flex justify-space-between t-underline-hover'
                            to={tabNavLinks.properties.unit.dynamicPath + unit.id}
                        >
                            {unit.fullUnitName}
                            <Icon type='room' variant='xs' classAdditions='mr-05'/>
                        </Link>
                    }
                >
                    <div className='ml-4 mr-4 form--unit'>
                        <UnitInfoForm unit={unit} />
                    </div>
                </Accordion>
            ))}

            {contentType === 'building' ?
                <Link
                    className='btn--new-item d-inline-block t-none mt-1'
                    to={tabNavLinks.properties.property.new.relativeUrl}
                    onClick={() => {
                        newDraftsVar({ ...newDraftsVar(), property: { buildingId: objectId } })
                    }}
                >
                    <span className="plus">+</span>
                    {tabNavLinks.properties.property.new.btnLabel}
                </Link>
            : contentType === 'address' ?
                <Link
                    className='btn--new-item d-inline-block t-none mt-1'
                    to={tabNavLinks.properties.unit.new.relativeUrl}
                    onClick={() => { 
                        newDraftsVar({ ...newDraftsVar(), unit: { addressId: objectId } })
                    }}
                >
                    <span className="plus">+</span>
                    {tabNavLinks.properties.unit.new.btnLabel}
                </Link>
            : null}
        </>
    )
};

export default PropertyInfoForms
