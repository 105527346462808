import { useMutation, useQuery } from '@apollo/client'
import { UPDATE_BUILDING } from '../mutations'
import { GET_CITIES, GET_HOMEOWNER_ASSOCIATIONS, GET_PARTNERS, GET_LANDLORDS } from '../queries';
import FormWithCustomFields from '../../forms/FormWithCustomFields'


const BuildingInfoForm = ({
    building={},
    draft,
    submitMutation=UPDATE_BUILDING,
    onCompleted,
    setDraft
}) => {
    const {data: hoaData, error: hoaError} = useQuery(GET_HOMEOWNER_ASSOCIATIONS)
    const {data: cityData, error: cityError} = useQuery(GET_CITIES)
    const {data: ownerData, error: ownerError} = useQuery(GET_LANDLORDS)
    const {data: partnerData, error: partnerError} = useQuery(GET_PARTNERS)
    const initialState = draft || {
        ...building,
        homeownerAssociationId: building.homeownerAssociation?.id,
        ownerId: building.owner?.id,
        caretakerId: building.caretaker?.id,
        cityId: building.city?.id
    }
    const [updateBuilding, { loading, called, data, error }] = useMutation(submitMutation, { onCompleted })

    const success = !error && !loading && called && !!data?.updateBuilding?.building
    let options = hoaData?.homeownerAssociations ? hoaData.homeownerAssociations.map(({ id, name }) => ({ value: id, label: name })) : [
        { value: building.homeownerAssociation?.id, label: building.homeownerAssociation?.name },
        { value: 'hoa-x', label: hoaError?.message || 'Loading ...' }
    ]
    let cityOptions = cityData?.cities ? cityData.cities.map(({ id, name }) => ({ value: id, label: name })) : [
        { value: building.city?.id, label: building.city?.name },
        { value: 'city-x', label: cityError?.message || 'Loading ...' }
    ]
    let ownerOptions = ownerData?.landlords ? ownerData.landlords.map(({ id, user }) => ({ value: id, label: user.fullName })) : [
        { value: building.owner?.id, label: building.owner?.user.fullName },
        { value: 'owner-x', label: ownerError?.message || 'Loading ...' }
    ]
    let partnerOptions = partnerData?.partners ? partnerData.partners.map(({ id, user }) => ({ value: id, label: user.fullName })) : [
        { value: building.caretaker?.id, label: building.caretaker?.user.fullName },
        { value: 'partner-x', label: partnerError?.message || 'Loading ...' }
    ]
    const fields = [
        { name: 'name', required: true },
        { name: 'cityId', required: true, lblKey: 'city', As: 'select', options: cityOptions.sort((a, b) => (a.label > b.label) ? 1 : -1) },
        { name: 'ownerId', lblKey: 'owner', As: 'select', options: ownerOptions.sort((a, b) => (a.label > b.label) ? 1 : -1) },
        { name: 'homeownerAssociationId', lblKey: 'homeownerAssociation', As: 'select', options: options.sort((a, b) => (a.label > b.label) ? 1 : -1) },
        { name: 'caretakerId', lblKey: 'caretaker', As: 'select', options: partnerOptions.sort((a, b) => (a.label > b.label) ? 1 : -1) },
        { name: 'keycode', disabled: true }
    ]

    return (
        <FormWithCustomFields
            contentType='building'
            objectId={building.id}
            fields={fields}
            initialState={initialState}
            onSubmit={(data, cancelChanges, saveCustomFields) => {
                updateBuilding({
                    variables: { id: building.id, ...data },
                    onCompleted: (c) => {
                        if (c && saveCustomFields) {
                            saveCustomFields(building.id || c.createBuilding?.building?.id)
                        }
                        onCompleted && onCompleted(c)
                    }
                })
            }}
            onCancel={onCompleted}
            isSubmitting={loading}
            isSuccess={success}
            setDraft={setDraft}
            formClassName='form--column--2'
        />
    )
};

export default BuildingInfoForm
