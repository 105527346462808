import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { ProfileImage, Label, Icon, HighlightedText } from '../../components'
import { tabNavLinks, priorityOptions, statusOptions, lbl } from '../../constants'

const TaskListItem = ({
    id,
    name,
    priority,
    assignee,
    createdBy,
    dueDateLabel,
    dueDateOffset,
    status,
    approvalRequired,
    ticket,
    archived,
    deleted,
    index,
    onSelect,
    isSelected,
    isAnySelected,
    propKeys,
    listCtrlVar,
    isArchive
}) => {
    const history = useHistory()
    const [hovered, setHovered] = useState(false)
    const statusIconType = isSelected ? 'checked' : hovered || isAnySelected ? 'check-circle' : statusOptions[status].icon
    let { searchTerm } = useReactiveVar(listCtrlVar)

    return (
        <tr
            onClick={() => history.push(`${tabNavLinks.tasks.dynamicPath}${id}`)}
            className={`lister__row${index===0 ? ' b-none' : ''}`}
            style={{
                opacity: archived && !isArchive ? 0.5 : deleted ? 0.3 : 1
            }}
        >
            {propKeys.map((key, i) => (
                <td className='lister__cell t-sm' key={key+i}>
                    {key === 'status' ?
                        <div className='lister-cell__wrapper' title={status}>
                            <button
                                className='btn btn--round'
                                role='checkbox'
                                aria-checked={isSelected}
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    onSelect(id, name, archived)
                                }}
                                onMouseEnter={() => setHovered(true)}
                                onMouseLeave={() => setHovered(false)}
                                title={isSelected ? 'deselect' : 'select'}
                            >
                                <Icon
                                    type={statusIconType}
                                    variant='sm'
                                />
                            </button>
                        </div>
                    : key === 'name' ?
                        <Link
                            className='t-none'
                            to={`${tabNavLinks.tasks.dynamicPath}${id}`}
                            title={name}
                        >
                            <HighlightedText text={name} mark={searchTerm} />
                        </Link>
                    : key === 'dueDate' && dueDateLabel ?
                        <Label
                            className='time-label'
                            iconProps={{ type: 'time' }}
                            label={dueDateLabel}
                            classModifier={dueDateOffset < 2 ? 'red' : dueDateOffset < 3 ? 'yellow' : 'grey'}
                        />
                    : key === 'ticket' && ticket ?
                        <Label
                            className='justify-space-between'
                            classModifier='grey'
                            label={<HighlightedText text={ticket.name} mark={searchTerm} />}
                            iconProps={{ type: 'ticket' }}
                        />
                    : key === 'createdBy' ?
                        <div className='lister-cell__wrapper'>
                            {createdBy && 
                                <>
                                    <ProfileImage
                                        url={createdBy.picture}
                                        size='xs'
                                        iconProps={{ type: 'reporter' }}
                                    />
                                    <div className='d-inline-block ml-1'>
                                        <HighlightedText text={createdBy.fullName} mark={searchTerm} />
                                    </div>
                                </>
                            }
                        </div>
                    : key === 'priority' ?
                        <Label
                            className=''
                            iconProps={{ type: priorityOptions[priority].icon }}
                            label={priorityOptions[priority].shortLabel}
                            classModifier={priorityOptions[priority].color}
                        />
                    : key === 'assignee' && assignee ?
                        <div className='lister-cell__wrapper'>
                            <ProfileImage url={assignee.picture} size='xs' />
                            <div className='d-inline-block ml-1'>
                                <HighlightedText text={assignee.fullName} mark={searchTerm} />
                            </div>
                        </div>
                    : key === 'archived' && archived ?
                        <div className='lister-cell__wrapper'>
                            <Icon
                                type='archive'
                                variant='xs'
                                wrapperClassAdditions='mx-auto'
                            />
                        </div>
                    : key === 'approvalRequired' ?
                        <div className='lister-cell__wrapper'>
                            <Label
                                label={approvalRequired ? lbl.required : lbl.notRequired}
                                iconProps={{
                                    type: approvalRequired ? 'checkmark' : 'close',
                                    fill: approvalRequired ? 'green' : 'red',
                                    variant: 'xs',
                                    wrapperClassAdditions: 'mx-auto'
                                }}
                            />
                        </div>
                    : null}
                </td>
            ))}
        </tr>
    )
}

export default TaskListItem