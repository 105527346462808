import { useState } from 'react'
import { useThreadActions } from '../hooks'
import ThreadListItem from './ThreadListItem'
import BatchActions from '../../components/BatchActions'


const ThreadList = ({
    threads,
    showUnreadOnly,
    noReply,
    redirect,
    maxMessages=3,
    accordionClosed=false,
    hideEmpty=false
}) => {
    const [selectedThreads, setSelectThreads] = useState(new Map())
    const [getThreadActions] = useThreadActions()
    const resetSelection = () => setSelectThreads(new Map())

    const onSelect = ({ id, ...props }) => {
        if (selectedThreads.get(id)) {
            selectedThreads.delete(id)
        } else {
            selectedThreads.set(id, props)
        }
        setSelectThreads(new Map([...selectedThreads]))
    }

    return (
        <div className='lister--threads'>
            {selectedThreads && !!selectedThreads.size &&
                <BatchActions
                    actions={getThreadActions({
                        threads: Array.from(selectedThreads.values()),
                        threadIds: Array.from(selectedThreads.keys()),
                    })}
                    resetSelection={resetSelection}
                />
            }
            <ul className='thread__list'>
                {threads && !!threads.length ?
                    threads.map(thread => {
                        if (!hideEmpty || thread.messages.length) return (
                            <ThreadListItem
                                key={thread.id + thread.name}
                                onSelect={onSelect}
                                isSelected={selectedThreads && Array.from(selectedThreads.keys()).includes(thread.id)}
                                isAnySelected={selectedThreads && selectedThreads.size}
                                showUnreadOnly={showUnreadOnly}
                                maxMessages={maxMessages}
                                noReply={noReply}
                                thread={thread}
                                redirect={redirect}
                                closed={accordionClosed || thread.archived}
                            />
                        )
                        return null
                    })
                :
                    <div>No subthreads</div>
                }
            </ul>
        </div>
    )
}

export default ThreadList
