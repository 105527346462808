import { useMutation } from '@apollo/client'
import { ARCHIVE_METER, UPDATE_METER } from '../mutations'
import Form from '../../forms/Form'
import { lbl } from '../../constants'


const MeterInfoForm = ({
    meter={},
    draft,
    submitMutation=UPDATE_METER,
    mutationVariables={},
    onCompleted,
    close=()=>{},
    setDraft
}) => {
    const initialState = draft || {
        ...meter,
    }
    const [updateMeter, { loading, done, called, data, error }] = useMutation(submitMutation)
    const [archiveMeter, { loading: archiving }] = useMutation(ARCHIVE_METER)

    const success = !error && !loading && called && !!data?.updateMeter?.meter
    
    const fields = [
        { name: 'number', lblKey: 'meterNumber', wide: true },
        { name: 'location', As: 'textarea', wide: true }
    ]

    return (
        <Form
            fields={fields}
            initialState={initialState}
            onSubmit={(data, cancelChanges) => {
                updateMeter({
                    variables: { id: meter.id, ...data, ...mutationVariables },
                    onError: cancelChanges,
                    onCompleted: (c) => {
                        if (onCompleted) onCompleted(c)
                        if (setDraft) setDraft({})
                        if (!!c?.addMeter) close()
                    }
                })
            }}
            onCancel={close}
            isSubmitting={archiving || (loading && !done)}
            disabled={meter.archived}
            isSuccess={success}
            setDraft={setDraft}
            formClassName='form--column--2'
            actions={(meter.id && !meter.archived) ? [{
                label: lbl.archive,
                onClick: () => {
                    if ( window.confirm('Are you sure you want to archive this meter?')) {
                        archiveMeter({ variables: { id: meter.id }})
                    }
                }
            }] : []}
        />
    )
}

export default MeterInfoForm
