import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery, useReactiveVar } from '@apollo/client'
import { initialPropertiesQueryVars, newDraftsVar, openedTabsVar } from '../../cache'
import { CREATE_PROPERTY } from '../mutations'
import { GET_BUILDINGS, GET_PROPERTIES } from '../queries'
import { tabNavLinks, lbl } from '../../constants'
import AddressInfoForm from './AddressInfoForm'


const NewProperty = () => {
    const { data: buildingData, loading, error, client } = useQuery(GET_BUILDINGS)
    const history = useHistory()
    const propertyDraft = useReactiveVar(newDraftsVar).property
    const openedTabs = useReactiveVar(openedTabsVar)
    const { relativeUrl } = tabNavLinks.properties.property.new
    const onCompleted = (data) => {
        openedTabs.delete(relativeUrl)
        openedTabsVar(new Map([...openedTabs]))
        newDraftsVar({ ...newDraftsVar(), property: {} })
        if (data) {
            if (data.createProperty?.property) {
                client.cache.updateQuery({
                    query: GET_PROPERTIES,
                    variables: initialPropertiesQueryVars
                }, (prevData) => {
                    if (!prevData?.properties) return undefined
                    return { properties: [data.createProperty.property, ...prevData.properties] }
                })

                client.cache.modify({
                    id: client.cache.identify({ __typename: 'BuildingType', id: data.createProperty.property.building.id }),
                    fields: {
                        properties(prev=[], { toReference }) {
                            return [...prev, toReference(data.createProperty.property)]
                        }
                    }
                })
            }
            history.push(`${tabNavLinks.properties.dynamicPath}${data.createProperty?.property?.id}`)
        } else {
            history.goBack()
        }
    }
    let buildingOptions = buildingData?.buildings?.length ? buildingData.buildings.map(({ id, name }) => (
        { value: id, label: name }
    )).sort((a, b) => (a.label > b.label) ? 1 : -1) : [{ value: '', label: loading ? 'Loading...' : error ? error.message : 'No buildings available' }]

    useEffect(() => {
        openedTabsVar(new Map([...openedTabsVar(), [relativeUrl, {
            name: lbl.newProperty,
            id: 'new',
            relativeUrl,
            icon: 'property'
        }]]))
    }, [relativeUrl])

    return (
        <div className='split-panel--main'>
            <AddressInfoForm
                draft={propertyDraft}
                submitMutation={CREATE_PROPERTY}
                onCompleted={onCompleted}
                setDraft={property => { newDraftsVar({ ...newDraftsVar(), property }) }}
                extraFields={[{ name: 'buildingId', required: true, lblKey: 'building', As: 'select', options: buildingOptions }]}
            />
        </div>
    )
}

export default NewProperty
