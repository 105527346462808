import React from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { propertyListCtrlsVar } from '../../cache'
import { tabNavLinks } from '../../constants'
import { Label, HighlightedText } from '../../components'


const PropertyListItem = ({
    id,
    streetWithHouseNumber,
    postcode,
    building,
    rooms,
    archived,
    propertyType,
    operationalModel,
    propKeys
}) => {
    const history = useHistory()
    let { searchTerm } = useReactiveVar(propertyListCtrlsVar)

    return (
        <tr
            onClick={() => history.push(`${tabNavLinks.properties.dynamicPath}${id}`)}
            className='lister__row'
            style={{ opacity: archived ? 0.5 : 1 }}
        >
            {propKeys.map((key, i) => (
                <React.Fragment key={key+i}>
                    {key === 'streetWithHouseNumber' ?
                        <td className='lister__cell'>
                            <Label className='justify-start' label={<HighlightedText text={streetWithHouseNumber} mark={searchTerm} />}/>
                        </td>
                    : key === 'postcode' ?
                        <td className='lister__cell'>
                            <Label label={<HighlightedText text={postcode} mark={searchTerm} />} />
                        </td>
                    : key === 'building.city' ?
                        <td className='lister__cell'>
                            {building?.city &&
                                <Label label={<HighlightedText text={building.city.name} mark={searchTerm} />}/>
                            }
                        </td>
                    : key === 'building' ?
                        <td className='lister__cell'>
                            {building &&
                                <Link
                                    to={tabNavLinks.properties.building.dynamicPath + building.id}
                                    onClick={e => e.stopPropagation()}
                                    className='t-underline-hover'
                                >
                                    <Label
                                        className='mr-05'
                                        classModifier='grey'
                                        iconProps={{ type: 'building' }}
                                        label={<HighlightedText text={building.name} mark={searchTerm} />}
                                    />
                                </Link>
                            }
                        </td>
                    : key === 'units' ?
                        <td className='lister__cell lister__cell--multi'>
                            {rooms && rooms.map(({ fullUnitName, id }) => (
                                <Link
                                    key={id + fullUnitName}
                                    to={tabNavLinks.properties.unit.dynamicPath + id}
                                    onClick={e => e.stopPropagation()}
                                    className='t-underline-hover'
                                >
                                    <Label
                                        key={fullUnitName}
                                        className='mr-05'
                                        classModifier='grey'
                                        label={<HighlightedText text={fullUnitName} mark={searchTerm} />}
                                    />
                                </Link>
                            ))}
                        </td>
                    : key === 'propertyType' ?
                        <td className='lister__cell'>
                            {propertyType &&
                                <Label label={<HighlightedText text={propertyType.name} mark={searchTerm} />}/>
                            }
                        </td>
                    : key === 'operationalModel' ?
                        <td className='lister__cell'>
                            {operationalModel &&
                                <Label label={<HighlightedText text={operationalModel.name} mark={searchTerm} />}/>
                            }
                        </td>
                    : null}
                </React.Fragment>
            ))}
        </tr>
    )
}

export default PropertyListItem