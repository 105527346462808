import { gql } from '@apollo/client'
import { CONTACT_FRAGMENT } from './fragments'

export const CREATE_USER = gql`
    mutation CREATE_USER($email: String!, $firstName: String!, $lastName: String!, $type: String!, $employmentStatus: String) {
        createUser(email: $email, firstName: $firstName, lastName: $lastName, type: $type, employmentStatus: $employmentStatus) {
            user {
                id
                email
                firstName
                lastName
                type
            }
        }
    }
`

export const UPDATE_USER = gql`
    mutation UPDATE_USER($id: ID!, $firstName: String, $lastName: String, $email: String, $phoneNumber: String, $secondaryPhoneNumber: String, $gender: String, $country: String, $language: String, $nationality: String, $address: String, $employmentStatus: String, $dob: String, $salutation: String, $relationshipStatus: String, $countryOfOrigin: String, $countryOfResidence: String, $bsnNumber: String, $degreeTitle: String, $graduationDate: String, $about: String, $hobbies: String, $secondaryEmail: String, $facebook: String, $instagram: String, $tiktok: String, $linkedin: String, $emergencyContactName: String, $emergencyContactNumber: String, $emergencyContactEmail: String, $emergencyContactAddress: String, $emergencyContactRelationship: String, $emergencyContactLanguage: String) {
        updateUserDetails(id: $id, firstName: $firstName, lastName: $lastName, email: $email, phoneNumber: $phoneNumber, secondaryPhoneNumber: $secondaryPhoneNumber, gender: $gender, country: $country, language: $language, nationality: $nationality, address: $address, employmentStatus: $employmentStatus, dob: $dob, salutation: $salutation, relationshipStatus: $relationshipStatus, countryOfOrigin: $countryOfOrigin, countryOfResidence: $countryOfResidence, bsnNumber: $bsnNumber, degreeTitle: $degreeTitle, graduationDate: $graduationDate, about: $about, hobbies: $hobbies, secondaryEmail: $secondaryEmail, facebook: $facebook, instagram: $instagram, tiktok: $tiktok, linkedin: $linkedin, emergencyContactName: $emergencyContactName, emergencyContactNumber: $emergencyContactNumber, emergencyContactEmail: $emergencyContactEmail, emergencyContactAddress: $emergencyContactAddress, emergencyContactRelationship: $emergencyContactRelationship, emergencyContactLanguage: $emergencyContactLanguage) {
            user {
                ...AllUserFields
            }
        }
    }
    ${CONTACT_FRAGMENT}
`

export const UPDATE_PARTNER = gql`
    mutation UPDATE_PARTNER($partnerId: ID!, $companyId: ID) {
        updatePartner(partnerId: $partnerId, companyId: $companyId) {
            partner {
                id
                company {
                    id
                    name
                }
            }
        }
    }
`

export const UPDATE_LANDLORD = gql`
    mutation UPDATE_LANDLORD($landlordId: ID!, $mandateAmount: Int) {
        updateLandlord(landlordId: $landlordId, mandateAmount: $mandateAmount) {
            landlord {
                id
                mandateAmount
            }
        }
    }
`

export const UPDATE_BANK_ACCOUNT = gql`
    mutation UPDATE_BANK_ACCOUNT($userId: ID!, $accountNumber: String, $accountHolderName: String, $accountHolderAddress: String, $bankName: String, $bankAddress: String, $bicCode: String) {
        updateBankAccount(userId: $userId, accountNumber: $accountNumber, accountHolderName: $accountHolderName, accountHolderAddress: $accountHolderAddress, bankName: $bankName, bankAddress: $bankAddress, bicCode: $bicCode) {
            bankAccount {
                id
                accountNumber
                accountHolderName
                accountHolderAddress
                bankName
                bankAddress
                bicCode
            }
        }
    }
`
