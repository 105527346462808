import React from 'react'
import { useHistory } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { contactListCtrlsVar } from '../../cache'
import { tabNavLinks, lbl, tenantStatusOptions } from '../../constants'
import { toTitleCase } from '../../utils'
import { ProfileImage, Label, HighlightedText } from '../../components'


const ContactListItem = ({
    id,
    fullName,
    profile,
    type,
    rentResponsabilityEndDate,
    tenancyStatus,
    unit,
    address,
    city,
    propKeys,
}) => {
    const history = useHistory()
    let { searchTerm } = useReactiveVar(contactListCtrlsVar)
    const onlineStatus = profile?.onlineStatus || 'OFFLINE'

    return (
        <tr
            onClick={() => history.push(`${tabNavLinks.contacts.dynamicPath}${id}`)}
            className='lister__row'>
            {propKeys.map((key, i) => (
                <React.Fragment key={key+i}>
                    {key === 'picture' ?
                        <td className='lister__cell'>
                            <ProfileImage
                                url={profile?.picture}
                                size='xs'
                            />
                        </td>
                    : key === 'fullName' ?
                        <td className='lister__cell'>
                            <div className='d-inline-block ml-1'>
                                <HighlightedText text={fullName} mark={searchTerm} />
                            </div>
                        </td>
                    : key === 'type' ?
                        <td className='lister__cell'>
                            {type &&
                                <Label
                                    className={`chip--${type}`}
                                    label={<HighlightedText text={lbl[`userType_${type}`]} mark={searchTerm} />}
                                />
                            }
                        </td>
                    : key === 'profile.onlineStatus' ?
                        <td className='lister__cell'>
                            <Label
                                className=''
                                iconProps={{ type: 'dot', wrapperClassAdditions: `icon-wrapper--${onlineStatus.toLowerCase()}` }}
                                label={<HighlightedText text={toTitleCase(onlineStatus)} mark={searchTerm} />}
                            />
                        </td>
                    : key === 'profile.employmentStatus' ?
                        <td className='lister__cell'>
                            {profile?.employmentStatus &&
                                <Label
                                    className=''
                                    label={<HighlightedText text={toTitleCase(profile.employmentStatus)} mark={searchTerm} />}
                                />
                            }
                        </td>
                    : key === 'tenancyStatus' ?
                        <td className='lister__cell'>
                            {tenancyStatus &&
                                <Label
                                    className=''
                                    label={<HighlightedText text={tenantStatusOptions[tenancyStatus].label} mark={searchTerm} />}
                                />
                            }
                        </td>
                    : key === 'city' ?
                        <td className='lister__cell'>
                            {city &&
                                <Label
                                    className=''
                                    iconProps={{ type: 'building' }}
                                    label={<HighlightedText text={city} mark={searchTerm} />}
                                />
                            }
                        </td>
                    : key === 'address' ?
                        <td className='lister__cell'>
                            {address &&
                                <Label
                                    className=''
                                    iconProps={{ type: 'address' }}
                                    label={<HighlightedText text={address} mark={searchTerm} />}
                                />
                            }
                        </td>
                    : key === 'unit' ?
                        <td className='lister__cell'>
                            {unit &&
                                <Label
                                    className=''
                                    iconProps={{ type: 'room' }}
                                    label={<HighlightedText text={unit} mark={searchTerm} />}
                                />
                            }
                        </td>
                    : key === 'rentResponsabilityEndDate' ?
                        <td className='lister__cell'>
                            {rentResponsabilityEndDate &&
                                <Label
                                    className='time-label'
                                    classModifier='grey'
                                    iconProps={{ type: 'time' }}
                                    label={<HighlightedText text={rentResponsabilityEndDate} mark={searchTerm} />}
                                />
                            }
                        </td>
                    : null}
                </React.Fragment>
            ))}
        </tr>
    )
}

export default ContactListItem