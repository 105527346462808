import React, { useEffect, useState } from 'react'
import { useReactiveVar, useMutation } from '@apollo/client'
import { Device } from '@twilio/voice-sdk'
import { Link } from 'react-router-dom'
import { ProfileImage, Icon, Popover } from '../../components'
import { tabNavLinks } from '../../constants'
import { twilioDeviceVar, currentUserVar } from '../../cache'
import { SET_RECEIVER_ID, SET_CALLER_ID } from '../mutations'
import { GET_ALL_USERS } from '../../queries'
import { SearchSelect } from '../../forms/Select'
import Dialler from './Dialler'
import Incoming from './Incoming'
import OnCall from './OnCall'
import states from '../deviceStates'
import CallAnswered from './CallAnswered'


const Phone = ({ username }) => {
    const [showContacts, setShowContacts] = useState(false)
    const [isOpen, open] = useState(false)
    const url = `${window._env_.REACT_APP_REST_ENDPOINT}/twilio_token/${username}`
    const { device, conn, call, number, contact, state } = useReactiveVar(twilioDeviceVar)
    const { status } = call || {}
    const setState = (state) => twilioDeviceVar({ ...twilioDeviceVar(), state })
    const setNumber = (number) => twilioDeviceVar({ ...twilioDeviceVar(), number })
    const setConn = (conn) => twilioDeviceVar({ ...twilioDeviceVar(), conn })
    const setDevice = (device) => twilioDeviceVar({ ...twilioDeviceVar(), device })
    const setContact = (contact) => twilioDeviceVar({ ...twilioDeviceVar(), contact })
    const setCall = (call) => twilioDeviceVar({ ...twilioDeviceVar(), call })
    const [setReceiverId] = useMutation(SET_RECEIVER_ID)
    const [setCallerId] = useMutation(SET_CALLER_ID)
    const currentUser = currentUserVar()
    const callSid = conn ? conn._direction === 'OUTGOING' ? conn.parameters?.CallSid : conn.customParameters?.get('call_sid') : ''

    const clearConnection = () => {
        setState(states.READY)
        setNumber('')
        setContact(null)
        setConn(null)
        setCall(null)
    }

    const handleConnection = (connection, isIncoming) => {
        setConn(connection)
        setCall({ status: 'initiated' })

        connection.on('accept', (c) => {
            setState(states.ON_CALL)

            // if (isIncoming) {
            //     setReceiverId({ variables: {
            //         callSid: c.customParameters.get('call_sid'),
            //         receiverId: currentUser.id
            //     } })
            // }
        })
        connection.on('disconnect', () => {
            clearConnection()
        })
        connection.on('reject', () => {
            clearConnection()
        })
        connection.on('cancel', () => {
            if (isIncoming) {
                setState(states.CANCELED)
                setTimeout(clearConnection, 4000)
            } else {
                clearConnection()
            }
        })
        connection.on('error', error => {
            console.error(error)
            clearConnection()
        })
    }

    const setupDevice = (shouldRegister=true) => {
        device && destroyDevice()

        fetch(url).then(
            response => response.json()
        ).then(data => {
            const token = data.token
            const device = new Device(token)

            device.on('registered', _device => {
                setState(states.READY)
            })

            device.on('connected', connection => {
                handleConnection(connection)
            })

            device.on('call', params => {
                if (device.audio._audioContext.state !== 'running') {
                    device.audio._audioContext.resume()
                }
                if (params.contact) setContact({
                    id: params.contact.id,
                    name: params.contact.fullName,
                    picture: params.contact.picture || params.contact.profile.picture
                })
                setState(states.CALLING)
                setNumber(params.number)

                const callParams = {
                    To: params.number,
                    Direction: 'outbound',
                    fromUserId: currentUser.id,
                    number: params.number,
                    toUserId: params.contact?.id || '',
                }

                if (currentUser.department?.id) {
                    callParams['departmentId'] = currentUser.department?.id
                }

                device.connect({ params: callParams }).then((connection) => {
                    device.emit('connected', connection)
                })
            })

            device.on('incoming', connection => {
                setState(states.INCOMING)
                const callerId = connection.customParameters.get('caller_id')

                if (callerId) {
                    setContact({
                        id: callerId,
                        name: connection.customParameters.get('caller_name'),
                        picture: connection.customParameters.get('caller_picture')
                    })
                }

                if (device.audio._audioContext.state !== 'running') {
                    device.audio._audioContext.resume()
                }

                handleConnection(connection, true)
            })

            device.on('error', error => {
                if (error.message.includes('ConnectionError')) {
                    destroyDevice()
                }
            })

            device.on('offline', () => {
                destroyDevice()
            })

            device.on('tokenWillExpire', () => {
                return fetch(url).then(
                    response => response.json()
                ).then(data => {
                    device.updateToken(data.token)
                })
            })

            shouldRegister && device.register()
            setDevice(device)
        }).catch((error) => {
            console.error(error)
        })
    }

    const destroyDevice = () => {
        device && device.destroy()
        setDevice(null)
        setState(states.OFFLINE)
    }

    const handleCall = () => {
        device.emit('call', { number })
        open(false)
    }

    const handleHangup = () => {
        setContact(null)
        device.disconnectAll()
    }

    useEffect(() => {
        destroyDevice()

        return () => {
            destroyDevice()
        }
    }, [])

    return (
        <>
            <div className={`device device--${conn ? 'connected' : 'no-connection'}  device--${isOpen ? 'open' : 'closed'} device--${state.toLowerCase()}`}>
                {conn ?
                    <div className='t-center device__connection'>
                        <div className='device__contact'>
                            {contact ?
                                <Link
                                    to={tabNavLinks.contacts.dynamicPath + contact.id}
                                    title={contact.name}
                                    className='t-underline-hover d-flex d-column mb-3'
                                >
                                    <ProfileImage
                                        url={contact.picture}
                                        size='2xl'
                                        showIconTag
                                        tagProps={{ type: `call-${conn?._direction === 'OUTGOING' ? 'outbound' : 'inbound'}`, variant: 'sm' }}
                                    />
                                    <div className='mt-1'>{contact.name}</div>
                                </Link>
                            :
                                <button
                                    className='btn btn--naked d-flex d-column mb-3 mx-auto'
                                    onClick={() => setShowContacts(!showContacts)}
                                    title='Identify contact'
                                >
                                    <ProfileImage
                                        iconProps={{ type: 'question-circle', viewBox: '10 10 20 20' }}
                                        size='2xl'
                                        showIconTag
                                        tagProps={{ type: `call-${conn?._direction === 'OUTGOING' ? 'outbound' : 'inbound'}`, variant: 'sm'}}
                                    />
                                    <div className='mt-1'>{number}</div>
                                </button>
                            }
                        </div>
                        {
                            state === states.INCOMING ?
                                <Incoming
                                    device={device}
                                    connection={conn}
                                    currentUser={currentUser}
                                />
                            : state === states.ON_CALL && callSid ?
                                <OnCall
                                    handleHangup={handleHangup}
                                    callSid={callSid}
                                    status={status}
                                />
                            : state === states.CANCELED && call?.receiver ?
                                <CallAnswered receiver={call.receiver} />
                            : null
                        }
                    </div>
                : isOpen ?
                    <Popover
                        isOpen={isOpen && !conn}
                        onClose={() => open(false)}
                        className='popover--dialler'
                        position={{ bottom: '20px', right: '20px' }}
                    >
                        <Dialler
                            number={number}
                            setNumber={setNumber}
                            call={handleCall}
                        />
                    </Popover>
                :
                    <button
                        className='btn btn--naked device__toggle'
                        title={(state === states.OFFLINE || (!device && states.CONNECTING)) ? 'Instantiate phone device' : states.CONNECTING ? 'Allow incoming calls' : 'Show dialler'}
                        onClick={() => {
                            switch(state) {
                                case states.OFFLINE:
                                    setupDevice(true)
                                    break
                                case states.CONNECTING:
                                    !device && setupDevice(true)
                                    break
                                default:
                                    open(true) 
                            }
                        }}
                    >
                        <Icon type={state === states.OFFLINE ? 'call-locked' : state === states.CONNECTING ? 'call' : 'call' } />
                    </button>
                }
            </div>
            <Popover
                isOpen={showContacts}
                onClose={() => setShowContacts(false)}
                className='popover--call-contact t-left'
            >
                <SearchSelect
                    searchable
                    isHidden
                    showLoader
                    searchBy='fullName'
                    onSelect={([{ fullName, id, picture }]) => {
                        conn._direction === 'OUTGOING' ?
                            setReceiverId({ variables: {
                                receiverId: id,
                                callSid,
                                phoneNumber: number
                            } })
                        :
                            setCallerId({ variables: {
                                callerId: id,
                                callSid,
                                phoneNumber: number || conn.parameters.From
                            } })
                        setContact({ id, picture, name: fullName })
                    }}
                    optionsQuery={{ query: GET_ALL_USERS, name: 'users'}}
                    labelField='fullName'
                    valueField='id'
                    keepSelectedInList={false}
                    onDropdownClose={() => setShowContacts(false)}
                    selectedOptions={[{ id: contact?.id || 'NONE', fullName: contact?.name || 'Unknown' }]}
                    getLabelNode={({ item: { id, fullName, picture }, isSelectedContent }) => (<>
                        {id === 'NONE' && !isSelectedContent ?
                            <Icon type='close' variant='xs' /> :
                            <ProfileImage url={picture} size='xs' />
                        }
                        {fullName}
                    </>)}
                />
            </Popover>
        </>
    )
}

export default Phone
