import { useState } from 'react'
import { ticketListCtrlsVar } from '../../cache'
import { GET_TICKETS } from '../queries'
import { listerCtrlOptions, listerColumns, ticketProperties } from '../settings'
import Lister from '../../components/Lister'
import TicketListItem from './TicketListItem'
import TicketBatchActions from './TicketBatchActions'


const TicketList = ({
    tickets,
    fetchMore,
    hideControls,
    showLoader,
    queryFieldName='tickets',
    queryVariables,
    query=GET_TICKETS,
    ctrlOptions=listerCtrlOptions,
    ...props
}) => {
    const [selectedTickets, setSelectTickets] = useState(new Map())
    const resetSelection = () => setSelectTickets(new Map())

    const onSelectTicket = (id, name, archived) => {
        if (selectedTickets.get(id)) {
            selectedTickets.delete(id)
        } else {
            selectedTickets.set(id, { name, archived })
        }
        setSelectTickets(new Map([...selectedTickets]))
    }

    const selectBatch = (items, deselect=false) => {
        items.forEach(({ id, name, archived }) => 
            deselect ? selectedTickets.delete(id) 
            : selectedTickets.set(id, { name, archived })
        )
        setSelectTickets(new Map([...selectedTickets]))
    }

    const listCtrlVar = props.listCtrlVar || ticketListCtrlsVar
    const columnKeys = props.columnKeys || listerColumns

    if (!tickets && !showLoader) return <div className='loader pos-center-y' />

    return (
        <div className='tab__panel--main intersection-root'>
            <div className='batch-actions pos-fixed'>
                {selectedTickets && !!selectedTickets.size &&
                    <TicketBatchActions
                        tickets={selectedTickets}
                        resetSelection={resetSelection}
                        optionsDict={ticketProperties}
                        refetchFilteredTickets={() => {}}
                    />
                }
            </div>
            <Lister
                options={ctrlOptions}
                optionsDict={ticketProperties}
                listerCtrlVar={listCtrlVar}
                columnKeys={columnKeys}
                items={tickets}
                hideControls={hideControls}
                controlsDisabled={selectedTickets && !!selectedTickets.size}
                onDisabledClick={resetSelection}
                onSelectBatch={selectBatch}
                resetBatchSelection={!selectedTickets.size}
                renderCtrlChildren={props.renderCtrlChildren}
                showLoader={showLoader}
                fetchMore={fetchMore}
                queryFieldName={queryFieldName}
                queryVariables={queryVariables}
                query={query}
                type='tickets'
            >
                {(itemProps) => (
                    <TicketListItem
                        onSelect={onSelectTicket}
                        isSelected={!!selectedTickets.get(itemProps.id)}
                        isAnySelected={!!selectedTickets.size}
                        propKeys={columnKeys}
                        listerCtrlVar={listCtrlVar}
                        isArchive={queryFieldName === 'archivedTickets'}
                        {...itemProps}
                    />
                )}
            </Lister>
        </div>
    )
}

export default TicketList
