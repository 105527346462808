import React from 'react'
import { RoundIconButton } from '../../components/Button'

const phoneRe = /^[+]?[0-9]{8,20}$/g;

const Dialler = ({ number, setNumber, call }) => {
    const handleNumberChange = event => {
        setNumber(event.target.value)
    }

    const handleBackSpace = () => {
        setNumber(number.substring(0, number.length - 1))
    }

    const handleNumberPressed = newNumber => {
        setNumber(`${number}${newNumber}`)
    }

    return (
        <div className='device__dialler'>
            <input
                type='tel'
                value={number}
                onChange={handleNumberChange}
                className='input'
            />
            <ol className='keypad'>
                {['1', '2', '3', '4', '5', '6', '7', '8', '9', '+', '0', '<'].map((char) => {
                    const isBackspace = char === '<'

                    if (!isBackspace || number.length > 0) {
                        return (
                            <li key={`dail-${char}`}>
                                <RoundIconButton onClick={isBackspace ? handleBackSpace : () => handleNumberPressed(char)}>
                                    {char}
                                </RoundIconButton>
                            </li>
                        )
                    }
                    return null
                })}
            </ol>
            <div className='t-center'>
                <RoundIconButton
                    disabled={!number.match(phoneRe)}
                    onClick={call}
                    color='green'
                    icon='call'
                />
            </div>
        </div>
    )
}

export default Dialler
