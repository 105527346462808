import { tabNavLinks } from "../constants"

export const getTenancyAddress = (tenancyAgreement) => {
    let addressLabel, addressUrl, contractUrl, url

    if (tenancyAgreement.unit) {
        addressLabel = tenancyAgreement.unit.longName
        addressUrl = tabNavLinks.properties.unit.dynamicPath + tenancyAgreement.unit.id
    }
    if (tenancyAgreement.contract?.unit) {
        url = tabNavLinks.properties.unit.dynamicPath + tenancyAgreement.contract.unit.id
        if (!addressLabel) addressLabel = tenancyAgreement.contract.unit.longName
        if (!addressUrl) addressUrl = url
    } else if (tenancyAgreement.contract?.property) {
        url = tabNavLinks.properties.dynamicPath + tenancyAgreement.contract.property.id
        if (!addressLabel) addressLabel = tenancyAgreement.contract.property.longName
        if (!addressUrl) addressUrl = url
    }

    contractUrl = `${url}/contracts#contract_${tenancyAgreement.contract.id}_tenancy_agreement_${tenancyAgreement.id}`

    return { addressLabel, addressUrl, contractUrl }
}


export const getLeadAddress = (lead) => {
    let addressLabel, addressUrl

    if (lead.unit) {
        addressLabel = lead.unit.longName
        addressUrl = tabNavLinks.properties.unit.dynamicPath + lead.unit.id
    } else if (lead.property) {
        addressUrl = tabNavLinks.properties.dynamicPath + lead.property.id
        addressLabel = lead.property.longName
    }

    return { addressLabel, addressUrl, leadUrl: `${addressUrl}/leads#lead_${lead.id}` }
}
