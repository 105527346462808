import { Link } from 'react-router-dom'
import { ProfileImage, Label, Icon } from '../../components'
import VoiceCall from '../../components/VoiceCall'
import { tabNavLinks } from '../../constants'

const TicketReporter = ({
    id,
    email,
    fullName,
    picture,
    profile,
    tenant,
    createdBy,
    createdDateLabel,
    createdDateOffset
}) => (
    <>
        {(createdBy && createdBy.id !== id) ?
            <>
                <section className='ticket__creator'>
                    <div className='d-flex'>
                        <div className='d-inline-flex mr-auto t-sm'>
                            Created by {createdBy.fullName}
                        </div>
                        <Label
                            className='time-label my-auto ml-1'
                            iconProps={{ type: 'time' }}
                            label={createdDateLabel}
                            classModifier={createdDateOffset < -2 ? 'red' : createdDateOffset < -1 ? 'yellow' : 'grey'}
                        />
                    </div>
                </section>
                <hr/>
            </> : <br/>
        }
        <section className='ticket__reporter'>
            <div className='d-flex'>
                <div className='d-inline-flex mr-auto'>
                    <ProfileImage url={picture} size='md' />
                    <div className='ml-2'>
                        <Link
                            to={tabNavLinks.contacts.dynamicPath + id}
                            title={`Go to ${fullName} contact page`}
                            className='t-underline-hover t-sm mb-05'
                        >
                            {fullName}
                        </Link>
                        {tenant?.address &&
                            <div className='t-grey t-xs'>
                                <Link
                                    to={tabNavLinks.properties.dynamicPath + tenant.address.id}
                                    title={`Go to address ${tenant.address.streetWithHouseNumber}`}
                                    className='d-block t-underline-hover'
                                >
                                    {tenant.address.streetWithHouseNumber}
                                </Link>
                                {tenant.unit && 
                                    <Link
                                        to={tabNavLinks.properties.unit.dynamicPath + tenant.unit.id}
                                        title={`Go to unit ${tenant.unit.fullUnitName}`}
                                        className='d-block t-underline-hover'
                                    >
                                        {tenant.unit.fullUnitName}
                                    </Link>
                                }
                                <div>{tenant.address.postcode} {tenant.address.building.city.name}</div>
                            </div>
                        }
                    </div>
                </div>
                {(!createdBy || createdBy.id === id) &&
                    <Label
                        className='time-label my-auto ml-1'
                        iconProps={{ type: 'time' }}
                        label={createdDateLabel}
                        classModifier={createdDateOffset < -2 ? 'red' : createdDateOffset < -1 ? 'yellow' : 'grey'}
                    />
                }
            </div>
            {Boolean(tenant && email) &&
                <div className='t-grey t-xs mt-1' style={{ marginLeft: '51px' }}>
                    <div className='d-flex align-center'>
                        <a
                            className='btn--naked mr-05 br-05'
                            href={`mailto:${email}`}
                            title='send email'
                        >
                            <Icon type='email' variant='2xs' />
                        </a>
                        {/* <Icon type='email' variant='2xs' classAdditions='mr-05' /> */}
                        {email}
                    </div>
                    {profile?.phoneNumber &&
                        <div className='d-flex align-center'>
                            <VoiceCall
                                contact={{ id, fullName, picture, profile }}
                                classAdditions='mr-05'
                                iconVariant='2xs'
                            />
                            {/* <Icon type='call' variant='2xs' classAdditions='mr-05' /> */}
                            {profile.phoneNumber}
                        </div>
                    }
                </div>
            }
        </section>
    </>
)

export default TicketReporter
