import { useState, useCallback, useEffect } from 'react'

const useContextMenu = (element=document) => {
    const [xPos, setXPos] = useState(0)
    const [yPos, setYPos] = useState(0)
    const [showMenu, setShowMenu] = useState(false)
  
    const handleContextMenu = useCallback(
        (e) => {
            e.preventDefault()
            setXPos(e.pageX)
            setYPos(e.pageY)
            setShowMenu(true)
        },
        [setXPos, setYPos]
    )
  
    const handleClick = useCallback(() => {
        showMenu && setShowMenu(false)
    }, [showMenu])
  
    useEffect(() => {
        document.addEventListener('click', handleClick)
        element.addEventListener('contextmenu', handleContextMenu)
        return () => {
            document.removeEventListener('click', handleClick)
            element.removeEventListener('contextmenu', handleContextMenu)
        }
    }, [element, handleClick, handleContextMenu])
  
    return { xPos, yPos, showMenu }
}

export default useContextMenu