import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation, useLazyQuery } from '@apollo/client'
import { openedTabsVar, pinnedTabsVar } from '../../cache'
import { RESET_QUERY } from '../../queries'
import { GET_TICKET } from '../queries'
import { ARCHIVE_TICKETS, ACTIVATE_TICKETS, MERGE_TICKETS, UPDATE_TICKETS, LABEL_TICKETS } from '../mutations'
import { batchActions } from '../settings'
import { getProp } from '../../utils'
import { lbl, tabNavLinks } from '../../constants'
import { Icon, ProfileImage } from '../../components'
import { HiddenSearchSelect, SelectOption } from '../../forms'


const TicketBatchActions = ({
    tickets,
    resetSelection,
    refetchFilteredTickets,
    optionsDict
}) => {
    const history = useHistory()
    const [activePopUp, setActivePopUp] = useState('')
    const { dynamicPath } = tabNavLinks.tickets
    const ticketIds = Array.from(tickets.keys())
    const [mainTicketId, ...ticketsToMerge] = ticketIds
    const [getOptions, { data, loading: loadingOptions }] = useLazyQuery(getProp('optionsQuery.query', optionsDict[activePopUp] || {}) || RESET_QUERY)
    const getOptionsFromData = () => data[optionsDict[activePopUp].optionsQuery.name].map(option => ({
        field: activePopUp,
        ...optionsDict[activePopUp].getOptionProps(option),
    }))
    const actions = Array.from(tickets.values()).some(ticket => ticket.archived) ? ['activate'] : batchActions

    useEffect(() => {
        if (activePopUp && !['archive', 'activate', 'merge', 'pipelineStage'].includes(activePopUp)) {
            getOptions()
        }
    }, [activePopUp, getOptions])

    const [mergeTickets] = useMutation(MERGE_TICKETS, {
        variables: { id: mainTicketId, ids: ticketsToMerge },
        refetchQueries: [{ query: GET_TICKET, variables: { id: mainTicketId } }],
        onCompleted: ({ mergeTickets: { mergedTicketIds, ticket } }) => {
            refetchFilteredTickets()
            
            const openedTabs = openedTabsVar()
            const pinnedTabs = pinnedTabsVar()
            const relativeUrl = dynamicPath + ticket.id
            
            mergedTicketIds.forEach(id => {
                const tabId = dynamicPath + id

                if (openedTabs.get(tabId)) {
                    openedTabs.delete(tabId) 
                } else if (pinnedTabs.get(tabId)) {
                    pinnedTabs.delete(tabId)
                    pinnedTabsVar(new Map([...pinnedTabs]))
                }
            })
            const mainTab = openedTabs.get(relativeUrl)
            mainTab && openedTabs.set(relativeUrl, { ...mainTab, name: ticket.name })
            openedTabsVar(new Map([...openedTabs]))
            
            history.push(relativeUrl)
        },
        update(cache, { data: { mergeTickets: { mergedTicketIds, mergedTicketStatusIds } } }) {
            cache.modify({
                fields: {
                    tickets(existingTickets = [], { readField }) {
                        return existingTickets.filter(t => !mergedTicketIds.includes(readField('id', t)))
                    }
                }
            })
            mergedTicketStatusIds.forEach((pipelineStageId) => {
                pipelineStageId && cache.modify({
                    id: cache.identify({ __typename: 'PipelineStageType', id: pipelineStageId }),
                    fields: {
                        tickets(existingTickets = [], { readField }) {
                            return existingTickets.filter(t => !mergedTicketIds.includes(readField('id', t)))
                        }
                    }
                })
            })
        }
    })

    const [archiveTickets] = useMutation(ARCHIVE_TICKETS, {
        variables: { ids: ticketIds },
        onCompleted: () => {
            refetchFilteredTickets()
            resetSelection()
        },
        update(cache, { data: { archiveTickets: { archivedTicketIds, archivedTicketStatusIds, tickets } } }) {
            // ticket listers will be updated via ON_NEW_CHANGE subscription
            archivedTicketStatusIds.forEach((pipelineStageId) => {
                pipelineStageId && cache.modify({
                    id: cache.identify({ __typename: 'PipelineStageType', id: pipelineStageId }),
                    fields: {
                        tickets(existingTickets = [], { readField }) {
                            return existingTickets.filter(t => !archivedTicketIds.includes(readField('id', t)))
                        }
                    }
                })
            })
        }
    })

    const [activateTickets] = useMutation(ACTIVATE_TICKETS, {
        variables: { ids: ticketIds },
        onCompleted: () => {
            refetchFilteredTickets()
            resetSelection()
        },
        update(cache, { data: { activateTickets: { tickets } } }) {
            // ticket listers will be updated via ON_NEW_CHANGE subscription
            tickets.forEach((ticket) => {
                ticket.pipelineStage && cache.modify({
                    id: cache.identify({ __typename: 'PipelineStageType', id: ticket.pipelineStage.id }),
                    fields: {
                        tickets(existingTickets = [], { toReference }) {
                            return [toReference(ticket), ...existingTickets]
                        }
                    }
                })
            })
        }
    })

    const [updateTickets] = useMutation(UPDATE_TICKETS, {
        onCompleted: () => {
            refetchFilteredTickets()
            resetSelection()
        }
    })

    const [labelTickets] = useMutation(LABEL_TICKETS, {
        onCompleted: () => {
            refetchFilteredTickets()
            resetSelection()
        }
    })
    
    return (
        <>
            {!activePopUp &&
                <div className='pop-up pop-up--block pop-up--top pop-up--center pop-up--primary'>
                    <div className='pop-up__container'>
                        <div className='pop-up__btns'>
                            {actions.map((action, i) => (
                                <button
                                    key={action}
                                    className='btn'
                                    disabled={action === 'merge' && tickets.size < 2}
                                    onClick={() => setActivePopUp(action)}
                                >
                                    <Icon type={action} variant='sm' />
                                    {i < 2 ? lbl[action] : optionsDict[action].actionLabel || optionsDict[action].label}
                                </button>
                            ))}
                        </div>
                        <button
                            className='btn btn--naked close close--xs'
                            onClick={resetSelection}
                        >
                            <Icon type='close' variant='2xs' />
                        </button>
                    </div>
                </div>
            }

            {activePopUp === 'merge' ?
                <div className='pop-up pop-up--block pop-up--top pop-up--center'>
                    <div className='pop-up__container'>
                        <header className='pop-up__header'>
                            Confirm merge
                        </header>
                        <div className='pop-up__message t-s'>
                            <p>Are you sure you want to merge selected tickets?</p>
                            <p>All content will be joined into <strong>'{tickets.get(mainTicketId).name}'</strong> and following tickets will be permanently deleted:</p>
                            <ul className='mb-2'>
                                {Array.from(tickets.values()).map(({ name }, i) => (
                                    i !== 0 && <li key={name+i}>• <strong>{name}</strong></li>
                                ))}
                            </ul>
                        </div>
                        <div className='pop-up__btns'>
                            <button
                                className='btn btn--secondary mr-2'
                                onClick={resetSelection}
                            >
                                {lbl.cancel}
                            </button>
                            <button
                                className='btn btn--primary'
                                onClick={mergeTickets}
                            >
                                {lbl.merge}
                            </button>
                        </div>
                        <button
                            className='btn btn--naked close'
                            onClick={resetSelection}
                        >
                            <Icon type='close'  variant='xs' />
                        </button>
                    </div>
                </div>
            : activePopUp === 'archive' ?
                <div className='pop-up pop-up--block pop-up--top pop-up--center'>
                    <div className='pop-up__container'>
                        <header className='pop-up__header'>
                            Confirm archive
                        </header>
                        <div className='pop-up__message t-s'>
                            <p>Are you sure you want to archive following tickets?</p>
                            <ul className='mb-2'>
                                {Array.from(tickets.values()).map(({ name }, i) => <li key={name+i}>• <strong>{name}</strong></li>)}
                            </ul>
                            <p className='mb-1'>This action will change their status to <strong>archived</strong> and remove them from pipeline board. All related threads and tasks will be archived as well.</p>
                        </div>
                        <div className='pop-up__btns'>
                            <button className='btn btn--secondary mr-2' onClick={resetSelection}>
                                {lbl.cancel}
                            </button>
                            <button
                                className='btn btn--primary'
                                onClick={archiveTickets}
                            >
                                {lbl.archive}
                            </button>
                        </div>
                        <button
                            className='btn btn--naked close'
                            onClick={resetSelection}
                        >
                            <Icon type='close'  variant='xs' />
                        </button>
                    </div>
                </div>    
            : activePopUp === 'activate' ?
                <div className='pop-up pop-up--block pop-up--top pop-up--center'>
                    <div className='pop-up__container'>
                        <header className='pop-up__header'>
                            Restore from archive
                        </header>
                        <div className='pop-up__message t-s'>
                            <p>Are you sure you want to restore following tickets from archive?</p>
                            <ul className='mb-2'>
                                {Array.from(tickets.values()).map(({ name, archived }, i) => archived ? <li key={name+i}>• <strong>{name}</strong></li> : null)}
                            </ul>
                            <p className='mb-1'>This action will activate all related archived threads and tasks as well.</p>
                        </div>
                        <div className='pop-up__btns'>
                            <button className='btn btn--secondary mr-2' onClick={resetSelection}>
                                {lbl.cancel}
                            </button>
                            <button
                                className='btn btn--primary'
                                onClick={activateTickets}
                            >
                                {lbl.activate}
                            </button>
                        </div>
                        <button
                            className='btn btn--naked close'
                            onClick={resetSelection}
                        >
                            <Icon type='close'  variant='xs' />
                        </button>
                    </div>
                </div>
            : activePopUp && (loadingOptions || data) &&
                <HiddenSearchSelect
                    key={activePopUp}
                    options={data ? getOptionsFromData() : []}
                    loading={loadingOptions}
                    disabled={loadingOptions}
                    onChange={([{ field, id }]) => {
                        if (field === 'labels') {
                            labelTickets({ variables: { labelId: id, ticketIds } })
                        } else if (field === 'pipeline') {
                            getOptions({ variables: { id }, onCompleted: setActivePopUp('pipelineStage') })
                        } else {
                            const propertyKey = optionsDict[field].type === 'object' ? field + 'Id' : field
                            updateTickets({ variables: { ids: ticketIds, [propertyKey]: id } })
                        }
                    }}
                    valueField='id'
                    searchBy='label'
                    placeholder={`Select ${optionsDict[activePopUp].label.toLowerCase()}...`}
                    closeOnSelect
                    onDropdownClose={activePopUp === 'pipeline' ?  undefined : resetSelection}
                    contentRenderer={undefined}
                    itemRenderer={(props) => (
                        <SelectOption
                            {...props}
                            getLabel={({ item: { picture, label, name, iconProps, field } }) => (
                                <>
                                    {picture && <ProfileImage url={picture} size='xs' />}
                                    {!picture && iconProps && <Icon variant='xs' {...iconProps} />}
                                    <span>{field === 'pipelineStage' ? name : label}</span>
                                </>
                            )}
                        />
                    )}
                />
            }
        </>
    )
}

export default TicketBatchActions
