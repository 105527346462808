import React, { useState } from "react";
import { useApolloClient, useMutation } from '@apollo/client'
import { postAsFormData } from '../utils'
import { CURRENT_USER_QUERY } from '../queries'
import { ASSIGN_UNCATEGORIZED_TICKET_FOLDER } from '../mutations'

const UserProfile = () => {
    const client = useApolloClient()
    const [selectedFile, setSelectedFile] = useState(null)
    const [doit] = useMutation(ASSIGN_UNCATEGORIZED_TICKET_FOLDER)

    let { me } = client.readQuery({
      query: CURRENT_USER_QUERY,
    })

    const uploadImage = (e) => {
        setSelectedFile(e.target.files[0])
    }

    const submitForm = (e) => {
        postAsFormData({
            url: '/upload_profile_picture/',
            data: {'file': selectedFile},
            onSuccess: (data) => {
                client.writeQuery({
                    query: CURRENT_USER_QUERY,
                    data: {
                        me: {
                            ...me,
                            picture: data.picture
                        }
                    }
                })
                setSelectedFile(null);
            },
        })
    }

    return (
        <div>
            <h2> Upload your new profile picture! </h2>
            <img src={me.picture} width="200" alt={me.fullName} />
            <form>
                <input
                    type="file"
                    onChange={uploadImage}
                />
            </form>
            <button onClick={submitForm}>Submit</button>
            {me.email === 'majapodrug22@gmail.com' && <button onClick={doit}>Assign thread folders to uncategorized ticket</button>}
        </div>
      )
}

export default UserProfile
