import { useContext, useEffect, useState, useRef } from 'react'
import { Redirect } from 'react-router-dom'
import { useQuery, useMutation, useReactiveVar } from '@apollo/client'
import { lastActiveThreadVar, preventInboxSwitch, showAllThreadsVar } from '../../cache'
import { GET_THREADS } from '../queries'
import { SEND_MESSAGE_AS_EMAIL } from '../mutations'
import { ON_NEW_THREAD_SUBSCRIPTION } from '../subscriptions'
import { TabNavContext } from '../../components/TabNav'
import { Icon, Popover, Popup } from '../../components'
import { lbl, tabNavLinks } from '../../constants'
import Inbox from './Inbox'
import Thread from './Thread'
import UnreadOverview from './UnreadOverview'
import MentionsOverview from './MentionsOverview'
import PinnedOverview from './PinnedOverview'
import ArchivedOverview from './ArchivedOverview'
import NewMessage from './NewMessage'


const ThreadPanel = ({ setCrumbs, sendMessageAsEmail }) => {
    const { id, activeTab } = useContext(TabNavContext)

    switch (activeTab) {
        case 'thread':
            if (id) return (
                <Thread
                    id={id}
                    key={id}
                    setCrumbs={setCrumbs}
                    sendMessageAsEmail={sendMessageAsEmail}
                />
            )
            return <Redirect to={tabNavLinks.messenger.new} />
        case 'new':
            return <UnreadOverview />
        case 'mentions':
            return <MentionsOverview />
        case 'pinned':
            return <PinnedOverview />
        case 'archive':
            return <ArchivedOverview />
        default:
            const lastVisited = lastActiveThreadVar()
            const fallbackUrl = lastVisited ? tabNavLinks.messenger[lastVisited] || tabNavLinks.messenger.dynamicPath + lastVisited : tabNavLinks.messenger.new
            if (fallbackUrl === tabNavLinks.messenger.dynamicPath + lastVisited) preventInboxSwitch(true)
            return <Redirect to={fallbackUrl} />
    }
}


const Messenger = () => {
    let showAllThreads = useReactiveVar(showAllThreadsVar)
    let [showNewMsgForm, toggleShowNewMsgForm] = useState(false)
    let [sendingEmail, setSendingEmail] = useState(false)
    let [emailOnTimeout, setEmailOnTimeout] = useState(false)
    let [emailTimeout, setEmailTimeout] = useState(30)
    let [crumbs, setCrumbs] = useState([])
    const emailMsgId = useRef()
    const timeout = useRef()
    const timeoutCounter = useRef()
    const { id, activeTab } = useContext(TabNavContext)

    const { data, loading, client, subscribeToMore } = useQuery(GET_THREADS, {
        variables: { all: showAllThreads, topLevelOnly: true }
    })

    const completed = !!data

    useEffect(() => {
        if (completed) {
            subscribeToMore({
                document: ON_NEW_THREAD_SUBSCRIPTION,
                variables: { all: true },
                updateQuery: (prev, { subscriptionData }) => {
                    if (!subscriptionData?.data?.onNewThread?.newThread) return prev
                    const { newThread } = subscriptionData.data.onNewThread
                    const newThreadRef = client.cache.data.toReference(newThread)
    
                    if (newThread.isFolder && !prev.threads.some(t => t.id === newThread.id)) {
                        return { threads: [...prev.threads, newThreadRef] }
                    }
    
                    return prev
                }
            })
        }
    }, [completed])

    const [sendMessageAsEmail] = useMutation(SEND_MESSAGE_AS_EMAIL, {
        onCompleted: ({ sendMessageAsEmail: { ok } }) => {
            resetEmailState()
            if (!ok) console.error('Ups! Email not sent')
        }
    })

    const sendMsgAsEmail = (messageId) => {
        emailMsgId.current = messageId

        timeoutCounter.current = setInterval(() => {
            setEmailTimeout(prev => prev - 1)
            emailTimeout === 0 && clearInterval(timeoutCounter.current)
        }, 1000);

        setEmailOnTimeout(true)

        timeout.current = setTimeout(() => {
            clearInterval(timeoutCounter.current)
            if (emailMsgId.current) {
                setSendingEmail(true)
                sendMessageAsEmail({
                    variables: { messageId }
                })
            } else {
                resetEmailState()
            }
        }, 30000) // delay 30 sec to enable cancellation
    }

    const resetEmailState = () => {
        clearInterval(timeoutCounter.current)
        clearTimeout(timeout.current)
        emailMsgId.current = undefined
        setEmailOnTimeout(false)
        setSendingEmail(false)
        setEmailTimeout(30)
    }

    const breadcrumbs = activeTab === 'thread' ? crumbs || [] : []
    
    return (
        <section className='messenger tab__panel'>
            <div className='split-panel'>
                <Inbox
                    threads={data?.threads || []}
                    isLoading={loading}
                    activeThread={id}
                    activeTab={activeTab}
                    threadCrumbs={breadcrumbs}
                />
                <ThreadPanel
                    setCrumbs={setCrumbs}
                    sendMessageAsEmail={sendMsgAsEmail}
                />
            </div>
            <button
                onClick={() => toggleShowNewMsgForm(true)}
                className='btn btn--primary btn--rounded btn--inline-icon pos-fixed t-none'
            >
                <Icon type='plus' variant='sm' />
                {lbl.newMessage}
            </button>
            {showNewMsgForm &&
                <Popover
                    onClose={() => toggleShowNewMsgForm(false)}
                    isOpen={!!showNewMsgForm}
                    className='popover--new-msg'
                >
                    <>
                        <div className='pop-up__header'>
                            {lbl.newMessage}
                        </div>
                        <NewMessage
                            inputLabel='Message To'
                            onMsgSend={() => toggleShowNewMsgForm(false)}
                            sendMessageAsEmail={sendMsgAsEmail}
                        />
                    </>
                </Popover>
            }
            {emailOnTimeout && emailMsgId.current &&
                <Popup
                    isOpen
                    position={{ x: '20px', y: 'calc(100% - 55px)' }}
                    className='pop-up--line email-countdown child-on-hover'
                >
                    {`Sending email ${sendingEmail ? '⚡' : `in ${emailTimeout} seconds`}`}
                    <button
                        className='btn btn--secondary ml-3'
                        onClick={resetEmailState}
                        disabled={sendingEmail}
                    >
                        {lbl.undo}
                    </button>
                    <button
                        className='btn btn--secondary ml-05 mr-3 on-hover--expand'
                        disabled={sendingEmail}
                        onClick={() => {
                            setSendingEmail(true)
                            sendMessageAsEmail({
                                variables: { messageId: emailMsgId.current }
                            })
                        }}
                    >
                        {lbl.instantSend}
                    </button>
                </Popup>
            }
        </section>
    )
}

export default Messenger
