import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Link, useLocation } from 'react-router-dom'
import { useQuery, useMutation, useApolloClient, gql, useReactiveVar } from '@apollo/client'
import { preventInboxSwitch, showAllThreadsVar, openedThreadsVar, lastActiveThreadVar, notificationCountersVar, ocpVar } from '../../cache'
import { GET_THREAD, GET_THREAD_CRUMBS, GET_UNREAD_THREADS } from '../queries'
import { REMOVE_MEMBERS } from '../mutations'
import { useMarkAsReadMutation } from '../hooks'
import { lbl, tabNavLinks } from '../../constants'
import Crumbs from '../../components/Crumbs'
import TicketSidePanel from '../../tickets/components/TicketSidePanel'
import TicketLoader from '../../tickets/components/TicketLoader'
import Chat from './Chat'
import ThreadList from './ThreadList'
import AddContact from './AddContact'
import { ProfileImage, Icon, Label, Popover, UserChip, SwitchButton } from '../../components'
import ThreadAttachments from './ThreadAttachments'
import HighlightedMessages from './HighlightedMessages'

const Thread = ({ id, setCrumbs, sendMessageAsEmail }) => {
    const { hash } = useLocation()
    const client = useApolloClient()
    const unreadCount = useRef(0)
    const [removeMembers] = useMutation(REMOVE_MEMBERS)
    const [markAsRead] = useMarkAsReadMutation()
    const [showSubthreadList, toggleSubthreadList] = useState(false)
    let [activePopup, setActivePopup] = useState()
    let counters = useReactiveVar(notificationCountersVar)
    let ocp = useReactiveVar(ocpVar)

    const openOcp = (props={}) => {
        ocpVar({ ...ocpVar(), isOpen: true, ...props })
    }

    useEffect(() => {
        const thread = client.readFragment({
            id: `ThreadType:${id}`,
            fragment: gql`
                fragment CurrentThread on ThreadType {
                    id
                    unreadCount
                }
            `
        })
        unreadCount.current = thread?.unreadCount || 0
        toggleSubthreadList(false)
        
        return () => {
            thread && markAsRead({ variables: { threadIds: [thread.id] } })
            ocpVar({ ...ocpVar(), type: undefined, defaultWidth: 480, isOpen: false })
        }
    }, [id])

    const { data, error, loading, fetchMore: fetchMoreMessages } = useQuery(GET_THREAD, {
        variables: { id, offset: hash ? undefined : 0, limit: hash ? undefined : 20, reversed: true, all: true },
        onCompleted: ({ thread }) => {
            if (preventInboxSwitch()) {
                setTimeout(() => preventInboxSwitch(false), 1000)
            } else if (!thread.membership && !showAllThreadsVar()) {
                showAllThreadsVar(true)
            }            
            lastActiveThreadVar(id)
            localStorage.setItem('lastActiveThread', id)
        },
        notifyOnNetworkStatusChange: true
    })

    useEffect(() => {
        if (data?.thread && (data.thread.unreadCount || data.thread.membership?.isUnread)) {
            markAsRead({
                variables: { threadIds: [data.thread.id] },
                refetchQueries: counters.unreadMentionsCount > 0 ? [{ query: GET_UNREAD_THREADS }] : undefined
            })
            unreadCount.current = 0
        }
    }, [id, !!data])

    const { data: crumbsData, refetch } = useQuery(GET_THREAD_CRUMBS, {
        variables: { id },
        onCompleted: ({ threadCrumbs }) => {
            if (threadCrumbs) {
                setCrumbs(threadCrumbs)
                const openedThreads = [...openedThreadsVar()]
                threadCrumbs.forEach(crumb => {
                    if (!openedThreads.includes(crumb.id)) openedThreads.push(crumb.id)
                })
                openedThreadsVar(openedThreads)
                localStorage.setItem('openedThreads', JSON.stringify(openedThreads))
            }
        }
    })

    const lastCrumbId = crumbsData?.threadCrumbs?.length && crumbsData.threadCrumbs[crumbsData.threadCrumbs.length-1].id
    const threadParentId = data?.thread?.parent?.id

    useEffect(() => {
        if (lastCrumbId && lastCrumbId !== threadParentId) {
            refetch()
        }
    }, [threadParentId, lastCrumbId])

    const members = data?.thread?.members || []
    const hasRelatedTicket = data?.thread?.contentType?.model === 'ticket'

    const removeMember = (user, e) => {
        e.preventDefault()
        e.stopPropagation()
        let confirmationText = `Press OK to confirm that you want to remove ${user.fullName} from thread #${data.thread.name}`
        if (data.thread.subthreads.length) confirmationText += ` and from all its subthreads`
        const confirmed = window.confirm(confirmationText)
        if (confirmed) removeMembers({ variables: { threadId: id, userIds: [user.id] } })
    }

    if (!data?.thread && error) {
        return (
            <div className='split-panel--main p-0 chat--single'>
                <div className='t-grey w-100 mx-auto my-auto'>
                    <center>{error.message}</center>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className='split-panel--main p-0 chat--single'>
                <div className='chat__header'>
                    <div className='chat-header__left'>
                        <ul className='crumbs-list t-xs t-grey'>
                            <Crumbs
                                crumbs={crumbsData?.threadCrumbs || []}
                                getRelativeUrl={(crumbId) => tabNavLinks.messenger.dynamicPath + crumbId}
                                activeId={data?.thread?.id}
                            />
                            {data?.thread &&
                                <>
                                    <li className={showSubthreadList ? 't-grey' : 'active'}>
                                        <button className='btn btn--naked' onClick={() => toggleSubthreadList(false)}>
                                            {data?.thread.name}
                                        </button>
                                    </li>
                                    {data?.thread.archived &&
                                        <Label
                                            classModifier='grey'
                                            className='label--uppercase ml-1'
                                            iconProps={{ type: 'archive', variant: '3xs' }}
                                            label={lbl.archived}
                                        />
                                    }
                                    {data?.thread.deleted &&
                                        <Label
                                            classModifier='grey'
                                            className='label--uppercase ml-1'
                                            iconProps={{ type: 'bin', variant: '3xs' }}
                                            label={lbl.deleted}
                                        />
                                    }
                                    {!!data.thread?.subthreads?.length &&
                                        <>
                                            <SwitchButton
                                                name='toggle-subthreads-view'
                                                variant='small'
                                                checked={showSubthreadList}
                                                onSwitch={() => toggleSubthreadList(!showSubthreadList)}
                                            />
                                            <li className={showSubthreadList ? 'active' : 't-grey'}>
                                                <button className='btn btn--naked' onClick={() => toggleSubthreadList(true)}>
                                                    subthreads
                                                </button>
                                            </li>
                                        </>
                                    }
                                </>
                            }
                        </ul>
                    </div>
                    {data && !showSubthreadList && !(data.thread?.isDirect && !data.thread?.isGroup) &&
                        <div className='chat-header__right'>
                            <button
                                className='btn btn--inline-icon btn--naked'
                                title={lbl.addMembers}
                                onClick={() => setActivePopup({ action: 'add-members' })}
                            >
                                <>
                                    <Icon type='contact-multi-add' variant='xs' />
                                    {!!members.length &&
                                        <div className='chat__members'>
                                            {members.slice(0, 6).map(({ id, picture, type }) => (
                                                <ProfileImage
                                                    key={picture + id}
                                                    url={picture}
                                                    size='xs'
                                                    type={type || 'bordered'}
                                                />
                                            ))}
                                            {members.length > 5 &&
                                                <div className='chat__members__extra'>
                                                    {`...+${members.length-5}`}
                                                </div> 
                                            }
                                        </div>                                
                                    }
                                </>
                            </button> 
                        </div>
                    }
                    {data && !showSubthreadList && data.thread?.isDirect && !data.thread?.isGroup &&
                        <div className='chat-header__right'>
                            {members.map(({ id, picture, type, fullName }) => (
                                <Link to={tabNavLinks.contacts.dynamicPath + id} title={fullName} key={fullName}>
                                    <ProfileImage
                                        key={picture + id}
                                        url={picture}
                                        size='xs'
                                        type={type || 'bordered'}
                                    />
                                </Link>
                            ))}
                        </div>
                    }
                    {data?.thread && !data.thread.isEmpty &&
                        <div className='chat-header__right'>
                            <button
                                className='btn btn--inline-icon btn--naked mr-05'
                                title='Show thread attachments'
                                onClick={() =>  openOcp({ defaultWidth: 800, type: 'thread-attachments' })}
                            >
                                <Icon type='attachment' variant='xs' />
                            </button>
                            <button
                                className='btn btn--inline-icon btn--naked'
                                title='Show crutial information and highlighted messages'
                                onClick={() =>  openOcp({ defaultWidth: 400, type: 'highlighted-messages' })}
                            >
                                <Icon type='important' variant='xs' />
                            </button>
                        </div>
                    }
                </div>
                {showSubthreadList ?
                    <ThreadList threads={data?.thread?.subthreads} />
                :
                    <Chat
                        key={`chat${data?.thread?.noReply ? '-notifications' : ''}${data?.thread?.id}`}
                        messages={data?.thread?.messages}
                        threadId={id}
                        archived={data?.thread?.archived}
                        deleted={data?.thread?.deleted}
                        isLoading={loading}
                        unreadCount={unreadCount.current}
                        isUnread={data?.thread?.membership?.isUnread}
                        totalCount={data?.thread?.totalMessageCount}
                        members={data?.thread?.members}
                        memberships={data?.thread?.threadmembershipSet}
                        loadMore={fetchMoreMessages}
                        isNotification={data?.thread?.noReply}
                        isMember={data?.thread ? !!data.thread.membership : true}
                        isEmpty={data?.thread?.isEmpty}
                        hasRelatedTicket={hasRelatedTicket}
                        sendMessageAsEmail={sendMessageAsEmail}
                    />
                }
            </div>
            {hasRelatedTicket && (
                <TicketLoader id={data.thread.objectId}>
                    {({ ticket }) => !!ticket && (
                        <TicketSidePanel key={ticket.id} ticket={ticket} />
                    )}
                </TicketLoader>
            )}
            {activePopup &&
                <Popover
                    onClose={() => setActivePopup(null)}
                    isOpen={!!activePopup}
                >
                    {activePopup.action === 'add-members' &&
                        <div className='pop-up--add-contact'>
                            <div className='pop-up__header'>
                                Add members to <span className='t-blue ml-1'># {data?.thread.name}</span>
                            </div>
                            <div className='contact-list t-sm mb-1 t-none'>
                                {members.map(member => (
                                    <Link
                                        key={member.fullName}
                                        title={member.fullName}
                                        to={tabNavLinks.contacts.dynamicPath + member.id}
                                    >
                                        <UserChip
                                            user={member}
                                            className='mr-05 mb-05'
                                            onRemove={!!data.thread && !data.thread.isDirect && removeMember}
                                        />
                                    </Link>
                                ))}
                            </div>
                            <AddContact
                                threadId={id}
                                members={members}
                                onCompleted={() => setActivePopup(null)}
                                presetOptions={members.length < 2 && data?.thread?.parent ? [{
                                    key: 'all-parent-thread-members',
                                    label: `All members from # ${data.thread.parent.name}`
                                }] : undefined}
                            />
                        </div>
                    }
                </Popover>
            }
            {ocp.type === 'thread-attachments' && createPortal(
                <ThreadAttachments threadId={id}/>,
                ocp.containerEl
            )}
            {ocp.type === 'highlighted-messages' && createPortal(
                <HighlightedMessages threadId={id}/>,
                ocp.containerEl
            )}
        </>
    )
}

export default Thread
