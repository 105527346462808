import { useEffect, useState, memo } from 'react'
import { useQuery } from '@apollo/client'
import {default as ReactDropdownSelect} from 'react-dropdown-select'
import { Icon, UserChip } from '.'
import ThreadLabel from '../messenger/components/ThreadLabel'
import { sortObjectsByFieldPath } from '../utils'
import React from 'react'
import { userTypeOptions } from '../constants'

const Select = memo(ReactDropdownSelect)

const SelectContactOption = ({
    item,
    itemIndex,
    methods,
    state: { searchResults, search },
    props: { keepSelectedInList },
}) => {
    const { dropDown, isSelected, addItem } = methods
    const isItemSelected = isSelected(item)
    if (search && item.isRecent) return null

    let level = item.isRecent ? 0 : (item.fullName?.match(/ - /g) || []).length
    if (level) level -= 1

    const isThread = item.__typename === 'ThreadType'
    const onClick = () => {
        addItem(item)
        isThread && dropDown('close')
    }
    const prevOption = searchResults[itemIndex-1]
    const heading = item.isRecent && itemIndex === 0 ? 'recent' : 
        !item.isRecent && isThread && (itemIndex === 0 || prevOption?.isRecent) ? 'threads' :
        item.__typename === 'UserType' && prevOption?.__typename === 'ThreadType' ? 'contacts' : ''
    const userTypeHeading = item.__typename === 'UserType' && (itemIndex === 0 || prevOption?.__typename !== 'UserType' || prevOption.type !== item.type) ? userTypeOptions[item.type]?.label : ''
    const getFolder = (fullName) => fullName?.split(' - ')[0]
    const showFolder = search && isThread && !item.isRecent && (itemIndex === 0 || prevOption?.isRecent || getFolder(item.fullName) !== getFolder(prevOption.fullName))

    return (
        <React.Fragment key={item.name + item.id}>
            {heading &&
                <div className='ml-2 mb-05 mt-2 t-uppercase t-grey t-500' style={{ fontSize: '13px' }}>
                    {heading}
                </div>
            }
            {userTypeHeading &&
                <div className='ml-2 mb-05 mt-1 t-uppercase t-xs t-grey t-500'>
                    {userTypeHeading}
                </div>
            }
            {showFolder &&
                <div className='ml-2 mb-05 mt-1 lh-20 t-grey d-inline-flex'>
                    {getFolder(item.fullName)}
                    <Icon type='caret' variant='2xs' classAdditions='ml-05' />
                </div>
            }
            {(!isItemSelected || keepSelectedInList) &&
                <div
                    className='react-dropdown-select-item t-grey d-inline-flex'
                    style={{ paddingLeft: `${level * 10 + 12}px` }}
                    onClick={onClick}
                    title={item.fullName}
                >
                    <>
                        {item.key === 'all-parent-thread-members' ?
                            <>
                                <Icon type='contact-multi' variant='2xs' />
                                <span className='select-item__title'>{item.label}</span>
                            </>
                        :
                            <>
                                {item.icon ?
                                    <Icon type={item.icon} variant='2xs' wrapperClassAdditions={
                                        item.icon === 'dot' ? `icon-wrapper--${item.contact?.status?.toLowerCase()}` : ''
                                    }/>
                                : item.isGroup ?
                                    <Icon type='contact-multi' variant='2xs' />
                                : !isThread ?
                                    <Icon type='dot' variant='2xs' wrapperClassAdditions={`icon-wrapper--${item.status?.toLowerCase()}`} />
                                :
                                    <span className='t-heading t-blue-grey t-bold mr-1 ml-05 t-sm'>#</span>
                                }
                                <span className='select-item__title'>{isThread ? item.name : item.fullName}</span>
                            </>
                        }
                    </>
                </div>
            }
        </React.Fragment>
    )
}


const SelectedContactOption = ({ item, methods }) => {
    if (item.isRecent || item.__typename === 'ThreadType') return  <ThreadLabel {...item} />
    return (
        <UserChip
            user={item}
            className='mr-05 mb-05'
            onRemove={() => methods.removeItem(null, item)}
        />
    )
}


const ContactSelect = ({
    optionsQuery,
    excludeUsers=[],
    inputLabel='Message To',
    selected=[],
    onSelect,
    presetOptions=[],
    onCloseOptions,
    directContactsOnly=false,
    closeOnSelect=false,
    keepSelectedInList=false,
    ...props
}) => {
    let [open, setOpen] = useState(true)
    let [options, setOptions] = useState([])
    let [initialOptions, setInitialOptions] = useState(presetOptions)
    // let [contactsOnly, setContactsOnly] = useState(directContactsOnly)
    
    const optionsCount = options.length - selected.length
    let spacing = open && optionsCount > 0 ? optionsCount * 26 + 110 : 0
    if (spacing > 260) spacing = 260

    const { loading } = useQuery(optionsQuery, {
        onCompleted: (data) => {
            if (!data) return

            const recent = data.recentlyViewedThreads?.map(thread => ({
                ...thread,
                isRecent: true,
                key: `${thread.fullName} ${thread.id} (recenty viewed)`,
                label: `# ${thread.name}`
            })) || []
            const users = data.users?.reduce((acc, user) => {
                if (excludeUsers.some(({ id }) => id === user.id)) return acc
                acc.push({
                    ...user,
                    key: `${user.fullName} ${user.id}`,
                    label: user.fullName
                })
                return acc
            }, []) || []
            const threads = data.threads?.map(thread => ({
                ...thread,
                label: `# ${thread.name}`,
                key: `${thread.fullName} ${thread.id}`,
            }))
            const sortedThreads = threads ? sortObjectsByFieldPath(threads, 'fullName') : []
            const options = [...presetOptions, ...recent, ...sortedThreads, ...users]
            
            setOptions(options)
            setInitialOptions(options)
        }
    })

    const onChange = (selection) => {
        if (selection.length === 1 && selection[0].__typename === 'UserType') {
            setOptions(options.filter(o => o.__typename === 'UserType'))
            // setContactsOnly(true)
        }
        if (selection.length === 0) {
            setOptions(initialOptions)
            // setContactsOnly(directContactsOnly)
        }

        if (selection.length === 1 && selection[0].key === 'all-parent-thread-members') {
            setOptions([])
        }

        onSelect(selection)
    }

    useEffect(() => {
        document.querySelector('.select-contact .react-dropdown-select-input').click()
    }, [])

    return (
        <div
            className='select-contact'
            style={{ paddingBottom: `${spacing}px` }}
        >
            <div className={`select-contact__input-label ${open ? 't-blue' : 't-grey'}`}>
                {inputLabel}
            </div>
            <Select
                className='select-contact__form'
                options={options}
                keepOpen={false}
                autoFocus={false}
                keepSelectedInList={keepSelectedInList}
                closeOnSelect={closeOnSelect}
                dropdownGap={5}
                searchable
                searchBy='fullName'
                placeholder='Type the contact name'
                loading={loading}
                noDataRenderer={({ state }) => !!options.length && (
                    <div className='p-1' style={{ height: `${spacing}px`, minHeight: '30px' }}>
                        {loading ? '...' : <>No matches for<strong>{`: ${state.search}`}</strong></>}
                    </div>
                )}
                valueField='key'
                onChange={onChange}
                onDropdownClose={() => {
                    setOpen(false)
                    onCloseOptions && onCloseOptions()
                }}
                onDropdownOpen={() => setOpen(true)}
                itemRenderer={SelectContactOption}
                optionRenderer={SelectedContactOption}
                {...props}
            />
        </div>
    )
}

export default ContactSelect
