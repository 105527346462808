import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useReactiveVar, useMutation } from '@apollo/client'
import { newDraftsVar, openedTabsVar } from '../../cache'
import { CREATE_TEMPLATE } from '../mutations'
import { tabNavLinks, lbl } from '../../constants'
import { TextField } from '../../forms'
import TextEditor from '../../components/TextEditor'

const NewTemplate = () => {
    const history = useHistory()
    const templateDraft = useReactiveVar(newDraftsVar).templates
    const openedTabs = useReactiveVar(openedTabsVar)
    const { relativeUrl } = tabNavLinks.templates.new
    const [createTemplate, { error }] = useMutation(CREATE_TEMPLATE, {
        onCompleted: ({ createTemplate: { template }}) => {
            openedTabs.delete(relativeUrl)
            openedTabsVar(new Map([...openedTabs]))
            newDraftsVar({ ...newDraftsVar(), templates: {} })
            history.push(`${tabNavLinks.templates.dynamicPath}${template.id}`)
        },
        update(cache, { data: { createTemplate: { template } } }) {
            cache.modify({
                fields: {
                    templates(existingTemplates = [], { toReference }) {
                        return [toReference(template), ...existingTemplates];
                    }
                }
            })
        }
    })
    const errMsg = error && error.message.replace(/',|'|]|\[/g, '')

    useEffect(() => {
        openedTabsVar(new Map([...openedTabsVar(), [relativeUrl, {
            name: lbl.newTemplate,
            id: 'new',
            relativeUrl,
            icon: 'clipboard'
        }]]))
    }, [relativeUrl])

    return (
        <div className='split-panel--main template template--new'>
            {!!(templateDraft.name && templateDraft.body) &&
                <button
                    onClick={() => createTemplate({ variables: templateDraft })}
                    className='btn btn--primary btn--rounded t-none pos-fixed'
                    style={{ position: 'absolute', right: '3vw', top: '10px' }}
                >
                    Save template
                </button>
            }
            <TextField
                onSubmit={value => {
                    const name = value.replace(/&nbsp;/g, ' ').trim()
                    if (name !== templateDraft.name) {
                        newDraftsVar({
                            ...newDraftsVar(),
                            templates: { ...templateDraft, name }
                        })
                    }
                }}
                inputProps={{
                    type: 'input',
                    name: 'name',
                    value: templateDraft.name || '',
                    role: 'textbox',
                    contentEditable: true,
                    placeholder: lbl.templateTitle,
                    autoFocus: true
                }}
                size='2xl'
                as='div'
                innerContent='innerHTML'
                innerHtml={templateDraft.name || ''}
            />
            {errMsg && <div className='error t-red'>{errMsg}</div>}
            <br/>

            <TextEditor
                model={templateDraft.body || ''}
                onModelChange={(body) => {
                    newDraftsVar({
                        ...newDraftsVar(),
                        templates: { ...templateDraft, body }
                    })
                }}
                config={{
                    placeholderText: 'Add template content...',
                    toolbarButtons: ['bold', 'italic', 'underline', 'backgroundColor', 'fontSize', '|', 'formatOL', 'formatUL', 'outdent', 'indent', 'align', '|', 'insertHR', 'insertTemplate', 'insertLink', 'insertImage', 'insertFile', 'emoticons', '|', 'selectAll', 'clearFormatting', 'undo', 'redo'],
                }}
                immediateReactModelUpdate
            />
        </div>
    )
}

export default NewTemplate
