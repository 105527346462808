import { 
    callDirectionOptions,
    callStatusOptions,
    callTimingOptions
} from '../constants'
import {
    GET_ALL_USERS,
    GET_CALL_DIRECTION_OPTIONS,
    GET_CALL_STATUS_OPTIONS,
    GET_CALL_TIMING_OPTIONS,
    GET_DEPARTMENTS
} from '../queries'
import { toTitleCase } from '../utils'

export const callProperties = {
    direction: {
        field: 'direction',
        label: 'Direction',
        iconProps: { type: 'repeat' },
        type: 'string',
        sortPath: 'direction',
        optionsQuery: { query: GET_CALL_DIRECTION_OPTIONS, name: 'callDirectionOptions' },
        getOptionProps: r => {
            const root = typeof r === 'string' ? callDirectionOptions[r] : r
            return ({ ...root, iconProps: { type: root.icon } })
        }
    },
    caller: {
        field: 'caller',
        type: 'object',
        iconProps: { type: 'call-outbound' },
        label: 'Caller',
        sortPath: 'caller.fullName',
        groupPath: 'caller.fullName',
        objectPath: 'caller',
        optionsQuery: { query: GET_ALL_USERS, name: 'users' },
        getOptionProps: root => ({
            ...root,
            label: root.fullName
        })
    },
    receiver: {
        field: 'receiver',
        type: 'object',
        label: 'Receiver',
        iconProps: { type: 'call-inbound' },
        sortPath: 'receiver.fullName',
        groupPath: 'receiver.fullName',
        objectPath: 'receiver',
        optionsQuery: { query: GET_ALL_USERS, name: 'users' },
        getOptionProps: root => ({
            ...root,
            label: root.fullName
        })
    },
    status: {
        field: 'status',
        label: 'Status',
        iconProps: { type: 'call-log' },
        type: 'string',
        sortPath: 'status',
        optionsQuery: { query: GET_CALL_STATUS_OPTIONS, name: 'callStatusOptions' },
        getOptionProps: r => {
            const root = typeof r === 'string' ? callStatusOptions[r] : r
            return ({ ...root, iconProps: { type: root.icon } })
        }
    },
    subject: {
        field: 'subject',
        label: 'Subject',
        type: 'string',
        sortPath: 'subject',
        iconProps: { type: 'sort-text' },
    },
    summary: {
        field: 'summary',
        label: 'Summary',
        type: 'string',
        sortPath: 'summary',
    },
    recordingUrl: {
        field: 'recordingUrl',
        label: 'Recording',
        type: 'string'
    },
    dateTime: {
        field: 'dateTime',
        type: 'string',
        label: 'Call started',
        sortPath: 'dateTime',
        sortDir: -1,
        iconProps: { type: 'time' },
    },
    duration: {
        field: 'duration',
        label: 'Duration',
        type: 'string',
        sortPath: 'duration',
        sortDir: -1,
        iconProps: { type: 'clock-sand' },
    },
    outsideOfficeHours: {
        field: 'outsideOfficeHours',
        label: 'Timing',
        iconProps: { type: 'time' },
        type: 'boolean',
        optionsQuery: { query: GET_CALL_TIMING_OPTIONS, name: 'callTimingOptions' },
        getOptionProps: r => {
            const root = typeof r === 'string' ?
                callTimingOptions[r] :
            typeof r === 'boolean' ? 
                callTimingOptions[r ? 'OUTSIDE_OFFICE_HOURS' : 'OFFICE_HOURS'] : r
            return ({ ...root, iconProps: { type: root.icon } })
        }
    },
    department: {
        field: 'department',
        type: 'object',
        label: 'Department',
        iconProps: { type: 'account-box' },
        sortPath: 'department.name',
        optionsQuery: { query: GET_DEPARTMENTS, name: 'departments' },
        getOptionProps: root => ({
            ...root,
            label: `${root.icon ? root.icon : ''} ${toTitleCase(root.name)}`,
            icon: ''
        })
    },
    missed: {
        field: 'missed',
        type: 'boolean',
        label: 'Missed calls',
        iconProps: { type: 'call-missed' },
        icon: 'call-missed',
        getOptionProps: () => {
            return {
                field: 'missed', id: true, label: 'Missed calls', icon: 'call-missed',
            }
        }
    },
}

export const listerCtrlOptions = {
    filter: ['missed', 'direction', 'caller', 'receiver', 'department', 'status', 'outsideOfficeHours'].map(key => callProperties[key]),
    group: ['direction', 'caller', 'receiver', 'department', 'status', 'outsideOfficeHours'].map(key => callProperties[key]),
    sort: ['direction', 'status', 'caller', 'department', 'receiver', 'subject', 'dateTime', 'duration'].map(key => callProperties[key]),
    search: ['direction', 'status', 'caller', 'department', 'receiver', 'subject', 'dateTime', 'duration'].map(key => callProperties[key])
}

export const listerColumns = ['direction', 'subject', 'caller', 'receiver', 'department', 'duration', 'dateTime', 'status', 'recordingUrl']
