import { useState,  } from 'react'
import { taskListCtrlsVar } from '../../cache'
import { listerCtrlOptions, listerColumns, taskProperties } from '../settings'
import Lister from '../../components/Lister'
import TaskListItem from './TaskListItem'
import TaskBatchActions from './TaskBatchActions'
import { GET_TASKS } from '../queries'


const TaskList = ({
    tasks,
    fetchMore,
    hideControls,
    showLoader,
    queryFieldName='tasks',
    queryVariables,
    query=GET_TASKS,
    ctrlOptions=listerCtrlOptions,
    ...props
}) => {
    const [selectedTasks, setSelectTasks] = useState(new Map())
    const resetSelection = () => setSelectTasks(new Map())
    const onSelectTask = (id, name, archived) => {
        selectedTasks.get(id) ? selectedTasks.delete(id) : selectedTasks.set(id, { name, archived })
        setSelectTasks(new Map([...selectedTasks]))
    }
    const selectBatch = (items, deselect=false) => {
        items.forEach(({ id, name, archived }) => deselect ? selectedTasks.delete(id) : selectedTasks.set(id, { name, archived }))
        setSelectTasks(new Map([...selectedTasks]))
    }
    const listCtrlVar = props.listCtrlVar || taskListCtrlsVar
    const columnKeys = props.columnKeys || listerColumns

    if (!tasks && !showLoader) return <div className='loader pos-center-y' />

    return (
        <div className='tab__panel--main intersection-root'>
            <div className='batch-actions pos-sticky pos-top'>
                {selectedTasks && !!selectedTasks.size &&
                    <TaskBatchActions
                        tasks={selectedTasks}
                        resetSelection={resetSelection}
                        optionsDict={taskProperties}
                        refetchGroupedTasks={() => {}}
                    />
                }
            </div>
            <Lister
                options={ctrlOptions}
                optionsDict={taskProperties}
                listerCtrlVar={listCtrlVar}
                columnKeys={columnKeys}
                items={tasks}
                hideControls={hideControls}
                controlsDisabled={props.controlsDisabled || (selectedTasks && !!selectedTasks.size)}
                onDisabledClick={resetSelection}
                onSelectBatch={selectBatch}
                resetBatchSelection={!selectedTasks.size}
                renderCtrlChildren={props.renderCtrlChildren}
                showLoader={showLoader}
                fetchMore={fetchMore}
                queryFieldName={queryFieldName}
                queryVariables={queryVariables}
                query={query}
                type='tasks'
            >
            {(itemProps) => (
                <TaskListItem
                    key={itemProps.index + itemProps.name}
                    onSelect={onSelectTask}
                    isSelected={!!selectedTasks.get(itemProps.id)}
                    isAnySelected={!!selectedTasks.size}
                    propKeys={columnKeys}
                    listCtrlVar={listCtrlVar}
                    isArchive={queryFieldName === 'archivedTasks'}
                    {...itemProps}
                />
            )}
            </Lister>
        </div>
    )
}

export default TaskList
