import { useQuery, useReactiveVar } from '@apollo/client'
import { propertyTaskListCtrlsVar } from '../../cache'
import { useState } from 'react'
import { Icon } from '../../components'
import TaskList from '../../tasks/components/TaskList'
import { GET_PROPERTY_TASKS } from '../../tasks/queries'
import { listerCtrlOptions } from '../../tasks/settings'

export const columns = ['status', 'name', 'assignee', 'dueDate', 'priority', 'ticket', 'createdBy']

const PropertyTasks = ({ contentType, addresses=[], units=[] }) => {
    const [includeUnitTasks, setUnitTasks] = useState(contentType === 'room')
    const propertyIds = contentType === 'room' ? [] : addresses.map(({ id }) => id)
    const unitIds = includeUnitTasks ? units.map(({ id }) => id) : []
    const vars = useReactiveVar(propertyTaskListCtrlsVar)
    const filters = vars.filters.map(f => `${f.field}___${f.id}`).sort().join(',')
    const { data, error, loading, fetchMore } = useQuery(GET_PROPERTY_TASKS, { variables: { 
        propertyIds,
        unitIds,
        ...vars,
        filters
    } })

    const renderContentTypeCtrls = () => (
        <li className='ctrls__listitem ml-auto'>
            <button
                disabled={true}
                className={`btn btn--naked btn--icon ml-4 mr-05 br-05${contentType !== 'room' ? ' active' : ''}`}
                onClick={() => {}}
            >
                <Icon type='address' variant='sm'/>
            </button>
            <button
                disabled={!units.length}
                className={`btn btn--naked btn--icon br-05${includeUnitTasks ? ' active' : ''}`}
                onClick={() => setUnitTasks((prev) => !prev)}
            >
                <Icon type='room' variant='sm'/>
            </button>
        </li>
    )

    return (
        <>
            {error && <div className='pos-center-y w-100 t-center'>{error.message}</div>}
            <TaskList
                tasks={data?.propertyTasks}
                controlsDisabled={loading}
                showLoader={loading && !data?.propertyTasks}
                columnKeys={columns}
                listCtrlVar={propertyTaskListCtrlsVar}
                query={GET_PROPERTY_TASKS}
                queryFieldName='propertyTasks'
                queryVariables={{ propertyIds, unitIds }}
                fetchMore={fetchMore}
                renderCtrlChildren={contentType !== 'room' ? renderContentTypeCtrls : undefined}
                ctrlOptions={{ ...listerCtrlOptions, sort: undefined }}
            />
        </>
    )
}

export default PropertyTasks
