import React from 'react'
import { GET_TASK_ATTACHMENTS } from '../queries'
import AttachmentsLister from '../../attachments/components/AttachmentLister'

const TaskAttachments = ({ taskId }) => {
    return (
        <div className='tab__panel--main'>
            <AttachmentsLister
                query={GET_TASK_ATTACHMENTS}
                queryVariables={{ taskId }}
                queryFieldName='taskAttachments'
                contentTypeString='ticketing | task'
                contentObjectId={taskId}
            />
        </div>
    )
}

export default TaskAttachments
