import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useMutation, gql } from '@apollo/client'
import { lbl } from '../constants'
import { currentUserVar } from '../cache'


const CHANGE_PASSWORD_MUTATION = gql`
  mutation CHANGE_PASSWORD_MUTATION($username: String!, $password: String!, $newPassword: String!) {
    changePassword(username: $username, password: $password, newPassword: $newPassword) {
        user {
            id
            email
            username
        }
    }
  }
`

const ChangePassword = () => {
    let newPasswordInput
    let [password, setPassword] = useState('')
    let [showError, setShowError] = useState(false)
    const [changePassword, { loading, error, called }] = useMutation(CHANGE_PASSWORD_MUTATION, {
        onError: () => { setShowError(true) },
        onCompleted: () => { setPassword(lbl.resetPassSuccess) }
    })
    const success = !error && !loading && called
    const handleOnChange = () => {showError && setShowError(false)}
    const currentNotValid = error && showError && error.message.includes('current password')
    const newNotValid = error && showError && error.message.includes('[')
    const errMsg = error && error.message.replace(/',|'|]|\[/g, '')
    const { firstName, username } = currentUserVar()

    return (
        <div className='contentbox--lg'>
            <div className='logo--center'>
                <img src={process.env.PUBLIC_URL + '/logo_long.svg'} alt='logo'/>
            </div>
            <h2 className='t-heading t-lg--responsive mb-6'>
                {lbl.hi} <strong>{firstName}</strong>,<br/>{lbl.changePassIntro}
            </h2>
            <div className='card'>
                <form
                    method='post'
                    data-test='form'
                    onSubmit={async e => {
                        e.preventDefault();
                        await changePassword({
                            variables: {
                                username,
                                password,
                                newPassword: newPasswordInput.value,
                            }
                        });
                    }}
                    >
                    <fieldset disabled={loading} aria-busy={loading}>
                        <br/>
                        <fieldset
                            disabled={success}
                            className={success ? 'success' : currentNotValid ? 'error' : ''}>
                            <label htmlFor='password'>
                                {success ? lbl.success : lbl.yourOldPass}
                            </label>
                            <input
                                type={success ? 'text' : 'password'}
                                name='password'
                                placeholder=''
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                    handleOnChange()
                                }}
                            />
                            {currentNotValid &&
                                <p className='input-message input-message--error'>
                                    {errMsg}
                                </p>
                            }
                        </fieldset>
                        {!success && 
                            <fieldset
                                className={newNotValid ? 'error' : ''}>
                                <label htmlFor='newPassword'>
                                    {lbl.yourNewPass}
                                </label>
                                <input
                                    type='password'
                                    name='newPassword'
                                    placeholder=''
                                    defaultValue=''
                                    ref={node => { newPasswordInput = node }}
                                />
                                {newNotValid &&
                                    <p className='input-message input-message--error'>
                                        {errMsg}
                                    </p>
                                }
                            </fieldset>
                        }
                        <br/>
                        {success ?
                            <Link to='/' type='button' className='btn d-inline-block mx-auto btn--success'>{lbl.goHome} &#8594;</Link>
                        :
                            <button type='submit' className='btn d-block mx-auto btn--primary'>
                                {loading ? <span className='btn__loader'>&#8635;</span> : lbl.save}
                            </button>
                        }
                    </fieldset>
                </form>
            </div>
        </div>
    )
}
export default ChangePassword;
