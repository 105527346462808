import { gql } from '@apollo/client'
import { BUILDING_DETAIL_FRAGMENT, TENANT_CONTRACT_FRAGMENT, PROPERTY_DETAIL_FRAGMENT, TENANCY_AGREEMENT_FRAGMENT, UNIT_FRAGMENT, OWNER_CONTRACT_FRAGMENT, SERVICE_FRAGMENT, SERVICE_PAYMENT_FRAGMENT } from './fragments'

export const CREATE_PROPERTY = gql`
    mutation CREATE_PROPERTY($streetName: String, $houseNumber:Int, $houseNumberAddition: String, $postcode: String, $buildingId: ID!, $ownerId: ID, $tenantRepresentativeId: ID, $propertyTypeId: ID, $operationalModelId: ID, $sizeSqm:Float, $serviceCostDivisionModel: String, $monthlyOwnerServiceCost:Float, $monthlyServiceCost:Float, $otherCosts:Float, $hasFireSafetySystem: Boolean, $brand: String, $model: String, $location: String, $dateInstalled: String, $lastCheckUpDate: String, $nextCheckUpDueDate: String, $repairContactId: ID, $notes: String, $minOccupancy: Int, $maxOccupancy: Int, $keycode: String) {
        createProperty(streetName: $streetName, houseNumber: $houseNumber, houseNumberAddition: $houseNumberAddition, postcode: $postcode, buildingId: $buildingId, ownerId: $ownerId, tenantRepresentativeId: $tenantRepresentativeId, propertyTypeId: $propertyTypeId, operationalModelId: $operationalModelId, sizeSqm: $sizeSqm, serviceCostDivisionModel: $serviceCostDivisionModel, monthlyOwnerServiceCost: $monthlyOwnerServiceCost, monthlyServiceCost: $monthlyServiceCost, otherCosts: $otherCosts, hasFireSafetySystem: $hasFireSafetySystem, brand: $brand, model: $model, location: $location, dateInstalled: $dateInstalled, lastCheckUpDate: $lastCheckUpDate, nextCheckUpDueDate: $nextCheckUpDueDate, repairContactId: $repairContactId, notes: $notes, minOccupancy: $minOccupancy, maxOccupancy: $maxOccupancy, keycode: $keycode) {
            property {
                ...AllPropertyFields
            }
        }
    }
    ${PROPERTY_DETAIL_FRAGMENT}
`

export const UPDATE_PROPERTY = gql`
    mutation UPDATE_PROPERTY($id: ID!, $streetName: String, $houseNumber:Int, $houseNumberAddition: String, $postcode: String, $ownerId: ID, $tenantRepresentativeId: ID, $propertyTypeId: ID, $operationalModelId: ID, $sizeSqm:Float, $minOccupancy: Int, $maxOccupancy: Int, $serviceCostDivisionModel: String, $monthlyOwnerServiceCost:Float, $monthlyServiceCost:Float, $otherCosts:Float, $hasFireSafetySystem: Boolean, $brand: String, $model: String, $location: String, $dateInstalled: String, $lastCheckUpDate: String, $nextCheckUpDueDate: String, $repairContactId: ID, $notes: String, $keycode: String) {
        updateProperty(id: $id, streetName: $streetName, houseNumber: $houseNumber, houseNumberAddition: $houseNumberAddition, postcode: $postcode, ownerId: $ownerId, tenantRepresentativeId: $tenantRepresentativeId, propertyTypeId: $propertyTypeId, operationalModelId: $operationalModelId, sizeSqm: $sizeSqm, serviceCostDivisionModel: $serviceCostDivisionModel, monthlyOwnerServiceCost: $monthlyOwnerServiceCost, monthlyServiceCost: $monthlyServiceCost, otherCosts: $otherCosts, hasFireSafetySystem: $hasFireSafetySystem, brand: $brand, model: $model, location: $location, dateInstalled: $dateInstalled, lastCheckUpDate: $lastCheckUpDate, nextCheckUpDueDate: $nextCheckUpDueDate, repairContactId: $repairContactId,notes: $notes, minOccupancy: $minOccupancy, maxOccupancy: $maxOccupancy, keycode: $keycode) {
            property {
                ...AllPropertyFields
            }
        }
    }
    ${PROPERTY_DETAIL_FRAGMENT}
`

export const CREATE_BUILDING = gql`
    mutation CREATE_BUILDING($name: String, $homeownerAssociationId: ID, $cityId: ID!, $ownerId: ID, $caretakerId: ID, $keycode: String) {
        createBuilding(name: $name, homeownerAssociationId: $homeownerAssociationId, cityId: $cityId, ownerId: $ownerId, caretakerId: $caretakerId, keycode: $keycode) {
            building {
                ...allBuildingFields
            }
        }
    }
    ${BUILDING_DETAIL_FRAGMENT}
`

export const UPDATE_BUILDING = gql`
    mutation UPDATE_BUILDING($id: ID!, $name: String, $homeownerAssociationId: ID, $cityId: ID, $ownerId: ID, $caretakerId: ID, $keycode: String) {
        updateBuilding(id: $id, name: $name, homeownerAssociationId: $homeownerAssociationId, cityId: $cityId, ownerId: $ownerId, caretakerId: $caretakerId, keycode: $keycode) {
            building {
                ...allBuildingFields
            }
        }
    }
    ${BUILDING_DETAIL_FRAGMENT}
`

export const CREATE_UNIT = gql`
    mutation CREATE_UNIT($addressId: ID!, $name: String, $unitNumber:Int, $operationalModelId: ID, $sizeSqm:Float, $monthlyBasicRent:Float, $monthlyServiceCost:Float, $deposit:Float, $minOccupancy: Int, $maxOccupancy: Int, $keycode: String) {
        createUnit(addressId: $addressId, name: $name, unitNumber: $unitNumber, operationalModelId: $operationalModelId, sizeSqm: $sizeSqm, monthlyBasicRent: $monthlyBasicRent, monthlyServiceCost: $monthlyServiceCost, deposit: $deposit, minOccupancy: $minOccupancy, maxOccupancy: $maxOccupancy, keycode: $keycode) {
            unit {
                ...roomFields
                operationalModel {
                    id
                    name
                }
                sizeSqm
                monthlyBasicRent
                monthlyServiceCost
                deposit
                address {
                    id
                }
                minOccupancy
                maxOccupancy
                keycode
            }
        }
    }
    ${UNIT_FRAGMENT}
`

export const UPDATE_UNIT = gql`
    mutation UPDATE_UNIT($id: ID!, $name: String, $unitNumber:Int, $operationalModelId: ID, $sizeSqm:Float, $monthlyBasicRent:Float, $monthlyServiceCost:Float, $deposit:Float, $minOccupancy: Int, $maxOccupancy: Int, $keycode: String) {
        updateUnit(id: $id, name: $name, unitNumber: $unitNumber, operationalModelId: $operationalModelId, sizeSqm: $sizeSqm, monthlyBasicRent: $monthlyBasicRent, monthlyServiceCost: $monthlyServiceCost, deposit: $deposit, minOccupancy: $minOccupancy, maxOccupancy: $maxOccupancy, keycode: $keycode) {
            unit {
                ...roomFields
                operationalModel {
                    id
                    name
                }
                sizeSqm
                monthlyBasicRent
                monthlyServiceCost
                deposit
                minOccupancy
                maxOccupancy
                keycode
            }
        }
    }
    ${UNIT_FRAGMENT}
`

export const CREATE_CONTRACT = gql`
    mutation CREATE_CONTRACT($propertyId: ID, $unitId: ID, $typeId: ID, $startDate: String!, $endDate: String, $forIndefinitePeriod: Boolean, $monthlyRent: Float, $monthlyServiceCost: Float, $serviceCostDescription: String, $otherCosts: Float, $deposit: Float, $includeUnitContracts: Boolean) {
        createContract(propertyId: $propertyId, unitId: $unitId, typeId: $typeId, startDate: $startDate, endDate: $endDate, forIndefinitePeriod: $forIndefinitePeriod, monthlyRent: $monthlyRent, monthlyServiceCost: $monthlyServiceCost, serviceCostDescription: $serviceCostDescription, otherCosts: $otherCosts, deposit: $deposit) {
            contract {
                ...TenantContractFields
                property {
                    id
                    longName
                    rooms {
                        id
                        fullUnitName
                    }
                    propertyContracts(includeUnitContracts: $includeUnitContracts) {
                        ...TenantContractFields
                    }
                }
                unit {
                    id
                    longName
                    fullUnitName
                    unitContracts {
                        ...TenantContractFields
                    }
                }
            }
        }
    }
    ${TENANT_CONTRACT_FRAGMENT}
`

export const UPDATE_CONTRACT = gql`
    mutation UPDATE_CONTRACT($id: ID!, $typeId: ID, $startDate: String, $endDate: String, $forIndefinitePeriod: Boolean, $monthlyRent: Float, $monthlyServiceCost: Float, $serviceCostDescription: String, $otherCosts: Float, $deposit: Float) {
        updateContract(id: $id, typeId: $typeId, startDate: $startDate, endDate: $endDate, forIndefinitePeriod: $forIndefinitePeriod, monthlyRent: $monthlyRent, monthlyServiceCost: $monthlyServiceCost, serviceCostDescription: $serviceCostDescription, otherCosts: $otherCosts, deposit: $deposit) {
            contract {
                ...TenantContractFields
                contractTenancyAgreements {
                    id
                    checkOutDate
                    rentResponsabilityEndDate
                    forIndefinitePeriod
                }
            }
        }
    }
    ${TENANT_CONTRACT_FRAGMENT}
`

export const CREATE_OWNER_CONTRACT = gql`
    mutation CREATE_OWNER_CONTRACT($ownerId: ID!, $addressIds: [ID], $roomIds: [ID], $buildingIds: [ID], $startDate: String!, $endDate: String, $forIndefinitePeriod: Boolean, $monthlyRent: Float, $monthlyServiceCost: Float, $serviceCostDescription: String, $otherCosts: Float, $deposit: Float) {
        createOwnerContract(ownerId: $ownerId, propertyIds: $addressIds, unitIds: $roomIds, buildingIds: $buildingIds, startDate: $startDate, endDate: $endDate, forIndefinitePeriod: $forIndefinitePeriod, monthlyRent: $monthlyRent, monthlyServiceCost: $monthlyServiceCost, serviceCostDescription: $serviceCostDescription, otherCosts: $otherCosts, deposit: $deposit) {
            contract {
                ...OwnerContractFields
                buildings {
                    id
                    buildingOwnerContracts {
                        ...OwnerContractFields
                    }
                }
                properties {
                    id
                    propertyOwnerContracts {
                        ...OwnerContractFields
                    }
                }
                units {
                    id
                    unitOwnerContracts {
                        ...OwnerContractFields
                    }
                }
            }
        }
    }
    ${OWNER_CONTRACT_FRAGMENT}
`

export const UPDATE_OWNER_CONTRACT = gql`
    mutation UPDATE_OWNER_CONTRACT($id: ID!, $ownerId: ID, $addressIds: [ID], $roomIds: [ID], $buildingIds: [ID], $startDate: String, $endDate: String, $forIndefinitePeriod: Boolean, $monthlyRent: Float, $monthlyServiceCost: Float, $serviceCostDescription: String, $otherCosts: Float, $deposit: Float) {
        updateOwnerContract(id: $id, ownerId: $ownerId, propertyIds: $addressIds, unitIds: $roomIds, buildingIds: $buildingIds, startDate: $startDate, endDate: $endDate, forIndefinitePeriod: $forIndefinitePeriod, monthlyRent: $monthlyRent, monthlyServiceCost: $monthlyServiceCost, serviceCostDescription: $serviceCostDescription, otherCosts: $otherCosts, deposit: $deposit) {
            contract {
                ...OwnerContractFields
                buildings {
                    id
                    buildingOwnerContracts {
                        ...OwnerContractFields
                    }
                }
                properties {
                    id
                    propertyOwnerContracts {
                        ...OwnerContractFields
                    }
                }
                units {
                    id
                    unitOwnerContracts {
                        ...OwnerContractFields
                    }
                }
            }
        }
    }
    ${OWNER_CONTRACT_FRAGMENT}
`

export const CREATE_TENANCY_AGREEMENT = gql`
    mutation CREATE_TENANCY_AGREEMENT($contractId: ID!, $tenantId: ID!, $unitId: ID, $contractSignedDate: String, $checkInDate: String, $checkOutDate: String, $rentResponsabilityStartDate: String!, $rentResponsabilityEndDate: String, $monthlyRent: Float, $monthlyServiceCost: Float, $otherCosts: Float, $deposit: Float, $depositReimbursed: Boolean, $forIndefinitePeriod: Boolean) {
        createTenancyAgreement(contractId: $contractId, tenantId: $tenantId, unitId: $unitId, contractSignedDate: $contractSignedDate, checkInDate: $checkInDate, checkOutDate: $checkOutDate, rentResponsabilityStartDate: $rentResponsabilityStartDate, rentResponsabilityEndDate: $rentResponsabilityEndDate, monthlyRent: $monthlyRent, monthlyServiceCost: $monthlyServiceCost, otherCosts: $otherCosts, deposit: $deposit, depositReimbursed: $depositReimbursed, forIndefinitePeriod: $forIndefinitePeriod) {
            tenancyAgreement {
                ...TenancyAgreementFields
                tenant {
                    id
                    user {
                        id
                        fullName
                        picture
                    }
                    tenancyStatus
                    activeTenancyAgreement {
                        id
                    }
                    tenancyAgreements(includeArchived: true) {
                        id
                    }
                }
                contract {
                    id
                    type {
                        id
                        name
                    }
                    startDate
                    endDate
                    forIndefinitePeriod
                    property {
                        id
                        longName
                        rooms {
                            id
                            fullUnitName
                        }
                    }
                    unit {
                        id
                        longName
                        fullUnitName
                        address {
                            id
                            longName
                        }
                    }
                    contractTenancyAgreements {
                        ...TenancyAgreementFields
                    }
                }
            }
        }
    }
    ${TENANCY_AGREEMENT_FRAGMENT}
`

export const UPDATE_TENANCY_AGREEMENT = gql`
    mutation UPDATE_TENANCY_AGREEMENT($id: ID!, $tenantId: ID, $unitId: ID, $contractSignedDate: String, $checkInDate: String, $checkOutDate: String, $rentResponsabilityStartDate: String, $rentResponsabilityEndDate: String, $monthlyRent: Float, $monthlyServiceCost: Float, $otherCosts: Float, $deposit: Float, $depositReimbursed: Boolean, $forIndefinitePeriod: Boolean) {
        updateTenancyAgreement(id: $id, tenantId: $tenantId, unitId: $unitId, contractSignedDate: $contractSignedDate, checkInDate: $checkInDate, checkOutDate: $checkOutDate, rentResponsabilityStartDate: $rentResponsabilityStartDate, rentResponsabilityEndDate: $rentResponsabilityEndDate, monthlyRent: $monthlyRent, monthlyServiceCost: $monthlyServiceCost, otherCosts: $otherCosts, deposit: $deposit, depositReimbursed: $depositReimbursed, forIndefinitePeriod: $forIndefinitePeriod) {
            tenancyAgreement {
                ...TenancyAgreementFields
                tenant {
                    id
                    user {
                        id
                        fullName
                        picture
                    }
                    tenancyStatus
                    activeTenancyAgreement {
                        id
                    }
                }
            }
        }
    }
    ${TENANCY_AGREEMENT_FRAGMENT}
`

export const CREATE_LEAD = gql`
    mutation CREATE_LEAD($tenantId: ID!, $propertyId: ID, $unitId: ID, $preferredCheckInDate: String, $budget: Float, $includeUnitLeads: Boolean) {
        createLead(tenantId: $tenantId, propertyId: $propertyId, unitId: $unitId, preferredCheckInDate: $preferredCheckInDate, budget: $budget) {
            lead {
                id
                budget
                preferredCheckInDate
                archived
                tenant {
                    id
                    user {
                        id
                        fullName
                        picture
                    }
                    activeLead {
                        id
                    }
                    leads(includeArchived: true) {
                        id
                    }
                }
                property {
                    id
                    streetWithHouseNumber
                    propertyLeads(includeUnitLeads: $includeUnitLeads) {
                        id
                    }
                }
                unit {
                    id
                    longName
                    unitLeads {
                        id
                    }
                }
            }
        }
    }
`

export const UPDATE_LEAD = gql`
    mutation UPDATE_LEAD($id: ID!, $tenantId: ID, $propertyId: ID, $unitId: ID, $preferredCheckInDate: String, $budget: Float) {
        updateLead(id: $id, tenantId: $tenantId, propertyId: $propertyId, unitId: $unitId, preferredCheckInDate: $preferredCheckInDate, budget: $budget) {
            lead {
                id
                budget
                preferredCheckInDate
                archived
                tenant {
                    id
                    user {
                        id
                        fullName
                        picture
                    }
                    activeLead {
                        id
                    }
                    leads {
                        id
                    }
                }
                property {
                    id
                    streetWithHouseNumber
                    propertyLeads {
                        id
                    }
                    rooms {
                        id
                        fullUnitName
                    }
                }
                unit {
                    id
                    fullUnitName
                    unitLeads {
                        id
                    }
                }
            }
        }
    }
`

export const ARCHIVE_LEAD = gql`
    mutation ARCHIVE_LEAD($id: ID!) {
        archiveLead(id: $id) {
            lead {
                id
                archived
                property {
                    id
                    propertyLeads {
                        id
                    }
                }
                unit {
                    id
                    unitLeads {
                        id
                    }
                }
                tenant {
                    id
                    tenancyStatus
                    activeLead {
                        id
                    }
                    leads {
                        id
                    }
                }
            }
        }
    }
`

export const ARCHIVE_LEADS = gql`
    mutation ARCHIVE_LEADS($ids: [ID]!) {
        archiveLeads(ids: $ids) {
            leads {
                id
                archived
                property {
                    id
                    propertyLeads {
                        id
                    }
                }
                unit {
                    id
                    unitLeads {
                        id
                    }
                }
                tenant {
                    id
                    tenancyStatus
                    activeLead {
                        id
                    }
                    leads {
                        id
                    }
                }
            }
        }
    }
`

export const ARCHIVE_CONTRACT = gql`
    mutation ARCHIVE_CONTRACT($id: ID!) {
        archiveContract(id: $id) {
            contract {
                id
                archived
                contractTenancyAgreements(includeArchived: true) {
                    id
                    active
                    archived
                    tenant {
                        id
                        tenancyStatus
                        activeTenancyAgreement {
                            id
                        }
                    }
                }
            }
        }
    }
`

export const ARCHIVE_CONTRACTS = gql`
    mutation ARCHIVE_CONTRACTS($ids: [ID]!) {
        archiveContracts(ids: $ids) {
            contracts {
                id
                archived
                contractTenancyAgreements(includeArchived: true) {
                    id
                    active
                    archived
                    tenant {
                        id
                        tenancyStatus
                        activeTenancyAgreement {
                            id
                        }
                    }
                }
            }
        }
    }
`

export const ARCHIVE_OWNER_CONTRACT = gql`
    mutation ARCHIVE_OWNER_CONTRACT($id: ID!) {
        archiveOwnerContract(id: $id) {
            contract {
                id
                archived
            }
        }
    }
`

export const ARCHIVE_OWNER_CONTRACTS = gql`
    mutation ARCHIVE_OWNER_CONTRACTS($ids: [ID]!) {
        archiveOwnerContracts(ids: $ids) {
            contracts {
                id
                archived
            }
        }
    }
`

export const ARCHIVE_TENANCY_AGREEMENT = gql`
    mutation ARCHIVE_TENANCY_AGREEMENT($id: ID!) {
        archiveTenancyAgreement(id: $id) {
            tenancyAgreement {
                id
                archived
                tenant {
                    id
                    tenancyStatus
                    activeTenancyAgreement {
                        id
                    }
                }
                contract {
                    id
                    archived
                }
            }
        }
    }
`

export const ARCHIVE_TENANCY_AGREEMENTS = gql`
    mutation ARCHIVE_TENANCY_AGREEMENTS($ids: [ID]!) {
        archiveTenancyAgreements(ids: $ids) {
            tenancyAgreements {
                id
                archived
                tenant {
                    id
                    tenancyStatus
                    activeTenancyAgreement {
                        id
                    }
                }
                contract {
                    id
                    archived
                }
            }
        }
    }
`

export const ARCHIVE_BUILDINGS = gql`
    mutation ARCHIVE_BUILDINGS($ids: [ID]!) {
        archiveBuildings(ids: $ids) {
            buildings {
                id
                archived
            }
        }
    }
`

export const ARCHIVE_ADDRESSES = gql`
    mutation ARCHIVE_ADDRESSES($ids: [ID]!) {
        archiveProperties(ids: $ids) {
            addresses {
                id
                archived
            }
        }
    }
`

export const ARCHIVE_ROOMS = gql`
    mutation ARCHIVE_ROOMS($ids: [ID]!) {
        archiveUnits(ids: $ids) {
            units {
                id
                archived
            }
        }
    }
`

export const ARCHIVE_PROPERTY_RELATED_OBJECTS = gql`
    mutation ARCHIVE_PROPERTY_RELATED_OBJECTS($buildingIds: [ID], $addressIds: [ID], $roomIds: [ID], $tenantContractIds: [ID], $ownerContractIds: [ID], $tenancyAgreementIds: [ID], $leadIds: [ID], $ticketIds: [ID], $taskIds:[ID], $threadIds: [ID]) {
        archiveBuildings(ids: $buildingIds) {
            buildings {
                id
                archived
                name
            }
        }
        archiveProperties(ids: $addressIds) {
            addresses {
                id
                archived
                label
            }
        }
        archiveUnits(ids: $roomIds) {
            units {
                id
                archived
                label
            }
        }
        archiveContracts(ids: $tenantContractIds) {
            contracts {
                id
                archived
                label
                contractTenancyAgreements(includeArchived: true) {
                    id
                    active
                    archived
                    tenant {
                        id
                        tenancyStatus
                        activeTenancyAgreement {
                            id
                        }
                    }
                }
            }
        }
        archiveOwnerContracts(ids: $ownerContractIds) {
            contracts {
                id
                archived
                label
            }
        }
        archiveTenancyAgreements(ids: $tenancyAgreementIds) {
            tenancyAgreements {
                id
                archived
                label
                tenant {
                    id
                    tenancyStatus
                    activeTenancyAgreement {
                        id
                    }
                }
                contract {
                    id
                    archived
                }
            }
        }
        archiveLeads(ids: $leadIds) {
            leads {
                id
                archived
                label
                property {
                    id
                    propertyLeads {
                        id
                    }
                }
                unit {
                    id
                    unitLeads {
                        id
                    }
                }
                tenant {
                    id
                    tenancyStatus
                    activeLead {
                        id
                    }
                    leads {
                        id
                    }
                }
            }
        }
        archiveTickets(ids: $ticketIds) {
            archivedTicketIds
            archivedTicketStatusIds
            tickets {
                id
                archived
                name
                tasks {
                    id
                    archived
                }
                threads {
                    id
                    archived
                }
            }
        }
        archiveTasks(ids: $taskIds) {
            archivedIds
            tasks {
                id
                archived
                label
                ticket {
                    id
                }
            }
        }
        archiveThreads(threadIds: $threadIds) {
            threads {
                id
                unreadCount
                archived
                name
                membership {
                    id
                    isUnread
                }
            }
        }
    }
`


export const ADD_METER = gql`
    mutation ADD_METER($serviceId: ID!, $location: String, $number: String) {
        addMeter(serviceId: $serviceId, location: $location, number: $number) {
            meter {
                id
                location
                number
                archived
                service {
                    id
                    meter {
                        id
                        location
                        number
                        archived
                    }
                }
            }
        }
    }
`

export const UPDATE_METER = gql`
    mutation UPDATE_METER($id: ID!, $location: String, $number: String) {
        updateMeter(id: $id, location: $location, number: $number) {
            meter {
                id
                location
                number
                archived
            }
        }
    }
`

export const ARCHIVE_METER = gql`
    mutation ARCHIVE_METER($id: ID!) {
        archiveMeter(id: $id) {
            meter {
                id
                type {
                    id
                    name
                }
                location
                number
                archived
            }
        }
    }
`

export const ADD_METER_READING = gql`
    mutation ADD_METER_READING($meterId: ID!, $date: String!, $value: Float!) {
        addMeterReading(meterId: $meterId, date: $date, value: $value) {
            meterReading {
                id
                date
                value
                picture
                archived
                meter {
                    id
                    meterReadings {
                        id
                        date
                        value
                        picture
                        archived
                    }
                }
            }
        }
    }
`

export const UPDATE_METER_READING = gql`
    mutation UPDATE_METER_READING($id: ID!, $date: String, $value: Float) {
        updateMeterReading(id: $id, date: $date, value: $value) {
            meterReading {
                id
                date
                value
                picture
                archived
            }
        }
    }
`

export const ARCHIVE_METER_READING = gql`
    mutation ARCHIVE_METER($id: ID!) {
        archiveMeterReading(id: $id) {
            meterReading {
                id
                date
                value
                picture
                archived
            }
        }
    }
`

export const ADD_SERVICE = gql`
    mutation ADD_SERVICE($typeId: ID!, $isInternet: Boolean, $buildingId: ID, $addressId: ID, $roomId: ID, $providerId: ID, $contractUrl: String, $serviceFrequency: String, $paymentFrequency: String, $price: Float, $note: String, $contractFileId: ID, $packageType: String, $modemCode: String, $modemLocation: String, $networkName: String, $networkPassword: String) {
        addService(typeId: $typeId, isInternet: $isInternet, buildingId: $buildingId, addressId: $addressId, roomId: $roomId, providerId: $providerId, contractUrl: $contractUrl, serviceFrequency: $serviceFrequency, paymentFrequency: $paymentFrequency, price: $price, note: $note, contractFileId: $contractFileId, packageType: $packageType, modemCode: $modemCode, modemLocation: $modemLocation, networkName: $networkName, networkPassword: $networkPassword) {
            service {
                ...ServiceFields
                building {
                    id
                    buildingServices {
                        id
                    }
                }
                address {
                    id
                    addressServices {
                        id
                    }
                }
                room {
                    id
                    roomServices {
                        id
                    }
                }
            }
        }
    }
    ${SERVICE_FRAGMENT}
`

export const UPDATE_SERVICE = gql`
    mutation UPDATE_SERVICE($id: ID!, $isInternet: Boolean, $providerId: ID, $contractUrl: String, $serviceFrequency: String, $paymentFrequency: String, $price: Float, $note: String, $contractFileId: ID,  $packageType: String, $modemCode: String, $modemLocation: String, $networkName: String, $networkPassword: String) {
        updateService(id: $id, isInternet: $isInternet, providerId: $providerId, contractUrl: $contractUrl, serviceFrequency: $serviceFrequency, paymentFrequency: $paymentFrequency, price: $price, note: $note, contractFileId: $contractFileId, packageType: $packageType, modemCode: $modemCode, modemLocation: $modemLocation, networkName: $networkName, networkPassword: $networkPassword) {
            service {
                ...ServiceFields
            }
        }
    }
    ${SERVICE_FRAGMENT}
`

export const ARCHIVE_SERVICE = gql`
    mutation ARCHIVE_SERVICE($id: ID!) {
        archiveService(id: $id) {
            service {
                id
                archived
            }
        }
    }
`

export const ADD_SERVICE_PAYMENT = gql`
    mutation ADD_SERVICE_PAYMENT($serviceId: ID!, $periodStartDate: String!, $periodEndDate: String!, $dueDate: String, $paymentDate: String, $paidById: ID, $amount: Float, $note: String, $fileId: ID) {
        addServicePayment(serviceId: $serviceId, periodStartDate: $periodStartDate, periodEndDate: $periodEndDate, dueDate: $dueDate, paymentDate: $paymentDate, paidById: $paidById, amount: $amount, note: $note, fileId: $fileId) {
            servicePayment {
                ...ServicePaymentFields
                service {
                    id
                    payments {
                        id
                    }
                }
            }
        }
    }
    ${SERVICE_PAYMENT_FRAGMENT}
`

export const UPDATE_SERVICE_PAYMENT = gql`
    mutation UPDATE_SERVICE_PAYMENT($id: ID!, $periodStartDate: String, $periodEndDate: String, $dueDate: String, $paymentDate: String, $paidById: ID, $amount: Float, $note: String, $fileId: ID) {
        updateServicePayment(id: $id, periodStartDate: $periodStartDate, periodEndDate: $periodEndDate, dueDate: $dueDate, paymentDate: $paymentDate, paidById: $paidById, amount: $amount, note: $note, fileId: $fileId) {
            servicePayment {
                ...ServicePaymentFields
            }
        }
    }
    ${SERVICE_PAYMENT_FRAGMENT}
`

export const ARCHIVE_SERVICE_PAYMENT = gql`
    mutation ARCHIVE_SERVICE_PAYMENT($id: ID!) {
        archiveServicePayment(id: $id) {
            servicePayment {
                id
                archived
            }
        }
    }
`
