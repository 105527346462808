import { useQuery } from '@apollo/client'
import { useReactiveVar } from '@apollo/client'
import { GET_PROPERTIES } from '../queries'
import Lister from '../../components/Lister'
import PropertyListItem from './PropertyListItem'
import { listerCtrlOptions, addressProperties, listerColumns } from '../settings'
import { propertyListCtrlsVar } from '../../cache'


const PropertyList = () => {
    const vars = useReactiveVar(propertyListCtrlsVar)
    const filters = vars.filters.map(f => `${f.field}___${f.id}`).sort().join(',')
    let variables = { ...vars, filters }
    const { data, loading, error, fetchMore } = useQuery(GET_PROPERTIES, {
        variables,
        notifyOnNetworkStatusChange: true
    })

    if (error) return <div className='pos-center-y w-100 t-center'>{error.message}</div>
    
    return (
        <div className='tab__panel--main intersection-root'>
            <Lister
                options={listerCtrlOptions}
                optionsDict={addressProperties}
                listerCtrlVar={propertyListCtrlsVar}
                columnKeys={listerColumns}
                items={data?.properties}
                showLoader={loading}
                fetchMore={fetchMore}
                queryFieldName='properties'
                query={GET_PROPERTIES}
                type='properties'
                showFirstColumnLabel
            >
                {(props) => (
                    <PropertyListItem
                        propKeys={listerColumns}
                        {...props}
                    />
                )}
            </Lister>
        </div>
    )
}

export default PropertyList;
