export const scrollTo = (scrollContainer, top) => {
    if (scrollContainer) scrollContainer.scrollTop = top
}

export const scrollToBottom = (scrollContainer) => {
    scrollContainer && scrollTo(scrollContainer, scrollContainer.scrollHeight)
}

export const scrolledToBottom = (scrollContainer) => {
    if (scrollContainer) {
        const { clientHeight, scrollTop, scrollHeight } = scrollContainer
        return clientHeight + scrollTop === scrollHeight
    }
    return true
}

export const scrolledToThreshold = (scrollContainer, threshold) =>
    scrollContainer && scrollContainer.scrollTop <= threshold

export const scrollTop = (scrollContainer) => scrollContainer && scrollContainer.scrollTop

export const scrollHeight = (scrollContainer) => scrollContainer && scrollContainer.scrollHeight

export const clientHeight = (scrollContainer) => scrollContainer && scrollContainer.clientHeight

export function isElementInViewport (el) {
    const rect = el.getBoundingClientRect()
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
}

const scrollApi = {
    scrollTo,
    scrollToBottom,
    scrolledToBottom,
    scrolledToThreshold,
    scrollTop,
    scrollHeight,
    clientHeight
}

export default scrollApi