import { useMutation } from '@apollo/client'
import { RoundIconButton } from '../../components'
import { SET_RECEIVER_ID } from '../mutations'

const Incoming = ({ connection, currentUser }) => {
    const [setReceiverId] = useMutation(SET_RECEIVER_ID)

    const acceptConnection = () => {
        setReceiverId({ variables: {
            callSid: connection.customParameters.get('call_sid'),
            receiverId: currentUser.id
        } })
        connection.accept()
    }
    const rejectConnection = () => {
        connection.reject()
    }

    return (
        <div className='t-center'>
            <div className='mt-6 call__btns'>
                <RoundIconButton
                    onClick={acceptConnection}
                    icon='call'
                    color='green'
                    title='answer'
                />
                <RoundIconButton
                    onClick={rejectConnection}
                    icon='call-hangup'
                    color='red'
                    title='reject'
                />
            </div>
        </div>
    )
}

export default Incoming
