import { gql } from '@apollo/client'
import { MESSAGE_FRAGMENT, THREAD_FRAGMENT } from './fragments'
import { USER_FRAGMENT } from '../fragments'


export const GET_THREADS = gql`
  query GET_THREADS($all: Boolean, $includeArchived: Boolean, $archivedOnly: Boolean, $topLevelOnly: Boolean) {
        threads(all: $all, includeArchived: $includeArchived, archivedOnly: $archivedOnly, topLevelOnly: $topLevelOnly) {
            ...BaseThreadFields
            hasSubthreads(all: $all)
        }
    }
    ${THREAD_FRAGMENT}
`

export const GET_THREAD = gql`
    query GET_THREAD($id: ID!, $offset: Int, $limit: Int, $reversed: Boolean, $all: Boolean) {
        thread(id: $id) {
            ...BaseThreadFields
            parent {
                id
                name
                icon
                isDirect
            }
            members {
                ...UserFields
            }
            threadmembershipSet {
                id
                user {
                    id
                    fullName
                }
                joinedAt
                leftAt
            }
            messages(offset: $offset, limit: $limit, reversed: $reversed) {
                ...MessageFields
            }
            totalMessageCount
            subthreads(all: $all) {
                ...BaseThreadFields
                membership {
                    id
                    isPinned
                    isSubscribed
                    isUnread
                    lastRead
                }
                messages(offset: 0, limit: 5, reversed: true) {
                    ...MessageFields
                }
                unreadMessages {
                    ...MessageFields
                }
            }
        }
    }
    ${THREAD_FRAGMENT}
    ${USER_FRAGMENT}
    ${MESSAGE_FRAGMENT}
`

export const GET_THREAD_SUBTHREADS = gql`
    query GET_THREAD_SUBTHREADS($threadId: ID!, $all: Boolean) {
        thread(id: $threadId) {
            id
            subthreads(all: $all) {
                ...BaseThreadFields
                hasSubthreads(all: $all)
            }
        }
    }
    ${THREAD_FRAGMENT}
`

export const GET_THREAD_CRUMBS = gql`
    query GET_THREAD_CRUMBS($id: ID!) {
        threadCrumbs(id: $id) {
            id
            name
            contentType {
                id
                model
            }
            objectId
        }
    }
`

export const GET_UNREAD_THREADS = gql`
    query GET_UNREAD_THREADS {
        unreadThreads {
            unreadMentionsCount
            unreadMessageCount
            threads {
                ...BaseThreadFields
                messages(offset: 0, limit: 5, reversed: true) {
                    ...MessageFields
                    isRead
                }
                unreadMessages {
                    ...MessageFields
                }
            }
        }
    }
    ${THREAD_FRAGMENT}
    ${MESSAGE_FRAGMENT}
`

export const GET_PINNED_THREADS = gql`
    query GET_PINNED_THREADS {
        pinnedThreads {
            ...BaseThreadFields
            messages(offset: 0, limit: 5, reversed: true) {
                ...MessageFields
            }
            unreadMessages {
                ...MessageFields
            }
        }
    }
    ${THREAD_FRAGMENT}
    ${MESSAGE_FRAGMENT}
`

export const GET_ARCHIVED_THREADS = gql`
    query GET_ARCHIVED_THREADS($offset: Int, $limit: Int) {
        archivedThreads(offset: $offset, limit: $limit) {
            ...BaseThreadFields
            messages(offset: 0, limit: 5, reversed: true) {
                ...MessageFields
            }
            unreadMessages {
                ...MessageFields
            }
            hasMore
        }
    }
    ${THREAD_FRAGMENT}
    ${MESSAGE_FRAGMENT}
`

export const GET_CONTACT_OPTIONS = gql`
    query GET_CONTACT_OPTIONS {
        recentlyViewedThreads {
            id
            name
            fullName
            icon
            isDirect
            isGroup
            contact {
                id
                onlineStatus
            }
        }
        threads(all: true, includeFolders: false) {
            id
            name
            fullName
            icon
            isDirect
            isGroup
            contact {
                id
                onlineStatus
            }
        }
        users(excludeCurrentUser: true, groupBy: "type") {
            id
            fullName
            picture
            type
            onlineStatus
        }
    }
`

export const GET_MENTIONS = gql`
    query GET_MENTIONS {
        mentions {
            ...MessageFields
            isRead
            thread {
                id
                icon
                name
                isDirect
                unreadCount
            }
        }
    }
    ${MESSAGE_FRAGMENT}
`

export const GET_USER_PUBLIC_THREADS = gql`
    query GET_USER_PUBLIC_THREADS($id: ID!, $offset: Int, $limit: Int) {
        userPublicThreads(userId: $id, offset: $offset, limit: $limit) {
            ...BaseThreadFields
            messages(offset: 0, limit: 5, reversed: true) {
                ...MessageFields
            }
            unreadMessages {
                ...MessageFields
            }
            hasMore
        }
    }
    ${THREAD_FRAGMENT}
    ${MESSAGE_FRAGMENT}
`

export const GET_TICKET_THREADS = gql`
    query GET_TICKET_THREADS($ticketId: ID!) {
        ticket(id: $ticketId) {
            id
            threads {
                ...BaseThreadFields
                messages(offset: 0, limit: 5, reversed: true) {
                    ...MessageFields
                }
                unreadMessages {
                    ...MessageFields
                }
            }
        }
    }
    ${THREAD_FRAGMENT}
    ${MESSAGE_FRAGMENT}
`

export const GET_PROPERTY_THREADS = gql`
    query GET_PROPERTY_THREADS($building: [ID], $address: [ID], $room: [ID]) {
        propertyThreads(buildingIds: $building, propertyIds: $address, unitIds: $room) {
            ...BaseThreadFields
            messages(offset: 0, limit: 5, reversed: true) {
                ...MessageFields
            }
            unreadMessages {
                ...MessageFields
            }
        }
    }
    ${THREAD_FRAGMENT}
    ${MESSAGE_FRAGMENT}
`

export const GET_RELATED_THREADS = gql`
    query GET_RELATED_THREADS($contentTypeModel: String!, $objectId: ID!) {
        threadsByRelatedObject(contentTypeModel: $contentTypeModel, objectId: $objectId, includeAll: true) {
            id
        }
    }
`

export const GET_HIGHLIGHTED_MESSAGES_FOR_THREAD = gql`
    query GET_HIGHLIGHTED_MESSAGES_FOR_THREAD($threadId: ID!) {
        highlightedMessages(threadId: $threadId) {
            ...MessageFields
        }
    }
    ${MESSAGE_FRAGMENT}
`
