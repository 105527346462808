import { useQuery } from '@apollo/client'
import { GET_PINNED_THREADS } from '../queries'
import ThreadList from './ThreadList'


const PinnedOverview = () => {
    const { data, loading, error } = useQuery(GET_PINNED_THREADS)

    if (loading) return <div className='loader pos-center-y' />
    if (error) console.error(error)
    if (data && data.pinnedThreads.length === 0) return (
        <div className='t-grey w-100 mx-auto my-auto'>
            <center>You have no pinned threads</center>
        </div>
    )
    if (data) return (
        <div className='split-panel--main p-0'>
            <ThreadList threads={data.pinnedThreads} />
        </div>
    )
    return null
}

export default PinnedOverview
