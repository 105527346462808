import { Link } from 'react-router-dom'
import { Icon, Counter } from '../../components'


export const InboxItemLabel = ({ label, level, icon='hash', onlineStatus, onClick, onContextMenu, unreadCount, hideIcon, markedAsUnread, isSubscribed=true }) => {
    return (
        <div
            className={`inbox_listitem__label inbox_listitem__label--level-${level}`}
            onClick={onClick}
            onContextMenu={onContextMenu}
        >
            {!hideIcon &&
                <Icon
                    type={icon}
                    variant='xs'
                    wrapperClassAdditions={`icon-wrapper--${onlineStatus?.toLowerCase()}`}
                />
            }
            <span className={unreadCount || markedAsUnread ? 'highlighted' : ''}>
                {label}
            </span>
            {((!!unreadCount && isSubscribed) || markedAsUnread) &&
                <Counter count={unreadCount || undefined} isInline />
            }
        </div>
    )
}

export const InboxItemLink = ({ relativeUrl, ...labelProps }) => (
    <Link to={relativeUrl}>
        <InboxItemLabel {...labelProps} />
    </Link>
)

export default InboxItemLink
