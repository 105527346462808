import { gql } from '@apollo/client'
import { CONTACT_FRAGMENT, CONTACT_LIST_FRAGMENT } from './fragments'
import { CHANGE_LOG_FRAGMENT } from './../fragments'
import { CALL_FRAGMENT } from '../calls/fragments'
import { OWNER_CONTRACT_FRAGMENT } from '../properties/fragments'

export const GET_USER = gql`
    query GET_USER($id: ID!) {
        user(id: $id) {
            ...AllUserFields
        }
    }
    ${CONTACT_FRAGMENT}
`

export const GET_CONTACTS = gql`
    query GET_CONTACTS(
        $offset: Int, $limit: Int, $searchTerm: String, $groupBy: String, $groupDir: Int, $sortBy: String, $sortDir: Int, $filters: String
    ) {
        users(offset: $offset, limit: $limit, searchTerm: $searchTerm, groupBy: $groupBy, groupDir: $groupDir, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            ...ContactListerFields
            hasMore
        }
    }
    ${CONTACT_LIST_FRAGMENT}
`

export const GET_USER_ACTIVITY = gql`
    query GET_USER_ACTIVITY($userId: ID!, $offset: Int, $limit: Int) {
        userActivity(userId: $userId, offset: $offset, limit: $limit) {
            ...ChangeLogFields
            contentName
            objectId
            hasMore
        }
    }
    ${CHANGE_LOG_FRAGMENT}
`

export const GET_USER_CALLS = gql`
    query GET_USER_CALLS($userId: ID!, $offset: Int, $limit: Int, $searchTerm: String, $groupBy: String, $groupDir: Int, $sortBy: String, $sortDir: Int, $filters: String) {
        userCalls(userId: $userId, offset: $offset, limit: $limit, searchTerm: $searchTerm, groupBy: $groupBy, groupDir: $groupDir, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            ...CallFields
            hasMore
        }
    }
    ${CALL_FRAGMENT}
`

export const GET_UNITS = gql`
    query GET_UNITS {
        units {
            id
            longName
            fullUnitName
            monthlyBasicRent
            monthlyServiceCost
            deposit
        }
    }
`

export const GET_CITIES = gql`
    query GET_CITIES {
        cities {
            id
            name
        }
    }
`

export const GET_PROPERTIES = gql`
    query GET_PROPERTIES {
        properties {
            id
            streetWithHouseNumber
            longName
            monthlyServiceCost
            otherCosts
        }
    }
`

export const GET_COMPANIES = gql`
    query GET_COMPANIES {
        companies {
            id
            name
        }
    }
`

export const GET_GUEST_GROUP = gql`
    query GET_GUEST_GROUP {
        guestGroup {
            id
        }
    }
`

export const GET_ATTACHMENTS_FOR_USER = gql`
    query GET_ATTACHMENTS_FOR_USER($profileId: ID!, $offset: Int, $limit: Int, $searchTerm: String, $groupBy: String, $groupDir: Int, $sortBy: String, $sortDir: Int, $filters: String) {
        attachments(profileId: $profileId, offset: $offset, limit: $limit, searchTerm: $searchTerm, groupBy: $groupBy, groupDir: $groupDir, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            id
            file
            originalFilename
            url
            dateChanged
            dateChangedLabel
            uploadedBy {
                id
                fullName
                picture
            }
            contentType {
                model
            }
            objectId
            relatedObjectLabel
            relatedObjectArchived
            dateCreated
            dateCreatedLabel
            hasMore
            taggedMessageId
            category {
                id
                name
                userGroups {
                    id
                    name
                }
            }
        }
    }
`

export const GET_PROPERTIES_FOR_LANDLORD = gql`
    query GET_PROPERTIES_FOR_LANDLORD($landlordId: ID!) {
        ownedProperties(landlordId: $landlordId) {
            id
            streetWithHouseNumber
        }
        ownedBuildings(landlordId: $landlordId) {
            id
            name
        }
    }
`

export const GET_OWNER_CONTRACTS = gql`
    query GET_OWNER_CONTRACTS($ownerId: ID!) {
        landlord(id: $ownerId) {
            contracts {
                ...OwnerContractFields
            }
        }
    }
    ${OWNER_CONTRACT_FRAGMENT}
`

export const GET_PROFILE_USER_ID = gql`
    query GET_PROFILE_USER_ID($profileId: ID!) {
        profileUserId(profileId: $profileId)
    }
`

export const GET_BANK_ACCOUNT_BY_USER_ID = gql`
    query GET_BANK_ACCOUNT_BY_USER_ID($userId: ID!) {
        bankAccountByUserId(userId: $userId) {
            id
            accountNumber
            accountHolderName
            accountHolderAddress
            bankName
            bankAddress
            bicCode
        }
    }
`

export const GET_VIEWABLE_ATTACHMENTS_FOR_GUEST_PER_CATEGORY = gql`
    query GET_VIEWABLE_ATTACHMENTS_FOR_GUEST_PER_CATEGORY($userId: ID) {
        viewableAttachmentsForGuestPerCategory(userId: $userId) {
            categoryName
            fileName
            fileUrl
        }
    }
`
