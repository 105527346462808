import { useEffect, useContext } from 'react'
import { useQuery, useLazyQuery } from '@apollo/client'
import { BUILDING_FRAGMENT } from '../fragments'
import { GET_BUILDING } from '../queries'
import { GET_PINNED_NOTES_FOR_CONTENT_OBJECTS } from '../../notes/queries'
import { TabNavContext } from '../../components/TabNav'
import { Label } from '../../components'
import { lbl, tabNavLinks } from '../../constants'
import PropertySidePanel from './PropertySidePanel'
import PropertyDetailNav from './PropertyDetailNav'
import PropertyDetailRouter from './PropertyDetailRouter'
import PinnedNotePreview from '../../notes/components/PinnedNotePreview'


const BuildingDetail = () => {
    const { id, activeSubtab, pinnedTabs, openTab } = useContext(TabNavContext)
    const [getPinnedNotes, { data: pinnedNotesData }] = useLazyQuery(GET_PINNED_NOTES_FOR_CONTENT_OBJECTS)
    const { loading, error, data, called } = useQuery(GET_BUILDING, {
        variables: { id },
        fetchPolicy: 'cache-first',
        onCompleted: ({ building }) => {
            getPinnedNotes({
                variables: { contentObjects: [
                    { contentType: 'building', objectIds: [id] },
                    { contentType: 'user', objectIds: building?.owner?.user?.id ? [building.owner.user.id] : [] },
                ] }
            })
        }
    })

    const relativeUrl = tabNavLinks.properties.building.dynamicPath + id
    const isPinned = pinnedTabs.get(relativeUrl)
    const name = data?.building?.name
    const isLister = ['tickets', 'tasks', 'attachments', 'calls'].includes(activeSubtab)

    useEffect(() => {
        called && name && !isPinned && openTab({ id, name, relativeUrl, icon: 'building' })
    }, [called, name, id, isPinned])

    if (loading) return <div className='loader pos-center-y' />
    if (error || !data?.building) return <p>Error :(</p>
    
    const { building } = data

    return (
        <div className='building-detail split-panel'>
            <section className='split-panel--main-dual intersection-root'>
                <div className='split-panel--main-top'>
                    {building.archived &&
                        <div>
                            <Label
                                classModifier='grey'
                                className='label--uppercase label--archived'
                                iconProps={{ type: 'archive', variant: '3xs' }}
                                label={lbl.archived}
                            />
                        </div>
                    }
                    <div className='d-inline-flex mb-1'>
                        <h1 className='t-heading t-2xl mr-2'>
                            {building.name}
                        </h1>
                    </div>
                    <div>
                        {building.city.name}
                    </div>
                    <div>
                        {pinnedNotesData?.notesForContentObjects?.map(note => (
                            <PinnedNotePreview
                                key={`${note.pinned ? 'pinned-' : ''}note-${note.id}`}
                                note={note}
                                path={`${tabNavLinks.properties.building.dynamicPath}${building.id}`}
                            />
                        ))}
                    </div>
                    <br/>
                </div>
                <div className='split-panel--main-nav'>
                    <PropertyDetailNav
                        urlRoot={tabNavLinks.properties.building.dynamicPath + building.id}
                        contentType='building'
                    />
                </div>
                <div className={`split-panel--main-bottom${isLister ? ' lister' : ''}`}>
                    <PropertyDetailRouter
                        urlRoot={tabNavLinks.properties.building.dynamicPath + building.id}
                        objectId={building.id}
                        contentType='building'
                        building={building}
                        addresses={building.properties}
                        title={building.name}
                        owner={building.owner}
                        archived={building.archived}
                    />
                </div>
            </section>

            <PropertySidePanel
                type='building'
                id={id}
                image={building.picture}
                contacts={[
                    { label: lbl.owner, type: 'landlords', user: building.owner?.user },
                    { label: lbl.caretaker, type: 'partners', user: building.caretaker?.user, organisation: building.caretaker?.company?.name },
                    { label: lbl.hoaManager, type: 'partners', user: building.homeownerAssociation?.vveManager?.user, organisation: building.homeownerAssociation?.name }
                ].filter(({ user }) => !!user)}
                properties={building.properties}
                fragment={BUILDING_FRAGMENT}
                isArchived={building.archived}
            />
        </div>
    )
}

export default BuildingDetail
