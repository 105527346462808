import { GET_ONLINE_STATUS_OPTIONS, GET_EMPLOYMENT_STATUS_OPTIONS, GET_TENANT_STATUS_OPTIONS, GET_USER_GROUPS, GET_USER_TYPE_OPTIONS } from '../queries'
import { GET_CITIES, GET_PROPERTIES, GET_UNITS } from './queries'
import { lbl, employmentStatuses, tenantStatusOptions, userTypeOptions } from '../constants'
import { toTitleCase } from '../utils'

export const userProperties = {
    fullName: {
        field: 'fullName',
        type: 'string',
        label: lbl.name,
        sortPath: 'fullName'
    },
    picture: {
        field: 'picture',
        type: 'string',
        label: 'Picture',
    },
    type: {
        field: 'type',
        type: 'string',
        sortPath: 'type',
        groupPath: 'type',
        searchPath: 'type',
        label: 'User type',
        optionsQuery: { query: GET_USER_TYPE_OPTIONS, name: 'userTypeOptions' },
        getOptionProps: r => typeof r === 'string' ? userTypeOptions[r] : r
    },
    'profile.onlineStatus': {
        field: 'onlineStatus',
        type: 'string',
        sortPath: 'profile.onlineStatus',
        sortDir: -1,
        groupPath: 'profile.onlineStatus',
        label: 'Online status',
        optionsQuery: { query: GET_ONLINE_STATUS_OPTIONS, name: 'onlineStatusOptions' },
        getOptionProps: root => ({ id: root, label: toTitleCase(root) })
    },
    'profile.employmentStatus': {
        field: 'employmentStatus',
        type: 'string',
        sortPath: 'profile.employmentStatus',
        groupPath: 'profile.employmentStatus',
        searchPath: 'profile.employmentStatus',
        label: 'Employment',
        optionsQuery: { query: GET_EMPLOYMENT_STATUS_OPTIONS, name: 'employmentStatusOptions' },
        getOptionProps: r => typeof r === 'string' ? employmentStatuses[r] : r
    },
    tenancyStatus: {
        field: 'tenancyStatus',
        type: 'string',
        label: 'Tenancy status',
        sortPath: 'tenancyStatus',
        optionsQuery: { query: GET_TENANT_STATUS_OPTIONS, name: 'tenantStatusOptions' },
        getOptionProps: r => typeof r === 'string' ? tenantStatusOptions[r] : r
    },
    city: {
        field: 'city',
        type: 'string',
        label: 'City',
        sortPath: 'city',
        optionsQuery: { query: GET_CITIES, name: 'cities' },
        getOptionProps: root => typeof root === 'string' ? ({ id: root, label: root }) : ({ ...root, label: root.name, id: root.name })
    },
    address: {
        field: 'address',
        type: 'string',
        label: 'Address',
        sortPath: 'address',
        optionsQuery: { query: GET_PROPERTIES, name: 'properties' },
        getOptionProps: root => typeof root === 'string' ? ({ id: root, label: root }) : ({ ...root, label: root.streetWithHouseNumber, id: root.streetWithHouseNumber })
    },
    unit: {
        field: 'unit',
        type: 'string',
        groupPath: 'unit',
        label: 'Unit',
        sortPath: 'unit',
        optionsQuery: { query: GET_UNITS, name: 'units' },
        getOptionProps: root => typeof root === 'string' ? ({ id: root, label: root }) : ({ ...root, label: root.fullUnitName, id: root.fullUnitName })
    },
    rentResponsabilityEndDate: {
        field: 'rentResponsabilityEndDate',
        type: 'string',
        label: 'Tenancy end',
        sortPath: 'rentResponsabilityEndDate',
        getOptionProps: root => ({ id: root, label: root })
    }
}

export const listerCtrlOptions = {
    filter: ['type', 'tenancyStatus', 'city', 'address'].map(key => userProperties[key]),
    group: ['type', 'profile.onlineStatus', 'tenancyStatus', 'city', 'address', 'unit'].map(key => userProperties[key]),
    sort: ['fullName', 'type', 'profile.onlineStatus', 'tenancyStatus', 'city', 'address', 'unit', 'rentResponsabilityEndDate'].map(key => userProperties[key]),
    search: ['fullName', 'type', 'profile.onlineStatus', 'tenancyStatus', 'city', 'address', 'unit', 'rentResponsabilityEndDate'].map(key => userProperties[key])
}

export const listerColumns = ['picture', 'fullName', 'type', 'tenancyStatus', 'address', 'unit', 'city', 'rentResponsabilityEndDate', 'profile.onlineStatus']
