import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useMutation, useReactiveVar } from '@apollo/client'
import { twilioDeviceVar } from '../../cache'
import { RoundIconButton, Label, Popover, ProfileImage } from '../../components'
import { callStatusOptions, tabNavLinks } from '../../constants'
import { numberToHHMMSS } from '../../utils'
import { SearchSelect } from '../../forms'
import { GET_EMPLOYEES, GET_DEPARTMENTS } from '../../queries'
import { TRANSFER_CALL_TO_DEPARTMENT, TRANSFER_CALL_TO_EMPLOYEE } from '../mutations'


const OnCall = ({ handleHangup, callSid, status='initiated' }) => {
    const { conn } = useReactiveVar(twilioDeviceVar)
    const [duration, setDuration] = useState(0)
    const [isMuted, setMuted] = useState(conn.isMuted())
    const toggleMute = () => { conn.mute(!isMuted) }
    const [transfer, setTransfer] = useState('')
    const [transferCallToDepartment] = useMutation(TRANSFER_CALL_TO_DEPARTMENT)
    const [transferCallToEmployee] = useMutation(TRANSFER_CALL_TO_EMPLOYEE)
    let timer = useRef()

    useEffect(() => {
        if (status === 'in-progress') {
            timer.current = setInterval(() => {
                setDuration(prev => prev += 1)
            }, 1000)
        }
        if (status === 'completed') {
            clearInterval(timer.current)
        }
        return () => clearInterval(timer.current)
    }, [status])

    useEffect(() => {
        conn.on('mute', (muted) => { setMuted(muted) })
    }, [])

    return (
        <>
            {status === 'in-progress' ?
                numberToHHMMSS(duration)
            : callStatusOptions[status] ?
                <Label
                    classModifier={callStatusOptions[status].color}
                    label={callStatusOptions[status].label}
                    iconProps={{ type: callStatusOptions[status].icon }}
                />
            : status}
            <div className='mt-4 mb-1'>
                <div className='call__btns'>
                    <RoundIconButton
                        onClick={toggleMute}
                        icon='microphone-off'
                        color={isMuted ? 'yellow' : 'grey'}
                        title={isMuted ? 'unmute' : 'mute'}
                    />
                    <RoundIconButton
                        icon='call-hangup'
                        color='red'
                        onClick={handleHangup}
                    />
                    {isMuted && <p className='t-xs t-yellow'>You are muted</p>}
                </div>
            </div>
            <div className='call__footer'>
                {status !== 'initiated' &&
                    <Link
                        to={tabNavLinks.calls.dynamicPath + callSid}
                        className='d-block t-center btn t-underline-hover'
                    >
                        Add Call Info
                    </Link>
                }
                {status === 'in-progress' && conn?._direction !== 'OUTGOING' &&
                    <>
                        <button
                            onClick={() => setTransfer('department')}
                            className='d-block t-center btn t-underline-hover'
                        >
                            Transfer to Department
                        </button>
                        <button
                            onClick={() => setTransfer('employee')}
                            className='d-block t-center btn t-underline-hover'
                        >
                            Transfer to Employee
                        </button>
                    </>
                }
            </div>
            <Popover
                isOpen={!!transfer}
                onClose={() => setTransfer('')}
                className='popover--w t-left'
                >
                {transfer === 'department' &&
                    <SearchSelect
                        optionsQuery={{ query: GET_DEPARTMENTS, name: 'departments' }}
                        searchBy='name'
                        valueField='id'
                        placeholder='Select department...'
                        onDropdownClose={() => setTransfer('')}
                        getLabelNode={({ item: { name, icon } }) => (<>{icon} {name}</>)}
                        onSelect={([department]) => {
                            transferCallToDepartment({ variables: {
                                callSid,
                                departmentId: department.id
                            }})
                        }}
                        showLoader
                        isHidden

                    />
                }
                {transfer === 'employee' &&
                    <SearchSelect
                        optionsQuery={{ query: GET_EMPLOYEES, name: 'employees' }}
                        searchBy='fullName'
                        valueField='id'
                        placeholder='Select employee...'
                        onDropdownClose={() => setTransfer('')}
                        getLabelNode={({ item: { fullName, picture } }) => (<>
                            <ProfileImage url={picture} size='xs' />
                            {fullName}
                        </>)}
                        onSelect={([employee]) => {
                            transferCallToEmployee({ variables: {
                                callSid,
                                employeeUserId: employee.id,
                                employeeUsername: employee.username
                            }})
                        }}
                        showLoader
                        isHidden
                    />
                }
            </Popover>
        </>
    )
}

export default OnCall
