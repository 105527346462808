import { useState, useEffect } from 'react'
import { useMutation, useLazyQuery } from '@apollo/client'
import { RESET_QUERY } from '../../queries'
import { ARCHIVE_TASKS, ACTIVATE_TASKS, UPDATE_TASKS } from '../mutations'
import { batchActions } from '../settings'
import { getProp } from '../../utils'
import { lbl } from '../../constants'
import { Icon, ProfileImage } from '../../components'
import { HiddenSearchSelect, SelectOption } from '../../forms'


const TaskBatchActions = ({
    tasks,
    resetSelection,
    refetchGroupedTasks,
    optionsDict
}) => {
    const [activePopUp, setActivePopUp] = useState('')
    const taskIds = Array.from(tasks.keys())
    const [getOptions, { data }] = useLazyQuery(getProp('optionsQuery.query', optionsDict[activePopUp] || {}) || RESET_QUERY)
    const getOptionsFromData = () => data[optionsDict[activePopUp].optionsQuery.name].map(option => ({
        field: activePopUp,
        ...optionsDict[activePopUp].getOptionProps(option),
    }))
    const actions = Array.from(tasks.values()).some(task => task.archived) ? ['activate'] : batchActions

    useEffect(() => {
        if (activePopUp && !['archive', 'activate'].includes(activePopUp)) {
            getOptions()
        }
    }, [activePopUp, getOptions])

    const [archiveTasks] = useMutation(ARCHIVE_TASKS, {
        variables: { ids: taskIds },
        onCompleted: () => {
            // refetchGroupedTasks()
            resetSelection()
        },
        update(cache, { data: { archiveTasks: { tasks } } }) {
            // task listers will be updated via ON_NEW_CHANGE subscription
            tasks.forEach(({ id, ticket }) => {
                ticket && !ticket.archived && cache.modify({
                    id: cache.identify({ __typename: 'TicketType', id: ticket.id }),
                    fields: {
                        tasks(existingTasks = [], { readField }) {
                            return existingTasks.filter(ticketTask => readField('id', ticketTask) !== id)
                        }
                    }
                })
            })
        }
    })

    const [activateTasks] = useMutation(ACTIVATE_TASKS, {
        variables: { ids: taskIds },
        onCompleted: () => {
            // refetchGroupedTasks()
            resetSelection()
        },
        update(cache, { data: { activateTasks: { tasks } } }) {
            // task listers will be updated via ON_NEW_CHANGE subscription
            tasks.forEach((task) => {
                task.ticket && cache.modify({
                    id: cache.identify({ __typename: 'TicketType', id: task.ticket.id }),
                    fields: {
                        tasks(existingTasks = [], { toReference }) {
                            return [toReference(task), ...existingTasks]
                        }
                    }
                })
            })
        }
    })

    const [updateTasks] = useMutation(UPDATE_TASKS, {
        onCompleted: () => {
            refetchGroupedTasks()
            resetSelection()
        }
    })
    
    return (
        <>
            {!activePopUp &&
                <div className='pop-up pop-up--block pop-up--top pop-up--center pop-up--primary'>
                    <div className='pop-up__container'>
                        <div className='pop-up__btns'>
                            {actions.map((action, i) => (
                                <button
                                    key={action}
                                    className='btn'
                                    disabled={action === 'merge' && tasks.size < 2}
                                    onClick={() => setActivePopUp(action)}
                                >
                                    <Icon type={action === 'status' ? 'check-status' : action} variant='sm' />
                                    {i < 2 ? lbl[action] : optionsDict[action].actionLabel || optionsDict[action].label}
                                </button>
                            ))}
                        </div>
                        <button
                            className='btn btn--naked close close--xs'
                            onClick={resetSelection}
                        >
                            <Icon type='close' size={40} variant='2xs' />
                        </button>
                    </div>
                </div>
            }

            {activePopUp === 'archive' ?
                <div className='pop-up pop-up--block pop-up--top pop-up--center'>
                    <div className='pop-up__container'>
                        <header className='pop-up__header'>
                            Confirm archive
                        </header>
                        <div className='pop-up__message t-s'>
                            <p>Are you sure you want to archive following tasks?</p>
                            <ul className='mb-2'>
                                {Array.from(tasks.values()).map(({ name }, i) => <li key={name+i}>• <strong>{name}</strong></li>)}
                            </ul>
                            <p className='mb-1'>This action will remove them from the list.</p>
                        </div>
                        <div className='pop-up__btns'>
                            <button className='btn btn--secondary mr-2' onClick={resetSelection}>
                                {lbl.cancel}
                            </button>
                            <button
                                className='btn btn--primary'
                                onClick={archiveTasks}
                            >
                                {lbl.archive}
                            </button>
                        </div>
                        <button
                            className='btn btn--naked close'
                            onClick={resetSelection}
                        >
                            <Icon type='close' variant='xs' />
                        </button>
                    </div>
                </div>
            : activePopUp === 'activate' ?
                <div className='pop-up pop-up--block pop-up--top pop-up--center'>
                    <div className='pop-up__container'>
                        <header className='pop-up__header'>
                            Restore from archive
                        </header>
                        <div className='pop-up__message t-s'>
                            <p>Are you sure you want to restore following tasks from archive?</p>
                            <ul className='mb-2'>
                                {Array.from(tasks.values()).map(({ name, archived }, i) => archived ? <li key={name+i}>• <strong>{name}</strong></li> : null)}
                            </ul>
                        </div>
                        <div className='pop-up__btns'>
                            <button className='btn btn--secondary mr-2' onClick={resetSelection}>
                                {lbl.cancel}
                            </button>
                            <button
                                className='btn btn--primary'
                                onClick={activateTasks}
                            >
                                {lbl.activate}
                            </button>
                        </div>
                        <button
                            className='btn btn--naked close'
                            onClick={resetSelection}
                        >
                            <Icon type='close'  variant='xs' />
                        </button>
                    </div>
                </div>
            : activePopUp && data &&
                <HiddenSearchSelect
                    key={activePopUp}
                    options={getOptionsFromData()}
                    onChange={([{ field, id }]) => {
                        const propertyKey = optionsDict[field].type === 'object' ? field + 'Id' : field
                        updateTasks({ variables: { ids: taskIds, [propertyKey]: id } })
                    }}
                    valueField='id'
                    searchBy='label'
                    placeholder={`Select ${optionsDict[activePopUp].label.toLowerCase()}...`}
                    closeOnSelect
                    onDropdownClose={resetSelection}
                    contentRenderer={undefined}
                    itemRenderer={(props) => (
                        <SelectOption
                            {...props}
                            getLabel={({ item: { picture, label, name, iconProps, field } }) => (
                                <>
                                    {picture && <ProfileImage url={picture} size='xs' />}
                                    {!picture && iconProps && <Icon variant='xs' {...iconProps} />}
                                    <span>{field === 'pipelineStage' ? name : label}</span>
                                </>
                            )}
                        />
                    )}
                />
            }
        </>
    )
}

export default TaskBatchActions
