import { useEffect, useContext, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { GET_PINNED_NOTES } from '../../notes/queries'
import { GET_TASK } from '../queries'
import { UPDATE_TASK } from '../mutations'
import { getProp } from '../../utils'
import { lbl, tabNavLinks, contentTypes } from '../../constants'
import { TabNavContext } from '../../components/TabNav'
import TextEditor from '../../components/TextEditor'
import { TextField } from '../../forms'
import { Label, Icon } from '../../components'
import TaskProperties from './TaskProperties'
import TaskActions from './TaskActions'
import TaskCreator from './TaskCreator'
import Attachments from './TaskAttachmentsPreview'
import TaskDetailNav from './TaskDetailNav'
import TaskDetailRouter from './TaskDetailRouter'
import PinnedNotePreview from '../../notes/components/PinnedNotePreview'

const TaskDetail = () => {
    const { id, activeSubtab, pinnedTabs, openTab, renameTab } = useContext(TabNavContext)
    const [showEditor, setShowEditor] = useState(false)
    const [body, setBody] = useState('')
    const { loading, error, data, called } = useQuery(GET_TASK, {
        variables: { id },
        onCompleted: ({ task }) => {
            !body && setBody(task.description)
        }
    })
    const [updateTask] = useMutation(UPDATE_TASK)
    const { data: pinnedNotesData } = useQuery(GET_PINNED_NOTES, { 
        variables: { contentType: contentTypes.TASK, objectId: id }
    })
    const name = getProp('task.name', data)
    const relativeUrl = tabNavLinks.tasks.dynamicPath + id
    const isPinned = pinnedTabs.get(relativeUrl)
    const isEditable = data?.task && !data.task.archived && !data.task.deleted
    const isLister = activeSubtab === 'attachments'

    useEffect(() => {
        called && name && !isPinned && openTab({ id, name, relativeUrl, icon: 'task' })
    }, [called, name, id, isPinned])

    if (loading) return <div className='loader pos-center-y' />
    if (error) return (
        <div className='t-grey w-100 pos-center-y'>
            <center>{error.message}</center>
        </div>
    )

    return (
        <div
            className='split-panel'
            onMouseDown={showEditor ? () => {
                setShowEditor(false)
            } : undefined}
        >
            <section className='split-panel--main-dual intersection-root'>
                <div className='split-panel--main-top'>
                    {data.task.deleted ?
                        <Label
                            classModifier='grey'
                            className='label--uppercase label--archived'
                            iconProps={{ type: 'bin', variant: '3xs' }}
                            label={lbl.deleted}
                        />
                    : data.task.archived ?
                        <Label
                            classModifier='grey'
                            className='label--uppercase label--archived'
                            iconProps={{ type: 'archive', variant: '3xs' }}
                            label={lbl.archived}
                        />
                    : null}
                    <TextField
                        onSubmit={async value => {
                            await updateTask({ variables: { id, name: value } })
                            renameTab({ id, name: value, relativeUrl })
                        }}
                        inputProps={{
                            type: 'input',
                            name: 'name',
                            value: data.task.name,
                            role: 'textbox',
                            contentEditable: isEditable,
                            placeholder: lbl.taskTitle
                        }}
                        size='2xl'
                        as='div'
                        innerHtml={data.task.name}
                        hidePencil={!isEditable}
                    />
                    <div>
                        {pinnedNotesData?.notesForContentObject?.map(note => (
                            <PinnedNotePreview
                                key={`${note.pinned ? 'pinned-' : ''}note-${note.id}`}
                                note={note}
                                path={`${tabNavLinks.tasks.dynamicPath}${id}`}
                            />
                        ))}
                    </div>
                    <div className='w-100' onMouseDown={e => e.stopPropagation()}>
                        {!showEditor ?
                            <div onClick={isEditable ? e => setShowEditor(true) : undefined}>
                                <br/>
                                <TextField
                                    inputProps={{
                                        type: 'input',
                                        name: 'description',
                                        value: data.task.description,
                                        role: 'textbox',
                                        contentEditable: false,
                                        placeholder: lbl.addDetails
                                    }}
                                    size='md'
                                    as='div'
                                    innerContent='innerHTML'
                                    innerHtml={data.task.description}
                                    hidePencil={!isEditable}
                                />
                            </div>
                        :
                            <div className='task__editor bg-lightblue'>
                                <TextEditor
                                    model={body}
                                    onModelChange={setBody}
                                    config={{
                                        placeholderText: lbl.addDetails,
                                        toolbarButtons: ['bold', 'italic', 'underline', 'backgroundColor', '|', 'formatOL', 'formatUL', 'outdent', 'indent', 'align', '|', 'insertTemplate', 'insertLink', 'emoticons', '|', 'selectAll', 'clearFormatting', 'undo', 'redo', '|', 'saveChanges', 'cancelEditing'],
                                    }}
                                    immediateReactModelUpdate
                                    focusOnMount
                                    saveCommand='saveChanges'
                                    onSave={async () => {
                                        await updateTask({ variables: { id, description: body } })
                                        setShowEditor(false)
                                    }}
                                    onCancel={() => {
                                        setBody(data.task.description)
                                        setShowEditor(false)
                                    }}
                                />
                            </div>
                        }
                        <br/>
                    </div>
                </div>
                
                <div className='split-panel--main-nav'>
                    <TaskDetailNav taskId={id} />
                </div>

                <div className={`split-panel--main-bottom${isLister ? ' lister' : ''}`}>
                    <TaskDetailRouter taskId={id} isEditable={isEditable} />
                </div>
            </section>
            <aside className='split-panel--side split-panel--right'>
                <div className='split-panel--side-inner'>
                    <TaskProperties task={data.task} updateTask={updateTask} />
                    <hr/>
                    <TaskActions {...data.task} />
                    <hr/>
                    {Boolean(data.task?.attachments?.length) &&
                        <>
                            <Attachments attachments={data.task.attachments}/>
                            <hr/>
                        </>
                    }
                    <TaskCreator
                        {...data.task.createdBy }
                        createdDateLabel={data.task.createdDateLabel}
                        createdDateOffset={data.task.createdDateOffset}
                    />
                </div>
            </aside>
        </div>
    )
}

export default TaskDetail
