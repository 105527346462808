import { useQuery, useReactiveVar } from '@apollo/client'
import { propertyCallListCtrlsVar } from '../../cache'
import { useState } from 'react'
import { Icon } from '../../components'
import CallsList from '../../calls/components/CallsList'
import { GET_PROPERTY_CALLS } from '../../calls/queries'
import { listerCtrlOptions } from '../../calls/settings'
import { columns } from '../../contacts/components/UserCalls'


const PropertyCalls = ({ contentType, addresses=[], units=[] }) => {
    const [includeUnitCalls, setUnitCalls] = useState(contentType === 'room')
    const propertyIds = contentType === 'room' ? [] : addresses.map(({ id }) => id)
    const unitIds = includeUnitCalls ? units.map(({ id }) => id) : []
    const vars = useReactiveVar(propertyCallListCtrlsVar)
    const filters = vars.filters.map(f => `${f.field}___${f.id}`).sort().join(',')
    const { data, error, loading, fetchMore } = useQuery(GET_PROPERTY_CALLS, { variables: { 
        propertyIds,
        unitIds,
        ...vars,
        filters
    } })

    const renderContentTypeCtrls = () => (
        <li className='ctrls__listitem ml-auto'>
            <button
                disabled={true}
                className={`btn btn--naked btn--icon ml-4 mr-05 br-05${contentType !== 'room' ? ' active' : ''}`}
                onClick={() => {}}
            >
                <Icon type='address' variant='sm'/>
            </button>
            <button
                disabled={includeUnitCalls || !units.length}
                className={`btn btn--naked btn--icon br-05${includeUnitCalls ? ' active' : ''}`}
                onClick={() => setUnitCalls((prev) => !prev)}
            >
                <Icon type='room' variant='sm'/>
            </button>
        </li>
    )

    return (
        <>
            {error && <div className='pos-center-y w-100 t-center'>{error.message}</div>}
            <CallsList
                calls={data?.propertyCalls}
                controlsDisabled={loading}
                showLoader={loading && !data?.propertyCalls}
                columnKeys={columns}
                listCtrlVar={propertyCallListCtrlsVar}
                query={GET_PROPERTY_CALLS}
                queryFieldName='propertyCalls'
                queryVariables={{ propertyIds, unitIds }}
                fetchMore={fetchMore}
                renderCtrlChildren={contentType !== 'room' ? renderContentTypeCtrls : undefined}
                ctrlOptions={{ ...listerCtrlOptions, sort: undefined }}
            />
        </>
    )
}

export default PropertyCalls
