import { useMutation, gql } from '@apollo/client'
import { clearLocalStorageAndCookies } from '../utils'
import { useHistory } from 'react-router-dom'
import { isLoggedInVar, twilioDeviceVar } from '../cache';

const LOGOUT = gql`
    mutation LOGOUT {
        logout {
            ok
        }
    }
`

const LogOut = () => {
    let history = useHistory()
    const [logout, { client }] = useMutation(LOGOUT,  {
        onError: () => {
            clearLocalStorageAndCookies()
            isLoggedInVar(false)
            history.replace('/login')
        },
        onCompleted: () => {
            const { device } = twilioDeviceVar()
            device && device.destroy()
            clearLocalStorageAndCookies()
            client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'me' })
            client.cache.gc()
            client.resetStore()
            isLoggedInVar(false)
            history.replace('/login')
        }
    })

    return (
        <button
            className='btn btn--naked btn--logout'
            onClick={e => {
                e.stopPropagation()
                logout()
            }}
        >
            Logout
        </button>
    )
}

export default LogOut
