import { gql } from '@apollo/client'

export const TEMPLATE_FRAGMENT = gql`
    fragment TemplateFields on TemplateType {
        id
        name
        body
        archived
        createdBy {
            id
            fullName
            picture
        }
        lastModifiedBy {
            id
            fullName
            picture
        }
        createdAt
        lastModified
        dateCreatedLabel
        dateUpdatedLabel
    }
`