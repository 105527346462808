import { gql } from '@apollo/client'
import { NOTE_FRAGMENT } from './fragments'

export const GET_NOTES_FOR_INSTANCE = gql`
    query GET_NOTES_FOR_INSTANCE($contentType: String!, $objectId: ID!) {
        notesForContentObject(contentType: $contentType, objectId: $objectId) {
            ...NoteFields
            tags {
                id
                label
                color
            }
        }
    }
    ${NOTE_FRAGMENT}
`

export const GET_NOTES_FOR_INSTANCES = gql`
    query GET_NOTES_FOR_INSTANCES($contentObjects: [ContentObjectType]!) {
        notesForContentObjects(contentObjects: $contentObjects) {
            ...NoteFields
            annotations {
                id
                objectId
                contentType {
                    model
                }
                label
            }
            tags {
                id
                label
                color
            }
        }
    }
    ${NOTE_FRAGMENT}
`

export const GET_PINNED_NOTES = gql`
    query GET_PINNED_NOTES($contentType: String!, $objectId: ID!) {
        notesForContentObject(contentType: $contentType, objectId: $objectId, pinnedOnly: true) {
            ...NoteFields
        }
    }
    ${NOTE_FRAGMENT}
`

export const GET_PINNED_NOTES_FOR_CONTENT_OBJECTS = gql`
    query GET_PINNED_NOTES_FOR_CONTENT_OBJECTS($contentObjects: [ContentObjectType]!) {
        notesForContentObjects(contentObjects: $contentObjects, pinnedOnly: true) {
            ...NoteFields
        }
    }
    ${NOTE_FRAGMENT}
`

export const GET_NOTE = gql`
    query GET_NOTE($id: ID!) {
        note(id: $id) {
            ...NoteFields
            tags {
                id
                label
                color
            }
        }
    }
    ${NOTE_FRAGMENT}
`

export const GET_NOTE_TAGS = gql`
    query GET_NOTE_TAGS {
        noteTags {
            id
            label
            color
        }
    }
`
