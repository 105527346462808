import { Switch, Route, Redirect } from 'react-router-dom'
import UserDetailForms from './UserDetailForms'
import UserThreads from './UserThreads'
import UserCalls from './UserCalls'
import UserTasks from './UserTasks'
import UserTickets from './UserTickets'
import UserActivity from './UserActivity'
import UserAttachments from './UserAttachments'
import TenantContracts from './TenantContracts'
import OwnerContracts from './OwnerContracts'
import NotesFeed from '../../notes/components/NotesFeed'
import { tabNavLinks } from '../../constants'
import RedirectToLastSubtab from '../../components/RedirectToLastSubtab'
import TenantLeads from './TenantLeads'

const ContactDetailRouter = ({ user }) => {
    const urlRoot = `${tabNavLinks.contacts.dynamicPath}${user.id}`

    return (
        <Switch>
            <Route path={`${urlRoot}/info`}>
                <UserDetailForms user={user} />
            </Route>
            <Route path={`${urlRoot}/activity`}>
                <UserActivity userId={user.id} />
            </Route>
            {user.type === '1_guests' &&
                <Route path={`${urlRoot}/threads`}>
                    <UserThreads userId={user.id} />
                </Route>
            }
            {user.tenant ?
                <Route path={`${urlRoot}/contracts`}>
                    <TenantContracts user={user} tenant={user.tenant} />
                </Route>
            : user.landlord ?
                <Route path={`${urlRoot}/contracts`}>
                    <OwnerContracts user={user} owner={user.landlord} />
                </Route>
            : null}
            {user.tenant &&
                <Route path={`${urlRoot}/leads`}>
                    <TenantLeads user={user} tenant={user.tenant} />
                </Route>
            }
            <Route path={`${urlRoot}/calls`}>
                <UserCalls userId={user.id} />
            </Route>
            <Route path={`${urlRoot}/tickets`}>
                <UserTickets userId={user.id} />
            </Route>
            <Route path={`${urlRoot}/tasks`}>
                <UserTasks userId={user.id} />
            </Route>
            <Route path={`${urlRoot}/notes`}>
                <NotesFeed contentType='user' objectId={user.id} />
            </Route>
            <Route path={`${urlRoot}/attachments`}>
                <UserAttachments profileId={user.profile.id} user={user} />
            </Route>
            <Route path={urlRoot}>
                <RedirectToLastSubtab urlRoot={urlRoot}/>
            </Route>
        </Switch>
    )
}

export default ContactDetailRouter
