import { useState } from 'react'
import { Icon } from '../components'


const CheckboxField = ({
    className,
    onChange,
    label,
    status,
    statusOptions,
    children,
    ...props
}) => {
    let [statusIndex, setStatusIndex] = useState(statusOptions.indexOf(status))
    
    const handleChange = () => {
        const i = (statusIndex + 1) % statusOptions.length
        setStatusIndex(i)
        onChange(statusOptions[i])
    }
    const isInitial = statusIndex === 0
    const isChecked = statusIndex === statusOptions.length - 1

    return (
        <div className={`checkbox__container${className ? ` ${className}` : ''}${isChecked ? ' checked' : ''}`}>
            <label className='checkbox__input'>
                <input
                    type='checkbox'
                    className='checkbox--hidden'
                    checked={isChecked}
                    onChange={handleChange}
                    {...props} />
                <div className='checkbox--styled'>
                    <Icon
                        variant='sm'
                        type={isChecked ? 'checkbox' : isInitial ? 'checkbox-empty' : 'checkbox-progress'}
                    />
                </div>
            </label>
            {label && <span className='checkbox__label'>{label}</span>}
            {children}
        </div>
    )
}

export default CheckboxField