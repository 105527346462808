import { useEffect, useRef } from 'react'


export const usePrevious = (value, allowFalsy=true) => {
    const ref = useRef()
    useEffect(() => {
        if (allowFalsy || value) ref.current = value
    })
    return ref.current
}

export default usePrevious