import React, { useState, useRef, useLayoutEffect, Suspense } from 'react'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import moment from 'moment'
import { GET_TICKETS } from '../../tickets/queries'
import { GET_ALL_USERS } from '../../queries'
import { UNASSIGN_TASK, REMOVE_TICKET_RELATION, REMOVE_TASK_ATTACHMENT_CATEGORY } from '../mutations'
import { lbl, priorityOptions, statusOptions, tabNavLinks } from '../../constants'
import SelectForm, { LazySelectForm, SearchSelect } from '../../forms/Select'
import { Icon, ProfileImage, Label } from '../../components'
import { GET_ATTACHMENT_CATEGORIES } from '../../attachments/queries'

const DatePicker = React.lazy(() => import('../../forms/DatePicker'))

const TaskProperties = ({ updateTask, isNew, onChange, task }) => {
    const [unassign] = useMutation(UNASSIGN_TASK)
    const [removeTicketRelation] = useMutation(REMOVE_TICKET_RELATION)
    const [removeTaskAttachmentCategory] = useMutation(REMOVE_TASK_ATTACHMENT_CATEGORY)
    const [datePickerOpened, openDatePicker] = useState(false)
    const [datePickerPosition, setDatePickerPosition] = useState({ x: 600, y: 160 })
    const datePickerAnchorEl = useRef(null)
    const [isAssigneeOpen, openAssignee] = useState(false)
    const [areTicketsOpen, openTickets] = useState(false)
    const onSelect = (vars, args, extraVars={}) => {
        if (isNew) {
            onChange({ ...vars, ...extraVars })
        } else {
            updateTask({ variables: { id: task.id, ...vars }, ...args })
        }
    }
    const isEditable = !task.archived && !task.deleted

    useLayoutEffect(() => {
        if (datePickerAnchorEl.current) {
            const { x, y } = datePickerAnchorEl.current.getBoundingClientRect()
            setDatePickerPosition({ x: x - 340, y })
        }
    }, [])

    return (
        <section id='task-properties'>
            <dl className='dl'>
                <div className='dl__li t-s'>
                    <dt>{lbl.status}</dt>
                    <dd>
                        <SelectForm
                            disabled={!isEditable}
                            onSelect={([{ value }]) => value !== task.status && onSelect({ status: value })}
                            options={Object.keys(statusOptions).map(value => ({ value, label: statusOptions[value].label }))}
                            selectedOptions={[{ label: statusOptions[task.status].label, value: task.status }]}
                            getLabelNode={({ item: { label, value } }) => (<>
                                <Icon type={statusOptions[value].icon} variant='sm' />
                                {label}
                            </>)}
                        />
                    </dd>
                </div>

                <div className='dl__li t-s'>
                    <dt>{lbl.dueDate}</dt>
                    <dd ref={datePickerAnchorEl}>
                        <button
                            className={`btn btn--naked btn--date-picker-toggle${datePickerOpened ? ' nohover' : ''}`}
                            onClick={() => openDatePicker(open => !open)}
                            disabled={!isEditable}
                        >
                            <Label
                                className='time-label my-auto'
                                iconProps={{ type: 'time' }}
                                label={task.dueDateLabel ? task.dueDateLabel : isEditable ? lbl.setDueDate : 'No due date' }
                                classModifier={task.dueDateOffset < 2 ? 'red' : task.dueDateOffset < 3 ? 'yellow' : 'grey'}
                            />
                        </button>
                        {datePickerOpened &&
                            <div
                                className='pop-up pop-up--top pop-up--left pop-up--date-picker'
                                style={{ top: datePickerPosition.y, left: datePickerPosition.x }}
                            >
                                <div className='pop-up__container'>
                                    <Suspense fallback={<div style={{ height: '296px' }} />}>
                                        <DatePicker
                                            date={!task.dueDate || task.dueDateOffset < 0 ? moment() : moment(task.dueDate, 'YYYY-MM-DD')}
                                            onChange={(date) => {
                                                onSelect({ dueDate: date.format('YYYY-MM-DD') })
                                                openDatePicker(false)
                                            }}
                                            disablePast={isNew}
                                        />
                                    </Suspense>
                                </div>
                            </div>
                        }
                    </dd>
                </div>

                <div className='dl__li t-s'>
                    <dt>{lbl.assignee}</dt>
                    <dd>
                        <button
                            className="btn btn--naked dd__values label-wrapper"
                            onClick={() => openAssignee(true)}
                            disabled={!isEditable}
                        >
                            {task.assignee ?
                                <>
                                    <ProfileImage url={task.assignee.picture} size='xs'/>
                                    {task.assignee.fullName}
                                </>
                            : isEditable ? lbl.setAssignee : lbl.noAssignee}
                        </button>
                        {task.assignee &&
                            <Link
                                className='btn btn--naked btn--inline-icon add mt-2'
                                to={tabNavLinks.contacts.dynamicPath + task.assignee.id}
                                title='Go to contact page'
                            >
                                <Icon type='arrow-right' variant='xs' style={{ transform: 'rotate(-45deg)' }}/>
                            </Link>
                        }
                        {isAssigneeOpen &&
                            <SearchSelect
                                searchable
                                isHidden
                                autoFocus
                                showLoader
                                searchBy='fullName'
                                onSelect={([option]) => {
                                    if (!isNew && option.id === 'NONE') {
                                        unassign({ variables: { id: task.id }})
                                    } else if (task.assignee?.id !== option.id) {
                                        onSelect({ assigneeId: option.id }, null, { assignee: option })
                                    }
                                }}
                                optionsQuery={{
                                    query: GET_ALL_USERS,
                                    name: 'users'
                                }}
                                labelField='fullName'
                                valueField='id'
                                keepSelectedInList={false}
                                onDropdownClose={() => openAssignee(false)}
                                selectedOptions={[task.assignee || { id: 'NONE', fullName: lbl.setAssignee }]}
                                unselectOption={task.assignee && { id: 'NONE', fullName: lbl.noAssignee }}
                                getLabelNode={({ item: { id, fullName, picture }, isSelectedContent }) => (<>
                                    {id === 'NONE' && !isSelectedContent ?
                                        <Icon type='close' variant='xs' /> :
                                        <ProfileImage url={picture} size='xs' />
                                    }
                                    {id === 'NONE' && isSelectedContent ? lbl.setAssignee : fullName}
                                </>)}
                            />
                        }
                    </dd>
                </div>

                <div className='dl__li t-s'>
                    <dt>{lbl.priority}</dt>
                    <dd>
                        <SelectForm
                            disabled={!isEditable}
                            onSelect={([{ value }]) => value !== task.priority && onSelect({ priority: value })}
                            options={Object.keys(priorityOptions).map(value => ({ value, label: priorityOptions[value].label }))}
                            selectedOptions={[{ label: priorityOptions[task.priority].label, value: task.priority }]}
                            getLabelNode={({ item: { label, value } }) => (<>
                                <Icon type={priorityOptions[value].icon} variant='sm' />
                                {label}
                            </>)}
                        />
                    </dd>
                </div>

                <div className='dl__li t-s'>
                    <dt>{lbl.approval}</dt>
                    <dd>
                        <SelectForm
                            disabled={!isEditable}
                            onSelect={([{ value }]) => value !== task.approvalRequired && onSelect({ approvalRequired: value })}
                            options={[{ value: true, label: lbl.required }, { value: false, label: lbl.notRequired }]}
                            selectedOptions={[{ label: task.approvalRequired ? lbl.required : lbl.notRequired, value: task.approvalRequired }]}
                            getLabelNode={({ item: { label, value } }) => (<>
                                <Icon type={value ? 'checkmark' : 'close'} variant='sm' fill={value ? 'green' : 'red'} />
                                {label}
                            </>)}
                        />
                    </dd>
                </div>

                <div className='dl__li t-s'>
                    <dt>{lbl.attachmentCategory}</dt>
                    <dd>
                        <LazySelectForm
                            disabled={!isEditable}
                            onSelect={isEditable ? ([option]) => {
                                if (!isNew && option.id === 'NONE') {
                                    removeTaskAttachmentCategory({ variables: { id: task.id }})
                                } else if (task.attachmentCategory?.id !== option.id) {
                                    onSelect({ attachmentCategoryId: option.id }, null, { attachmentCategory: option })
                                }
                            } : () => {}}
                            optionsQuery={{
                                query: GET_ATTACHMENT_CATEGORIES,
                                name: 'attachmentCategories'
                            }}
                            labelField='name'
                            valueField='id'
                            keepSelectedInList={false}
                            selectedOptions={[task.attachmentCategory || { id: 'NONE', name: lbl.selectAttachmentCategory }]}
                            unselectOption={(task.attachmentCategory || task.attachmentCategoryId) && { id: 'NONE', name: lbl.noCategory }}
                            getLabelNode={({ item: { id, name, userGroups }, isSelectedContent }) => (
                                <div className={`d-flex align-center ${!isSelectedContent && id !== 'NONE' ? ' w-100 flex-reverse justify-space-between' : ''}`}>
                                    {id === 'NONE' && <Icon type={isSelectedContent ? 'plus' : 'close'} variant={isSelectedContent ? 'sm' : 'xs'} />}
                                    <div className={id !== 'NONE' && isSelectedContent ? 'mr-2' : ''}>
                                        {userGroups?.map(userGroup => (
                                            <Icon key={userGroup.name} type={userGroup.name} variant={isSelectedContent ? 'xs' : 'xs'} wrapperClassAdditions='m-0' />
                                        ))}
                                    </div>
                                    <div className=''>{id === 'NONE' && isSelectedContent ? lbl.selectAttachmentCategory : name}</div>
                                </div>
                            )}
                        />
                    </dd>
                </div>

                <div className='dl__li t-s'>
                    <dt>{lbl.ticket}</dt>
                    <dd>
                        <button
                            className="btn btn--naked dd__values label-wrapper"
                            onClick={() => openTickets(true)}
                            disabled={!isEditable}
                        >
                            {task.ticket ?
                                <>
                                    <Icon
                                        type={task.ticket.pipelineStage ?
                                            `status-${task.ticket.pipelineStage.isFirst ? '1' : task.ticket.pipelineStage.isLast ? '3' : '2'}` : 
                                            'status-0'}
                                        variant='sm'
                                    />
                                    {task.ticket.name}
                                </>
                            : 
                                <>
                                    <Icon type='ticket' variant='sm' />
                                    {isEditable ? lbl.connectToTicket : lbl.noTicket}
                                </>
                            }
                        </button>
                        {areTicketsOpen &&
                            <SearchSelect
                                searchable
                                isHidden
                                autoFocus
                                searchBy='name'
                                showLoader
                                onDropdownClose={() => openTickets(false)}
                                onSelect={([option]) => {
                                    if (!isNew && option.id === 'NONE') { 
                                        removeTicketRelation({ variables: { id: task.id }})
                                    } else if (task.ticket?.id !== option.id) { 
                                        onSelect({ ticketId: option.id }, null, { ticket: option })
                                    }
                                }}
                                optionsQuery={{
                                    query: GET_TICKETS,
                                    name: 'tickets',
                                    variables: {
                                        limit: 20,
                                        filters: 'pipeline_stage__next__isnull___false',
                                        sortBy: 'name',
                                        searchBy: ['name'],
                                        searchTerm: ''
                                    }
                                }}
                                labelField='name'
                                valueField='id'
                                keepSelectedInList={false}
                                selectedOptions={[task.ticket || { id: 'NONE', name: lbl.selectTicket }]}
                                unselectOption={task.ticket && { id: 'NONE', name: lbl.noTicket }}
                                getLabelNode={({ item: { id, name, pipelineStage }, isSelectedContent }) => (<>
                                    {id === 'NONE' ?
                                        isSelectedContent ? <Icon type='ticket' variant='sm' />
                                        : <Icon type='close' variant='xs' />
                                    : <Icon
                                            type={pipelineStage ?
                                                `status-${pipelineStage.isFirst ? '1' : pipelineStage.isLast ? '3' : '2'}` : 
                                                'status-0'}
                                            variant='sm'
                                        />
                                    }
                                    <span>{id === 'NONE' && isSelectedContent ? lbl.connectToTicket : name}</span>
                                </>)}
                            />
                        }
                    </dd>
                </div>

            </dl>
        </section>
    )
}

export default TaskProperties