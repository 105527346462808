import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '../fragments'

export const BASE_TASK_FRAGMENT = gql`
    fragment BaseTaskFields on TaskType {
        id
        name
        assignee {
            ...UserFields
        }
        createdBy {
            ...UserFields
        }
        status
        priority
        dueDate
        dueDateOffset
        dueDateLabel
        createdDate
        createdDateLabel
        createdDateOffset
        archived
        approvalRequired
    }
    ${USER_FRAGMENT}
`

export const FULL_TASK_FRAGMENT = gql`
    fragment AllTaskFields on TaskType {
        ...BaseTaskFields
        description
        ticket {
            id
            name
            priority
            pipelineStage {
                id
                isFirst
                isLast
            }
            createdDate
        }
        attachments {
            id
            originalFilename
        }
        attachmentCategory {
            id
            name
            userGroups {
                id
                name
            }
        }
    }
    ${BASE_TASK_FRAGMENT}
`
