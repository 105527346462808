import { Fragment, useState, useRef } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { ticketBoardCtrlsVar } from '../../cache'
import { GET_PIPELINE, GET_TICKETS_BY_PIPELINE_STAGE } from '../queries'
import { UPDATE_PIPELINE, DELETE_PIPELINE_STAGE } from '../mutations'
import { lbl } from '../../constants'
import { toTitleCase, cacheNewListerQuery } from '../../utils'
import { boardCtrlOptions, ticketProperties } from '../settings'
import { Icon, ListerControls } from '../../components'
import { TextField } from '../../forms'
import TicketBoardColumn from './TicketBoardColumn'
import TicketBoardSelector from './TicketBoardSelector'
import TicketBoardColumnNew from './TicketBoardColumnNew'


export const EmptyBoard = () => {
    return (
        <div className='tab__panel--main ticket-board'>
            <div className='tab__panel--main__header d-flex mb-2 pos-sticky pos-left'>
                <h1 className='t-heading t-2xl'>
                    {toTitleCase(lbl.selectBoard)}
                </h1>
                <TicketBoardSelector />
            </div>
        </div>
    )
}

const TicketBoard = ({ id }) => {
    let [newStageOpenedAfter, openNewStageAfter] = useState()
    const container = useRef()

    let { loading, error, data, client } = useQuery(GET_PIPELINE, { variables: { id } })
    const [updatePipeline] = useMutation(UPDATE_PIPELINE)
    const [deletePipelineStage] = useMutation(DELETE_PIPELINE_STAGE, {
        update(cache, { data: { deletePipelineStage: { deletedId } } }) {
            cache.modify({
                id: cache.identify({ __typename: 'PipelineType', id }),
                fields: {
                    pipelineStages(stages = [], { readField }) {
                        return stages.filter(s => deletedId !== readField('id', s))
                    }
                }
            })
        }
    })

    const onSelectCtrl = (actionType, ctrls) => {
        data?.pipeline.pipelineStages.forEach((pipelineStage, i) => {
            const prevCtrls = ticketBoardCtrlsVar()
            const filters = prevCtrls?.filters?.map(f => `${f.field}___${f.id}`).sort().join(',') || ''
            const variables = { pipelineStageId: pipelineStage.id, ...prevCtrls, filters }
            const prevQuery = client.cache.readQuery({ query: GET_TICKETS_BY_PIPELINE_STAGE, variables })

            if (!prevQuery) return
            
            const { tickets, hasMore } = prevQuery.ticketsByPipelineStage
            
            cacheNewListerQuery({
                actionType,
                ctrls,
                items: tickets,
                shouldFetchMore: hasMore,
                listCtrlVar: ticketBoardCtrlsVar,
                listerCtrlOptions: boardCtrlOptions,
                getFieldProps: (field) => ticketProperties[field],
                queryFieldName: 'ticketsByPipelineStage',
                querySubfieldName: 'tickets',
                queryVariables: { pipelineStageId: pipelineStage.id },
                query: GET_TICKETS_BY_PIPELINE_STAGE,
                cache: client.cache
            })
        })
    }

    if (!data && loading) return <div className='loader pos-center-y' />
    
    if (error) return (
        <div className='t-grey w-100 pos-center-y'>
            <center>{error.message}</center>
        </div>
    )

    const counts = data?.pipeline.pipelineStages.reduce((acc, stage) => {
        const count = stage.ticketCount
        if (!count) return acc
        if (stage.isLast) return ({ ...acc, done: count })
        if (stage.isFirst) return ({ ...acc, open: count })
        return ({ ...acc, inProgress: acc.inProgress + count })
    }, { open: 0, inProgress: 0, done: 0 })

    return (
        <div className='tab__panel--main ticket-board' ref={container}>
            <div className='tab__panel--main__header d-flex mb-2 pos-sticky pos-left'>
                <TextField
                    classAdditions='textfield-form--board-name'
                    onSubmit={name => name !== data?.pipeline?.name && updatePipeline({ variables: { id, name } })}
                    inputProps={{
                        type: 'input',
                        name: 'name',
                        value: data?.pipeline?.name,
                        role: 'textbox',
                        contentEditable: true,
                        placeholder: 'Board Name'
                    }}
                    size='2xl'
                    as='div'
                    innerHtml={data?.pipeline?.name}
                    inline
                />
                <TicketBoardSelector activeBoardId={id} />
                {counts &&
                    <div className='ml-auto mr-1 t-grey'>
                        <span className='t-grey'>{counts.open}</span> {` + `}
                        <span className='t-yellow'>{counts.inProgress}</span> {` + `}
                        <span className='t-green'>{counts.done}</span> {` = `}
                        <span>{counts.open + counts.inProgress + counts.done} tickets</span>
                    </div>
                }
            </div>
            <ListerControls
                controlsDisabled={loading}
                onSelectCtrl={onSelectCtrl}
                options={boardCtrlOptions}
                optionsDict={ticketProperties}
                listerCtrlVar={ticketBoardCtrlsVar}
                classModifier='board'
                type='tickets'
            />
            <div className='board'>
                {data?.pipeline.pipelineStages.map(({ticketCount, ...props}, i, array) => {    
                    return (
                        <Fragment key={props.name}>
                            <TicketBoardColumn
                                index={i}
                                ticketCount={ticketCount}
                                listerCtrlVar={ticketBoardCtrlsVar}
                                container={container.current}
                                {...props}
                            />
                            {i !== array.length - 1 &&
                                <div className='board__spacer'>
                                    <div className='board-column__ctrls'>
                                        {i !== 0 && !ticketCount &&
                                            <button
                                                className='btn btn--naked btn--inline-icon'
                                                title='Delete column'
                                                onClick={() => deletePipelineStage({ variables: { id: props.id } })}
                                            >
                                                <Icon type='bin' variant='xs' />
                                            </button>
                                        }
                                        <div className='dots-wrapper'>
                                            <Icon
                                                type='dots-vertical'
                                                fill='#C6CACC'
                                            />
                                        </div>
                                        <button
                                            className='btn btn--naked btn--inline-icon btn--icon-plus'
                                            title='Insert new column'
                                            onClick={() => openNewStageAfter(props.id)}
                                        >
                                            <Icon type='plus' variant='xs' />
                                        </button>
                                        <div className='board-column__placeholder'/>
                                    </div>
                                </div>
                            }
                            {newStageOpenedAfter === props.id &&
                                <TicketBoardColumnNew
                                    prevId={props.id}
                                    closeNew={() => openNewStageAfter('')}
                                    index={i+1}
                                />
                            }
                        </Fragment>
                    )
                })}
            </div>
        </div>
    )
}

export default TicketBoard
