import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { tabNavLinks } from '../../constants'
import Chat from './Chat'
import { Icon, SelectIcon } from '../../components'
import { useMarkAsReadMutation } from '../hooks'
import { GET_UNREAD_THREADS } from '../queries'
import { notificationCountersVar } from '../../cache'


const ThreadListItem = ({
    thread,
    noReply,
    maxMessages,
    showUnreadOnly,
    isSelected,
    isAnySelected,
    onSelect,
    redirect,
    closed
}) => {
    const [isOpen, setOpen] = useState(closed ? false : !thread.isEmpty)
    const [markAsRead] = useMarkAsReadMutation()
    const { unreadCount, messages, unreadMessages } = thread
    let counters = useReactiveVar(notificationCountersVar)
    const isUnread = !!thread.membership && (!!unreadCount || thread.membership.isUnread)

    return (
        <li
            key={thread.name}
            className={`thread__listitem ${isOpen ? 'open' : 'closed'}${thread.archived ? ' thread__listitem--archived' : ''}${thread.noReply ? ' thread__listitem--notification' : ''}${isUnread ? ' thread__listitem--unread' : ''}`}
        >
            <Link to={tabNavLinks.messenger.dynamicPath + thread.id}>
                <header className='thread__header'>
                    <SelectIcon
                        isSelected={isSelected}
                        isVisible={isAnySelected}
                        icon={thread.deleted ? 'bin' : thread.archived ? 'archive' : thread.icon || 'hash'}
                        onSelect={(e) => {
                            e.preventDefault()
                            onSelect(thread)
                        }}
                    />
                    <div className='thread__title'>
                        {thread.name}
                    </div>
                    {isUnread &&
                        <>
                            {!!unreadCount &&
                                <span className='t-xs t-blue no-wrap'>
                                    {unreadCount} unread
                                </span>
                            }
                            <button
                                className='btn btn--tertiary ml-1'
                                onClick={(e) => {
                                    e.preventDefault()
                                    markAsRead({
                                        variables: { threadIds: [thread.id] },
                                        refetchQueries: counters.unreadMentionsCount ? [{ query: GET_UNREAD_THREADS }] : undefined
                                    })
                                }}
                            >
                                Mark as read
                            </button>
                        </>
                    }
                    <button
                        className='btn btn--naked btn--inline-icon'
                        onClick={(e) => {
                            e.preventDefault()
                            setOpen(prev => !prev)
                        }}
                    >
                        <Icon type='caret' classAdditions={isOpen ? '' : 'caret--right'}/>
                    </button>
                </header>
            </Link>
            {isOpen &&
                <div className='thread__chat'>
                    <Chat
                        messages={showUnreadOnly || (maxMessages && maxMessages < unreadCount) ?
                            unreadMessages
                            : maxMessages && messages.length > maxMessages ?
                                messages.slice(messages.length - maxMessages, messages.length)
                                : messages}
                        unreadCount={unreadCount}
                        threadId={thread.id}
                        noReply={noReply}
                        archived={thread.archived}
                        deleted={thread.deleted}
                        isNotification={thread.noReply}
                        isEmpty={thread.isEmpty}
                        isUnread={!!thread.membership?.isUnread}
                        isInLister
                        preventScroll
                        redirect={redirect}
                        isMember={!!thread.membership}
                        members={thread.members}
                    />
                </div>
            }
        </li>
    )
}

export default ThreadListItem
