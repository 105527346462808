import { useMutation, useQuery } from '@apollo/client'
import { GET_PARTNERS } from '../properties/queries'
import { SAVE_CUSTOM_FIELD_VALUES_FOR_INSTANCE } from '../mutations'
import { postAsFormData, toTitleCase } from '../utils'

export const useCustomFormFields = ({
    contentType,
    objectId,
    setUploading,
    setUploadErrors,
    setUploadSuccess,
    uploadErrors={},
    ungroupedFields=[],
    groupedFields=[],
    groupedFirst=false
}) => {
    const {data: partnerData, error: partnerError, client} = useQuery(GET_PARTNERS)
    let partnerContactOptions = partnerData?.partners ? partnerData.partners.map(({ id, user, company }) => ({ value: id, label: `${company ? company.name + ' / ' : ''}${user.fullName}` })) : [
        { value: 'partner-x', label: partnerError?.message || 'Loading ...' }
    ]
    const [saveCustomFields, { loading, called, data, error, done }] = useMutation(SAVE_CUSTOM_FIELD_VALUES_FOR_INSTANCE)

    const FIELD_TYPE_ATTRIBUTES = {
        'INT': { type: 'number' },
        'TXT': { As: 'textarea', wide: true },
        'STR': { type: 'text' },
        'CONTACT': { As: 'select', options: partnerContactOptions},
        'FILE': { type: 'file', value: undefined, wide: true },
        'LINK': { type: 'url', wide: true },
    }

    const clearError = (fieldId) => {
        if (uploadErrors[fieldId]) {
            const errCopy = {...uploadErrors}
            delete errCopy[fieldId]
            setUploadErrors(errCopy)
        }
    }

    const getFieldProps = (field) => {
        const fieldProps = {
            name: field.id,
            label: toTitleCase(field.label),
            disabled: !field.editable,
            ...FIELD_TYPE_ATTRIBUTES[field.valueType],
        }
        if (field.valueType === 'LINK' && field.value) {
            fieldProps['href'] = field.value
        } else if (field.valueType === 'FILE') {
            fieldProps['id'] = `${field.id}_${objectId}`
            fieldProps['onChangeCb'] = () => clearError(field.id)
            if (field.value) {
                fieldProps['label'] = `Change ${fieldProps['label']}`
                fieldProps['filename'] = field.value.substring(field.value.lastIndexOf('/')+1, field.value.lastIndexOf('?'))
                fieldProps['href'] = field.value
            }
        }
        return fieldProps
    }

    const getFields = () => {
        const _ungroupedFields = []
        const _groupedFields = []
        const fileFields = {}
        const initialState = {}
        const fieldNames = []
        
        ungroupedFields.forEach((f, i) => {
            _ungroupedFields.push({
                ...getFieldProps(f),
                BeforeNode: i === 0 && groupedFirst ? () => <div className='field-group__title t-sm mt-2 mb-2'>Additional info</div> : undefined,
            })
            if (f.valueType === 'FILE') fileFields[f.id] = f.label
            if (f.value) initialState[f.id] = f.value
            fieldNames.push(f.id)
        })
        groupedFields.forEach(({ fields, name }) => {
            fields.forEach((f, i) => {
                _groupedFields.push({
                    ...getFieldProps(f),
                    BeforeNode: i === 0 ? () => <div className='field-group__title t-sm mt-2 mb-2'>{name}</div> : undefined,
                })
                if (f.valueType === 'FILE') fileFields[f.id] = f.label
                if (f.value) initialState[f.id] = f.value
                fieldNames.push(f.id)
            })
        })
        
        return { fileFields, initialState, fieldNames, fields: groupedFirst ? [..._groupedFields, ..._ungroupedFields] : [..._ungroupedFields, ..._groupedFields] }
    }

    const uploadFile = (file, customFieldId, isLast, objectId=objectId) => {
        postAsFormData({
            url: '/upload_file_to_custom_field/',
            data: {
                'file': file,
                'custom_field_id': customFieldId,
                'content_type': contentType,
                'object_id': objectId
            },
            onError: (err) => {
                setUploadErrors({ ...uploadErrors, [customFieldId]: err.message })
            },
            onSuccess: (fileUrl) => {
                client.cache.modify({
                    id: client.cache.identify({
                        __typename: 'CustomFieldType',
                        id: customFieldId
                    }),
                    fields: { value() { return fileUrl } }
                })
                clearError(customFieldId)
                isLast && setUploadSuccess(true)
            },
            onDone: () => isLast && setUploading(false)
        })
    }

    const { fileFields, fields, fieldNames, initialState } = getFields()

    const save = (formData, _objectId=objectId) => {
        const customFieldInputs = []
        const files = []

        Object.keys(formData).map(customFieldId => {
            const value = formData[customFieldId]
            if (value instanceof File) {
                files.push({ customFieldId, value })
            } else {
                if (!value && Object.keys(fileFields).includes(customFieldId)) {
                    if (window.confirm(`Do you want to delete the file previously uploaded to ${fileFields[customFieldId]} field?`)) {
                        customFieldInputs.push({ customFieldId, value })
                    }
                } else {
                    customFieldInputs.push({ customFieldId, value })
                }
            }
        })

        if (customFieldInputs.length) {
            saveCustomFields({ variables: {
                contentType,
                objectId: _objectId,
                customFieldInputs
            } })
        }
        if (files.length) {
            setUploading(true)
            files.forEach(({ customFieldId, value }, i) => uploadFile(value, customFieldId, i === files.length - 1, _objectId))
        }
    }
  
    return ({ fields, fieldNames, initialState, saveCustomFields: { save, loading, called, data, error, done } })
}

export default useCustomFormFields
