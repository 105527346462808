import { useState } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'
import { GET_PROPERTY_LEADS, GET_TENANTS } from '../queries'
import { CREATE_LEAD } from '../mutations'
import { newDraftsVar } from '../../cache'
import { lbl } from '../../constants'
import LeadAccordion from './LeadAccordion'
import { Icon } from '../../components'


const PropertyLeads = ({ id, property, units }) => {
    const [includeUnitLeads, setUnitLeads] = useState(false)
    const { loading, data, client } = useQuery(GET_PROPERTY_LEADS, { variables: { id, includeUnitLeads } })
    const {data: tenantsData, error: tenantsError} = useQuery(GET_TENANTS)
    const [newLeadOpen, setNewLeadOpen] = useState(false)
    let { propertyLeads } = useReactiveVar(newDraftsVar)
    let draft = propertyLeads[id] || {}

    const getTenantOptions = ({ tenant }) => tenantsData?.tenants ? tenantsData.tenants.map(({ id, user }) => ({ value: id, label: user.fullName })) : [
        { value: tenant?.id, label: tenant?.user.fullName },
        { value: 'tenant-x', label: tenantsError?.message || 'Loading ...' }
    ]

    return (
        <>
            {loading && <div className='loader pos-center-y'/>}
            <div className='property-level-filters d-flex justify-end mb-1'>
                <button
                    disabled={true}
                    className='btn btn--naked btn--icon mr-1 br-05 active'
                    onClick={() => {}}
                >
                    <Icon type='address' variant='sm'/>
                </button>
                <button
                    className={`btn btn--naked btn--icon mr-1 br-05${includeUnitLeads ? ' active' : ''}`}
                    onClick={() => setUnitLeads((prev) => !prev)}
                >
                    <Icon type='room' variant='sm'/>
                </button>
            </div>
            {newLeadOpen ?
                <LeadAccordion
                    accordionProps={{
                        isOpen: true,
                        className: 'bg-lightblue p-1 br-05 mb-1',
                        label: lbl.newLead,
                        onOpenCb: () => { setNewLeadOpen(false) }
                    }}
                    draft={{ ...draft, propertyId: id }}
                    units={units}
                    extraFields={[
                        { name: 'tenantId', lblKey: 'tenant', As: 'search-select', options: getTenantOptions({}), required: true, wide: true },
                        { name: 'propertyId', lblKey: 'property', As: 'select', options: [
                            { value: id, label: property?.streetWithHouseNumber}
                        ], required: true, disabled: true, wide: true }
                    ]}
                    submitMutation={CREATE_LEAD}
                    mutationVariables={{ includeUnitLeads }}
                    onCompleted={() => {
                        client.cache.evict({
                            id: `PropertyType:${id}`,
                            fieldName: 'propertyLeads',
                            variables: { includeUnitLeads: !includeUnitLeads }
                        })
                        client.cache.gc()
                    }}
                    close={() => { setNewLeadOpen(false) }}
                    setDraft={(leadDraft) => {
                        const drafts = { ...newDraftsVar()}
                        drafts.propertyLeads[id] = leadDraft
                        newDraftsVar(drafts)
                    }}
                />
            :
                <button
                    className='btn btn--naked btn--new-item w-100 mb-2'
                    onClick={() => { setNewLeadOpen(true) }}
                >
                    <span className="plus">+</span>
                    {lbl.addNewPropertyLead}
                </button>
            }
            {data?.property?.propertyLeads?.map((lead) => (
                <LeadAccordion
                    key={'lead' + lead.id}
                    lead={{ ...lead, property: { id: data.property.id, streetWithHouseNumber: data.property.streetWithHouseNumber } }}
                    units={units}
                    extraFields={[
                        { name: 'tenantId', lblKey: 'tenant', As: 'select', options: getTenantOptions(lead), required: true, wide: true },
                        { name: 'propertyId', lblKey: 'property', As: 'select', options: [
                            { value: id, label: property?.streetWithHouseNumber}
                        ], required: true, disabled: true, wide: true }
                    ].filter(Boolean)}
                    labelAddition={lead.unit?.fullUnitName}
                />
            ))}
        </>
    )
}

export default PropertyLeads
