import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useMutation, useReactiveVar } from '@apollo/client'
import { TextField } from '../../forms'
import { Icon, ProfileImage, HighlightedText, Label } from '../../components'
import { UPDATE_ATTACHMENT, REMOVE_ATTACHMENT_CATEGORY } from '../mutations'
import { GET_ATTACHMENT_CATEGORIES } from '../queries'
import { attachmentListCtrlsVar } from '../../cache'
import { CONTENT_MODEL_MAP, lbl } from '../../constants'
import { SearchSelect } from '../../forms/Select'

const AttachmentItem = ({
    propKeys,
    deleteAttachment,
    openOcp,
    isInCurrentSlide,
    listCtrlVar=attachmentListCtrlsVar,
    ...attachment
}) => {
    const {
        id,
        originalFilename,
        uploadedBy,
        contentType,
        objectId,
        category,
        relatedObjectLabel,
        relatedObjectArchived,
        taggedMessageId,
        dateCreatedLabel,
        dateChangedLabel,
        fileName,
        categoryName
    } = attachment
    const url = attachment.url || attachment.fileUrl
    let { searchTerm } = useReactiveVar(listCtrlVar)
    const disableContentObjectLink = !contentType || !CONTENT_MODEL_MAP[contentType.model]?.path || (relatedObjectArchived && CONTENT_MODEL_MAP[contentType.model].disableArchived)
    const [categoriesOpen, toggleCategories] = useState(false)
    const [updateAttachment] = useMutation(UPDATE_ATTACHMENT)
    const [removeAttachmentCategory] = useMutation(REMOVE_ATTACHMENT_CATEGORY)


    return (
        <tr className='lister__row'>
            {propKeys.map((key, i) => (
                <React.Fragment key={key + i}>
                    {(key === 'originalFilename' || key === 'fileName') ? (
                        <td className='lister__cell lister__cell--wide'>
                            <div className='lister-cell__wrapper'>
                                <TextField
                                    onSubmit={async (value) => {
                                        updateAttachment({
                                            variables: {
                                                id,
                                                originalFilename: value
                                            }
                                        })
                                    }}
                                    inputProps={{
                                        type: 'input',
                                        name: 'name',
                                        value: fileName || originalFilename || url,
                                        role: 'textbox',
                                        contentEditable: !!originalFilename,
                                        suppressContentEditableWarning: true,
                                        size: 'xs',
                                        disabled: !id || !originalFilename,
                                    }}
                                    as='div'
                                    hidePencil={!id || !originalFilename}
                                    InputChild={<HighlightedText text={fileName || originalFilename || url} mark={searchTerm} />}
                                />
                                <a
                                    href={url}
                                    className='btn btn--naked mr-05'
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    <Icon type='arrow-right' variant='xs' style={{ transform: 'rotate(-45deg)' }}/>
                                </a>
                                {id &&
                                    <button
                                        onClick={openOcp}
                                        className={`btn btn--naked mr-05${isInCurrentSlide ? ' active' : ''}`}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <Icon type='eye' variant='xs' />
                                    </button>
                                }
                                {id && deleteAttachment && !(contentType?.model === 'thread') &&
                                    <button
                                        className='btn btn--naked disabled'
                                        onClick={() => {
                                            if (
                                                window.confirm(
                                                    `Are you sure you want to delete ${originalFilename || 'this'} file?`
                                                )
                                            ) {
                                                deleteAttachment({
                                                    variables: { id }
                                                })
                                            }
                                        }}
                                    >
                                        <Icon type='bin' variant='xs' />
                                    </button>
                                }
                            </div>
                        </td>
                    ) : key === 'dateCreated' ? (
                        <td className='lister__cell'>
                            <Label
                                className='time-label'
                                iconProps={{ type: 'time' }}
                                label={dateCreatedLabel}
                                classModifier='grey'
                            />
                        </td>
                    ) : key === 'dateChanged' ? (
                        <td className='lister__cell'>
                            <Label
                                className='time-label'
                                iconProps={{ type: 'time' }}
                                label={dateChangedLabel}
                                classModifier='grey'
                            />
                        </td>
                    ) : key === 'uploadedBy' ? (
                        <td className='lister__cell t-sm'>
                            {uploadedBy &&
                                <div className='lister-cell__wrapper'>
                                    <ProfileImage
                                        url={uploadedBy.picture}
                                        size='xs'
                                    />
                                    <div className='d-inline-block ml-1'>
                                        <HighlightedText text={uploadedBy.fullName} mark={searchTerm} />
                                    </div>
                                </div>
                            }
                        </td>
                    ) : key === 'contentType' ? (
                        <td className='lister__cell'>
                            {contentType && CONTENT_MODEL_MAP[contentType.model] &&
                                <Link
                                    to={`${CONTENT_MODEL_MAP[contentType.model].path}${objectId}${taggedMessageId && contentType.model === 'thread' ? `#${taggedMessageId}` : ''}`}
                                    title={`Go to related ${CONTENT_MODEL_MAP[contentType.model].lbl || contentType.model}`}
                                    className='t-underline-hover'
                                    onClick={disableContentObjectLink ? (e) => e.preventDefault() : undefined}
                                    style={{
                                        opacity: relatedObjectArchived ? 0.5 : 1,
                                        pointerEvents: disableContentObjectLink ? 'none' : 'initial',
                                        cursor: disableContentObjectLink ? 'default' : 'pointer'
                                    }}
                                >
                                    <Label
                                        className='justify-start'
                                        label={<HighlightedText text={relatedObjectLabel} mark={searchTerm} />}
                                        iconProps={{ type: relatedObjectArchived ? 'archive' : CONTENT_MODEL_MAP[contentType.model].icon || contentType.model, variant: 'xs' }}
                                    />
                                </Link>
                            }
                        </td>
                    ) : key === 'categoryName' ? (
                        <td className='lister__cell'>
                            <Label
                                label={
                                    <div className='d-inline-flex'>
                                        <div className='mr-05'><HighlightedText text={categoryName} mark={searchTerm} /></div>
                                    </div>
                                }
                                classModifier='bordered'
                            />
                        </td>
                    ) : key === 'category' ? (
                        <td className='lister__cell pos-relative' style={{ overflow: 'visible', textAlign: 'center' }}>
                            <button
                                onClick={toggleCategories}
                                className='btn btn--naked'
                                style={{ verticalAlign: 'inherit', padding: 0 }}
                                target='_blank'
                                rel='noreferrer'
                                title={category ? 'Change file category' : 'Add file category'}
                            >
                                {category ?
                                    <Label
                                        style={{ width: 'auto' }}
                                        label={
                                            <div className='d-inline-flex'>
                                                <div className='mr-05'><HighlightedText text={category.name} mark={searchTerm} /></div>
                                                {category.userGroups?.map(userGroup => <Icon key={userGroup.name} type={userGroup.name} variant='2xs'/>)}
                                            </div>
                                        }
                                        classModifier='bordered'
                                    />
                                :
                                    <Icon type='plus' variant='xs' />
                                }
                            </button>
                            {categoriesOpen &&
                                <SearchSelect
                                    searchable
                                    isHidden
                                    searchBy='name'
                                    showLoader
                                    keepSelectedInList={false}
                                    selectedOptions={category ? [category] : []}
                                    unselectOption={category && { id: 'NONE', name: 'Remove Category' }}
                                    onSelect={async ([{ id, name, userGroups }]) => {
                                        if (id === 'NONE') {
                                            removeAttachmentCategory({ variables: { attachmentId: attachment.id }})
                                        } else if (category?.id !== id && name !== id) {
                                            updateAttachment({
                                                variables: { id: attachment.id, categoryId: id },
                                                onCompleted: () => {
                                                    toggleCategories(false)
                                                }
                                            })
                                        }
                                    }}
                                    onDropdownClose={() => toggleCategories(false)}
                                    optionsQuery={{
                                        query: GET_ATTACHMENT_CATEGORIES,
                                        name: 'attachmentCategories'
                                    }}
                                    labelField='name'
                                    valueField='id'
                                    placeholder={lbl.search}
                                    getLabelNode={({ item: { name, userGroups } }) => (<div key={name} className='d-inline-flex'>
                                        <span className='mr-05'>{name}</span>
                                        {userGroups?.map(userGroup => (
                                            <Icon key={userGroup.name} type={userGroup.name} variant='2xs' wrapperClassAdditions='m-0' />
                                        ))}
                                    </div>)}
                                />
                            }
                        </td>
                    ) : null}
                </React.Fragment>
            ))}
        </tr>
    )
}

export default AttachmentItem
