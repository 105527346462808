import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Icon, Popover, SelectIcon, UserChip } from '../../components'
import VoiceCall from '../../components/VoiceCall'
import { tabNavLinks, lbl, CONTENT_OBJECTS_MAP } from '../../constants'
import { postAsFormData, camelToTitleCase } from '../../utils'
import { GET_RELATED_THREADS } from '../../messenger/queries'
import { GET_PROPERTY_RELATED_OBJECTS } from '../queries'
import { ARCHIVE_PROPERTY_RELATED_OBJECTS } from '../mutations'
import { newDraftsVar } from '../../cache'
import PropertyTenantsList from './PropertyTenantsList'

const PropertySidePanel = ({
    type,
    id,
    image,
    contacts,
    properties,
    propertyId,
    fragment,
    isArchived
}) => {
    const client = useApolloClient()
    const { data: threadsData } = useQuery(GET_RELATED_THREADS, {
        variables: { contentTypeModel: type, objectId: id }
    })
    const [fetchRelatedObjects, { data, loading: loadingRelatedObjects, error: relatedObjectsError, refetch }] = useLazyQuery(GET_PROPERTY_RELATED_OBJECTS)
    const relatedObjects = data?.propertyRelatedObjects
    const [uploading, setUploading] = useState(false)
    const [showBigImage, setShowBigImage] = useState(false)
    const [archivePopUpOpened, toggleArchivePopUp] = useState(false)
    const [preventArchive, setPreventArchive] = useState({})
    const uploadImage = (e) => {
        setUploading(true)
        const selectedFile = e.target.files[0]

        postAsFormData({
            url: '/upload_property_picture/',
            data: { 'file': selectedFile, type, id },
            onSuccess: (picture) => {
                client.cache.updateFragment({
                    id: `${fragment.definitions[0].typeCondition.name.value}:${id}`,
                    fragment
                }, (data) => ({ ...data, picture }))
            },
            onDone: () => setUploading(false)
        })
    }
    const [archive, { data: archivedData, loading: archiving, error: archivingError }] = useMutation(ARCHIVE_PROPERTY_RELATED_OBJECTS)

    return (
        <>
            <aside className='split-panel--side split-panel--right'>
                <div className='split-panel--side-inner'>
                    <div className='t-center'>
                        {image ?
                            <img
                                className='img w-100 cursor--pointer'
                                src={process.env.PUBLIC_URL + image}
                                alt={type}
                                onClick={() => setShowBigImage(true)}
                                style={{
                                    maxHeight: '250px',
                                    objectFit: 'cover'
                                }}
                            />
                        :
                            <Icon
                                type={type}
                                size={100}
                                variant='auto'
                            />
                        }
                        <form>
                            <div className='d-flex justify-center mb-1'>
                                <label
                                    htmlFor='image_upload'
                                    className={uploading ? '' : image ? 'btn--naked br-05 cursor--pointer' : 'btn btn--tertiary mx-auto'}
                                >
                                    {uploading ? 'uploading...' : image ? `Change ${type} image` : `Upload ${type} image`}
                                </label>
                                <input
                                    disabled={uploading}
                                    className='pos-absolute'
                                    type='file'
                                    id='image_upload'
                                    name='image_upload'
                                    accept='.jpg, .jpeg, .png'
                                    onChange={uploadImage}
                                    style={{ opacity: 0, height: 0 }}
                                />
                            </div>
                        </form>
                    </div>
                    <hr/>
                    {properties &&
                        <>
                            <section>
                            <div className='t-sm mb-2'>PROPERTIES</div>
                                <ul className=''>
                                    {properties.map(property => (
                                        <li key={'property' + property.id} className=''>
                                            <Link
                                                to={`${tabNavLinks.properties.dynamicPath}${property.id}`}
                                                className='d-inline-flex t-underline-hover'
                                            >
                                                <Icon type='property' variant='xs'/>
                                                <div className='ml-05'>{property.streetWithHouseNumber}</div>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                                <Link
                                    className='d-block mt-1 btn--naked br-05 t-none'
                                    to={tabNavLinks.properties.property.new.relativeUrl}
                                    onClick={type === 'building' ? (e) => { 
                                        newDraftsVar({ ...newDraftsVar(), property: { buildingId: id } })
                                    } : undefined}
                                >
                                    + {tabNavLinks.properties.property.new.btnLabel}
                                </Link>
                            </section>
                            <hr/>
                        </>
                    }
                    {propertyId &&
                        <>
                            <section>
                            <div className='t-sm'>UNITS & TENANTS</div>
                                <PropertyTenantsList propertyId={propertyId}/>
                                {type === 'address' &&
                                    <Link
                                        className='d-block mt-1 btn--naked br-05 t-none'
                                        to={tabNavLinks.properties.unit.new.relativeUrl}
                                        onClick={() => { newDraftsVar({ ...newDraftsVar(), unit: { addressId: id } }) }}
                                    >
                                        + {tabNavLinks.properties.unit.new.btnLabel}
                                    </Link>
                                }
                            </section>
                            <hr/>
                        </>
                    }
                    {!!contacts.length &&
                        <>
                            <section>
                                <div className='t-sm mb-2'>CONTACTS</div>
                                <dl className='dl'>
                                    {contacts.map(contact => (
                                        <div className='dl__li t-s' key={contact.label}>
                                            <dt>{contact.label}</dt>
                                            <dd style={{ flexDirection: 'column', alignItems: 'flex-start', padding: '8px 0 8px 8px' }}>
                                                <Link
                                                    to={`${tabNavLinks.contacts.dynamicPath}${contact.user.id}`}
                                                    className='d-inline-flex t-underline-hover'
                                                >
                                                    <UserChip user={contact.user} className={`chip--${contact.type}`}/>
                                                </Link>
                                                {contact.organisation &&
                                                    <span className='d-inline-flex t-xs mt-05 ml-05'>
                                                        {contact.organisation}
                                                    </span>
                                                }
                                                <span className='d-inline-flex t-xs'>
                                                    <a
                                                        className='btn--naked mr-05 br-05'
                                                        href={contact.user.email ? `mailto:${contact.user.email}` : undefined}
                                                        aria-disabled={!contact.user.email}
                                                        title='send email'
                                                    >
                                                        <Icon type='email' variant='xs' />
                                                    </a>
                                                    {contact.user.email || 'N/A'}
                                                </span>
                                                <span className='d-inline-flex t-xs'>
                                                    <VoiceCall
                                                        contact={contact.user}
                                                        classAdditions='mr-05'
                                                        iconVariant='xs'
                                                    />
                                                    {contact.user.profile.phoneNumber || 'N/A'}
                                                </span>
                                            </dd>
                                        </div>
                                    ))}
                                </dl>
                            </section>
                            <hr/>
                        </>
                    }
                    
                    <section className='property__actions'>
                        <ul className='action-list'>
                            {!!threadsData?.threadsByRelatedObject.length &&
                                <li className='action__li action__li--thread'>
                                    <Link
                                        className='btn btn--naked btn--inline-icon'
                                        to={`${tabNavLinks.messenger.dynamicPath}${threadsData.threadsByRelatedObject[0].id}`}
                                    >
                                        <Icon type='message' variant='sm' />
                                        {lbl.goToRelThread}
                                    </Link>
                                </li>
                            }
                            {!isArchived &&
                                <li className='action__li action__li--archive pos-relative'>
                                    <button
                                        className='btn btn--naked btn--inline-icon'
                                        onClick={() => {
                                            toggleArchivePopUp(true)
                                            fetchRelatedObjects({
                                                variables: {
                                                    contentModel: type,
                                                    objectId: id
                                                }
                                            })
                                        }}
                                    >
                                        <Icon type='archive' variant='sm' />
                                        {lbl.archive}
                                    </button>
                                </li>
                            }
                        </ul>
                    </section>
                </div>
            </aside>
            {image && showBigImage &&
                <Popover
                    isOpen
                    className='popover--no-padding popover--image'
                    onClose={() => setShowBigImage(false)}
                >
                    <img src={image} alt='lightbox'/>
                </Popover>
            }
            {archivePopUpOpened &&
                <Popover
                    isOpen
                    onClose={() => toggleArchivePopUp(false)}
                >
                    
                    <header className='pop-up__header'>
                        {archivedData ?
                            <p className='t-green'>
                                Successfully archived selected instances:
                            </p>
                        : 'Are you sure you want to archive all selected instances?'}
                    </header>
                    {relatedObjectsError ?
                        <div className='pop-up__message t-s t-red'>
                            {relatedObjectsError}
                        </div>
                    : loadingRelatedObjects ?
                        <div className='pop-up__message t-s mb-2'>
                            <p>Fetching related instances...</p>
                            <div className='loader'/>
                        </div>
                    : relatedObjects ?
                        <div className='pop-up__message t-s' style={archivedData ? { marginBottom: '-24px' } : {}}>
                            {archivingError &&
                                <p className='t-red'>
                                    {archivingError}
                                </p>
                            }
                            {archivedData &&
                                <ul style={{ marginBottom: '20px' }}>
                                    {archivedData.archiveBuildings?.buildings?.map(x => 
                                        <li className='d-flex'>
                                            <Icon type='building' variant='xs' wrapperClassAdditions='mr-1'/>
                                            {x.name}
                                        </li>
                                    )}
                                    {archivedData.archiveProperties?.addresses?.map(x => 
                                        <li className='d-flex'>
                                            <Icon type='property' variant='xs' wrapperClassAdditions='mr-1'/>
                                            {x.label}
                                        </li>
                                    )}
                                    {archivedData.archiveUnits?.units?.map(x => 
                                        <li className='d-flex'>
                                            <Icon type='unit' variant='xs' wrapperClassAdditions='mr-1'/>
                                            {x.label}
                                        </li>
                                    )}
                                    {archivedData.archiveContracts?.contracts?.map(x => 
                                        <li className='d-flex'>
                                            <Icon type='tenant' variant='xs' wrapperClassAdditions='mr-1'/>
                                            {x.label}
                                        </li>
                                    )}
                                    {archivedData.archiveOwnerContracts?.contracts?.map(x => 
                                        <li className='d-flex'>
                                            <Icon type='landlord' variant='xs' wrapperClassAdditions='mr-1'/>
                                            {x.label}
                                        </li>
                                    )}
                                    {archivedData.archiveTenancyAgreements?.tenancyAgreements?.map(x => 
                                        <li className='d-flex'>
                                            <Icon type='document' variant='xs' wrapperClassAdditions='mr-1'/>
                                            {x.label}
                                        </li>
                                    )}
                                    {archivedData.archiveLeads?.leads?.map(x => 
                                        <li className='d-flex'>
                                            <Icon type='lead' variant='xs' wrapperClassAdditions='mr-1'/>
                                            {x.label}
                                        </li>
                                    )}
                                    {archivedData.archiveTickets?.tickets?.map(x => 
                                        <li className='d-flex'>
                                            <Icon type='ticket' variant='xs' wrapperClassAdditions='mr-1'/>
                                            {x.name}
                                        </li>
                                    )}
                                    {archivedData.archiveTasks?.tasks?.map(x => 
                                        <li className='d-flex'>
                                            <Icon type='task' variant='xs' wrapperClassAdditions='mr-1'/>
                                            {x.label}
                                        </li>
                                    )}
                                    {archivedData.archiveThreads?.threads?.map(x => 
                                        <li className='d-flex'>
                                            <Icon type='thread' variant='xs' wrapperClassAdditions='mr-1'/>
                                            {x.name}
                                        </li>
                                    )}
                                </ul>
                            }
                            <ul>
                                {Object.keys(relatedObjects).map(key => (
                                    Array.isArray(relatedObjects[key]) && relatedObjects[key].length > 0 &&
                                    <li key={key} className='mt-2'>
                                        <p className='t-uppercase t-sm'>{camelToTitleCase(key)}</p>
                                        <ul>
                                            {relatedObjects[key].map(obj => (
                                                <li key={key + obj.id} className=''>
                                                    <div className='d-inline-flex ml-05'>
                                                        <SelectIcon
                                                            isVisible
                                                            icon='check-circle'
                                                            isSelected={!preventArchive[key]?.includes(obj.id)}
                                                            onSelect={() => {
                                                                setPreventArchive({...preventArchive,
                                                                    [key]: !preventArchive[key] ? [obj.id]
                                                                    : preventArchive[key]?.includes(obj.id) ?
                                                                        preventArchive[key].filter(id => id !== obj.id)
                                                                    : [...preventArchive[key], obj.id]
                                                                })
                                                            }}
                                                        />
                                                        <span className='mr-1'/>
                                                        {CONTENT_OBJECTS_MAP[key]?.path ?
                                                            <Link
                                                                to={`${CONTENT_OBJECTS_MAP[key]?.path}${obj.id}`}
                                                                className='d-inline-flex t-underline-hover'
                                                            >
                                                                <Icon type={key} variant='xs' wrapperClassAdditions='mr-1'/>
                                                                {obj.label || obj.name}
                                                                <Icon type='arrow-right' variant='xs' style={{ transform: 'rotate(-45deg)', marginLeft: '5px' }}/>
                                                            </Link>
                                                        :
                                                            <>
                                                                <Icon type={key} variant='xs' wrapperClassAdditions='mr-1'/>
                                                                {obj.label || obj.name}
                                                            </>
                                                        }
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    : null}
                    {!archivedData &&
                        <div className='pop-up__btns bg-light'>
                            <button className='btn btn--secondary mr-2' onClick={() => toggleArchivePopUp(false)}>
                                {archivedData ? lbl.close : lbl.cancel}
                            </button>
                            
                            <button
                                className='btn btn--primary'
                                onClick={() => {
                                    archive({
                                        variables: {
                                            buildingIds: relatedObjects?.buildings?.filter(building => !preventArchive['buildings']?.includes(building.id)).map(building =>  building.id) || [],
                                            addressIds: relatedObjects?.addresses?.filter(address => !preventArchive['addresses']?.includes(address.id)).map(address => address.id) || [],
                                            roomIds: relatedObjects?.rooms?.filter(room => !preventArchive['rooms']?.includes(room.id)).map(room => room.id) || [],
                                            ownerContractIds: relatedObjects?.ownerContracts?.filter(ownerContract => !preventArchive['ownerContracts']?.includes(ownerContract.id)).map(ownerContract => ownerContract.id) || [],
                                            tenantContractIds: relatedObjects?.tenantContracts?.filter(tenantContract => !preventArchive['tenantContracts']?.includes(tenantContract.id)).map(tenantContract => tenantContract.id) || [],
                                            tenancyAgreementIds: relatedObjects?.tenancyAgreements?.filter(tenancyAgreement => !preventArchive['tenancyAgreements']?.includes(tenancyAgreement.id)).map(tenancyAgreement => tenancyAgreement.id) || [],
                                            leadIds: relatedObjects?.leads?.filter(lead => !preventArchive['leads']?.includes(lead.id)).map(lead => lead.id) || [],
                                            ticketIds: relatedObjects?.tickets?.filter(ticket => !preventArchive['tickets']?.includes(ticket.id)).map(ticket => ticket.id) || [],
                                            taskIds: relatedObjects?.tasks?.filter(task => !preventArchive['tasks']?.includes(task.id)).map(task => task.id) || [],
                                            threadIds: relatedObjects?.threads?.filter(thread => !preventArchive['threads']?.includes(thread.id)).map(thread => thread.id) || [],
                                        },
                                        onCompleted: refetch,
                                    })
                                }}
                                disabled={loadingRelatedObjects || !relatedObjects || relatedObjectsError || archiving}
                            >
                                {archiving ? lbl.archiving : lbl.archive}
                            </button>
                        </div>
                    }
                </Popover>
            }
        </>
    )
}

export default PropertySidePanel
