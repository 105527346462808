import { useQuery } from '@apollo/client'
import { GET_UNREAD_THREADS } from '../queries'
import ThreadList from './ThreadList'


const UnreadOverview = () => {
    const { data, loading, error } = useQuery(GET_UNREAD_THREADS)

    if (!data && loading) return <div className='loader pos-center-y' />
    if (error) return <div className='error t-red pos-center-y'>{error.message}</div>
    if (data && data.unreadThreads.threads.length === 0) return (
        <div className='t-center t-grey w-100 mx-auto my-auto'>
            <br/>👍<br/>
            <span>You have no unread messages</span>
        </div>
    )
    if (data) return (
        <div className='split-panel--main p-0'>
            <ThreadList
                threads={data.unreadThreads.threads}
                showUnreadOnly={false}
                redirect={false}
            />
        </div>
    )
    return null
}

export default UnreadOverview
