import { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { ARCHIVE_SERVICE, UPDATE_SERVICE } from '../mutations'
import { GET_PARTNERS, GET_SERVICE_TYPES } from '../queries'
import Form from '../../forms/Form'
import { lbl } from '../../constants'
import { postAsFormData } from '../../utils'


const ServiceInfoForm = ({
    service={},
    draft,
    submitMutation=UPDATE_SERVICE,
    mutationVariables={},
    onCompleted,
    close=()=>{},
    setDraft
}) => {
    const history = useHistory()
    const { pathname } = useLocation()
    const [uploading, setUploading] = useState(false)
    const [uploadError, setUploadError] = useState('')
    const {data: serviceTypeData, error: serviceTypeError} = useQuery(GET_SERVICE_TYPES)
    const {data: partnerData, error: partnerError} = useQuery(GET_PARTNERS)

    const initialState = draft || {
        ...service,
        typeId: service.type?.id,
        providerId: service.provider?.id,
        packageType: service.internet?.packageType || '',
        modemCode: service.internet?.modemCode || '',
        modemLocation: service.internet?.modemLocation || '',
        networkName: service.internet?.networkName || '',
        networkPassword: service.internet?.networkPassword || '' 
    }
    let [type, setType] = useState(initialState.type)
    const [updateService, { loading, done, called, data, error }] = useMutation(submitMutation)
    const [archiveService, { loading: archiving }] = useMutation(ARCHIVE_SERVICE)

    const success = !error && !uploadError && !loading && !uploading && (called && !!data)
    
    let serviceTypeOptions = serviceTypeData?.serviceTypes ? serviceTypeData.serviceTypes.map(({ id, name }) => ({ value: id, label: name })) : [
        { value: service.type?.id, label: service.type?.name },
        { value: 'serviceType-x', label: serviceTypeError?.message || 'Loading ...' }
    ]
    let partnerOptions = partnerData?.partners ? partnerData.partners.map(({ id, user }) => ({ value: id, label: user.fullName })) : [
        { value: service.provider?.id, label: service.provider?.user.fullName },
        { value: 'partner-x', label: partnerError?.message || 'Loading ...' }
    ]

    const introFields = [
        { name: 'typeId', lblKey: 'type', As: 'select', options: serviceTypeOptions, required: true, disabled: !!service.type, onChangeCb: (v) => {
            const selectedType = serviceTypeData?.serviceTypes.find(t => t.id === v) || {}
            setType(selectedType)
        } },
        { name: 'providerId', lblKey: 'provider', As: 'select', options: partnerOptions },
    ]
    const fields = [
        { name: 'contractUrl', lblKey: 'contractLink', type: 'url', href: service.contractUrl },
        { name: 'contractFile', id: 'contract_file_' + service.id, type: 'file', value: undefined, href: service.contractFile?.url, filename: service.contractFile?.originalFilename, onChangeCb: () => uploadError && setUploadError('') },
        (type && !type.isUtility && !type.isInternet) && { name: 'serviceFrequency' },
        { name: 'paymentFrequency' },
        { name: 'price', type: 'decimal' },
        { name: 'note', As: 'textarea', wide: true }
    ].filter(Boolean)
    const internetFields = type?.isInternet ? [
        { name: 'packageType' },
        { name: 'modemCode' },
        { name: 'modemLocation', As: 'textarea', wide: true },
        { name: 'networkName' },
        { name: 'networkPassword' }
    ] : []

    const uploadFile = ({ file, onSuccess }) => {
        setUploading(true)
        postAsFormData({
            data: {'file': file },
            onError: (err) => setUploadError(err.message),
            onSuccess: (attachment) => {
                onSuccess(attachment.id)
            },
            onDone: () => setUploading(false)
        })
    }

    return (
        <Form
            fields={[...introFields, ...internetFields, ...fields]}
            initialState={initialState}
            onSubmit={(data, cancelChanges) => {
                const { contractFile, ..._data } = data
                const saveService = (contractFileId) => {
                    updateService({
                        variables: { id: service.id, isInternet: type.isInternet, contractFileId, ..._data, ...mutationVariables },
                        onError: cancelChanges,
                        onCompleted: (args) => {
                            if (onCompleted) onCompleted(args)
                            if (setDraft) setDraft({})
                            if (args?.addService) {
                                const url = `${pathname}#service_${args.addService.service?.id}`
                                history.push(url)
                                close()
                            }
                        }
                    })
                }

                if (contractFile) {
                    uploadFile({ file: contractFile, onSuccess: saveService })
                } else if (Object.keys(_data).length) {
                    saveService()
                }
            }}
            onCancel={close}
            isSubmitting={archiving || uploading || (loading && !done)}
            disabled={service.archived}
            isSuccess={success}
            setDraft={setDraft}
            formClassName='form--column--2'
            error={error?.message || uploadError}
            actions={(service.id && !service.archived) ? [{
                label: lbl.archive,
                onClick: () => {
                    if ( window.confirm('Are you sure you want to archive this service?')) {
                        archiveService({ variables: { id: service.id }})
                    }
                }
            }] : []}
        />
    )
}

export default ServiceInfoForm
