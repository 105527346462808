import { useQuery, useReactiveVar } from '@apollo/client'
import { userCallListCtrlsVar } from '../../cache'
import CallsList from '../../calls/components/CallsList'
import { GET_USER_CALLS } from '../queries'

export const columns = ['direction', 'subject', 'caller', 'receiver', 'duration', 'dateTime', 'status', 'recordingUrl']

const UserCalls = ({ userId }) => {
    const vars = useReactiveVar(userCallListCtrlsVar)
    const filters = vars.filters.map(f => `${f.field}___${f.id}`).sort().join(',')
    const { data, error, loading, fetchMore } = useQuery(GET_USER_CALLS, { 
        variables: { userId, ...vars, filters },
        notifyOnNetworkStatusChange: true
    })

    if (error) return <div className='pos-center-y w-100 t-center'>{error.message}</div>

    return (
        <CallsList
            calls={data?.userCalls}
            query={GET_USER_CALLS}
            queryFieldName='userCalls'
            queryVariables={{ userId }}
            columnKeys={columns}
            listCtrlVar={userCallListCtrlsVar}
            controlsDisabled={loading}
            showLoader={loading && !data?.userTickets}
            fetchMore={fetchMore}
        />
    )
}

export default UserCalls
