import { useLayoutEffect, useRef } from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { useMutation } from "@apollo/client"
import { Accordion, Label } from "../../components"
import Form from "../../forms/Form"
import { UPDATE_LEAD, ARCHIVE_LEAD } from "../mutations"
import { lbl, tabNavLinks } from '../../constants'


const LeadAccordion = ({
    draft,
    lead={},
    accordionProps={},
    labelAddition,
    close=()=>{},
    setDraft,
    extraFields=[],
    disableFields=[],
    submitMutation=UPDATE_LEAD,
    mutationVariables={},
    onCompleted,
    refetchQueries,
    units=[],
    properties=[],
    propertyUpdated=false,
    ...formProps
}) => {
    const history = useHistory()
    const { pathname, hash } = useLocation()
    const leadEl = useRef()
    const initialState = draft || {
        preferredCheckInDate: lead.preferredCheckInDate,
        budget: lead.budget,
        propertyId: lead.property?.id,
        unitId: lead.unit?.id,
        tenantId: lead.tenant?.id
    }

    const [updateLead, { loading, done, called, data, error }] = useMutation(submitMutation)
    const [archiveLead, { loading: archiving }] = useMutation(ARCHIVE_LEAD)

    const success = !error && !loading && called && !!data?.updateLead?.lead
    const _units = units.length ? units : lead.unit ? [lead.unit] : []
    const unitOptions = _units.map(({ id, longName, fullUnitName }) => ({ value: id, label: longName && !lead.property ? longName : fullUnitName }))
    
    const fields = [
        { name: 'unitId', lblKey: 'unit', As: 'search-select', value: propertyUpdated ? '' : undefined, wide: true, disabled: disableFields.includes('unitId') && !!units.length, options: unitOptions },
        { name: 'preferredCheckInDate', type: 'date' },
        { name: 'budget', type: 'number' },
    ]

    useLayoutEffect(() => {
        if (hash && lead.id && hash === `#lead_${lead.id}` && leadEl?.current) {
            setTimeout(() => leadEl.current.scrollIntoView({
                behavior: 'smooth', block: 'start'
            }), 100)
        }
    }, [hash, leadEl.current, lead.id])


    return (
        <div id={`lead_${lead.id}`} ref={leadEl}>
            <Accordion
                isOpen={hash === `#lead_${lead.id}`}
                Tag='div'
                className='bg-light p-1 br-05 mb-1'
                headerClass='accordion__header--bordered t-md t-heading mr-4'
                label={
                    <>
                        {labelAddition && `${labelAddition} / `}
                        <Link
                            to={tabNavLinks.contacts.dynamicPath+lead?.tenant?.user.id}
                            className='t-underline-hover'
                            title='Go to contact page'
                        >
                            {lead?.tenant?.user.fullName}
                        </Link>
                        {lead.archived &&
                            <Label
                                classModifier='grey'
                                className='ml-1 label--uppercase label--archived'
                                iconProps={{ type: 'archive', variant: '3xs' }}
                                label={lbl.archived}
                            />
                        }
                    </>
                }
                {...accordionProps}
            >
                <div className='ml-4 mr-4'>
                    <Form
                        key={lead.property?.id}
                        fields={[...extraFields, ...fields]}
                        initialState={initialState}
                        onSubmit={(data, cancelChanges) => {
                            updateLead({
                                variables: { id: lead.id, ...data, ...mutationVariables },
                                refetchQueries,
                                onError: cancelChanges,
                                onCompleted: (c) => {
                                    onCompleted && onCompleted(c)
                                    
                                    if (!!c.createLead) {
                                        const url = `${pathname}#lead_${c.createLead.lead.id}`
                                        history.push(url)
                                        close()
                                        setDraft({})
                                    }
                                },
                            })
                        }}
                        onCancel={close}
                        isSubmitting={archiving || (loading && !done)}
                        disabled={lead.archived}
                        isSuccess={success}
                        setDraft={setDraft}
                        formClassName='form--column--2'
                        actions={(lead.id && !lead.archived) ? [{
                            label: lbl.archive,
                            onClick: () => {
                                if ( window.confirm('Are you sure you want to archive this lead')) {
                                    archiveLead({ variables: { id: lead.id }})
                                }
                            }
                        }] : []}
                        {...formProps}
                    />
                </div>
            </Accordion>
        </div>
    )
}

export default LeadAccordion
