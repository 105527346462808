import { useQuery, useReactiveVar } from '@apollo/client'
import { ticketArchiveCtrlsVar } from '../../cache'
import { GET_ARCHIVED_TICKETS } from '../queries'
import TicketList from './TicketList'

const TicketsArchive = () => {
    const vars = useReactiveVar(ticketArchiveCtrlsVar)
    const filters = vars.filters.map(f => `${f.field}___${f.id}`).sort().join(',')
    const { data, loading, error, fetchMore } = useQuery(GET_ARCHIVED_TICKETS, {
        variables: { ...vars, filters },
        notifyOnNetworkStatusChange: true
    })

    if (error) return <div className='pos-center-y w-100 t-center'>{error.message}</div>

    return (
        <TicketList
            tickets={data?.archivedTickets}
            controlsDisabled={loading}
            listCtrlVar={ticketArchiveCtrlsVar}
            showLoader={loading && !data?.tickets}
            query={GET_ARCHIVED_TICKETS}
            queryFieldName='archivedTickets'
            fetchMore={fetchMore}
        />
    )
}

export default TicketsArchive
