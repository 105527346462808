import Icon from './Icon'
import ProfileImage from './ProfileImage'
import { currentUserVar } from '../cache'

const UserChip = ({ user, onRemove, onClick, style, className }) => {
    const isCurrentUser = currentUserVar().id === user.id

    return (
        <span
            className={`chip chip--user chip--${isCurrentUser ? 'current' : `${user.type}`} ${className}`}
            onClick={onClick}
            title={user.fullName}
            style={style}
        >
            <ProfileImage url={user.picture || user.profile?.picture} size='2xs' />
            <span style={{ whiteSpace: 'nowrap' }}>{isCurrentUser ? 'Me' : user.fullName}</span>
            {onRemove &&
                <button
                    className='btn close btn--inline-icon btn--naked'
                    onClick={e => onRemove(user, e)}
                >
                    <Icon type='close' variant='3xs' />
                </button>
            }
        </span>
    )
}

export default UserChip
