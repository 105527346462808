import { gql } from '@apollo/client'


export const TAG_MESSAGE_ATTACHMENTS = gql`
    mutation TAG_MESSAGE_ATTACHMENTS {
        tagMessageAttachments {
            ok
        }
    }
`

export const SAVE_CUSTOM_FIELD_VALUES_FOR_INSTANCE = gql`
    mutation SAVE_CUSTOM_FIELD_VALUES_FOR_INSTANCE($contentType: String!, $objectId: ID!, $customFieldInputs: [CustomFieldInputType]) {
        saveCustomFieldValuesForContentObject(contentType: $contentType, objectId: $objectId, customFieldInputs: $customFieldInputs) {
            fields {
                id
                label
                valueType
                editable
                helpText
                value(objectId: $objectId)
            }
        }
    }
`

export const ASSIGN_UNCATEGORIZED_TICKET_FOLDER = gql`
    mutation ASSIGN_UNCATEGORIZED_TICKET_FOLDER {
        assignUncategorizedTicketFolder {
            ok
        }
    }
`
