import { gql } from '@apollo/client'

export const USER_FRAGMENT = gql`
    fragment UserFields on UserType {
        id
        fullName
        username
        picture
        type
        onlineStatus
    }
`

export const CHANGE_LOG_FRAGMENT = gql`
    fragment ChangeLogFields on ChangeLogType {
        id
        dateTime
        kind
        changes
        contentModel
        user {
            id
            fullName
            picture
            type
        }
        attachment {
            id 
            link
            originalFilename
        }
    }
`
