import { useMutation, useQuery } from '@apollo/client'
import { UPDATE_UNIT } from '../mutations'
import { GET_OPERATIONAL_MODELS } from '../queries';
import FormWithCustomFields from '../../forms/FormWithCustomFields'


const UnitInfoForm = ({
    unit={},
    draft,
    submitMutation=UPDATE_UNIT,
    onCompleted,
    setDraft,
    extraFields=[]
}) => {
    const {data: operationalModelData, error: operationalModelError} = useQuery(GET_OPERATIONAL_MODELS)
    const initialState = draft || {
        ...unit,
        operationalModelId: unit.operationalModel?.id
    }
    const [updateUnit, { loading, called, data, error }] = useMutation(submitMutation, { onCompleted })
    const success = !error && !loading && called && !!data?.updateUnit?.unit
    let operationalModelOptions = operationalModelData?.operationalModels ? operationalModelData.operationalModels.map(({ id, name }) => ({ value: id, label: name })) : [
        { value: unit.operationalModel?.id, label: unit.operationalModel?.name },
        { value: 'x', label: operationalModelError?.message || 'Loading ...' }
    ]
    const unitFields = [
        { name: 'name', required: true },
        { name: 'unitNumber', type: 'number'},
        { name: 'operationalModelId', lblKey: 'operationalModel', As: 'select', options: operationalModelOptions },
        { name: 'sizeSqm', type: 'number', step: '0.1' },
        { name: 'minOccupancy', type: 'number' },
        { name: 'maxOccupancy', type: 'number' },
        { name: 'keycode', disabled: true },
        { name: 'monthlyBasicRent', type: 'decimal', BeforeNode: () => <div className='field-group__title t-sm mt-2 mb-2'>Costs</div> },
        { name: 'monthlyServiceCost', type: 'decimal' },
        { name: 'deposit', type: 'decimal' },
    ]

    return (
        <FormWithCustomFields
            contentType='room'
            objectId={unit.id}
            fields={[...extraFields, ...unitFields]}
            initialState={initialState}
            onSubmit={(data, cancelChanges, saveCustomFields) => {
                updateUnit({
                    variables: { id: unit.id, ...data },
                    onCompleted: (c) => {
                        if (c && saveCustomFields) {
                            saveCustomFields(unit.id || c.createUnit?.unit?.id)
                        }
                        onCompleted && onCompleted(c)
                    },
                    onError: cancelChanges
                })
            }}
            onCancel={onCompleted}
            isSubmitting={loading}
            isSuccess={success}
            setDraft={setDraft}
            formClassName='form--column--2'
        />
    )
};

export default UnitInfoForm
