import { gql } from '@apollo/client'
import { TEMPLATE_FRAGMENT } from './fragments'

export const CREATE_TEMPLATE = gql`
    mutation CREATE_TEMPLATE($body: String!, $name: String!) {
        createTemplate(name: $name, body: $body) {
            template {
                ...TemplateFields
            }
        }
    }
    ${TEMPLATE_FRAGMENT}
`

export const ARCHIVE_TEMPLATE = gql`
    mutation ARCHIVE_TEMPLATE($id: ID!) {
        archiveTemplate(id: $id, archived: true) {
            template {
                ...TemplateFields
            }
        }
    }
    ${TEMPLATE_FRAGMENT}
`

export const UPDATE_TEMPLATE = gql`
    mutation UPDATE_TEMPLATE($id: ID!, $name: String, $body: String) {
        updateTemplate(id: $id, name: $name, body: $body) {
            template {
                ...TemplateFields
            }
        }
    }
    ${TEMPLATE_FRAGMENT}
`

export const DELETE_TEMPLATE = gql`
    mutation DELETE_TEMPLATE($id: ID!) {
        deleteTemplate(id: $id) {
            deletedTemplateId
        }
    }
`
