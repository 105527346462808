import Icon from './Icon'

const Label = ({ label, classModifier, className, iconProps, iconVariant='xs', style }) => (
    <div className={`label icon-label label--${classModifier}${className ? ` ${className}` : ''}`} style={style}>
        {iconProps &&
            <Icon
                variant={iconProps.type === 'time' ? '3xs' : iconVariant}
                {...iconProps}
            />}
        <span>{label}</span>
    </div>
)

export default Label