import { GET_BUILDINGS, GET_CITIES, GET_OPERATIONAL_MODELS, GET_PROPERTY_TYPES } from './queries'

export const addressProperties = {
    building: {
        field: 'building',
        type: 'object',
        groupPath: 'building',
        sortPath: 'building.name',
        searchPath: 'building',
        label: 'Building',
        optionsQuery: { query: GET_BUILDINGS, name: 'buildings' },
        getOptionProps: root => ({ ...root, label: root.name })
    },
    'building.city': {
        field: 'building.city',
        type: 'object',
        groupPath: 'building.city.name',
        sortPath: 'building.city.name',
        objectPath: 'building.city',
        label: 'City',
        optionsQuery: { query: GET_CITIES, name: 'cities' },
        getOptionProps: root => ({ ...root, label: root.name })
    },
    streetWithHouseNumber: {
        field: 'streetWithHouseNumber',
        sortPath: 'streetWithHouseNumber',
        type: 'string',
        label: 'Address'
    },
    postcode: {
        field: 'postcode',
        type: 'string',
        sortPath: 'postcode',
        label: 'Postcode',
        getOptionProps: root => ({ id: root, label: root, field: 'postcode' })
    },
    units: {
        field: 'rooms',
        type: 'array',
        label: 'Units',
        multi: true,
        getOptionProps: root => ({ ...root, label: root.fullUnitName })
    },
    propertyType: {
        field: 'propertyType',
        type: 'object',
        sortPath: 'propertyType.name',
        groupPath: 'propertyType.name',
        objectPath: 'propertyType',
        label: 'Type',
        optionsQuery: { query: GET_PROPERTY_TYPES, name: 'propertyTypes' },
        getOptionProps: root => ({ ...root, label: root.name })
    },
    operationalModel: {
        field: 'operationalModel',
        type: 'object',
        groupPath: 'operationalModel.name',
        sortPath: 'operationalModel.name',
        objectPath: 'operationalModel',
        label: 'Operational model',
        optionsQuery: { query: GET_OPERATIONAL_MODELS, name: 'operationalModels' },
        getOptionProps: root => ({ ...root, label: root.name })
    }
}

export const listerCtrlOptions = {
    filter: ['building.city', 'building', 'propertyType', 'operationalModel'].map(key => addressProperties[key]),
    group: ['building.city', 'postcode', 'building', 'propertyType', 'operationalModel'].map(key => addressProperties[key]),
    sort: ['building.city', 'streetWithHouseNumber', 'postcode', 'building', 'propertyType', 'operationalModel'].map(key => addressProperties[key]),
    search: ['building.city', 'streetWithHouseNumber', 'postcode', 'building', 'units', 'propertyType', 'operationalModel'].map(key => addressProperties[key])
}

export const listerColumns = ['streetWithHouseNumber', 'building', 'postcode', 'building.city', 'units', 'operationalModel', 'propertyType']
