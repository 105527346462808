import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { GET_NOTE_TAGS } from '../queries'
import { CREATE_NOTE_TAG, REMOVE_TAG_FROM_NOTE, ADD_TAG_TO_NOTE } from '../mutations'
import SelectForm, { SearchSelect } from '../../forms/Select'
import Icon from '../../components/Icon'
import { lbl, labelColors } from '../../constants'
import { toTitleCase } from '../../utils'
import { useEffect } from 'react'


const NoteTags = ({
    noteId,
    tags,
    isOpen,
    onClose,
    hideAddButton=false,
    onTagSelect=()=>{},
    onTagRemove=()=>{}
}) => {
    const [tagsOpen, toggleTags] = useState(isOpen)
    const [colorsOpen, toggleColors] = useState(false)
    const [newTagLabel, setNewTagLabel] = useState('')
    const [addTagToNote] = useMutation(ADD_TAG_TO_NOTE)
    const [removeTagFromNote] = useMutation(REMOVE_TAG_FROM_NOTE)
    const [createNoteTag] = useMutation(CREATE_NOTE_TAG, {
        update(cache, { data: { createNoteTag: { tag } } }) {
            cache.modify({
                fields: {
                    noteTags(existing = []) {
                        return [tag, ...existing];
                    }
                }
            });
        }
    })

    useEffect(() => {
        if (isOpen) {
            toggleTags(true)
        } else {
            toggleTags(false)
        }
    }, [isOpen])

    return  (
        <>
            <ul className='d-inline-flex flex-wrap'>
                {tags && tags.map(({ label, color, id }) => (
                    <span
                        key={label}
                        className='d-inline-flex br-05 mr-05 p-0'
                        style={{ backgroundColor: `${color}40` }}
                    >
                        <Icon
                            type='label'
                            variant='2xs'
                            fill={color}
                            style={{ transform: 'rotate(45deg)' }}
                        />
                        {label}
                        <button
                            className='btn close btn--inline-icon btn--naked'
                            title={lbl.removeTag}
                            onClick={e => {
                                e.stopPropagation()
                                noteId && removeTagFromNote({ variables: { tagId: id, noteId } })
                                onTagRemove({id})
                            }}
                        >
                            <Icon type='close' variant='3xs' />
                        </button>
                    </span>
                ))}
                {!hideAddButton &&
                    <button
                        className='btn btn--naked btn--inline-icon'
                        style={{ padding: '2px' }}
                        onClick={() => toggleTags(true)}
                        title={lbl.addTag}
                    >
                        <Icon type='label-plus' variant='2xs'/>
                    </button>
                }
            </ul>
            {tagsOpen &&
                <SearchSelect
                    multi
                    isHidden
                    searchBy='label'
                    create
                    createNewLabel='+ Create new tag: {search}'
                    onCreateNew={({ label }) => {
                        toggleColors(true)
                        setNewTagLabel(toTitleCase(label))
                    }}
                    keepSelectedInList={false}
                    selectedOptions={tags}
                    onSelect={async ([tag]) => {
                        const { id, label } = tag
                        if (id !== label && noteId) await addTagToNote({ variables: { tagId: id, noteId } })
                        onTagSelect(tag)
                        onClose()
                        toggleTags(false)
                    }}
                    onDropdownClose={() => {
                        onClose()
                        toggleTags(false)
                    }}
                    optionsQuery={{ query: GET_NOTE_TAGS, name: 'noteTags' }}
                    labelField='label'
                    valueField='id'
                    placeholder={lbl.addNoteTagSearch}
                    getLabelNode={({ item: { label, color } }) => (<>
                        <Icon type='label' variant='xs' fill={color} />
                        {label}
                    </>)}
                    style={{ zIndex: 10 }}
                />
            }
            {colorsOpen &&
                <SelectForm
                    isHidden
                    options={labelColors}
                    onSelect={async ([{ color }]) => {
                        if (newTagLabel) await createNoteTag({ variables: { label: newTagLabel, color, noteId } })
                        toggleColors(false)
                    }}
                    onDropdownClose={() => toggleColors(false)}
                    labelField='color'
                    valueField='color'
                    getLabelNode={({ item: { color } }) => (<>
                        <div style={{ width: '16px', height: '16px', borderRadius: '50%', backgroundColor: color, marginRight: '10px' }} />
                        {color}
                    </>)}
                    style={{ top: 0 }}
                />
            }
        </>
    )
}

export default NoteTags
