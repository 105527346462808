import React from 'react'
import { useApolloClient } from '@apollo/client'
import { ticketAttachmentListCtrlsVar } from '../../cache'
import { GET_ATTACHMENTS_FOR_CONTENT_OBJECTS } from '../../attachments/queries'
import AttachmentsLister from '../../attachments/components/AttachmentLister'
import { extendedListerColumns, extendedListerCtrlOptions } from '../../attachments/settings'

const TicketAttachments = ({ ticket }) => {
    const { cache } = useApolloClient()
    const contentObjects = [
        { contentType: 'ticket', objectIds: [ticket.id], order: 1 },
        ticket.tasks?.length && { contentType: 'task', order: 3, objectIds: ticket.tasks.map(({ id }) => id)},
        ticket.threads?.length && { contentType: 'thread', order: 2, objectIds: ticket.threads.map(({ id }) => id)},
    ].filter(Boolean)

    return (
        <div className='tab__panel--main'>
            <AttachmentsLister
                columns={extendedListerColumns}
                ctrlOptions={extendedListerCtrlOptions}
                listCtrlVar={ticketAttachmentListCtrlsVar}
                query={GET_ATTACHMENTS_FOR_CONTENT_OBJECTS}
                queryVariables={{ contentObjects }}
                queryFieldName='attachmentsForContentObjects'
                contentTypeString='ticketing | ticket'
                contentObjectId={ticket.id}
                onUploaded={() => {
                    cache.evict({
                        id: 'ROOT_QUERY',
                        fieldName: 'attachmentsForContentObjects',
                        variables: { contentObjects }
                    })
                    cache.gc()
                }}
            />
        </div>
    )
}

export default TicketAttachments
