import { Redirect } from 'react-router-dom'
import { activeSubtabVar } from '../cache'

const RedirectToLastSubtab = ({ urlRoot, defaultSubtab='info' }) => {
    const [ , , activeTab, id ] = urlRoot.split('/')
    const subtabs = activeSubtabVar()
    const activeSubtab = (subtabs[activeTab] && subtabs[activeTab][id]) || defaultSubtab

    return <Redirect to={{ pathname: `${urlRoot}/${activeSubtab}` }}/>
}

export default RedirectToLastSubtab
