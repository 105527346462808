import Icon from './Icon'

export const RoundIconButton = ({ children, onClick, icon, color='grey', ...props }) => {
    return (
        <button
            className={`btn btn--round btn--solid btn--solid--${color}`}
            onClick={onClick}
            {...props}
        >
            {children}
            {icon && <Icon type={icon} variant='md' />}
        </button>
    )
}

export default RoundIconButton
