import { useQuery, useReactiveVar } from '@apollo/client'
import { taskListCtrlsVar } from '../../cache'
import { GET_TASKS } from '../queries'
import TaskList from './TaskList'

const TaskLister = () => {
    const vars = useReactiveVar(taskListCtrlsVar)
    const filters = vars.filters.map(f => `${f.field}___${f.id}`).sort().join(',')
    const { data, loading, error, fetchMore } = useQuery(GET_TASKS, {
        variables: { ...vars, filters },
        notifyOnNetworkStatusChange: true
    })

    if (error) return <div className='pos-center-y w-100 t-center'>{error.message}</div>

    return (
        <TaskList
            tasks={data?.tasks}
            controlsDisabled={loading}
            fetchMore={fetchMore}
            showLoader={loading && !data?.tasks}
            query={GET_TASKS}
            queryFieldName='tasks'
        />
    )
}

export default TaskLister
