import { useEffect, useContext, useState } from 'react'
import { useHistory } from 'react-router'
import { useQuery, useMutation } from '@apollo/client'
import { TabNavContext } from '../../components/TabNav'
import TextEditor from '../../components/TextEditor'
import Label from '../../components/Label'
import { TextField } from '../../forms'
import { tabNavLinks, lbl } from '../../constants'
import { currentUserVar } from '../../cache'
import { GET_TEMPLATE } from '../queries'
import { UPDATE_TEMPLATE, ARCHIVE_TEMPLATE, DELETE_TEMPLATE } from '../mutations'

const Template = () => {
    const history = useHistory()
    const currentUser = currentUserVar()
    const { id, pinnedTabs, unpinTab, openTab, closeTab, renameTab } = useContext(TabNavContext)
    const [showEditor, setShowEditor] = useState(false)
    const [body, setBody] = useState('')
    const { loading, error, data, called } = useQuery(GET_TEMPLATE, {
        variables: { id },
        fetchPolicy: 'cache-first',
        onCompleted: ({ template }) => {
            setBody(template.body)
        }
    })
    const relativeUrl = tabNavLinks.templates.dynamicPath + id
    const isPinned = pinnedTabs.get(relativeUrl)
    const name = data?.template?.name
    const [updateTemplate] = useMutation(UPDATE_TEMPLATE)
    const [archiveTemplate] = useMutation(ARCHIVE_TEMPLATE, {
        update(cache, { data: { archiveTemplate: { template } } }) {
            cache.modify({
                fields: {
                    templates(existingTemplates = [], { readField }) {
                        return existingTemplates.filter(t => template.id !== readField('id', t))
                    }
                }
            })
        }
    })
    const [deleteTemplate, { called: deleted }] = useMutation(DELETE_TEMPLATE, {
        onCompleted: ({ deleteTemplate: { deletedTemplateId } }) => {
            if (pinnedTabs.get(relativeUrl)) {
                unpinTab({ relativeUrl, preventOpen: true })
                history.push(tabNavLinks.templates.staticTabs[0].relativeUrl)
            } else {
                closeTab({ id: deletedTemplateId, relativeUrl })
            }
        },
        update(cache, { data: { deleteTemplate: { deletedTemplateId } } }) {
            cache.modify({
                fields: {
                    templates(existingTemplates = [], { readField }) {
                        return existingTemplates.filter(t => deletedTemplateId !== readField('id', t))
                    }
                }
            })
        }
    })

    useEffect(() => {
        called && !deleted && name && !isPinned && openTab({ id, name, relativeUrl, icon: 'clipboard' })
    }, [called, name, id, isPinned, deleted])

    if (loading) return <div className='loader pos-center-y' />
    if (error || !data?.template) return <p>Error :(</p>
    
    const { template } = data
    const isEditable = !template.archived && (currentUser.isSuperuser || currentUser.id === template.createdBy?.id)

    return (
        <div className='template split-panel'>
            <section
                className='split-panel--main d-column'
                onMouseDown={showEditor ? () => {
                    setShowEditor(false)
                } : undefined}
            >
                <div className='w-100' onClick={e => e.stopPropagation()}>
                    {template.archived &&
                        <Label
                            classModifier='grey'
                            className='label--uppercase label--archived'
                            iconProps={{ type: 'archive', variant: '3xs' }}
                            label={lbl.archived}
                        />
                    }
                    <TextField
                        onSubmit={async value => {
                            await updateTemplate({ variables: { id, name: value } })
                            renameTab({ id, name: value, relativeUrl })
                        }}
                        inputProps={{
                            type: 'input',
                            name: 'name',
                            value: template.name,
                            role: 'textbox',
                            contentEditable: isEditable,
                            placeholder: lbl.templateTitle
                        }}
                        size='2xl'
                        as='div'
                        innerHtml={template.name}
                        hidePencil={!isEditable}
                    />
                </div>
                <div className='w-100' onMouseDown={e => e.stopPropagation()}>
                    { !showEditor ?
                        <div onClick={isEditable ? e => setShowEditor(true) : undefined}>
                            <hr style={{ opacity: 0.3 }} />
                            <br/>
                            <TextField
                                inputProps={{
                                    type: 'input',
                                    name: 'body',
                                    value: template.body,
                                    role: 'textbox',
                                    contentEditable: false
                                }}
                                as='div'
                                innerHtml={template.body}
                                hidePencil={!isEditable}
                            />
                        </div>
                    :
                        <div className='template__editor bg-lightblue'>
                            <TextEditor
                                model={body}
                                onModelChange={setBody}
                                config={{
                                    placeholderText: 'Add template content...',
                                    toolbarButtons: ['bold', 'italic', 'underline', 'backgroundColor', 'fontSize', '|', 'formatOL', 'formatUL', 'outdent', 'indent', 'align', '|', 'insertHR', 'insertTemplate', 'insertLink', 'insertImage', 'insertFile', 'emoticons', '|', 'selectAll', 'clearFormatting', 'undo', 'redo', '|', 'delete', 'archive', '|', 'saveChanges', 'cancelEditing'],
                                }}
                                immediateReactModelUpdate
                                focusOnMount
                                saveCommand='saveChanges'
                                onSave={async () => {
                                    await updateTemplate({ variables: { id, body } })
                                    setShowEditor(false)
                                }}
                                onCancel={() => {
                                    setBody(template.body)
                                    setShowEditor(false)
                                }}
                                onArchive={() => {
                                    const confirmed = window.confirm(`Please confirm that you want to archive template "${template.name}" by pressing OK`)
                                    if (confirmed) {
                                        setShowEditor(false)
                                        archiveTemplate({ variables: { id } })
                                    }
                                }}
                                onDelete={() => {
                                    const confirmed = window.confirm(`Please confirm that you want to permanently delete template "${template.name}" by pressing OK`)
                                    if (confirmed) {
                                        setShowEditor(false)
                                        deleteTemplate({ variables: { id } })
                                    }
                                }}
                            />
                        </div>
                    }
                </div>
            </section>
        </div>
    )
}

export default Template
