import { gql } from '@apollo/client'
import { PIPELINE_STAGE_FRAGMENT, BASE_TICKET_FRAGMENT } from './fragments'

export const ON_NEW_TICKET_SUBSCRIPTION = gql`
    subscription ON_NEW_TICKET_SUBSCRIPTION {
        onNewTicket {
            newTicket {
            ...BaseTicketFields
            pipelineStage {
                ...PipelineStageFields
                pipeline {
                    id
                    name
                }
            }
            pipeline {
                id
                name
            }
        }
    }
}
${BASE_TICKET_FRAGMENT}
${PIPELINE_STAGE_FRAGMENT}
`;
