import { Switch, Route, Redirect } from 'react-router-dom'
import NotesFeed from '../../notes/components/NotesFeed'
import TimelineWidgets from '../../components/TimelineWidgets'
import TicketThreads from './TicketThreads'
import { tabNavLinks, contentTypes } from '../../constants'
import { ticketProperties } from '../settings'
import RedirectToLastSubtab from '../../components/RedirectToLastSubtab'
import TicketAttachments from './TicketAttachments'

const TicketDetailRouter = ({ ticket, ticketId, isEditable }) => {
    const urlRoot = `${tabNavLinks.tickets.dynamicPath}${ticketId}`

    return (
        <Switch>
            <Route path={`${urlRoot}/updates`}>
                <TimelineWidgets
                    contentType={contentTypes.TICKET}
                    objectId={ticketId}
                    properties={ticketProperties}
                />
            </Route>
            <Route path={`${urlRoot}/threads`}>
               <TicketThreads ticketId={ticketId} />
            </Route>
            <Route path={`${urlRoot}/attachments`}>
                <TicketAttachments ticket={ticket} />
            </Route>
            <Route path={`${urlRoot}/notes`}>
                <NotesFeed
                    contentType={contentTypes.TICKET}
                    objectId={ticketId}
                    disabled={!isEditable}
                />
            </Route>
            <Route path={urlRoot}>
                <RedirectToLastSubtab urlRoot={urlRoot} defaultSubtab='updates' />
            </Route>
        </Switch>
    )
}

export default TicketDetailRouter
