import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { GET_LABELS } from '../queries'
import { CREATE_LABEL, REMOVE_LABEL, ADD_LABEL } from '../mutations'
import SelectForm, { SearchSelect } from '../../forms/Select'
import Icon from '../../components/Icon'
import { lbl, labelColors } from '../../constants'
import { toTitleCase } from '../../utils'


const TicketLabels = ({ ticketId, labels, isEditable, onChange }) => {
    const [labelsOpen, toggleLabels] = useState(false)
    const [colorsOpen, toggleColors] = useState(false)
    const [newLabelName, setNewLabelName] = useState('')
    const [addLabel] = useMutation(ADD_LABEL)
    const [removeLabel] = useMutation(REMOVE_LABEL)
    const [createLabel] = useMutation(CREATE_LABEL, {
        update(cache, { data: { createLabel: { label } } }) {
            cache.modify({
                fields: {
                    labels(existingLabels = []) {
                        return [label, ...existingLabels];
                    }
                }
            });
        }
    })

    return  (
        <div className='dl__li dl__li--multi dl__li t-s'>
            <dt>{lbl.labels}</dt>
            <dd>
                <div className='dd__values' onClick={() => isEditable && toggleLabels(open => !open)}>
                    {labels && labels.map(({ name, color, id }) => (
                        <span key={name} className='label-wrapper'>
                            <Icon type='label' variant='2xs' fill={color} classAdditions='mr-1'/>
                            {name}
                            {isEditable ?
                                <button
                                    className='btn close btn--inline-icon btn--naked'
                                    onClick={e => {
                                        e.stopPropagation()
                                        ticketId ?
                                            removeLabel({ variables: { id, ticketId } })
                                        : onChange({ labels: labels.filter(l => l.id !== id) })
                                    }}
                                >
                                    <Icon type='close' variant='3xs' />
                                </button>
                            :
                                <div className='mr-1'/>
                            } 
                        </span>
                    ))}
                    {isEditable &&
                        <button className='btn btn--naked add'>
                            {lbl.addLabel}
                        </button>
                    }
                </div>
                {labelsOpen &&
                    <SearchSelect
                        multi
                        isHidden
                        searchBy='name'
                        create
                        createNewLabel='+ Create new label: {search}'
                        onCreateNew={({ name }) => {
                            toggleColors(true)
                            setNewLabelName(toTitleCase(name))
                        }}
                        keepSelectedInList={false}
                        selectedOptions={labels}
                        onSelect={async ([{ id, name, color }]) => {
                            if (id !== name) {
                                if (ticketId) {
                                    await addLabel({ variables: { id, ticketId } })
                                } else {
                                    onChange({ labels: [...labels, { id, name, color }] })
                                }
                            }
                            toggleLabels(false)
                        }}
                        onDropdownClose={() => toggleLabels(false)}
                        optionsQuery={{ query: GET_LABELS, name: 'labels' }}
                        labelField='name'
                        valueField='id'
                        placeholder={lbl.addLabelSearch}
                        getLabelNode={({ item: { name, color } }) => (<>
                            <Icon type='label' variant='xs' fill={color} />
                            {name}
                        </>)}
                    />
                }
                {colorsOpen &&
                    <SelectForm
                        isHidden
                        options={labelColors}
                        onSelect={async ([{ color }]) => {
                            if (newLabelName) await createLabel({ variables: { name: newLabelName, color, ticketId } })
                            toggleColors(false)
                        }}
                        onDropdownClose={() => toggleColors(false)}
                        labelField='color'
                        valueField='color'
                        getLabelNode={({ item: { color } }) => (<>
                            <div style={{ width: '16px', height: '16px', borderRadius: '50%', backgroundColor: color, marginRight: '10px' }} />
                            {color}
                        </>)}
                    />
                }
            </dd>
        </div>
    )
}

export default TicketLabels
