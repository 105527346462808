import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { staticTabsVar } from '../../cache'
import { GET_PIPELINES } from '../queries'
import { CREATE_PIPELINE } from '../mutations'
import { lbl } from '../../constants'
import { Icon } from '../../components'
import { SearchSelect } from '../../forms'


const TicketBoardSelector = ({ activeBoardId }) => {
    const history = useHistory()
    let [pipelinesOpened, togglePipelines] = useState(false)

    const [createPipeline] = useMutation(CREATE_PIPELINE, {
        onCompleted: ({ createPipeline: { pipeline } }) => {
            const relativeUrl = `/tickets/board/${pipeline.id}`
            const staticTabs = staticTabsVar()
            staticTabs.tickets[0].relativeUrl = relativeUrl
            staticTabsVar({ ...staticTabs })

            localStorage.setItem('activeTicketBoard', pipeline.id)
            history.push(relativeUrl)
        },
        update(cache, { data: { createPipeline: { pipeline } } }) {
            cache.modify({
                fields: {
                    pipelines(existingPipelines = []) {
                        return [pipeline, ...existingPipelines];
                    }
                }
            });
        }
    })

    return (
        <>
            <button
                disabled={pipelinesOpened}
                className='btn btn--naked btn--inline-icon btn--toggle'
                onClick={() => togglePipelines(isOpen => !isOpen)}
            >
                <Icon type='caret' variant='sm' />
            </button>
            {pipelinesOpened &&
                <SearchSelect
                    showLoader
                    optionsQuery={{ query: GET_PIPELINES, name: 'pipelines' }}
                    onSelect={([{ id, name }]) => {
                        if (id !== name) {
                            const relativeUrl = `/tickets/board/${id}`
                            const staticTabs = staticTabsVar()
                            staticTabs.tickets[0].relativeUrl = relativeUrl
                            staticTabsVar({ ...staticTabs })

                            localStorage.setItem('activeTicketBoard', id)
                            history.push(relativeUrl)
                        }
                    }}
                    placeholder={lbl.selectBoard + '...'}
                    create
                    createNewLabel='+ Create new board: {search}'
                    onCreateNew={async ({ name }) => {
                        await createPipeline({ variables: { name } })
                    }}
                    valueField='id'
                    labelField='name'
                    searchBy='name'
                    isHidden
                    onDropdownClose={() => togglePipelines(false)}
                    excludeValues={activeBoardId ? [activeBoardId] : undefined}
                    contentRenderer={undefined}
                />
            }
        </>
    )
}

export default TicketBoardSelector
