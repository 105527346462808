import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { tabNavLinks, priorityOptions, lbl } from '../../constants'
import { ProfileImage, Label, HighlightedText, SelectIcon } from '../../components'


const TicketListItem = ({
    id,
    name,
    priority,
    owner,
    assignee,
    createdBy,
    createdDateLabel,
    createdDateOffset,
    pipelineStage,
    labels,
    category,
    archived,
    deleted,
    onSelect,
    isSelected,
    isAnySelected,
    propKeys,
    listerCtrlVar,
    isArchive
}) => {
    const history = useHistory()
    let { searchTerm } = useReactiveVar(listerCtrlVar)

    return (
        <tr
            onClick={() => history.push(`${tabNavLinks.tickets.dynamicPath}${id}`)}
            className='lister__row'
            style={{
                opacity: archived && !isArchive ? 0.5 : deleted ? 0.3 : 1
            }}
        >
            {propKeys.map((key, i) => (
                <React.Fragment key={key+i}>
                    {key === 'pipelineStage' ?
                        <td className='lister__cell'>
                            <div
                                className='lister-cell__wrapper'
                                title={pipelineStage ? pipelineStage.name : lbl.noStatus}
                            >
                                <SelectIcon
                                    isSelected={isSelected}
                                    isVisible={isAnySelected}
                                    icon={pipelineStage ?
                                        `status-${pipelineStage.isFirst ? '1' :
                                        pipelineStage.isLast ? '3' : '2'}`
                                    : 'status-0'}
                                    onSelect={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        onSelect(id, name, archived)
                                    }}
                                />
                            </div>
                        </td>
                    : key === 'name' ?
                        <td className='lister__cell lister__cell--grow'>
                            <Link
                                className='t-none'
                                to={`${tabNavLinks.tickets.dynamicPath}${id}`}
                                title={name}
                            >
                                <HighlightedText text={name} mark={searchTerm} />
                            </Link>
                        </td>
                    : key === 'labels' ?
                        <td className='lister__cell lister__cell--multi'>
                            {labels && labels.map(({ name, color }) => (
                                <Label
                                    key={name}
                                    className='bg-light'
                                    iconProps={{ type: 'label', fill: color }}
                                    label={<HighlightedText text={name} mark={searchTerm} />}
                                    classModifier={color}
                                />
                            ))}
                        </td>
                    : key === 'category' ?
                        <td className='lister__cell'>
                            {category &&
                                <Label
                                    className=''
                                    label={<HighlightedText text={category.name} mark={searchTerm} />}
                                    classModifier='bordered'
                                />
                            }
                        </td>
                    : key === 'createdDate' ?
                        <td className='lister__cell'>
                            <Label
                                className='time-label'
                                iconProps={{ type: 'time' }}
                                label={createdDateLabel}
                                classModifier={createdDateOffset < -2 ? 'red' : createdDateOffset < -1 ? 'yellow' : 'grey'}
                            />
                        </td>
                    : key === 'createdBy' ?
                        <td className='lister__cell lister__cell--200 t-sm'>
                            {createdBy &&
                                <div className='lister-cell__wrapper'>
                                    <ProfileImage
                                        url={createdBy.picture}
                                        size='xs'
                                        iconProps={{ type: 'reporter' }}
                                    />
                                    <div className='d-inline-block ml-1'>
                                        <HighlightedText text={createdBy.fullName} mark={searchTerm} />
                                    </div>
                                </div>
                            }
                        </td>
                    : key === 'owner' ?
                        <td className='lister__cell lister__cell--200 t-sm'>
                            {owner &&
                                <div className='lister-cell__wrapper'>
                                    <ProfileImage
                                        url={owner.picture}
                                        size='xs'
                                        iconProps={{ type: 'reporter' }}
                                    />
                                    <div className='d-inline-block ml-1'>
                                        <HighlightedText text={owner.fullName} mark={searchTerm} />
                                    </div>
                                </div>
                            }
                        </td>
                    : key === 'priority' ?
                        <td className='lister__cell'>
                            <Label
                                className=''
                                iconProps={{ type: priorityOptions[priority].icon }}
                                label={<HighlightedText text={priorityOptions[priority].label} mark={searchTerm} />}
                                classModifier={priorityOptions[priority].color}
                            />
                        </td>
                    : key === 'assignee' ?
                        <td className='lister__cell lister__cell--200 t-sm'>
                            {assignee &&
                                <div className='lister-cell__wrapper'>
                                    <ProfileImage url={assignee.picture} size='xs' />
                                    <div className='d-inline-block ml-1'>
                                        <HighlightedText text={assignee.fullName} mark={searchTerm} />
                                    </div>
                                </div>
                            }
                        </td>
                    : key === 'archived' ?
                        <td className='lister__cell t-sm'>
                            {archived &&
                                <div className='lister-cell__wrapper'>
                                    <Label
                                        classModifier='grey'
                                        label={<HighlightedText text={lbl.archived} mark={searchTerm} />}
                                        iconProps={{ type: 'archive' }}
                                    />
                                </div>
                            }
                        </td>
                    : null}
                </React.Fragment>
            ))}
        </tr>
    )
}

export default TicketListItem