import { gql } from '@apollo/client'
import {
    PIPELINE_STAGE_FRAGMENT,
    LABEL_FRAGMENT,
    BASE_TICKET_FRAGMENT,
    TICKET_TASK_FRAGMENT,
    FULL_TICKET_FRAGMENT
} from './fragments'


export const GET_TICKETS = gql`
    query GET_TICKETS(
        $offset: Int, $limit: Int, $searchTerm: String, $searchBy: [String], $groupBy: String, $groupDir: Int, $sortBy: String, $sortDir: Int, $filters: String
    ) {
        tickets(offset: $offset, limit: $limit, searchTerm: $searchTerm, searchBy: $searchBy, groupBy: $groupBy, groupDir: $groupDir, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            ...BaseTicketFields
            pipelineStage {
                ...PipelineStageFields
                pipeline {
                    id
                    name
                }
            }
            pipeline {
                id
                name
            }
            hasMore
        }
    }
    ${BASE_TICKET_FRAGMENT}
    ${PIPELINE_STAGE_FRAGMENT}
`

export const GET_ARCHIVED_TICKETS = gql`
  query GET_ARCHIVED_TICKETS($offset: Int, $limit: Int, $searchTerm: String, $groupBy: String, $groupDir: Int, $sortBy: String, $sortDir: Int, $filters: String) {
        archivedTickets(offset: $offset, limit: $limit, searchTerm: $searchTerm, groupBy: $groupBy, groupDir: $groupDir, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            ...BaseTicketFields
            pipelineStage {
                ...PipelineStageFields
                pipeline {
                    id
                    name
                }
            }
            pipeline {
                id
                name
            }
            hasMore
        }
    }
    ${BASE_TICKET_FRAGMENT}
    ${PIPELINE_STAGE_FRAGMENT}
`

export const GET_USER_TICKETS = gql`
  query GET_USER_TICKETS($id: ID!, $offset: Int, $limit: Int, $searchTerm: String, $groupBy: String, $groupDir: Int, $sortBy: String, $sortDir: Int, $filters: String) {
        userTickets(userId: $id, offset: $offset, limit: $limit, searchTerm: $searchTerm, groupBy: $groupBy, groupDir: $groupDir, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            ...BaseTicketFields
            pipelineStage {
                ...PipelineStageFields
                pipeline {
                    id
                    name
                }
            }
            pipeline {
                id
                name
            }
            hasMore
        }
    }
    ${BASE_TICKET_FRAGMENT}
    ${PIPELINE_STAGE_FRAGMENT}
`

export const GET_PROPERTY_TICKETS = gql`
  query GET_PROPERTY_TICKETS($propertyIds: [ID], $unitIds: [ID], $offset: Int, $limit: Int, $searchTerm: String, $groupBy: String, $groupDir: Int, $sortBy: String, $sortDir: Int, $filters: String) {
        propertyTickets(propertyIds: $propertyIds, unitIds: $unitIds, offset: $offset, limit: $limit, searchTerm: $searchTerm, groupBy: $groupBy, groupDir: $groupDir, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            ...BaseTicketFields
            pipelineStage {
                ...PipelineStageFields
                pipeline {
                    id
                    name
                }
            }
            pipeline {
                id
                name
            }
            hasMore
        }
    }
    ${BASE_TICKET_FRAGMENT}
    ${PIPELINE_STAGE_FRAGMENT}
`


export const GET_TICKETS_BY_PIPELINE_STAGE = gql`
  query GET_TICKETS_BY_PIPELINE_STAGE($pipelineStageId: ID!, $cursor: ID, $limit: Int, $searchTerm: String, $sortBy: String, $sortDir: Int, $filters: String, $preventMerge: Boolean) {
        ticketsByPipelineStage(pipelineStageId: $pipelineStageId, cursor: $cursor, limit: $limit, searchTerm: $searchTerm, sortBy: $sortBy, sortDir: $sortDir, filters: $filters, preventMerge: $preventMerge) {
            tickets {
                ...BaseTicketFields
                pipelineStage {
                    ...PipelineStageFields
                    pipeline {
                        id
                        name
                    }
                }
                pipeline {
                    id
                    name
                }
                threads {
                    id
                    archived
                    unreadCount
                    isUnread
                }
                unreadMessageCount @client
            }
            cursor
            hasMore
            filteredTicketCount
        }
    }
    ${BASE_TICKET_FRAGMENT}
    ${PIPELINE_STAGE_FRAGMENT}
`


export const GET_TICKET = gql`
    query GET_TICKET($id: ID!) {
        ticket(id: $id) {
            ...AllTicketFields
            tasks {
                ...TicketTaskFields
            }
        }
    }
    ${TICKET_TASK_FRAGMENT}
    ${FULL_TICKET_FRAGMENT}
`

export const GET_STATUS_OPTIONS = gql`
    query GET_STATUS_OPTIONS($id: ID!) {
        pipelineStages(id: $id) {
            ...PipelineStageFields
            pipeline {
                id
                name
            }
        }
    }
    ${PIPELINE_STAGE_FRAGMENT}
`

export const GET_LABELS = gql`
    query GET_LABELS {
        labels {
            ...LabelFields
        }
    }
    ${LABEL_FRAGMENT}
`

export const GET_CATEGORIES = gql`
    query GET_CATEGORIES {
        categories {
            id
            name
            userGroup {
                id
                name
            }
        }
    }
`

export const GET_PIPELINES = gql`
    query GET_PIPELINES($excludeEmpty: Boolean) {
        pipelines(excludeEmpty: $excludeEmpty) {
            id
            name
            pipelineStages {
                id
                name
            }
        }
    }
`

export const GET_PIPELINE = gql`
    query GET_PIPELINE($id: ID!) {
        pipeline(id: $id) {
            id
            name
            pipelineStages {
                ...PipelineStageFields
                ticketCount
            }
        }
    }
    ${PIPELINE_STAGE_FRAGMENT}
`

export const GET_PIPELINE_STAGE = gql`
    query GET_PIPELINE_STAGE($id: ID!) {
        pipelineStage(id: $id) {
            ...PipelineStageFields
        }
    }
    ${PIPELINE_STAGE_FRAGMENT}
`

export const GET_BOARD_FILTER_OPTIONS = gql`
    query GET_BOARD_FILTER_OPTIONS($field: String) {
        boardFilterOptions(field: $field) @client
    }
`
