import React, { useRef, useState } from 'react';
import { useReactiveVar } from '@apollo/client'
import { Link } from 'react-router-dom';
import { useDrag } from 'react-dnd'
import { ticketBoardCtrlsVar } from '../../cache'
import { ProfileImage, Label, HighlightedText, Counter } from '../../components'
import { lbl, priorityOptions, tabNavLinks } from '../../constants'


export const BoardCard = ({ item, children }) => {
    const [{ isDragging, isMoved }, dragRef] = useDrag({
        item,
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
            isMoved: monitor.didDrop() && monitor.getDropResult().targetId !== item.originId,
        }),
    })

    return (
        <div className={`draggable ${isDragging ? ' dragged' : ''}${isMoved ? ' moved' : ''}`}>
            <div className='board-card' ref={dragRef}>
                {children}
            </div>
        </div>
    )
}

export const TicketCard = ({
    id,
    name,
    category,
    priority,
    assignee,
    labels,
    owner,
    createdDateLabel,
    createdDateOffset,
    unreadMessageCount,
    item,
}) => {
    let movedOut = useRef(false)
    let [isHovered, setHovered] = useState(false)
    let { searchTerm } = useReactiveVar(ticketBoardCtrlsVar)
    const [{ isDragging, dropResult }, dragRef] = useDrag({
        item,
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
            dropResult: monitor.didDrop() && monitor.getDropResult(),
        }),
    })

    // hack to prevent flicker before cache updates 
    if (dropResult && dropResult.itemId === id && dropResult.targetId !== item.originId) {
        movedOut.current = true
    }
    if (movedOut.current) return null

    return (
        <div className={`draggable ${isDragging ? ' dragged' : ''}`}>
            <div
                className={`board-card pos-relative ${!isDragging && isHovered ? ' hovered' : ''}`}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                ref={dragRef}
            >
                <Link to={`${tabNavLinks.tickets.dynamicPath}${id}`}>
                    <div className='ticket-card t-sm'>
                        <header className='t-s mb-1'>
                            <HighlightedText text={name} mark={searchTerm} />
                        </header>
                        <p className='t-xs mb-2'>
                            {lbl.owner} · <HighlightedText text={owner?.fullName || "?"} mark={searchTerm} />
                        </p>
                        <div className='board-card__labels mb-2'>
                            <div className='d-flex flex-wrap'>
                                <Label
                                    className='board-card-label'
                                    iconProps={{ type: priorityOptions[priority].icon }}
                                    label={<HighlightedText text={priorityOptions[priority].label} mark={searchTerm} />}
                                    classModifier={priorityOptions[priority].color}
                                />
                                { category &&
                                    <Label
                                        className='board-card-label'
                                        label={<HighlightedText text={category.name} mark={searchTerm} />}
                                        classModifier='bordered'
                                    />
                                }
                            </div>
                            {labels && labels.map(({ name, color }) => (
                                <Label
                                    key={name}
                                    className='board-card-label bg-light'
                                    iconProps={{ type: 'label', fill: color }}
                                    label={<HighlightedText text={name} mark={searchTerm} />}
                                    classModifier={color}
                                />
                            ))}
                        </div>
                        <footer>
                            {assignee &&
                                <div className='d-inline-flex'>
                                    <ProfileImage url={assignee.picture} size='xs' />
                                    <div className='ml-1 mr-1 no-wrap'>
                                        <HighlightedText text={assignee.fullName} mark={searchTerm} />
                                    </div>
                                </div>
                            }
                            <Label
                                className='time-label'
                                iconProps={{ type: 'time' }}
                                label={createdDateLabel}
                                classModifier={createdDateOffset < -2 ? 'red' : createdDateOffset < -1 ? 'yellow' : 'grey'}
                            />
                        </footer>
                    </div>
                </Link>
                {!!unreadMessageCount &&
                    <Counter count={unreadMessageCount}/>
                }
            </div>
        </div>
    )
}

export default TicketCard