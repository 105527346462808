import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useReactiveVar, useMutation } from '@apollo/client'
import { newDraftsVar, openedTabsVar } from '../../cache';
import { CREATE_TASK } from '../mutations'
import { tabNavLinks, lbl } from '../../constants'
import { TextField } from '../../forms'
import TextEditor from '../../components/TextEditor'
import TaskProperties from './TaskProperties'
import { formatDate } from '../../utils'


const NewTask = () => {
    const history = useHistory()
    const taskDraft = useReactiveVar(newDraftsVar).tasks
    const openedTabs = useReactiveVar(openedTabsVar)
    const { relativeUrl } = tabNavLinks.tasks.new
    const { label: dueDateLabel, dayDiff: dueDateOffset } = taskDraft.dueDate ? formatDate(taskDraft.dueDate) : {}
    const defaultProperties = { priority: '2_MEDIUM', status: '1_TODO', approvalRequired: true }

    const [createTask, { error, loading }] = useMutation(CREATE_TASK, {
        onCompleted: ({ createTask: { task } }) => {
            openedTabs.delete(relativeUrl)
            openedTabsVar(new Map([...openedTabs]))
            newDraftsVar({ ...newDraftsVar(), tasks: defaultProperties })
            history.push(`${tabNavLinks.tasks.dynamicPath}${task.id}`)
        },
        update(cache, { data: { createTask: { task } } }) {
            cache.modify({
                fields: {
                    tasks(existingTasks = []) {
                        return [task, ...existingTasks];
                    }
                }
            })
            if (task.ticket) {
                cache.modify({
                    id: cache.identify({ __typename: 'TicketType', id: task.ticket.id }),
                    fields: {
                        tasks(existingTasks = []) {
                            return [task, ...existingTasks]
                        }
                    }
                })
            }
        }
    })

    const onSubmit = () => {
        const {
            assignee,
            ticket,
            ...variables
        } = taskDraft

        createTask({ variables })
    }

    const errMsg = error && error.message.replace(/',|'|]|\[/g, '')

    useEffect(() => {
        openedTabsVar(new Map([...openedTabsVar(), [relativeUrl, {
            name: lbl.newTask,
            id: 'new',
            relativeUrl,
            icon: 'task'
        }]]))
    }, [relativeUrl])

    return (
        <div className='split-panel'>
            <section className='split-panel--main task'>
                <button
                    onClick={onSubmit}
                    className='btn btn--primary btn--rounded t-none pos-fixed'
                    style={{ position: 'absolute', right: '3vw', top: '10px' }}
                    disabled={loading || !taskDraft.name || !taskDraft.description}
                >
                    {loading ? <div className='loader loader--inline'/> : 'Save task'}
                </button>
                <TextField
                    onSubmit={value => {
                        const name = value.replace(/&nbsp;/g, ' ').trim()
                        if (name !== taskDraft.name) {
                            newDraftsVar({ ...newDraftsVar(), tasks: { ...taskDraft, name } })
                        }
                    }}
                    inputProps={{
                        type: 'input',
                        name: 'name',
                        value: taskDraft.name || '',
                        role: 'textbox',
                        contentEditable: true,
                        placeholder: lbl.taskTitle,
                        autoFocus: true
                    }}
                    size='2xl'
                    as='div'
                />
                {errMsg && <div className='error t-red'>{errMsg}</div>}
                <br/>
                <div className='task__editor'>
                    <TextEditor
                        model={taskDraft.description || ''}
                        onModelChange={(description) => {
                            newDraftsVar({
                                ...newDraftsVar(),
                                tasks: { ...taskDraft, description }
                            })
                        }}
                        config={{
                            placeholderText: lbl.addDetails,
                            toolbarButtons: ['bold', 'italic', 'underline', 'backgroundColor', '|', 'formatOL', 'formatUL', 'outdent', 'indent', 'align', '|', 'insertTemplate', 'insertLink', 'emoticons', '|', 'selectAll', 'clearFormatting', 'undo', 'redo'],
                        }}
                        immediateReactModelUpdate
                    />
                </div>
            </section>
            <aside className='split-panel--side split-panel--right'>
                <div className='split-panel--side-inner'>
                    <br/>
                    <TaskProperties
                        task={{ ...defaultProperties, ...taskDraft, dueDateLabel, dueDateOffset }}
                        onChange={obj => {
                            const updates = {}
                            Object.keys(obj).forEach(key => {
                                if (!obj[key] || obj[key] === 'NONE' || obj[key].id === 'NONE') {
                                    updates[key] = undefined
                                } else {
                                    updates[key] = obj[key]
                                }
                            })
                            
                            newDraftsVar({
                                ...newDraftsVar(),
                                tasks: { ...taskDraft, ...updates }
                            })
                        }}
                        isNew
                    />
                </div>
            </aside>
        </div>
    )
}

export default NewTask
