import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_PROPERTY_TENANTS_PER_ROOM } from '../queries'
import { tabNavLinks } from '../../constants'
import { Icon, UserChip } from '../../components'

const PropertyTenantsList = ({ propertyId }) => {
    const { data, loading, error } = useQuery(GET_PROPERTY_TENANTS_PER_ROOM, { variables: { propertyId } })

    if (loading) return <div className='loader pos-center-y' />

    if (error) return <div className='pos-center-y w-100 t-center'>{error.message}</div>
    
    if (data?.property) return (
        <ul>
            {data.property.rooms.map(unit => (
                <li key={'unit'+ unit.id} className='mt-1'>
                    <Link
                        to={`${tabNavLinks.properties.unit.dynamicPath}${unit.id}`}
                        className='d-inline-flex t-underline-hover'
                    >
                        <Icon type='room' variant='xs'/>
                        <div className='ml-05'>{unit.fullUnitName}</div>
                    </Link>
                    <dl className='dl'>
                        {unit.tenancies?.map(tenant => (
                            <div className='dl__li t-s' key={tenant.user.fullName}>
                                <dt></dt>
                                <dd style={{ flexDirection: 'column', alignItems: 'flex-start', paddingRight: '8px' }}>
                                    <Link
                                        to={`${tabNavLinks.contacts.dynamicPath}${tenant.user.id}`}
                                        className='d-inline-flex t-underline-hover mt-05'
                                    >
                                        <UserChip user={tenant.user} className='chip--guests'/>
                                    </Link>
                                </dd>
                            </div>
                        ))}
                    </dl>
                </li>
            ))}
            {!!data.property.roomlessTenants?.length &&
                <li className='mt-1 t-s'>
                    No assigned unit
                    <dl className='dl'>
                        {data.property.roomlessTenants.map(tenant => (
                            <div className='dl__li t-s' key={tenant.user.fullName}>
                                <dt></dt>
                                <dd style={{ flexDirection: 'column', alignItems: 'flex-start', paddingRight: '8px' }}>
                                    <Link
                                        to={`${tabNavLinks.contacts.dynamicPath}${tenant.user.id}`}
                                        className='d-inline-flex t-underline-hover mt-05'
                                    >
                                        <UserChip user={tenant.user} className='chip--guests'/>
                                    </Link>
                                </dd>
                            </div>
                        ))}
                    </dl>
                </li>
            }
        </ul>
    )
}

export default PropertyTenantsList
