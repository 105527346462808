import { GET_PRIORITY_OPTIONS, GET_ALL_USERS, GET_STATIC_STATUS_OPTIONS, GET_APPROVAL_REQUIRED_OPTIONS } from '../queries'
import { lbl, priorityOptions, statusOptions } from '../constants'
import { GET_TICKETS } from '../tickets/queries'
import { currentUserVar } from '../cache'


export const taskProperties = {
    priority: {
        field: 'priority',
        type: 'string',
        label: 'Priority',
        iconProps: { type: 'priority' },
        icon: 'priority',
        sortPath: 'priority',
        sortDir: -1,
        labelField: 'label',
        optionsQuery: { query: GET_PRIORITY_OPTIONS, name: 'priorityOptions' },
        getOptionProps: r => { 
            const root = typeof r === 'string' ? priorityOptions[r] : r
            return ({ ...root, iconProps: { type: root.icon } })
        },
        options: priorityOptions,
    },
    status: {
        field: 'status',
        type: 'string',
        label: 'Status',
        iconProps: { type: 'check-status' },
        icon: 'check-status',
        sortPath: 'status',
        optionsQuery: { query: GET_STATIC_STATUS_OPTIONS, name: 'statusOptions' },
        getOptionProps: r => { 
            const root = typeof r === 'string' ? statusOptions[r] : r
            return ({ ...root, iconProps: { type: root.icon } })
        },
    },
    assignee: {
        field: 'assignee',
        type: 'object',
        label: 'Assignee',
        actionLabel: 'Assign',
        iconProps: { type: 'assignee' },
        icon: 'assignee',
        sortPath: 'assignee.fullName',
        groupPath: 'assignee.fullName',
        objectPath: 'assignee',
        optionsQuery: { query: GET_ALL_USERS, name: 'users' },
        getOptionProps: root => {
            const isMe = root.id === currentUserVar().id
            return {
                ...root,
                label: `${root.fullName}${isMe ? ' (me)' : ''}`,
                actionLabel: `Assigned to ${isMe ? 'me' : root.fullName}`,
                classModifier: 'profile',
                icon: 'assignee',
            }
        }
    },
    createdBy: {
        field: 'createdBy',
        type: 'object',
        label: 'Task creator',
        sortPath: 'createdBy.fullName',
        groupPath: 'createdBy.fullName',
        objectPath: 'createdBy',
        icon: 'reporter',
        iconProps: { type: 'reporter' },
        optionsQuery: { query: GET_ALL_USERS, name: 'users' },
        getOptionProps: root => {
            const isMe = root.id === currentUserVar().id
            return {
                ...root,
                label: `${root.fullName}${isMe ? ' (me)' : ''}`,
                actionLabel: `Reported by ${isMe ? 'me' : root.fullName}`,
                classModifier: 'reporter',
                icon: 'reporter'
            }
        }
    },
    dueDate: {
        field: 'dueDate',
        type: 'string',
        label: 'Due date',
        iconProps: { type: 'time', variant: '2xs' },
        icon: 'time',
        sortPath: 'dueDate',
        sortDir: -1
    },
    name: {
        field: 'name',
        type: 'string',
        label: 'Name',
        sortPath: 'name',
        iconProps: { type: 'sort-text' },
    },
    description: {
        field: 'description',
        type: 'string',
        label: 'Description',
        iconProps: { type: 'sort-text' },
    },
    ticket: {
        field: 'ticket',
        type: 'object',
        label: 'Related ticket',
        sortPath: 'ticket.name',
        iconProps: { type: 'ticket' },
        icon: 'ticket',
        optionsQuery: { query: GET_TICKETS, name: 'tickets' },
        getOptionProps: root => ({
            ...root, label: root.name, iconProps: { type: 'ticket' }, icon: 'ticket',
        })
    },
    archived: {
        field: 'archived',
        sortPath: 'archived',
        type: 'boolean',
        label: 'Archived',
        icon: 'archive',
        iconProps: { type: 'archive' },
        getOptionProps: root => ({
            label: root ? lbl.archived : lbl.active, iconProps: { type: root ? 'archive' : 'task' }
        })
    },
    me: {
        field: 'me',
        type: 'object',
        label: 'Assigned to me',
        iconProps: { type: 'assignee' },
        getOptionProps: () => {
            const { id, picture } = currentUserVar()
            return {
                field: 'assignee', id, picture, label: 'Assigned to me', icon: 'assignee'
            }
        }
    },
    approvalRequired: {
        field: 'approvalRequired',
        sortPath: 'approvalRequired',
        type: 'boolean',
        label: 'Approval',
        icon: 'checkmark',
        iconProps: { type: 'checkmark' },
        optionsQuery: { query: GET_APPROVAL_REQUIRED_OPTIONS, name: 'approvalRequiredOptions' },
        getOptionProps: r => {
            const root = typeof r === 'boolean' ? { id: r } : r
            const iconProps = { type: root.id ? 'checkmark' : 'close', fill: root.id ? 'green' : 'red' }
            if (typeof r === 'boolean') return { label: r ? lbl.required : lbl.notRequired, iconProps }
            return { ...root, iconProps }
        }
    }
}

export const listerCtrlOptions = {
    filter: ['priority', 'status', 'assignee', 'createdBy', 'ticket', 'approvalRequired'].map(key => taskProperties[key]),
    group: ['priority', 'status', 'assignee', 'createdBy', 'ticket', 'approvalRequired'].map(key => taskProperties[key]),
    sort: ['dueDate', 'name', 'status', 'assignee', 'priority', 'ticket', 'createdBy', 'approvalRequired'].map(key => taskProperties[key]),
    search: ['name', 'assignee', 'createdBy', 'ticket', 'priority'].map(key => taskProperties[key])
}

export const batchActions = ['archive', 'assignee', 'priority', 'status', 'approvalRequired']
export const listerColumns = ['status', 'name', 'dueDate', 'assignee', 'priority', 'ticket', 'createdBy', 'approvalRequired']