import { useContext } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { tabNavLinks } from '../../constants'
import { TabNavContext } from '../../components/TabNav'
import { GET_SERVICE_PAYMENT } from '../queries'


const ServicePaymentRedirect = () => {
    const { id } = useContext(TabNavContext)
    const { data, loading, error } = useQuery(GET_SERVICE_PAYMENT, { variables: { id } })
    const { service } = data?.servicePayment || {}

    if (loading) return <div className='loader pos-center-y' />
    
    if (error) return (
        <div className='t-red w-100 pos-center-y'>
            <center>{error.message}</center>
        </div>
    )

    if (service?.building) {
        return <Redirect to={`${tabNavLinks.properties.building.dynamicPath}${service.building.id}/services#service_payment_${id}`} />
    }

    if (service?.address) {
        return <Redirect to={`${tabNavLinks.properties.property.dynamicPath}${service.address.id}/services#service_payment_${id}`} />
    }

    if (service?.room) {
        return <Redirect to={`${tabNavLinks.properties.unit.dynamicPath}${service.room.id}/services#service_payment_${id}`} />
    }

    return null
}

export default ServicePaymentRedirect
