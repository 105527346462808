import { useQuery } from '@apollo/client'
import { GET_USER_ACTIVITY } from '../queries'
import ChangeLog from '../../components/ChangeLog'
import OnScrollLoader from '../../components/OnScrollLoader'

const UserActivity = ({ userId }) => {
    const { loading, data, error, fetchMore } = useQuery(GET_USER_ACTIVITY, {
        variables: { userId, offset: 0, limit: 20 }
    })
    const hasMore = !!data?.userActivity?.length ? data.userActivity[data.userActivity.length-1].hasMore : false

    if (error) return <div>Ups! :() {error.message}</div>
    if (!data && loading) return <div className='loader' />
    if (!data) return null
    if (data && data.userActivity?.length === 0) return (
        <div className='t-grey w-100 mx-auto my-auto'>
            <center>User has no activity</center>
        </div>
    )
    return (
        <>
            <ChangeLog
                items={data?.userActivity}
                isUserActivity
            />
            {hasMore &&
                <OnScrollLoader
                    onIntersect={() => {
                        fetchMore({
                            variables: {
                                offset: data.userActivity.length
                            }
                        })
                    }}
                />
            }
        </>
    )
}

export default UserActivity
