import { useQuery } from '@apollo/client'
import { useReactiveVar } from '@apollo/client'
import { GET_CONTACTS } from '../queries'
import Lister from '../../components/Lister'
import ContactListItem from './ContactListItem'
import { listerCtrlOptions, userProperties, listerColumns } from '../settings'
import { contactListCtrlsVar } from '../../cache'

const ContactList = () => {
    const vars = useReactiveVar(contactListCtrlsVar)
    const filters = vars.filters.map(f => `${f.field}___${f.id}`).sort().join(',')
    let variables = { ...vars, filters }
    const { data, loading, error, fetchMore } = useQuery(GET_CONTACTS, {
        variables,
        notifyOnNetworkStatusChange: true
    })

    if (error) return <div className='pos-center-y w-100 t-center'>{error.message}</div>

    return (
        <div className='tab__panel--main intersection-root'>
            <Lister
                options={listerCtrlOptions}
                optionsDict={userProperties}
                listerCtrlVar={contactListCtrlsVar}
                columnKeys={listerColumns}
                items={data?.users}
                showLoader={loading}
                fetchMore={fetchMore}
                queryFieldName='users'
                query={GET_CONTACTS}
                type='contacts'
            >
                {(props) => (
                    <ContactListItem
                        propKeys={listerColumns}
                        {...props}
                    />
                )}
            </Lister>
        </div>
    )
}

export default ContactList
