import { ProfileImage } from '../../components'

const CallAnswered = ({ receiver }) => (
    <>
        <p>Answered by:</p>
        <div className='d-inline-flex mt-1 mb-1'>
            <ProfileImage
                url={receiver.profile.picture}
                size='xs'
            />
            <div className='d-inline-block ml-1'>
                {receiver.fullName}
            </div>
        </div>
    </>
)

export default CallAnswered
