import Icon from './Icon'
import { lbl } from '../constants'

const ListerHeader = ({
    columnKeys,
    optionsDict,
    onSelectCtrl,
    listerCtrlVar,
    showFirstColumnLabel=false,
    isPaginated,
    ...props
}) => (
    <thead>
        <tr className='spacer-row'>
            {Array.apply(null, Array(columnKeys.length)).map((x, i) => <th key={i} />)}
        </tr>
        <tr className='lister__header'>
            {columnKeys.map((key, i) => {
                const { label, sortDir, sortPath, multi } = optionsDict[key]
                const sortField = isPaginated ? sortPath : key
                const isActive = listerCtrlVar().sortBy === sortField
                const sortDisabled = !onSelectCtrl || multi || !sortPath
                
                return (
                    <th key={key} className={`lister-header__cell${isActive ? ' active' : ''}`}>
                        <button
                            title={`${lbl.sortBy} ${label}`}
                            className='btn btn--inline-icon btn--naked'
                            disabled={sortDisabled}
                            onClick={() => {
                                const { sortBy, sortDir: activeSortDir, ...rest } = listerCtrlVar()
                                const ctrls = {
                                    ...rest,
                                    sortBy: sortField,
                                    sortDir: isActive ? activeSortDir * -1 : sortDir || 1
                                }
                                if (isPaginated) onSelectCtrl('sort', ctrls)
                                listerCtrlVar(ctrls)
                                if (!isPaginated) onSelectCtrl('sort')
                            }}
                        >
                            <div className='pos-relative'>
                                {(i !== 0 || showFirstColumnLabel) && label}
                                {!sortDisabled &&
                                    <Icon
                                        type='caret'
                                        variant='2xs'
                                        classAdditions={`sort-${listerCtrlVar().sortDir}`}
                                        wrapperClassAdditions={showFirstColumnLabel ? 'pos-absolute' : ''}
                                    />
                                }
                            </div>
                        </button>
                        {i !== columnKeys.length - 1 &&
                            <Icon type='dots-vertical' variant='xs' />
                        }
                    </th>
                )
            })}
        </tr>
    </thead>
)

export default ListerHeader
