import { useState, useRef, useEffect } from 'react'
import { usePrevious } from '../hooks/usePrevious'
import { Icon } from '../components'


const TextField = ({
    as='input',
    inputProps={},
    onSubmit,
    label,
    error,
    size='sm',
    classAdditions,
    preventSubmitOnBlur,
    innerContent='innerText',
    innerHtml,
    InputChild,
    inline,
    hidePencil=false,
    preventTagSwitch=false
}) => {
    let [value, setValue] = useState(inputProps.value || '')
    const Tag = value || preventTagSwitch ? as : 'input'
    const inputEl = useRef(null);
    let prevTag = usePrevious(Tag.toUpperCase())

    const handleSubmit = e => {
        e.preventDefault()
        value ? value !== inputProps.value && onSubmit(value) : setValue(inputProps.value)
    }

    const handleKeyDown = (e) => {
        if (Tag !== 'input' && e.keyCode === 13 && !e.shiftKey) {
            if (preventSubmitOnBlur) {
                handleSubmit(e)
            } else {
                inputEl.current.blur()
            }
        }
    }

    useEffect(() => {
        // move cursor position behind text when input element changes to div
        if (inputEl.current.tagName === 'DIV' && value && prevTag === 'INPUT') {
            inputEl.current.focus()

            inputEl.current.textContent = value
            var range = document.createRange()
            var sel = window.getSelection()
            
            range.setStart(inputEl.current, 1)
            range.collapse(true)
            
            sel.removeAllRanges()
            sel.addRange(range)
        }

        if (prevTag === 'DIV' && inputEl.current.tagName !== 'DIV') {
            inputEl.current.focus()
        }
    }, [Tag, prevTag, value])


    return(
        <form
            className={`textfield-form textfield-form--${label || inline ? 'inline' : 'block'} textfield-form--${size} ${classAdditions ? classAdditions : ''}`}
            method='post'
            autoComplete='off'
            onSubmit={handleSubmit}
        >
            <fieldset>
                {label &&
                    <label
                        className='textfield__label'
                        htmlFor={inputProps.name}
                    >
                        {label}
                    </label>
                }
                <Tag
                    ref={inputEl}
                    className='textfield__input'
                    onChange={e => setValue(e.target.value)}
                    onInput={Tag === 'div' ? e => setValue(e.target[innerContent]) : undefined}
                    dangerouslySetInnerHTML={Tag === 'div' && innerHtml ? { __html: innerHtml } : undefined}
                    onBlur={!preventSubmitOnBlur ? handleSubmit : undefined}
                    onKeyDown={handleKeyDown}
                    autoComplete='off'
                    {...inputProps}
                    value={value}
                >
                    {InputChild}
                </Tag>
                {error && <p className='input-message input-message--error'>{error.message}</p>}
                {!hidePencil && <Icon onClick={() => inputEl.current.focus()} type='pencil' variant={size} />}
            </fieldset>
        </form>
    )
}

export default TextField