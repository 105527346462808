import { Suspense, lazy } from 'react'
import { useQuery } from '@apollo/client'
import { GET_ATTACHMENTS_FOR_CONTENT_OBJECT } from '../../attachments/queries'
const FilesCarousel = lazy(() => import('../../attachments/components/FilesCarousel'))

const ThreadAttachments = ({ threadId }) => {
    const { data, loading, error } = useQuery(GET_ATTACHMENTS_FOR_CONTENT_OBJECT, {
        variables: { contentTypeModel: 'thread', objectId: threadId }
    })
    const attachments = data?.attachmentsForContentObject || []
    const renderThumbs = (items) => {
        return items.map((item, idx) => attachments[idx].originalFilename)
    }

    if (loading) return <div className='loader pos-center-y' />
    
    if (error) return (
        <div className='t-red w-100 pos-center-y'>
            <center>{error.message}</center>
        </div>
    )

    if (!!data && !attachments.length) return (
        <div className='w-100 pos-center-y'>
            <center>No attachments uploaded in this thread.</center>
        </div>
    )

    return (
        <Suspense fallback={<div className='loader pos-center-y'/>}>
            <FilesCarousel
                files={attachments}
                autoFocus
                showThumbs
                renderThumbs={renderThumbs}
                thumbWidth='auto'
            />
        </Suspense>
    )
}

export default ThreadAttachments
