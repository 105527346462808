import { gql } from '@apollo/client'

export const UNIT_FRAGMENT = gql`
    fragment roomFields on RoomType {
        id
        name
        unitNumber
        fullUnitName
        picture
        archived
    }
`

export const BUILDING_FRAGMENT = gql`
    fragment buildingFields on BuildingType {
        id
        name
        picture
        archived
    }
`

export const PROPERTY_FRAGMENT = gql`
    fragment propertyFields on PropertyType {
        id
        picture
        streetWithHouseNumber
        postcode
        archived
    }
`

export const HOA_FRAGMENT = gql`
    fragment hoaFields on HomeownerAssociationType {
        id
        name
        vveManager {
            id
            user {
                id
                fullName
                picture
                email
                profile {
                    id
                    phoneNumber
                }
            }
        }
    }
`

export const CENTRAL_HEATING_FRAGMENT = gql`
    fragment centralHeatingFields on CentralHeatingType {
        id
        brand
        model
        location
        dateInstalled
        lastCheckUpDate
        nextCheckUpDueDate
        notes
        repairContact {
            id
            company {
                id
                name
            }
            user {
                id
                fullName
                email
                picture
                profile {
                    id
                    phoneNumber
                }
            }
        }
    }
`

export const BUILDING_DETAIL_FRAGMENT = gql`
    fragment allBuildingFields on BuildingType {
        ...buildingFields
        keycode
        city {
            id
            name
        }
        owner {
            id
            user {
                id
                fullName
                picture
                email
                profile {
                    id
                    phoneNumber
                }
            }
        }
        caretaker {
            id
            company {
                id
                name
            }
            user {
                id
                fullName
                picture
                email
                profile {
                    id
                    phoneNumber
                }
            }
        }
        homeownerAssociation {
            ...hoaFields
        }
    }
    ${BUILDING_FRAGMENT}
    ${HOA_FRAGMENT}
`

export const PROPERTY_DETAIL_FRAGMENT = gql`
    fragment AllPropertyFields on PropertyType {
        ...propertyFields
        streetName
        houseNumber
        houseNumberAddition
        sizeSqm
        serviceCostDivisionModel
        monthlyOwnerServiceCost
        monthlyServiceCost
        otherCosts
        hasFireSafetySystem
        minOccupancy
        maxOccupancy
        keycode
        propertyType {
            id
            name
        }
        operationalModel {
            id
            name
        }
        city {
            id
            name
        }
        building {
            ...allBuildingFields
        }
        tenantRepresentative {
            id
            user {
                id
                fullName
                picture
                email
                profile {
                    id
                    phoneNumber
                }
            }
        }
        rooms {
            ...roomFields
            operationalModel {
                id
                name
            }
            sizeSqm
            monthlyBasicRent
            monthlyServiceCost
            deposit
        }
        owner {
            id
            user {
                id
                fullName
                picture
                email
                profile {
                    id
                    phoneNumber
                }
            }
        }
        centralHeating {
            ...centralHeatingFields
        }
    }
    ${UNIT_FRAGMENT}
    ${PROPERTY_FRAGMENT}
    ${BUILDING_DETAIL_FRAGMENT}
    ${HOA_FRAGMENT}
    ${CENTRAL_HEATING_FRAGMENT}
`

export const PROPERTY_LIST_FRAGMENT = gql`
    fragment PropertyListerFields on PropertyType {
        id
        streetWithHouseNumber
        postcode
        archived
        propertyType {
            id
            name
        }
        operationalModel {
            id
            name
        }
        building {
            ...buildingFields
            city {
                id
                name
            }
        }
        rooms {
            ...roomFields
        }
    }
    ${UNIT_FRAGMENT}
    ${BUILDING_FRAGMENT}
`

export const CONTRACT_FRAGMENT = gql`
    fragment ContractFields on ContractType {
        startDate
        endDate
        forIndefinitePeriod
        monthlyRent
        monthlyServiceCost
        otherCosts
        deposit
        active
        archived
    }
`

export const TENANT_CONTRACT_FRAGMENT = gql`
    fragment TenantContractFields on TenantContractType {
        id
        startDate
        endDate
        forIndefinitePeriod
        monthlyRent
        monthlyServiceCost
        serviceCostDescription
        otherCosts
        deposit
        active
        archived
        type {
            id
            name
        }
    }
`

export const OWNER_CONTRACT_FRAGMENT = gql`
    fragment OwnerContractFields on OwnerContractType {
        id
        startDate
        endDate
        forIndefinitePeriod
        monthlyRent
        monthlyServiceCost
        serviceCostDescription
        otherCosts
        deposit
        active
        archived
        owner {
            id
            user {
                id
                fullName
            }
            contracts {
                id
            }
        }
        buildings {
            id
        }
        properties {
            id
        }
        units {
            id
        }
    }
`

export const TENANCY_AGREEMENT_FRAGMENT = gql`
    fragment TenancyAgreementFields on TenancyAgreementType {
        id
        unit {
            id
            fullUnitName
            longName
            sizeSqm
            monthlyBasicRent
            monthlyServiceCost
            deposit
        }
        contractSignedDate
        checkInDate
        checkOutDate
        rentResponsabilityStartDate
        rentResponsabilityEndDate
        monthlyRent
        monthlyServiceCost
        otherCosts
        deposit
        depositReimbursed
        forIndefinitePeriod
        active
        archived
    }
`

export const LEAD_FRAGMENT = gql`
    fragment LeadFields on LeadType {
        id
        budget
        preferredCheckInDate
        unit {
            id
            fullUnitName
        }
        property {
            id
            streetWithHouseNumber
        }
        archived
    }
`

export const SERVICE_FRAGMENT = gql`
    fragment ServiceFields on ServiceType {
        id
        type {
            id
            name
            isUtility
            isInternet
            emoji
        }
        provider {
            id
            user {
                id
                fullName
            }
            company {
                id
                name
            }
        }
        price
        contractFile {
            id
            url
            originalFilename
        }
        contractUrl
        serviceFrequency
        paymentFrequency
        note
        archived
        internet {
            packageType
            modemCode
            modemLocation
            networkName
            networkPassword
        }
    }
`

export const SERVICE_PAYMENT_FRAGMENT = gql`
    fragment ServicePaymentFields on ServicePaymentType {
        id
        periodStartDate
        periodEndDate
        dueDate
        paymentDate
        paidBy {
            id
            user {
                id
                fullName
            }
        }
        amount
        file {
            id
            url
            originalFilename
        }
        note
        archived
    }
`
