import { useMutation } from '@apollo/client'
import { UPDATE_LANDLORD } from '../mutations'
import Form from '../../forms/Form'


const LandlordInfoForm = ({ user }) => {
    const initialState = {
        mandateAmount: user.landlord.mandateAmount,
    }
    const [updateLandlord, { loading, called, data, error }] = useMutation(UPDATE_LANDLORD)

    const success = !error && !loading && called && !!data?.updateLandlord?.landlord

    const fields = [
        { name: 'mandateAmount', type: 'number' },
    ]

    return (
        <Form
            fields={fields}
            initialState={initialState}
            onSubmit={(data) => updateLandlord({ variables: { landlordId: user.landlord.id, ...data } })}
            isSubmitting={loading}
            isSuccess={success}
        />
    )
}

export default LandlordInfoForm
