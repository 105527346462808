import { useState } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'
import { GET_UNIT_LEADS, GET_TENANTS } from '../queries'
import { CREATE_LEAD } from '../mutations'
import { newDraftsVar } from '../../cache'
import { lbl } from '../../constants'
import LeadAccordion from './LeadAccordion'


const UnitLeads = ({ unit }) => {
    const { id } = unit
    const { loading, data, client } = useQuery(GET_UNIT_LEADS, { variables: { id } })
    const {data: tenantsData, error: tenantsError} = useQuery(GET_TENANTS)
    const [newLeadOpen, setNewLeadOpen] = useState(false)
    let { unitLeads } = useReactiveVar(newDraftsVar)
    let draft = unitLeads[id] || {}

    const getTenantOptions = ({ tenant }) => tenantsData?.tenants ? tenantsData.tenants.map(({ id, user }) => ({ value: id, label: user.fullName })) : [
        { value: tenant?.id, label: tenant?.user.fullName },
        { value: 'tenant-x', label: tenantsError?.message || 'Loading ...' }
    ]

    return (
        <>
            {loading && <div className='loader pos-center-y'/>}
            {newLeadOpen ?
                <LeadAccordion
                    accordionProps={{
                        isOpen: true,
                        className: 'bg-lightblue p-1 br-05 mb-1',
                        label: lbl.newLead,
                        onOpenCb: () => { setNewLeadOpen(false) }
                    }}
                    draft={{ ...draft, unitId: id, propertyId: unit.address?.id }}
                    units={[unit]}
                    extraFields={[
                        { name: 'tenantId', lblKey: 'tenant', As: 'search-select', options: getTenantOptions({}), required: true, wide: true },
                        { name: 'propertyId', lblKey: 'property', As: 'select', options: [
                            { value: unit.address?.id, label: unit.address?.streetWithHouseNumber}
                        ], required: true, disabled: true, wide: true }
                    ]}
                    disableFields={['unitId']}
                    submitMutation={CREATE_LEAD}
                    onCompleted={() => {
                        client.cache.evict({
                            id: `PropertyType:${unit.address.id}`,
                            fieldName: 'propertyLeads',
                            variables: { includeUnitLeads: true }
                        })
                        client.cache.gc()
                    }}
                    close={() => { setNewLeadOpen(false) }}
                    setDraft={(leadDraft) => {
                        const drafts = { ...newDraftsVar()}
                        drafts.unitLeads[id] = leadDraft
                        newDraftsVar(drafts)
                    }}
                />
            :
                <button
                    className='btn btn--naked btn--new-item w-100 mb-2'
                    onClick={() => { setNewLeadOpen(true) }}
                >
                    <span className="plus">+</span>
                    {lbl.addNewUnitLead}
                </button>
            }
            {data?.unit?.unitLeads?.map((lead) => (
                <LeadAccordion
                    key={'lead' + lead.id}
                    lead={{ ...lead, unit: { id: unit.id, fullName: unit.fullName } }}
                    units={[unit]}
                    extraFields={[
                        { name: 'tenantId', lblKey: 'tenant', As: 'select', options: getTenantOptions(lead), required: true, wide: true },
                        { name: 'propertyId', lblKey: 'property', As: 'select', options: [
                            { value: unit.address?.id, label: unit.address?.streetWithHouseNumber}
                        ], disabled: true, wide: true }
                    ]}
                    disableFields={['unitId']}
                />
            ))}
        </>
    )
}

export default UnitLeads
