import { useMutation, useQuery } from '@apollo/client'
import { UPDATE_BANK_ACCOUNT } from '../mutations'
import Form from '../../forms/Form'
import { GET_BANK_ACCOUNT_BY_USER_ID } from '../queries'

const fields = [
    { name: 'accountNumber' },
    { name: 'accountHolderName' },
    { name: 'accountHolderAddress', wide: true, placeholder: 'street name/number, postal code, city, country' },
    { name: 'bankName' },
    { name: 'bicCode'},
    { name: 'bankAddress', wide: true, placeholder: 'street name/number, postal code, city, country' },
]

const FinancialInfoForm = ({ user }) => {
    const { data, loading, error } = useQuery(GET_BANK_ACCOUNT_BY_USER_ID, { variables: { userId: user.id } })
    const [updateBankAccount, { loading: updating, called, data: updatedData, error: updateError }] = useMutation(UPDATE_BANK_ACCOUNT)

    if (!data && loading) return <div className='loader pos-center-y' />
    if (error) return <div className='pos-center-y w-100 t-center'>{error.message}</div>
    if (!data) return null

    const bankAccount = data?.bankAccountByUserId || {}
    const initialState = {
        accountNumber: bankAccount.accountNumber,
        accountHolderName: bankAccount.accountHolderName,
        accountHolderAddress: bankAccount.accountHolderAddress,
        bankName: bankAccount.bankName,
        bicCode: bankAccount.bicCode,
        bankAddress: bankAccount.bankAddress,
    }

    const success = !updateError && !updating && called && !!updatedData?.updateBankAccount?.bankAccount

    return (
        <Form
            fields={fields}
            initialState={initialState}
            onSubmit={data => updateBankAccount({ variables: { userId: user.id, ...data } })}
            isSubmitting={updating}
            isSuccess={success}
        />
    )
}

export default FinancialInfoForm
