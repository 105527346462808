import { useHistory } from 'react-router-dom'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { useDrop } from 'react-dnd'
import { DragTypes, lbl } from '../../constants'
import { newDraftsVar } from '../../cache'
import { GET_TICKETS_BY_PIPELINE_STAGE } from '../queries'
import { UPDATE_TICKET, UPDATE_PIPELINE_STAGE } from '../mutations'
import { TextField } from '../../forms'
import { Icon, OnScrollLoader } from '../../components'
import TicketCard from './TicketCard'


const TicketBoardColumn = ({
    index,
    id,
    name,
    isFirst,
    isLast,
    ticketCount,
    listerCtrlVar,
    container
}) => {
    const history = useHistory()
    const vars = useReactiveVar(listerCtrlVar)
    const filters = vars.filters.map(f => `${f.field}___${f.id}`).sort().join(',')
    let variables = { pipelineStageId: id, ...vars, filters }
    const { data, loading, error, fetchMore } = useQuery(GET_TICKETS_BY_PIPELINE_STAGE, {
        variables,
        notifyOnNetworkStatusChange: true
    })
    const { tickets, cursor, hasMore, filteredTicketCount } = data ? data.ticketsByPipelineStage : {}
    const [updateTicket] = useMutation(UPDATE_TICKET)
    const [updatePipelineStage] = useMutation(UPDATE_PIPELINE_STAGE)

    const [{ hovered }, drop] = useDrop({
        accept: DragTypes.TICKET,
        drop: ({ itemId, originId }) => {
            if (id !== originId) {
                updateTicket({
                    variables: { id: itemId, pipelineStageId: id },
                    update(cache, { data: { updateTicket } }) {
                        // remove ticket from previous stage
                        cache.updateQuery({
                            query: GET_TICKETS_BY_PIPELINE_STAGE,
                            variables: { ...variables, pipelineStageId: originId, preventMerge: true }
                        }, ({ ticketsByPipelineStage }) => {
                            return {
                                ticketsByPipelineStage: {
                                    ...ticketsByPipelineStage,
                                    tickets: ticketsByPipelineStage.tickets.filter(t => itemId !== t.id),
                                }
                            }
                        })

                        cache.modify({
                            id: cache.identify({ __typename: 'PipelineStageType', id: originId }),
                            fields: {
                                ticketCount(prev) {
                                    return prev - 1
                                }
                            }
                        })
                        // add ticket to next stage
                        cache.updateQuery({
                            query: GET_TICKETS_BY_PIPELINE_STAGE,
                            variables: { ...variables, preventMerge: true }
                        }, ({ ticketsByPipelineStage }) => {
                            return {
                                ticketsByPipelineStage: {
                                    ...ticketsByPipelineStage,
                                    tickets: [updateTicket.ticket, ...ticketsByPipelineStage.tickets]
                                }
                            }
                        })
                        
                        cache.modify({
                            id: cache.identify({ __typename: 'PipelineStageType', id }),
                            fields: {
                                ticketCount(prev) {
                                    return prev + 1
                                }
                            }
                        })
                    }
                })
                return { targetId: id, itemId }
            }
        },
        collect: (monitor) => ({
            hovered: !!monitor.isOver() && (monitor.getItem().originId !== id || true) //remove true flag to remove hovered ui from origin column
        })
    })

    const onScroll = () => {
        fetchMore({ variables: { ...variables, cursor } })
    }

    return (
        <div className={`board-column board-column--${index}`}>
            <header className='board-column__header no-wrap'>
                <div className={`board-column-title board-column-title--${isFirst ? 'grey' : isLast ? 'green' : 'yellow'}`}>
                    <TextField
                        classAdditions='textfield-form--board-column-name'
                        onSubmit={value => name !== value ? updatePipelineStage({ variables: { id, name: value } }) : null}
                        inputProps={{
                            type: 'input',
                            name: 'name',
                            value: name || '',
                            placeholder: lbl.addStatusName
                        }}
                        size='sm'
                        inline
                    />
                    <Icon
                        type={`status-${isFirst ? '1' : isLast ? '3' : '2'}`}
                        wrapperClassAdditions='icon-wrapper--status'
                        variant='sm'
                    />
                </div>
                {!!ticketCount &&
                    <div className='t-sm t-grey mr-2 align-self-center'>
                        {data && filteredTicketCount !== ticketCount ? `${filteredTicketCount} of ` : ''}{ticketCount} tickets
                    </div>
                }
            </header>
            <div
                ref={drop}
                className={`board-column__container${hovered ? ' hovered' : ''}`}
            >
                <div className='board-column__tickets'>
                    {tickets && tickets.map((ticket, i) => (
                        <TicketCard
                            key={(i + ticket.name)}
                            item={{
                                type: DragTypes.TICKET,
                                itemId: ticket.id,
                                originId: ticket.pipelineStage.id
                            }}
                            {...ticket}
                        />
                    ))}
                    {loading ?
                        <div className='loader' style={{ marginBottom: '10px' }} />
                    : error ?
                        <div className='pos-center-y w-100 t-center'>{error.message}</div>
                    : hasMore ?
                        <OnScrollLoader
                            onIntersect={onScroll}
                            root={container}
                            rootMargin='1600px'
                        />
                    : null}
                </div>
                <button
                    className='btn btn--new-item'
                    onClick={() => {
                        newDraftsVar({ ...newDraftsVar(), tickets: { pipelineStageId: id, priority: '2_MEDIUM' } })
                        history.push('/tickets/new')
                    }}
                >
                    <span className='plus'>+</span> New ticket...
                </button>
            </div>
        </div>
    )
}

export default TicketBoardColumn
