import { useMutation } from '@apollo/client'
import { lastActiveThreadVar, preventInboxSwitch } from '../cache'
import {
    MARK_AS_READ,
    MARK_AS_UNREAD,
    JOIN_THREADS,
    LEAVE_THREADS,
    PIN_THREADS,
    UNPIN_THREADS,
    SUBSCRIBE_TO_THREADS,
    UNSUBSCRIBE_FROM_THREADS,
    ARCHIVE_THREADS,
    ACTIVATE_THREADS
} from './mutations'
import { GET_UNREAD_THREADS } from './queries'


export const useMarkAsReadMutation = (updateUnreadThreads=true) => useMutation(MARK_AS_READ, {
    update(cache, { data: { markAsRead: { threads } } }) {
        const threadIds = []
        let readCount = 0
        
        threads.forEach(thread => {
            let readCountInThread = 0
            cache.modify({
                id: cache.identify({ __typename: 'ThreadType', id: thread.id }),
                fields: {
                    unreadCount(prevCount) {
                        readCountInThread = prevCount
                        return 0
                    },
                    isUnread(prev) {
                        if (prev && !readCountInThread) readCountInThread = 1
                        return false
                    },
                    unreadMessages() { return [] }
                }
            })
            if (thread.membership) {
                cache.modify({
                    id: cache.identify({ __typename: 'ThreadMembershipType', id: thread.membership.id }),
                    fields: {
                        isUnread(prev) {
                            if (prev && !readCountInThread) readCountInThread = 1
                            return false
                        }
                    }
                })
            }
            readCount += readCountInThread
            threadIds.push(thread.id)
        })

        if (updateUnreadThreads) {
            cache.updateQuery({ query: GET_UNREAD_THREADS }, (data) => {
                if (!data?.unreadThreads) return undefined
                let newUnreadCount = data.unreadThreads.unreadMessageCount - readCount
                if (newUnreadCount < 0) newUnreadCount = 0

                return { unreadThreads: {
                    ...data.unreadThreads,
                    threads: data.unreadThreads.threads.filter(t => !threadIds.includes(t.id)),
                    unreadMessageCount: newUnreadCount
                } }
            })
        }
    }
})

export const useMarkAsUnreadMutation = () => useMutation(MARK_AS_UNREAD)

export const useArchiveThreadsMutation = () => {
    const [archiveThreads, { data, loading, error}] = useMutation(ARCHIVE_THREADS, {
        update(cache, { data: { archiveThreads: { threads } } }) {
            cache.modify({
                fields: {
                    archivedThreads(prev) {
                        return prev && [...prev, threads]
                    }
                }
            })
        }
    })
  
    return [archiveThreads, { data, loading, error }]
}


export const useActivateThreadsMutation = () => {
    const [activateThreads, { data, loading, error}] = useMutation(ACTIVATE_THREADS, {
        update(cache, { data: { activateThreads: { threads } } }) {
            cache.modify({
                fields: {
                    archivedThreads(prev, { readField }) {                
                        return prev && prev.filter(prevT => !threads.some(({ id }) => id === readField('id', prevT)))
                    }
                }
            })
            threads.forEach(thread => {
                cache.modify({
                    id: cache.identify({ __typename: 'ThreadType', id: thread.parent?.id }),
                    fields: {
                        subthreads(prev=[], { readField, toReference }) {  
                            if (!prev.some(prevRef => readField('id', thread) === readField('id', prevRef))) {
                                return [...prev, toReference(thread)]
                            }   
                            return prev
                        }
                    }
                })
            })
        }
    })
  
    return [activateThreads, { data, loading, error }]
}


export const useThreadActions = ({ activeThread } = {}) => {
    const [markAsRead] = useMarkAsReadMutation()
    const [markAsUnread] = useMarkAsUnreadMutation()
    const [archiveThreads] = useArchiveThreadsMutation()
    const [activateThreads] = useActivateThreadsMutation()
    const [pinThreads] = useMutation(PIN_THREADS, {
        update(cache, { data: { pinThreads } }) {
            cache.modify({
                fields: {
                    pinnedThreads(existing=[]) {
                        return [...existing, ...pinThreads.threads]
                    }
                }
            })
        }
    })
    const [unpinThreads] = useMutation(UNPIN_THREADS, {
        update(cache, { data: { unpinThreads } }) {
            cache.modify({
                fields: {
                    pinnedThreads(existing=[], { readField }) {
                        return existing.filter(e => !unpinThreads.threads.some(({ id }) => id === readField('id', e)))
                    }
                }
            })
        }
    })
    const [subscribeToThreads] = useMutation(SUBSCRIBE_TO_THREADS)
    const [unsubscribeFromThreads] = useMutation(UNSUBSCRIBE_FROM_THREADS)
    const [joinThreads] = useMutation(JOIN_THREADS)
    const [leaveThreads] = useMutation(LEAVE_THREADS)

    const getThreadActions = ({ threads, threadIds }) => {
        const isAnyUnpinned = threads.some(thread => !thread.membership?.isPinned)
        const isAnyUnsubscribed = threads.some(thread => !thread.membership?.isSubscribed)
        const isAnyUnread = threads.some(thread => !!thread.unreadCount || thread.membership?.isUnread)
        const isAnyActive = activeThread && threads.some(thread => thread.id === activeThread)
        const isAnyFixed = threads.some(thread => thread.fixed)
        const isAnyDirect = threads.some(thread => thread.isDirect)
        const isMemberInAll = threads.every(thread => !!thread.membership)
        const areAllArchived = threads.every(thread => thread.archived)
        const applyMutation = (mutation, refetchUnreadThreads=true) => mutation && mutation({
            variables: { threadIds },
            refetchQueries: refetchUnreadThreads ? [{ query: GET_UNREAD_THREADS }] : undefined
        })

        return [
            isAnyUnread ? {
                icon: 'checkmark',
                label: 'Mark as read',
                onClick: () => applyMutation(markAsRead)
            } : (!isAnyUnsubscribed && !isAnyActive) ? {
                icon: 'eye-off',
                label: 'Mark as unread',
                onClick: () => applyMutation(markAsUnread)
            } : null,
            !isMemberInAll ? {
                icon: 'contact-multi-add',
                label: 'Join',
                onClick: () => applyMutation(joinThreads)
            } : null,
            isMemberInAll && !isAnyDirect ? {
                icon: 'contact-multi-remove',
                label: 'Leave',
                onClick: () => {
                    if (threadIds.includes(lastActiveThreadVar())) preventInboxSwitch(true)
                    applyMutation(leaveThreads)
                }
            } : null,
            isMemberInAll && isAnyUnsubscribed ? {
                icon: 'bell',
                label: 'Subscribe',
                onClick: () => applyMutation(subscribeToThreads)
            } : null,
            !isAnyUnsubscribed ? {
                icon: 'bell-off',
                label: 'Unsubscribe',
                onClick: () => applyMutation(unsubscribeFromThreads)
            } : null,
            isMemberInAll && isAnyUnpinned ? {
                icon: 'pin',
                label: 'Pin',
                onClick: () => applyMutation(pinThreads, false)
            } : null,
            !isAnyUnpinned ? {
                icon: 'unpin',
                label: 'Unpin',
                onClick: () => applyMutation(unpinThreads, false)
            } : null,
            !isAnyFixed && !areAllArchived ? {
                icon: 'archive',
                label: 'Archive',
                onClick: () => applyMutation(archiveThreads)
            } : null,
            areAllArchived ? {
                icon: 'activate',
                label: 'Activate',
                onClick: () => applyMutation(activateThreads)
            } : null
        ].filter(Boolean)
    }

    return [getThreadActions, {
        markAsRead,
        joinThreads,
        pinThreads,
        unpinThreads,
        subscribeToThreads,
        unsubscribeFromThreads
    }]
}
