export function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        }
    )
}

export function snakeToCamelCase(str) {
    return str.replace(/([-_]\w)/g, g => g[1].toUpperCase())
}

export function camelToTitleCase(text) {
    const result = text.replace(/([A-Z])/g, " $1")
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
    return finalResult
}

export function formatDate(dateTime) {
    const date = new Date(dateTime)
    const today = new Date()
    date.setHours(0,0,0,0)
    today.setHours(0,0,0,0)
    const dayDiff = (date.getTime() - today.getTime()) / 86400000
    let label

    if (dayDiff >= 0) {
        if (dayDiff === 0) {
            label = 'Today'
        } else if (dayDiff === 1) {
            label = 'Tomorrow'
        } else if (dayDiff < 7) {
            label = date.toLocaleDateString([], { weekday: 'long' })
        }
    } else if (dayDiff === -1) {
        label = 'Yesterday'
    }
    
    return {
        label: label || date.toLocaleDateString([], { weekday: 'short', day: 'numeric', month: 'short' }),
        dayDiff
    }
}

export function formatDateString(dateTime) {
    if (!dateTime) return ''
    const date = new Date(dateTime)
    return date.toLocaleDateString()
}

export function formatDateTimeLabel(dateTimeStr) {
    const date = new Date(dateTimeStr)
    const today = new Date()
    const timeLabel = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    let dayLabel
    
    date.setHours(0,0,0,0)
    today.setHours(0,0,0,0)
    const dayDiff = (today.getTime() - date.getTime()) / 86400000

    if (dayDiff === 0) {
        dayLabel = 'Today'
    } else if (dayDiff === 1) {
        dayLabel = 'Yesterday'
    } else if (dayDiff < 7) {
        dayLabel = date.toLocaleDateString([], { weekday: 'long' })
    } else {
        dayLabel = date.toLocaleDateString([], { weekday: 'short', day: 'numeric', month: 'short' })
    }
    
    return { dayLabel, timeLabel }
}

export function dateInPast(dateString) {
    if (!dateString) return false
    const date = new Date(dateString)
    const today = new Date()
    if (date.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0)) return true
    return false
}

export function extractTextFromHtml(_html) {
    const el = document.createElement('div')
    el.innerHTML = _html
    return el.textContent || el.innerText
}

export const normalizeString = (str) => {
    if (!str || typeof str !== 'string') return ''
    return str.toLowerCase().normalize('NFKD').replace(/[\u0300-\u036F]/g, '')
}

export const numberToHHMMSS = (sec_num) => {
    let hours = Math.floor(sec_num / 3600)
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60)
    let seconds = sec_num - (hours * 3600) - (minutes * 60)

    if (hours   < 10) hours = '0' + hours
    if (minutes < 10) minutes = '0' + minutes
    if (seconds < 10) seconds = '0' + seconds
    
    if (hours === '00') {
        return minutes + ':' + seconds
    } else {
        return hours + ':' + minutes + ':' + seconds
    }
}

export const stripFieldFromPath = (str='') => {
    if (['profile.onlineStatus'].includes(str)) {
        return str
    } else {
        var lastIndex = str.lastIndexOf('.')
        if (lastIndex !== -1) {
            return str.substr(0, lastIndex)
        }
    }
    return str
}
