import { Link } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { GET_PROPERTIES } from '../queries'
import { lbl, tabNavLinks } from '../../constants'
import Accordion from '../../components/Accordion'
import ContactInfoForm from './BasicInfoForm'
import CommunicationForm from './CommunicationForm'
import FinancialInfoForm from './FinancialInfoForm'
import CompanyInfoForm from './CompanyInfoForm'
import LandlordInfoForm from './LandlordInfoForm'
import TenancyAgreementForm from '../../properties/components/TenancyAgreementForm'
import LeadAccordion from '../../properties/components/LeadAccordion'
import { Icon } from '../../components'
import { useState } from 'react'


const UserDetailForms = ({ user }) => {
    const [getProperties, { data: propertyData, called: propertiesCalled, error: propertyError }] = useLazyQuery(GET_PROPERTIES)
    const tenancyAgreement = user.tenant?.activeTenancyAgreement
    const lead = user.tenant?.activeLead
    const contractFor = tenancyAgreement ? tenancyAgreement.contract.unit ? 'unit' : tenancyAgreement.contract.property ? 'property' : '' : ''
    const leadFor = lead ? lead.unit ? 'unit' : lead.property ? 'property' : '' : ''
    const [leadPropertyChanged, setLeadPropertyChanged] = useState(false)
    return (
        <>
            <Accordion
                isOpen={!!user.tenant || !!user.partner}
                Tag='div'
                className='bg-light p-1 br-05 mb-1'
                headerClass='accordion__header--bordered t-md t-heading mr-4'
                label={lbl.personalInfo}
            >
                <div className='ml-4 mr-4'>
                    <ContactInfoForm user={user} />
                </div>
            </Accordion>
            
            {user.tenant &&
                <>
                    <Accordion
                        Tag='div'
                        className='bg-light p-1 br-05 mb-1'
                        headerClass='accordion__header--bordered t-md t-heading mr-4'
                        label={lbl.financialInfo}
                    >
                        <div className='ml-4 mr-4'>
                            <FinancialInfoForm user={user} />
                        </div>
                    </Accordion>
                    <Accordion
                        Tag='div'
                        className='bg-light p-1 br-05 mb-1'
                        headerClass='accordion__header--bordered t-md t-heading mr-4'
                        label={lbl.emergencyContactAndSocialMedia}
                    >
                        <div className='ml-4 mr-4'>
                            <CommunicationForm user={user} />
                        </div>
                    </Accordion>
                    {tenancyAgreement &&
                        <Accordion
                            Tag='div'
                            className='bg-light p-1 br-05 mb-1'
                            headerClass='accordion__header--bordered t-md t-heading mr-4'
                            label={
                                <div className='d-flex justify-space-between'>
                                    <span>{lbl.activeContractDetails}</span>
                                    {contractFor &&
                                        <Link
                                            to={`${tabNavLinks.properties[contractFor].dynamicPath}${tenancyAgreement.contract[contractFor].id}/contracts#contract_${tenancyAgreement.contract.id}_tenancy_agreement_${tenancyAgreement.id}`}
                                            className='btn--naked btn--inline-icon br-05'
                                            title={`Go to contract on ${contractFor} page`}
                                        >
                                            <Icon type='arrow-right' variant='xs' style={{ transform: 'rotate(-45deg)' }}/>
                                        </Link>
                                    }
                                </div>
                            }
                        >
                            <div className='ml-4 mr-4'>
                                <TenancyAgreementForm
                                    tenancyAgreement={{
                                        ...tenancyAgreement,
                                        tenant: { id: user.tenant.id, user }
                                    }}
                                    contract={tenancyAgreement.contract}
                                    isUnitContract={!!tenancyAgreement.contract.unit}
                                    contractId={tenancyAgreement.contract.id}
                                    propertyId={tenancyAgreement.contract.property?.id || tenancyAgreement.contract.unit.address.id}
                                    property={tenancyAgreement.contract.property || tenancyAgreement.contract.unit.address}
                                    units={tenancyAgreement.contract.unit ?
                                        [tenancyAgreement.contract.unit] :
                                        tenancyAgreement.contract.property.rooms
                                    }
                                    extraFields={[
                                        tenancyAgreement.contract.property && { name: 'property', disabled: true, value: tenancyAgreement.contract.property.longName, wide: true },
                                        tenancyAgreement.contract.unit && { name: 'unit', disabled: true, value: tenancyAgreement.contract.unit.longName, wide: true },
                                        { name: 'contractType', lblKey: 'contractType', disabled: true, value: tenancyAgreement.contract?.type?.name || '' },
                                        { name: 'contractForIndefinitePeriod', lblKey: 'forIndefinitePeriod', type: 'checkbox', disabled: true, value: !!tenancyAgreement.contract?.forIndefinitePeriod },
                                        { name: 'startDate', lblKey: 'contractStartDate', type: 'date', disabled: true, value: tenancyAgreement.contract?.startDate || '' },
                                        { name: 'endDate', lblKey: 'contractEndDate', type: 'date', disabled: true, value: tenancyAgreement.contract?.endDate || '' },
                                    ].filter(Boolean)}
                                    disableFields={['tenantId']}
                                />
                            </div>
                        </Accordion>
                    }
                    {lead &&
                        <LeadAccordion
                            accordionProps={{
                                onOpenCb: !propertiesCalled && !propertyData ? getProperties : () => {},
                                label: (
                                    <div className='d-flex justify-space-between'>
                                        <span>{lbl.latestLeadDetails}</span>
                                        {leadFor &&
                                            <Link
                                                to={`${tabNavLinks.properties[leadFor].dynamicPath}${lead[leadFor].id}/leads#lead_${lead.id}`}
                                                className='btn--naked btn--inline-icon br-05'
                                                title={`Go to lead on ${leadFor} page`}
                                            >
                                                <Icon type='arrow-right' variant='xs' style={{ transform: 'rotate(-45deg)' }}/>
                                            </Link>
                                        }
                                    </div>
                                )
                            }}
                            lead={lead}
                            units={lead.property?.rooms ? lead.property.rooms : lead.unit ? [lead.unit] : []}
                            extraFields={[
                                {
                                    name: 'propertyId',
                                    lblKey: 'property',
                                    As: 'search-select',
                                    wide: true,
                                    options: propertyData?.properties ? propertyData.properties.map(({ id, longName }) => ({ value: id, label: longName })) : [
                                        { value: lead.property?.id, label: lead.property?.longName },
                                        { value: 'property-x', label: propertyError?.message || 'Loading ...' }
                                    ],
                                    onChangeCb: (v) => setLeadPropertyChanged(v !== lead.property?.id)
                                },
                            ]}
                            disableFields={leadPropertyChanged ? ['unitId'] : undefined}
                            onCompleted={() => setLeadPropertyChanged(false)}
                            propertyUpdated={leadPropertyChanged}
                        />
                    }
                </>
            }
            {user.partner &&
                <Accordion
                    Tag='div'
                    className='bg-light p-1 br-05 mb-1'
                    headerClass='accordion__header--bordered t-md t-heading mr-4'
                    label={lbl.companyInfo}
                >
                    <div className='ml-4 mr-4'>
                        <CompanyInfoForm user={user} />
                    </div>
                </Accordion>
            }
            {user.landlord &&
                <Accordion
                    isOpen
                    Tag='div'
                    className='bg-light p-1 br-05 mb-1'
                    headerClass='accordion__header--bordered t-md t-heading mr-4'
                    label={lbl.propertyManagement}
                >
                    <div className='ml-4 mr-4'>
                        <LandlordInfoForm user={user} />
                    </div>
                </Accordion>
            }
        </>
    )
};

export default UserDetailForms
