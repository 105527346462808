import { useQuery } from '@apollo/client'
import { GET_TICKET } from '../queries'

const TicketLoader = ({ id, children }) => {
    const { loading, data } = useQuery(GET_TICKET, { variables: { id } })

    return children(data || {}, loading)
}

export default TicketLoader
