import { useState, useEffect } from 'react'
import { useLazyQuery, useReactiveVar } from '@apollo/client'
import { debounce } from 'lodash'
import { RESET_QUERY } from '../queries'
import { lbl } from '../constants'
import { HiddenSearchSelect, SelectOption, SearchInput } from '../forms'
import Icon from './Icon'
import Label from './Label'
import ProfileImage from './ProfileImage'
import { getProp, stripFieldFromPath } from '../utils'


const clearOption = { field: 'clear', iconProps: { type: 'close' } }
const OPTIONLESS_FILTER_FIELDS = ['me', 'missed', 'relevantToMe']

const ListerControls = ({
    options,
    optionsDict,
    listerCtrlVar,
    onSelectCtrl,
    controlsDisabled,
    onDisabledClick,
    classModifier='lister',
    type,
    renderCtrlChildren
}) => {
    const [openOption, setOpenOption] = useState(null)
    const [activeFilterContext, setActiveFilterContext] = useState()
    
    const [getFilterOptions, { data: filterOptionsData, loading: loadingFilterOptions }] = useLazyQuery(
        getProp('optionsQuery.query', activeFilterContext) || RESET_QUERY, {
            variables: getProp('optionsQuery.variables', activeFilterContext)
        }
    )
    const { groupBy, sortBy, sortDir, filters, searchTerm, limit } = useReactiveVar(listerCtrlVar)

    const isBtnDisabled = controlsDisabled || openOption || (activeFilterContext && filterOptionsData)

    const isPaginated = !!limit

    const getOptions = () => {
        let currentOptions = [...options[openOption]]

        if (openOption === 'filter') {
            if (optionsDict.me && !filters.some(f => f.label === optionsDict.me.label)) {
                currentOptions.unshift(optionsDict.me)
            }
            if (optionsDict.relevantToMe && filters.some(f => f.label === optionsDict.relevantToMe.label)) {
                currentOptions = currentOptions.filter(o => o.field !== optionsDict.relevantToMe.field)
            }
        }

        if (
            (openOption === 'group' && groupBy) ||
            (openOption === 'sort' && sortBy) ||
            (openOption === 'filter' && filters.length)
        ) {
           currentOptions.push(clearOption)
        }
        return currentOptions
    }

    const getFilterOptionsFromData = () => {
        const options = []
        filterOptionsData[activeFilterContext.optionsQuery.name].forEach(x => {
            const activeIndex = filters.findIndex(f => f.field === activeFilterContext.field && f.id === x.id)
            const option = {
                activeIndex,
                field: activeFilterContext.field,
                ...activeFilterContext.getOptionProps(x),
            }
            activeIndex > -1 ? options.unshift(option) : options.push(option)
        })
        return options
    }

    const removeFilterAtIndex = (i) => {
        filters.splice(i, 1)
        listerCtrlVar({ ...listerCtrlVar(), filters: filters })
        onSelectCtrl('filter-remove')
    }

    const setFilter = (selectedFilter) => {
        // remove if selected
        if (selectedFilter.activeIndex > -1) {
            return removeFilterAtIndex(selectedFilter.activeIndex)
        }

        const { field, id, picture, icon, itemIds } = selectedFilter
        const label = selectedFilter.actionLabel || selectedFilter.label
        const newFilter = { field, id, label, picture, icon, itemIds }
        const activeSiblingIndex = filters.findIndex(f => f.field === field)
        
        if (activeSiblingIndex > -1) {
            // replace with another filter from same context
            const newFilters = [...filters]
            newFilters.splice(activeSiblingIndex, 1, newFilter)
            listerCtrlVar({
                ...listerCtrlVar(),
                filters: newFilters
            })
            onSelectCtrl('filter-reset')
        } else {
            // add new filter
            const ctrls = { ...listerCtrlVar(), filters: [...filters, newFilter] }
            isPaginated && onSelectCtrl('filter-add', ctrls)
            listerCtrlVar(ctrls)
            !isPaginated && onSelectCtrl('filter-add')
        }
    }

    const setSearchTerm = (searchTerm) => {
        const ctrls = { ...listerCtrlVar(), searchTerm }
        isPaginated && onSelectCtrl('search', ctrls)
        listerCtrlVar(ctrls)
        !isPaginated && onSelectCtrl('search')
    }

    useEffect(() => {
        if (activeFilterContext) getFilterOptions()
    }, [activeFilterContext, getFilterOptions])

    return (
        <div
            className={`ctrls ctrls--${classModifier}`}
            onClick={controlsDisabled ? onDisabledClick : undefined}
        >
            <ul className='ctrls__list mb-3 t-grey'>
                {options.filter &&
                    <li className='mr-2'>
                        <ul className='ctrls__list'>
                            <li className='ctrls__listitem ctrls__listitem--ctrl'>
                                <button
                                    disabled={isBtnDisabled}
                                    className='btn btn--naked btn--inline-icon p-1'
                                    onClick={!isBtnDisabled ? () => {
                                        setOpenOption(option => option ? null : 'filter')
                                    } : undefined}>
                                    <Icon type='filter' variant='xs' />
                                    <div className='ctrls-listitem__label'>{filters.length ? lbl.filteredBy : lbl.filter}</div>
                                </button>
                            </li>
                            {!!filters.length &&
                                <>
                                    {filters.map((filter, i) => (
                                        <li
                                            className='ctrls__listitem ctrls__listitem--option mr-1'
                                            key={i + filter.label}
                                        >
                                            <button
                                                className={`btn btn--inline-icon btn--naked w-auto${filter.picture && filter.iconProps ? ' btn--profile-icon' : ''}`}
                                                onClick={() => setActiveFilterContext(options['filter'].find(({ field }) => field === filter.field))}
                                            >
                                                {filter.picture && <ProfileImage url={filter.picture} size='xs' />}
                                                <Label
                                                    { ...filter }
                                                    iconProps={filter.picture || !filter.icon ? undefined : { type: filter.icon, variant: '2xs '}}
                                                />
                                                <div className='btn close btn--inline-icon btn--naked'
                                                    title={`Remove ${filter.label} filter`}
                                                    onClick={e => {
                                                        e.stopPropagation()
                                                        removeFilterAtIndex(i)
                                                    }}
                                                >
                                                    <Icon type='close' variant='3xs' />
                                                </div>
                                            </button>
                                        </li>
                                    ))}
                                    <li className='ctrls__listitem ctrls__listitem--ctrl'>
                                        <button
                                            disabled={isBtnDisabled}
                                            className='btn btn--inline-icon btn--naked'
                                            onClick={() => setOpenOption(option => option ? null : 'filter')}
                                        >
                                            {lbl.addFilter}
                                        </button>
                                    </li>
                                </>
                            }
                        </ul>
                    </li>
                }

                {options.group &&
                    <li className='mr-2'>
                        <ul className='ctrls__list'>
                            <li className='ctrls__listitem ctrls__listitem--ctrl'>
                                <button
                                    disabled={isBtnDisabled}
                                    className='btn btn--naked btn--inline-icon p-1'
                                    onClick={!isBtnDisabled ? () => {
                                        setOpenOption(option => option ? null : 'group')
                                    } : undefined}>
                                    <Icon type='group' variant='xs' />
                                    <div className='ctrls-listitem__label'>{groupBy ? lbl.groupedBy : lbl.group}</div>
                                </button>
                            </li>
                            {groupBy &&
                                <li className='ctrls__listitem ctrls__listitem--option'>
                                    <button
                                        disabled={isBtnDisabled}
                                        className='btn btn--inline-icon btn--naked'
                                        onClick={!isBtnDisabled ? () => {
                                            setOpenOption(option => option ? null : 'group')
                                        } : undefined}
                                    >
                                        <Label {...optionsDict[stripFieldFromPath(groupBy)]} iconVariant='2xs' />
                                        <div className='btn close btn--inline-icon btn--naked'
                                            onClick={e => {
                                                e.stopPropagation()
                                                const ctrls = { ...listerCtrlVar(), groupBy: '' }
                                                if (isPaginated) onSelectCtrl('group', ctrls)
                                                listerCtrlVar(ctrls)
                                                if (!isPaginated) onSelectCtrl('group')
                                            }}
                                            title='Remove grouping'
                                        >
                                            <Icon type='close' variant='3xs' />
                                        </div>
                                    </button>
                                </li>
                            }
                        </ul>
                    </li>
                }

                {options.sort &&
                    <li className='mr-2'>
                        <ul className='ctrls__list'>
                            <li className='ctrls__listitem ctrls__listitem--ctrl'>
                                <button
                                    disabled={isBtnDisabled}
                                    className='btn btn--naked btn--inline-icon p-1'
                                    onClick={!isBtnDisabled ? () => {
                                        setOpenOption(option => option ? null : 'sort')
                                    } : undefined}>
                                    <div
                                        className={`toggle btn--inline-icon btn--naked${sortBy ? '' : ' disabled'}`}
                                        role='button'
                                        tabIndex='0'
                                        onClick={e => {
                                            e.stopPropagation()
                                            const ctrls = { ...listerCtrlVar(), sortDir: listerCtrlVar().sortDir * -1 }
                                            if (isPaginated) onSelectCtrl('sort', ctrls)
                                            listerCtrlVar(ctrls)
                                            if (!isPaginated) onSelectCtrl('sort')
                                        }}
                                        title='Toggle sort direction'
                                    >
                                        <Icon type={sortBy ? `sort-${sortDir}` : 'sort'} variant='xs' />
                                    </div>
                                    <div className='ctrls-listitem__label'>{sortBy ? lbl.sortedBy : lbl.sort}</div>
                                </button>
                            </li>
                            {sortBy &&
                                <li className='ctrls__listitem ctrls__listitem--option'>
                                    <button
                                        disabled={isBtnDisabled}
                                        className='btn btn--inline-icon btn--naked'
                                        onClick={!isBtnDisabled ? () => {
                                            setOpenOption(option => option ? null : 'sort')
                                        } : undefined}
                                    >
                                        <Label {...optionsDict[stripFieldFromPath(sortBy)]} iconVariant='2xs' />
                                        <div className='btn close btn--inline-icon btn--naked'
                                            onClick={e => {
                                                e.stopPropagation()
                                                const ctrls = { ...listerCtrlVar(), sortBy: '', sortDir: 1 }
                                                if (isPaginated) onSelectCtrl('sort', ctrls)
                                                listerCtrlVar(ctrls)
                                                if (!isPaginated) onSelectCtrl('sort')
                                            }}
                                            title='Remove sorting'
                                        >
                                            <Icon type='close' variant='3xs' />
                                        </div>
                                    </button>
                                </li>
                            }
                        </ul>
                    </li>
                }

                {options.search &&
                    <li className={`ctrls__listitem ctrls__listitem--search${searchTerm ? '' : ' empty'}`}>
                        <SearchInput
                            onSubmit={setSearchTerm}
                            onChange={debounce(setSearchTerm, 300)}
                            onClear={() => setSearchTerm('')}
                            inputProps={{ 'data-empty': !searchTerm }}
                            initialSearchTerm={listerCtrlVar().searchTerm}
                        >
                            {({ isFocused }) => (
                                <div className='ctrls__listitem--ctrl'>
                                    <button
                                        className='btn btn--inline-icon btn--naked'
                                        disabled={isBtnDisabled}
                                        type='submit'
                                    >
                                        <Icon type='search' variant='xs' />
                                        <span className='ctrls-listitem__label'>{searchTerm || isFocused ? lbl.searchBy : lbl.search}</span>
                                    </button>
                                </div>
                            )}
                        </SearchInput>
                    </li>
                }

                {renderCtrlChildren && renderCtrlChildren()}
            </ul>

            {openOption &&
                <HiddenSearchSelect
                    options={getOptions()}
                    onChange={([option]) => {
                        if (openOption === 'filter') {
                            if (option.field === 'clear') {
                                setOpenOption(false)
                                listerCtrlVar({ ...listerCtrlVar(), filters: [] })
                                onSelectCtrl('filter-remove')
                            } else if (OPTIONLESS_FILTER_FIELDS.includes(option.field)) {
                                setOpenOption(false)
                                setFilter(option.getOptionProps())
                            } else setActiveFilterContext(option)
                            return
                        }

                        const ctrls = {
                            ...listerCtrlVar(),
                            [`${openOption}By`]: option.field === 'clear' ? '' : isPaginated && option[`${openOption}Path`] ? option[`${openOption}Path`] : option.field,
                            [`${openOption}Dir`]: option.sortDir || 1
                        }

                        if (isPaginated) onSelectCtrl(openOption, ctrls)
                        listerCtrlVar(ctrls)
                        if (!isPaginated) onSelectCtrl(openOption)
                    }}
                    valueField='field'
                    searchBy='label'
                    placeholder={`${lbl[`${openOption}XBy`].replace('{}', type)}...`}
                    closeOnSelect
                    onDropdownClose={() => setOpenOption(false)}
                    keepSelectedInList={false}
                    contentRenderer={undefined}
                    itemRenderer={(props) => (
                        <SelectOption
                            {...props}
                            getLabel={({ item: { label, iconProps, field, getOptionProps } }) => (<>
                                {field === 'me' ?
                                    <ProfileImage url={getOptionProps().picture} size='xs' />
                                : iconProps ? 
                                    <Icon {...iconProps} variant='sm' />
                                : null}
                                <span>
                                    {field === 'clear' ? openOption === 'filter' ? 'Remove all filters' : `No ${openOption}ing` :
                                        OPTIONLESS_FILTER_FIELDS.includes(field) ? label :
                                        `${lbl[`${openOption}By`]} ${label.toLowerCase()}`
                                    }
                                </span>
                            </>)}
                        />
                    )}
                />
            }

            {activeFilterContext && (filterOptionsData || loadingFilterOptions) &&
                <HiddenSearchSelect
                    options={filterOptionsData ? getFilterOptionsFromData() : []}
                    disabled={loadingFilterOptions}
                    loading={loadingFilterOptions}
                    onChange={([selectedFilter]) => setFilter(selectedFilter)}
                    valueField='id'
                    searchBy='label'
                    placeholder={`${lbl.filterBy} ${activeFilterContext.label}...`}
                    closeOnSelect
                    onDropdownClose={() => setActiveFilterContext(null)}
                    contentRenderer={undefined}
                    itemRenderer={(props) => (
                        <SelectOption
                            {...props}
                            getLabel={({ item: { field, picture, label, iconProps, activeIndex } }) => {
                                const isProfile = field === 'assignee' || field === 'createdBy' || field === 'owner'
                                if (activeIndex > -1) return (
                                    <>
                                        <Icon variant='xs' type='close' />
                                        <span>{`Remove ${activeFilterContext.label.toLowerCase()} filter`}</span>
                                    </>
                                )
                                return (
                                    <>
                                        {isProfile && <ProfileImage url={picture} size='xs' />}
                                        {!isProfile && iconProps && <Icon variant='sm' {...iconProps} />}
                                        <span>{label}</span>
                                    </>
                                )
                            }}
                        />
                    )}
                />
            }
        </div>
    )
}

export default ListerControls
