import PropTypes from 'prop-types'
import { useQuery, useSubscription } from '@apollo/client'
import { initailCallsQueryVars } from '../cache'
import {
    ON_NEW_CHANGE_SUBSCRIPTION,
    ON_INSTANCE_DELETED_SUBSCRIPTION,
    ON_USER_STATUS_UPDATE_SUBSCRIPTION
} from '../subscriptions'
import { GET_NOTE, GET_NOTES_FOR_INSTANCE } from '../notes/queries'
import { GET_TASK } from '../tasks/queries'
import { GET_TICKET } from '../tickets/queries'
import { GET_THREAD } from '../messenger/queries'
import { CHANGE_LOG_FRAGMENT } from '../fragments'
import { notificationCountersVar, currentUserVar } from '../cache'
import { GET_UNREAD_THREADS } from '../messenger/queries'
import { GET_UNREAD_NOTIFICATIONS, GET_UNREAD_NOTIFICATIONS_COUNT } from '../notifications/queries'
import { GET_CALLS, GET_MISSED_CALLS_COUNT } from '../calls/queries'
import { ON_NEW_NOTIFICATION_SUBSCRIPTION } from '../notifications/subscriptions'
import { ON_NEW_MESSAGE_SUBSCRIPTION, ON_NEW_READ_RECEIPT_SUBSCRIPTION } from '../messenger/subscriptions'
import { ON_NEW_TASK_SUBSCRIPTION } from '../tasks/subscriptions'
import { ON_NEW_TICKET_SUBSCRIPTION } from '../tickets/subscriptions'
import { ON_MISSED_CALL_SUBSCRIPTION } from '../calls/subscriptions'
import OnCallStatusSubscriber from '../calls/components/CallStatusSubscriber'
import { useMarkAsReadMutation } from '../messenger/hooks'
import { onNewTicket } from '../tickets/helpers/subscribeToNewTickets'
import { onNewTask } from '../tasks/helpers/subscribeToNewTasks'
import { tabNavLinks, changeLogKinds } from '../constants'
import { toTitleCase, updateLister } from '../utils'


const Subscriber = ({ userId }) => {
    const currentUser = currentUserVar()
    const [markAsRead] = useMarkAsReadMutation(false)

    const { refetch: refetchUnreadThreads } = useQuery(GET_UNREAD_THREADS, {
        onCompleted: ({ unreadThreads }) => {
            notificationCountersVar({
                ...notificationCountersVar(),
                unreadMessageCount: unreadThreads.unreadMessageCount,
                unreadMentionsCount: unreadThreads.unreadMentionsCount
            })
        }
    })

    useQuery(GET_UNREAD_NOTIFICATIONS_COUNT, {
        onCompleted: ({ unreadNotificationsCount }) => {
            notificationCountersVar({ ...notificationCountersVar(), unreadNotificationsCount })
        }
    })

    useQuery(GET_MISSED_CALLS_COUNT, {
        variables: { departmentId: currentUser?.department?.id },
        pollInterval: 15 * 60000,
        onCompleted: ({ missedCallsCount }) => {
            notificationCountersVar({ ...notificationCountersVar(), missedCallsCount })
        }
    })

    // subscribe to new tickets
    useSubscription(ON_NEW_TICKET_SUBSCRIPTION, { onSubscriptionData: onNewTicket })

    // subscribe to new tasks
    useSubscription(ON_NEW_TASK_SUBSCRIPTION, { onSubscriptionData: onNewTask })

    // subscribe to new messages
    useSubscription(ON_NEW_MESSAGE_SUBSCRIPTION, {
        onSubscriptionData: ({ subscriptionData, client }) => {
            if (!subscriptionData?.data?.onNewMessage?.newMessage) return
            const { newMessage } = subscriptionData.data.onNewMessage
            const isThreadActive = document.location.pathname === tabNavLinks.messenger.dynamicPath + newMessage.thread.id
            const isSubscribed = newMessage.thread.membership?.isSubscribed
            const containsMention = newMessage.mentions.some(({ id }) => id === userId)
            let alreadyReceived = false

            // add new message to thread
            client.cache.modify({
                id: client.cache.identify({
                    __typename: 'ThreadType',
                    id: newMessage.thread.id
                }),
                fields: {
                    messages(existingMsgs, { toReference, readField }) {
                        if (!existingMsgs) return existingMsgs
                        alreadyReceived = existingMsgs.some(msg => readField('id', msg) === newMessage.id)
                        if (alreadyReceived) return existingMsgs
                        return [...existingMsgs, toReference(newMessage)]
                    },
                    unreadMessages(existing=[], { toReference, readField }) {
                        if (isThreadActive || !isSubscribed) return existing
                        if (existing.some(msg => readField('id', msg) === newMessage.id)) return existing
                        return [...existing, toReference(newMessage)]
                    }
                }
            })

            if (alreadyReceived) return

            // add new message to mentions if current user is mentioned
            if (containsMention) {
                client.cache.modify({
                    fields: {
                        mentions(existingMentions, { toReference, readField }) {
                            if (!existingMentions || existingMentions.some(msg => readField('id', msg) === newMessage.id)) return existingMentions
                            return [toReference(newMessage), ...existingMentions]
                        }
                    }
                })
            }

            // if thread is opened mark it as read
            if (isThreadActive) {
                markAsRead({ variables: { threadIds: [newMessage.thread.id] } })
            } else {
                refetchUnreadThreads()
            }
        }
    })

    // subscribe to updates on tickets and tasks
    useSubscription(ON_NEW_CHANGE_SUBSCRIPTION, {
        variables: { contentTypes: ['ticket', 'task']},
        onSubscriptionData: ({ subscriptionData, client }) => {
            if (!subscriptionData.data) return
            
            const { changeLog, contentObject } = subscriptionData.data.onNewChange
            const { contentModel, kind, changes } = changeLog
            const logsQueryArgs = `{"contentType":"${contentModel}","objectId":"${contentObject.id}"}`
            
            client.cache.modify({
                fields: {
                    changeLogForContentObject(existingLogs=[], { readField, storeFieldName }) {
                        if (storeFieldName === `changeLogForContentObject:${logsQueryArgs}`) {
                            if (existingLogs.some(ref => readField('id', ref) === changeLog.id)) {
                                return existingLogs
                            }
                            const newChangeLogRef = client.cache.writeFragment({
                                data: changeLog,
                                fragment: CHANGE_LOG_FRAGMENT,
                            })
                            return [newChangeLogRef, ...existingLogs]
                        }
                        return existingLogs
                    }
                }
            })

            // update lists on archived status change
            if (kind === changeLogKinds.CHANGE) {
                const fieldName = `${contentModel}s`
                const archiveFieldName = `archived${toTitleCase(contentModel)}s`

                if (!!changes.archived) {
                    const isArchived = changes.archived?.value === 'True'
                    let listCached = false
                    let archiveCached = false

                    client.cache.modify({
                        fields: {
                            [fieldName](existing, { readField, toReference }) {
                                if (!existing) return
                                listCached = true
                                if (isArchived) return existing.filter(t => contentObject.id !== readField('id', t))
                                return [toReference(contentObject), ...existing]
                            },
                            [archiveFieldName](existing, { readField, toReference }) {
                                if (!existing) return
                                archiveCached = true
                                if (!isArchived) return existing.filter(t => contentObject.id !== readField('id', t))
                                return [toReference(contentObject), ...existing]
                            },
                        }
                    })

                    listCached && updateLister({ cache: client.cache, queryFieldName: fieldName })
                    archiveCached && updateLister({ cache: client.cache, queryFieldName: archiveFieldName })
                } else {
                    updateLister({ cache: client.cache, queryFieldName: contentObject.archived ? archiveFieldName : fieldName })
                }
            }
        }
    })

    // subscribe to updates on deleted tickets, tasks and threads
    useSubscription(ON_INSTANCE_DELETED_SUBSCRIPTION, {
        variables: { contentTypes: ['ticket', 'task', 'thread']},
        onSubscriptionData: ({ subscriptionData, client }) => {
            if (!subscriptionData.data) return
            
            const { changeLog } = subscriptionData.data.onInstanceDeleted
            const { contentModel, objectId } = changeLog
            const logsQueryArgs = `{"contentType":"${contentModel}","objectId":"${objectId}"}`
            const id = objectId.toString()

            client.cache.updateQuery({
                query: contentModel === 'ticket' ? GET_TICKET : contentModel === 'task' ? GET_TASK : GET_THREAD,
                variables: { id }
            }, (data) => {
                if (data) return {
                    [contentModel]: {
                        ...data[contentModel],
                        deleted: true
                    }
                }
                return undefined
            })

            client.cache.modify({
                fields: {
                    changeLogForContentObject(existingLogs=[], { readField, storeFieldName }) {
                        if (storeFieldName === `changeLogForContentObject:${logsQueryArgs}`) {
                            if (existingLogs.some(ref => readField('id', ref) === changeLog.id)) {
                                return existingLogs
                            }
                            const newChangeLogRef = client.cache.writeFragment({
                                data: changeLog,
                                fragment: CHANGE_LOG_FRAGMENT,
                            })
                            return [newChangeLogRef, ...existingLogs]
                        }
                        return existingLogs
                    },
                    [`${contentModel}s`](existing) {
                        return existing?.filter(t => id !== client.cache.data.getFieldValue(t, 'id'))
                    },
                    [`archived${toTitleCase(contentModel)}s`](existing) {
                        return existing?.filter(t => id !== client.cache.data.getFieldValue(t, 'id'))
                    },
                    [`active${toTitleCase(contentModel)}List`](existing) {
                        return existing?.filter(t => id !== client.cache.data.getFieldValue(t, 'id'))
                    },
                    [`active${toTitleCase(contentModel)}Archive`](existing) {
                        return existing?.filter(t => id !== client.cache.data.getFieldValue(t, 'id'))
                    }
                }
            })
        }
    })

    useSubscription(ON_MISSED_CALL_SUBSCRIPTION, {
        onSubscriptionData: ({ subscriptionData, client }) => {
            if (!subscriptionData.data) return
            
            const { call } = subscriptionData.data.onMissedCall
            let missedCallsCount

            client.cache.updateQuery({
                query: GET_CALLS,
                variables: initailCallsQueryVars
            }, (data) => {
                if (!data?.calls) return undefined
                let updatedMissedCalls = []

                if (call.resolvedBy) {
                    updatedMissedCalls = data.calls.filter(c => call.id !== client.cache.data.getFieldValue(c, 'id'))
                } else if (data.calls.some(c => call.id === client.cache.data.getFieldValue(c, 'id'))) {
                    updatedMissedCalls = data.calls
                } else (
                    updatedMissedCalls = [call, ...data.calls]
                )

                if (!call.department || !currentUser.department || currentUser.department.id === call.department.id) {
                    const countDiff = updatedMissedCalls.length - data.calls.length
                    missedCallsCount = notificationCountersVar().missedCallsCount + countDiff
                }

                return { calls: updatedMissedCalls }
            })

            missedCallsCount && client.cache.modify({
                fields: {
                    missedCallsCount() { return missedCallsCount } 
                }
            })
        }
    })

    // subscribe to user online status updates
    useSubscription(ON_USER_STATUS_UPDATE_SUBSCRIPTION)

    // subscribe to read receipts
    useSubscription(ON_NEW_READ_RECEIPT_SUBSCRIPTION)

    // subscribe to new notifications
    useSubscription(ON_NEW_NOTIFICATION_SUBSCRIPTION, {
        onSubscriptionData: ({ subscriptionData, client }) => {
            if (!subscriptionData?.data?.onNewNotification?.newNotification) return
            
            const { newNotification } = subscriptionData.data.onNewNotification
            let increaseCount = false
            
            // add new notification to existing notifications
            client.cache.modify({
                fields: {
                    unreadNotifications(prev, { toReference, readField }) {
                        if (!prev || prev.some(n => readField('id', n) === newNotification.id)) return prev
                        increaseCount = true
                        return [toReference(newNotification), ...prev]
                    }
                }
            })

            // add 1 to unread notifications counter
            if (increaseCount) {
                const currentCounters = notificationCountersVar()
                const newCount = currentCounters.unreadNotificationsCount + 1
                notificationCountersVar({ ...currentCounters, unreadNotificationsCount: newCount })
            }

            // refetch notes on new note
            if (newNotification.actionObjectContentType.model === 'note') {
                client.query({
                    query: GET_NOTES_FOR_INSTANCE,
                    variables: {
                        contentType: newNotification.targetContentType.model,
                        objectId: newNotification.targetObjectId,
                    },
                    fetchPolicy: 'network-only',
                    nextFetchPolicy: 'cache-first' 
                })
            }

            // refetch note comments on new comment
            if (newNotification.actionObjectContentType.model === 'comment') {
                client.query({
                    query: GET_NOTE,
                    variables: { id: newNotification.targetObjectId },
                    fetchPolicy: 'network-only',
                    nextFetchPolicy: 'cache-first' 
                })
            }
        }
    })

    return (
        <>
            <OnCallStatusSubscriber/>
        </>
    )
}

Subscriber.propTypes = {
    userId: PropTypes.string
}

export default Subscriber
