import { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { tabNavLinks } from '../../constants'
import { TabNavContext } from '../../components/TabNav'
import TaskDetail from './TaskDetail'
import TaskLister from './TaskLister'
import NewTask from './TaskNew'
import TasksArchive from './TasksArchive'

const TasksLoader = () => {
    const { activeTab, id } = useContext(TabNavContext)

    switch (activeTab) {
        case 'list':
            return <TaskLister />
        case 'archive':
            return <TasksArchive />
        case 'task':
            return <TaskDetail key={activeTab + id} />
        case 'new':
            return <NewTask />
        default:
            return <Redirect to={tabNavLinks.tasks.staticTabs[0].relativeUrl}  />
    }
}

export default TasksLoader
