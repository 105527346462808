import { useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { useQuery, useMutation, gql } from '@apollo/client'
import { CURRENT_USER_QUERY } from '../queries'
import { lbl } from '../constants'

const GET_USER_BY_RESET_TOKEN = gql`
    query GET_USER_BY_RESET_TOKEN($resetToken: String!) {
        userByResetToken(resetToken: $resetToken) {
            firstName
            type
        }
    }
`;

const RESET_PASSWORD_MUTATION = gql`
    mutation RESET_PASSWORD_MUTATION($resetToken: String!, $password: String!, $confirmPassword: String!) {
        resetPassword(resetToken: $resetToken, password: $password, confirmPassword: $confirmPassword) {
            user {
                id
                email
                username
            }
        }
    }
`

const ResetPassword = () => {
    let confirmPasswordInput
    let [password, setPassword] = useState('')
    let [showError, setShowError] = useState(false)
    const { resetToken } = useParams()
    const { data } = useQuery(GET_USER_BY_RESET_TOKEN, { 
        variables: { resetToken },
        fetchPolicy: 'no-cache'
    })
    const [resetPassword, { loading, error, called }] = useMutation(RESET_PASSWORD_MUTATION, {
        onError: () => { setShowError(true) },
        onCompleted: () => { 
            setPassword(lbl.resetPassSuccess)
        }
    })
    const success = !error && !loading && called
    const handleOnChange = () => {showError && setShowError(false)}
    const errMsg = error && error.message.replace(/',|'|]|\[/g, '')

    return (
        <div className='contentbox--lg'>
            <div className='logo--center'>
                <img src={process.env.PUBLIC_URL + '/logo_long.svg'} alt='logo'/>
            </div>
            <h2 className='t-heading t-lg--responsive mb-6'>
                {lbl.hi} <strong>{ (data && data.userByResetToken && data.userByResetToken.firstName) || lbl.user}</strong>,<br/>{lbl.resetPassIntro}
            </h2>
            <div className='card'>
                <form
                    method='post'
                    data-test='form'
                    onSubmit={async e => {
                        e.preventDefault();
                        await resetPassword({
                            variables: {
                                resetToken,
                                password: password,
                                confirmPassword: confirmPasswordInput.value,
                            },
                            refetchQueries: [{ query: CURRENT_USER_QUERY }]
                        });
                    }}
                    >
                    <fieldset disabled={loading} aria-busy={loading}>
                        <br/>
                        <fieldset
                            disabled={success}
                            className={success ? 'success' : error && showError ? 'error' : ''}>
                            <label htmlFor='password'>
                                {success ? lbl.success : lbl.yourNewPass}
                            </label>
                            <input
                                type={success ? 'text' : 'password'}
                                name='password'
                                placeholder=''
                                required
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                    handleOnChange()
                                }}
                            />
                        </fieldset>
                        {!success && 
                            <fieldset
                                className={error && showError ? 'error' : ''}>
                                <label htmlFor='confirmPassword'>
                                    {lbl.yourNewPassRetype}
                                </label>
                                <input
                                    type='password'
                                    name='confirmPassword'
                                    placeholder=''
                                    required
                                    defaultValue=''
                                    ref={node => { confirmPasswordInput = node }}
                                    onChange={handleOnChange}
                                />
                                {error && showError &&
                                    <p className='input-message input-message--error'>
                                        {errMsg} {errMsg.includes('password reset link') && <Link className='ml-1' to='/request_reset'>{lbl.requestNewPassReset}</Link>}
                                    </p>
                                }
                            </fieldset>
                        }
                        <br/>
                        {success ?
                            <>
                                {data?.userByResetToken?.type === "employees" ?
                                    <Link to='/' type='button' className='btn d-inline-block mx-auto btn--success'>{lbl.login}</Link>
                                :
                                    <p>Go to Home App to sign in with your new password</p>
                                }
                            </>
                        :
                            <button type='submit' className='btn d-block mx-auto btn--primary'>
                                {loading ? <span className='btn__loader'>&#8635;</span> : lbl.confirm}
                            </button>
                        }
                    </fieldset>
                </form>
            </div>
        </div>
    )
}

export default ResetPassword;
