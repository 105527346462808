import { useQuery } from '@apollo/client'
import { useState } from 'react'
import ThreadList from '../../messenger/components/ThreadList'
import { GET_PROPERTY_THREADS } from '../../messenger/queries'
import PropertyTypeFilters from './PropertyTypeFilters'


const PropertyThreads = ({ contentType, objectId, ...props }) => {
    let [contentObjects, setContentObjects] = useState({ [contentType]: [objectId] })
    const { data, error, loading } = useQuery(GET_PROPERTY_THREADS, { variables: contentObjects })

    return (
        <>
            <PropertyTypeFilters
                contentType={contentType}
                className='d-flex justify-end mb-2'
                subtab='threads'
                setState={setContentObjects}
                state={contentObjects}
                {...props}
            />
            {error && <div className='t-center'>{error.message}</div>}
            {!data && loading && <div className='loader pos-center-y' />}
            {data &&
                <ThreadList
                    threads={data.propertyThreads}
                    accordionClosed
                    // hideEmpty
                />
            }
        </>
    )
}

export default PropertyThreads
