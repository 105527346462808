import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import Icon from './Icon'

const Crumbs = ({ crumbs, getRelativeUrl, activeId }) => (
    <>
        {crumbs.map(({ id, name, relativeUrl }) => (
            <Fragment key={activeId + name}>
                <li className='crumbs-listitem'>
                    <Link
                        to={relativeUrl || getRelativeUrl(id)}
                        title={name}
                    >
                        {name}
                    </Link>
                </li>
                <Icon type='caret' classAdditions='caret--right' variant='2xs' />
            </Fragment>
        ))}
    </>
)

export default Crumbs