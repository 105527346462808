import { useState, useEffect, useLayoutEffect, memo } from 'react'
import { useReactiveVar, useLazyQuery } from '@apollo/client'
import { showAllThreadsVar } from '../../cache'
import { tabNavLinks } from '../../constants'
import { Accordion, Icon } from '../../components'
import TextField from '../../forms/TextField'
import { InboxItemLabel, InboxItemLink } from './InboxItem'
import { isElementInViewport } from '../../utils'
import { GET_THREAD_SUBTHREADS } from '../queries'

const ThreadsTreeItem = ({ thread, isLast, level=0, parentId, ...staticProps }) => {
    const { activeThread, openedThreads, crumbs, onContextMenu, createSubthread, newThreadParentId, clearNewThread, renameThread, renameThreadId, onToggleSubthreads } = staticProps
    const { id, name, hasSubthreads, contentType, icon, contact, isEmpty, isFolder, isDirect, isGroup, unreadCount, archived, membership, parent } = thread
    const isCrumb = crumbs.includes(id)
    const isActive = id === activeThread
    const _isOpen = openedThreads.includes(id)
    const LabelTag = isFolder ? InboxItemLabel : InboxItemLink
    const hasNewSubthread = newThreadParentId && newThreadParentId === id
    // const hasUnreadSubthreads = checkTreeProp(thread, 'subthreads', 'unreadCount')
    const isBeingRenamed = renameThreadId && renameThreadId === id
    const labelIcon = icon ? icon : isGroup ? 'contact-multi' : isDirect && level === 1 ? 'dot' : 'hash'
    const className = `inbox__listitem${isEmpty ? ' inbox__listitem--empty' : ''}${isBeingRenamed ? ' inbox__listitem--new d-flex' : ''}${isActive ? ' inbox__listitem--active' : ''}${isActive || isBeingRenamed ? ' inbox__listitem--nohover' : ''}`
    let [isOpen, setIsOpen] = useState(_isOpen || isCrumb || hasNewSubthread);
    let showAllThreads = useReactiveVar(showAllThreadsVar)

    const [get_thread_subthreads, { data, loading, called, client }] = useLazyQuery(GET_THREAD_SUBTHREADS,
        { variables: { threadId: id, all: showAllThreads } }
    )
    const subthreads = data?.thread.subthreads

    useEffect(() => {
        if (!isOpen && (_isOpen || hasNewSubthread)) setIsOpen(true)
    }, [activeThread, _isOpen, hasNewSubthread])

    useEffect(() => {
        if (_isOpen && hasSubthreads && !called) get_thread_subthreads()
    }, [_isOpen])

    useEffect(() => {
        // on thread parent change (eg. after ticket category or owner update)
        // update thread parent's subthreads
        // and remove thread from previous parent's subthreads
        // so inbox tree reflects the new thread hierarchy
        if (parent?.id !== parentId) {
            if (parent?.id) {
                [true, false].forEach(showAllThreads => {
                    client.cache.updateQuery({
                        query: GET_THREAD_SUBTHREADS,
                        variables: { threadId: parent.id, all: showAllThreads }
                    }, (data) => {
                        if (!data?.thread) return undefined
                        if (data.thread.subthreads.some(st => st.id === id)) {
                            return data
                        } else {
                            return { thread: {
                                ...data.thread,
                                subthreads: [...data.thread.subthreads, { __typename: 'ThreadType', id }]
                            } }
                        }
                    })
                })
            }
            if (parentId) {
                [true, false].forEach(showAllThreads => {
                    client.cache.updateQuery({
                        query: GET_THREAD_SUBTHREADS,
                        variables: { threadId: parentId, all: showAllThreads }
                    }, (data) => {
                        if (!data?.thread) return undefined
                        return ({
                            thread: {
                                ...data.thread,
                                subthreads: data.thread.subthreads.filter(st => st.id !== id)
                            }
                        })
                    })
                })
            }
        }
    }, [id, parent?.id, parentId])

    useLayoutEffect(() => {
        if (isActive) {
            let selector = '.inbox__listitem--active'
            if (isOpen) selector += '> .accordion__header'
            const activeItem = document.querySelector(selector)
            
            if (activeItem && !isElementInViewport(activeItem)) {
                activeItem.scrollIntoView({ block: 'center' })
            }
        }
    }, [isActive, showAllThreads])

    if (archived || (!showAllThreads && !membership)) return null

    const onOpen = () => {
        onToggleSubthreads(id, isOpen)
        if (!isOpen && !called) get_thread_subthreads()
        setIsOpen(prev => !prev)
    }

    const getNewThreadLi = (_level=level) => (
        <li className='inbox__listitem inbox__listitem--new inbox__listitem--nohover d-inline-flex'>
            <div className={`level--${_level}`}>
                <Icon type='hash' variant='xs' classAdditions='mr-05' />
                <TextField
                    preventSubmitOnBlur
                    onSubmit={name => {
                        if (!!membership) {
                            createSubthread({ name })
                        } else if (window.confirm('By creating this thread you will also become subscribed member of all its parent threads.')) {
                            createSubthread({ name, joinParentThreads: true })
                        } else {
                            clearNewThread()
                        }
                    }}
                    inputProps={{
                        placeholder: 'name new thread',
                        autoFocus: true,
                        value: ''
                    }}
                    inline
                />
                <button
                    className='btn btn--naked btn--inline-icon clear'
                    onClick={clearNewThread}
                >
                    <Icon type='bin' variant='xs' />
                </button>
            </div>
        </li>
    )

    const label = (
        isBeingRenamed ?
            <>
                <Icon type={icon} variant='xs' classAdditions='mr-05' />
                <TextField
                    onSubmit={name => renameThread({ name })}
                    inputProps={{
                        placeholder: thread.name,
                        autoFocus: true,
                        value: ''
                    }}
                    inline
                />
            </>
        :
            <LabelTag
                label={name}
                contentType={contentType}
                id={id}
                level={level}
                icon={labelIcon}
                onlineStatus={contact?.onlineStatus}
                hideIcon={level === 0}
                isActive={isActive}
                isDirect={isDirect}
                unreadCount={!isActive && unreadCount}
                isSubscribed={!!membership?.isSubscribed}
                markedAsUnread={!!membership?.isUnread}
                onClick={isFolder ? onOpen : undefined}
                onContextMenu={(e) => {
                    e.stopPropagation()
                    onContextMenu(thread, level)
                }}
                relativeUrl={tabNavLinks.messenger.dynamicPath + id}
            />
    )

    if (isFolder && !hasSubthreads) {
        return null
    }

    if (hasNewSubthread || hasSubthreads || (subthreads?.length && (showAllThreads || subthreads.some(t => !!t.membership)))) return (
        <Accordion
            key={id + name}
            className={className}
            headerClass={`level--${level}`}
            label={label}
            isOpen={isOpen}
            onOpen={onOpen}
        >
            {!!subthreads?.length ?
                <ThreadsTree
                    threads={subthreads}
                    level={level+1}
                    parentId={id}
                    {...staticProps }
                />
            : loading ?
                <div className={`level--${level+1} ml-3`}>
                    <div className='loader loader--inline'/>
                </div>
            : null}
            {hasNewSubthread && getNewThreadLi(level+1)}
        </Accordion>
    )

    return (
        <li className={className} key={id + name}>
            <div className={`level--${level}`}>
                {label}
            </div>
        </li>
    )
}

const ThreadItem = memo(ThreadsTreeItem)

const ThreadsTree = ({ threads, ...props }) => {
    return threads.map((thread, index) => {
        if (thread.deleted) return null

        return (
            <ThreadItem
                key={`thread-${thread.id}`}
                thread={thread}
                isLast={index === threads.length - 1}
                {...props}
            />
        )
    })
}

export default ThreadsTree
