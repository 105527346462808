import { gql } from '@apollo/client'
import { BASE_NOTIFICATION_FRAGMENT } from './fragments'

export const ON_NEW_NOTIFICATION_SUBSCRIPTION = gql`
    subscription ON_NEW_NOTIFICATION_SUBSCRIPTION {
        onNewNotification {
            newNotification {
                ...NotificationFields
            }
        }
    }
    ${BASE_NOTIFICATION_FRAGMENT}
`
