import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import { ProfileImage, Label, Icon } from '../../components'
import { lbl, tabNavLinks } from '../../constants'
import { GET_PROPERTY_TENANTS_PER_ROOM } from '../../properties/queries'

const FlatMates = ({ tenantId, roomId, propertyId }) => {
    const { data, loading } = useQuery(GET_PROPERTY_TENANTS_PER_ROOM, { variables: { propertyId } })

    if (loading) return <div className='loader pos-center-y' />

    const rooms = data?.property?.rooms || []
    const roomCount = rooms.length
    let occupiedRoomCount = 0
    const flatmates = []

    if (data?.property) {
        rooms.forEach(room => {
            if (room.tenancies?.length) {
                occupiedRoomCount += 1

                room.tenancies.forEach(tenant => {
                    if (tenant.id !== tenantId) {
                        flatmates.push({ tenant, room })
                    }
                })
            }
        })

        if (data?.property?.roomlessTenants) {
            data.property.roomlessTenants.forEach(tenant => {
                if (tenant.id !== tenantId) {
                    flatmates.push({ tenant })
                }
            })
        }
    }

    return (
        <>
            <div className='d-flex align-center t-sm mb-2'>
                CO-TENANTS
                <div className='t-grey ml-05'>({flatmates.length})</div>
                <div className='ml-auto'>
                    <Icon type='room' variant='sm'/>
                </div>
                <div className='t-grey ml-05'>{occupiedRoomCount}/{roomCount}</div>
            </div>
            <div className='scroll__wrapper'>
                <ul className='d-flex scroll__list'>
                    {flatmates.map(({ tenant, room }, i) => (
                        <li className='d-column t-xs t-center' key={i + tenant.id + room?.id}>
                            <Link
                                to={`${tabNavLinks.contacts.dynamicPath}${tenant.user.id}`}
                                className='t-none d-column'
                            >
                                <ProfileImage
                                    url={tenant.user.picture}
                                    type='1_guests'
                                    size='xl'
                                />
                                <Label
                                    label={room?.id === roomId ? lbl.roommate : lbl.flatmate}
                                    classModifier='grey'
                                    className='mt-1 mb-1 t-uppercase'
                                />
                                <div>{tenant.user.fullName}</div>
                            </Link>
                            {room &&
                                <Link
                                    className='mt-05 t-grey t-underline-hover'
                                    to={`${tabNavLinks.properties.unit.dynamicPath}${room.id}`}
                                >
                                    {room.fullUnitName}
                                </Link>
                            }
                        </li>
                    ))}
                </ul>
            </div>
        </>
    )
}

export default FlatMates
