import { CONTENT_MODEL_MAP } from '../constants'

export const attachmentProperties = {
    uploadedBy: {
        field: 'uploadedBy',
        type: 'object',
        label: 'Uploaded by',
        sortPath: 'uploadedBy.fullName',
        groupPath: 'uploadedBy.fullName',
        objectPath: 'uploadedBy',
        getOptionProps: root => ({
            ...root,
            label: root.fullName
        })
    },
    dateCreated: {
        field: 'dateCreated',
        type: 'string',
        label: 'Upload date',
        sortPath: 'dateCreated',
    },
    dateChanged: {
        field: 'dateChanged',
        type: 'string',
        label: 'Last update',
        sortPath: 'dateChanged',
    },
    originalFilename: {
        field: 'originalFilename',
        label: 'Name',
        type: 'string',
        sortPath: 'originalFilename',
    },
    fileName: {
        field: 'fileName',
        label: 'Name',
        type: 'string',
        sortPath: 'fileName',
    },
    contentType: {
        field: 'contentType',
        type: 'string',
        label: 'Related object',
        sortPath: 'contentType.objectName',
        searchPath: 'relatedObjectLabel',
        groupPath: 'contentType.model',
        getOptionProps: root => ({
            label: `Uploaded to ${CONTENT_MODEL_MAP[root]?.lbl || root}`,
            iconProps: { type: CONTENT_MODEL_MAP[root]?.icon || root, variant: 'sm' }
        })
    },
    category: {
        field: 'category',
        type: 'object',
        label: 'Category',
        sortPath: 'category.name',
        groupPath: 'category.name',
        objectPath: 'category',
        getOptionProps: root => ({ ...root, label: root.name }),
    },
    categoryName: {
        field: 'categoryName',
        label: 'Category',
        type: 'string',
        sortPath: 'categoryName',
        getOptionProps: root => ({ id: root, label: root }),
    }
}

export const listerCtrlOptions = {
    sort: ['uploadedBy', 'dateCreated', 'dateChanged', 'originalFilename', 'category'].map(
        (key) => attachmentProperties[key]
    ),
    search: ['originalFilename', 'uploadedBy'].map(
        (key) => attachmentProperties[key]
    )
}

export const extendedListerCtrlOptions = {
    sort: ['contentType', 'uploadedBy', 'dateCreated', 'dateChanged', 'originalFilename', 'category'].map(
        (key) => attachmentProperties[key]
    ),
    search: ['originalFilename', 'uploadedBy', 'contentType'].map(
        (key) => attachmentProperties[key]
    )
}

export const basicListerColumns = ['fileName']

export const listerColumns = ['originalFilename', 'category', 'dateChanged', 'dateCreated', 'uploadedBy']

export const extendedListerColumns = ['originalFilename', 'contentType', 'category', 'dateChanged', 'dateCreated', 'uploadedBy']

export const bacicListerCtrlOptions = {
    sort: ['fileName', 'categoryName'].map((key) => attachmentProperties[key]),
    search: [attachmentProperties['fileName']]
}