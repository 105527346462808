import { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { tabNavLinks } from '../../constants'
import { TabNavContext } from '../../components/TabNav'
import { GET_LEAD } from '../queries'


const LeadRedirect = () => {
    const { id } = useContext(TabNavContext)
    const { data, loading, error } = useQuery(GET_LEAD, { variables: { id } })
    const lead = data?.lead

    if (loading) return <div className='loader pos-center-y' />
    
    if (error) return (
        <div className='t-red w-100 pos-center-y'>
            <center>{error.message}</center>
        </div>
    )

    if (lead?.property) {
        return <Redirect to={`${tabNavLinks.properties.property.dynamicPath}${lead.property.id}/leads#lead_${lead.id}`} />
    }

    if (lead?.unit) {
        return <Redirect to={`${tabNavLinks.properties.unit.dynamicPath}${lead.unit.id}/leads#lead_${lead.id}`} />
    }

    return null
}

export default LeadRedirect
