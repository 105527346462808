import React from 'react'
import { GET_ATTACHMENTS_FOR_USER, GET_VIEWABLE_ATTACHMENTS_FOR_GUEST_PER_CATEGORY } from '../queries'
import AttachmentsLister from '../../attachments/components/AttachmentLister'
import { extendedListerColumns, extendedListerCtrlOptions, basicListerColumns, bacicListerCtrlOptions } from '../../attachments/settings'
import { contactAttachmentListCtrlsVar, viewableByUserAttachmentListCtrlsVar } from '../../cache'
import { Icon } from '../../components'

const UserAttachments = ({ profileId, user }) => {
    const [viewableByUser, toggleViewableByUser] = React.useState(false)

    return (
        <div className='tab__panel--main'>
            
            <AttachmentsLister
                columns={viewableByUser ? basicListerColumns : extendedListerColumns}
                ctrlOptions={viewableByUser ? bacicListerCtrlOptions : extendedListerCtrlOptions}
                listCtrlVar={viewableByUser ? viewableByUserAttachmentListCtrlsVar : contactAttachmentListCtrlsVar}
                query={viewableByUser ? GET_VIEWABLE_ATTACHMENTS_FOR_GUEST_PER_CATEGORY : GET_ATTACHMENTS_FOR_USER}
                queryVariables={viewableByUser ? { userId: user.id } : { profileId }}
                queryFieldName={viewableByUser ? 'viewableAttachmentsForGuestPerCategory' : 'attachments'}
                contentTypeString='users | profile'
                contentObjectId={profileId}
                disableUpload={viewableByUser}
                renderCtrlChildren={user.tenant ? () => (
                    <li className='ctrls__listitem ctrls__listitem--ctrl ml-2'>
                        <button
                            className='btn btn--naked btn--inline-icon p-1'
                            onClick={() => toggleViewableByUser(!viewableByUser)}
                            title={viewableByUser ? 'Show all contact attachments' : 'Show attachments viewable by guest in documents section in mobile app'}>
                            <div className='toggle btn--inline-icon btn--naked'>
                                <Icon type={viewableByUser ? 'eye-off' : 'eye'} variant='xs' />
                            </div>
                            <div className='ctrls-listitem__label'>{viewableByUser ? `Clear visible to ${user.fullName}` : `Visible to ${user.fullName}`}</div>
                        </button>
                    </li>
                ) : undefined}
            />
        </div>
    )
}

export default UserAttachments
