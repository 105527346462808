import { normalizeString } from './stringUtils'

/**
 * Gets the value of a nested property without being unable to return x of undefined
 * @param {Object} obj the object to search in
 * @param {string} key the object path to search
 * @return {*} the value or the desired item or false
 */
export function getProp(key, obj) {
    return key.split('.').reduce((o, x) => {
        return typeof o === 'undefined' || o === null ? undefined : o[x];
    }, obj);
}

const getComparableValue = (obj, propPath) => {
    const value = getProp(propPath, obj)
    if (typeof value === 'number') return value
    return value ? value.toString().toLowerCase() : ''
}

export const insertObjInSortedArray = (obj, array, sortBy, sortDir=1) => {
    const objValue = getComparableValue(obj, sortBy)

    let i = array.length - 1
    array[i + 1] = obj

    if (!objValue) return array

    if (sortDir === -1) {
        while (i >= 0 && (!getComparableValue(array[i], sortBy) || getComparableValue(array[i], sortBy) < objValue)) {
            array[i + 1] = array[i]
            array[i] = obj
            i -= 1
        }
    } else  {
        while (i >= 0 && (!getComparableValue(array[i], sortBy) || getComparableValue(array[i], sortBy) > objValue)) {
            array[i + 1] = array[i]
            array[i] = obj
            i -= 1
        }
    }

    return array
}

export const insertObjInGroupedSortedArray = ({ obj, array, groupBy, sortGroupBy, sortBy, sortDir=1, groupDir=1 }) => {
    const objValue = getComparableValue(obj, sortBy)
    const objGroup = getComparableValue(obj, groupBy)
    const objGroupValue = getComparableValue(obj, sortGroupBy)

    let i = array.length - 1
    array[i + 1] = obj

    if (!objGroup) return array

    while (
        i >= 0 && (
            (groupBy !== sortGroupBy && (
                !getComparableValue(array[i], sortGroupBy) ||
                (groupDir === -1 && getComparableValue(array[i], sortGroupBy) < objGroupValue) ||
                (groupDir !== -1 && getComparableValue(array[i], sortGroupBy) > objGroupValue)
            )) ||
            ((groupBy === sortGroupBy || getComparableValue(array[i], sortGroupBy) === objGroupValue) && (
                !getComparableValue(array[i], groupBy) ||
                (groupDir === -1 && getComparableValue(array[i], groupBy) < objGroup) ||
                (groupDir !== -1 && getComparableValue(array[i], groupBy) > objGroup)
            )) ||
            (getComparableValue(array[i], groupBy) === objGroup && (
                !getComparableValue(array[i], sortBy) || 
                (sortDir === -1 && getComparableValue(array[i], sortBy) < objValue) ||
                (sortDir !== -1 && getComparableValue(array[i], sortBy) > objValue)
            ))
        )
    ) {
        array[i + 1] = array[i]
        array[i] = obj
        i -= 1
    }

    return array
}

export const sortObjectsByFieldPath = (array, fieldPath, sortDir=1) => {
    array.sort((a, b) => {
        const _a = getComparableValue(a, fieldPath)
        const _b = getComparableValue(b, fieldPath)
        
        if (!_a) return sortDir
        if (!_b) return -1 * sortDir
        if (_a < _b) return -1 * sortDir
        if (_a > _b) return sortDir
        return 0
    })
    return array
}

export const sortGroupedObjectsByFieldPath = (array, sortFieldPath, sortDir=1, groupFieldPath, groupDir=1, groupIdPath) => {
    array.sort((a, b) => {
        let _a = getComparableValue(a, groupFieldPath)
        let _b = getComparableValue(b, groupFieldPath)

        if (groupIdPath) {
            _a += getProp(groupIdPath, a)
            _b += getProp(groupIdPath, b)
        }
        
        if (!_a) return groupDir
        if (!_b) return -1 * groupDir
        if (_a < _b) return -1 * groupDir
        if (_a > _b) return groupDir

        const _c = getComparableValue(a, sortFieldPath)
        const _d = getComparableValue(b, sortFieldPath)

        if (!_c) return sortDir
        if (!_d) return -1 * sortDir
        if (_c < _d) return -1 * sortDir
        if (_c > _d) return sortDir
        return 0
    })
    return array
}

export const resetControlledList = ({ items, filters, groupBy, groupDir, sortBy, sortDir, getFieldProps }) => {
    const list = []
    const _filters = filters && filters.map(f => ({ ...f, type: getFieldProps(f.field).type }))
    
    items.forEach(item => {
        // filter
        if (_filters && filters.length) {
            const include = _filters.every(({ field, id, type }) => {
                if (type === 'string') return item[field] && id === item[field]
                if (type === 'object') return item[field] && id === item[field].id
                if (type === 'array') return item[field].length && item[field].some(x => x.id === id)
                return false
            })
            if (!include) return
        }

        // sort
        if (list.length && (sortBy || groupBy)) {
            if (sortBy && groupBy) {
                insertObjInGroupedSortedArray({
                    obj: item,
                    array: list, 
                    sortBy: getFieldProps(sortBy).sortPath,
                    groupBy: getFieldProps(groupBy).groupPath || getFieldProps(groupBy).sortPath,
                    sortGroupBy: getFieldProps(groupBy).sortPath,
                    sortDir,
                    groupDir
                })
            } else {
                const _sortBy = sortBy || groupBy
                const _sortDir = sortBy ? sortDir : groupDir
                insertObjInSortedArray(item, list, getFieldProps(_sortBy).sortPath, _sortDir)
            }
        } else {
            list.push(item)
        }
    })

    return list
}

export const getFilteredList = (items, { type, field, path, id }) => {
    // applies single filter
    return items.filter(item => {
        const root = getProp(path || field, item)

        if (type === 'string' || type === 'boolean' || type === 'number') return id === root
        if (type === 'object') return root && id === root.id
        if (type === 'array') return root && !!root.length && root.some(x => x.id === id)
        return false
    })
}

export const getSortedList = ({ items, sortBy, sortDir, groupBy, groupDir, getFieldProps }) => {
    if (!sortBy && !groupBy) return items

    if (groupBy && groupBy !== sortBy) {
        let { groupPath, sortPath: groupSortPath } = getFieldProps(groupBy)
        if (!groupPath) groupPath = groupSortPath
        
        if (!sortBy) {
            if (groupPath === groupSortPath) {
                return sortObjectsByFieldPath(
                    [...items],
                    groupSortPath,
                    groupDir
                )
            }
            return sortGroupedObjectsByFieldPath(
                [...items],
                groupPath,
                sortDir,
                groupSortPath,
                groupDir
            )
        }
        
        return sortGroupedObjectsByFieldPath(
            [...items],
            getFieldProps(sortBy).sortPath,
            sortDir,
            groupSortPath,
            groupDir,
            groupPath
        )
    }

    return sortObjectsByFieldPath(
        [...items],
        getFieldProps(sortBy).sortPath,
        sortDir
    )
}

export const getGroupedAndSortedList = ({ items, sortBy, sortDir, groupBy, groupDir }) => {
    if (!sortBy && !groupBy) return items

    if (groupBy && groupBy !== sortBy) {
        if (!sortBy) {
            return sortObjectsByFieldPath(
                [...items],
                groupBy,
                groupDir
            )
        }
        
        return sortGroupedObjectsByFieldPath(
            [...items],
            sortBy,
            sortDir,
            groupBy,
            groupDir
        )
    }

    return sortObjectsByFieldPath(
        [...items],
        sortBy,
        sortDir
    )
}

export const objectIncludesString = ({ obj, searchFields, str }) => {
    return searchFields.some(({ field, type, getOptionProps, searchPath }) => {
        const root = getProp(searchPath || field, obj)
        const _str = normalizeString(str)
        
        if (!root) return false
        
        if (type !== 'array') {
            let text = type === 'string' && !getOptionProps ? root : getOptionProps(root).label
            text = normalizeString(text)
            return text.includes(_str)
        } else {
            return !!root.length && root.some(x => normalizeString(getOptionProps(x)?.label).includes(_str))
        }
    })
}

export function getNestedOptions({ options, items }) {
    return options.reduce((result, option) => {        
        const { field, type, getOptionProps } = option

        const subOptions = items.reduce((acc, item) => {
            const itemOption = item[field]

            if (!itemOption) return acc

            if (type === 'string') {
                const includedItems = acc.has(itemOption) ? acc.get(itemOption).itemIds : []
                acc.set(itemOption, { ...getOptionProps(itemOption), itemIds: [...includedItems, item.id], field })
            }
            if (type === 'object') {
                const includedItems = acc.has(itemOption.id) ? acc.get(itemOption.id).itemIds : []
                acc.set(itemOption.id, { ...getOptionProps(itemOption), itemIds: [...includedItems, item.id], field })
            }
            if (type === 'array') {
                itemOption.length && itemOption.forEach(li => {
                    const includedItems = acc.has(li.id) ? acc.get(li.id).itemIds : []
                    acc.set(li.id, { ...getOptionProps(li), itemIds: [...includedItems, item.id], field })
                })
            }
            return acc
        }, new Map())

        result[field] = Array.from(subOptions.values())

        return result
    }, {})
}

export function checkTreeProp(node, childrenKey, keyToValidate) {
    return !!node[childrenKey] && node[childrenKey].some(x => {
        if (x[keyToValidate]) return true
        return checkTreeProp(x, childrenKey, keyToValidate)
    })
}
