import { makeVar, InMemoryCache, gql } from '@apollo/client'
import { offsetLimitPagination } from '@apollo/client/utilities'
import {
    priorityOptions,
    statusOptions,
    staticTabs,
    onlineStatusOptions,
    employmentStatusOptions,
    callDirectionOptions,
    callStatusOptions,
    callTimingOptions,
    tenantStatusOptions,
    userTypeOptions,
    lbl
} from './constants'

export const initailTicketsQueryVars = { 
    filters: [],
    groupBy: '',
    groupDir: 1,
    sortBy: 'createdDate',
    sortDir: -1,
    searchTerm: '',
    limit: 30
}

export const initailTasksQueryVars = { 
    filters: [],
    groupBy: 'status',
    groupDir: 1,
    sortBy: 'dueDate',
    sortDir: -1,
    searchTerm: '',
    limit: 30
}

export const initailCallsQueryVars = {
    filters: [{ field: 'missed', icon: 'call-missed', id: true, label: 'Missed calls' }],
    groupBy: '',
    groupDir: 1,
    sortBy: 'dateTime',
    sortDir: -1,
    searchTerm: '',
    limit: 30
}

export const initialPropertiesQueryVars = {
    filters: [],
    groupBy: 'building.city.name',
    groupDir: 1,
    sortBy: 'streetWithHouseNumber',
    sortDir: 1,
    searchTerm: '',
    limit: 30
}

// reactive variables
export const isLoggedInVar = makeVar(!!localStorage.getItem('token'));
export const currentUserVar = makeVar()
export const twilioDeviceVar = makeVar({ state: 'Connecting', conn: null, device: null, call: null, number: '' })
export const pinnedTabsVar = makeVar(new Map(JSON.parse(localStorage.getItem('pinnedTabs'))))
export const openedTabsVar = makeVar(new Map())
export const staticTabsVar = makeVar(staticTabs)
export const activeAppVar = makeVar(window.location.pathname.split('/')[1])
export const newDraftsVar = makeVar({
    tickets: { priority: '2_MEDIUM' },
    tasks: { priority: '2_MEDIUM', status: '1_TODO', approvalRequired: true },
    contacts: { type: '1_guests' },
    templates: {},
    property: {},
    building: {},
    unit: {},
    propertyContracts: {},
    unitContracts: {},
    tenancyAgreements: {},
    propertyLeads: {},
    unitLeads: {},
    tenantLeads: {},
    tenantAgreements: {},
    tenantContracts: {},
    addressOwnerContracts: {},
    buildingOwnerContracts: {},
    roomOwnerContracts: {},
    ownerContracts: {},
    propertyMeters: {},
    meterReadings: {},
    addressServices: {},
    buildingServices: {},
    roomServices: {},
    servicePayments: {},
})
export const newMessageDrafts = makeVar(new Map())
export const ticketListCtrlsVar = makeVar({
    ...initailTicketsQueryVars,
    filters: [{ field: 'relevantToMe', id: 'true', label: 'Relevant to me', icon: 'important' }],
})
export const ticketArchiveCtrlsVar = makeVar(initailTicketsQueryVars)
export const taskListCtrlsVar = makeVar(initailTasksQueryVars)
export const taskArchiveCtrlsVar = makeVar(initailTasksQueryVars)
export const callListCtrlsVar = makeVar(initailCallsQueryVars)
export const ticketBoardCtrlsVar = makeVar({
    filters: [{ field: 'relevantToMe', id: 'true', label: 'Relevant to me', icon: 'important' }],
    sortBy: 'unreadMessageCount',
    sortDir: -1,
    searchTerm: '',
    limit: 10
})
export const contactListCtrlsVar = makeVar({
    filters: [],
    groupBy: 'type',
    groupDir: 1,
    sortBy: 'fullName',
    sortDir: 1,
    searchTerm: '',
    limit: 30
})
export const userTaskListCtrlsVar = makeVar({ 
    filters: [],
    groupBy: 'archived',
    groupDir: 1,
    sortBy: 'status',
    sortDir: 1,
    searchTerm: '',
    limit: 30
})
export const userTicketListCtrlsVar = makeVar({ 
    filters: [],
    groupBy: 'archived',
    groupDir: 1,
    sortBy: 'createdDate',
    sortDir: -1,
    searchTerm: '',
    limit: 30
})
export const userCallListCtrlsVar = makeVar({
    filters: [],
    groupBy: '',
    groupDir: 1,
    sortBy: 'dateTime',
    sortDir: -1,
    searchTerm: '',
    limit: 30
})
export const attachmentListCtrlsVar = makeVar({
    filters: [],
    sortBy: 'dateChanged',
    sortDir: -1,
    searchTerm: '',
    limit: 30
})
export const contactAttachmentListCtrlsVar = makeVar({
    filters: [],
    sortBy: 'dateCreated',
    groupBy: 'contentType.model',
    groupDir: 1,
    sortDir: -1,
    searchTerm: '',
    limit: 30
})
export const viewableByUserAttachmentListCtrlsVar = makeVar({
    filters: [],
    sortBy: 'fileName',
    groupBy: 'categoryName',
    groupDir: 1,
    sortDir: 1,
    searchTerm: ''
})
export const propertyAttachmentListCtrlsVar = makeVar({
    filters: [],
    sortBy: 'dateCreated',
    groupBy: 'contentType.model',
    sortDir: -1,
    searchTerm: '',
    limit: 30
})
export const ticketAttachmentListCtrlsVar = makeVar({
    filters: [],
    sortBy: 'dateCreated',
    groupBy: 'contentType.model',
    sortDir: -1,
    groupDir: 1,
    searchTerm: '',
    limit: 30
})
export const templateListCtrlsVar = makeVar({
    filters: [],
    sortBy: 'name',
    sortDir: 1,
    searchTerm: '',
    limit: 30
})
export const propertyListCtrlsVar = makeVar(initialPropertiesQueryVars)
export const propertyTicketListCtrlsVar = makeVar({ ...initailTicketsQueryVars, groupBy: 'archived' })
export const propertyTaskListCtrlsVar = makeVar({ ...initailTicketsQueryVars, groupBy: 'archived' })
export const propertyCallListCtrlsVar = makeVar({ ...initailCallsQueryVars, filters: [] })
export const boardFilterOptionsVar = makeVar({})
export const lastActiveThreadVar = makeVar(localStorage.getItem('lastActiveThread'))
export const openedThreadsVar = makeVar(JSON.parse(localStorage.getItem('openedThreads')) || [])
export const notificationCountersVar = makeVar({
    unreadMessageCount: 0,
    unreadMentionsCount: 0,
    unreadNotificationsCount: 0,
    missedCallsCount: 0
})
export const preventInboxSwitch = makeVar(false)
export const showAllThreadsVar = makeVar(false)
export const activeSubtabVar = makeVar({})
export const ocpVar = makeVar({
    isOpen: false,
    containerEl: null,
    defaultWidth: 480
})
export const searchTermVar = makeVar('')

export const cache = new InMemoryCache({
    possibleTypes: {
        ContentObjectType: ['TicketType', 'TaskType'],
    },
    typePolicies: {
        Query: {
            fields: {
                unreadThreads: { merge: (existing = {}, incoming) => {
                    return { ...existing, ...incoming }
                }},
                calls: { merge: (_, incoming) => incoming },
                changeLogForContentObject: offsetLimitPagination(['contentType', 'objectId']),
                userActivity: offsetLimitPagination(['userId']),
                currentUser: { read: () => currentUserVar() },
                activeApp: { read: () => activeAppVar() },
                staticTabs: { read: () => staticTabsVar() },
                pinnedTabs: { read: () => pinnedTabsVar() },
                openedTabs: { read: () => openedTabsVar() },
                newDrafts: { read: () => newDraftsVar() },
                ticketBoardCtrls: { read: () => ticketBoardCtrlsVar() },
                ticketListCtrls: { read: () => ticketListCtrlsVar() },
                taskListCtrlsVar: { read: () => taskListCtrlsVar() },
                boardFilterOptions: { read: (_, { args: { field }}) => boardFilterOptionsVar()[field] },
                priorityOptions: { read: () => Object.keys(priorityOptions).map(key => ({ ...priorityOptions[key], id: key })) },
                approvalRequiredOptions: { read: () => ([{ id: true, label: lbl.required, icon: 'checkmark', color: 'green' }, { id: false, label: lbl.notRequired, icon: 'close', color: 'red' }]) },
                statusOptions: { read: () => Object.keys(statusOptions).map(key => ({ ...statusOptions[key], id: key })) },
                onlineStatusOptions: { read: () => Object.keys(onlineStatusOptions).map(key => ({ ...onlineStatusOptions[key], id: key })) },
                employmentStatusOptions: { read: () => employmentStatusOptions },
                callStatusOptions: { read: () => Object.keys(callStatusOptions).map(key => ({ ...callStatusOptions[key], id: key })) },
                callDirectionOptions: { read: () => Object.keys(callDirectionOptions).map(key => ({ ...callDirectionOptions[key], id: key })) },
                callTimingOptions: { read: () => Object.keys(callTimingOptions).map(key => ({ ...callTimingOptions[key] })) },
                tenantStatusOptions: { read: () => Object.keys(tenantStatusOptions).map(key => ({ ...tenantStatusOptions[key] })) },
                userTypeOptions: { read: () => Object.keys(userTypeOptions).map(key => ({ ...userTypeOptions[key] })) },
                tickets: offsetLimitPagination(['filters', 'groupBy', 'sortBy', 'groupDir', 'sortDir', 'searchTerm', 'searchBy']),
                archivedTickets: offsetLimitPagination(['filters', 'groupBy', 'sortBy', 'groupDir', 'sortDir', 'searchTerm']),
                userPublicThreads: offsetLimitPagination(['userId']),
                userTickets: offsetLimitPagination(['userId', 'filters', 'groupBy', 'sortBy', 'groupDir', 'sortDir', 'searchTerm']),
                propertyTickets: offsetLimitPagination(['propertyIds', 'unitIds', 'filters', 'groupBy', 'sortBy', 'groupDir', 'sortDir', 'searchTerm']),
                tasks: offsetLimitPagination(['filters', 'groupBy', 'sortBy', 'groupDir', 'sortDir', 'searchTerm']),
                archivedTasks: offsetLimitPagination(['filters', 'groupBy', 'sortBy', 'groupDir', 'sortDir', 'searchTerm']),
                userTasks: offsetLimitPagination(['userId', 'filters', 'groupBy', 'sortBy', 'groupDir', 'sortDir', 'searchTerm']),
                propertyTasks: offsetLimitPagination(['propertyIds', 'unitIds', 'filters', 'groupBy', 'sortBy', 'groupDir', 'sortDir', 'searchTerm']),
                calls: offsetLimitPagination(['filters', 'groupBy', 'sortBy', 'groupDir', 'sortDir', 'searchTerm']),
                userCalls: offsetLimitPagination(['userId', 'filters', 'groupBy', 'sortBy', 'groupDir', 'sortDir', 'searchTerm']),
                propertyCalls: offsetLimitPagination(['propertyIds', 'unitIds', 'filters', 'groupBy', 'sortBy', 'groupDir', 'sortDir', 'searchTerm']),
                users: offsetLimitPagination(['filters', 'groupBy', 'sortBy', 'groupDir', 'sortDir', 'searchTerm']),
                templates: offsetLimitPagination(['filters', 'groupBy', 'sortBy', 'groupDir', 'sortDir', 'searchTerm']),
                properties: offsetLimitPagination(['filters', 'groupBy', 'sortBy', 'groupDir', 'sortDir', 'searchTerm']),
                attachments: offsetLimitPagination(['profileId', 'filters', 'groupBy', 'sortBy', 'groupDir', 'sortDir', 'searchTerm']),
                taskAttachments: offsetLimitPagination(['taskId', 'filters', 'groupBy', 'sortBy', 'groupDir', 'sortDir', 'searchTerm']),
                attachmentsForContentObject: offsetLimitPagination(['contentTypeModel', 'objectId', 'filters', 'groupBy', 'sortBy', 'groupDir', 'sortDir', 'searchTerm']),
                attachmentsForContentObjects: offsetLimitPagination(['contentObjects', 'filters', 'groupBy', 'sortBy', 'groupDir', 'sortDir', 'searchTerm']),
                threadsBySearchTerm: offsetLimitPagination(['searchTerm']),
                ticketsBySearchTerm: offsetLimitPagination(['searchTerm']),
                tasksBySearchTerm: offsetLimitPagination(['searchTerm']),
                usersBySearchTerm: offsetLimitPagination(['searchTerm']),
                propertiesBySearchTerm: offsetLimitPagination(['searchTerm']),
                templatesBySearchTerm: offsetLimitPagination(['searchTerm']),
                unreadNotifications: offsetLimitPagination(),
                readNotifications: offsetLimitPagination(),
                archivedThreads: offsetLimitPagination(),
                ticketsByPipelineStage: {
                    keyArgs: ['pipelineStageId', 'filters', 'sortBy', 'sortDir', 'searchTerm'],
                    merge(existing, incoming, { readField, args }) {
                        let tickets
                        const incomingTickets = new Map()
                        
                        incoming.tickets.forEach(ticket => {
                            const id = readField('id', ticket)
                            incomingTickets.set(id, ticket)
                        })

                        if (!existing || args.preventMerge) {
                            tickets = incomingTickets
                        } else {
                            const mergedTickets = new Map([...existing.tickets, ...incomingTickets])
                            tickets = mergedTickets
                        }

                        return {
                            cursor: incoming.cursor,
                            hasMore: incoming.hasMore,
                            filteredTicketCount: incoming.filteredTicketCount,
                            tickets
                        }
                    },
                    read(existing, { readField }) {
                        if (existing) {
                            const { sortBy, sortDir } = ticketBoardCtrlsVar()
                            let tickets = Array.from(existing.tickets.values())
                            if (sortBy === 'unreadMessageCount') {
                                tickets = tickets.sort((a,b) => readField('unreadMessageCount', a) > readField('unreadMessageCount', b) ? sortDir : sortDir*-1)
                            }
                            return {
                                cursor: existing.cursor,
                                hasMore: existing.hasMore,
                                filteredTicketCount: existing.filteredTicketCount,
                                tickets
                            }
                        }
                    },
                }
            },
        },
        ThreadType: {
            keyArgs: ['id'],
            keyFields: ['id'],
            fields: {
                messages: {
                    keyArgs: [],
                    merge: (existing=[], incoming, { args, readField }) => {
                        const incomingFiltered = incoming.filter(inMsg => {
                            return !existing.some(exMsg => readField('id', exMsg) === readField('id', inMsg))
                        })
                        return args?.reversed ? [...incomingFiltered, ...existing] : [...existing, ...incomingFiltered]
                    }
                },
                unreadMessages: {
                    keyArgs: [],
                    merge: (existing=[], incoming, { readField }) => {
                        const incomingFiltered = incoming.filter(inMsg => {
                            return !existing.some(exMsg => readField('id', exMsg) === readField('id', inMsg))
                        })
                        return [...existing, ...incomingFiltered]
                    }
                },
                subthreads: {
                    keyArgs: ['all'],
                    merge: (existing, incoming) => { return incoming }
                },
                deleted: { read: (isDeleted=false) => isDeleted }
            }
        },
        TicketType: {
            keyArgs: ['id'],
            fields: {
                deleted: { read: (isDeleted=false) => isDeleted },
                unreadMessageCount: { read: (_, { readField, cache }) => {
                    const threads = readField('threads')
                    if (!threads || !threads.length) return 0
                    let unread_count = 0
                    
                    threads.forEach(thread_ref => {
                        const t = cache.readFragment({
                            id: thread_ref.__ref,
                            fragment: gql`
                                fragment TicketThreadFields on ThreadType {
                                    id
                                    archived
                                    unreadCount
                                    isUnread
                                }
                            `
                        })
                        if (t && !t.archived && (t.unreadCount || t.isUnread)) {
                            unread_count += t.unreadCount || 1
                        }
                    })

                    return unread_count
                } }
            }
        },
        TaskType: {
            keyArgs: ['id'],
            fields: {
                deleted: { read: (isDeleted=false) => isDeleted }
            }
        },
        TenantType: {
            keyArgs: ['id'],
            fields: {
                address: { keyArgs: ['id'], read: (_, { readField, cache }) => {
                    const ref = readField('activeTenancyAgreement') || readField('activeLead')
                    if (!ref) return null
                    const addressRef = readField('address', ref)

                    if (!addressRef) return null

                    return cache.readFragment({
                        id: addressRef.__ref,
                        fragment: gql`
                            fragment ContactListerFields on PropertyType {
                                id
                                streetWithHouseNumber
                                postcode
                                building {
                                    id
                                    city {
                                        id
                                        name
                                    }
                                }
                            }
                        `
                    })
                }},
                unit: { keyArgs: ['id'], read: (_, { readField, cache }) => {
                    const ref = readField('activeTenancyAgreement') || readField('activeLead')
                    if (!ref) return null
                    const unitRef = readField('room', ref)

                    if (!unitRef) return null
                    
                    return cache.readFragment({
                        id: unitRef.__ref,
                        fragment: gql`
                            fragment ContactListerFields on RoomType {
                                id
                                fullUnitName
                            }
                        `
                    })
                }}
            }
        },
        OwnerContractType: {
            keyArgs: ['id'],
            fields: {
                buildings: { merge: (existing=[], incoming) => incoming },
                properties: { merge: (existing=[], incoming) => incoming },
                units: { merge: (existing=[], incoming) => incoming }
            }
        }
    }
})

export default cache
