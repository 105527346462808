import { gql } from '@apollo/client'
import { NOTE_FRAGMENT } from './fragments'

export const CREATE_NOTE = gql`
    mutation CREATE_NOTE($body: String!, $notedContentType: String!, $notedObjectId: ID!, $mentionIds: [ID], $tagIds: [ID]) {
        createNote(body: $body, notedContentType: $notedContentType, notedObjectId: $notedObjectId, mentionIds: $mentionIds, tagIds: $tagIds) {
            note {
                ...NoteFields
                tags {
                    id
                    label
                    color
                }
                annotations {
                    id
                    objectId
                    contentType {
                        model
                    }
                    label
                }
            }
        }
    }
    ${NOTE_FRAGMENT}
`

export const PIN_NOTE = gql`
    mutation PIN_NOTE($id: ID!, $pinned: Boolean) {
        pinNote(id: $id, pinned: $pinned) {
            note {
                ...NoteFields
            }
        }
    }
    ${NOTE_FRAGMENT}
`

export const ARCHIVE_NOTE = gql`
    mutation ARCHIVE_NOTE($id: ID!, $archived: Boolean) {
        archiveNote(id: $id, archived: $archived) {
            note {
                ...NoteFields
            }
        }
    }
    ${NOTE_FRAGMENT}
`

export const ADD_COMMENT = gql`
    mutation ADD_COMMENT($body: String!, $noteId: ID!, $mentionIds: [ID]) {
        addComment(body: $body, noteId: $noteId, mentionIds: $mentionIds) {
            note {
                ...NoteFields
            }
        }
    }
    ${NOTE_FRAGMENT}
`

export const ARCHIVE_COMMENT = gql`
    mutation ARCHIVE_COMMENT($id: ID!) {
        archiveComment(id: $id, archived: true) {
            comment {
                id
                body
                archived
                createdAt
                createdBy {
                    id
                    fullName
                    picture
                }
            }
        }
    }
`

export const CREATE_NOTE_TAG = gql`
    mutation CREATE_NOTE_TAG($label: String!, $color: String!, $noteId: ID) {
        createNoteTag(label: $label, color: $color, noteId: $noteId) {
            tag {
                id
                label
                color
            }
            note {
                id
                tags {
                    id
                    label
                    color
                }
            }
        }
    }
`

export const ADD_TAG_TO_NOTE = gql`
    mutation ADD_TAG_TO_NOTE($tagId: ID!, $noteId: ID!) {
        addTagToNote(tagId: $tagId, noteId: $noteId) {
            note {
                id
                tags {
                    id
                    label
                    color
                }
            }
        }
    }
`


export const REMOVE_TAG_FROM_NOTE = gql`
    mutation REMOVE_TAG_FROM_NOTE($tagId: ID!, $noteId: ID!) {
        removeTagFromNote(tagId: $tagId, noteId: $noteId) {
            note {
                id
                tags {
                    id
                    label
                    color
                }
            }
        }
    }
`
