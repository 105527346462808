import { Icon } from '../../components'

const PropertyTypeFilters = ({
    building,
    addresses,
    units,
    state,
    setState,
    contentType,
    As='div',
    className='property-level-filters d-inline-flex float-right',
    subtab
}) => (
    <As className={className}>
        {[['building', [building]], ['address', addresses], ['room', units]].map(([type, objects], i) => {
            const isOn = !!state[type]

            return (
                <button
                    key={type}
                    disabled={type === contentType || !objects}
                    className={`btn btn--naked btn--icon mr-1 br-05${isOn ? ' active': ''}`}
                    title={`${isOn ? 'exclude' : 'include'} ${type} ${subtab}`}
                    onClick={() => setState(prev => {
                        if (isOn) {
                            const newState = {...prev}
                            delete newState[type]
                            return newState
                        } else {
                            return ({ ...prev, [type]: [...prev[type] ? prev[type] : [], ...objects.map(({ id }) => id )] })
                        }
                    })}
                >
                    <Icon type={type} variant='sm'/>
                </button>
            )
        })}
    </As>
)

export default PropertyTypeFilters
