import { useEffect, useRef, useState, memo } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import Select from 'react-dropdown-select'
import { Icon, OnScrollLoader } from '../components'
import { objectIncludesString } from '../utils'

const MemoSelect = memo(Select)


export const SelectOption = ({
    item,
    itemIndex,
    state: { cursor },
    props: { keepSelectedInList, valueField },
    methods: { addItem },
    getLabel,
    selectedOptions,
    toBeRemoved,
    textWrap
}) => {
    const optionRef = useRef(null)
    const isSelected = selectedOptions && selectedOptions.filter(o => o[valueField] === item[valueField]).length
    // TODO: fix focus and key up/down

    useEffect(() => {
        if (optionRef && optionRef.current && isSelected) optionRef.current.focus()
    }, [isSelected])

    if (isSelected && !keepSelectedInList) return null

    return (
        <span
            ref={optionRef}
            className={`select-form__label react-dropdown-select-item ${
                (isSelected && cursor === null) || cursor === itemIndex ?
                'react-dropdown-select-item-active' : ''}${textWrap ? ' wrap' : ''}`}
            aria-selected={isSelected}
            aria-label={item.name}
            onClick={() => (toBeRemoved || !isSelected) && addItem(item)}
            onKeyPress={() => (toBeRemoved || !isSelected) && addItem(item)}
            key={`option-${item[valueField]}`}
        >
            <span className='label-wrapper'>
                {getLabel({ item, itemIndex })}
            </span>
            {toBeRemoved && <Icon type='close' variant='sm' classAdditions='ml-auto' />}
        </span>
    )
}


const SelectedContent = ({ state: { values }, props, getLabel }) => values.map(item => (
    <span key={`selected-option-${item[props.valueField]}`} className='label-wrapper'>
        {getLabel({ item, isSelectedContent: true })}
    </span>
))


export const SelectForm = ({ selectedOptions, onSelect, getLabelNode, isHidden, ...props }) => {
    useEffect(() => {
        isHidden && document.querySelector('.select-form--static.select-form--hidden').click()
    }, [isHidden])

    return (
        <Select
            className={`select-form select-form--static${isHidden ? ' select-form--hidden' : ''}`}
            values={selectedOptions}
            onChange={onSelect}
            backspaceDelete={!!props.multi}
            keepSelectedInList
            searchable={false}
            closeOnSelect
            contentRenderer={!isHidden && getLabelNode ? (props) => <SelectedContent getLabel={getLabelNode} {...props} /> : undefined}
            itemRenderer={getLabelNode ? (props) => <SelectOption getLabel={getLabelNode} selectedOptions={selectedOptions} {...props} /> : undefined}
            {...props}
        />
    )
}


export const LazySelectForm = ({
    optionsQuery,
    onSelect,
    selectedOptions,
    unselectOption,
    getLabelNode,
    showLoader,
    isHidden,
    ...props
}) => {
    const [getOptions, { loading, data }] = useLazyQuery(optionsQuery.query, {
        variables: optionsQuery.variables
    })
    let options = [unselectOption, ...data ? data[optionsQuery.name] : selectedOptions || []].filter(Boolean)

    useEffect(() => {
        isHidden && document.querySelector('.select-form.select-form--static').click()
    }, [isHidden])

    return (
        <Select
            className='select-form select-form--static'
            values={selectedOptions}
            options={options}
            onDropdownOpen={() => getOptions(optionsQuery.args)}
            onChange={onSelect}
            loading={showLoader && loading}
            backspaceDelete={!!props.multi}
            searchable={false}
            keepSelectedInList
            closeOnSelect
            contentRenderer={getLabelNode ? (props) => <SelectedContent getLabel={getLabelNode} {...props} /> : undefined}
            itemRenderer={getLabelNode ? (props) => <SelectOption getLabel={getLabelNode} selectedOptions={selectedOptions} {...props}/> : undefined}
            {...props}
        />
    )
}


export const SearchSelect = ({
    optionsQuery,
    unselectOption,
    getLabelNode,
    onSelect,
    onClose,
    selectedOptions,
    excludeValues,
    isHidden,
    showLoader,
    ...props
}) => {
    const [shouldFetchMore, setFetchMore] = useState(false)
    const [variables, setVariables] = useState(optionsQuery.variables || {})
    const isPaginated = variables.limit
    const { data, loading, fetchMore, client } = useQuery(optionsQuery.query, {
        variables,
    })

    const options = [unselectOption, ...(data && data[optionsQuery.name]) ? 
        excludeValues ? data[optionsQuery.name].filter(o => !excludeValues.includes(o[props.valueField])) : data[optionsQuery.name]
    : []].filter(Boolean)

    const onScroll = () => {
        if (!isPaginated || !data || !data[optionsQuery.name]) return
        
        fetchMore({
            variables: { ...variables, offset: data[optionsQuery.name].length }
        }).then(fetchMoreResult => {
            if (fetchMoreResult.data && fetchMoreResult.data[optionsQuery.name]) {
                const newBatchCount = fetchMoreResult.data[optionsQuery.name].length
                if (newBatchCount === 0) setFetchMore(false)
            }
        })
    }

    const onSearch = ({ state }) => {
        let updatedList = []
        const updatedSearchTerm = state.search || ''

        if (updatedSearchTerm !== variables.searchTerm) {
            const updatedVars = { ...variables, searchTerm: updatedSearchTerm }
            const cached = client.cache.readQuery({ query: optionsQuery.query, variables: updatedVars })
            
            if (cached) {
                updatedList = cached[optionsQuery.name]
            } else {
                let initialList
                if (updatedSearchTerm.length > variables.searchTerm?.length || 0) {
                    initialList = state.searchResults
                } else {
                    initialList = client.cache.readQuery(optionsQuery)[optionsQuery.name]
                }
                updatedList = updatedSearchTerm ? initialList.filter((item) => objectIncludesString({
                    obj: item,
                    str: updatedSearchTerm,
                    searchFields: variables.searchBy ? variables.searchBy.map((field) => ({ field, type: 'string' })) : [{ field: props.labelField || 'name', type: 'string'}]
                })) : initialList

                updatedList.length && client.cache.writeQuery({
                    query: optionsQuery.query,
                    variables: updatedVars,
                    data: { [optionsQuery.name]: updatedList }
                })
            }
            setVariables(updatedVars)

            return updatedList
        }
    }

    useEffect(() => {
        isHidden && document.querySelector('.select-form--search.select-form--hidden').click()
    }, [isHidden])

    useEffect(() => {
        if (isPaginated && data && data[optionsQuery.name]) {
            if (data[optionsQuery.name].length) {
                const hasMore = !!data[optionsQuery.name][data[optionsQuery.name].length - 1].hasMore
                if (hasMore !== shouldFetchMore) setFetchMore(hasMore)
            }
        }
    }, [isPaginated, data && data[optionsQuery.name]])

    return (
        <MemoSelect
            className={`select-form select-form--search${isHidden ? ' select-form--hidden' : ''}`}
            options={options}
            onChange={onSelect}
            backspaceDelete={false}
            loading={showLoader && loading && (!isPaginated || !shouldFetchMore)}
            searchable
            closeOnSelect
            searchFn={isPaginated ? onSearch : undefined}
            itemRenderer={getLabelNode ? (_props) => {
                const { item, state } = _props
                const isLast = item[props.valueField] === state.searchResults[state.searchResults.length - 1][props.valueField]
                return (
                    <>
                        <SelectOption
                            getLabel={getLabelNode}
                            selectedOptions={selectedOptions}
                            textWrap={isPaginated}
                            {..._props}
                        />
                        {isLast && shouldFetchMore &&
                            <OnScrollLoader onIntersect={onScroll} />
                        }
                    </>
                )
            } : undefined }
            noDataRenderer={({ state, props }) => {
                if (isPaginated && state.search !== variables.searchTerm) onSearch({ state })
                if (shouldFetchMore) return <OnScrollLoader onIntersect={onScroll} />
                if (props.create || (showLoader && loading)) return null
                return <span className='label-wrapper'>No matches for::<strong>{state.search}</strong></span>
            }}
            loadingRenderer={() => <div className='pb-4'><div className='loader'/></div>}
            {...props}
        />
    )
}

export const HiddenSearchSelect = (props) => {
    console.log('HiddenSearchSelect', props.options)
    useEffect(() => {
        document.querySelector('.select-form--search.select-form--hidden').click()
    }, [])

    return <Select className='select-form select-form--search select-form--hidden' {...props} />
}

export default SelectForm