import { gql } from '@apollo/client'

export const GET_ATTACHMENTS_FOR_CONTENT_OBJECT = gql`
    query GET_ATTACHMENTS_FOR_CONTENT_OBJECT(
        $objectId: ID!, $contentTypeModel: String!, $offset: Int, $limit: Int, $searchTerm: String, $groupBy: String, $groupDir: Int, $sortBy: String, $sortDir: Int, $filters: String
    ) {
        attachmentsForContentObject(objectId: $objectId, contentTypeModel: $contentTypeModel, offset: $offset, limit: $limit, searchTerm: $searchTerm, groupBy: $groupBy, groupDir: $groupDir, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            id
            originalFilename
            url
            dateChanged
            dateChangedLabel
            uploadedBy {
                id
                fullName
                picture
            }
            dateCreated
            dateCreatedLabel
            hasMore
            category {
                id
                name
                userGroups {
                    id
                    name
                }
            }
        }
    }
`

export const GET_ATTACHMENTS_FOR_CONTENT_OBJECTS = gql`
    query GET_ATTACHMENTS_FOR_CONTENT_OBJECTS(
        $contentObjects: [ContentObjectType]!, $offset: Int, $limit: Int, $searchTerm: String, $groupBy: String, $groupDir: Int, $sortBy: String, $sortDir: Int, $filters: String
    ) {
        attachmentsForContentObjects(contentObjects: $contentObjects, offset: $offset, limit: $limit, searchTerm: $searchTerm, groupBy: $groupBy, groupDir: $groupDir, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            id
            originalFilename
            url
            dateChanged
            dateChangedLabel
            uploadedBy {
                id
                fullName
                picture
            }
            contentType {
                model
            }
            objectId
            relatedObjectLabel
            relatedObjectArchived
            dateCreated
            dateCreatedLabel
            hasMore
            taggedMessageId
            category {
                id
                name
                userGroups {
                    id
                    name
                }
            }
        }
    }
`

export const GET_ATTACHMENT_CATEGORIES = gql`
    query GET_ATTACHMENT_CATEGORIES {
        attachmentCategories {
            id
            name
            userGroups {
                id
                name
            }
        }
    }
`
