import 'froala-editor/js/froala_editor.pkgd.min.js'
import 'froala-editor/js/plugins/image.min.js'
import 'froala-editor/css/plugins/image.min.css'
import 'froala-editor/js/plugins/file.min.js'
import 'froala-editor/css/plugins/file.min.css'
import 'froala-editor/js/plugins/video.min.js'
import 'froala-editor/css/plugins/video.min.css'
import 'froala-editor/css/plugins/emoticons.min.css'
import 'froala-editor/js/plugins/emoticons.min.js'
import 'froala-editor/css/plugins/colors.min.css'
import 'froala-editor/js/plugins/colors.min.js'
import 'froala-editor/js/plugins/lists.min.js'
import 'froala-editor/js/plugins/link.min.js'
import 'froala-editor/js/plugins/font_size.min.js'
import 'froala-editor/js/plugins/word_paste.min.js'
import 'froala-editor/js/plugins/align.min.js'
import Froala from 'froala-editor'
import FroalaEditor from 'react-froala-wysiwyg'
import React, { useState, useRef, useLayoutEffect } from 'react'
import Popover from './Popover'
import { SearchSelect } from '../forms'
import ContactSelect from './ContactSelect'
import { GET_TEMPLATES } from '../templates/queries'
import { GET_ALL_USERS } from '../queries'
import { lbl, FROALA_LICENCE_KEY } from '../constants'


const TextEditor = ({
    config,
    handleManualController,
    onSave,
    onCancel,
    onArchive,
    onDelete,
    onInsert,
    onAddTag,
    allowMentions=false,
    saveCommand='',
    saveButtonLabel='SAVE CHANGES',
    focusOnMount=false,
    ...props
}) => {
    let [activePopup, setActivePopup] = useState('')
    const editorRef = useRef()

    Froala.DefineIcon('insertTemplate', { PATH: 'M18.2222 19.7778H5.77778V5.55556H7.55556V8.22222H16.4444V5.55556H18.2222V19.7778ZM12 3.77778C12.2357 3.77778 12.4618 3.87143 12.6285 4.03813C12.7952 4.20483 12.8889 4.43092 12.8889 4.66667C12.8889 4.90241 12.7952 5.12851 12.6285 5.29521C12.4618 5.46191 12.2357 5.55556 12 5.55556C11.7643 5.55556 11.5382 5.46191 11.3715 5.29521C11.2048 5.12851 11.1111 4.90241 11.1111 4.66667C11.1111 4.43092 11.2048 4.20483 11.3715 4.03813C11.5382 3.87143 11.7643 3.77778 12 3.77778V3.77778ZM18.2222 3.77778H14.5067C14.1333 2.74667 13.1556 2 12 2C10.8444 2 9.86667 2.74667 9.49333 3.77778H5.77778C5.30628 3.77778 4.8541 3.96508 4.5207 4.29848C4.1873 4.63187 4 5.08406 4 5.55556V19.7778C4 20.2493 4.1873 20.7015 4.5207 21.0349C4.8541 21.3683 5.30628 21.5556 5.77778 21.5556H18.2222C18.6937 21.5556 19.1459 21.3683 19.4793 21.0349C19.8127 20.7015 20 20.2493 20 19.7778V5.55556C20 5.08406 19.8127 4.63187 19.4793 4.29848C19.1459 3.96508 18.6937 3.77778 18.2222 3.77778Z', template: 'svg' });
    Froala.RegisterCommand('insertTemplate', {
        title: 'Insert text template',
        icon: 'insertTemplate',
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            this.selection.save()
            editorRef.current = this
            setActivePopup('template')
        }
    })

    if (!saveCommand || saveCommand === 'saveChanges') {
        Froala.DefineIcon('saveChanges', { NAME: saveButtonLabel, template: 'text' });
        Froala.RegisterCommand('saveChanges', {
            title: 'Save',
            icon: 'saveChanges',
            focus: true,
            undo: false,
            refreshAfterCallback: true,
            callback: onSave,
            refresh: function ($btn) {
                editorRef.current = this
                const text = this.html.get()
                if (text) {
                    $btn.removeClass('fr-disabled')
                } else if (!text) {
                    $btn.addClass('fr-disabled')
                }
            }
        })
    }

    Froala.DefineIcon('addTag', { PATH: 'M23.41 12.58L14.41 3.58C14.04 3.21 13.53 3 13 3H6C5.46957 3 4.96086 3.21071 4.58579 3.58579C4.21071 3.96086 4 4.46957 4 5V12C4 12.53 4.21 13.04 4.59 13.41L5 13.81C5.9 13.27 6.94 13 8 13C9.5913 13 11.1174 13.6321 12.2426 14.7574C13.3679 15.8826 14 17.4087 14 19C14 20.06 13.72 21.09 13.18 22L13.58 22.4C13.95 22.78 14.47 23 15 23C15.53 23 16.04 22.79 16.41 22.41L23.41 15.41C23.79 15.04 24 14.53 24 14C24 13.47 23.79 12.96 23.41 12.58ZM7.5 8C7.10218 8 6.72064 7.84196 6.43934 7.56066C6.15804 7.27936 6 6.89782 6 6.5C6 6.10218 6.15804 5.72064 6.43934 5.43934C6.72064 5.15804 7.10218 5 7.5 5C7.89782 5 8.27936 5.15804 8.56066 5.43934C8.84196 5.72064 9 6.10218 9 6.5C9 6.89782 8.84196 7.27936 8.56066 7.56066C8.27936 7.84196 7.89782 8 7.5 8V8ZM12 20H9V23H7V20H4V18H7V15H9V18H12V20Z' });
    onAddTag && Froala.RegisterCommand('addTag', {
        title: 'Add tag',
        icon: 'addTag',
        focus: true,
        undo: false,
        refreshAfterCallback: true,
        callback: onAddTag
    })

    Froala.DefineIcon('archive', { PATH: 'M4 4H20V7.55556H4V4ZM4.88889 8.44444H19.1111V20H4.88889V8.44444ZM9.77778 11.1111C9.6599 11.1111 9.54686 11.1579 9.46351 11.2413C9.38016 11.3246 9.33333 11.4377 9.33333 11.5556V12.8889H14.6667V11.5556C14.6667 11.4377 14.6198 11.3246 14.5365 11.2413C14.4531 11.1579 14.3401 11.1111 14.2222 11.1111H9.77778Z', template: 'svg' });
    onArchive && Froala.RegisterCommand('archive', {
        title: 'Archive template',
        icon: 'archive',
        focus: true,
        undo: false,
        refreshAfterCallback: true,
        callback: onArchive
    })

    Froala.DefineIcon('delete', { SVG_KEY: 'remove' });
    onDelete && Froala.RegisterCommand('delete', {
        title: 'Delete template',
        icon: 'delete',
        focus: true,
        undo: false,
        refreshAfterCallback: true,
        callback: onDelete
    })

    Froala.DefineIcon('cancelEditing', { SVG_KEY: 'close' });
    onCancel && Froala.RegisterCommand('cancelEditing', {
        title: 'Cancel editing',
        icon: 'cancelEditing',
        focus: true,
        undo: false,
        callback: onCancel
    })

    const editorConfig = {
        placeholderText: lbl.newContentPlaceholder,
        emoticonsUseImage: false,
        listAdvancedTypes: false,
        toolbarButtons: ['bold', 'italic', 'underline', 'formatUL', 'formatOL', 'clearFormatting', '|', 'insertLink', 'insertFile', 'insertVideo', 'insertImage', 'insertTemplate', 'emoticons'].filter(Boolean),
        ...config
    }

    if (allowMentions) {
        editorConfig['events']['keyup'] = function(e) {
            const { key, target } = e
            // config?.events?.keyup && config.events.keyup(e)
            if (key === '@' && /\s@|>@/.test(target.innerHTML)) {
                editorRef.current.selection.save()
                setActivePopup('mention')
            }
        }
    }

    const handleSaveBtnUi = () => {
        if (editorRef?.current && !!saveCommand) {
            const saveBtn = editorRef.current.$box[0].querySelector(`[data-cmd="${saveCommand}"]`)
            
            saveBtn?.classList.add('fr-disabled')
            editorRef.current.button?.bulkRefresh();
        }
    }

    const selectMention = (mentions) => {
        const lastMention = mentions[mentions.length - 1]
        editorRef.current.selection.restore();
        editorRef.current.cursor.backspace();
        editorRef.current.html.insert(`<a class="fr-deletable mention" data-mention-id="${lastMention.id}" href="/contacts/contact/${lastMention.id}">@${lastMention.fullName}</a>`);
    }

    const selectTemplate = ([{ body }]) => {
        editorRef.current.selection.restore()
        editorRef.current.html.insert(body)
    }

    const onManualControllerReady = ({ initialize, getEditor }) => {
        initialize()
        editorRef.current = getEditor()
        handleManualController && handleManualController({ getEditor, editor: editorRef.current })
    }

    const onClose = () => {
        setActivePopup('')
    }

    useLayoutEffect(() => {
        setTimeout(() => {
            handleSaveBtnUi()
            if (focusOnMount && editorRef.current) {
                editorRef.current.el.focus()
                editorRef.current.selection.setAtEnd(editorRef.current.el)
                editorRef.current.selection.restore()
            }
        }, 100)
    }, [focusOnMount])

    return (
        <React.Fragment>
            <FroalaEditor
                tag='textarea'
                config={editorConfig}
                onManualControllerReady={onManualControllerReady}
                {...props}
            />
            <Popover
                isOpen={!!activePopup}
                onClose={onClose}
                className={`popover--insert-${activePopup}`}
            >
                {activePopup === 'template' ?
                    <SearchSelect
                        isHidden
                        showLoader
                        searchBy='name'
                        labelField='name'
                        valueField='id'
                        optionsQuery={{ query: GET_TEMPLATES, name: 'templates' }}
                        onDropdownClose={onClose}
                        onSelect={selectTemplate}
                    />
                : activePopup === 'mention' ?
                    <>
                        <header className="pop-up__header">
                            @ Add mention
                        </header>
                        <ContactSelect
                            inputLabel={lbl.selectContact}
                            optionsQuery={GET_ALL_USERS}
                            placeholder='Type the contact name'
                            onSelect={selectMention}
                            directContactsOnly
                            closeOnSelect
                            keepSelectedInList
                            onCloseOptions={onClose}
                        />
                    </>
                : null}
            </Popover>
        </React.Fragment>
    )
}

export default TextEditor
