import Icon from './Icon'

const BatchActions = ({
    actions,
    resetSelection,
}) => {
    return (
        <div className='batch-actions pos-fixed'>
            <div className='pop-up pop-up--block pop-up--top pop-up--center pop-up--primary'>
                <div className='pop-up__container'>
                    <div className='pop-up__btns'>
                        {actions.map(action => {
                            const { icon, label, isDisabled, isHidden, onClick } = action
                            if (isHidden) return null
                            return (
                                <button
                                    key={label}
                                    className='btn'
                                    disabled={isDisabled}
                                    onClick={() => {
                                        onClick && onClick()
                                        resetSelection()
                                    }}
                                >
                                    <Icon type={icon} variant='sm' />
                                    <div>{label}</div>
                                </button>
                            )
                        })}
                    </div>
                    <button
                        className='btn btn--naked close close--xs'
                        onClick={resetSelection}
                    >
                        <Icon type='close' variant='2xs' />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default BatchActions
