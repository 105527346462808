import { Fragment, useState } from 'react'
import { lbl } from '../constants'
import Field from './Field'

const Form = ({
    initialState,
    fields,
    introFields,
    isSubmitting,
    isSuccess,
    onSubmit,
    onCancel,
    onDone,
    error,
    setDraft,
    formClassName,
    actions=[],
    disableSubmit,
    As='form',
    ...props
}) => {
    const disabled = isSubmitting || props.disabled
    const [state, setState] = useState(initialState)
    const [changedFields, setChanged] = useState(setDraft ? Object.keys(initialState) : [])
    const handleChange = (e, onChangeCb, isProgramatic=false) => {
        let { name, value, type, checked, files } = e.target
        if (type === 'checkbox') {
            value = checked
        } else if (type === 'file') {
            value = files[0]
        } else if ((type === 'number' || type === 'decimal') && value === '') {
            value = null
        }
        const changed = changedFields.includes(name)

        if (!changed && state[name] !== value) {
            setChanged(prev => [...prev, name])
        } else if (changed && (initialState[name] === value || (!initialState[name] && !value))) {
            setChanged(prev => prev.filter(field => field !== name))
        }

        const newState = { ...state, [name]: value }
        if (setDraft) setDraft(newState)
        if (onChangeCb) onChangeCb(value, isProgramatic)
        setState(newState)
    }
    const cancelChanges = () => {
        setState(initialState)
        setDraft && setDraft({})
        setChanged([])
        onCancel && onCancel()
    }
    const hasChanged = !!changedFields.length

    return (
        <As
            method='post'
            className={formClassName}
            onSubmit={async e => {
                e.preventDefault()
                e.stopPropagation()
                const data = {}
                
                changedFields.forEach(field => {
                    data[field] = state[field]
                })
                await onSubmit(data, cancelChanges)
                setChanged([])
                onDone && onDone()
            }}
            {...props}
        >
            {introFields &&
                <fieldset className='fieldset--triple' disabled={disabled} aria-busy={disabled}>
                    {introFields.map(({ BeforeNode, AfterNode, name, onChangeCb, value, ...fieldProps }) => (
                        <Fragment key={name}>
                            {BeforeNode ? typeof BeforeNode === 'function' ? <BeforeNode/> : BeforeNode : null}
                            <Field
                                name={name}
                                value={value !== undefined ? value : state[name] || (fieldProps.multiple ? [] : '')}
                                isEdited={changedFields.includes(name)}
                                handleChange={(e, isProgramatic) => handleChange(e, onChangeCb, isProgramatic)}
                                isSubmitting={isSubmitting}
                                {...fieldProps}
                            />
                            {AfterNode ? typeof AfterNode === 'function' ? <AfterNode/> : AfterNode : null}
                        </Fragment>
                    ))}
                </fieldset>
            }
            <fieldset disabled={disabled} aria-busy={disabled}>
                {fields.map(({ BeforeNode, AfterNode, name, onChangeCb, value, ...fieldProps }) => (
                    <Fragment key={name + fieldProps.label}>
                        {BeforeNode ? typeof BeforeNode === 'function' ? <BeforeNode/> : BeforeNode : null}
                        <Field
                            name={name}
                            value={value !== undefined ? value : state[name] || (fieldProps.multiple ? [] : '')}
                            isEdited={changedFields.includes(name)}
                            handleChange={(e, isProgramatic) => handleChange(e, onChangeCb, isProgramatic)}
                            isSubmitting={isSubmitting}
                            {...fieldProps}
                        />
                        {AfterNode ? typeof AfterNode === 'function' ? <AfterNode/> : AfterNode : null}
                    </Fragment>
                ))}
            </fieldset>
            {error && <div className='error t-red mb-2'>{error}</div>}
            <div className='d-flex mb-1'>
                <div className='ml-auto'/>
                {actions?.map(({ label, ...btnProps }) => (
                    <button
                        key={label}
                        className='btn btn--secondary mr-2'
                        type="button"
                        disabled={disabled}
                        {...btnProps}
                    >
                        {label}
                    </button>
                ))}
                <button
                    onClick={cancelChanges}
                    className='btn btn--secondary mr-2'
                    disabled={!hasChanged || disabled}
                >
                    {lbl.cancel}
                </button>
                <input
                    type='submit'
                    className={`btn btn--${!hasChanged && isSuccess ? 'success' : 'primary'}`}
                    disabled={ !hasChanged || disabled || disableSubmit }
                    value={isSubmitting ? lbl.saving : !hasChanged && isSuccess ? lbl.saved : lbl.save}
                >
                </input>
            </div>
        </As>
    )
}

export default Form
